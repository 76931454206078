import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";

import subscriptionService from "../../services/subscription";
import SimpleBackdrop from "../../components/ui/SimpleBackdrop";
import { useSelector } from "react-redux";

import XeroLogo from "../../assets/xero.svg";
import QuickBooksLogo from "../../assets/quickbooks.svg";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import styled from "@emotion/styled";

const RadioCust = styled(Radio)(() => ({
    color: "#131f3e",
    "&.Mui-checked": {
        color: "#131f3e",
    },
}));

const FormLabelCust = styled(FormLabel)(() => ({
    color: "#131f3e",
    "&.Mui-focused": {
        color: "#131f3e",
    },
}));

const CustomPlan = (props) => {
    const {
        handleClose,
        plansInfo,
        currentPlan,
        setCartItems,
        handleOpenCart,
        setActivePlan,
        setPlanCredits,
        creditsInfo,
        activePlan,
    } = props;

    const [t] = useTranslation("global");
    const [servType, setServType] = useState("");
    const [numBanks, setNumBanks] = useState("");
    const [freqMonth, setFreqMonth] = useState("");
    const [users, setUsers] = useState("");
    const [platform, setPlatform] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [idealPlan, setIdealPlan] = useState("");
    const [customCredits, setCustomCredits] = useState(0);

    const todos = useSelector((state) => state.value);

    const handlePlanSelect = async () => {
        const value = plansInfo.find((item) => item.planId === idealPlan.subscription_plan);

        const params = { franchise_id: todos.franchiseID };
        const finalCart = [];
        let planSelected = "";
        let samePlan = idealPlan.subscription_plan === currentPlan;

        if (value) {
            const newVAl = {
                planType: value.data.credits,
                planData: value.data,
                subscription_plan: value.planId,
                line_amount: value.cost,
                line_unit_amount: (value.cost / value.data.credits).toFixed(2),
                line_qty: 1,
                line_since_date: moment().format(),
                line_end_date: moment().add(1, "M").format(),
                image: value.image,
            };

            if (currentPlan) {
                if (value.planId < currentPlan) {
                    //Downgrade
                    newVAl.line_type = 3;
                } else if (value.planId > currentPlan) {
                    //Upgrade
                    newVAl.line_type = 4;

                    setActivePlan(value.planId);
                    planSelected = value.planId;
                }
            } else {
                newVAl.line_type = 1;

                setActivePlan(value.planId);
                if (value.planId === 1) {
                    setPlanCredits(creditsInfo.slice(0, 2));
                } else if (value.planId === 2) {
                    setPlanCredits(creditsInfo.slice(1, 3));
                } else if (value.planId === 3) {
                    setPlanCredits(creditsInfo.slice(2, 4));
                } else if (value.planId === 4) {
                    setPlanCredits(creditsInfo.slice(4, 6));
                } else if (value.planId === 5) {
                    setPlanCredits(creditsInfo.slice(5, 7));
                } else {
                    setPlanCredits(creditsInfo.slice(7, 9));
                }
            }

            if (!samePlan) {
                finalCart.push(newVAl);
            }
        }

        if (customCredits > 0) {
            const totalCreditCost =
                Math.round(
                    (planSelected
                        ? customCredits * plansInfo.find((item) => item.planId === planSelected).customCredits
                        : currentPlan
                        ? customCredits * plansInfo.find((item) => item.planId === currentPlan).customCredits
                        : customCredits * 2) * 100
                ) / 100;

            const newVAl = {
                line_type: 6,
                line_amount: totalCreditCost,
                line_unit_amount: totalCreditCost / customCredits,
                line_qty: customCredits,
                line_since_date: moment().format(),
                line_end_date: moment().add(1, "M").format(),
                fixed: false,
            };

            finalCart.push(newVAl);
        }

        params.cart_lines = finalCart;

        setIsLoading(true);

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setCartItems(finalCart);
        handleOpenCart();

        setIsLoading(false);
    };

    const handleChangeServType = (event) => {
        setServType(event.target.value);
        setNumBanks("");
        setFreqMonth("");
        setUsers("");
        setPlatform("");
        setCustomCredits(0);
    };

    const handleChangeNumBanks = (event) => {
        if (event.target.value > 0 || event.target.value === "") {
            setNumBanks(event.target.value);
        }
    };

    const handleChangeFreqMonth = (event) => {
        setFreqMonth(event.target.value);
    };

    const handleChangeUsers = (event) => {
        setUsers(event.target.value);
    };

    const handleChangePlatform = (event) => {
        setPlatform(event.target.value);
    };

    const handleCustomCreditsSelect = (data) => {
        if (!(data === -1 && (customCredits === 1 || customCredits === 0 || isNaN(customCredits)))) {
            if (isNaN(customCredits)) {
                setCustomCredits(1);
            } else {
                setCustomCredits((prevState) => parseInt(prevState) + parseInt(data));
            }
        }
    };

    const handleCustomCreditsSelectInput = (e) => {
        if (e.target.value >= 0) {
            setCustomCredits(parseInt(e.target.value));
        }
    };

    const mainTitlePlan =
        idealPlan.subscription_plan === 0
            ? t("navBar.onDemand").toUpperCase()
            : plansInfo.find((item) => item.planId === idealPlan.subscription_plan)?.data.credits;

    const pricePlan =
        idealPlan.subscription_plan === 0
            ? 2
            : plansInfo.find((item) => item.planId === idealPlan.subscription_plan)?.cost;

    const pricePlanText = idealPlan.subscription_plan === 0 ? t("credits.conversion") : t("converter.month");

    const planCostCred = plansInfo.find((item) => item.planId === idealPlan.subscription_plan)?.customCredits;

    const usersPlan =
        idealPlan.subscription_plan === 0
            ? 1
            : plansInfo.find((item) => item.planId === idealPlan.subscription_plan)?.data.users;

    const singleCreditsCost = activePlan
        ? plansInfo.find((item) => item.planId === activePlan).customCredits
        : idealPlan.subscription_plan
        ? planCostCred
        : 2;

    const totalAmount = pricePlan + customCredits * singleCreditsCost;

    useEffect(() => {
        (async () => {
            if (servType || numBanks || freqMonth || users || users) {
                const params = {
                    service_type: servType,
                    account_count: numBanks,
                    service_frequency: freqMonth,
                    user_count: users,
                    accounting_system: platform,
                };

                setIsLoading(true);

                await subscriptionService
                    .getBestPlan(params)
                    .then((response) => {
                        setIdealPlan({
                            subscription_plan: response.subscription_plan,
                            extra_credits: response.extra_credits,
                        });
                        setCustomCredits(response.extra_credits);
                    })
                    .catch((err) => console.log(err));

                setIsLoading(false);
            }
        })();
    }, [servType, numBanks, freqMonth, users, platform]);

    return (
        <Box paddingX={2} sx={{ maxWidth: 800 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={7}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h2">{t("plans.findThePlan")}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabelCust id="demo-row-radio-buttons-group-label">
                                    {t("plans.recuServ")}
                                </FormLabelCust>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={servType}
                                    onChange={handleChangeServType}
                                >
                                    <FormControlLabel
                                        value={1}
                                        control={<RadioCust size="small" />}
                                        label={t("plans.recu")}
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<RadioCust size="small" color="default" />}
                                        label={t("plans.unique")}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <FormLabelCust id="banks-label" sx={{ pb: 1 }}>
                                    {t("plans.howManyBanks")}
                                </FormLabelCust>
                                <TextField
                                    aria-labelledby="banks-label"
                                    label={
                                        <Typography
                                            sx={{
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("plans.writeNum")}
                                        </Typography>
                                    }
                                    variant="filled"
                                    type="number"
                                    size="small"
                                    value={numBanks}
                                    onChange={handleChangeNumBanks}
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl disabled={servType === "2"}>
                                <FormLabelCust id="often-group-label">{t("plans.howOften")}</FormLabelCust>
                                <RadioGroup
                                    row
                                    aria-labelledby="often-group-label"
                                    name="often-group"
                                    value={freqMonth}
                                    onChange={handleChangeFreqMonth}
                                >
                                    <FormControlLabel
                                        value={1}
                                        control={<RadioCust size="small" />}
                                        label={t("navBar.monthly")}
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<RadioCust size="small" />}
                                        label={t("navBar.quart")}
                                    />
                                    <FormControlLabel
                                        value={3}
                                        control={<RadioCust size="small" />}
                                        label={t("navBar.weekly")}
                                    />
                                    <FormControlLabel
                                        value={4}
                                        control={<RadioCust size="small" />}
                                        label={t("navBar.daily")}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl disabled={servType === "2"}>
                                <FormLabelCust id="users-group-label">{t("plans.howMUsers")}</FormLabelCust>
                                <RadioGroup
                                    row
                                    aria-labelledby="users-group-label"
                                    name="users-group"
                                    value={users}
                                    onChange={handleChangeUsers}
                                >
                                    <FormControlLabel value={1} control={<RadioCust size="small" />} label="1" />
                                    <FormControlLabel value={2} control={<RadioCust size="small" />} label="2" />
                                    <FormControlLabel value={3} control={<RadioCust size="small" />} label="3" />
                                    <FormControlLabel value={4} control={<RadioCust size="small" />} label="4" />
                                    <FormControlLabel value={5} control={<RadioCust size="small" />} label="5" />
                                    <FormControlLabel value={6} control={<RadioCust size="small" />} label=">5" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl disabled={servType === "2"}>
                                <FormLabelCust id="plat-group-label">{t("plans.platform")}</FormLabelCust>
                                <RadioGroup
                                    row
                                    aria-labelledby="plat-group-label"
                                    name="plat-group"
                                    value={platform}
                                    onChange={handleChangePlatform}
                                >
                                    <FormControlLabel value={1} control={<RadioCust size="small" />} label="Xero" />
                                    <FormControlLabel
                                        value={2}
                                        control={<RadioCust size="small" />}
                                        label="Quickbooks"
                                    />
                                    <FormControlLabel
                                        value={3}
                                        control={<RadioCust size="small" />}
                                        label={t("register.other")}
                                    />
                                    <FormControlLabel
                                        value={4}
                                        control={<RadioCust size="small" />}
                                        label={t("miscellaneous.none")}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={5}>
                    {!idealPlan ? (
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            sx={{ backgroundColor: "#F8F8F9", minHeight: 500, textAlign: "center", borderRadius: 4 }}
                        >
                            <Typography variant="h2" sx={{ width: 200 }}>
                                {t("plans.startAnswer")}
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{ backgroundColor: "#E5E0F9", minHeight: 500, textAlign: "center", borderRadius: 4, p: 2 }}
                        >
                            <Stack alignItems="center">
                                <Box sx={{ backgroundColor: "#6544DB", px: 2, py: 1, mb: "18px", borderRadius: 3, width: 200 }}>
                                    <Typography fontSize={18} variant="h1" sx={{ color: "white" }}>
                                        {mainTitlePlan}
                                    </Typography>
                                </Box>
                                <Typography fontSize={16} pb="12px">
                                    <b>
                                        $
                                        {parseFloat(pricePlan).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}
                                    </b>{" "}
                                    / {pricePlanText}
                                </Typography>
                                {idealPlan.subscription_plan !== 0 && (
                                    <>
                                        <Typography variant="h2">{`${t("filters.until")} ${mainTitlePlan} ${t(
                                            "credits.credits"
                                        )}`}</Typography>
                                        <Typography pb="12px">
                                            + US${" "}
                                            {`${parseFloat(planCostCred).toLocaleString(todos.amountFormat, {
                                                minimumFractionDigits: 2,
                                            })} ${t("miscellaneous.add")}`}
                                        </Typography>
                                    </>
                                )}
                                <Typography pb="12px">
                                    <b>{usersPlan}</b>{" "}
                                    {usersPlan === 1 ? t("subscription.messageUser") : t("subscription.messageUsers")}
                                </Typography>
                                <Typography variant="h2">{t("subscription.stdTrain")}</Typography>
                                <Typography pb="12px">{t("subscription.knowlBase")}</Typography>
                                <Typography variant="h2">{t("subscription.stdSupp")}</Typography>
                                <Typography pb="12px">{`${t("plans.email")} | ${t("plans.liveChat")}`}</Typography>
                                {idealPlan.subscription_plan !== 0 && idealPlan.subscription_plan !== 1 && (
                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                        <Typography variant="h2" sx={{ mr: 1 }}>
                                            {t("plans.integrations")}{" "}
                                        </Typography>
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 15,
                                                width: 15,
                                                maxHeight: { xs: 15, md: 15 },
                                                maxWidth: { xs: 15, md: 15 },
                                            }}
                                            src={XeroLogo}
                                        />
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 15,
                                                width: 75,
                                                maxHeight: { xs: 15, md: 15 },
                                                maxWidth: { xs: 75, md: 75 },
                                                ml: 0.5,
                                            }}
                                            src={QuickBooksLogo}
                                        />
                                    </Stack>
                                )}
                                {idealPlan.subscription_plan === 0 ? (
                                    <Typography pb="12px">
                                        <b>30 {t("miscellaneous.days")}</b> {t("miscellaneous.extenPeriod")}
                                    </Typography>
                                ) : (
                                    <Typography pb="12px">
                                        <b>{`+${[1, 2, 3].includes(idealPlan.subscription_plan) ? 30 : 60} ${t(
                                            "miscellaneous.days"
                                        )}`}</b>{" "}
                                        {t("miscellaneous.exten")}
                                    </Typography>
                                )}
                                {idealPlan.extra_credits ? (
                                    <Typography variant="h2" color="primary">
                                        + {t("payment.addConver")}
                                    </Typography>
                                ) : null}
                            </Stack>
                            <Stack alignItems="center">
                                {idealPlan.subscription_plan === 0 || idealPlan.extra_credits ? (
                                    <>
                                        <Typography fontSize={16} color="primary" pb="12px">
                                            ${" "}
                                            {isNaN(customCredits)
                                                ? parseFloat(0).toLocaleString(todos.amountFormat, {
                                                      minimumFractionDigits: 2,
                                                  })
                                                : parseFloat(customCredits * singleCreditsCost).toLocaleString(
                                                      todos.amountFormat,
                                                      {
                                                          minimumFractionDigits: 2,
                                                      }
                                                  )}
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            justifyContent="start"
                                            alignItems="center"
                                            spacing={1.5}
                                            sx={{
                                                backgroundColor: "white",
                                                px: 1,
                                                py: 1,
                                                borderRadius: 2,
                                                width: 150,
                                            }}
                                        >
                                            <IconButton
                                                sx={{
                                                    backgroundColor:
                                                        customCredits === 0 || isNaN(customCredits)
                                                            ? "rgba(19, 31, 62, 0.13)"
                                                            : "rgba(19, 31, 62, 0.33)",
                                                    cursor:
                                                        customCredits === 0 || isNaN(customCredits)
                                                            ? "default"
                                                            : "pointer",
                                                    "&:hover": {
                                                        backgroundColor:
                                                            customCredits === 0 || isNaN(customCredits)
                                                                ? "rgba(19, 31, 62, 0.13)"
                                                                : "rgba(19, 31, 62, 0.33)",
                                                    },
                                                }}
                                                size="small"
                                                onClick={() => handleCustomCreditsSelect(-1)}
                                            >
                                                <RemoveIcon sx={{ color: "#FFF" }} fontSize="inherit" />
                                            </IconButton>
                                            <TextField
                                                inputProps={{ min: 1, style: { textAlign: "center" } }} // the change is here
                                                hiddenLabel
                                                value={customCredits}
                                                onChange={handleCustomCreditsSelectInput}
                                                variant="standard"
                                                type="number"
                                                size="small"
                                                sx={{
                                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            display: "none",
                                                        },
                                                }}
                                            />
                                            <IconButton
                                                sx={{
                                                    backgroundColor: "rgba(19, 31, 62, 0.33)",
                                                    "&:hover": {
                                                        backgroundColor: "rgba(19, 31, 62, 0.33)",
                                                    },
                                                }}
                                                size="small"
                                                onClick={() => handleCustomCreditsSelect(1)}
                                            >
                                                <AddIcon sx={{ color: "#FFF" }} fontSize="inherit" />
                                            </IconButton>
                                        </Stack>
                                    </>
                                ) : null}
                                {idealPlan.subscription_plan !== 0 && idealPlan.extra_credits !== 0 && (
                                    <>
                                        <Typography variant="h2" pt="12px">
                                            {`${t("plans.totalConv")}: ${customCredits + mainTitlePlan}`}
                                        </Typography>
                                        <Typography variant="h2" pb="12px">
                                            Total: $
                                            {parseFloat(totalAmount).toLocaleString(todos.amountFormat, {
                                                minimumFractionDigits: 2,
                                            })}
                                        </Typography>
                                    </>
                                )}
                                <Button
                                    variant="contained"
                                    sx={{ width: 200, mt: 2 }}
                                    disableElevation
                                    onClick={idealPlan.subscription_plan === currentPlan && idealPlan.extra_credits === 0 ? () => handleClose() : () => handlePlanSelect()}
                                >
                                    {idealPlan.subscription_plan === currentPlan && idealPlan.extra_credits === 0 ? t("plans.active"): t("dialog.select")}
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                </Grid>
            </Grid>
            <Button onClick={handleClose} sx={{ mt: 1 }}>
                {t("miscellaneous.goBack")}
            </Button>
            <SimpleBackdrop open={isLoading} />
        </Box>
    );
};

export default CustomPlan;
