import { useTranslation } from "react-i18next";

import {
    Box,
    Skeleton,
    styled,
    Typography,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    tableCellClasses,
    TableBody,
    TablePagination,
    Stack,
    Button
} from "@mui/material";

import XeroLogo from "../../../assets/xero.svg";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

import { useState } from "react";

const rowsSkeleton = [];

for (let i = 0; i < 4; i++) {
    rowsSkeleton.push(<Skeleton variant="rounded" width="100%" height={100} />);
}

const greyBoxStyles = {
    backgroundColor: "#F8F8F9",
    px: 3,
    py: 2,
    borderRadius: 4,
    mt: 2,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const ChecksSyncDetails = (props) => {
    const { detailsHist, checksStatus, handleGoBack, previewCheck } = props;

    const [t] = useTranslation("global");

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selected, setSelected] = useState([]);

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const columns = [
        //{ name: "id", title: "ID" },
        { name: "check", title: t("converter.check") },
        { name: "contact", title: t("converter.contact") },
        { name: "error", title: t("converter.errorType") },
    ];

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = detailsHist.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - detailsHist.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
         <Box
                sx={{
                    backgroundColor: checksStatus.failed === checksStatus.total ? "#FFD8D5" : "#DBF4E5",
                    px: 3,
                    py: 2,
                    borderRadius: 4,
                    mt: 2,
                }}
            >
                <Stack direction={{ xs: "column", md: "row" }} justifyContent={{ xs: "center", md: "space-between" }} spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={1} justifyContent={{ xs: "center" }}>
                        {!(checksStatus.failed === checksStatus.total) && <Typography variant="h3" color={checksStatus.success === checksStatus.total ? "#34bd6a" : "#ffb23f"}>{checksStatus.success}/{checksStatus.total}</Typography>}
                        <Typography variant="h3">
                            {checksStatus.failed === checksStatus.total ? (
                                    t("converter.bannerErr")
                                ) : (
                                    t("converter.checksSyncSucc")
                                )
                            }
                        </Typography>
                        <Box
                            component="img"
                            sx={{
                                height: 20,
                                width: 20,
                            }}
                            src={XeroLogo}
                        />
                    </Stack>
                    <Stack direction={{ xs: "column", md: "row" }} alignItems="center" spacing={{ xs: 2, md: 4 }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            sx={{
                                backgroundColor: checksStatus.failed === checksStatus.total ? "#FFE1E6" : "#E4F7EB",
                                borderRadius: 3,
                                px: 2,
                                py: 1,
                            }}
                        >
                            {checksStatus.success === checksStatus.total ? (
                                <CheckIcon color="success" />
                            ) : checksStatus.failed === checksStatus.total ? (
                                <CloseIcon color="error" />
                            ) : (
                                <ErrorIcon color="warning" />
                            )}
                            <Typography>
                                {checksStatus.success === checksStatus.total ? (
                                    t("converter.bannerCheck")
                                ) : checksStatus.failed === checksStatus.total ? (
                                    t("converter.bannerError")
                                ) : (
                                    t("converter.bannerWarning")
                                )}
                            </Typography>
                        </Stack>
                        <Button variant="contained" disableElevation sx={{ px: 4.5, py: 1.2}} onClick={handleGoBack}>
                            {t("dialog.finally")}
                        </Button>
                    </Stack>
                </Stack>
            </Box>
            <Box sx={greyBoxStyles}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h1" color="primary">{t("converter.checksNotSync")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table sx={{ borderCollapse: "separate", borderSpacing: "0 6px", minWidth: 1300 }}>
                                <TableHead>
                                    <TableRow sx={{ "& th": { border: "none" } }}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                indeterminate={selected.length > 0 && selected.length < detailsHist.length}
                                                checked={detailsHist.length > 0 && selected.length === detailsHist.length}
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        {columns.map((headCell) => (
                                            <StyledTableCell
                                                key={headCell.name}
                                                align={headCell.align ?? "left"}
                                                style={{ width: headCell.width }}
                                            >
                                                {headCell.title}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {detailsHist.length > 0 ? detailsHist.map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <StyledTableRow
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            "aria-labelledby": labelId,
                                                        }}
                                                        onClick={() => handleClick(row.id)}
                                                    />
                                                </TableCell>
                                                <StyledTableCell><Button onClick={() => previewCheck(row.uploaded_file_s3, row.check_id)}>{row.check}</Button></StyledTableCell>
                                                <StyledTableCell>{row.contact}</StyledTableCell>
                                                <StyledTableCell>{row.error}</StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    }) : <StyledTableRow>
                                            <TableCell colSpan={6}>{t("converter.noItems")}</TableCell>
                                        </StyledTableRow>}
                                    {emptyRows > 0 && (
                                        <StyledTableRow>
                                            <TableCell colSpan={6} />
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, { label: t("miscellaneous.all"), value: -1 }]}
                            component="div"
                            count={detailsHist.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage={t("dashboard.rowsPerPage")}
                            labelDisplayedRows={(page) =>
                                `${page.from}-${page.to === -1 ? page.count : page.to} ${t("team.unlinkText2")} ${page.count}`
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ChecksSyncDetails;
