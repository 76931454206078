import axios from "axios";

/* Script que contiene las llamadas a la API */
const paymentMethods = {
    // Petición para obtener métodos de pago.
    getPaymentMethods: (params) => {
        const request = axios.get("/getPaymentMethods", {
            params: params,
        });

        return request.then((response) => response.data);
    },
    // Llamada para obtener la key publica
    getConfigStripe: () => {
        const request = axios.get(`/config`);

        return request.then((response) => response.data);
    },
    // Llamada para crear el pago automatico
    createPaymentIntent: (params) => {
        const request = axios.post("/createPaymentIntent", params);

        return request.then((response) => response.data);
    },
    // Llamada para guardar una tarjeta en Stripe
    addPaymentMethod: (params) => {
        const request = axios.post("/addPaymentMethod", params);

        return request.then((response) => response);
    },
    // Llamada para reembolsar pago
    refundPayment: (params) => {
        const request = axios.post("/refundPayment", params);

        return request.then((response) => response);
    },
    // Llamada para editar un método de pago.
    updatePaymentMethod: (params) => {
        const request = axios.post("/updatePaymentMethod", params);

        return request.then((response) => response);
    },
    // Llamada para eliminar un método de pago.
    deletePaymentMethod: (params) => {
        const request = axios.post("/deletePaymentMethod", params);

        return request.then((response) => response);
    },
    // Llamada para cobrar a una tarjeta guardada.
    chargeSavedCard: (params) => {
        const request = axios.post("/chargeSavedCard", params);

        return request.then((response) => response);
    },
    // Llamada para cambiar metodo de pago predeterminado
    updateDefaultMethod: (params) => {
        const request = axios.post("/updateDefaultMethod", params);

        return request.then((response) => response);
    },
    // Llamada para cobrar una factura nuevamente
    retryPayment: (params) => {
        const request = axios.post("/retryPayment", params);

        return request.then((response) => response);
    },
    // Llamada para notificar un pago manual fallido
    sendEmailFailedPayment: (params) => {
        const request = axios.post("/sendEmailFailedPayment", params);

        return request.then((response) => response);
    },
};

export default paymentMethods;
