/** React imports */
import React from "react";
import introJs from "intro.js";

/** React-Redux */
import { useDispatch, useSelector } from "react-redux";
import { addCompanyID, addFranchiseID, addTutorial } from "../../redux/userSlice";

/** MUI imports */
import { Stack, Breadcrumbs, Typography, IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import classes from "./DashboardBreadcrumbs.module.css";
import { useTranslation } from "react-i18next";
import HelpIcon from "@mui/icons-material/Help";
import LightTooltip from "../ui/LightTooltip";

const DashboardBreadcrumbs = ({ franchise, isGrouped, company, noPadding }) => {
    /** Redux */
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const breadcrumbs = [];

    const handleClickFranchise = () => {
        dispatch(addFranchiseID(franchise._id));
        dispatch(addCompanyID(null));
    };

    const openTutorial = () => {
        const defaultTut = {
            step1: false,
            step2: false,
            step4: false,
            step5: false,
            step6: false,
            step7: false,
            step8: false,
            step9: false,
        };

        let isDone = false;

        dispatch(addTutorial(defaultTut));

        introJs()
            .setOptions({
                disableInteraction: true,
                exitOnOverlayClick: false,
                keyboardNavigation: false,
                nextLabel: t("dialog.continue"),
                prevLabel: t("miscellaneous.goBack"),
                doneLabel: t("miscellaneous.accept"),
                steps: [
                    {
                        element: "#navbar-tutorial",
                        title: `${t("miscellaneous.step")} 1`,
                        intro: t("miscellaneous.tutorialNav1"),
                    },
                    {
                        element: "#navbar-tutorial2",
                        title: `${t("miscellaneous.step")} 1`,
                        intro: t("miscellaneous.tutorialNav2"),
                    },
                    {
                        element: "#navbar-tutorial3",
                        title: `${t("miscellaneous.step")} 1`,
                        intro: t("miscellaneous.tutorialNav3"),
                    },
                    {
                        element: "#navbar-tutorial4",
                        title: `${t("miscellaneous.step")} 1`,
                        intro: t("miscellaneous.tutorialNav4"),
                    },
                    {
                        element: "#navbar-tutorial5",
                        title: `${t("miscellaneous.step")} 1`,
                        intro: t("miscellaneous.tutorialNav5"),
                    },
                    {
                        element: "#navbar-tutorial6",
                        title: `${t("miscellaneous.step")} 1`,
                        intro: t("miscellaneous.tutorialNav6"),
                    },
                    {
                        element: "#navbar-tutorial7",
                        title: `${t("miscellaneous.step")} 1`,
                        intro: t("miscellaneous.tutorialNav7"),
                    },
                ],
            })
            .oncomplete(() => {
                isDone = true;
                dispatch(addTutorial({ ...todos.steps, step2: true }));
            })
            .onexit(() => {
                if(!isDone){
                    dispatch(addTutorial(defaultTut));
                }
            })
            .start();
    };

    if (!isGrouped) {
        if (franchise?.franchise_name) {
            breadcrumbs.push(
                <Typography
                    key="1"
                    variant="h1"
                    color={!company ? "primary" : "#131F3E"}
                    className={classes.breadcrumbs}
                    onClick={noPadding ? null : handleClickFranchise}
                    sx={{ cursor: noPadding ? "text" : "pointer" }}
                >
                    {franchise.franchise_name}
                </Typography>
            );
        }
        if (company) {
            breadcrumbs.push(
                <Typography key="2" variant="h1" color="primary" className={classes.breadcrumbs}>
                    {company.company_name}
                </Typography>
            );
        }
    }

    return (
        <Stack direction="row" sx={{ pb: noPadding ? 0 : 2, mt: 2 }} alignItems="center" spacing={1}>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
            {company && (
                <LightTooltip title={t("miscellaneous.help")}>
                    <IconButton onClick={openTutorial}>
                        <HelpIcon color="primary" />
                    </IconButton>
                </LightTooltip>
            )}
        </Stack>
    );
};

export default DashboardBreadcrumbs;
