import axios from "axios";

/* Script que contiene las llamadas a la API */
const compVision = {

  checksParser: (params) => {
    const request = axios.post("/checksParser", params);

    return request.then((response) => response);
  },

  invoicesParser: (params) => {
    const request = axios.post("/invoicesParser", params);

    return request.then((response) => response);
  },

  getCustomModelsStatements: () => {
    const request = axios.get("/getCustomModelsStatements");

    return request.then((response) => response.data);
  },

  analyzeBankStatements: (params) => {
    const request = axios.post("/analyzeBankStatements", params);

    return request.then((response) => response.data);
  },

  downloadAIConversion: (params) => {
    const request = axios.get("/downloadAIConversion", { params: params });

    return request.then((response) => response.data);
  },

  downloadCheckConversion: (params) => {
    const request = axios.get("/downloadCheckConversion", { params: params });

    return request.then((response) => response.data);
  },

};

export default compVision;
