import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const UpdateTemporaryForm = (props) => {

    const { downgradePlan, usersFranq, userTemp, setUserTemp, invFranq, userTemp2, setUserTemp2 } = props

    const [t] = useTranslation("global");

    const handleChange = (e, item) => {
        if (e.target.checked) {
            let final = userTemp.concat([item])
            setUserTemp(final)
        } else {
            let final = userTemp.filter((i) => i !== item)
            setUserTemp(final)
        }
    }

    const handleChange2 = (e, item) => {
        if (e.target.checked) {
            let final = userTemp2.concat([item])
            setUserTemp2(final)
        } else {
            let final = userTemp2.filter((i) => i !== item)
            setUserTemp2(final)
        }
    }

    return (
        <Box sx={{ ml: 1 }}>
            <Typography fontSize={14}>
                {t("subscription.messageUpdateForm")}{" "}
                <Typography component="span" fontSize={14} fontWeight={600}>
                    {downgradePlan.planTitle}{" "}
                </Typography>
                <Typography component="span" fontSize={14}>
                    {t("subscription.messageUpdateForm1")}{" "}
                </Typography>
                <Typography component="span" fontSize={14} fontWeight={600}>
                    {downgradePlan.users}
                </Typography>
                <Typography component="span" fontSize={14}>
                    {` ${downgradePlan.users === 1 ? t("subscription.messageUser") : t("subscription.messageUsers")}. `}
                </Typography>
                {(usersFranq.length + invFranq.length) - downgradePlan.users > 0 ? <>
                    <Typography component="span" fontSize={14}>
                        {t("subscription.messageUpdateForm3")}{" "}
                    </Typography>
                    <Typography component="span" fontSize={14} fontWeight={600}>
                        {(usersFranq.length + invFranq.length) - downgradePlan.users}{" "}
                    </Typography>
                    <Typography component="span" fontSize={14}>
                        {((usersFranq.length + invFranq.length) - downgradePlan.users) === 1 ? t("subscription.messageUpdateForm4B") : t("subscription.messageUpdateForm4")}{". "}
                    </Typography>
                    <br />
                    <Typography component="span" fontSize={14}>
                        {((usersFranq.length + invFranq.length) - downgradePlan.users) === 1 ? t("subscription.messageUpdateForm2B") : t("subscription.messageUpdateForm2")}{" "}
                    </Typography>
                </> : <>
                <Typography component="span" fontSize={14}>
                        {t("subscription.messageUpdateForm0")}{" "}
                    </Typography>
                </>}
            </Typography>
            <FormGroup sx={{ mt: 1 }}>
                {usersFranq.filter((item) => item.permission !== 1).map((item, index) => (
                    <FormControlLabel
                        key={"checkUser_" + index}
                        control={
                            <Checkbox checked={userTemp.includes(item._id)} onChange={(e) => handleChange(e, item._id)} />
                        }
                        label={<Typography fontSize={14}>{item.user_name} ({item.email})</Typography>}
                    />
                ))}
                {invFranq.filter((item) => item.permission !== 1).map((item, index) => (
                    <FormControlLabel
                        key={"checkInv_" + index}
                        control={
                            <Checkbox checked={userTemp2.includes(item._id)} onChange={(e) => handleChange2(e, item._id)} />
                        }
                        label={<Typography fontSize={14}>{item.user_name} ({item.email})</Typography>}
                    />
                ))}
            </FormGroup>
        </Box>
    );
};

export default UpdateTemporaryForm;
