/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI icons */
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/es";
import subscriptionService from "../../services/subscription";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

/** MUI imports */
import {
    Button,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    FilledInput,
    MenuItem,
    IconButton,
} from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import FiltersPopover from "../ui/FiltersPopover";

const defaultFilter = {
    filtersArray: [
        {
            type: "",
            value: "",
            since: "",
            until: "",
        },
    ],
};

const FiltersButton = (props) => {
    const { franchise, company, rowsSkeleton, setRows, handleOpenBill, payments } = props;
    const [t] = useTranslation("global");

    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useState(defaultFilter);
    const todos = useSelector((state) => state.value);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const attributes = [
        { id: 1, name: t("payment.description"), query: "concept" },
        { id: 2, name: t("payment.period"), query: "period" },
        { id: 3, name: t("payment.date"), query: "date" },
        { id: 4, name: t("payment.paymentMethod"), query: "payment" },
    ];

    const concepts = [
        { id: 1, name: t("subscription.activation") },
        { id: 2, name: t("subscription.renewal") },
        { id: 3, name: t("payment.update") },
        { id: 4, name: t("credits.buySingleCred").charAt(0).toUpperCase() + t("credits.buySingleCred").slice(1) },
    ];

    const limit = 2;

    const plansInfo = [
        {
            planId: 1,
            credits: 5,
        },
        {
            planId: 2,
            credits: 15,
        },
        {
            planId: 3,
            credits: 30,
        },
        {
            planId: 4,
            credits: 50,
        },
        {
            planId: 5,
            credits: 200,
        },
        {
            planId: 6,
            credits: 500,
        },
    ];

    const addFilter = () => {
        let array = [...filters.filtersArray];
        if (array.length <= limit) {
            setFilters({
                ...filters,
                filtersArray: [
                    ...filters.filtersArray,
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                    },
                ],
            });
        }
    };

    const getConcept = (concept) => {
        let finalConcept = [];
        for (let i = 0; i < concept.length; i++) {
            if (concept[i].type === 1) {
                finalConcept.push(
                    t("subscription.activation") +
                    " Plan " +
                    plansInfo.find((item) => item.planId === concept[i].value).credits
                );
            } else if (concept[i].type === 2) {
                finalConcept.push(
                    t("subscription.renewal") +
                    " Plan " +
                    plansInfo.find((item) => item.planId === concept[i].value).credits
                );
            } else if (concept[i].type === 3 || concept[i].type === 4) {
                finalConcept.push(
                    t("payment.update") + " Plan " + plansInfo.find((item) => item.planId === concept[i].value).credits
                );
            } else {
                finalConcept.push(
                    t("credits.buySingleCred").charAt(0).toUpperCase() +
                    t("credits.buySingleCred").slice(1) +
                    " " +
                    concept[i].value
                );
            }
        }
        return finalConcept.join(", ");
    };

    const getPeriod = (invoice) => {
        let finalPeriod = "";
        let getAllTypes = invoice.invoice_description.map((item) => item.type)

        if (getAllTypes.some(i => [1, 2, 3, 4].includes(i))) {
            finalPeriod = moment(invoice.since_date, "YYYY-MM-DD").format(todos.dateFormat) +
                " - " + moment(invoice.end_date, "YYYY-MM-DD").format(todos.dateFormat);
        } else {
            finalPeriod = moment(invoice.creation_date, "YYYY-MM-DD").format(todos.dateFormat);
        }

        return finalPeriod;
    };


    const removeFilter = async (index) => {
        let array = [...filters.filtersArray];

        if (index !== -1) {
            array.splice(index, 1);
            setFilters({
                ...filters,
                filtersArray: array,
            });

            setRows(rowsSkeleton);
            let objectFinal = {};

            array.forEach((item) => {
                if (item.value && item.type) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
                }
                if (item.type && item.since) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                        item.since.format("YYYY-MM-DD");
                }
                if (item.type && item.until) {
                    objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                        item.until.format("YYYY-MM-DD");
                }
            });

            await subscriptionService
                .getInvoices({ franchise_id: franchise, ...objectFinal })
                .then((response) => {
                    const rowsData = response.map((item) => {
                        return {
                            id: item._id,
                            date: moment(item.payment_date, "YYYY-MM-DD").format(todos.dateFormat),
                            number: <Button onClick={() => handleOpenBill(item._id)}>{item.invoice_number}</Button>,
                            concept: item.invoice_description !== "" ? getConcept(item.invoice_description) : "",
                            period:
                                item.invoice_description !== ""
                                    ? getPeriod(item)
                                    : moment(item.creation_date, "YYYY-MM-DD").format(todos.dateFormat),
                            card:
                                item.brand && item.card_number
                                    ? `${item.brand ? item.brand.charAt(0).toUpperCase() + item.brand.slice(1) : ""} ${item.card_number ? "*" + item.card_number.split("-").pop() : ""
                                    }`
                                    : t("payment.withoutInfo"),
                            download: (
                                <IconButton>
                                    <CloudDownloadIcon fontSize="small" color="primary" />
                                </IconButton>
                            ),
                        };
                    });
                    setRows(rowsData);
                })
                .catch((err) => console.log(err));
        }
    };

    const removeFirstFilter = async () => {
        setRows(rowsSkeleton);
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                    since: "",
                    until: "",
                },
            ],
        });

        await subscriptionService
            .getInvoices({ franchise_id: franchise })
            .then((response) => {
                const rowsData = response.map((item) => {
                    return {
                        id: item._id,
                        date: moment(item.payment_date, "YYYY-MM-DD").format(todos.dateFormat),
                        number: <Button onClick={() => handleOpenBill(item._id)}>{item.invoice_number}</Button>,
                        concept: item.invoice_description !== "" ? getConcept(item.invoice_description) : "",
                        period:
                            item.invoice_description !== ""
                                ? getPeriod(item)
                                : moment(item.creation_date, "YYYY-MM-DD").format(todos.dateFormat),
                        card: `${item.brand ? item.brand.charAt(0).toUpperCase() + item.brand.slice(1) : ""} ${item.card_number ? "*" + item.card_number.slice(15, 19) : ""
                            }`,
                        download: (
                            <IconButton>
                                <CloudDownloadIcon fontSize="small" color="primary" />
                            </IconButton>
                        ),
                    };
                });
                setRows(rowsData);
            })
            .catch((err) => console.log(err));
    };

    const handleChangeType = (event, index) => {
        let aux = [...filters.filtersArray];
        aux[index].type = event.target.value;
        aux[index].value = "";
        aux[index].since = "";
        aux[index].until = "";

        setFilters({
            ...filters,
            filtersArray: aux,
        });
    };

    const handleChangeFilter = async (event, index, filterType) => {
        let aux = [...filters.filtersArray];

        aux[index].value = event.target.value;
        aux[index].since = "";
        aux[index].until = "";

        setRows(rowsSkeleton);

        let objectFinal = {};

        aux.forEach((item) => {
            if (item.value && item.type) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
            }
            if (item.type && item.since) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                    item.since.format("YYYY-MM-DD");
            }
            if (item.type && item.until) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                    item.until.format("YYYY-MM-DD");
            }
        });

        await subscriptionService
            .getInvoices({ franchise_id: franchise, ...objectFinal })
            .then((response) => {
                const rowsData = response.map((item) => {
                    return {
                        id: item._id,
                        date: moment(item.payment_date, "YYYY-MM-DD").format(todos.dateFormat),
                        number: <Button onClick={() => handleOpenBill(item._id)}>{item.invoice_number}</Button>,
                        concept: item.invoice_description !== "" ? getConcept(item.invoice_description) : "",
                        period:
                            item.invoice_description !== ""
                                ? getPeriod(item)
                                : moment(item.creation_date, "YYYY-MM-DD").format(todos.dateFormat),
                        card: `${item.brand ? item.brand.charAt(0).toUpperCase() + item.brand.slice(1) : ""} ${item.card_number ? "*" + item.card_number.slice(15, 19) : ""
                            }`,
                        download: (
                            <IconButton>
                                <CloudDownloadIcon fontSize="small" color="primary" />
                            </IconButton>
                        ),
                    };
                });
                setRows(rowsData);
            })
            .catch((err) => console.log(err));

        setFilters({
            ...filters,
            filtersArray: aux,
        });
    };

    const handleChangeFilterRange = async (event, index, range) => {
        let aux = [...filters.filtersArray];
        aux[index][range] = event;
        aux[index].value = "";

        let objectFinal = {};
        setRows(rowsSkeleton);

        aux.forEach((item) => {
            if (item.value && item.type) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
            }
            if (item.type && item.since) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                    item.since.format("YYYY-MM-DD");
            }
            if (item.type && item.until) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                    item.until.format("YYYY-MM-DD");
            }
        });

        await subscriptionService
            .getInvoices({ franchise_id: franchise, ...objectFinal })
            .then((response) => {
                const rowsData = response.map((item) => {
                    return {
                        id: item._id,
                        date: moment(item.payment_date, "YYYY-MM-DD").format(todos.dateFormat),
                        number: <Button onClick={() => handleOpenBill(item._id)}>{item.invoice_number}</Button>,
                        concept: item.invoice_description !== "" ? getConcept(item.invoice_description) : "",
                        period:
                            item.invoice_description !== ""
                                ? getPeriod(item)
                                : moment(item.creation_date, "YYYY-MM-DD").format(todos.dateFormat),
                        card: `${item.brand ? item.brand.charAt(0).toUpperCase() + item.brand.slice(1) : ""} ${item.card_number ? "*" + item.card_number.slice(15, 19) : ""
                            }`,
                        download: (
                            <IconButton>
                                <CloudDownloadIcon fontSize="small" color="primary" />
                            </IconButton>
                        ),
                    };
                });
                setRows(rowsData);
            })
            .catch((err) => console.log(err));

        setFilters({
            ...filters,
            filtersArray: aux,
        });

    };

    const cleanFilters = async (e) => {
        if (e.target.classList.contains("MuiAvatar-img")) {
            setFilters({
                filtersArray: [
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                    },
                ],
            });

            setRows(rowsSkeleton);

            await subscriptionService
                .getInvoices({ franchise_id: franchise })
                .then((response) => {
                    const rowsData = response.map((item) => {
                        return {
                            id: item._id,
                            date: moment(item.payment_date, "YYYY-MM-DD").format(todos.dateFormat),
                            number: <Button onClick={() => handleOpenBill(item._id)}>{item.invoice_number}</Button>,
                            concept: item.invoice_description !== "" ? getConcept(item.invoice_description) : "",
                            period:
                                item.invoice_description !== ""
                                    ? getPeriod(item)
                                    : moment(item.creation_date, "YYYY-MM-DD").format(todos.dateFormat),
                            card: `${item.brand ? item.brand.charAt(0).toUpperCase() + item.brand.slice(1) : ""} ${item.card_number ? "*" + item.card_number.slice(15, 19) : ""
                                }`,
                            download: (
                                <IconButton>
                                    <CloudDownloadIcon fontSize="small" color="primary" />
                                </IconButton>
                            ),
                        };
                    });
                    setRows(rowsData);
                })
                .catch((err) => console.log(err));
        }
    };

    useEffect(() => {
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                    since: "",
                    until: "",
                },
            ],
        });
    }, [company, franchise]);

    return (
        <FiltersPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            cleanFilters={cleanFilters}
            handleClick={handleClick}
            length={
                filters.filtersArray.filter(
                    (item) => item.type && (item.value || item.since || item.until)
                ).length
            }
            addFilter={addFilter}
            disabled={limit + 1}
        >
            {filters.filtersArray.map((value, index) => (
                <Grid container spacing={2} alignItems="center" key={index} sx={{ mb: 1 }}>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel
                                        id="clientStatus-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#131F3E",
                                        }}
                                    >
                                        {t("filters.attribute")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        IconComponent={(props) => {
                                            if (props.className.includes("MuiSelect-iconOpen")) {
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(270deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform: "rotate(90deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }}
                                        value={value.type}
                                        displayEmpty
                                        onChange={(e) => handleChangeType(e, index)}
                                        className="ddlGeneral"
                                        input={
                                            <FilledInput
                                                label={t("filters.attribute")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#131F3E",
                                                }}
                                            />
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {value.type !== ""
                                            ? attributes
                                                .filter(
                                                    (item) =>
                                                        !filters.filtersArray
                                                            .map((item) => item.type)
                                                            .includes(item.id)
                                                )
                                                .concat(
                                                    attributes.filter((item) => item.id === value.type)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.id} key={"attr_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))
                                            : attributes
                                                .filter(
                                                    (item) =>
                                                        !filters.filtersArray
                                                            .map((item) => item.type)
                                                            .includes(item.id)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.id} key={"attr_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {value.type === "" && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled" disabled>
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.type}
                                            displayEmpty
                                            onChange={(e) => handleChangeType(e, index)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        ></Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {(value.type === 1 || value.type === 4) && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        >
                                            {value.type === 1 &&
                                                concepts.map((item) => (
                                                    <MenuItem value={item.id} key={"concept_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                            {value.type === 4 &&
                                                payments.map((item) => (
                                                    <MenuItem value={item._id} key={"pay_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {`${item.brand.charAt(0).toUpperCase() +
                                                                item.brand.slice(1)
                                                                } ${item.card_number.split("-").pop()}`}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {(value.type === 2 || value.type === 3) && (
                                <>
                                    <Grid item xs={6} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <DatePicker
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    >
                                                        {t("filters.since")}
                                                    </Typography>
                                                }
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        style: { width: "100%" },
                                                        variant: "filled",
                                                        readOnly: true,
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                value={value.since !== "" ? value.since : null}
                                                onChange={(e) => handleChangeFilterRange(e, index, "since")}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid
                                        adapterLocale={t("language.locale")}
                                        localeText={{ clearButtonLabel: t("miscellaneous.clear") }} item xs={6} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <DatePicker
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    >
                                                        {t("filters.until")}
                                                    </Typography>
                                                }
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        style: { width: "100%" },
                                                        variant: "filled",
                                                        readOnly: true,
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                minDate={
                                                    filters.filtersArray[index]?.since !== ""
                                                        ? filters.filtersArray[index].since
                                                        : null
                                                }
                                                value={value.until !== "" ? value.until : null}
                                                onChange={(e) => handleChangeFilterRange(e, index, "until")}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={1} display="flex" justifyContent="center">
                        {filters.filtersArray.length > 1 && (
                            <DeleteIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() => removeFilter(index)}
                            />
                        )}
                        {filters.filtersArray.length === 1 &&
                            (value.value !== "" || value.since !== "" || value.until !== "") && (
                                <DeleteIcon
                                    color="primary"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => removeFirstFilter()}
                                />
                            )}
                    </Grid>
                </Grid>
            ))}
        </FiltersPopover>
    );
};

export default FiltersButton;
