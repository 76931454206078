import {
    Grid,
    TextField,
    Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const ReturnInvoiceContent = (props) => {
    const {emailReturn, setEmailReturn, errorReturn, setErrorReturn} = props;

    const [t] = useTranslation("global");

    const handleChangeCurrentEmail = async (event) => {
        setEmailReturn(event.target.value);
    };

    const handleChangeCurrentError = async (event) => {
        setErrorReturn(event.target.value);
    };

    return (
        <Grid container spacing={2} alignItems="center" mx={-2}>
            <Grid item xs={12}>
                <Typography textAlign="justify">{t("converter.returnInvoiceMsg2")}</Typography>
            </Grid> 
            <Grid item xs={12}>
                <CustomTextField
                    fullWidth
                    label={t("register.email")}
                    name="email"
                    onChange={(event) => handleChangeCurrentEmail(event)}
                    error={emailReturn !== "" && !/.+@.+\..+/.test(emailReturn)}
                    value={emailReturn}
                    variant="filled"
                    inputProps={{
                        fontStyle: "normal",
                    }}
                    type="text"
                />
            </Grid>
            <Grid item xs={12}>
                <CustomTextField
                    fullWidth
                    label={t("converter.errorDesc")}
                    name="errorDesc"
                    onChange={(event) => handleChangeCurrentError(event)}
                    value={errorReturn}
                    variant="filled"
                    inputProps={{
                        fontStyle: "normal",
                    }}
                    type="text"
                />
            </Grid>
        </Grid>
    );
};

export default ReturnInvoiceContent;
