import styled from "@emotion/styled";
import { Paper, Typography, Button, Stack, IconButton, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSelector } from "react-redux";

const ColorButton2 = styled(Button)(({ theme }) => ({
    letterSpacing: "-0.22px",
    color: "#FFF",
    fontStyle: "normal",
    fontWeight: "350",
    border: "1px solid #4A22D4",
    borderRadius: "6px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "4px",
    paddingBottom: "4px",
    "&:disabled": {
        backgroundColor: "#4A22D4",
        border: "1px solid #4A22D4",
        color: "#FFF",
    },
}));

const CustomCardCredit = (props) => {
    const { customCredits, handle, cost, handleAdd, cartItems, inCart, handleInput, noTotal } = props;
    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);

    return (
        <Paper
            elevation={3}
            square={false}
            sx={{
                width: "100%",
                minHeight: 116,
                transition: "transform 0.2s", // Add a transition for a smooth effect
                transform: cartItems.find((item) => item.line_type === 6) ? "scale(1.05)" : "scale(1.00)",
                "&:hover": {
                    transform: "scale(1.05)", // Scale the card on hover
                },
                position: "relative",
                "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%", // 1/2 del card
                    height: "20%",
                    opacity: "0.1",
                    //backgroundColor: data.colorTop, // Color de fondo
                    borderRadius: "0 0 50% 50%", // Hacer un semicírculo
                },
                py: 2.3,
                px: { xs: 2, md: 3 },
                borderRadius: 2.5,
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="column">
                    <Typography fontWeight={600} sx={{ pb: 1 }}>
                        {t("credits.chooseQuanty")}
                    </Typography>
                    <Stack
                        direction="row"
                        justifyContent="start"
                        alignItems="center"
                        spacing={1}
                        sx={{ backgroundColor: "rgba(19, 31, 62, 0.03)", px: 1, py: 1, borderRadius: 2, width: 170 }}
                    >
                        <IconButton
                            sx={{
                                backgroundColor:
                                    customCredits === 0 || isNaN(customCredits)
                                        ? "rgba(19, 31, 62, 0.13)"
                                        : "rgba(19, 31, 62, 0.33)",
                                cursor: customCredits === 0 || isNaN(customCredits) ? "default" : "pointer",
                                "&:hover": {
                                    backgroundColor:
                                        customCredits === 0 || isNaN(customCredits)
                                            ? "rgba(19, 31, 62, 0.13)"
                                            : "rgba(19, 31, 62, 0.33)",
                                },
                            }}
                            size="small"
                            onClick={() => handle(-1)}
                        >
                            <RemoveIcon sx={{ color: "#FFF" }} fontSize="inherit" />
                        </IconButton>
                        <TextField
                            inputProps={{ min: 1, style: { textAlign: "center" } }} // the change is here
                            hiddenLabel
                            value={customCredits}
                            onChange={handleInput}
                            variant="standard"
                            type="number"
                            size="small"
                            sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                },
                            }}
                        />
                        <IconButton
                            sx={{
                                backgroundColor: "rgba(19, 31, 62, 0.33)",
                                "&:hover": {
                                    backgroundColor: "rgba(19, 31, 62, 0.33)",
                                },
                            }}
                            size="small"
                            onClick={() => handle(1)}
                        >
                            <AddIcon sx={{ color: "#FFF" }} fontSize="inherit" />
                        </IconButton>
                    </Stack>
                </Stack>
                <Stack direction="column" justifyContent="center" textAlign="center">
                    {!noTotal && (
                        <>
                            {" "}
                            <Typography fontSize={13} color="primary" fontWeight={500} sx={{ mb: -0.7 }}>
                                ${" "}
                                {parseFloat(cost).toLocaleString(todos.amountFormat, {
                                    minimumFractionDigits: 2,
                                })}{" "}
                                {t("credits.per")}
                            </Typography>{" "}
                            <Typography fontSize={16} color="primary" fontWeight={700}>
                                ${" "}
                                {isNaN(customCredits)
                                    ? parseFloat(0).toLocaleString(todos.amountFormat, {
                                          minimumFractionDigits: 2,
                                      })
                                    : parseFloat(customCredits * cost).toLocaleString(todos.amountFormat, {
                                          minimumFractionDigits: 2,
                                      })}
                            </Typography>
                        </>
                    )}
                    {noTotal && (
                        <Typography fontSize={16} color="primary" fontWeight={600}>
                            ${" "}
                            {parseFloat(cost).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                            })}
                        </Typography>
                    )}
                    <ColorButton2
                        variant="contained"
                        onClick={handleAdd}
                        color="primary"
                        disabled={inCart}
                        sx={{ whiteSpace: "nowrap" }}
                    >
                        {inCart ? (
                            <>
                                {t("plans.inCart")} <ShoppingCartIcon sx={{ ml: 0.5 }} fontSize="inherit" />
                            </>
                        ) : (
                            t("credits.add")
                        )}
                    </ColorButton2>
                </Stack>
            </Stack>
        </Paper>
    );
};

export default CustomCardCredit;
