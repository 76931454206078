import { useTranslation } from "react-i18next";

/* Componentes */
import { List, ListItem, ListItemText, Stack, Typography, Grid, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

/* Estilos */
import classes from "./HistoryList.module.css";

/* Iconos */
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import teamService from "../../services/team";
import moment from "moment";
import { useSelector } from "react-redux";
import LightTooltip from "../ui/LightTooltip";
import { useEffect, useState } from "react";

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    backgroundColor: "#f7f8f9",
    "&:before": {
        // Esto es para quitar la línea horizontal por defecto en el encabezado del Accordion
        display: "none",
    },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    "& .MuiAccordionSummary-content": {
        marginLeft: 0,
    },
    "&:hover": {
        background: "rgba(19, 31, 62, 0.05)",
    },
    borderRadius: 15,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
}));

const HistoryList = (props) => {
    const { setIsLoading, setTexto, setAlertType, setShow, invitations, setReload, usersCounter, subscription } = props;

    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    const todos = useSelector((state) => state.value);

    const [usersQuantity, setUsersQuantity] = useState("");
    const [isDisabledAddTeam, setIsDisabledAddTeam] = useState(false);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));

    const reSendInv = async (histId, id) => {
        setIsLoading(true);

        const params = {
            history_id: histId,
            invitation_id: id,
            language: language,
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
        };

        await teamService
            .resendInvitation(params)
            .then(() => {
                setTexto(t("team.resend200"));
                setAlertType("success");
                setShow(true);
                setReload((prevState) => !prevState);
            })
            .catch((err) => {
                switch (err.response.status) {
                    case 404:
                        setTexto(t("team.resendError404"));
                        break;
                    default:
                        setTexto(t("team.resendError500"));
                        break;
                }
                setAlertType("error");
                setShow(true);
            });

        setIsLoading(false);
    };

    useEffect(() => {
        if (subscription) {
            switch (subscription.subscription_plan) {
                case 0:
                    setUsersQuantity(1);
                    break;
                case 1:
                    setUsersQuantity(1);
                    break;
                case 2:
                    setUsersQuantity(2);
                    break;
                case 3:
                    setUsersQuantity(3);
                    break;
                case 4:
                    setUsersQuantity(4);
                    break;
                case 5:
                    setUsersQuantity(5);
                    break;
                default:
                    setUsersQuantity("unlimited");
                    break;
            }
        }
    }, [subscription]);

    useEffect(() => {
        switch (usersQuantity) {
            case 1:
                setIsDisabledAddTeam(true);
                break;
            case 2:
                if (usersCounter >= 2) {
                    setIsDisabledAddTeam(true);
                } else {
                    setIsDisabledAddTeam(false);
                }
                break;
            case 3:
                if (usersCounter >= 3) {
                    setIsDisabledAddTeam(true);
                } else {
                    setIsDisabledAddTeam(false);
                }
                break;
            case 4:
                if (usersCounter >= 4) {
                    setIsDisabledAddTeam(true);
                } else {
                    setIsDisabledAddTeam(false);
                }
                break;
            case 5:
                if (usersCounter >= 5) {
                    setIsDisabledAddTeam(true);
                } else {
                    setIsDisabledAddTeam(false);
                }
                break;
            default:
                setIsDisabledAddTeam(false);
                break;
        }
    }, [usersQuantity, usersCounter]);

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h3" className={classes.title}>
                    {t("team.historyInv")}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List className={classes.list}>
                    {invitations.length > 0 ? (
                        invitations.map((item) => (
                            <ListItem
                                secondaryAction={
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Typography className={classes.statusInv}>
                                            {item.invite_status === 2 &&
                                            Math.ceil(
                                                moment(item.creation_date).add(7, "days").diff(moment(), "days", true)
                                            ) >= 0
                                                ? t("team.send")
                                                : item.invite_status === 3
                                                ? t("team.nulled")
                                                : item.invite_status === 5
                                                ? ""
                                                : Math.ceil(
                                                      moment(item.creation_date)
                                                          .add(7, "days")
                                                          .diff(moment(), "days", true)
                                                  ) < 0
                                                ? t("team.expiredInv")
                                                : t("miscellaneous.deleted")}
                                        </Typography>
                                        {(item.invite_status === 3 ||
                                            item.invite_status === 4 ||
                                            (Math.ceil(
                                                moment(item.creation_date).add(7, "days").diff(moment(), "days", true)
                                            ) <= 0 &&
                                                (!item.resend_date ||
                                                    Math.ceil(
                                                        moment(item.resend_date)
                                                            .add(7, "days")
                                                            .diff(moment(), "days", true)
                                                    ) <= 0))) && (
                                            <Button
                                                onClick={() => reSendInv(item._id, item.original_inv_id)}
                                                disabled={
                                                    isDisabledAddTeam ||
                                                    item.validate_resend ||
                                                    (todos.subscription.last_value === 1 &&
                                                        todos.subscription.value === 6)
                                                }
                                            >
                                                {t("team.resend")}
                                            </Button>
                                        )}
                                        {(item.invite_status === 1 || item.invite_status === 2) &&
                                            Math.ceil(
                                                moment(item.creation_date).add(7, "days").diff(moment(), "days", true)
                                            ) >= 0 && (
                                                <Typography className={classes.lastTime}>
                                                    {`${moment(item.creation_date).format(
                                                        `${todos.dateFormat} HH:mm`
                                                    )}`}
                                                </Typography>
                                            )}
                                        {item.invite_status === 5 && (
                                            <Typography className={classes.lastTime}>
                                                {`${t("team.resendDate")} ${moment(item.resend_date).format(
                                                    todos.dateFormat
                                                )} ${moment(item.resend_date).format("HH:mm")}`}
                                            </Typography>
                                        )}
                                    </Stack>
                                }
                                key={item._id}
                            >
                                <ListItemText
                                    primary={
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item>
                                                <Grid
                                                    container
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Grid item>
                                                        <Typography variant="h4">{item.invite_user_name}</Typography>
                                                    </Grid>
                                                    <Grid item>•</Grid>
                                                    <Grid item>
                                                        <Typography>{item.invite_email}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <LightTooltip
                                                    title={
                                                        item.user_status === 1
                                                            ? t("team.isRegister")
                                                            : t("team.noRegister")
                                                    }
                                                >
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={1}
                                                        className={
                                                            item.user_status === 1 ? classes.active : classes.new
                                                        }
                                                    >
                                                        <Typography>
                                                            {item.user_status === 1 ? t("team.active") : t("team.new")}
                                                        </Typography>
                                                        <CheckIcon
                                                            className={
                                                                item.user_status === 1
                                                                    ? classes.activeIcon
                                                                    : classes.newIcon
                                                            }
                                                        />
                                                    </Stack>
                                                </LightTooltip>
                                            </Grid>
                                        </Grid>
                                    }
                                />
                            </ListItem>
                        ))
                    ) : (
                        <Typography className={classes.lastTime} style={{ textAlign: "center", fontSize: "16px" }}>
                            {t("team.emptyHistoryInv")}
                        </Typography>
                    )}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

export default HistoryList;
