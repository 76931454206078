import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 20,
        padding: "20px 30px",
    },
    "& .MuiDialogTitle-root": {
        fontSize: 20,
        fontWeight: 600,
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
        justifyContent: "space-between",
    },
}));

const BootstrapDialogCust = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 20,
        padding: "20px 30px",
        width: 700,
    },
    "& .MuiDialogTitle-root": {
        fontSize: 16,
        fontWeight: 600,
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
        justifyContent: "space-between",
    },
}));

export default function FormDialog(props) {
    const { open, handleClose, title, content, actions, maxWidth, custom, align } = props;

    if (custom) {
        return (
            <BootstrapDialogCust open={open} onClose={handleClose} maxWidth={maxWidth}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>{content}</DialogContent>
                <DialogActions>{actions}</DialogActions>
            </BootstrapDialogCust>
        );
    }

    return (
        <BootstrapDialog open={open} onClose={handleClose} maxWidth={maxWidth} fullWidth>
            <DialogTitle sx={{ textAlign: align ? "center" : "left" }}>{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>{actions}</DialogActions>
        </BootstrapDialog>
    );
}
