import { FilledInput, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export default function CustomFilledSelect(props) {
    const { id, label, value, onChange, error, disabled, values, idName, valueName, required, name } = props;

    return (
        <FormControl
            fullWidth
            variant="filled"
            required={required}
            error={error}
            disabled={disabled ?? false}
        >
            <InputLabel
                sx={{
                    fontStyle: "normal",
                    fontWeight: "600",
                    color: "#131F3E",
                }}
            >
                {label}
            </InputLabel>
            <Select
                id={id}
                name={name}
                IconComponent={(props) => {
                    if (props.className.includes('MuiSelect-iconOpen')) {
                        return <PlayArrowIcon sx={{ position: "absolute", transform: "rotate(270deg)", color: "#131F3E", right: ".5rem", cursor: "pointer", zIndex: 0, pointerEvents: "none" }} />
                    }
                    return <PlayArrowIcon sx={{ position: "absolute", transform: "rotate(90deg)", color: "#131F3E", right: ".5rem", cursor: "pointer", zIndex: 0, pointerEvents: "none" }} />
                }}
                value={value}
                onChange={onChange}
                input={
                    <FilledInput
                        label={label}
                        sx={{
                            fontStyle: "normal",
                            color: "#131F3E",
                        }}
                    />
                }

                MenuProps={{
                    PaperProps: {
                        sx: {
                            maxHeight: 48 * 4.5 + 8,
                            mt: 0.7,
                            borderRadius: 2,
                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                        },
                    },
                }}
            >
                {values.map((item) => (
                    <MenuItem key={item[valueName] + "_" + item[idName]} value={item[idName]} sx={{display: item[idName] === "hidden" ? "none" : "block"}}>{item[valueName]}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
