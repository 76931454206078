const conversionFormats = [
	{
		id: 13,
		name: "Xero (.csv) - DD/MM/YYYY",
		name_en: "Xero (.csv) - DD/MM/YYYY"
	},
	{
		id: 14,
		name: "Xero (.csv) - MM/DD/YYYY",
		name_en: "Xero (.csv) - MM/DD/YYYY"
	},
	// {
	// 	id: 6,
	//     name: "Quickbooks Desktop"
	// },
	{
		id: 7,
		name: "Quickbooks Online (.csv)",
		name_en: "Quickbooks Online (.csv)"
	},
	{
		id: 15,
		name: "Quickbooks Online (.qbo)",
		name_en: "Quickbooks Online (.qbo)"
	},
	{
		id: 16,
		name: "Quickbooks Desktop (.qbo)",
		name_en: "Quickbooks Desktop (.qbo)",
	},
	{
		id: 17,
		name: "Odoo (.csv)",
		name_en: "Odoo (.csv)",
	},
	{
		id: 3,
		name: "Estándar (.csv)",
		name_en: "Standard (.csv)"
	},
	{
		id: 4,
		name: "Estándar (.xlsx)",
		name_en: "Standard (.xlsx)"
	},
	{
		id: 1,
		name: "Accounts IQ (.xml)",
		name_en: "Accounts IQ (.xml)"
	},
	{
		id: 2,
		name: "AccountsPrep (.csv)",
		name_en: "AccountsPrep (.csv)"
	},
	{
		id: 5,
		name: "Iris Accounts Production (.csv)",
		name_en: "Iris Accounts Production (.csv)"
	},
	{
		id: 8,
		name: "ReckonOne (.csv)",
		name_en: "ReckonOne (.csv)"
	},
	{
		id: 9,
		name: "Sage 50 (.csv)",
		name_en: "Sage 50 (.csv)"
	},
	{
		id: 10,
		name: "Sage Accounting (.csv)",
		name_en: "Sage Accounting (.csv)"
	},
	// {
	// 	id: 11,
	//     name: "Sage Accounting - US date (.csv)"
	// },
	{
		id: 12,
		name: "Twinfield (.csv)",
		name_en: "Twinfield (.csv)"
	},
]

export default conversionFormats;
