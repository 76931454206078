/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import {
    Dialog,
    DialogContent,
    Typography,
    Box
} from "@mui/material";

/* MUI Icons */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const DialogSaveNewEmail = (props) => {
    const { open, onClose } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        borderRadius: 8,
                        p: { xs: 3, md: "16px 0px 0px 0px" },
                        width: "700px"
                    },
                }}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <CheckCircleIcon sx={{ color: "rgba(0, 177, 71, 1)", fontSize: "100px", ml: 4, mr: 2, mb: 2 }} />
                        <Box sx={{ mt: 1.5 }}>
                            <Typography
                                fontStyle="normal"
                                fontSize={23}
                                fontWeight={600}
                            >
                                {t("register.confirmationLink")}
                            </Typography>
                            <Typography
                                fontStyle="normal"
                                fontSize={16}
                                fontWeight={500}
                                sx={{ maxWidth: "400px" }}
                            >
                                {t("register.confirmEmail")}
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DialogSaveNewEmail;
