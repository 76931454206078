import axios from "axios";

/* Script que contiene las llamadas a la API */
const user = {
    // Petición para obtener la información de un usuario.
    getUserData: (email) => {
        const request = axios.get(`/getUserData?user_email=${email}`);

        return request.then((response) => response.data);
    },

    // Petición para actualizar perfil de usuario.
    updateUserProfile: (params) => {
        const request = axios.post(`/updateUserProfile`, params);

        return request.then((response) => response.data);
    },

    // Petición para verificar contraseña.
    verifyPassword: (params) => {
        const request = axios.post(`/verifyPassword`, params);

        return request.then((response) => response);
    },

    // Petición para actualizar check de pregunta
    updateCheckAsk: (params) => {
        const request = axios.put(`/updateCheckAsk`, params);

        return request.then((response) => response);
    },
};

export default user;
