import axios from "axios";

/* Script que contiene las llamadas a la API */
const cases = {
    // Peticion para obtener las notificaciones
    getNotifications: (params) => {
        const request = axios.get("/getNotifications", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Peticion para leer todas las notificaciones
    readNotification: (params) => {
        const request = axios.post("/readNotification", params);

        return request.then((response) => response);
    },

    // Peticion para quitar lectura de la notificacion
    unreadNotification: (params) => {
        const request = axios.post("/unreadNotification", params);

        return request.then((response) => response);
    },

    // Peticion para crear una notificacion
    newNotification: (params) => {
        const request = axios.post("/newNotification", params);

        return request.then((response) => response);
    },
};

export default cases;
