import { useState } from "react";
import { useTranslation } from "react-i18next";
import authService from "../../services/auth";

/* Componentes */
import CustTextField from "../ui/CustTextField";
import BaseCard from "../ui/BaseCard";
import { Typography, Grid, Button, Paper, InputAdornment, Alert } from "@mui/material";

/* Estilos */
import classes from "./ForgotCard.module.css";

/** MUI icons */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const ForgotCard = (props) => {
    const { handleShowForgotCard, setTexto, setAlertType, setShow, setIsLoading } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    const [isError, setIsError] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [verified, setVerified] = useState(false);
    const [reseted, setReseted] = useState(false);
    const [emailVal, setEmailVal] = useState("");
    const [disabledButton, setDisabledButton] = useState(true);

    const handleBack = () => {
        setIsError(false);
        setIsValidEmail(false);
        setVerified(false);
        setReseted(false);
        setEmailVal("");
        handleShowForgotCard();
    };

    const resetPass = async () => {
        if (/.+@.+\..+/.test(emailVal)) {
            setIsLoading(true);

            const params = {
                user_email: emailVal,
                language: t("language.locale"),
            };

            await authService
                .forgotPassword(params)
                .then(() => {
                    setReseted(true);
                    setIsError(false);
                })
                .catch((err) => {
                    switch (err.response.status) {
                        case 404:
                            setTexto(t("login.loginError404"));
                            break;
                        default:
                            setTexto(t("login.resetError500"));
                            break;
                    }
                    setAlertType("error");
                    setShow(true);
                });

            setIsLoading(false);
        } else {
            setIsError(true);
        }
    };

    const handleChangeEmail = (event) => {
        setEmailVal(event.target.value);
        verifyEmail(event.target.value);
    };

    const verifyEmail = async (email) => {
        if (email.trim() && /.+@.+\..+/.test(email)) {
            await authService
                .verifyExistingUser(email)
                .then(() => {
                    setVerified(true);
                    setIsValidEmail(false);
                    setDisabledButton(false);
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 404:
                            setVerified(false);
                            setIsValidEmail(true);
                            setDisabledButton(true);
                            break;
                        default:
                            setDisabledButton(true);
                            setIsValidEmail(false);
                            break;
                    }
                });
        }
    };

    return (
        <BaseCard>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.title} gutterBottom>
                        {t("login.forgotText1")}
                    </Typography>
                </Grid>
                {!reseted ? (
                    <>
                        <Grid item xs={12}>
                            <CustTextField
                                id="outlined-email-reset"
                                label={t("login.email")}
                                error={isError || isValidEmail}
                                onChange={handleChangeEmail}
                                InputProps={{
                                    endAdornment:
                                        (emailVal.trim() && isValidEmail && (
                                            <InputAdornment position="end">
                                                <CancelIcon color="error" />
                                            </InputAdornment>
                                        )) ||
                                        (emailVal.trim() && !isValidEmail && verified && (
                                            <InputAdornment position="end">
                                                <CheckCircleIcon sx={{ color: "#00B147" }} />
                                            </InputAdornment>
                                        )),
                                }}
                                helperText={emailVal.trim() && isValidEmail && t("login.dontExist")}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.message}>
                                <Typography>{t("login.resetText")}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" fullWidth onClick={resetPass} disabled={disabledButton}>
                                {t("login.getLink")}
                            </Button>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Alert severity="success">
                                <Typography fontSize={14}>
                                    {t("login.sendTo")} <b>{emailVal}</b>
                                </Typography>
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.message}>
                                <Typography>{t("login.goToMail")}</Typography>
                            </Paper>
                        </Grid>
                    </>
                )}
                <Grid item>
                    <Button className={classes.backLogin} variant="text" onClick={handleBack}>
                        {t("login.backToLogin")}
                    </Button>
                </Grid>
            </Grid>
        </BaseCard>
    );
};

export default ForgotCard;
