import {
    Box,
    Card,
    Container,
    Grid,
    List,
    ListItem,
    ListItemButton,
    Skeleton,
    Stack,
    Typography,
    FormControlLabel,
    Switch,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import BarChartIcon from "@mui/icons-material/BarChart";
import CheckIcon from "@mui/icons-material/Check";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BiotechIcon from "@mui/icons-material/Biotech";
import ForumIcon from "@mui/icons-material/Forum";

import LightTooltip from "../ui/LightTooltip";
import { useEffect, useState } from "react";

import casesServices from "../../services/case";
import commServices from "../../services/communication";
import conversionService from "../../services/conversion";
import { initiateSocket, subscribeToChat, newUserResponse, emitMessage, disconnectSocket } from "../../services/Socket";

import { useSelector } from "react-redux";
import ChatBox from "./ChatBox";

import moment from "moment";

const rowsSkeleton = [];

for (let i = 0; i < 15; i++) {
    rowsSkeleton.push(<Skeleton variant="rounded" width="100%" height={100} />);
}

const ChatTab = (props) => {
    const { cases, setIsLoading, reloadCases } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const [open, setOpen] = useState(false);
    const [caseInfo, setCaseInfo] = useState({});
    const [chatInfo, setChatInfo] = useState({});
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [disabledTest, setDisabledTest] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [selectedCase, setSelectedCase] = useState({});
    const [fileLimit, setFileLimit] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [orderedCases, setOrderedCases] = useState(null);
    const [room, setRoom] = useState(null);
    const [checked, setChecked] = useState(false);

    const handleChangeSwitch = async (event) => {
        setChecked(event.target.checked);

        if (event.target.checked) {
            if (cases && cases.open) {
                const numberSortResolved = (a, b) => new Date(b.status_date) - new Date(a.status_date);

                const sortedCloseCases = cases.resolved.sort(numberSortResolved);

                setOrderedCases(sortedCloseCases);
            } else {
                setOrderedCases([]);
            }
        } else {
            if (cases && cases.open) {
                const numberSortOpen = (a, b) => new Date(b.lastMesTime) - new Date(a.lastMesTime);

                const newAllArr = cases.open.concat(cases.pending);
                let haveNewMess = [];
                let notHaveMess = [];

                for (const items of newAllArr) {
                    if (items.unread > 0) {
                        haveNewMess.push(items);
                    } else {
                        notHaveMess.push(items);
                    }
                }

                haveNewMess = haveNewMess.sort(numberSortOpen);
                notHaveMess = notHaveMess.sort(numberSortOpen);

                const sortedCases = haveNewMess.concat(notHaveMess);

                setOrderedCases(sortedCases);
            } else {
                setOrderedCases([]);
            }
        }
    };

    useEffect(() => {
        if (cases && cases.open) {
            if (checked) {
                const numberSortResolved = (a, b) => new Date(b.status_date) - new Date(a.status_date);

                const sortedCloseCases = cases.resolved.sort(numberSortResolved);

                setOrderedCases(sortedCloseCases);
            } else {
                const numberSortOpen = (a, b) => new Date(b.lastMesTime) - new Date(a.lastMesTime);

                const newAllArr = cases.open.concat(cases.pending);
                let haveNewMess = [];
                let notHaveMess = [];

                for (const items of newAllArr) {
                    if (items.unread > 0) {
                        haveNewMess.push(items);
                    } else {
                        notHaveMess.push(items);
                    }
                }

                haveNewMess = haveNewMess.sort(numberSortOpen);
                notHaveMess = notHaveMess.sort(numberSortOpen);

                const sortedCases = haveNewMess.concat(notHaveMess);

                setOrderedCases(sortedCases);
            }
        }
    }, [cases, checked]);

    const onClose = () => {
        setOpen(false);
        setSelectedFiles([]);
        setFileLimit(false);
        setShowButtons(false);
        if (room) {
            disconnectSocket(room, todos.userInfo, "");
            setRoom(null);
            setChat([]);
            setCaseInfo({});
        }
    };

    const convertTZ = (date, tzString) => {
        return new Date(
            (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString })
        );
    };

    const connectSocket = async (item) => {
        const { case_id, case_reason, conversion_id } = item;

        onClose();

        setSelectedCase(item);
        setIsLoading(true);
        setRoom(case_id);

        let params1 = {
            case_id: case_id,
        };

        await casesServices
            .getCaseInfo(params1)
            .then(async (data) => {
                setCaseInfo(data.caseInfo);
                setChatInfo(data.chatCase);

                initiateSocket(
                    case_id,
                    todos.userInfo,
                    case_reason === 1 && conversion_id ? "conversion" : "general",
                    ""
                );
                subscribeToChat((err, data) => {
                    if (err) return;
                    setChat((oldChats) => [...oldChats, data]);
                });
                newUserResponse((err, data) => {
                    if (err) return;
                    setUsers(data);
                });
                setOpen(true);
                await commServices
                    .updateStatusMess({
                        room: case_id,
                        user: todos.userInfo._id,
                    })
                    .then()
                    .catch((err) => console.log(err));
                reloadCases();
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const convert = async (e) => {
        e.preventDefault();
        setDisabledTest(true);

        let paramsMsg = {
            case_id: caseInfo._id,
            room: chatInfo._id,
            message:
                t("language.locale") === "es"
                    ? "El usuario " +
                      todos.userInfo.user_name +
                      " ha iniciado la prueba de conversión. Por favor, espera un momento. Estamos procesando la conversión."
                    : "User " +
                      todos.userInfo.user_name +
                      " has started the conversion test. Please wait a moment. We are processing the conversion.",
        };

        // Mensaje automatico del convertidor de pruebas
        await casesServices
            .botMessage(paramsMsg)
            .then(async (response) => {
                if (response.message) {
                    let messageId = response.message;
                    emitMessage(room, messageId);
                }
            })
            .catch(async (err) => {
                console.log(err);
            });

        let params = {
            case_id: caseInfo._id,
            lang: t("language.locale"),
        };

        await conversionService
            .testBankStatementChat(params)
            .then(async (response) => {
                if (response.message) {
                    let messageId = response.message;
                    emitMessage(room, messageId);
                }
                if (response.chatInfo) {
                    setChatInfo(response.chatInfo);
                }
            })
            .catch(async (err) => {
                if (err.response.data.message) {
                    let messageId = err.response.data.message;
                    emitMessage(room, messageId);
                }
                if (err.response.data.chatInfo) {
                    setChatInfo(err.response.data.chatInfo);
                }
            });
        setDisabledTest(false);
    };

    const development = async (e) => {
        e.preventDefault();
        setShowButtons(true);

        const params = {
            case_id: caseInfo._id,
            lang: t("language.locale"),
        };

        await casesServices
            .returnAdminCase(params)
            .then(async (data) => {
                if (data.caseInfo) {
                    setCaseInfo(data.caseInfo);
                }
                if (data.message) {
                    let messageId = data.message;
                    emitMessage(room, messageId);
                }
                if (data.chatInfo) {
                    setChatInfo(data.chatInfo);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const success = async (e) => {
        e.preventDefault();
        setShowButtons(true);

        let params1 = {
            case_id: room,
            lang: t("language.locale"),
        };

        await casesServices
            .approveAdminCase(params1)
            .then(async (data) => {
                if (data.caseInfo) {
                    setCaseInfo(data.caseInfo);
                }
                if (data.message) {
                    let messageId = data.message;
                    emitMessage(room, messageId);
                }
                if (data.chatInfo) {
                    setChatInfo(data.chatInfo);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Container maxWidth="xl" disableGutters>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Card
                        sx={{
                            borderRadius: 4,
                            backgroundColor: "#f9f8fd",
                            p: 2,
                        }}
                    >
                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mx: 2, my: 1 }}>
                            <Typography variant="h2" color="primary">
                                Chats de soporte
                            </Typography>
                            <Box>
                                <FormControlLabel
                                    sx={{ alignItems: "center" }}
                                    control={
                                        <Switch
                                            id="disabledAccounts"
                                            checked={checked}
                                            onChange={handleChangeSwitch}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    }
                                    label={
                                        <Typography fontWeight={600} fontSize={16} noWrap>
                                            Cerrados
                                        </Typography>
                                    }
                                />
                            </Box>
                        </Stack>
                        <List
                            sx={{
                                maxHeight: "60vh",
                                overflowY: "auto",
                                mb: 1,
                            }}
                        >
                            {!orderedCases
                                ? rowsSkeleton.map((item, index) => <ListItem key={index}>{item}</ListItem>)
                                : orderedCases.map((item) => (
                                      <ListItem key={item.case_id}>
                                          <ListItemButton
                                              sx={{
                                                  backgroundColor: room === item.case_id ? "#E5E0F9" : "white",
                                                  borderRadius: 3,
                                                  px: 2,
                                                  py: 2,
                                              }}
                                              onClick={() => connectSocket(item)}
                                          >
                                              <Grid container spacing={1}>
                                                  <Grid item xs={9}>
                                                      <Stack direction="row" spacing={1} alignItems="center">
                                                          {item.unread > 0 && (
                                                              <Box
                                                                  sx={{
                                                                      backgroundColor: "#4A22D4",
                                                                      color: "white",
                                                                      px: 1,
                                                                      borderRadius: 1,
                                                                  }}
                                                              >
                                                                  {item.unread}{" "}
                                                              </Box>
                                                          )}
                                                          <Box pl={item.unread > 0 ? 0 : 4}>
                                                              <Typography variant="h2" gutterBottom>
                                                                  {item.global_case_number}
                                                              </Typography>
                                                              <Typography variant="h2" gutterBottom>
                                                                  {item.franchise_name}
                                                              </Typography>
                                                              {item.lastMesTime && (
                                                                  <Typography>
                                                                      Último mensaje a las{" "}
                                                                      {moment(
                                                                          convertTZ(
                                                                              item.lastMesTime,
                                                                              "America/New_York"
                                                                          )
                                                                      ).format(`${todos.dateFormat} HH:mm`)}
                                                                  </Typography>
                                                              )}
                                                          </Box>
                                                      </Stack>
                                                  </Grid>
                                                  <Grid
                                                      item
                                                      xs={3}
                                                      sx={{ textAlign: "right", alignContent: "space-between" }}
                                                  >
                                                      <Stack
                                                          direction="column"
                                                          spacing={1}
                                                          justifyContent="space-between"
                                                          alignItems="end"
                                                          //alignContent="space-between"
                                                          sx={{ height: "100%" }}
                                                      >
                                                          <Typography lineHeight="1">
                                                              {item.case_reason === 1
                                                                  ? t("inbox.conversions")
                                                                  : item.case_reason === 2
                                                                  ? t("inbox.newBank")
                                                                  : t("inbox.directRequest")}
                                                          </Typography>
                                                          {item.case_status === 1 ? (
                                                              <LightTooltip title={t("inbox.analysis")}>
                                                                  <BarChartIcon
                                                                      sx={{ color: "#FFB23F" }}
                                                                      fontSize="medium"
                                                                  />
                                                              </LightTooltip>
                                                          ) : item.case_status === 2 ? (
                                                              <LightTooltip title={t("inbox.progress")}>
                                                                  <EngineeringIcon
                                                                      sx={{ color: "#3FA7D6" }}
                                                                      fontSize="medium"
                                                                  />
                                                              </LightTooltip>
                                                          ) : item.case_status === 3 ? (
                                                              <LightTooltip title={t("inbox.test")}>
                                                                  <BiotechIcon
                                                                      sx={{ color: "#005792" }}
                                                                      fontSize="medium"
                                                                  />
                                                              </LightTooltip>
                                                          ) : item.case_status === 4 ? (
                                                              <LightTooltip title={t("inbox.solvedKiiper")}>
                                                                  <CheckIcon
                                                                      sx={{ color: "#00C8C8" }}
                                                                      fontSize="medium"
                                                                  />
                                                              </LightTooltip>
                                                          ) : (
                                                              <LightTooltip title={t("inbox.solvedUser")}>
                                                                  <CheckIcon
                                                                      sx={{ color: "#00B147" }}
                                                                      fontSize="medium"
                                                                  />
                                                              </LightTooltip>
                                                          )}
                                                      </Stack>
                                                  </Grid>
                                              </Grid>
                                          </ListItemButton>
                                      </ListItem>
                                  ))}
                        </List>
                    </Card>
                </Grid>
                <Grid item xs={12} md={8}>
                    {open ? (
                        <ChatBox
                            room={room}
                            caseInfo={caseInfo}
                            users={users}
                            chatInfo={chatInfo}
                            chat={chat}
                            disabledTest={disabledTest}
                            convert={convert}
                            setIsLoading={setIsLoading}
                            showButtons={showButtons}
                            development={development}
                            success={success}
                            selectedCase={selectedCase}
                            fileLimit={fileLimit}
                            selectedFiles={selectedFiles}
                            setFileLimit={setFileLimit}
                            setSelectedFiles={setSelectedFiles}
                        />
                    ) : (
                        <Stack justifyContent="center" alignItems="center" sx={{ height: 600 }}>
                            <ForumIcon sx={{ fontSize: 100 }} color="disabled" />
                            <Typography>{t("inbox.clickChat")}</Typography>
                        </Stack>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default ChatTab;
