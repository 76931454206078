import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const DowngradePlanForm = (props) => {

    const { downgradePlan, usersFranq, checked, setChecked, getDueDate, invFranq, checked2, setChecked2 } = props

    const [t] = useTranslation("global");

    const handleChange = (e, item) => {
        if (e.target.checked) {
            let final = checked.concat([item])
            setChecked(final)
        } else {
            let final = checked.filter((i) => i !== item)
            setChecked(final)
        }
    }

    const handleChange2 = (e, item) => {
        if (e.target.checked) {
            let final = checked2.concat([item])
            setChecked2(final)
        } else {
            let final = checked2.filter((i) => i !== item)
            setChecked2(final)
        }
    }

    return (
        <Box sx={{ ml: 1 }}>
            <Typography fontSize={14}>
                {t("subscription.downgradeFormMsg2")}{" "}
                <Typography component="span" fontSize={14} fontWeight={600}>
                    Plan {downgradePlan.planType}{" "}
                </Typography>
                <Typography component="span" fontSize={14}>
                    {t("subscription.downgradeFormMsg3")}{" "}
                </Typography>
                <Typography component="span" fontSize={14} fontWeight={600}>
                    {downgradePlan.planData.users}{" "}
                </Typography>
                <Typography component="span" fontSize={14}>
                    {downgradePlan.planData.users === 1 ? t("subscription.downgradeFormMsg4B") : t("subscription.downgradeFormMsg4")}{" "}
                </Typography>
                <Typography component="span" fontSize={14}>
                    {(usersFranq.length + invFranq.length) - downgradePlan.planData.users === 1 ? t("subscription.downgradeFormMsg5B") : t("subscription.downgradeFormMsg5")}{" "}
                </Typography>
                {(usersFranq.length + invFranq.length) - downgradePlan.planData.users !== 1 &&
                    <Typography component="span" fontSize={14} fontWeight={600}>
                        {(usersFranq.length + invFranq.length) - downgradePlan.planData.users}{" "}
                    </Typography>
                }
                <Typography component="span" fontSize={14}>
                    {(usersFranq.length + invFranq.length) - downgradePlan.planData.users === 1 ? t("subscription.downgradeFormMsg6B") : t("subscription.downgradeFormMsg6")}{" "}
                </Typography>
                <Typography component="span" fontSize={14} fontWeight={600}>
                    {getDueDate}{"."}
                </Typography>
            </Typography>

            <FormGroup sx={{ mt: 1 }}>
                {usersFranq.filter((item) => item.permission !== 1).map((item, index) => (
                    <FormControlLabel
                        key={"checkUser_" + index}
                        control={
                            <Checkbox checked={checked.includes(item._id)} onChange={(e) => handleChange(e, item._id)} />
                        }
                        label={<Typography fontSize={14}>{item.user_name} ({item.email})</Typography>}
                    />
                ))}
                {invFranq.filter((item) => item.permission !== 1).map((item, index) => (
                    <FormControlLabel
                        key={"checkInv_" + index}
                        control={
                            <Checkbox checked={checked2.includes(item._id)} onChange={(e) => handleChange2(e, item._id)} />
                        }
                        label={<Typography fontSize={14}>{item.user_name} ({item.email})</Typography>}
                    />
                ))}
            </FormGroup>
        </Box>
    );
};

export default DowngradePlanForm;
