import {
    Box,
    Button,
    Link,
    Stack,
    Typography,
    Skeleton,
    Grid,
    FormControlLabel,
    Switch,
    IconButton,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import notificationsService from "../../services/notification";
import CommuIcon from "../../assets/Communication.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LightTooltip from "../ui/LightTooltip";
import { useDispatch, useSelector } from "react-redux";
import { addInvisibleNoti, addResetDash } from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        notification: <Skeleton variant="text" animation="wave" width="100%" />,
    });
}

const AdminNotification = (props) => {
    const {
        user,
        notif,
        setNotif,
        setShow,
        setTexto,
        setAlertType,
        showSkeleton,
        setShowSkeleton,
        setIsLoading,
        checked,
        setChecked,
        groupByDate,
    } = props;

    const [t] = useTranslation("global");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const todos = useSelector((state) => state.value);

    const handleMark = async () => {
        let params = {
            email: user.email,
            read_all: true,
        };

        setIsLoading(true);
        setShowSkeleton(true);

        await notificationsService
            .readNotification(params)
            .then(async () => {
                await notificationsService
                    .getNotifications()
                    .then((data) => {
                        let hasNoti = false;
                        let newArr = [];

                        for (const item of data) {
                            if (!item.notification_status.find((item) => item.email === user.email)?.read) {
                                hasNoti = true;
                                if (!checked) {
                                    newArr.push(item);
                                }
                            }
                        }
                        if (checked) {
                            newArr = data;
                        }

                        dispatch(addInvisibleNoti(!hasNoti));

                        const groupedData = groupByDate(newArr);
                        setNotif(groupedData);
                        setAlertType("success");
                        setTexto(t("inbox.markedReadMultiple"));
                        setShow(true);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
        setShowSkeleton(false);
    };

    const goToSupp = () => {
        dispatch(addResetDash({ all: false, supp: true }));
        navigate("/inbox");
    };

    const markUnRead = async (id) => {
        setIsLoading(true);

        let params = {
            email: user.email,
            notification_id: id,
        };

        await notificationsService
            .unreadNotification(params)
            .then(async () => {
                await notificationsService
                    .getNotifications()
                    .then((data) => {
                        let hasNoti = false;
                        let newArr = [];

                        for (const item of data) {
                            if (!item.notification_status.find((item) => item.email === user.email)?.read) {
                                hasNoti = true;
                                if (!checked) {
                                    newArr.push(item);
                                }
                            }
                        }
                        if (checked) {
                            newArr = data;
                        }

                        dispatch(addInvisibleNoti(!hasNoti));

                        const groupedData = groupByDate(newArr);
                        setNotif(groupedData);
                        setAlertType("success");
                        setTexto(t("inbox.markedUnRead"));
                        setShow(true);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const markRead = async (id) => {
        setIsLoading(true);

        let params = {
            email: user.email,
            notification_id: id,
        };

        await notificationsService
            .readNotification(params)
            .then(async () => {
                await notificationsService
                    .getNotifications()
                    .then((data) => {
                        let hasNoti = false;
                        let newArr = [];

                        for (const item of data) {
                            if (!item.notification_status.find((item) => item.email === user.email)?.read) {
                                hasNoti = true;
                                if (!checked) {
                                    newArr.push(item);
                                }
                            }
                        }
                        if (checked) {
                            newArr = data;
                        }

                        dispatch(addInvisibleNoti(!hasNoti));

                        const groupedData = groupByDate(newArr);
                        setNotif(groupedData);
                        setAlertType("success");
                        setTexto(t("inbox.markedRead"));
                        setShow(true);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const handleChangeSwitch = async (event) => {
        setChecked(event.target.checked);
        setShowSkeleton(true);

        await notificationsService
            .getNotifications()
            .then((data) => {
                let newArr = [];
                if (!event.target.checked) {
                    for (const item of data) {
                        if (!item.notification_status.find((item) => item.email === user.email)?.read) {
                            newArr.push(item);
                        }
                    }
                } else {
                    newArr = data;
                }
                const groupedData = groupByDate(newArr);
                setNotif(groupedData);
            })
            .catch((err) => {
                console.log(err);
            });

        setShowSkeleton(false);
    };

    return (
        <Box px={{ xs: 0, md: 2, lg: 8 }} pt={12} pb={5} sx={{ height: "100%", minHeight: "calc(100vh - 81px)" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" pb={1} pt={1}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={checked}
                            onChange={handleChangeSwitch}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    }
                    label={
                        <Typography fontWeight={600} fontSize={16}>
                            {t("inbox.showAll")}
                        </Typography>
                    }
                />
                <Link
                    sx={{ fontWeight: 600, fontSize: 16, cursor: "pointer", textDecoration: "none" }}
                    component={Button}
                    onClick={handleMark}
                >
                    {t("inbox.markAll")}
                </Link>
            </Stack>
            {showSkeleton ? (
                rowsSkeleton.map((item) => (
                    <Box bgcolor="#FFF" py={2} px={5} borderRadius="10px" mb={0.7} key={item.id}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            {item.notification}
                        </Stack>
                    </Box>
                ))
            ) : Object.entries(notif).length > 0 ? (
                Object.entries(notif).map(([date, items]) => (
                    <Box key={date}>
                        <Typography py={1} variant="h3">
                            {moment.utc(date).isSame(new Date(), "day")
                                ? t("miscellaneous.today")
                                : moment.utc(date).isSame(moment().subtract(1, "day"), "day")
                                    ? t("miscellaneous.yesterday")
                                    : moment.utc(date).format(todos.dateFormat)}
                        </Typography>
                        {items.map((item) => (
                            <Box bgcolor="#FFF" py={2} px={3} borderRadius={2.5} mb={0.7} key={item._id}>
                                <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                                    <Grid item>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Box
                                                component="img"
                                                src={CommuIcon}
                                                sx={{
                                                    visibility: !item.notification_status.find(
                                                        (item) => item.email === user.email
                                                    )?.read
                                                        ? "visible"
                                                        : "hidden",
                                                }}
                                            />
                                            <Button onClick={goToSupp}>
                                                <Typography color="#131F3E" fontWeight={600}>
                                                    {item.notification_description}
                                                </Typography>
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Stack direction="row" alignItems="center" spacing={3}>
                                            <Typography sx={{ ml: "auto" }}>
                                                {moment(item.creation_date).format("hh:mm A")}
                                            </Typography>
                                            {!item.notification_status.find((item) => item.email === user.email)
                                                ?.read ? (
                                                <LightTooltip title={t("inbox.markRead")}>
                                                    <IconButton onClick={() => markRead(item._id)}>
                                                        <CheckCircleIcon sx={{ "&:hover": { color: "#00B147" } }} />
                                                    </IconButton>
                                                </LightTooltip>
                                            ) : (
                                                <LightTooltip title={t("inbox.markUnRead")}>
                                                    <IconButton onClick={() => markUnRead(item._id)}>
                                                        <CheckCircleIcon sx={{ color: "#00B147" }} />
                                                    </IconButton>
                                                </LightTooltip>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </Box>
                ))
            ) : (
                <Box bgcolor="#FFF" py={2} px={5} borderRadius="10px">
                    <Typography color="#131F3E" fontSize={18} sx={{ ml: 1 }} fontStyle="italic">
                        {t("inbox.noNotif")}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default AdminNotification;
