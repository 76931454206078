import axios from "axios";

/* Script que contiene las llamadas a la API */
const userCompany = {
    // Petición para listar las empresas a las que tiene acceso un usuario
    getCompanies: () => {
        const request = axios.get("/getCompanies");

        return request.then((response) => response.data);
    },

    // Petición para listar las empresas a las que tiene acceso un usuario dependiendo su franquicia
    getFranchiseInfo: (id) => {
        const request = axios.get(`/getCompanies?franchise_id=${id}`);

        return request.then((response) => response.data);
    },

    // Petición para listar las franquicias a las que tiene acceso un usuario
    getUserFranchises: () => {
        const request = axios.get(`/getUserFranchises`);

        return request.then((response) => response.data);
    },

    // Petición para listar los usuarios de una franquicia
    getAllUsersFranchise: (params) => {
        const request = axios.get(`/getAllUsersFranchise`, {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Petición que actualiza el último acceso de una empresa
    updateLastAccess: (params) => {
        const request = axios.get(`/updateLastAccess`, {
            params: params,
        });

        return request.then((response) => response);
    },

    updateDefaultFormat: (params) => {
        const request = axios.post("/updateDefaultFormat", params);

        return request.then((response) => response);
    },

    getUserCompany: (id) => {
        const request = axios.get(`/getUserCompany?company_id=${id}`);

        return request.then((response) => response.data);
    },

    // Petición para listar las empresas detalladas a las que tiene acceso un usuario dependiendo su franquicia
    getCompaniesDet: (id) => {
        const request = axios.get(`/getCompaniesDet?franchise_id=${id}`);

        return request.then((response) => response.data);
    },
};

export default userCompany;
