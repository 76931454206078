import axios from "axios";

/* Script que contiene las llamadas a la API */
const check = {
    convertChecks: (params) => {
        const request = axios.post("/convertChecks", params);

        return request.then((response) => response);
    },

    getCheckDetails: (params) => {
        const request = axios.get("/getCheckDetails", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    downloadCheckConversion: (params) => {
        const request = axios.get("/downloadCheckConversion", { params: params });

        return request.then((response) => response.data);
    },

    // Petición que retorna el id de la conversion agrupada
    createGroupedConversionChecks: (params) => {
        const request = axios.post("/createGroupedConversionChecks", params);

        return request.then((response) => response.data);
    },

    getGroupedCheckDetails: (params) => {
        const request = axios.get("/getGroupedCheckDetails", {
            params: params,
        });

        return request.then((response) => response.data);
    },
    
    downloadHistoryChecks: (params) => {
        const request = axios.get("/downloadHistoryChecks", { params: params });

        return request.then((response) => response.data);
    },

    downloadHistoryInvoices: (params) => {
        const request = axios.get("/downloadHistoryInvoices", { params: params });

        return request.then((response) => response.data);
    },
};

export default check;
