import { useEffect, useState } from "react";

/* Componentes */
import AdminNotification from "../components/inbox/AdminNotification";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";
import { useSelector } from "react-redux";
import BaseSnackbar from "../components/ui/BaseSnackbar";

import notificationsService from "../services/notification";
import { Box, Container } from "@mui/material";

const Notifications = () => {
    const todos = useSelector((state) => state.value);
    const [isLoading, setIsLoading] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [notif, setNotif] = useState([]);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [checked, setChecked] = useState(false);

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const groupByDate = (arr) =>
        arr.reduce((acc, obj) => {
            const date = obj.creation_date.split("T")[0]; // Extraer la fecha sin la hora
            acc[date] = acc[date] || [];
            acc[date].push(obj);
            return acc;
        }, {});

    useEffect(() => {
        (async () => {
            setShowSkeleton(true);

            await notificationsService
                .getNotifications()
                .then((data) => {
                    let newArr = [];
                    for (const item of data) {
                        if (!item.notification_status.find((item) => item.email === todos.userInfo.email)?.read) {
                            newArr.push(item);
                        }
                    }
                    const groupedData = groupByDate(newArr);
                    setNotif(groupedData);
                })
                .catch((err) => {
                    console.log(err);
                });

            setShowSkeleton(false);
        })();
    }, [todos.userInfo.email]);

    return (
        <Box sx={{ background: "rgba(19, 31, 62, 0.03)" }}>
            <Container maxWidth="xl">
                <AdminNotification
                    user={todos.userInfo}
                    setIsLoading={setIsLoading}
                    notif={notif}
                    setNotif={setNotif}
                    setShow={setShow}
                    setTexto={setTexto}
                    setAlertType={setAlertType}
                    showSkeleton={showSkeleton}
                    setShowSkeleton={setShowSkeleton}
                    setChecked={setChecked}
                    checked={checked}
                    groupByDate={groupByDate}
                />
            </Container>
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleClose} />
            <SimpleBackdrop open={isLoading} />
        </Box>
    );
};

export default Notifications;
