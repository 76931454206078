/* MUI Imports */
import { Box } from "@mui/material";
import UserRegister from "../components/auth/UserRegister/UserRegister";
import NavBar from "../components/layout/NavBar";
import { Toolbar } from "@mui/material";

const UserRegisterP = (props) => {
    const { userEmail, handleShowRegister, register, independentUser, invitation, language, invitationId } = props;

    return (
        <>
            <NavBar register={register} independentUser={independentUser} userEmail={userEmail} />
            <Toolbar className="toolbarMain" />
            <Box sx={{ backgroundColor: "rgba(19, 31, 62, 0.03)", height: "100%", paddingTop: 4, paddingBottom: 4 }}>
                <UserRegister
                    editMode={false}
                    userEmail={userEmail}
                    handleShowRegister={handleShowRegister}
                    independentUser={independentUser}
                    invitation={invitation}
                    language={language}
                    invitationId={invitationId}
                />
            </Box>
        </>
    );
};

export default UserRegisterP;
