const creditsInfo = [
    {
        creditId: 1,
        credits: 5,
        cost: 10.00,
        discount: [
            { planId: 1, cost: 9.00 }
        ]
    },
    {
        creditId: 2,
        credits: 10,
        cost: 20.00,
        discount: [
            { planId: 1, cost: 18.00 },
            { planId: 2, cost: 17.00 }
        ]
    },
    {
        creditId: 3,
        credits: 15,
        cost: 30.00,
        discount: [
            { planId: 2, cost: 25.50 },
            { planId: 3, cost: 24.00 }
        ]
    },
    {
        creditId: 4,
        credits: 20,
        cost: 40.00,
        discount: [
            { planId: 3, cost: 32.00 }
        ]
    },
    {
        creditId: 5,
        credits: 50,
        cost: 100.00,
        discount: [
            { planId: 4, cost: 75.00 },
        ]
    },
    {
        creditId: 6,
        credits: 100,
        cost: 200.00,
        discount: [
            { planId: 4, cost: 150.00 },
            { planId: 5, cost: 140.00 },
        ]
    },
    {
        creditId: 7,
        credits: 150,
        cost: 300.00,
        discount: [
            { planId: 5, cost: 210.00 },
        ]
    },
    {
        creditId: 8,
        credits: 200,
        cost: 400.00,
        discount: [
            { planId: 6, cost: 260.00 },
        ]
    },
    {
        creditId: 9,
        credits: 300,
        cost: 600.00,
        discount: [
            { planId: 6, cost: 390.00 },
        ]
    },
];

export default creditsInfo;
