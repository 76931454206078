/** MUI imports */
import { Stack, Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const GeneralBreadCrumb = ({ breadcrumbs }) => {
    return (
        <Stack direction="row" sx={{ pb: 2 }} alignItems="center" spacing={1}>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" sx={{ color: "#031851" }} />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
};

export default GeneralBreadCrumb;
