/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//import moment from "moment";

/** MUI icons */
import DeleteIcon from "@mui/icons-material/Delete";
import "moment/locale/es";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

/** MUI imports */
import {
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    FilledInput,
    MenuItem,
    Checkbox,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import userCompanyService from "../../services/user_company";
import credService from "../../services/subscription";
import FiltersPopover from "../ui/FiltersPopover";

const defaultFilter = {
    filtersArray: [
        {
            type: "",
            value: "",
            since: "",
            until: "",
            multiple: [],
        },
    ],
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 45 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getcontentanchorel: null,
};

const FiltersButton = (props) => {
    const { setShowSkeleton, franchise_id, company_id, setCredits, setCreditsOG, usersFranq, showSkeleton } = props;

    const [t] = useTranslation("global");

    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useState(defaultFilter);
    const [companiesFilters, setCompaniesFilters] = useState([]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const attributes = [
        { id: 2, name: t("payment.date"), query: "date" },
        { id: 3, name: t("payment.source"), query: "source" },
        { id: 4, name: t("payment.movement"), query: "movement" },
        { id: 5, name: t("converter.user"), query: "user" },
    ];

    if (franchise_id) {
        attributes.unshift({ id: 1, name: t("dashboard.company"), query: "franchise_company_id" });
    }

    const sources = [
        { id: 1, name: t("payment.monthlyPlan") },
        { id: 2, name: t("credits.converExtra") },
        { id: 4, name: t("credits.userFreeTrial2") },
        { id: 5, name: t("payment.expiration") },
        { id: 6, name: t("payment.convConsumption") },
    ];

    const movements = [
        { id: 1, name: t("payment.ingress") },
        { id: 2, name: t("payment.egress") },
    ];

    const addFilter = () => {
        let array = [...filters.filtersArray];

        if (array.length <= 4) {
            setFilters({
                ...filters,
                filtersArray: [
                    ...filters.filtersArray,
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                        multiple: [],
                    },
                ],
            });
        }
    };

    const reloadHistCred = async (params) => {
        setShowSkeleton(true);

        await credService
            .getCredits(params)
            .then((response) => {
                setCredits(response.historyCredits);
                setCreditsOG(response.historyCredits);
            })
            .catch((error) => {
                console.log(error);
            });

        setShowSkeleton(false);
    };

    const removeFilter = (index) => {
        let array = [...filters.filtersArray];

        if (index !== -1) {
            array.splice(index, 1);
            setFilters({
                ...filters,
                filtersArray: array,
            });

            setShowSkeleton(true);

            let objectFinal = {};

            array.forEach((item) => {
                if (item.value && item.type) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value);
                }
                if (item.type && item.since) {
                    return (objectFinal["since_" + attributes.find((item2) => item2.id === item.type).query] =
                        item.since.format("YYYY-MM-DD"));
                }
                if (item.type && item.until) {
                    return (objectFinal["end_" + attributes.find((item2) => item2.id === item.type).query] =
                        item.until.format("YYYY-MM-DD"));
                }
                if (item.multiple && item.multiple.length > 0) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.multiple);
                }
            });

            // Llamar al back para listar Historial Creditos
            const params = {
                franchise_id: franchise_id,
                company_id: company_id,
                ...objectFinal,
            };
            reloadHistCred(params);
            setShowSkeleton(true);
        }
    };

    const handleChangeType = (event, index) => {
        setShowSkeleton(true);
        let aux = [...filters.filtersArray];
        aux[index].type = event.target.value;
        aux[index].value = "";

        setFilters({
            ...filters,
            filtersArray: aux,
        });
        setShowSkeleton(false);
    };

    const handleChangeFilterRange = (event, index, range) => {
        let aux = [...filters.filtersArray];

        aux[index][range] = event;
        aux[index].value = "";

        setShowSkeleton(true);

        let objectFinal = {};

        aux.forEach((item) => {
            if (item.value && item.type) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
            }
            if (item.type && item.since) {
                objectFinal["since_" + attributes.find((item2) => item2.id === item.type).query] =
                    item.since.format("YYYY-MM-DD");
            }
            if (item.type && item.until) {
                objectFinal["end_" + attributes.find((item2) => item2.id === item.type).query] =
                    item.until.format("YYYY-MM-DD");
            }
            if (item.multiple && item.multiple.length > 0) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.multiple;
            }
        });

        // Llamar al back para listar Historial Creditos
        const params = {
            franchise_id: franchise_id,
            company_id: company_id,
            ...objectFinal,
        };

        reloadHistCred(params);

        setFilters({
            ...filters,
            filtersArray: aux,
        });

        setShowSkeleton(false);
    };

    const handleChangeFilter = (event, index, filterType) => {
        let aux = [...filters.filtersArray];

        if (filterType === 1 || filterType === 3) {
            aux[index].value = "";
            aux[index].since = "";
            aux[index].until = "";
            aux[index].multiple = event.target.value;
        } else {
            aux[index].value = event.target.value;
            aux[index].since = "";
            aux[index].until = "";
            aux[index].multiple = [];
        }

        setShowSkeleton(true);

        let objectFinal = {};

        aux.forEach((item) => {
            if (item.value && item.type) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
            }
            if (item.type && item.since) {
                objectFinal["since_" + attributes.find((item2) => item2.id === item.type).query] =
                    item.since.format("YYYY-MM-DD");
            }
            if (item.type && item.until) {
                objectFinal["end_" + attributes.find((item2) => item2.id === item.type).query] =
                    item.until.format("YYYY-MM-DD");
            }
            if (item.multiple && item.multiple.length > 0) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.multiple;
            }
        });

        // Llamar al back para listar Historial Creditos

        const params = {
            franchise_id: franchise_id,
            company_id: company_id,
            ...objectFinal,
        };

        reloadHistCred(params);

        setFilters({
            ...filters,
            filtersArray: aux,
        });

        setShowSkeleton(false);
    };

    const removeFirstFilter = () => {
        setShowSkeleton(true);
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                    since: "",
                    until: "",
                    multiple: [],
                },
            ],
        });

        // Llamar al back para listar Historial Creditos
        const params = {
            franchise_id: franchise_id,
            company_id: company_id,
        };

        reloadHistCred(params);
        setShowSkeleton(false);
    };

    const cleanFilters = (e) => {
        if (e.target.classList.contains("MuiAvatar-img")) {
            setShowSkeleton(true);
            setFilters({
                filtersArray: [
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                        multiple: [],
                    },
                ],
            });

            // Llamar al back para listar Historial Creditos
            const params = {
                franchise_id: franchise_id,
                company_id: company_id,
            };

            reloadHistCred(params);
            setShowSkeleton(false);
        }
    };

    useEffect(() => {
        (async () => {
            setShowSkeleton(true);
            const result = await userCompanyService.getFranchiseInfo(franchise_id);

            setCompaniesFilters(result.franchiseChildren);
            setShowSkeleton(false);
        })();
    }, [franchise_id, setShowSkeleton]);

    return (
        <FiltersPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            cleanFilters={cleanFilters}
            handleClick={handleClick}
            length={
                filters.filtersArray.filter(
                    (item) => item.type && (item.value || item.since || item.until || item.multiple.length > 0)
                ).length
            }
            addFilter={addFilter}
            disabled={filters.filtersArray.length === 5}
            disabledButton={showSkeleton}
        >
            {filters.filtersArray.map((value, index) => (
                <Grid container spacing={2} alignItems="center" key={index} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel
                                        id="clientStatus-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#131F3E",
                                        }}
                                    >
                                        {t("filters.attribute")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        IconComponent={(props) => {
                                            if (props.className.includes("MuiSelect-iconOpen")) {
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            transform: "rotate(270deg)",
                                                            color: "#131F3E",
                                                            mx: 1,
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        transform: "rotate(90deg)",
                                                        color: "#131F3E",
                                                        mx: 1,
                                                    }}
                                                />
                                            );
                                        }}
                                        value={value.type}
                                        displayEmpty
                                        onChange={(e) => handleChangeType(e, index)}
                                        className="ddlGeneral"
                                        input={
                                            <FilledInput
                                                label={t("filters.attribute")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#131F3E",
                                                }}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                        defaultValue=""
                                    >
                                        {value.type !== ""
                                            ? attributes
                                                .filter(
                                                    (item) =>
                                                        !filters.filtersArray
                                                            .map((item) => item.type)
                                                            .includes(item.id)
                                                )
                                                .concat(
                                                    attributes.filter((item) => item.id === value.type)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.id} key={"attr_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))
                                            : attributes
                                                .filter(
                                                    (item) =>
                                                        !filters.filtersArray
                                                            .map((item) => item.type)
                                                            .includes(item.id)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.id} key={"attr_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {value.type === "" && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled" disabled>
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                mx: 1,
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            mx: 1,
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.type}
                                            displayEmpty
                                            onChange={(e) => handleChangeType(e, index)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        ></Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 1 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                mx: 1,
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            mx: 1,
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.multiple}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                            multiple
                                            renderValue={(selected) => {
                                                return selected
                                                    .map((item) => {
                                                        return companiesFilters.filter(
                                                            (item2) => item2._id === item
                                                        )[0].company_name;
                                                    })
                                                    .join(", ");
                                            }}
                                        >
                                            {companiesFilters.map((item) => (
                                                <MenuItem value={item._id} key={"company_" + item._id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={value.multiple.includes(item._id)}
                                                        />
                                                        {item.company_name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 2 && (
                                <>
                                    <Grid item xs={6} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <DatePicker
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    >
                                                        {t("filters.since")}
                                                    </Typography>
                                                }
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        style: { width: "100%" },
                                                        variant: "filled",
                                                        readOnly: true,
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                value={value.since !== "" ? value.since : null}
                                                onChange={(e) => handleChangeFilterRange(e, index, "since")}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <DatePicker
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    >
                                                        {t("filters.until")}
                                                    </Typography>
                                                }
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        style: { width: "100%" },
                                                        variant: "filled",
                                                        readOnly: true,
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                minDate={
                                                    filters.filtersArray[index]?.since !== ""
                                                        ? filters.filtersArray[index].since
                                                        : null
                                                }
                                                value={value.until !== "" ? value.until : null}
                                                onChange={(e) => handleChangeFilterRange(e, index, "until")}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </>
                            )}
                            {value.type === 3 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                mx: 1,
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            mx: 1,
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.multiple}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            multiple
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                            renderValue={(selected) => {
                                                return selected
                                                    .map((item) => {
                                                        return sources.filter(
                                                            (item2) => item2.id === item
                                                        )[0].name;
                                                    })
                                                    .join(", ");
                                            }}
                                        >
                                            {sources.map((item) => (
                                                <MenuItem value={item.id} key={"sources_" + item._id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        <Checkbox
                                                            checked={value.multiple.includes(item.id)}
                                                        />
                                                        {item.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 4 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                mx: 1,
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            mx: 1,
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        >
                                            {movements.map((item) => (
                                                <MenuItem value={item.id} key={"movements_" + item.id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 5 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        >
                                            {usersFranq.map((item) => (
                                                <MenuItem value={item._id} key={"users_" + item._id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.user_name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={1} display="flex" justifyContent="center">
                        {filters.filtersArray.length > 1 && (
                            <DeleteIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() => removeFilter(index)}
                            />
                        )}
                        {filters.filtersArray.length === 1 &&
                            (value.value !== "" ||
                                value.since !== "" ||
                                value.until !== "" ||
                                value.multiple.length > 0) && (
                                <DeleteIcon
                                    color="primary"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => removeFirstFilter()}
                                />
                            )}
                    </Grid>
                </Grid>
            ))}
        </FiltersPopover>
    );
};

export default FiltersButton;
