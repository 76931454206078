const devTeam = [
	{
		value: "jalvarez@gca.digital",
		name: "José Álvarez"
	},
	{
		value: "btorres@gca.digital",
		name: "Brian Torres"
	},
	{
		value: "mmedina@gca.digital",
		name: "Micaela Medina"
	},
	{
		value: "jrojas@gca.digital",
		name: "Johanna Rojas"
	},
	{
		value: "na",
		name: "N/A"
	},
]

export default devTeam;
