import axios from "axios";

/* Script que contiene las llamadas a la API */
const auth = {
    // Función para iniciar sesión
    login: (params) => {
        const request = axios.post("/login", params);

        return request.then((response) => response.data);
    },

    // Función para reiniciar la clave
    forgotPassword: (params) => {
        const request = axios.post("/forgotPassword", params);

        return request.then((response) => response);
    },

    // Función para verificar la existencia de un usuario
    verifyExistingUser: (email) => {
        const request = axios.get("/verifyExistingUser", {
            params: {
                user_email: email,
            },
        });

        return request.then((response) => response);
    },

    // Función para crear un usuario de prueba
    createNewUser: (params) => {
        const request = axios.post("/createNewUser", params);

        return request.then((response) => response);
    },

    // Actualizar contraseña
    updatePassword: (params) => {
        const request = axios.post("/updatePassword", params);

        return request.then((response) => response);
    },

    // Función para cerrar la sesión
    logout: () => {
        const request = axios.get("/logout");

        return request.then((response) => response);
    },

    // Función para validar el estado de la sesion
    checkSession: () => {
        const request = axios.get("/checkSession");

        return request.then((response) => response.data);
    },

    // Función para actualizar usuario
    updateUser: (params) => {
        const request = axios.post("/updateUser", params);

        return request.then((response) => response);
    },

    // Función para login xero
    signUpXero: (params) => {
        const request = axios.get("/signUpXero", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Función para login xero
    signUpQB: (params) => {
        const request = axios.get("/signUpQB", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Función para obtener los datos del usuario
    getUserData: (email) => {
        const request = axios.get("/getUserData", {
            params: { user_email: email },
        });

        return request.then((response) => response.data);
    },

    // Función para logout xero
    logoutXero: (params) => {
        const request = axios.get("/logoutXero", {
            params: params,
        });

        return request.then((response) => response);
    },

    // Función para logout QB
    logoutQB: (params) => {
        const request = axios.get("/logoutQB", {
            params: params,
        });

        return request.then((response) => response);
    },

    // Función para crear un usuario de prueba
    resend: (params) => {
        const request = axios.post("/resend", params);

        return request.then((response) => response);
    },

    updateUnverifiedUser: (params) => {
        const request = axios.put("/updateUnverifiedUser", params);

        return request.then((response) => response);
    },
};

export default auth;
