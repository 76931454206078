/* Componentes */
import { Box, Grid } from "@mui/material";
import LangButton from "../auth/LangButton";

/* Iconos */
import { ReactComponent as KiiperLogo } from "../../assets/kiiper-logo.svg";

/* Estilos */
import classes from "./BackLogin.module.css";

const BackLogin = (props) => {
    const { children, resetLogin, language } = props;

    return (
        <Box className={classes.background}>
            <LangButton lang={language} />
            <Grid container justifyContent="center" alignItems="center" direction="column">
                <Grid item>
                    <KiiperLogo className={classes.logo} onClick={resetLogin} />
                </Grid>
                <Grid item sx={{ paddingLeft: 2, paddingRight: 2 }}>{children}</Grid>
            </Grid>
        </Box>
    );
};

export default BackLogin;
