/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import introJs from "intro.js";

/** MUI imports */
import { Button, Stack } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import InventoryIcon from "@mui/icons-material/Inventory";
import classes from "./DashboardHeader.module.css";
import { useNavigate } from "react-router-dom";
import dashboardService from "../../services/dashboard";
import conversionsService from "../../services/conversion";
import accountsService from "../../services/accounts";
import LightTooltip from "../ui/LightTooltip";
import CheckIcon from "@mui/icons-material/Check";

/** React-Redux */
import { useDispatch, useSelector } from "react-redux";
import { addTutorial } from "../../redux/userSlice";

const DashboardHeader = (props) => {
    const [t] = useTranslation("global");

    const {
        type,
        setXeroView,
        xeroView,
        franchise,
        company,
        setShowSkeleton,
        setDashboardInfo,
        grupal,
        history,
        setHistory,
        setConversions,
        setUsersFilters,
        setAccounts,
        // franchiseInfo,
        // companyInfo,
    } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    /* Component States */

    const handleNavigation = (type) => {
        switch (type) {
            case 1:
                navigate("/accounts");
                break;
            default:
                break;
        }
    };

    // const goToConciliations = () => {
    //     navigate("/reconciliations", { state: { franchiseInfo: franchiseInfo, companyInfo: companyInfo } });
    // };

    const handleClickHistory = async () => {
        setShowSkeleton(true);
        if (!xeroView) {
            await conversionsService
                .getConversionsByFranchise({ franchise_id: franchise ?? undefined, grouped: todos.isGrouped ?? false })
                .then((response) => {
                    if (response.length > 0) {
                        setConversions(response);
                    } else {
                        setConversions([]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            await conversionsService
                .getConversionUsersByFranchise({
                    franchise_id: franchise ?? undefined,
                    grouped: todos.isGrouped ?? false,
                })
                .then((response) => {
                    setUsersFilters(response);
                })
                .catch((err) => {
                    console.log(err);
                });

            await accountsService
                .getAccountsByFranchise({ franchise_id: franchise ?? undefined, grouped: todos.isGrouped ?? false })
                .then((response) => {
                    const newAcc = response.setup.map((item) => {
                        return {
                            id: item._id,
                            name: item.account_name,
                            bankName: item.bank_name,
                        };
                    });
                    setAccounts(newAcc);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            await dashboardService
                .getDashboard({
                    company_id: company ?? undefined,
                    franchise_id: franchise ?? undefined,
                    grouped: grupal ?? false,
                })
                .then((response) => {
                    if (response.length > 0) {
                        setDashboardInfo(response);
                    } else {
                        setDashboardInfo([]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        setShowSkeleton(false);
        setHistory(!history);
        setXeroView(false);
    };

    // useEffect(() => {
    //     if (todos.subscription) {
    //         if (
    //             todos.subscription.value !== 2 &&
    //             todos.subscription.value !== 5 &&
    //             !(todos.subscription.value === 3 && todos.subscription.plan === 1)
    //         ) {
    //             setShowDashboardXero(true);
    //         } else {
    //             setShowDashboardXero(false);
    //         }
    //     }
    // }, [todos.subscription]);

    useEffect(() => {
        if (todos.steps?.step2) {
            let isDone = false;

            setTimeout(() => {
                introJs()
                    .setOptions({
                        disableInteraction: true,
                        exitOnOverlayClick: false,
                        keyboardNavigation: false,
                        nextLabel: t("dialog.continue"),
                        prevLabel: t("miscellaneous.goBack"),
                        doneLabel: t("miscellaneous.accept"),
                        steps: [
                            {
                                title: `${t("miscellaneous.step")} 2`,
                                element: "#dashboard-tutorial",
                                intro: t("miscellaneous.tutorialDash"),
                            },
                        ],
                    })
                    .oncomplete(() => {
                        isDone = true;
                        dispatch(addTutorial({ ...todos.steps, step2: false, step3: true }));
                    })
                    .onexit(() => {
                        if (!isDone) {
                            dispatch(addTutorial({ ...todos.steps, step2: false }));
                        }
                    })
                    .start();
            }, 1000);
        }
    }, [dispatch, t, todos.steps]);

    return (
        <Stack
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "center", md: "space-between" }}
        >
            {(!type || type === undefined || type === null || type === "") && (
                <LightTooltip title={t("dashboard.disabledMsg")}>
                    <span>
                        <Button
                            fullWidth
                            disableElevation
                            variant="contained"
                            disabled
                            className={classes.disabledButton}
                        >
                            {t("dashboard.manageAcc")}{" "}
                            <TuneIcon sx={{ transform: "rotate(90deg)", ml: 2 }} fontSize="small" />
                        </Button>
                    </span>
                </LightTooltip>
            )}
            {type === "Smb" && !grupal && (
                <span>
                    <Button
                        fullWidth
                        onClick={() => handleNavigation(1)}
                        className={classes.turquoiseButton}
                        disableElevation
                        variant="contained"
                        id="dashboard-tutorial"
                        endIcon={<TuneIcon sx={{ transform: "rotate(90deg)", ml: 2 }} fontSize="small" />}
                    >
                        {t("dashboard.manageAcc")}
                    </Button>
                </span>
            )}
            {(type === "Firm" || grupal) && (
                <span>
                    <Button
                        fullWidth
                        onClick={handleClickHistory}
                        className={classes.purpleButton}
                        disableElevation
                        variant="contained"
                        endIcon={history ? <CheckIcon /> : <InventoryIcon fontSize="inherit" />}
                        sx={{
                            backgroundColor: history ? "#FFF !important" : "#4A22D4",
                            color: history ? "#4A22D4 !important" : "#FFF",
                        }}
                    >
                        {t("dashboard.viewHist")}
                    </Button>
                </span>
            )}
            {/* {integration === 1 && (
                <span>
                    <Button
                        fullWidth
                        onClick={goToConciliations}
                        className={classes.purpleButton}
                        endIcon={<SwapHorizIcon />}
                        disableElevation
                        variant="contained"
                    >
                        {t("dashboard.checkCon")}
                    </Button>
                </span>
            )} */}
        </Stack>
    );
};

export default DashboardHeader;