import { Checkbox, FormControlLabel, Grid, Skeleton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CardCredit from "./CardCredit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import subscriptionService from "../../services/subscription";
import userService from "../../services/user";
import AlertDialog from "../ui/AlertDialog";
import InfoIcon from "@mui/icons-material/Info";
import LightTooltip from "../ui/LightTooltip";
import moment from "moment";
import CustomCardCredit from "./CustomCardCredit";
import { addUserInfo } from "../../redux/userSlice";

const ConversionPack = (props) => {
    const {
        cartItems,
        setCartItems,
        isCart,
        setIsLoading,
        activePlan,
        customCredits,
        setCustomCredits,
        planCredits,
        handleOpenCart,
        currentPlan,
        plansInfo,
        mainSkeleton,
    } = props;

    const [t] = useTranslation("global");
    /* React redux */
    const dispatch = useDispatch();
    const todos = useSelector((state) => state?.value);

    const [inCart, setInCart] = useState(false);
    const [open, setOpen] = useState(false);
    const [creditsData, setCreditsData] = useState(null);
    const [isCustom, setIsCustom] = useState(false);
    const [checkAsk, setCheck] = useState(todos.userInfo.askAgain);

    const handleOpenConvModal = (value) => {
        if (!checkAsk) {
            setCreditsData(value);
            setOpen(true);
        } else {
            handleCreditsSelect(false, value);
        }
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setCreditsData(null);
        setIsCustom(false);
    };

    const handleCheck = (event) => {
        setCheck(event.target.checked);
    };

    const handleCreditsSelect = async (pinned, value) => {
        const data = value ?? creditsData;

        const isFound = cartItems.find((item) => item.line_qty === data.credits && item.line_type === 5);

        if (!isFound) {
            const totalCreditCost =
                Math.round(
                    (activePlan
                        ? data.credits * plansInfo.find((item) => item.planId === activePlan).customCredits
                        : currentPlan
                        ? data.credits * plansInfo.find((item) => item.planId === currentPlan).customCredits
                        : data.credits * 2) * 100
                ) / 100;

            const newVAl = {
                line_type: 5,
                line_amount: data.credits * plansInfo.find((item) => item.planId === activePlan).customCredits,
                line_unit_amount: (totalCreditCost / data.credits).toFixed(2),
                line_qty: data.credits,
                line_since_date: moment().format(),
                line_end_date: moment().add(1, "M").format(),
                fixed: pinned,
            };

            const params = {
                franchise_id: todos.franchiseID,
                company_id: todos.companyID,
                cart_lines: [...cartItems, newVAl],
            };

            setIsLoading(true);

            await subscriptionService
                .updateCart(params)
                .then()
                .catch((error) => {
                    console.log(error);
                });

            if (checkAsk) {
                await userService
                    .updateCheckAsk({ _id: todos.userInfo._id, checkAsk: checkAsk })
                    .then(() => {
                        let newUser = { ...todos.userInfo };
                        newUser.askAgain = checkAsk;
                        dispatch(addUserInfo(newUser));
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

            setIsLoading(false);

            setCartItems([...cartItems, newVAl]);
            handleOpenCart();
        }
        handleCloseDialog();
    };

    const handleCustomCreditsSelect = (data) => {
        if (!(data === -1 && (customCredits === 1 || customCredits === 0 || isNaN(customCredits)))) {
            if (isNaN(customCredits)) {
                setCustomCredits(1);
            } else {
                setCustomCredits((prevState) => parseInt(prevState) + parseInt(data));
            }
        }
        setInCart(false);
    };

    const handleCustomCreditsSelectInput = (e) => {
        if (e.target.value >= 0) {
            setCustomCredits(parseInt(e.target.value));
        }
        setInCart(false);
    };

    const handleAddCustomModal = () => {
        if (customCredits && !isNaN(customCredits) && customCredits > 0) {
            if (activePlan && !checkAsk) {
                setIsCustom(true);
                setOpen(true);
            } else {
                handleAdd(false);
            }
        }
    };

    const handleAdd = async (pinned) => {
        if (customCredits > 0) {
            setInCart(true);

            const params = {
                franchise_id: todos.franchiseID,
            };

            const isFound = cartItems.find((item) => item.line_type === 6);

            const totalCreditCost =
                Math.round(
                    (activePlan
                        ? customCredits * plansInfo.find((item) => item.planId === activePlan).customCredits
                        : currentPlan
                        ? customCredits * plansInfo.find((item) => item.planId === currentPlan).customCredits
                        : customCredits * 2) * 100
                ) / 100;

            if (isFound) {
                var index = cartItems.map((item) => item.line_type).indexOf(6);

                cartItems[index].line_amount = totalCreditCost;
                cartItems[index].line_unit_amount = totalCreditCost / customCredits;
                cartItems[index].line_qty = customCredits;

                params.cart_lines = [...cartItems];

                setCartItems([...cartItems]);
            } else {
                const newVAl = {
                    line_type: 6,
                    line_amount: totalCreditCost,
                    line_unit_amount: totalCreditCost / customCredits,
                    line_qty: customCredits,
                    line_since_date: moment().format(),
                    line_end_date: moment().add(1, "M").format(),
                    fixed: pinned,
                };

                params.cart_lines = [...cartItems, newVAl];

                setCartItems([...cartItems, newVAl]);
            }

            setIsLoading(true);

            await subscriptionService
                .updateCart(params)
                .then()
                .catch((error) => {
                    console.log(error);
                });

            setIsLoading(false);

            handleOpenCart();
        }
        handleCloseDialog();
    };

    return (
        <Grid container alignItems="center" spacing={4} mt={isCart ? 0 : 1} pb={isCart ? 0 : 5}>
            {!isCart && (
                <Grid item xs={12}>
                    {currentPlan || activePlan ? (
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography fontSize={24} fontWeight={600}>
                                {t("credits.conversionPackages")}
                            </Typography>
                            <LightTooltip title={t("plans.nonePlansTooltip")}>
                                <InfoIcon fontSize="small" color="primary" />
                            </LightTooltip>
                        </Stack>
                    ) : todos.subscription.value === 1 || todos.subscription.value === 4 ? (
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography fontSize={24} fontWeight={600}>
                                {t("plans.nonePlans")}
                            </Typography>
                            <LightTooltip title={t("plans.nonePlansTooltip")}>
                                <InfoIcon fontSize="small" color="primary" />
                            </LightTooltip>
                        </Stack>
                    ) : (
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography fontSize={24} fontWeight={600}>
                                {t("subscription.onDemandConv")}
                            </Typography>
                            <LightTooltip title={t("plans.nonePlansTooltip")}>
                                <InfoIcon fontSize="small" color="primary" />
                            </LightTooltip>
                        </Stack>
                    )}
                    {!currentPlan && !activePlan && (
                        <Typography variant="h3" color="primary">
                            {t("plans.nonePlansText")}
                        </Typography>
                    )}
                    {(currentPlan || activePlan) && (
                        <Typography variant="h3" color="primary">
                            {t("credits.PotentExp")}
                        </Typography>
                    )}
                </Grid>
            )}
            {!mainSkeleton &&
                activePlan &&
                planCredits.map((item) => (
                    <Grid item xs={12} sm={6} md={4} key={item.creditId}>
                        <CardCredit
                            data={item}
                            handle={handleOpenConvModal}
                            activePlan={activePlan}
                            cartItems={cartItems}
                            plansInfo={plansInfo}
                        />
                    </Grid>
                ))}
            {mainSkeleton && (
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <Skeleton variant="rounded" animation="wave" height={116} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Skeleton variant="rounded" animation="wave" height={116} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Skeleton variant="rounded" animation="wave" height={116} />
                    </Grid>
                </>
            )}
            {!mainSkeleton && (
                <Grid item xs={12} sm={6} md={4}>
                    <CustomCardCredit
                        customCredits={customCredits}
                        handle={handleCustomCreditsSelect}
                        cost={
                            activePlan
                                ? plansInfo.find((item) => item.planId === activePlan).customCredits
                                : currentPlan
                                ? plansInfo.find((item) => item.planId === currentPlan).customCredits
                                : 2
                        }
                        handleAdd={handleAddCustomModal}
                        cartItems={cartItems}
                        inCart={inCart}
                        handleInput={handleCustomCreditsSelectInput}
                    />
                </Grid>
            )}
            {!mainSkeleton && !activePlan && (
                <Grid item xs={12} sm={6} md={4}>
                    <Typography gutterBottom>
                        <span style={{ color: "#4A22D4", fontWeight: 600 }}>1</span> {t("subscription.user")}
                    </Typography>
                    <Typography gutterBottom>
                        {t("plans.support")} <span style={{ fontWeight: 600 }}>{t("plans.standard")}</span>
                    </Typography>
                    <Typography gutterBottom>
                        <span style={{ color: "#4A22D4", fontWeight: 600 }}>60</span> {t("subscription.60days")}
                    </Typography>
                </Grid>
            )}
            <AlertDialog
                open={open}
                onClose={handleCloseDialog}
                type="cart"
                maxWidth="sm"
                title={t("dialog.buySingleCred")}
                message={
                    <Stack alignItems="center">
                        <Typography textAlign="justify" fontSize={14}>
                            {t("dialog.buySingleCredText")}
                        </Typography>
                        <FormControlLabel
                            control={<Checkbox value={checkAsk} onChange={handleCheck} />}
                            label={t("dialog.dontAskAgain")}
                        />
                    </Stack>
                }
                agreeAction={() => (isCustom ? handleAdd(true) : handleCreditsSelect(true, null))}
                oneAction={() => (isCustom ? handleAdd(false) : handleCreditsSelect(false, null))}
                agreeBtnLabel={t("plans.pin")}
                disagreeBtnLabel={t("dialog.singleBuy")}
                onlyAction={true}
            />
        </Grid>
    );
};

export default ConversionPack;
