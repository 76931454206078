import { Button, Grid, Paper, Stack, Typography } from "@mui/material";

import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";

import AlertDialog from "../ui/AlertDialog";
import { useState } from "react";

import SubsService from "../../services/subscription";
import { useSelector } from "react-redux";

const ColorButton2 = styled(Button)(({ theme }) => ({
    borderRadius: "6px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "4px",
    paddingBottom: "4px",
}));

const FixedCardCredit = (props) => {
    const { currentPlanInfo, credits, creditsInfo, setReload, setIsLoading, plansInfo } = props;

    const [t] = useTranslation("global");
    const [openAlert, setOpenAlert] = useState(false);
    const todos = useSelector((state) => state.value);

    let price = 0;
    if (currentPlanInfo.subscription_plan !== 0) {
        price = creditsInfo
            .find((item) => item.credits === credits)
            ?.discount.find((item2) => item2.planId === currentPlanInfo.subscription_plan)?.cost;
    } else {
        price = creditsInfo.find((item) => item.credits === credits).discount;
    }
    if (!price) {
        price =
            credits *
            (currentPlanInfo.subscription_plan
                ? plansInfo.find((item) => item.planId === currentPlanInfo.subscription_plan).customCredits
                : 2);
    }

    const showModal = () => {
        setOpenAlert(true);
    };

    const handleCloseDialog = () => {
        setOpenAlert(false);
    };

    const handleCancelFixed = async () => {
        const params = {
            franchise_id: currentPlanInfo.franchise_id,
            custom_package_1: credits === currentPlanInfo.custom_package_1 ? null : currentPlanInfo.custom_package_1,
            custom_package_2: credits === currentPlanInfo.custom_package_2 ? null : currentPlanInfo.custom_package_2,
            extra_credits: credits === currentPlanInfo.extra_credits ? null : currentPlanInfo.extra_credits,
        };

        setIsLoading(true);

        await SubsService.updateSubscription(params)
            .then(() => setReload((prevState) => !prevState))
            .catch((err) => console.log(err));

        setIsLoading(false);
    };

    const bodyMess = (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item>
                <Typography fontSize={14} textAlign="justify">{t("dialog.whenFixedDelete")}</Typography>
            </Grid>
            <Grid item>
                <Typography fontSize={14} textAlign="justify">{t("dialog.warningConfirm2")}</Typography>
            </Grid>
        </Grid>
    );

    return (
        <Paper
            elevation={3}
            square={false}
            sx={{
                transition: "transform 0.2s", // Add a transition for a smooth effect
                "&:hover": {
                    transform: "scale(1.05)", // Scale the card on hover
                },
                py: 2,
                px: { xs: 3, md: 4 },
                borderRadius: 2.5,
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="column">
                    <Typography fontSize={34} fontWeight={700} sx={{ mb: 0, pb: 0 }} color="primary">
                        +{credits}
                    </Typography>
                    <Typography fontSize={14} fontWeight={600} sx={{ ml: 1, mt: -1, pt: 0 }}>
                        {t("credits.credits")}
                    </Typography>
                </Stack>
                <Stack justifyContent="center" alignItems="center" textAlign="center">
                    <Typography fontSize={16} fontWeight={600} color="primary">
                        $
                        {parseFloat(price).toLocaleString(todos.amountFormat, {
                            minimumFractionDigits: 2,
                        })}
                    </Typography>
                    <ColorButton2 variant="outlined" disableElevation endIcon={<CheckIcon />}>
                        {t("plans.active")}
                    </ColorButton2>
                    <Button color="error" size="small" sx={{ fontSize: 12, mt: 0.5, width: 100 }} onClick={showModal}>
                        {t("dialog.cancel")}
                    </Button>
                </Stack>
            </Stack>
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={bodyMess}
                agreeAction={handleCancelFixed}
                maxWidth="sm"
            />
        </Paper>
    );
};

export default FixedCardCredit;
