/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    InputAdornment,
    Skeleton,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

/** MUI icons */
import { useTranslation } from "react-i18next";
import { useState } from "react";

import TablePaginationActions from "../ui/TablePagActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import companyService from "../../services/user_company";
import AddCompanyDialog from "./AddCompanyDialog";
import BaseSnackbar from "../ui/BaseSnackbar";
import { useNavigate } from "react-router-dom";
import { addCompanyID } from "../../redux/userSlice";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.03)",
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        company: <Skeleton variant="text" animation="wave" />,
        banks: <Skeleton variant="text" animation="wave" />,
        access: <Skeleton variant="text" animation="wave" />,
        integ: <Skeleton variant="text" animation="wave" />,
        conversions: <Skeleton variant="text" animation="wave" />,
    });
}

const CompaniesTable = (props) => {
    const { setIsLoading } = props;

    const [t] = useTranslation("global");
    /* React redux */
    const todos = useSelector((state) => state.value);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [rows, setRows] = useState(rowsSkeleton);
    const [rowsOG, setRowsOG] = useState([]);
    const [openModalNewCompany, setOpenModalNewCompany] = useState(false);
    const [reload, setReload] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [addNew, setAddNew] = useState(false);
    const [companyRedirect, setCompanyRedirect] = useState(null);

    const columns = [
        { name: "company", title: t("dashboard.company"), minWidth: 160 },
        { name: "banks", title: t("dashboard.accounts") },
        { name: "access", title: t("dashboard.rolAcc") },
        { name: "integ", title: t("subscription.integ") },
        { name: "conversions", title: t("miscellaneous.succesConv") },
    ];

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleNewCompany = () => {
        setOpenModalNewCompany(true);
        setAddNew(false);
    };

    useEffect(() => {
        (async () => {
            const handleOpenCompany = (id) => {
                dispatch(addCompanyID(id));
                navigate("/dashboard");
            };

            await companyService
                .getCompaniesDet(todos.franchiseID)
                .then((data) => {
                    const notAssingData = data.map((item) => {
                        return {
                            id: item.id,
                            company_name: item.company_name,
                            company: (
                                <Button size="small" onClick={() => handleOpenCompany(item.id)}>
                                    {item.company_name}
                                </Button>
                            ),
                            banks: item.banks,
                            access:
                                item.user_permission === 1
                                    ? t("team.owner")
                                    : item.user_permission === 2
                                    ? t("team.admin")
                                    : t("team.colab"),
                            integ:
                                item.system_integration === 1
                                    ? "Xero"
                                    : item.system_integration === 2
                                    ? "Quickbooks"
                                    : t("miscellaneous.withoutInt"),
                            conversions: item.conversions,
                        };
                    });

                    setRows(notAssingData);
                    setRowsOG(notAssingData);
                })
                .catch((err) => {
                    console.log(err);
                });
        })();
    }, [t, todos.dateFormat, todos.franchiseID, reload, navigate, dispatch]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);

        const filterCases = rowsOG.filter(
            (item) =>
                item.company_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.access.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.integ.toLowerCase().includes(event.target.value.toLowerCase())
        );

        setRows(filterCases);
    };

    return (
        <>
            <Card>
                <CardContent sx={{ padding: 0, paddingBottom: "12px !important" }}>
                    <Typography variant="h1" sx={{ pb: 1, pt: 2, px: { xs: 1, md: 5 } }}>
                        {t("miscellaneous.companyDir")}
                    </Typography>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ pb: 2, pt: 2, px: { xs: 1, md: 5 } }}
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                disableElevation
                                startIcon={<AddIcon />}
                                onClick={handleNewCompany}
                            >
                                {t("navBar.newCompany")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="flex-end" spacing={2}>
                                <Grid item></Grid>
                                <Grid item>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="filled"
                                        hiddenLabel
                                        placeholder="Buscar..."
                                        //size="small"
                                        value={searchValue}
                                        onChange={handleChangeSearchValue}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Box
                component="main"
                sx={{
                    px: { xs: 2, md: 5 },
                    backgroundColor: "#F8F8F9",
                    pb: 4,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        {columns.map((item) => (
                                            <StyledTableCell
                                                key={item.name}
                                                align={item.align ?? "left"}
                                                style={{ minWidth: item.minWidth }}
                                            >
                                                {item.title}
                                            </StyledTableCell>
                                        ))}
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row) => (
                                        <StyledTableRow
                                            key={row.id}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <StyledTableCell>{row.company}</StyledTableCell>
                                            <StyledTableCell>{row.banks}</StyledTableCell>
                                            <StyledTableCell>{row.access}</StyledTableCell>
                                            <StyledTableCell>{row.integ}</StyledTableCell>
                                            <StyledTableCell>{row.conversions}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[
                                                10,
                                                20,
                                                30,
                                                { label: t("miscellaneous.all"), value: -1 },
                                            ]}
                                            colSpan={12}
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "rowsPerPage",
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            labelRowsPerPage={t("dashboard.rowsPerPage")}
                                            labelDisplayedRows={(page) =>
                                                `${page.from}-${page.to === -1 ? page.count : page.to} ${t(
                                                    "team.unlinkText2"
                                                )} ${page.count}`
                                            }
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
            <AddCompanyDialog
                open={openModalNewCompany}
                onClose={() => setOpenModalNewCompany(false)}
                setReload={setReload}
                franchiseID={todos.franchiseID}
                setIsLoading={setIsLoading}
                setShow={setShow}
                setTexto={setTexto}
                setAlertType={setAlertType}
                addNew={addNew}
                franchiseInfo={todos.allComData.find((item) => item._id === todos.franchiseID)}
                softwares={todos.userInfo.software}
                companyRedirect={companyRedirect}
                setCompanyRedirect={setCompanyRedirect}
            />
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleClose} />
        </>
    );
};

export default CompaniesTable;
