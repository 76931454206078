const countries = [
  {
    name_es: "Afganistán",
    name_en: "Afghanistan",
    code: "Afganistán",
    phone_code: "+93",
    country_code: "AF"
  },
  {
    name_es: "Albania",
    name_en: "Albania",
    code: "Albania",
    phone_code: "+355",
    country_code: "AL"
  },
  {
    name_es: "Alemania",
    name_en: "Germany",
    code: "Alemania",
    phone_code: "+49",
    country_code: "DE"
  },
  {
    name_es: "Andorra",
    name_en: "Andorra",
    code: "Andorra",
    phone_code: "+376",
    country_code: "AD"
  },
  {
    name_es: "Angola",
    name_en: "Angola",
    code: "Angola",
    phone_code: "+244",
    country_code: "AO"
  },
  {
    name_es: "Anguilla",
    name_en: "Anguilla",
    code: "Anguilla",
    phone_code: "+1",
    country_code: "AI"
  },
  {
    name_es: "Antigua y Barbuda",
    name_en: "Antigua & Barbuda",
    code: "Antigua y Barbuda",
    phone_code: "+1",
    country_code: "AG"
  },
  {
    name_es: "Antillas Holandesas",
    name_en: "Netherlands Antilles",
    code: "Antillas Holandesas",
    phone_code: "+31",
    country_code: "NL"
  },
  {
    name_es: "Antártida",
    name_en: "Antarctica",
    code: "Antártida",
    phone_code: "+672",
    country_code: "AQ"
  },
  {
    name_es: "Arabia Saudita",
    name_en: "Saudi Arabia",
    code: "Arabia Saudita",
    phone_code: "+966",
    country_code: "SA"
  },
  {
    name_es: "Argelia",
    name_en: "Algeria",
    code: "Argelia",
    phone_code: "+213",
    country_code: "DZ"
  },
  {
    name_es: "Argentina",
    name_en: "Argentina",
    code: "Argentina",
    phone_code: "+54",
    country_code: "AR"
  },
  {
    name_es: "Armenia",
    name_en: "Armenia",
    code: "Armenia",
    phone_code: "+374",
    country_code: "AM"
  },
  {
    name_es: "Aruba",
    name_en: "Aruba",
    code: "Aruba",
    phone_code: "+297",
    country_code: "AW"
  },
  {
    name_es: "Australia",
    name_en: "Australia",
    code: "Australia",
    phone_code: "+61",
    country_code: "AU"
  },
  {
    name_es: "Austria",
    name_en: "Austria",
    code: "Austria",
    phone_code: "+43",
    country_code: "AT"
  },
  {
    name_es: "Azerbaiyán",
    name_en: "Azerbaijan",
    code: "Azerbaiyán",
    phone_code: "+994",
    country_code: "AZ"
  },
  {
    name_es: "Bahamas",
    name_en: "Bahamas",
    code: "Bahamas",
    phone_code: "+1",
    country_code: "BS"
  },
  {
    name_es: "Banglades",
    name_en: "Bangladesh",
    code: "Banglades",
    phone_code: "+880",
    country_code: "BD"
  },
  {
    name_es: "Barbados",
    name_en: "Barbados",
    code: "Barbados",
    phone_code: "+1",
    country_code: "BB"
  },
  {
    name_es: "Baréin",
    name_en: "Bahrain",
    code: "Baréin",
    phone_code: "+973",
    country_code: "BH"
  },
  {
    name_es: "Belice",
    name_en: "Belize",
    code: "Belice",
    phone_code: "+501",
    country_code: "BZ"
  },
  {
    name_es: "Benin",
    name_en: "Benin",
    code: "Benin",
    phone_code: "+229",
    country_code: "BJ"
  },
  {
    name_es: "Bermudas",
    name_en: "Bermuda",
    code: "Bermudas",
    phone_code: "+1",
    country_code: "BM"
  },
  {
    name_es: "Bielorrusia",
    name_en: "Belarus",
    code: "Bielorrusia",
    phone_code: "+375",
    country_code: "BY"
  },
  {
    name_es: "Birmania",
    name_en: "Myanmar",
    code: "Birmania",
    phone_code: "+91",
    country_code: "MM"
  },
  {
    name_es: "Bolivia",
    name_en: "Bolivia",
    code: "Bolivia",
    phone_code: "+591",
    country_code: "BO"
  },
  {
    name_es: "Bosnia-Herzegovina",
    name_en: "Bosnia & Herzegovina",
    code: "Bosnia-Herzegovina",
    phone_code: "+387",
    country_code: "BA"
  },
  {
    name_es: "Botsuana",
    name_en: "Botswana",
    code: "Botsuana",
    phone_code: "+267",
    country_code: "BW"
  },
  {
    name_es: "Bouvet",
    name_en: "Bouvet",
    code: "Bouvet",
    phone_code: "+47",
    country_code: "BV"
  },
  {
    name_es: "Brasil",
    name_en: "Brazil",
    code: "Brasil",
    phone_code: "+55",
    country_code: "BR"
  },
  {
    name_es: "Brunei",
    name_en: "Brunei Darussalam",
    code: "Brunei",
    phone_code: "+673",
    country_code: "BN"
  },
  {
    name_es: "Bulgaria",
    name_en: "Bulgaria",
    code: "Bulgaria",
    phone_code: "+359",
    country_code: "BG"
  },
  {
    name_es: "Burkina Faso",
    name_en: "Burkina Faso",
    code: "Burkina Faso",
    phone_code: "+226",
    country_code: "BF"
  },
  {
    name_es: "Burundi",
    name_en: "Burundi",
    code: "Burundi",
    phone_code: "+257",
    country_code: "BI"
  },
  {
    name_es: "Butan",
    name_en: "Bhutan",
    code: "Butan",
    phone_code: "+975",
    country_code: "BT"
  },
  {
    name_es: "Bélgica",
    name_en: "Belgium",
    code: "Bélgica",
    phone_code: "+32",
    country_code: "BE"
  },
  {
    name_es: "Cabo Verde",
    name_en: "Cape Verde",
    code: "Cabo Verde",
    phone_code: "+238",
    country_code: "CV"
  },
  {
    name_es: "Camboya",
    name_en: "Cambodia",
    code: "Camboya",
    phone_code: "+855",
    country_code: "KH"
  },
  {
    name_es: "Camerún",
    name_en: "Cameroon",
    code: "Camerún",
    phone_code: "+237",
    country_code: "CM"
  },
  {
    name_es: "Canadá",
    name_en: "Canada",
    code: "Canadá",
    phone_code: "+1",
    country_code: "CA"
  },
  {
    name_es: "Chad",
    name_en: "Chad",
    code: "Chad",
    phone_code: "+235",
    country_code: "TD"
  },
  {
    name_es: "Chile",
    name_en: "Chile",
    code: "Chile",
    phone_code: "+56",
    country_code: "CL"
  },
  {
    name_es: "China",
    name_en: "China",
    code: "China",
    phone_code: "+86",
    country_code: "CN"
  },
  {
    name_es: "Chipre",
    name_en: "Cyprus",
    code: "Chipre",
    phone_code: "+357",
    country_code: "CY"
  },
  {
    name_es: "Ciudad del Vaticano",
    name_en: "Holy See (Vatican City State)",
    code: "Ciudad del Vaticano",
    phone_code: "+39",
    country_code: "VA"
  },
  {
    name_es: "Colombia",
    name_en: "Colombia",
    code: "Colombia",
    phone_code: "+57",
    country_code: "CO"
  },
  {
    name_es: "Comoras",
    name_en: "Comoros",
    code: "Comoras",
    phone_code: "+269",
    country_code: "KM"
  },
  {
    name_es: "Congo",
    name_en: "Congo",
    code: "Congo",
    phone_code: "+242",
    country_code: "CG"
  },
  {
    name_es: "Corea del Norte",
    name_en: "Korea, Democratic People's Republic of",
    code: "Corea del Norte",
    phone_code: "+850",
    country_code: "KP"
  },
  {
    name_es: "Corea del Sur",
    name_en: "Korea, Republic of",
    code: "Corea del Sur",
    phone_code: "+82",
    country_code: "KR"
  },
  {
    name_es: "Costa de Marfil",
    name_en: "Cote d'Ivoire",
    code: "Costa de Marfil",
    phone_code: "+225",
    country_code: "CI"
  },
  {
    name_es: "Costa Rica",
    name_en: "Costa Rica",
    code: "Costa Rica",
    phone_code: "+506",
    country_code: "CR"
  },
  {
    name_es: "Croacia",
    name_en: "Croatia",
    code: "Croacia",
    phone_code: "+385",
    country_code: "HR"
  },
  {
    name_es: "Cuba",
    name_en: "Cuba",
    code: "Cuba",
    phone_code: "+53",
    country_code: "CU"
  },
  {
    name_es: "Dinamarca",
    name_en: "Denmark",
    code: "Dinamarca",
    phone_code: "+45",
    country_code: "DK"
  },
  {
    name_es: "Dominica",
    name_en: "Dominica",
    code: "Dominica",
    phone_code: "+1",
    country_code: "DM"
  },
  {
    name_es: "Ecuador",
    name_en: "Ecuador",
    code: "Ecuador",
    phone_code: "+593",
    country_code: "EC"
  },
  {
    name_es: "Egipto",
    name_en: "Egypt",
    code: "Egipto",
    phone_code: "+20",
    country_code: "EG"
  },
  {
    name_es: "El Salvador",
    name_en: "El Salvador",
    code: "El Salvador",
    phone_code: "+503",
    country_code: "SV"
  },
  {
    name_es: "Emiratos Árabes Unidos",
    name_en: "United Arab Emirates",
    code: "Emiratos Árabes Unidos",
    phone_code: "+971",
    country_code: "AE"
  },
  {
    name_es: "Eritrea",
    name_en: "Eritrea",
    code: "Eritrea",
    phone_code: "+291",
    country_code: "ER"
  },
  {
    name_es: "Eslovaquia",
    name_en: "Slovakia",
    code: "Eslovaquia",
    phone_code: "+421",
    country_code: "SK"
  },
  {
    name_es: "Eslovenia",
    name_en: "Slovenia",
    code: "Eslovenia",
    phone_code: "+386",
    country_code: "SI"
  },
  {
    name_es: "España",
    name_en: "Spain",
    code: "España",
    phone_code: "+34",
    country_code: "ES"
  },
  {
    name_es: "Estado de Palestina",
    name_en: "State of Palestine",
    code: "Estado de Palestina",
    phone_code: "+970",
    country_code: "PS"
  },
  {
    name_es: "Estados Federados de Micronesia",
    name_en: "Micronesia, Federated States of",
    code: "Estados Federados de Micronesia",
    phone_code: "+691",
    country_code: "FM"
  },
  {
    name_es: "Estados Unidos",
    name_en: "United States",
    code: "Estados Unidos",
    phone_code: "+1",
    country_code: "US"
  },
  {
    name_es: "Estonia",
    name_en: "Estonia",
    code: "Estonia",
    phone_code: "+372",
    country_code: "EE"
  },
  {
    name_es: "Etiopía",
    name_en: "Ethiopia",
    code: "Etiopía",
    phone_code: "+251",
    country_code: "ET"
  },
  {
    name_es: "Filipinas",
    name_en: "Philippines",
    code: "Filipinas",
    phone_code: "+63",
    country_code: "PH"
  },
  {
    name_es: "Finlandia",
    name_en: "Finland",
    code: "Finlandia",
    phone_code: "+358",
    country_code: "FI"
  },
  {
    name_es: "Fiyi",
    name_en: "Fiji",
    code: "Fiyi",
    phone_code: "+679",
    country_code: "FJ"
  },
  {
    name_es: "Francia",
    name_en: "France",
    code: "Francia",
    phone_code: "+33",
    country_code: "FR"
  },
  {
    name_es: "Gabón",
    name_en: "Gabon",
    code: "Gabón",
    phone_code: "+241",
    country_code: "GA"
  },
  {
    name_es: "Gambia",
    name_en: "Gambia",
    code: "Gambia",
    phone_code: "+220",
    country_code: "GM"
  },
  {
    name_es: "Georgia",
    name_en: "Georgia",
    code: "Georgia",
    phone_code: "+995",
    country_code: "GE"
  },
  {
    name_es: "Ghana",
    name_en: "Ghana",
    code: "Ghana",
    phone_code: "+233",
    country_code: "GH"
  },
  {
    name_es: "Gibraltar",
    name_en: "Gibraltar",
    code: "Gibraltar",
    phone_code: "+350",
    country_code: "GI"
  },
  {
    name_es: "Granada",
    name_en: "Grenada",
    code: "Granada",
    phone_code: "+1",
    country_code: "GD"
  },
  {
    name_es: "Grecia",
    name_en: "Greece",
    code: "Grecia",
    phone_code: "+30",
    country_code: "GR"
  },
  {
    name_es: "Groenlandia",
    name_en: "Greenland",
    code: "Groenlandia",
    phone_code: "+299",
    country_code: "GL"
  },
  {
    name_es: "Guadalupe",
    name_en: "Guadeloupe",
    code: "Guadalupe",
    phone_code: "+590",
    country_code: "GP"
  },
  {
    name_es: "Guam",
    name_en: "Guam",
    code: "Guam",
    phone_code: "+1",
    country_code: "GU"
  },
  {
    name_es: "Guatemala",
    name_en: "Guatemala",
    code: "Guatemala",
    phone_code: "+502",
    country_code: "GT"
  },
  {
    name_es: "Guayana Francesa",
    name_en: "French Guiana",
    code: "Guayana Francesa",
    phone_code: "+594",
    country_code: "GF"
  },
  {
    name_es: "Guernsey",
    name_en: "Guernsey",
    code: "Guernsey",
    phone_code: "+44",
    country_code: "GG"
  },
  {
    name_es: "Guinea",
    name_en: "Guinea",
    code: "Guinea",
    phone_code: "+224",
    country_code: "GN"
  },
  {
    name_es: "Guinea Ecuatorial",
    name_en: "Equatorial Guinea",
    code: "Guinea Ecuatorial",
    phone_code: "+240",
    country_code: "GQ"
  },
  {
    name_es: "Guinea-Bisau",
    name_en: "Guinea-Bissau",
    code: "Guinea-Bisau",
    phone_code: "+245",
    country_code: "GW"
  },
  {
    name_es: "Guyana",
    name_en: "Guyana",
    code: "Guyana",
    phone_code: "+592",
    country_code: "GY"
  },
  {
    name_es: "Haití",
    name_en: "Haiti",
    code: "Haití",
    phone_code: "+509",
    country_code: "HT"
  },
  {
    name_es: "Holanda",
    name_en: "Netherlands",
    code: "Holanda",
    phone_code: "+31",
    country_code: "NL"
  },
  {
    name_es: "Honduras",
    name_en: "Honduras",
    code: "Honduras",
    phone_code: "+504",
    country_code: "HN"
  },
  {
    name_es: "Hong Kong",
    name_en: "Hong Kong",
    code: "Hong Kong",
    phone_code: "+852",
    country_code: "HK"
  },
  {
    name_es: "Hungría",
    name_en: "Hungary",
    code: "Hungría",
    phone_code: "+36",
    country_code: "HU"
  },
  {
    name_es: "India",
    name_en: "India",
    code: "India",
    phone_code: "+91",
    country_code: "IN"
  },
  {
    name_es: "Indonesia",
    name_en: "Indonesia",
    code: "Indonesia",
    phone_code: "+62",
    country_code: "ID"
  },
  {
    name_es: "Iraq",
    name_en: "Iraq",
    code: "Iraq",
    phone_code: "+964",
    country_code: "IQ"
  },
  {
    name_es: "Irlanda",
    name_en: "Ireland",
    code: "Irlanda",
    phone_code: "+353",
    country_code: "IE"
  },
  {
    name_es: "Irán",
    name_en: "Iran, Islamic Republic of",
    code: "Irán",
    phone_code: "+98",
    country_code: "IR"
  },
  {
    name_es: "Isla de Man",
    name_en: "Isle of Man",
    code: "Isla de Man",
    phone_code: "+44",
    country_code: "IM"
  },
  {
    name_es: "Isla de Navidad",
    name_en: "Christmas Island",
    code: "Isla de Navidad",
    phone_code: "+61",
    country_code: "CX"
  },
  {
    name_es: "Isla Norfolk",
    name_en: "Norfolk Island",
    code: "Isla Norfolk",
    phone_code: "+672",
    country_code: "NF"
  },
  {
    name_es: "Islandia",
    name_en: "Iceland",
    code: "Islandia",
    phone_code: "+354",
    country_code: "IS"
  },
  {
    name_es: "Islas Caimán",
    name_en: "Cayman Islands",
    code: "Islas Caimán",
    phone_code: "+1",
    country_code: "KY"
  },
  {
    name_es: "Islas Cocos",
    name_en: "Cocos (Keeling) Islands",
    code: "Islas Cocos",
    phone_code: "+61",
    country_code: "CC"
  },
  {
    name_es: "Islas Cook",
    name_en: "Cook Islands",
    code: "Islas Cook",
    phone_code: "+682",
    country_code: "CK"
  },
  {
    name_es: "Islas Feroe",
    name_en: "Faroe Islands",
    code: "Islas Feroe",
    phone_code: "+298",
    country_code: "FO"
  },
  {
    name_es: "Islas Georgias del Sur y Sandwich del Sur",
    name_en: "South Georgia & the South Sandwich Islands",
    code: "Islas Georgias del Sur y Sandwich del Sur",
    phone_code: "+500",
    country_code: "GS"
  },
  {
    name_es: "Islas Heard y McDonald",
    name_en: "Heard Island & McDonald Islands",
    code: "Islas Heard y McDonald",
    phone_code: "+61",
    country_code: "HM"
  },
  {
    name_es: "Islas Malvinas",
    name_en: "Falkland Islands (Malvinas)",
    code: "Islas Malvinas",
    phone_code: "+500",
    country_code: "FK"
  },
  {
    name_es: "Islas Marianas del Norte",
    name_en: "Northern Mariana Islands",
    code: "Islas Marianas del Norte",
    phone_code: "+1",
    country_code: "MP"
  },
  {
    name_es: "Islas Marshall",
    name_en: "Marshall Islands",
    code: "Islas Marshall",
    phone_code: "+692",
    country_code: "MH"
  },
  {
    name_es: "Islas Pitcairn",
    name_en: "Pitcairn Islands",
    code: "Islas Pitcairn",
    phone_code: "+64",
    country_code: "PN"
  },
  {
    name_es: "Islas Salomón",
    name_en: "Solomon Islands",
    code: "Islas Salomón",
    phone_code: "+677",
    country_code: "SB"
  },
  {
    name_es: "Islas Turcas y Caicos",
    name_en: "Turks and Caicos Islands",
    code: "Islas Turcas y Caicos",
    phone_code: "+1",
    country_code: "TC"
  },
  {
    name_es: "Islas Ultramarinas Menores de Estados Unidos",
    name_en: "United States Minor Outlying Islands",
    code: "Islas Ultramarinas Menores de Estados Unidos",
    phone_code: "+1",
    country_code: "UM"
  },
  {
    name_es: "Islas Vírgenes Británicas",
    name_en: "Virgin Islands, British",
    code: "Islas Vírgenes Británicas",
    phone_code: "+1",
    country_code: "VG"
  },
  {
    name_es: "Islas Vírgenes de los Estados Unidos",
    name_en: "Virgin Islands of the United States",
    code: "Islas Vírgenes de los Estados Unidos",
    phone_code: "+1",
    country_code: "VI"
  },
  {
    name_es: "Israel",
    name_en: "Israel",
    code: "Israel",
    phone_code: "+972",
    country_code: "IL"
  },
  {
    name_es: "Italia",
    name_en: "Italy",
    code: "Italia",
    phone_code: "+39",
    country_code: "IT"
  },
  {
    name_es: "Jamaica",
    name_en: "Jamaica",
    code: "Jamaica",
    phone_code: "+1",
    country_code: "JM"
  },
  {
    name_es: "Japón",
    name_en: "Japan",
    code: "Japón",
    phone_code: "+81",
    country_code: "JP"
  },
  {
    name_es: "Jersey",
    name_en: "Jersey",
    code: "Jersey",
    phone_code: "+44",
    country_code: "JE"
  },
  {
    name_es: "Jordania",
    name_en: "Jordan",
    code: "Jordania",
    phone_code: "+962",
    country_code: "JO"
  },
  {
    name_es: "Kazajistán",
    name_en: "Kazakhstan",
    code: "Kazajistán",
    phone_code: "+7",
    country_code: "KZ"
  },
  {
    name_es: "Kenia",
    name_en: "Kenya",
    code: "Kenia",
    phone_code: "+254",
    country_code: "KE"
  },
  {
    name_es: "Kirguistán",
    name_en: "Kyrgyzstan",
    code: "Kirguistán",
    phone_code: "+996",
    country_code: "KG"
  },
  {
    name_es: "Kiribati",
    name_en: "Kiribati",
    code: "Kiribati",
    phone_code: "+686",
    country_code: "KI"
  },
  {
    name_es: "Kuwait",
    name_en: "Kuwait",
    code: "Kuwait",
    phone_code: "+965",
    country_code: "KW"
  },
  {
    name_es: "Laos",
    name_en: "Lao People's Democratic Republic",
    code: "Laos",
    phone_code: "+856",
    country_code: "LA"
  },
  {
    name_es: "Lesoto",
    name_en: "Lesotho",
    code: "Lesoto",
    phone_code: "+266",
    country_code: "LS"
  },
  {
    name_es: "Letonia",
    name_en: "Latvia",
    code: "Letonia",
    phone_code: "+371",
    country_code: "LV"
  },
  {
    name_es: "Liberia",
    name_en: "Liberia",
    code: "Liberia",
    phone_code: "+231",
    country_code: "LR"
  },
  {
    name_es: "Libia",
    name_en: "Libya",
    code: "Libia",
    phone_code: "+218",
    country_code: "LY"
  },
  {
    name_es: "Liechtenstein",
    name_en: "Liechtenstein",
    code: "Liechtenstein",
    phone_code: "+423",
    country_code: "LI"
  },
  {
    name_es: "Lituania",
    name_en: "Lithuania",
    code: "Lituania",
    phone_code: "+370",
    country_code: "LT"
  },
  {
    name_es: "Luxemburgo",
    name_en: "Luxembourg",
    code: "Luxemburgo",
    phone_code: "+352",
    country_code: "LU"
  },
  {
    name_es: "Líbano",
    name_en: "Lebanon",
    code: "Líbano",
    phone_code: "+961",
    country_code: "LB"
  },
  {
    name_es: "Macao",
    name_en: "Macao",
    code: "Macao",
    phone_code: "+853",
    country_code: "MO"
  },
  {
    name_es: "Madagascar",
    name_en: "Madagascar",
    code: "Madagascar",
    phone_code: "+261",
    country_code: "MG"
  },
  {
    name_es: "Malasia",
    name_en: "Malaysia",
    code: "Malasia",
    phone_code: "+60",
    country_code: "MY"
  },
  {
    name_es: "Malaui",
    name_en: "Malawi",
    code: "Malaui",
    phone_code: "+265",
    country_code: "MW"
  },
  {
    name_es: "Maldivas",
    name_en: "Maldives",
    code: "Maldivas",
    phone_code: "+960",
    country_code: "MV"
  },
  {
    name_es: "Malta",
    name_en: "Malta",
    code: "Malta",
    phone_code: "+356",
    country_code: "MT"
  },
  {
    name_es: "Malí",
    name_en: "Mali",
    code: "Malí",
    phone_code: "+223",
    country_code: "ML"
  },
  {
    name_es: "Marruecos",
    name_en: "Morocco",
    code: "Marruecos",
    phone_code: "+212",
    country_code: "MA"
  },
  {
    name_es: "Martinica",
    name_en: "Martinique",
    code: "Martinica",
    phone_code: "+596",
    country_code: "MQ"
  },
  {
    name_es: "Mauricio",
    name_en: "Mauritius",
    code: "Mauricio",
    phone_code: "+230",
    country_code: "MU"
  },
  {
    name_es: "Mauritania",
    name_en: "Mauritania",
    code: "Mauritania",
    phone_code: "+222",
    country_code: "MR"
  },
  {
    name_es: "Mayotte",
    name_en: "Mayotte",
    code: "Mayotte",
    phone_code: "+262",
    country_code: "YT"
  },
  {
    name_es: "Moldavia",
    name_en: "Moldova, Republic of",
    code: "Moldavia",
    phone_code: "+373",
    country_code: "MD"
  },
  {
    name_es: "Monaco",
    name_en: "Monaco",
    code: "Monaco",
    phone_code: "+377",
    country_code: "MC"
  },
  {
    name_es: "Mongolia",
    name_en: "Mongolia",
    code: "Mongolia",
    phone_code: "+976",
    country_code: "MN"
  },
  {
    name_es: "Montserrat",
    name_en: "Montserrat",
    code: "Montserrat",
    phone_code: "+1",
    country_code: "MS"
  },
  {
    name_es: "Mozambique",
    name_en: "Mozambique",
    code: "Mozambique",
    phone_code: "+258",
    country_code: "MZ"
  },
  {
    name_es: "México",
    name_en: "Mexico",
    code: "México",
    phone_code: "+52",
    country_code: "MX"
  },
  {
    name_es: "Namibia",
    name_en: "Namibia",
    code: "Namibia",
    phone_code: "+264",
    country_code: "NA"
  },
  {
    name_es: "Nauru",
    name_en: "Nauru",
    code: "Nauru",
    phone_code: "+674",
    country_code: "NR"
  },
  {
    name_es: "Nepal",
    name_en: "Nepal",
    code: "Nepal",
    phone_code: "+977",
    country_code: "NP"
  },
  {
    name_es: "Nicaragua",
    name_en: "Nicaragua",
    code: "Nicaragua",
    phone_code: "+505",
    country_code: "NI"
  },
  {
    name_es: "Niger",
    name_en: "Niger",
    code: "Niger",
    phone_code: "+227",
    country_code: "NE"
  },
  {
    name_es: "Nigeria",
    name_en: "Nigeria",
    code: "Nigeria",
    phone_code: "+234",
    country_code: "NG"
  },
  {
    name_es: "Niue",
    name_en: "Niue",
    code: "Niue",
    phone_code: "+683",
    country_code: "NU"
  },
  {
    name_es: "Noruega",
    name_en: "Norway",
    code: "Noruega",
    phone_code: "+47",
    country_code: "NO"
  },
  {
    name_es: "Nueva Caledonia",
    name_en: "New Caledonia",
    code: "Nueva Caledonia",
    phone_code: "+687",
    country_code: "NC"
  },
  {
    name_es: "Nueva Zelanda",
    name_en: "New Zealand",
    code: "Nueva Zelanda",
    phone_code: "+64",
    country_code: "NZ"
  },
  {
    name_es: "Omán",
    name_en: "Oman",
    code: "Omán",
    phone_code: "+968",
    country_code: "OM"
  },
  {
    name_es: "Pakistán",
    name_en: "Pakistan",
    code: "Pakistán",
    phone_code: "+92",
    country_code: "PK"
  },
  {
    name_es: "Palaos",
    name_en: "Palau",
    code: "Palaos",
    phone_code: "+680",
    country_code: "PW"
  },
  {
    name_es: "Panamá",
    name_en: "Panama",
    code: "Panamá",
    phone_code: "+507",
    country_code: "PA"
  },
  {
    name_es: "Papúa Nueva Guinea",
    name_en: "Papua New Guinea",
    code: "Papúa Nueva Guinea",
    phone_code: "+675",
    country_code: "PG"
  },
  {
    name_es: "Paraguay",
    name_en: "Paraguay",
    code: "Paraguay",
    phone_code: "+595",
    country_code: "PY"
  },
  {
    name_es: "Perú",
    name_en: "Peru",
    code: "Perú",
    phone_code: "+51",
    country_code: "PE"
  },
  {
    name_es: "Polinesia Francesa",
    name_en: "French Polynesia",
    code: "Polinesia Francesa",
    phone_code: "+689",
    country_code: "PF"
  },
  {
    name_es: "Polonia",
    name_en: "Poland",
    code: "Polonia",
    phone_code: "+48",
    country_code: "PL"
  },
  {
    name_es: "Portugal",
    name_en: "Portugal",
    code: "Portugal",
    phone_code: "+351",
    country_code: "PT"
  },
  {
    name_es: "Puerto Rico",
    name_en: "Puerto Rico",
    code: "Puerto Rico",
    phone_code: "+1",
    country_code: "PR"
  },
  {
    name_es: "Qatar",
    name_en: "Qatar",
    code: "Qatar",
    phone_code: "+971",
    country_code: "QA"
  },
  {
    name_es: "Reino Unido",
    name_en: "United Kingdom",
    code: "Reino Unido",
    phone_code: "+44",
    country_code: "GB"
  },
  {
    name_es: "República Centroafricana",
    name_en: "Central African Republic",
    code: "República Centroafricana",
    phone_code: "+236",
    country_code: "CF"
  },
  {
    name_es: "República Checa",
    name_en: "Czech Republic",
    code: "República Checa",
    phone_code: "+420",
    country_code: "CZ"
  },
  {
    name_es: "República de Macedonia",
    name_en: "Macedonia, The Former Yugoslav Republic of",
    code: "República de Macedonia",
    phone_code: "+389",
    country_code: "MK"
  },
  {
    name_es: "República Democrática del Congo",
    name_en: "Congo, The Democratic Republic of the",
    code: "República Democrática del Congo",
    phone_code: "+243",
    country_code: "CD"
  },
  {
    name_es: "República Dominicana",
    name_en: "Dominican Republic",
    code: "República Dominicana",
    phone_code: "+1",
    country_code: "DO"
  },
  {
    name_es: "Reunion",
    name_en: "Reunion",
    code: "Reunion",
    phone_code: "+262",
    country_code: "RE"
  },
  {
    name_es: "Ruanda",
    name_en: "Rwanda",
    code: "Ruanda",
    phone_code: "+250",
    country_code: "RW"
  },
  {
    name_es: "Rumania",
    name_en: "Romania",
    code: "Rumania",
    phone_code: "+40",
    country_code: "RO"
  },
  {
    name_es: "Rusia",
    name_en: "Russia",
    code: "Rusia",
    phone_code: "+7",
    country_code: "RU"
  },
  {
    name_es: "Samoa",
    name_en: "Samoa",
    code: "Samoa",
    phone_code: "+685",
    country_code: "WS"
  },
  {
    name_es: "Samoa Americana",
    name_en: "American Samoa",
    code: "Samoa Americana",
    phone_code: "+1",
    country_code: "AS"
  },
  {
    name_es: "San Cristóbal y Nieves",
    name_en: "Saint Kitts & Nevis",
    code: "San Cristóbal y Nieves",
    phone_code: "+1",
    country_code: "KN"
  },
  {
    name_es: "San Marino",
    name_en: "San Marino",
    code: "San Marino",
    phone_code: "+378",
    country_code: "SM"
  },
  {
    name_es: "San Pedro y Miquelon",
    name_en: "Saint Pierre & Miquelon",
    code: "San Pedro y Miquelon",
    phone_code: "+508",
    country_code: "PM"
  },
  {
    name_es: "San Vicente y las Granadinas",
    name_en: "Saint Vincent & the Grenadines",
    code: "San Vicente y las Granadinas",
    phone_code: "+1",
    country_code: "VC"
  },
  {
    name_es: "Santa Elena",
    name_en: "Saint Helena",
    code: "Santa Elena",
    phone_code: "+290",
    country_code: "SH"
  },
  {
    name_es: "Santa Lucía",
    name_en: "Saint Lucia",
    code: "Santa Lucía",
    phone_code: "+1",
    country_code: "LC"
  },
  {
    name_es: "Santo Tomé y Príncipe",
    name_en: "Sao Tome & Principe",
    code: "Santo Tomé y Príncipe",
    phone_code: "+239",
    country_code: "ST"
  },
  {
    name_es: "Senegal",
    name_en: "Senegal",
    code: "Senegal",
    phone_code: "+221",
    country_code: "SN"
  },
  {
    name_es: "Serbia",
    name_en: "Serbia",
    code: "Serbia",
    phone_code: "+381",
    country_code: "RS"
  },
  {
    name_es: "Seychelles",
    name_en: "Seychelles",
    code: "Seychelles",
    phone_code: "+248",
    country_code: "SC"
  },
  {
    name_es: "Sierra Leona",
    name_en: "Sierra Leone",
    code: "Sierra Leona",
    phone_code: "+232",
    country_code: "SL"
  },
  {
    name_es: "Singapur",
    name_en: "Singapore",
    code: "Singapur",
    phone_code: "+65",
    country_code: "SG"
  },
  {
    name_es: "Siria",
    name_en: "Syrian Arab Republic",
    code: "Siria",
    phone_code: "+963",
    country_code: "SY"
  },
  {
    name_es: "Somalia",
    name_en: "Somalia",
    code: "Somalia",
    phone_code: "+252",
    country_code: "SO"
  },
  {
    name_es: "Sri Lanka",
    name_en: "Sri Lanka",
    code: "Sri Lanka",
    phone_code: "+94",
    country_code: "LK"
  },
  {
    name_es: "Eswatini",
    name_en: "Eswatini",
    code: "Eswatini",
    phone_code: "+268",
    country_code: "SZ"
  },
  {
    name_es: "Sudáfrica",
    name_en: "South Africa",
    code: "Sudáfrica",
    phone_code: "+27",
    country_code: "ZA"
  },
  {
    name_es: "Sudán",
    name_en: "Sudan",
    code: "Sudán",
    phone_code: "+249",
    country_code: "SD"
  },
  {
    name_es: "Suecia",
    name_en: "Sweden",
    code: "Suecia",
    phone_code: "+46",
    country_code: "SE"
  },
  {
    name_es: "Suiza",
    name_en: "Switzerland",
    code: "Suiza",
    phone_code: "+41",
    country_code: "CH"
  },
  {
    name_es: "Surinam",
    name_en: "Suriname",
    code: "Surinam",
    phone_code: "+597",
    country_code: "SR"
  },
  {
    name_es: "Svalbard y Jan Mayen",
    name_en: "Svalbard & Jan Mayen",
    code: "Svalbard y Jan Mayen",
    phone_code: "+47",
    country_code: "SJ"
  },
  {
    name_es: "Tailandia",
    name_en: "Thailand",
    code: "Tailandia",
    phone_code: "+66",
    country_code: "TH"
  },
  {
    name_es: "Taiwán",
    name_en: "Taiwan, Province of China",
    code: "Taiwán",
    phone_code: "+886",
    country_code: "TW"
  },
  {
    name_es: "Tanzania",
    name_en: "Tanzania, United Republic of",
    code: "Tanzania",
    phone_code: "+255",
    country_code: "TZ"
  },
  {
    name_es: "Tayikistán",
    name_en: "Tajikistan",
    code: "Tayikistán",
    phone_code: "+992",
    country_code: "TJ"
  },
  {
    name_es: "Territorio Británico del Océano Índico",
    name_en: "British Indian Ocean Territory",
    code: "Territorio Británico del Océano Índico",
    phone_code: "+246",
    country_code: "IO"
  },
  {
    name_es: "Territorios Australes Franceses",
    name_en: "French Southern Territories",
    code: "Territorios Australes Franceses",
    phone_code: "+262",
    country_code: "TF"
  },
  {
    name_es: "Timor Oriental",
    name_en: "Timor-Leste",
    code: "Timor Oriental",
    phone_code: "+670",
    country_code: "TL"
  },
  {
    name_es: "Togo",
    name_en: "Togo",
    code: "Togo",
    phone_code: "+228",
    country_code: "TG"
  },
  {
    name_es: "Tokelau",
    name_en: "Tokelau",
    code: "Tokelau",
    phone_code: "+690",
    country_code: "TK"
  },
  {
    name_es: "Tonga",
    name_en: "Tonga",
    code: "Tonga",
    phone_code: "+676",
    country_code: "TO"
  },
  {
    name_es: "Trinidad y Tobago",
    name_en: "Trinidad & Tobago",
    code: "Trinidad y Tobago",
    phone_code: "+1",
    country_code: "TT"
  },
  {
    name_es: "Turkmenistán",
    name_en: "Turkmenistan",
    code: "Turkmenistán",
    phone_code: "+993",
    country_code: "TM"
  },
  {
    name_es: "Turquía",
    name_en: "Turkey",
    code: "Turquía",
    phone_code: "+90",
    country_code: "TR"
  },
  {
    name_es: "Tuvalu",
    name_en: "Tuvalu",
    code: "Tuvalu",
    phone_code: "+688",
    country_code: "TV"
  },
  {
    name_es: "Túnez",
    name_en: "Tunisia",
    code: "Túnez",
    phone_code: "+216",
    country_code: "TN"
  },
  {
    name_es: "Ucrania",
    name_en: "Ukraine",
    code: "Ucrania",
    phone_code: "+380",
    country_code: "UA"
  },
  {
    name_es: "Uganda",
    name_en: "Uganda",
    code: "Uganda",
    phone_code: "+256",
    country_code: "UG"
  },
  {
    name_es: "Uruguay",
    name_en: "Uruguay",
    code: "Uruguay",
    phone_code: "+598",
    country_code: "UY"
  },
  {
    name_es: "Uzbekistán",
    name_en: "Uzbekistan",
    code: "Uzbekistán",
    phone_code: "+998",
    country_code: "UZ"
  },
  {
    name_es: "Vanuatu",
    name_en: "Vanuatu",
    code: "Vanuatu",
    phone_code: "+678",
    country_code: "VU"
  },
  {
    name_es: "Venezuela",
    name_en: "Venezuela",
    code: "Venezuela",
    phone_code: "+58",
    country_code: "VE"
  },
  {
    name_es: "Vietnam",
    name_en: "Vietnam",
    code: "Vietnam",
    phone_code: "+84",
    country_code: "VN"
  },
  {
    name_es: "Wallis y Futuna",
    name_en: "Wallis & Futuna",
    code: "Wallis y Futuna",
    phone_code: "+681",
    country_code: "WF"
  },
  {
    name_es: "Yemen",
    name_en: "Yemen",
    code: "Yemen",
    phone_code: "+967",
    country_code: "YE"
  },
  {
    name_es: "Yibuti",
    name_en: "Djibouti",
    code: "Yibuti",
    phone_code: "+253",
    country_code: "DJ"
  },
  {
    name_es: "Zambia",
    name_en: "Zambia",
    code: "Zambia",
    phone_code: "+260",
    country_code: "ZM"
  },
  {
    name_es: "Zimbabue",
    name_en: "Zimbabwe",
    code: "Zimbabue",
    phone_code: "+263",
    country_code: "ZW"
  },
  {
    name_es: "Åland",
    name_en: "Åland Islands",
    code: "Åland",
    phone_code: "+358",
    country_code: "AX"
  }
]

export default countries;
