import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CssTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
    },
    "& label.Mui-focused": {
        color: "#131F3E",
    },
});

const BaseFillTextField = (props) => {
    const { id, label, value, onChange, error, onBlur, InputProps, disabled } = props;

    return (
        <CssTextField
            fullWidth
            variant="filled"
            size="small"
            id={id}
            label={label}
            value={value}
            onChange={onChange}
            error={error}
            onBlur={onBlur}
            InputProps={InputProps}
            disabled={disabled}
            inputProps={{ maxLength: 50 }}
        />
    );
};

export default BaseFillTextField;
