/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    Chip,
    IconButton,
    Popover,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { initiateSocket, subscribeToChat, newUserResponse } from "../../services/Socket";
import { CopyToClipboard } from "react-copy-to-clipboard";
/** MUI icons */
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckIcon from "@mui/icons-material/Check";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BiotechIcon from "@mui/icons-material/Biotech";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import { useTranslation } from "react-i18next";
import casesServices from "../../services/case";
import commServices from "../../services/communication";
import { useState } from "react";
import AlertDialog from "../ui/AlertDialog";
import LightTooltip from "../ui/LightTooltip";

import TablePaginationActions from "../ui/TablePagActions";
import { useSelector } from "react-redux";
import ChatModal from "../ui/Chat";
import ChatIconCustom from "../../assets/chat-icon-inbox.svg";
import ChatIconCustomNotif from "../../assets/chat-icon-inbox-notif.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect } from "react";
import moment from "moment";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.03)",
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const CustomPopover = styled(Popover)({
    ".MuiPopover-paper": {
        borderRadius: 20,
    },
});

const CustomIcon = styled(CancelIcon)({
    "&:hover": {
        color: "#FF007A",
    },
});

const SolvedTableAdmin = (props) => {
    const {
        columns,
        priorities,
        setIsLoading,
        user_id,
        company_id,
        franchise_id,
        setCases,
        reloadCases,
        mainUsers,
        devUsers,
        cases,
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const [open, setOpen] = useState(false);
    const [ticketID, setTicketID] = useState("");

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [room, setRoom] = useState(null);
    const [openChat, setOpenChat] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [chatDisabled, setChatDisabled] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCase, setSelectedCase] = useState({});
    const [deletedID, setDeletedID] = useState("");
    const [showModalDel, setShowModalDel] = useState(false);
    const [copied, setCopied] = useState(false);
    const [caseInfo, setCaseInfo] = useState({});
    const [chatInfo, setChatInfo] = useState({});
    const [rowsSolved, setRowsSolved] = useState([]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsSolved.length) : 0;

    const openPop = Boolean(anchorEl);
    const id = openPop ? "simple-popover" : undefined;

    const deleteCase = async () => {
        setIsLoading(true);
        await casesServices
            .deleteCase({ case_id: deletedID })
            .then(() => reloadCases())
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
        handleCloseModal();
    };

    const convertTZ = (date, tzString) => {
        return new Date(
            (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString })
        );
    };

    const handleCloseModal = () => {
        setShowModalDel(false);
        setDeletedID("");
    };

    const handleClick = (event, val) => {
        setAnchorEl(event.currentTarget);
        setSelectedCase(val);
    };

    const handleClosePop = () => {
        setAnchorEl(null);
        setSelectedCase({});
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleShowModal = (id) => {
        setOpen(true);
        setTicketID(id);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleReopen = async () => {
        setIsLoading(true);

        const params = {
            case_id: ticketID,
        };

        await casesServices
            .reopenAdminCase(params)
            .then(async () => {
                let params2 = {
                    user_id: user_id,
                    company_id: company_id,
                    franchise_id: franchise_id,
                };

                await casesServices
                    .getAdminCases(params2)
                    .then((data) => {
                        setCases(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        handleCloseDialog();
        setIsLoading(false);
    };

    useEffect(() => {
        if (cases) {
            const resolvedData = cases.resolved.map((item) => {
                return {
                    id: item.case_id,
                    global_case: item.global_case_number,
                    case: item.case_number,
                    conversion_id: item.conversion_id ? (
                        <LightTooltip title={item.conversion_id}>{item.conversion_id.slice(-6)}</LightTooltip>
                    ) : (
                        ""
                    ),
                    conversion_status: item.conversion_status,
                    creation_date: moment(convertTZ(item.creation_date, "America/New_York")).format(
                        `${todos.dateFormat} HH:mm`
                    ),
                    franchise: item.franchise_name,
                    company: item.company_name,
                    case_user: item.case_user,
                    case_user_email: item.case_user_email,
                    case_reason:
                        item.case_reason === 1
                            ? t("inbox.conversions")
                            : item.case_reason === 2
                            ? t("inbox.newBank")
                            : t("inbox.directRequest"),
                    mainUser: item.case_responsible,
                    devUser: item.case_developer_manager,
                    testUser: item.case_test_manager,
                    priority: item.priority,
                    status: item.case_status,
                    unread: item.unread,
                    origin: item.case_reason,
                    phone_code: item.phone_code,
                    phone_number: item.phone_number,
                    bank_name: item.bank_name ? item.bank_name : "",
                    bank_currency: item.bank_currency ? item.bank_currency : "",
                    bank_country: item.bank_country ? item.bank_country : "",
                };
            });

            setRowsSolved(resolvedData);
        }
    }, [cases, company_id, franchise_id, setCases, setIsLoading, t, todos.dateFormat, user_id]);

    const connectSocket = async (case_id, reason, convID, disabled) => {
        setIsLoading(true);
        setRoom(case_id);

        let params1 = {
            case_id: case_id,
        };

        await casesServices
            .getCaseInfo(params1)
            .then(async (data) => {
                setCaseInfo(data.caseInfo);
                setChatInfo(data.chatCase);

                initiateSocket(
                    case_id,
                    todos.userInfo,
                    reason === 1 && convID ? "conversion" : "general",
                    ""
                );
                subscribeToChat((err, data) => {
                    if (err) return;
                    setChat((oldChats) => [...oldChats, data]);
                });
                newUserResponse((err, data) => {
                    if (err) return;
                    setUsers(data);
                });
                setChatDisabled(disabled);
                setOpenChat(true);
                await commServices
                    .updateStatusMess({
                        room: case_id,
                        user: todos.userInfo._id,
                    })
                    .catch((err) => console.log(err));
                reloadCases();
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const showModal = (id) => {
        setDeletedID(id);
        setShowModalDel(true);
    };

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 5000);
    };

    return (
        <>
            <TableContainer>
                <Table
                    sx={{ borderCollapse: "separate", borderSpacing: "0 6px", minWidth: 1400 }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <StyledTableRow>
                            {columns.map((item) => (
                                <StyledTableCell
                                    key={item.name}
                                    align={item.align ?? "left"}
                                    style={{ width: item.width }}
                                >
                                    {item.title}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? rowsSolved.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rowsSolved
                        ).map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                    <Button onClick={(event) => handleClick(event, row)} size="small">
                                        {row.global_case}
                                    </Button>
                                </StyledTableCell>
                                <StyledTableCell>{row.franchise}</StyledTableCell>
                                <StyledTableCell align="center">{row.creation_date}</StyledTableCell>
                                <StyledTableCell>
                                    {row.status === 1 ? (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography color="#FFB23F">{t("inbox.analysis")}</Typography>
                                            <BarChartIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                        </Stack>
                                    ) : row.status === 2 ? (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography color="#3FA7D6">{t("inbox.progress")}</Typography>
                                            <EngineeringIcon sx={{ color: "#3FA7D6" }} fontSize="inherit" />
                                        </Stack>
                                    ) : row.status === 3 ? (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography color="#005792">{t("inbox.test")}</Typography>
                                            <BiotechIcon sx={{ color: "#005792" }} fontSize="inherit" />
                                        </Stack>
                                    ) : row.status === 4 ? (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography color="#00C8C8">{t("inbox.solvedKiiper")}</Typography>
                                            <CheckIcon sx={{ color: "#00C8C8" }} fontSize="inherit" />
                                        </Stack>
                                    ) : (
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography color="#00B147">{t("inbox.solvedUser")}</Typography>
                                            <CheckIcon sx={{ color: "#00B147" }} fontSize="inherit" />
                                        </Stack>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {mainUsers.find((item) => item.value === row.mainUser)?.name ?? ""}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {devUsers.find((item) => item.value === row.devUser)?.name ?? ""}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {mainUsers.find((item) => item.value === row.testUser)?.name ?? ""}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {
                                        <Typography
                                            color={priorities.find((item) => item.value === row.priority)?.color}
                                        >
                                            {priorities.find((item) => item.value === row.priority)?.name}
                                        </Typography>
                                    }
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.actions ?? (
                                        <Stack direction="row" spacing={1}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disableElevation
                                                endIcon={
                                                    <Box
                                                        component="img"
                                                        src={row.unread > 0 ? ChatIconCustomNotif : ChatIconCustom}
                                                        sx={{ width: "15px", height: "15px" }}
                                                    />
                                                }
                                                onClick={() =>
                                                    connectSocket(
                                                        row.id,
                                                        row.origin,
                                                        row.conversion_id,
                                                        row.status === 5
                                                    )
                                                }
                                                sx={{ whiteSpace: "nowrap", width: { lg: 132 } }}
                                            >
                                                Chat
                                            </Button>
                                            <Button
                                                disableElevation
                                                onClick={() => handleShowModal(row.id)}
                                                size="small"
                                                disabled={row.status !== 5 && row.status !== 4}
                                                color="error"
                                                sx={{ width: { lg: 132 } }}
                                            >
                                                {t("inbox.reopen")}
                                            </Button>
                                            <IconButton onClick={() => showModal(row.id)}>
                                                <CustomIcon />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, { label: t("miscellaneous.all"), value: -1 }]}
                                colSpan={12}
                                count={rowsSolved.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rowsPerPage",
                                    },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage={t("dashboard.rowsPerPage")}
                                labelDisplayedRows={(page) =>
                                    `${page.from}-${page.to === -1 ? page.count : page.to} ${t("team.unlinkText2")} ${
                                        page.count
                                    }`
                                }
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
                <AlertDialog
                    open={open}
                    onClose={handleCloseDialog}
                    type="warning"
                    maxWidth="sm"
                    agreeBtnLabel={t("inbox.reopen")}
                    disagreeBtnLabel={t("dialog.cancel")}
                    title={t("dialog.warning")}
                    message={
                        <Typography textAlign="justify" fontSize={14}>
                            {t("inbox.msgReopen")}
                        </Typography>
                    }
                    agreeAction={handleReopen}
                />
            </TableContainer>
            <CustomPopover
                id={id}
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClosePop}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                sx={{ borderRadius: 20 }}
            >
                <Box sx={{ padding: "10px 30px", backgroundColor: "#6544DB" }}>
                    <Typography variant="h2" sx={{ color: "white", pb: 1 }}>
                        {selectedCase.company}
                    </Typography>
                    <Typography sx={{ color: "white", fontSize: 12 }}>
                        {t("inbox.origin")}: {selectedCase.case_reason}
                    </Typography>
                    <Typography sx={{ color: "white", fontSize: 12 }}>
                        {t("inbox.interID")}: {selectedCase.case}
                    </Typography>
                    {selectedCase.origin === 1 && (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography sx={{ color: "white", fontSize: 12 }}>
                                {t("inbox.convID")}: {selectedCase.conversion_id}
                            </Typography>
                            <Chip
                                label={
                                    selectedCase.conversion_status === 3
                                        ? t("converter.successful")
                                        : selectedCase.conversion_status === 4
                                        ? t("converter.inReview")
                                        : t("converter.invalid")
                                }
                                color={
                                    selectedCase.conversion_status === 3
                                        ? "success"
                                        : selectedCase.conversion_status === 4
                                        ? "warning"
                                        : "error"
                                }
                                size="small"
                                sx={{ color: "white", fontSize: 12 }}
                            />
                        </Stack>
                    )}
                    {selectedCase.origin === 1 && (
                        <Typography sx={{ color: "white", fontSize: 12 }}>
                            {selectedCase.bank_name} - {selectedCase.bank_country} - {selectedCase.bank_currency}
                        </Typography>
                    )}
                </Box>
                <Box sx={{ padding: "10px 30px" }}>
                    <Typography variant="h2" color="primary" sx={{ pb: 1 }}>
                        {selectedCase.case_user}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={0.5} display="flex" sx={{ pb: 1 }}>
                        <Typography sx={{ textDecoration: "underline" }}>{selectedCase.case_user_email}</Typography>
                        <CopyToClipboard text={selectedCase.case_user_email} onCopy={() => handleCopy()}>
                            <div>
                                <LightTooltip title={copied ? t("inbox.copied") : t("inbox.copy")} position="bottom">
                                    <FileCopyIcon fontSize="inherit" sx={{ cursor: "pointer" }} />
                                </LightTooltip>
                            </div>
                        </CopyToClipboard>
                    </Stack>
                    <Typography sx={{ pb: 1 }}>
                        {selectedCase.phone_code} {selectedCase.phone_number}
                    </Typography>
                </Box>
            </CustomPopover>
            <ChatModal
                room={room}
                setRoom={setRoom}
                open={openChat}
                setOpen={setOpenChat}
                setChat={setChat}
                chat={chat}
                users={users}
                chatDisabled={chatDisabled}
                caseInfo={caseInfo}
                setCaseInfo={setCaseInfo}
                reloadCases={reloadCases}
                chatInfo={chatInfo}
                setChatInfo={setChatInfo}
                connectSocket={connectSocket}
                params={4}
            />
            <AlertDialog
                open={showModalDel}
                onClose={handleCloseModal}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("team.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={
                    <Box sx={{ textAlign: "center" }}>
                        <Typography>{t("inbox.sureDel1")}</Typography>
                        <Typography>{t("inbox.sureDel2")}</Typography>
                    </Box>
                }
                agreeAction={deleteCase}
                maxWidth="xs"
            />
        </>
    );
};

export default SolvedTableAdmin;
