import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 20,
        padding: 15,
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
}));

const BootstrapDialog2 = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 0,
        padding: 0,
    },
    "& .MuiDialogContent-root": {
        padding: 0,
    },
}));

const BootstrapDialog3 = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 15,
        padding: 0,
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(1),
        overflow: "auto",
        "&::-webkit-scrollbar": {
            width: "15px",
            height: "15px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 15px 15px 15px #c7c9d1",
            borderRadius: "15px",
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#404963",
            borderRadius: "15px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#031851",
        },
    },
}));

export default function SimpleDialog(props) {
    const { open, handleClose, content, maxWidth, invoice, preview, actions } = props;

    if (invoice) {
        return (
            <BootstrapDialog2 open={open} onClose={handleClose} maxWidth={maxWidth}>
                <DialogContent>{content}</DialogContent>
            </BootstrapDialog2>
        );
    } else if (preview) {
        return (
            <BootstrapDialog3 open={open} onClose={handleClose} maxWidth={maxWidth}>
                <DialogContent>{content}</DialogContent>
                <DialogActions sx={{ display: "flex", justifyContent: "flex-end", width: "98%" }}>{actions}</DialogActions>
            </BootstrapDialog3>
        );
    } else {
        return (
            <BootstrapDialog open={open} onClose={handleClose} maxWidth={maxWidth}>
                <DialogContent>{content}</DialogContent>
            </BootstrapDialog>
        );
    }
}
