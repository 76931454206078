import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { useSelector } from "react-redux";

const CancelPlanForm = (props) => {
    const { reasons, setReasons, details, setDetails, isError, currentPlanInfo } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const handleChange = (event) => {
        setReasons(event.target.value);
        setDetails("");
    };

    const handleChangeText = (event) => {
        setDetails(event.target.value);
    };

    const valuesofRadio = [
        { id: 1, name: t("plans.notEnoughValue") },
        { id: 2, name: t("plans.notUseFunc") },
        { id: 3, name: t("plans.techProblems") },
        { id: 4, name: t("plans.noSupport") },
        { id: 5, name: t("plans.prefNotTell") },
        { id: 6, name: t("plans.otherReason") },
    ];

    const getDueDate = moment.utc(currentPlanInfo?.since_date).format(todos.dateFormat);

    return (
        <Box sx={{ ml: 1 }}>
            <Typography>{t("plans.onceYouCancel")}</Typography>
            <ul>
                <li>
                    {t("plans.yourSubs")} <b>{getDueDate}</b>
                </li>
                <li>{t("plans.youCanAccessHist")}</li>
                <li>
                    {t("plans.theConvSubs")} <b>{t("subscription.conversBalance")}</b>
                </li>
            </ul>
            <Typography variant="h3" gutterBottom>
                {t("plans.pleaseTellReason")}
            </Typography>
            <Typography color="primary" variant="h3">
                {t("plans.commentsHelp")}
            </Typography>
            <FormControl sx={{ mt: 2, mb: 2 }} error={isError && !reasons}>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={reasons}
                    onChange={handleChange}
                >
                    {valuesofRadio.map((item, index) => (
                        <FormControlLabel
                            key={index}
                            value={item.id}
                            control={<Radio size="small" />}
                            label={item.name}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            <TextField
                id="outlined-multiline-flexible"
                label={t("plans.pleaseExplain")}
                multiline
                maxRows={4}
                fullWidth
                value={details}
                onChange={handleChangeText}
                disabled={reasons !== "6"}
                error={isError && reasons === "6" && !details.trim()}
            />
        </Box>
    );
};

export default CancelPlanForm;
