import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
/* Componentes */
import BackDashboard from "../components/layout/BackDashboard";
import DashboardTable from "../components/dashboard/DashboardTable";
import dashboardService from "../services/dashboard";
import accountsService from "../services/accounts";
import companyService from "../services/company";
import conversionService from "../services/conversion";
import userCompanyService from "../services/user_company";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";
import { useDispatch, useSelector } from "react-redux";
import { addSubscription } from "../redux/userSlice";
import HistoryConv from "../components/dashboard/HistoryConv";

import moment from "moment";

const Dashboard = () => {
    /* React redux */
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const todos = useSelector((state) => state.value);

    const [xeroView, setXeroView] = useState(false);
    const [franchise, setFranchise] = useState(null);
    const [company, setCompany] = useState(null);
    const [dashboardInfo, setDashboardInfo] = useState([]);
    const [type, setType] = useState("Firm");
    const [integration, setIntegration] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [banksFilters, setBanksFilters] = useState([]);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [franchiseInfo, setFranchiseInfo] = useState(null);
    const [companiesFilters, setCompaniesFilters] = useState([]);
    const [checkGrouped, setCheckGrouped] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [history, setHistory] = useState(false);
    const [conversions, setConversions] = useState([]);
    const [usersFilters, setUsersFilters] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [finalBanks, setFinalBanks] = useState([]);
    const [isReload, setIsReload] = useState(false);

    useEffect(() => {
        (async () => {
            setShowSkeleton(true);

            if (todos.companyID || todos.franchiseID) {
                await dashboardService
                    .getDashboard({
                        company_id: todos.companyID ?? undefined,
                        franchise_id: todos.franchiseID ?? undefined,
                        grouped: todos.isGrouped ?? false,
                    })
                    .then(async (response) => {
                        const banksId = response.map((item) => item.bank_id);
                        const currentBanks = [];

                        setDashboardInfo(response);

                        await accountsService
                            .getBanksFilter()
                            .then((data) => {
                                data?.forEach((bank) => {
                                    if (bank._id.includes(",")) {
                                        let ids = bank._id.split(",");
                                        ids?.forEach((id) => {
                                            if (banksId?.includes(id)) {
                                                currentBanks.push(bank);
                                            }
                                        });
                                    } else {
                                        if (banksId?.includes(bank._id)) {
                                            currentBanks.push(bank);
                                        }
                                    }
                                });
                                setBanksFilters(data);
                                setFinalBanks(currentBanks);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            setHistory(false);
            setShowSkeleton(false);
        })();
    }, [todos.companyID, todos.franchiseID, todos.isGrouped, isReload]);

    useEffect(() => {
        (async () => {
            if (todos.isGrouped) {
                setCheckGrouped(true);
                setHistory(false);
                setXeroView(false);
            } else {
                setCheckGrouped(false);
                setHistory(false);
            }
        })();
    }, [todos.isGrouped]);

    useEffect(() => {
        (async () => {
            setShowSkeleton(true);
            setFranchise(todos.franchiseID ?? null);
            setType(todos.companyID ? "Smb" : todos.franchiseID ? "Firm" : "");
            setIntegration(null);
            if (todos.franchiseID) {
                await companyService
                    .getFranchiseDetails(todos.franchiseID)
                    .then(async (data) => {
                        dispatch(
                            addSubscription({
                                value: data.id_status,
                                last_value: data.id_last_status,
                                plan: data.subscription_plan,
                            })
                        );
                        setFranchiseInfo(data);

                        const params = {
                            franchise_id: todos.franchiseID,
                            date: moment().format(),
                        };

                        await userCompanyService
                            .updateLastAccess(params)
                            .then()
                            .catch((error) => {
                                console.log(error);
                            });

                        if (
                            (location?.state?.redirect && data.id_status === 6) ||
                            (location?.state?.redirect && location?.state?.tab)
                        ) {
                            navigate("/subscription", {
                                state: {
                                    tab: data.id_status === 6 || location.state.tab === "0" ? 0 : 1,
                                    isCart: location?.state?.isCart,
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                if (!todos.isGrouped) {
                    await dashboardService
                        .getCompaniesFirm({ franchise_id: todos.franchiseID })
                        .then((data) => {
                            setCompaniesFilters(data);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            } else {
                setFranchiseInfo(null);
            }
            setShowSkeleton(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [todos.companyID, todos.franchiseID, todos.isGrouped]);

    useEffect(() => {
        (async () => {
            if (todos.isGrouped) {
                await dashboardService
                    .getCompaniesUser({ user_id: todos.userInfo._id })
                    .then((data) => {
                        setCompaniesFilters(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        })();
    }, [todos.isGrouped, todos.userInfo._id]);

    useEffect(() => {
        (async () => {
            if (!todos.isGrouped) {
                setCompany(todos.companyID ?? null);
                setType(todos.companyID ? "Smb" : todos.franchiseID ? "Firm" : "");
                if (todos.companyID) {
                    await companyService
                        .getCompanyDetails(todos.companyID)
                        .then((data) => {
                            setCompanyInfo(data);
                            setIntegration(data.system_integration);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    setCompaniesFilters([]);
                } else {
                    setCompanyInfo(null);
                    setIntegration(null);
                }
            }
            setXeroView(false);
        })();
    }, [todos.companyID, todos.franchiseID, todos.allComData, todos.isGrouped]);

    const downloadXeroDoc = async (data) => {
        setIsLoading(true);
        await dashboardService
            .downloadKiiperTransactions({ account_id: data._id })
            .then(async (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${data.account_name}_UpdateTransactions_${moment().format("DD-MM-YYYY")}.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                await conversionService
                    .getImportUrl({ account_id: data._id })
                    .then((response) => {
                        window.open(response, "_blank");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const downloadXeroDocRev = async (data) => {
        setIsLoading(true);

        await dashboardService
            .downloadSystemTransactions({ account_id: data._id })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${data.account_name}_ReviewTransactions_${moment().format("DD-MM-YYYY")}.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const handleClickRefresh = async () => {
        setIsLoading(true);

        await dashboardService
            .updateDashboard({ company_id: company })
            .then(() => {
                setIsReload(!isReload);
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const handleClickRefreshAcc = async (accID) => {
        setIsLoading(true);

        await dashboardService
            .updateDashboard({ account_id: accID })
            .then(() => {
                setIsReload(!isReload);
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    return (
        <BackDashboard fix={franchise && company && !todos.isGrouped}>
            {!xeroView && !history && (
                <DashboardTable
                    integration={integration}
                    grupal={todos.isGrouped ?? false}
                    setXeroView={setXeroView}
                    xeroView={xeroView}
                    franchise={franchise}
                    company={company}
                    data={dashboardInfo}
                    type={type}
                    setIsLoading={setIsLoading}
                    setDashboardInfo={setDashboardInfo}
                    franchiseInfo={franchiseInfo}
                    companyInfo={companyInfo}
                    companiesFilters={companiesFilters}
                    checkGrouped={checkGrouped}
                    showSkeleton={showSkeleton}
                    setShowSkeleton={setShowSkeleton}
                    history={history}
                    setHistory={setHistory}
                    setConversions={setConversions}
                    setUsersFilters={setUsersFilters}
                    setAccounts={setAccounts}
                    finalBanks={finalBanks}
                    setFinalBanks={setFinalBanks}
                    banksFilters={banksFilters}
                    downloadXeroDoc={downloadXeroDoc}
                    downloadXeroDocRev={downloadXeroDocRev}
                    handleClickRefresh={handleClickRefresh}
                    handleClickRefreshAcc={handleClickRefreshAcc}
                />
            )}
            {history && (
                <HistoryConv
                    integration={integration}
                    grupal={todos.isGrouped ?? false}
                    setXeroView={setXeroView}
                    xeroView={xeroView}
                    franchise={franchise}
                    company={company}
                    data={conversions}
                    type={type}
                    setIsLoading={setIsLoading}
                    setDashboardInfo={setDashboardInfo}
                    franchiseInfo={franchiseInfo}
                    companyInfo={companyInfo}
                    checkGrouped={checkGrouped}
                    showSkeleton={showSkeleton}
                    setShowSkeleton={setShowSkeleton}
                    history={history}
                    setHistory={setHistory}
                    setConversions={setConversions}
                    usersFilters={usersFilters}
                    setAccounts={setAccounts}
                    accounts={accounts}
                />
            )}
            <SimpleBackdrop open={isLoading} />
        </BackDashboard>
    );
};

export default Dashboard;
