const util = {
    // Check and save the language variable in session storage
    getSessionStorageLanguage: () => {
        let language = "en";

        if (sessionStorage.getItem("lng")) {
            language = sessionStorage.getItem("lng");
            return language;
        }
        sessionStorage.setItem("lng", language);
        return language;
    },
};

export default util;
