import axios from "axios";

/* Script que contiene las llamadas a la API */
const chat = {
    // Peticion para eliminar un caso
    deleteChat: (params) => {
        const request = axios.delete("/removeChat", {
            params: params,
        });

        return request.then((response) => response);
    },
};

export default chat;
