import axios from "axios";

/* Script que contiene las llamadas a la API */
const userConfig = {
    // Petición para obtener la información de la configuración de un usuario.
    getUserConfig: (id) => {
        const request = axios.get(`/getUserConfig/${id}`);

        return request.then((response) => response.data);
    },

    // Petición para actualizar perfil de usuario.
    saveUserConfig: (params) => {
        const request = axios.post(`/saveUserConfig`, params);

        return request.then((response) => response.data);
    },
}

export default userConfig;
