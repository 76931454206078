/** React imports */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/** MUI Imports */
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";

/** Styles */
// import classes from "./ConvertButton.module.css";

/** MUI Icons */
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useDispatch, useSelector } from "react-redux";
import { addCompanyID, addSelected } from "../../../redux/userSlice";

const ConvertButton = (props) => {
    const { setSelectedCompany, setMobileOpen, setOpenCollapse } = props;

    //Buttom group
    const [openBtnGroupSend, setOpenBtnGroupSend] = useState(false);
    const anchorRefBtnGroup = useRef(null);
    const [selectedIndexBtnGroup, setSelectedIndexBtnGroup] = useState(null);

    const todos = useSelector((state) => state.value);
    const dispatch = useDispatch();

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const navigate = useNavigate();

    const optionsGroupSend = [
        { data: t("navBar.eecc"), id: 0 },
        { data: t("navBar.salesInvoices"), id: 1 },
        { data: t("navBar.purchaseInvoices"), id: 2 },
        // { data: t("navBar.receipts"), id: 3 },
    ];

    useEffect(() => {
        if (todos.selected === "ddlAccounts") {
            setSelectedIndexBtnGroup(0);
        } else if (todos.selected === "ddlSalesInv") {
            setSelectedIndexBtnGroup(1);
        } else if (todos.selected === "ddlPurchaseInv") {
            setSelectedIndexBtnGroup(2);
        }
    }, [todos.selected]);

    const handleClick = (index = null) => {

        setMobileOpen(false)
        setOpenCollapse(false)
        if (index !== null) {
            if (index === 0) {
                dispatch(addSelected("ddlAccounts"));
                navigate("/convert");
            } else if (index === 1) {
                
                dispatch(addSelected("ddlSalesInv"));
                if (!todos.companyID) {
                    const newComp = todos.allComData.filter((company) => !company.franchise_name)[0];
                    setSelectedCompany(newComp);
                    dispatch(addCompanyID(newComp._id));
                }
                navigate(`/convert/invoices`);
            } else if (index === 2) {
                // Navegar hacia el convertidor de facturas de compra (FALTA)
                dispatch(addSelected("ddlPurchaseInv"));
                if (!todos.companyID) {
                    const newComp = todos.allComData.filter((company) => !company.franchise_name)[0];
                    setSelectedCompany(newComp);
                    dispatch(addCompanyID(newComp._id));
                }
                navigate(`/convert/bills`);
            } else {
                navigate("/convert");
            }
        } else {
            if (todos.selected === "ddlAccounts") {
                navigate("/convert");
                setSelectedIndexBtnGroup(0);
            } else if (todos.selected === "ddlSalesInv") {
                if (!todos.companyID) {
                    const newComp = todos.allComData.filter((company) => !company.franchise_name)[0];
                    setSelectedCompany(newComp);
                    dispatch(addCompanyID(newComp._id));
                }
                navigate(`/convert/invoices`);
                setSelectedIndexBtnGroup(1);
            } else if (todos.selected === "ddlPurchaseInv") {
                if (!todos.companyID) {
                    const newComp = todos.allComData.filter((company) => !company.franchise_name)[0];
                    setSelectedCompany(newComp);
                    dispatch(addCompanyID(newComp._id));
                }
                navigate(`/convert/bills`);
                setSelectedIndexBtnGroup(2);
            } else {
                navigate("/convert");
                setSelectedIndexBtnGroup(0);
            }
        }
    };

    const handleCloseBtnGroup = (event) => {
        if (anchorRefBtnGroup.current && anchorRefBtnGroup.current.contains(event.target)) {
            return;
        }

        setOpenBtnGroupSend(false);
    };

    const handleMenuItemClickBtnGroup = (event, index) => {
        setSelectedIndexBtnGroup(index);
        setOpenBtnGroupSend(false);
        handleClick(index)
    };

    const handleToggleBtnGroup = () => {
        setOpenBtnGroupSend((prevOpenBtnGroupSend) => !prevOpenBtnGroupSend);
    };

    return (
        <>
            <ButtonGroup
                disableElevation
                variant="outlined"
                ref={anchorRefBtnGroup}
                aria-label="split button"
                color="inherit"
            >
                <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                        mr: 0.3,
                        borderRadius: "8px",
                        boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.15)",
                        px: 5,
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            color: "#fff",
                            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.15)",
                        },
                        fontWeight: 500,
                        fontSize: 14
                    }}
                    onClick={() => handleClick(null)}
                >
                    {t("navBar.convert")}
                </Button>
                <Button
                    size="small"
                    aria-controls={
                        openBtnGroupSend ? "split-button-menu" : undefined
                    }
                    aria-expanded={openBtnGroupSend ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    disableElevation
                    variant="contained"
                    color="primary"
                    sx={{
                        borderRadius: "8px",
                        boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.15)",
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            color: "#fff",
                            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.15)",
                        },
                    }}
                    onClick={handleToggleBtnGroup}
                >
                    <PlayArrowIcon sx={{ position: "absolute", transform: "rotate(90deg)", color: "#FFFFF", right: ".5rem", cursor: "pointer", zIndex: 0, pointerEvents: "none", fontSize: "22px" }} />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                    pt: 1,
                }}
                open={openBtnGroupSend}
                anchorEl={anchorRefBtnGroup.current}
                role={undefined}
                transition
                disablePortal
                style={{ width: 270 }}
                modifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [-46, 0], // Ajuste de desplazamiento vertical
                        },
                    },
                ]}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                        }}
                    >
                        <Paper sx={{
                            borderRadius: "20px",
                            boxShadow: "4px 4px 15px rgba(74, 34, 212, 0.15)",
                        }}>
                            <ClickAwayListener
                                onClickAway={handleCloseBtnGroup}
                            >
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {optionsGroupSend.map(
                                        (
                                            { id, data },
                                            index
                                        ) => (
                                            <MenuItem
                                                key={"convertOption_" + id}
                                                selected={
                                                    index ===
                                                    selectedIndexBtnGroup
                                                }
                                                onClick={(event) =>
                                                    handleMenuItemClickBtnGroup(
                                                        event,
                                                        index
                                                    )
                                                }
                                            >
                                                {data}
                                            </MenuItem>
                                        )
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default ConvertButton;
