import { Box, Container, Typography } from "@mui/material";
import GeneralBreadCrumb from "../components/ui/GeneralBreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addCompanyID, addFranchiseID } from "../redux/userSlice";
import classes from "../components/dashboard/DashboardBreadcrumbs.module.css";
import { useTranslation } from "react-i18next";
import BackGeneral from "../components/layout/BackGeneral";
import { useEffect, useState } from "react";
import accountsService from "../services/accounts";
import BanksList from "../components/reconciliations/BanksList";
import BankDetails from "../components/reconciliations/BankDetails";
import ChatTransaction from "../components/reconciliations/ChatTransaction";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";
import { initiateSocket, newUserResponse, subscribeToChat } from "../services/Socket";
import AlertDialog from "../components/ui/AlertDialog";
import BaseSnackbar from "../components/ui/BaseSnackbar";

const Reconciliations = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [t] = useTranslation("global");
    const navigate = useNavigate();

    const [accounts, setAccounts] = useState(null);
    const [selAccount, setSelAccount] = useState("");
    const [selAccountData, setSelAccountData] = useState("");
    const [bankDetails, setBankDetails] = useState("");
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [transInfo, setTransInfo] = useState({});
    const [showModalRecon, setShowModalRecon] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");

    const todos = useSelector((state) => state.value);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    }; 

    const handleClickFranchise = () => {
        dispatch(addFranchiseID(location.state?.franchiseInfo._id));
        dispatch(addCompanyID(null));
        navigate("/dashboard");
    };

    const handleClickComp = () => {
        navigate("/dashboard");
    };

    const handleCloseDialog = () => {
        setShowModalRecon(false);
    };

    const breadcrumbs = [
        <Typography
            variant="h1"
            color="#131F3E"
            className={classes.breadcrumbs}
            onClick={handleClickFranchise}
            sx={{ cursor: "pointer" }}
            key="0"
        >
            {location.state?.franchiseInfo.franchise_name}
        </Typography>,
        <Typography
            key="1"
            variant="h1"
            color="#131F3E"
            sx={{ cursor: "pointer" }}
            className={classes.breadcrumbs}
            onClick={handleClickComp}
        >
            {location.state?.companyInfo.company_name}
        </Typography>,
        <Typography key="3" variant="h1" color="primary" className={classes.breadcrumbs}>
            {t("miscellaneous.conciliations")}
        </Typography>,
    ];

    useEffect(() => {
        (async () => {
            await accountsService
                .getReconciliationBanks({ company_id: location.state?.companyInfo._id })
                .then((accounts) => {
                    setAccounts(accounts);
                })
                .catch((err) => {
                    console.log(err);
                });
        })();
    }, [location.state?.companyInfo._id]);

    const connectSocket = async (transaction) => {
        setTransInfo(transaction);
        setRoom(transaction.kiiper_id);
        initiateSocket(transaction.kiiper_id, todos.userInfo, "transaction", "");
        subscribeToChat((err, data) => {
            if (err) return;
            setChat((oldChats) => [...oldChats, data]);
        });
        newUserResponse((err, data) => {
            if (err) return;
            setUsers(data);
        });
        setOpen(true);
    };
    
    const reloadBankDet = async () => {
        await accountsService
            .getReconciliationBankDetails({ account_id: selAccount })
            .then((details) => {
                setBankDetails(details);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <Box sx={{ backgroundColor: "#fff", px: { xs: 2, md: 5 }, mt: 5 }}>
                <GeneralBreadCrumb breadcrumbs={breadcrumbs} />
            </Box>
            <BackGeneral colorBg="#f8f8f9">
                <Container maxWidth="lg" sx={{ py: 3 }}>
                    <BanksList
                        accounts={accounts}
                        selAccount={selAccount}
                        setSelAccount={setSelAccount}
                        setSelAccountData={setSelAccountData}
                        setBankDetails={setBankDetails}
                    />
                    {selAccount && (
                        <BankDetails
                            selAccountData={selAccountData}
                            bankDetails={bankDetails}
                            connectSocket={connectSocket}
                            accounts={accounts}
                            setOpenBackdrop={setOpenBackdrop}
                            setShowModalRecon={setShowModalRecon}
                            setShow={setShow}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            reloadBankDet={reloadBankDet}
                        />
                    )}
                </Container>
            </BackGeneral>
            {open && (
                <ChatTransaction
                    room={room}
                    setRoom={setRoom}
                    open={open}
                    setOpen={setOpen}
                    setChat={setChat}
                    chat={chat}
                    type="transaction"
                    setIsLoading={setOpenBackdrop}
                    users={users}
                    franchiseName={
                        todos.allComData?.find(
                            (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                        )?.franchise_name
                    }
                    transInfo={transInfo}
                    setTransInfo={setTransInfo}
                    accountInfo={bankDetails.account}
                />
            )}
            <SimpleBackdrop open={openBackdrop} />
            <AlertDialog
                open={showModalRecon}
                onClose={handleCloseDialog}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                type={"info"}
                title={t("login.confirm")}
                message={
                    <Box sx={{ textAlign: "center" }}>
                        <Typography>{t("accounts.dialogRecon")}</Typography>
                        <Typography>{t("dialog.warningConfirm2")}</Typography>
                    </Box>
                }
                agreeAction={handleCloseDialog}
                maxWidth="sm"
            />
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
        </>
    );
};

export default Reconciliations;
