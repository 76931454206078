/* MUI Imports */
import UserRegister from "../components/auth/UserRegister/UserRegister";

const UserProfile = () => {
    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", minHeight: "80vh" }}>
                <UserRegister editMode={true} />
            </div>
        </>
    );
};

export default UserProfile;
