/** MUI imports */
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    Toolbar,
    SearchPanel,
    TableSelection,
} from "@devexpress/dx-react-grid-material-ui";
import {
    SearchState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SelectionState,
    IntegratedSelection,
} from "@devexpress/dx-react-grid";
import { Box, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PREFIX = "BaseTable";

const classes = {
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    tableHeaderBand: `${PREFIX}-tableHeaderBand`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "#f8f8f9",
        fontSize: "15px",
        fontWeight: 600,
        color: "#131F3E !important",
        textAlign: "center !important",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
            paddingRight: "24px",
        },
    },

    [`& .${classes.tableBody}`]: {
        backgroundColor: "#fff",
        borderBottom: "2px solid #f8f8f9",
        fontSize: "14px",
        fontWeight: 400,
        color: "#131f3e",
        paddingTop: "10px",
        paddingBottom: "10px",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#131F3E",
        backgroundColor: "#f2f2f2",
        borderRadius: 8,
        borderBottom: "none",
        paddingTop: "11px",
        paddingBottom: "11px",
        paddingLeft: "20px",
        paddingRight: "40px",
        marginBottom: "35px",
        "&:before": {
            borderBottom: "none",
            color: "#131F3E",
        },
        "&:hover:before": {
            borderBottom: "none",
            color: "#131F3E",
        },
    },

    [`& .${classes.tableHeaderBand}`]: {
        backgroundColor: "transparent",
        color: "#4a22d4",
        fontSize: "14px",
        fontWeight: 600,
        border: "none !important",
        textAlign: "center",
        paddingBottom: 0,
        [theme.breakpoints.up("md")]: {
            whiteSpace: "pre-wrap",
        },
    },
}));

const tableHeaderCell = ({ ...props }) => (
    <TableHeaderRow.Cell {...props} className={classes.tableHeader} />
);

const TableCellComponent = ({ column, row, ...props }) => {
    if (column.name === "check" && row.checkComponent) {
        return (
            <Table.Cell {...props} className={classes.tableBody}>
                {row.checkComponent}
            </Table.Cell>
        );
    }

    return <Table.Cell {...props} className={classes.tableBody} />
};

const PagingPanelContainer = ({ ...props }) => (
    <PagingPanel.Container {...props} className={classes.pager} />
);

const inputComponent = ({ ...props }) => (
    <SearchPanel.Input {...props} className={classes.searchInput} />
);

const toolRootComponent = ({ ...props }) => (
    <Toolbar.Root {...props} className={classes.toolbarSearch} />
);

const tableHeaderSelCellBase = ({ ...props }) => (
    <TableSelection.HeaderCell {...props} className={classes.tableHeader} />
);

const tableSelectionCell = ({ ...props }) => (
    <TableSelection.Cell {...props} className={classes.tableBody} />
);

const BaseTable = (props) => {
    const {
        rows,
        columns,
        selectionAcc,
        setSelectionAcc,
        searchValue,
        setSearchState,
        tableColumnExtensions,
    } = props;

    const [t] = useTranslation("global");

    const setSelection = (gridSelectedRows) => {
        setSelectionAcc(gridSelectedRows);
    };

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const tableMessages = {
        noData: <Typography fontSize={14}>{t("dashboard.noInfo")}</Typography>,
    };

    const pagingMessages = {
        info: ({ from, to, count }) =>
            `${from}${from < to ? `-${to}` : ""} ${t(
                "dashboard.pagingOf"
            )} ${count}`,
        rowsPerPage: t("dashboard.rowsPerPage"),
    };

    const searchMessages = {
        searchPlaceholder: t("dashboard.search"),
    };

    return (
        <StyledContainer>
            <Grid rows={rows} columns={columns}>
                <SelectionState
                    selection={selectionAcc}
                    onSelectionChange={setSelection}
                />
                <SearchState value={searchValue} onValueChange={handleSearch} />
                <IntegratedFiltering />
                <PagingState defaultCurrentPage={0} pageSize={15} />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table
                    columnExtensions={tableColumnExtensions}
                    cellComponent={TableCellComponent}
                    messages={tableMessages}
                />
                <TableHeaderRow cellComponent={tableHeaderCell} />
                <TableSelection
                    showSelectAll
                    headerCellComponent={tableHeaderSelCellBase}
                    cellComponent={tableSelectionCell}
                />
                <Toolbar rootComponent={toolRootComponent} />
                <SearchPanel
                    messages={searchMessages}
                    inputComponent={inputComponent}
                />
                <PagingPanel
                    containerComponent={PagingPanelContainer}
                    messages={pagingMessages}
                />
            </Grid>
        </StyledContainer>
    );
};

export default BaseTable;
