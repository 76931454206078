/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI icons */
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/es";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

/** MUI imports */
import {
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    FilledInput,
    MenuItem,
} from "@mui/material";
import casesService from "../../services/case";
import FiltersPopover from "../ui/FiltersPopover";

const defaultFilter = {
    filtersArray: [
        {
            type: "",
            value: "",
            since: "",
            until: "",
        },
    ],
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 45 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getcontentanchorel: null,
};

const FiltersButton = (props) => {
    const { accounts, users, company, setShowSkeleton, franchise, banks, user_id, setCases } = props;

    const [t] = useTranslation("global");

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filters, setFilters] = React.useState(defaultFilter);
    const [finalAccounts, setFinalAccounts] = React.useState(accounts);
    const [finalUsers, setFinalUsers] = React.useState(users);
    const [finalBanks, setFinalBanks] = React.useState(banks);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    /* Filtraríamos por Banco (cuenta bancaria), fecha de carga (campo desde y hasta), formato, fecha de estados de cuenta (fecha desde y hasta), usuario y estatus.*/

    const attributes = [
        { id: 1, name: t("inbox.bankAcc"), query: "account_id" },
        { id: 2, name: t("dashboard.bank"), query: "bank_id" },
        { id: 3, name: t("payment.concept"), query: "concept" },
        { id: 4, name: t("converter.status"), query: "id_status" },
        { id: 5, name: t("converter.user"), query: "user_name" },
        { id: 6, name: t("inbox.creationDate"), query: "creation_date" },
    ];

    const status = [
        { id: 1, name: t("inbox.analysis") },
        { id: 2, name: t("inbox.progress") },
        { id: 3, name: t("inbox.test") },
        { id: 4, name: t("inbox.solvedKiiper") },
        { id: 5, name: t("inbox.solvedUser") },
    ];

    const concept = [
        { id: 1, name: t("inbox.conversions") },
        { id: 2, name: t("inbox.newBank") },
        { id: 3, name: t("inbox.directRequest") },
    ];

    const addFilter = () => {
        let array = [...filters.filtersArray];

        if (array.length <= 6) {
            setFilters({
                ...filters,
                filtersArray: [
                    ...filters.filtersArray,
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                    },
                ],
            });
        }
    };

    const removeFilter = async (index) => {
        let array = [...filters.filtersArray];

        if (index !== -1) {
            array.splice(index, 1);
            setFilters({
                ...filters,
                filtersArray: array,
            });

            setShowSkeleton(true);

            let objectFinal = {};

            array.forEach((item) => {
                if (item.value && item.type) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value);
                }
                if (item.type && item.since) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                        item.since.format("YYYY-MM-DD"));
                }
                if (item.type && item.until) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                        item.until.format("YYYY-MM-DD"));
                }
            });

            let params = {
                user_id: user_id,
                company_id: company,
                franchise_id: franchise,
                ...objectFinal,
            };

            await casesService
                .getCases(params)
                .then((data) => {
                    setCases(data);
                    setFinalAccounts(data?.allAccounts ?? []);
                    setFinalUsers(data?.allUsers ?? []);
                    setFinalBanks(data?.allBanks ?? []);
                })
                .catch((err) => {
                    console.log(err);
                });

            setShowSkeleton(false);
        }
    };

    const removeFirstFilter = async () => {
        setShowSkeleton(true);
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                    since: "",
                    until: "",
                },
            ],
        });

        let params = {
            user_id: user_id,
            company_id: company,
            franchise_id: franchise,
        };

        await casesService
            .getCases(params)
            .then((data) => {
                setCases(data);
                setFinalAccounts(data?.allAccounts ?? []);
                setFinalUsers(data?.allUsers ?? []);
                setFinalBanks(data?.allBanks ?? []);
            })
            .catch((err) => {
                console.log(err);
            });

        setShowSkeleton(false);
    };

    const handleChangeType = (event, index) => {
        let aux = [...filters.filtersArray];
        aux[index].type = event.target.value;
        aux[index].value = "";
        aux[index].since = "";
        aux[index].until = "";

        setFilters({
            ...filters,
            filtersArray: aux,
        });
    };

    const handleChangeFilter = async (event, index, filterType) => {
        let aux = [...filters.filtersArray];

        aux[index].value = event.target.value;
        aux[index].since = "";
        aux[index].until = "";

        setShowSkeleton(true);

        let objectFinal = {};

        aux.forEach((item) => {
            if (item.value && item.type) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
            }
            if (item.type && item.since) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                    item.since.format("YYYY-MM-DD");
            }
            if (item.type && item.until) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                    item.until.format("YYYY-MM-DD");
            }
        });

        let params = {
            user_id: user_id,
            company_id: company,
            franchise_id: franchise,
            ...objectFinal,
        };

        await casesService
            .getCases(params)
            .then((data) => {
                setCases(data);
                setFinalAccounts(data?.allAccounts ?? []);
                setFinalUsers(data?.allUsers ?? []);
                setFinalBanks(data?.allBanks ?? []);
            })
            .catch((err) => {
                console.log(err);
            });

        setFilters({
            ...filters,
            filtersArray: aux,
        });

        setShowSkeleton(false);
    };

    const handleChangeFilterRange = async (event, index, filterType, range) => {
        let aux = [...filters.filtersArray];
        aux[index][range] = event;
        aux[index].value = "";

        setShowSkeleton(true);

        let objectFinal = {};

        aux.forEach((item) => {
            if (item.value && item.type) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
            }
            if (item.type && item.since) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                    item.since.format("YYYY-MM-DD");
            }
            if (item.type && item.until) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                    item.until.format("YYYY-MM-DD");
            }
        });

        let params = {
            user_id: user_id,
            company_id: company,
            franchise_id: franchise,
            ...objectFinal,
        };

        await casesService
            .getCases(params)
            .then((data) => {
                setCases(data);
                setFinalAccounts(data?.allAccounts ?? []);
                setFinalUsers(data?.allUsers ?? []);
                setFinalBanks(data?.allBanks ?? []);
            })
            .catch((err) => {
                console.log(err);
            });

        setFilters({
            ...filters,
            filtersArray: aux,
        });

        setShowSkeleton(false);
    };

    const cleanFilters = async (e) => {
        if (e.target.classList.contains("MuiAvatar-img")) {
            setShowSkeleton(true);
            setFilters({
                filtersArray: [
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                    },
                ],
            });

            let params = {
                user_id: user_id,
                company_id: company,
                franchise_id: franchise,
            };

            await casesService
                .getCases(params)
                .then((data) => {
                    setCases(data);
                    setFinalAccounts(data?.allAccounts ?? []);
                    setFinalUsers(data?.allUsers ?? []);
                    setFinalBanks(data?.allBanks ?? []);
                })
                .catch((err) => {
                    console.log(err);
                });

            setShowSkeleton(false);
        }
    };

    useEffect(() => {
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                    since: "",
                    until: "",
                },
            ],
        });
    }, [company, franchise]);

    return (
        <FiltersPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            cleanFilters={cleanFilters}
            handleClick={handleClick}
            length={filters.filtersArray.filter(
                (item) => item.type && (item.value || item.since || item.until)
            ).length}
            addFilter={addFilter}
            disabled={filters.filtersArray.length === 6}
        >
            {filters.filtersArray.map((value, index) => (
                <Grid container spacing={2} alignItems="center" key={index} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel
                                        id="clientStatus-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#131F3E",
                                        }}
                                    >
                                        {t("filters.attribute")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        IconComponent={(props) => {
                                            if (props.className.includes("MuiSelect-iconOpen")) {
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(270deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform: "rotate(90deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }}
                                        value={value.type}
                                        displayEmpty
                                        onChange={(e) => handleChangeType(e, index)}
                                        className="ddlGeneral"
                                        input={
                                            <FilledInput
                                                label={t("filters.attribute")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#131F3E",
                                                }}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                        defaultValue=""
                                    >
                                        {value.type !== ""
                                            ? attributes
                                                .filter(
                                                    (item) =>
                                                        !filters.filtersArray
                                                            .map((item) => item.type)
                                                            .includes(item.id)
                                                )
                                                .concat(
                                                    attributes.filter((item) => item.id === value.type)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.id} key={"attr_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))
                                            : attributes
                                                .filter(
                                                    (item) =>
                                                        !filters.filtersArray
                                                            .map((item) => item.type)
                                                            .includes(item.id)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.id} key={"attr_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {value.type === "" && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled" disabled>
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.type}
                                            displayEmpty
                                            onChange={(e) => handleChangeType(e, index)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        ></Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 1 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        >
                                            {finalAccounts.map((item) => (
                                                <MenuItem value={item._id} key={"accounts_" + item._id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.account_name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 2 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                mx: 1,
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            mx: 1,
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        >
                                            {finalBanks.map((item) => (
                                                <MenuItem value={item._id} key={"banks_" + item._id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.bank_name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 3 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        >
                                            {concept.map((item) => (
                                                <MenuItem value={item.id} key={"concept_" + item.id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 4 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        >
                                            {status.map((item) => (
                                                <MenuItem value={item.id} key={"status_" + item.id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 5 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        >
                                            {finalUsers.map((item) => (
                                                <MenuItem value={item} key={"users_" + item}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 6 && (
                                <>
                                    <Grid item xs={6} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <DatePicker
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    >
                                                        {t("filters.since")}
                                                    </Typography>
                                                }
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        style: { width: "100%" },
                                                        variant: "filled",
                                                        readOnly: true,
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                value={value.since !== "" ? value.since : null}
                                                onChange={(e) =>
                                                    handleChangeFilterRange(e, index, value.type, "since")
                                                }
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <DatePicker
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    >
                                                        {t("filters.until")}
                                                    </Typography>
                                                }
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        style: { width: "100%" },
                                                        variant: "filled",
                                                        readOnly: true,
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                minDate={
                                                    filters.filtersArray[index]?.since !== ""
                                                        ? filters.filtersArray[index].since
                                                        : null
                                                }
                                                value={value.until !== "" ? value.until : null}
                                                onChange={(e) =>
                                                    handleChangeFilterRange(e, index, value.type, "until")
                                                }
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={1} display="flex" justifyContent="center">
                        {filters.filtersArray.length > 1 && (
                            <DeleteIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() => removeFilter(index)}
                            />
                        )}
                        {filters.filtersArray.length === 1 &&
                            (value.value !== "" || value.since !== "" || value.until !== "") && (
                                <DeleteIcon
                                    color="primary"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => removeFirstFilter()}
                                />
                            )}
                    </Grid>
                </Grid>
            ))}
        </FiltersPopover>
    );
};

export default FiltersButton;
