import moment from "moment";

/* Componentes */
import { List, ListItem, ListItemText, Stack, Typography, Grid, Button, Box } from "@mui/material";

/* Estilos */
import classes from "./InvitationsList.module.css";

import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

import teamService from "../../services/team";
import LightTooltip from "../ui/LightTooltip";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

const InvitationsList = (props) => {
    const { invitations, setIsLoading, setReload, setShow, setAlertType, setTexto, usersCounter, subscription } = props;
    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    const rolNames = [
        { id: 1, name: t("team.owner") },
        { id: 2, name: t("team.admin") },
        { id: 3, name: t("team.colab") },
    ];

    const [usersQuantity, setUsersQuantity] = useState("");
    const filterInv = invitations.filter((item) => item.invite_status === 2);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));
    const [isDisabledAddTeam, setIsDisabledAddTeam] = useState(false);

    const todos = useSelector((state) => state.value);

    const nullUser = async (id) => {
        setIsLoading(true);

        const params = {
            invitation_id: id,
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
        };

        await teamService
            .cancelInvitation(params)
            .then(() => {
                setTexto(t("team.successNull"));
                setAlertType("success");
                setShow(true);
                setReload((prevState) => !prevState);
            })
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);
    };

    const reSendInv = async (id) => {
        setIsLoading(true);

        const params = {
            invitation_id: id,
            language: language,
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
        };

        await teamService
            .resendInvitation(params)
            .then(() => {
                setTexto(t("team.resend200"));
                setAlertType("success");
                setShow(true);
                setReload((prevState) => !prevState);
            })
            .catch((err) => {
                switch (err.response.status) {
                    case 404:
                        setTexto(t("team.resendError404"));
                        break;
                    default:
                        setTexto(t("team.resendError500"));
                        break;
                }
                setAlertType("error");
                setShow(true);
            });

        setIsLoading(false);
    };

    useEffect(() => {
        if (subscription) {
            switch (subscription.subscription_plan) {
                case 0:
                    setUsersQuantity(1);
                    break;
                case 1:
                    setUsersQuantity(1);
                    break;
                case 2:
                    setUsersQuantity(2);
                    break;
                case 3:
                    setUsersQuantity(3);
                    break;
                case 4:
                    setUsersQuantity(4);
                    break;
                case 5:
                    setUsersQuantity(5);
                    break;
                default:
                    setUsersQuantity("unlimited");
                    break;
            }
        }
    }, [subscription]);

    useEffect(() => {
        switch (usersQuantity) {
            case 1:
                setIsDisabledAddTeam(true);
                break;
            case 2:
                if (usersCounter >= 2) {
                    setIsDisabledAddTeam(true);
                } else {
                    setIsDisabledAddTeam(false);
                }
                break;
            case 3:
                if (usersCounter >= 3) {
                    setIsDisabledAddTeam(true);
                } else {
                    setIsDisabledAddTeam(false);
                }
                break;
            case 4:
                if (usersCounter >= 4) {
                    setIsDisabledAddTeam(true);
                } else {
                    setIsDisabledAddTeam(false);
                }
                break;
            case 5:
                if (usersCounter >= 5) {
                    setIsDisabledAddTeam(true);
                } else {
                    setIsDisabledAddTeam(false);
                }
                break;
            default:
                setIsDisabledAddTeam(false);
                break;
        }
    }, [usersQuantity, usersCounter]);

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    return (
        filterInv.length !== 0 && (
            <>
                <Typography variant="h3" className={classes.title}>
                    {t("team.sendRequests")}
                </Typography>
                <List className={classes.list}>
                    {filterInv.map((item) => (
                        <ListItem
                            secondaryAction={
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={12} sm>
                                        <Typography
                                            className={classes.lastTime}
                                            sx={{ display: { xs: "none", sm: "block" } }}
                                        >
                                            {Math.ceil(
                                                moment(item.creation_date).add(7, "days").diff(moment(), "days", true)
                                            ) > 0
                                                ? `${Math.ceil(
                                                      moment(item.creation_date)
                                                          .add(7, "days")
                                                          .diff(moment(), "days", true)
                                                  )} ${
                                                      Math.ceil(
                                                          moment(item.creation_date)
                                                              .add(7, "days")
                                                              .diff(moment(), "days", true)
                                                      ) === 1
                                                          ? t("team.leftDay")
                                                          : t("team.leftDays")
                                                  }`
                                                : t("team.expiredInv")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {Math.ceil(
                                            moment(item.creation_date).add(7, "days").diff(moment(), "days", true)
                                        ) <= 0 && (
                                            <Button onClick={() => reSendInv(item._id)} disabled={isDisabledAddTeam}>
                                                {t("team.resend")}
                                            </Button>
                                        )}
                                        <Button sx={{ color: "#FF007A" }} onClick={() => nullUser(item._id)}>
                                            {t("team.null")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                            key={item._id}
                        >
                            <ListItemText
                                primary={
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item>
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography variant="h4" sx={{ pl: 0.5 }}>
                                                    {item.invite_user_name}
                                                </Typography>
                                                <Typography>•</Typography>
                                                <Typography>{item.invite_email}</Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item>
                                            <Box
                                                className={
                                                    item.user_permission === 1
                                                        ? classes.owner
                                                        : item.user_permission === 2
                                                        ? classes.admin
                                                        : classes.colab
                                                }
                                            >
                                                <Typography>
                                                    {rolNames.find((x) => x.id === item.user_permission).name}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        {((item.temporary && item.temporary === true) ||
                                            (subscription?.last_cancel && item.user_permission !== 1)) && (
                                            <Grid item>
                                                <Box className={classes.temporary}>
                                                    <Typography>{t("team.temporary")}</Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <LightTooltip
                                                title={
                                                    item.user_status === 1 ? t("team.isRegister") : t("team.noRegister")
                                                }
                                            >
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={1}
                                                    className={item.user_status === 1 ? classes.active : classes.new}
                                                >
                                                    <Typography>
                                                        {item.user_status === 1 ? t("team.active") : t("team.new")}
                                                    </Typography>
                                                    <CheckIcon
                                                        className={
                                                            item.user_status === 1
                                                                ? classes.activeIcon
                                                                : classes.newIcon
                                                        }
                                                    />
                                                </Stack>
                                            </LightTooltip>
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </>
        )
    );
};

export default InvitationsList;
