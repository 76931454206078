import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CssTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
    },
    "& label.Mui-focused": {
        color: "#131F3E",
    },
});

export default function CustTextField(props) {
    const { id, label, type, InputProps, value, onChange, error, onBlur, helperText, onKeyDown, shrink } = props;

    return (
        <CssTextField
            InputLabelProps={{ shrink: shrink }}
            id={id}
            label={label}
            variant="outlined"
            size="small"
            type={type}
            fullWidth
            value={value}
            onChange={onChange}
            error={error}
            onBlur={onBlur}
            InputProps={InputProps}
            helperText={helperText}
            onKeyDown={onKeyDown}
        />
    );
}
