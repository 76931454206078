/** React imports */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

/** MUI Imports */
import { Typography, MenuItem, Divider, Box, Popover, Button, MenuList, Stack } from "@mui/material";

/** MUI Icons */
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

/** Components */
import BaseSnackbar from "../../ui/BaseSnackbar";
import Settings from "./Settings";

const DropdownUser = (props) => {
    const {
        anchorElConfig,
        open,
        id,
        handleClose,
        handleLogout,
        settings,
        setSettings,
        language,
        dateFormat,
        amountFormat,
        setLanguage,
        setDateFormat,
        setAmountFormat,
        setIsLoading,
        register
    } = props;

    /** Internationalization i18n */
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const navigate = useNavigate();

    /** Component states */

    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setalertType] = useState("success");

    /** Component functions */
    const handleConfig = () => {
        setSettings((config) => !config);
    };

    const handleCloseSnackBar = () => {
        setTexto("");
        setalertType("");
        setShow(false);
    };

    const handleOpenUserProfile = () => {
        navigate("/profile");
        handleClose();
    };

    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorElConfig}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                PaperProps={{
                    style: {
                        borderRadius: 20,
                        backgroundColor: "#fff",
                        padding: 2,
                    },
                }}
            >
                {settings || register ? (
                    <Settings
                        setLanguage={setLanguage}
                        setDateFormat={setDateFormat}
                        language={language}
                        dateFormat={dateFormat}
                        amountFormat={amountFormat}
                        setAmountFormat={setAmountFormat}
                        handleClose={handleClose}
                        setTexto={setTexto}
                        setalertType={setalertType}
                        setShow={setShow}
                        setIsLoading={setIsLoading}
                    />
                ) : (
                    <>
                        <Stack alignItems="center" direction="row" spacing={2} sx={{ m: 2 }}>
                            <Box
                                sx={{
                                    width: 57,
                                    height: 57,
                                    borderRadius: "50%",
                                    backgroundColor: "rgba(217, 217, 217, 1)",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: 40,
                                    fontWeight: "bold",
                                }}
                            >
                                {todos?.userInfo?.user_name[0].toUpperCase() ?? ""}
                            </Box>
                            <Box>
                                <Typography variant="h2">{todos.userInfo?.user_name}</Typography>
                                <Button color="primary" size="small" onClick={handleOpenUserProfile} sx={{ ml: -0.5 }}>
                                    {t("navBar.editProfile")}
                                </Button>
                            </Box>
                        </Stack>
                        <Divider />
                        <MenuList
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                        >
                            <MenuItem sx={{ width: "100%" }} onClick={handleConfig}>
                                <Typography variant="h3">{t("navBar.settings")}</Typography>
                                <ArrowRightIcon sx={{ ml: "auto" }} />
                            </MenuItem>
                            <MenuItem sx={{ width: "100%" }} onClick={handleLogout}>
                                <Typography variant="h3" sx={{ color: "#FF007A", cursor: "pointer" }}>
                                    {t("navBar.logOut")}
                                </Typography>
                            </MenuItem>
                        </MenuList>
                    </>
                )}
            </Popover>
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
        </>
    );
};

export default DropdownUser;
