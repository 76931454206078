import conversionService from "../services/conversion";
import subscriptionService from "../services/subscription";


export function getFileExtension(fileName) {
    const parts = fileName ? fileName.split(".") : [];

    if (parts.length > 1) {
        return parts[parts.length - 1];
    } else {
        return "";
    }
}

export const zeroPad = (num, places) => String(num).padStart(places, "0");

// Función para obtener el MIME type basado en la extensión
const getMimeType = (extension) => {
    switch (extension.toLowerCase()) {
        case 'png':
            return 'image/png';
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        default:
            return '';
    }
};

export async function fetchFileFromS3(filename) {

    const params = { uploaded_file_s3: filename };

    try {
        const response = await conversionService.getFileS3(params);
        // Check if the file is an image
        const extension = getFileExtension(filename);
        const mimeType = getMimeType(extension);

        if (extension === "png" || extension === "jpg" || extension === "jpeg") {
            const blob = new Blob([response.data], { type: `image/${mimeType}` });
            const url = URL.createObjectURL(blob);
            return url;
        }
        return response.data;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export async function getFranchiseCredits(franchiseID) {
    try {
        return await subscriptionService.getFranchiseCredits({ franchise_id: franchiseID });
    } catch (err) {
        console.error(err);
        return 0;
    }
}