import { useEffect, useState } from "react";

/* Componentes */
import BackDashboard from "../components/layout/BackDashboard";
import CompaniesTable from "../components/company/CompaniesTable";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";

const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);

    useEffect(() => {
        (async () => {
            setShowSkeleton(true);

            setShowSkeleton(false);
        })();
    }, []);

    return (
        <BackDashboard fix={true}>
            <CompaniesTable showSkeleton={showSkeleton} setIsLoading={setIsLoading} />
            <SimpleBackdrop open={isLoading} />
        </BackDashboard>
    );
};

export default Dashboard;
