import classes from "./BaseCard.module.css";
import { Card, CardContent } from "@mui/material";

const BaseCard = (props) => {
    return (
        <Card className={classes.card}>
            <CardContent sx={{
                "&:last-child": {
                    paddingBottom: 0,
                },
            }}>{props.children}</CardContent>
        </Card>
    );
};

export default BaseCard;
