import styled from "@emotion/styled";
import { Paper, Typography, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSelector } from "react-redux";

const ColorButton2 = styled(Button)(({ theme }) => ({
    letterSpacing: "-0.22px",
    color: "#FFF",
    fontStyle: "normal",
    fontWeight: "350",
    border: "1px solid #4A22D4",
    borderRadius: "6px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "4px",
    paddingBottom: "4px",
    "&:disabled": {
        backgroundColor: "#4A22D4",
        border: "1px solid #4A22D4",
        color: "#FFF",
    },
}));

const CardCredit = (props) => {
    const { data, handle, activePlan, cartItems, plansInfo } = props;

    const todos = useSelector((state) => state.value);

    const [t] = useTranslation("global");
    return (
        <Paper
            elevation={3}
            square={false}
            sx={{
                width: "100%",
                minHeight: 100,
                transition: "transform 0.2s", // Add a transition for a smooth effect
                transform: cartItems.find((item) => item.line_qty === data.credits && item.line_type === 5)
                    ? "scale(1.05)"
                    : "scale(1.00)",
                "&:hover": {
                    transform: "scale(1.05)", // Scale the card on hover
                },
                position: "relative",
                "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%", // 1/2 del card
                    height: "20%",
                    opacity: "0.1",
                    backgroundColor: data.colorTop, // Color de fondo
                    borderRadius: "0 0 50% 50%", // Hacer un semicírculo
                },
                py: 3,
                px: { xs: 3, md: 4 },
                borderRadius: 2.5,
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="column">
                    <Typography fontSize={34} fontWeight={700} sx={{ mb: 0, pb: 0 }} color="primary">
                        +{data.credits}
                    </Typography>
                    <Typography fontSize={14} fontWeight={600} sx={{ ml: 1, mt: -1, pt: 0 }}>
                        {t("credits.credits")}
                    </Typography>
                </Stack>
                <Stack direction="column" justifyContent="center" textAlign="center">
                    {todos.subscription?.value !== 5 && activePlan ? (
                        <>
                            <Typography fontSize={16} fontWeight={600} color="primary">
                                ${" "}
                                {parseFloat(
                                    data.credits * plansInfo.find((item) => item.planId === activePlan).customCredits
                                ).toLocaleString(todos.amountFormat, {
                                    minimumFractionDigits: 2,
                                })}
                            </Typography>
                            <ColorButton2
                                variant="contained"
                                disableElevation
                                color="primary"
                                onClick={() => handle(data)}
                                disabled={cartItems.some(
                                    (item) => item.line_qty === data.credits && item.line_type === 5
                                )}
                            >
                                {cartItems.find((item) => item.line_qty === data.credits && item.line_type === 5) ? (
                                    <>
                                        {t("plans.inCart")} <ShoppingCartIcon sx={{ ml: 0.5 }} fontSize="inherit" />
                                    </>
                                ) : (
                                    t("credits.add")
                                )}
                            </ColorButton2>
                        </>
                    ) : (
                        <>
                            <Typography fontSize={13} color="#FF007A" fontWeight={600} sx={{ mb: -1 }}>
                                &nbsp;
                            </Typography>
                            <Typography fontSize={16} fontWeight={600} color="primary">
                                ${" "}
                                {parseFloat(data.cost).toLocaleString(todos.amountFormat, {
                                    minimumFractionDigits: 2,
                                })}
                            </Typography>
                            <ColorButton2
                                variant="contained"
                                color="primary"
                                onClick={() => handle(data)}
                                disabled={cartItems.some(
                                    (item) => item.line_qty === data.credits && item.line_type === 5
                                )}
                            >
                                {cartItems.find((item) => item.line_qty === data.credits && item.line_type === 5) ? (
                                    <>
                                        {t("plans.inCart")} <ShoppingCartIcon sx={{ ml: 0.5 }} fontSize="inherit" />
                                    </>
                                ) : (
                                    t("credits.add")
                                )}
                            </ColorButton2>
                        </>
                    )}
                </Stack>
            </Stack>
        </Paper>
    );
};

export default CardCredit;
