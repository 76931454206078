import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        value: {},
    },
    reducers: {
        addAuth: (state, action) => {
            state.value = { ...state.value, auth: action.payload };
        },
        addUserInfo: (state, action) => {
            state.value = { ...state.value, userInfo: action.payload };
        },
        addCompanyID: (state, action) => {
            state.value = { ...state.value, companyID: action.payload };
        },
        addFranchiseID: (state, action) => {
            state.value = { ...state.value, franchiseID: action.payload };
        },
        addIsGrouped: (state, action) => {
            state.value = { ...state.value, isGrouped: action.payload };
        },
        addAllComData: (state, action) => {
            state.value = { ...state.value, allComData: action.payload };
        },
        addAccounts: (state, action) => {
            state.value = { ...state.value, accounts: action.payload };
        },
        addDateFormat: (state, action) => {
            state.value = { ...state.value, dateFormat: action.payload };
        },
        addAmountFormat: (state, action) => {
            state.value = { ...state.value, amountFormat: action.payload };
        },
        addPermissions: (state, action) => {
            state.value = { ...state.value, permissions: action.payload };
        },
        addCredits: (state, action) => {
            state.value = { ...state.value, credits: action.payload };
        },
        addSubscription: (state, action) => {
            state.value = { ...state.value, subscription: action.payload };
        },
        addCases: (state, action) => {
            state.value = { ...state.value, cases: action.payload };
        },
        addUpXero: (state, action) => {
            state.value = { ...state.value, upXero: action.payload };
        },
        addUserCompRol: (state, action) => {
            state.value = { ...state.value, userRol: action.payload };
        },
        clearInfo: (state) => {
            state.value = {};
        },
        addMessages: (state, action) => {
            state.value = { ...state.value, messages: action.payload };
        },
        addTutorial: (state, action) => {
            state.value = { ...state.value, steps: action.payload };
        },
        addResetDash: (state, action) => {
            state.value = { ...state.value, reset: action.payload };
        },
        addInvisibleNoti: (state, action) => {
            state.value = { ...state.value, read: action.payload };
        },
        addSelected: (state, action) => {
            state.value = { ...state.value, selected: action.payload };
        },
        addChangeRoute: (state, action) => {
            state.value = { ...state.value, keyRoute: action.payload };
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    addTutorial,
    addAuth,
    addUserInfo,
    addCompanyID,
    addFranchiseID,
    addIsGrouped,
    addAllComData,
    addAccounts,
    addDateFormat,
    addAmountFormat,
    addCredits,
    addPermissions,
    addSubscription,
    addCases,
    addUpXero,
    addUserCompRol,
    clearInfo,
    addMessages,
    addResetDash,
    addInvisibleNoti,
    addSelected,
    addChangeRoute
} = userSlice.actions;

export default userSlice.reducer;
