import { Box, Button, FormControlLabel, Link, Stack, Switch, Typography, Skeleton } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import notificationsService from "../../services/notification";
import CommuIcon from "../../assets/Communication.svg"
import checkGreenIcon from "../../assets/checkGreen.svg"
import checkGreyIcon from "../../assets/checkGrey.svg"
import LightTooltip from "../ui/LightTooltip";

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        notification: <Skeleton variant="text" animation="wave" width="100%" />,
    });
};

const CaseTab2 = (props) => {
    const {
        user_id,
        notif,
        setNotif,
        setShow,
        setTexto,
        setAlertType,
        showSkeleton,
        setShowSkeleton,
        setIsLoading,
    } = props;

    const [checked, setChecked] = useState(true);
    const [t] = useTranslation("global");

    const handleChangeSwitch = async (event) => {
        setChecked(event.target.checked);
        setShowSkeleton(true);

        if (event.target.checked) {
            let params = {
                user_id: user_id,
                all_notifications: true
            }
            await notificationsService
                .getNotifications(params)
                .then((data) => {
                    setNotif(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            let params = {
                user_id: user_id
            }
            await notificationsService
                .getNotifications(params)
                .then((data) => {
                    setNotif(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        setShowSkeleton(false);
    };

    const handleMark = async () => {
        let params = {
            user_id: user_id,
            read_all: true
        };

        setIsLoading(true);
        setShowSkeleton(true);

        await notificationsService
            .readNotification(params)
            .then(async () => {

                let params2 = {
                    user_id: user_id,
                    all_notifications: true
                }

                await notificationsService
                    .getNotifications(params2)
                    .then((data) => {
                        setNotif(data);
                        setAlertType("success");
                        setTexto(t("inbox.markedReadMultiple"));
                        setShow(true);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
        setShowSkeleton(false);
    };

    const markRead = async (id) => {
        setIsLoading(true);
        setShowSkeleton(true);

        let params = {
            notification_id: id
        }

        await notificationsService
            .readNotification(params)
            .then(async () => {

                let params2 = {
                    user_id: user_id,
                    all_notifications: true
                }

                await notificationsService
                    .getNotifications(params2)
                    .then((data) => {
                        setNotif(data);
                        setAlertType("success");
                        setTexto(t("inbox.markedRead"));
                        setShow(true);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
        setShowSkeleton(false);
    };

    function durationAsString(start, end) {
        const duration = moment.duration(moment(end).diff(moment(start)));

        //Get Days
        const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
        const daysFormatted = days ? `${days}d ` : ''; // if no full days then do not display it at all

        //Get Hours
        const hours = duration.hours();
        const hoursFormatted = `${hours}h `;

        //Get Minutes
        const minutes = duration.minutes();
        const minutesFormatted = `${minutes}m`;

        return [daysFormatted, hoursFormatted, minutesFormatted].join('');
    }

    return (
        <Box paddingX={{ xs: 0, md: 2, lg: 8, height: "100vh" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" pb={1}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={checked}
                            onChange={handleChangeSwitch}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={<Typography fontWeight={600} fontSize={16}>{t("inbox.showAll")}</Typography>}
                />
                <Link sx={{ fontWeight: 600, fontSize: 16, cursor: "pointer", textDecoration: "none" }} component={Button} onClick={handleMark}>{t("inbox.markAll")}</Link>
            </Stack>
            {showSkeleton ? rowsSkeleton.map((item) => (
                <Box bgcolor="#FFF" py={2} px={5} borderRadius="10px" mb={0.7}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        {item.notification}
                    </Stack>
                </Box>
            )) : notif.length > 0 ? notif.map((item) =>
                <Box bgcolor="#FFF" py={2} px={5} borderRadius="10px" mb={0.7}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={5}>
                            <Box component="img" src={CommuIcon} sx={{ visibility: item.notification_status === 1 ? "visible" : "hidden" }} />
                            <Typography color="#131F3E" fontWeight={500} sx={{ ml: 1 }} noWrap>
                                {item.notification_description}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={5}>
                            <Typography sx={{ ml: "auto" }}>
                                {durationAsString(item.creation_date, moment())}
                            </Typography>
                            {item.notification_status === 1 ?
                                <LightTooltip title={t("inbox.markRead")}>
                                    <Box component="img" src={checkGreyIcon} width={27} height={27} onClick={() => markRead(item._id)} sx={{ cursor: "pointer" }} />
                                </LightTooltip> :
                                <Box component="img" src={checkGreenIcon} width={25} height={25} />}
                        </Stack>
                    </Stack>
                </Box>
            ) : <Box bgcolor="#FFF" py={2} px={5} borderRadius="10px">
                <Typography color="#131F3E" fontSize={18} sx={{ ml: 1 }} fontStyle="italic">
                    {t("inbox.noNotif")}
                </Typography>
            </Box>}
        </Box>
    );
};

export default CaseTab2;
