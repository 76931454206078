import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Stack,
    TextField,
    Typography,
    Popover,
    Chip,
    LinearProgress,
} from "@mui/material";
import LightTooltip from "../ui/LightTooltip";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ITTeam from "../Js/ITTeam";
import moment from "moment";
import { sendMessage, emitMessage } from "../../services/Socket";
import casesServices from "../../services/case";
import conversionService from "../../services/conversion";
import DownloadIcon from '@mui/icons-material/Download';

import AttachFileIcon from "@mui/icons-material/AttachFile";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import PDFIcon from "../../assets/pdf.svg";
import ImageIcon from "../../assets/image.png";
import ExcelIcon from "../../assets/microsoft-excel.svg";
import TxtIcon from "../../assets/txtFileIcon.svg";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InfoIcon from "@mui/icons-material/Info";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const MAX_COUNT = 3;

function LinearProgressWithLabel(props) {
    return (
        <Grid
            item
            xs={12}
            sx={{
                padding: "5px 25px 5px 25px",
            }}
        >
            <LinearProgress
                variant="determinate"
                {...props}
                sx={{
                    height: 10,
                    borderRadius: 5,
                }}
            />
            <Typography variant="h3">{`${Math.round(props.value)}%`}</Typography>
        </Grid>
    );
}

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const CustomPopover = styled(Popover)({
    ".MuiPopover-paper": {
        borderRadius: 20,
    },
});

const ChatBox = (props) => {
    const {
        room,
        type,
        franchiseName,
        caseInfo,
        users,
        chat,
        chatDisabled,
        setIsLoading,
        showButtons,
        development,
        success,
        fileLimit,
        selectedCase,
        selectedFiles,
        setFileLimit,
        setSelectedFiles,
    } = props;

    const todos = useSelector((state) => state.value);
    const [anchorEl, setAnchorEl] = useState(null);
    const [t] = useTranslation("global");

    const openPop = Boolean(anchorEl);
    const id = openPop ? "simple-popover" : undefined;

    const listRef = useRef(null);
    const [copied, setCopied] = useState(false);
    const [progress, setProgress] = useState(0);
    const [loadingFile, setLoadingFile] = useState(false);
    const inputRef = useRef(null);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePop = () => {
        setAnchorEl(null);
    };

    const deleteFiles = (index) => {
        const uploaded = [...selectedFiles];

        uploaded.splice(index, 1);

        if (uploaded.length < MAX_COUNT) {
            setFileLimit(false);
        }

        setSelectedFiles(uploaded);
    };

    const formatBytes = (bytes, decimals = 0) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const downloadFile = async (originalname, file_name) => {
        let params = {
            uploaded_file_s3: file_name,
        };
        setIsLoading(true);

        await casesServices
            .getChatFileS3(params)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", originalname);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const handleFileChange = (e) => {
        const chosenFiles =
            e._reactName === "onDrop"
                ? Array.prototype.slice.call(e.dataTransfer.files)
                : Array.prototype.slice.call(e.target.files);

        const uploaded = [...selectedFiles];
        let limitExceeded = false;

        chosenFiles.forEach((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push({ filename: file.name, size: file.size, buffer: file });
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    limitExceeded = true;
                }
            }
        });
        if (!limitExceeded) setSelectedFiles(uploaded);
    };

    const handleKeyUp = (event) => {
        if (event.key === "Enter" && event.shiftKey) {
            event.preventDefault();
            if (!inputRef.current.value.endsWith("\n")) {
                const updatedMessage = `${inputRef.current.value} \n `;
                inputRef.current.value = updatedMessage;
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 5000);
    };

    const convertTZ = (date, tzString) => {
        return new Date(
            (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString })
        );
    };

    const getIcon = (filename) =>
        filename.substring(filename.lastIndexOf(".") + 1) === "pdf"
            ? PDFIcon
            : filename.substring(filename.lastIndexOf(".") + 1) === "xlsx" ||
                filename.substring(filename.lastIndexOf(".") + 1) === "csv" ||
                filename.substring(filename.lastIndexOf(".") + 1) === "xls"
                ? ExcelIcon
                : filename.substring(filename.lastIndexOf(".") + 1) === "txt"
                    ? TxtIcon
                    : ImageIcon;

    const onKeyPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            if (selectedFiles.length > 0) {
                sMessageF(event)
            } else {
                sMessage(event)
            }
        }
    };

    useEffect(() => {
        listRef.current?.lastElementChild?.scrollIntoView();
    }, [chat]);

    const sMessageF = async (event) => {
        event.preventDefault()
        const value = inputRef.current.value;

        const data = new FormData();

        if (selectedFiles.length > 0) {
            for (let x = 0; x < selectedFiles.length; x++) {
                data.append("files", selectedFiles[x].buffer);
            }
        }

        data.append("message", value);
        data.append("room", room);
        data.append("user", todos.userInfo._id);
        data.append("type", type);
        data.append("franchise_name", franchiseName);
        data.append("lang", t("language.locale"));

        setLoadingFile(true);

        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded / total) * 100);

                if (percent < 100) {
                    setProgress(percent);
                }
            },
        };

        if (selectedFiles.length > 0) {
            await conversionService
                .uploadFilesChat(data, options)
                .then((messages) => {
                    emitMessage(room, messages);
                    setProgress(0);
                })
                .catch((err) => {
                    console.log(err);
                    setProgress(0);
                });
        }

        setLoadingFile(false);
        setFileLimit(false);

        inputRef.current.value = ""
        setSelectedFiles([])
    }

    const sMessage = async (event) => {
        event.preventDefault()
        const value = inputRef.current.value;
        sendMessage(
            event,
            room,
            value,
            todos.userInfo,
            inputRef,
            type,
            franchiseName
        )
    }

    const downloadEcc = async () => {
        let params = {
            conversion_id: caseInfo.conversion_id,
        };
        await conversionService
            .getFileUrlS3(params)
            .then((response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });
    }


    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ backgroundColor: "#4A22D4", borderTopLeftRadius: 20, borderTopRightRadius: 20, px: 3, py: 2 }}
            >
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Box>
                        <Typography fontSize={18} fontWeight={600} color="#FFF">
                            {(caseInfo.franchise_id && !caseInfo.company_id) ? (caseInfo?.franchiseInfo?.franchise_name + ' | ' ?? '') + (ITTeam.includes(todos.userInfo.email) ? caseInfo.global_case_number : caseInfo.case_number) : (caseInfo?.companyInfo?.company_name + ' | ' ?? '') + (ITTeam.includes(todos.userInfo.email) ? caseInfo.global_case_number : caseInfo.case_number)}
                        </Typography>
                        <Typography fontSize={13} color="#FFF" sx={{ mt: 0.3 }}>
                            {users.length === 0
                                ? ""
                                : users.length === 1
                                    ? users[0].name + " " + t("inbox.connectedChat")
                                    : users.length === 2
                                        ? users.map((item) => item.name).join(t("inbox.and")) + " " + t("inbox.connectedChat2")
                                        : users
                                            .map((item) => item.name)
                                            .slice(0, users.length - 2)
                                            .join(", ") +
                                        ", " +
                                        users
                                            .map((item) => item.name)
                                            .slice(users.length - 2, users.length)
                                            .join(t("inbox.and")) +
                                        " " +
                                        t("inbox.connectedChat2")}
                        </Typography>
                    </Box>
                    <IconButton onClick={handleClick} size="small" sx={{ color: "white" }}>
                        <InfoIcon fontSize="small" />
                    </IconButton>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                    {ITTeam.includes(todos.userInfo.email) && !caseInfo?.global_case_number?.includes("OTR") && (
                        <>
                            <span>
                                <Button
                                    onClick={(e) => downloadEcc(e)}
                                    sx={{
                                        color: "#FFF", fontWeight: 600, bgcolor: "#FFF", px: 3, py: 0, borderRadius: "5px",
                                        width: "162px",
                                        border: "0.5px solid #FFF",
                                        height: "42px",
                                        backgroundColor: "#4A22D4",
                                        "&:hover": {
                                            backgroundColor: "#FFFFFF",
                                            color: "#4A22D4",
                                        },
                                        lineHeight: 1,
                                        whiteSpace: "nowrap",
                                        fontSize: "13px"
                                    }}
                                    endIcon={<DownloadIcon />}
                                >
                                    Descargar EC
                                </Button>
                            </span>
                        </>
                    )}
                </Stack>
            </Stack>
            {room && (
                <form
                    onSubmit={
                        selectedFiles.length > 0
                            ? (event) =>
                                sMessageF(event)
                            : (event) =>
                                sMessage(event)
                    }
                >
                    <List
                        sx={{
                            height: "52vh",
                            overflowY: "auto",
                            bgcolor: "#F9F8FD",
                        }}
                        ref={listRef}
                    >
                        {chat.map((m) => (
                            <>
                                <ListItem
                                    key={m.date}
                                    sx={{
                                        justifyContent: m.user && todos.userInfo._id === m.user._id ? "end" : "start",
                                    }}
                                >
                                    {Object.keys(m).user}
                                    <Box
                                        sx={{
                                            bgcolor:
                                                m.user && todos.userInfo._id === m.user._id
                                                    ? "#efefef" // Usuario actual, gris
                                                    : m.converter_bot === true &&
                                                        (m.message.includes(
                                                            "La conversión no ha sido completada con éxito"
                                                        ) ||
                                                            m.message.includes(
                                                                "The conversion has not been completed successfully"
                                                            ) ||
                                                            m.message.includes("ha regresado el caso a desarrollo") ||
                                                            m.message.includes("has returned the case to development"))
                                                        ? "#FDE8F1" //Convertidor de pruebas, mensaje de error, rojo
                                                        : m.converter_bot === true &&
                                                            (m.message.includes(
                                                                "La conversión se ha completado de manera exitosa"
                                                            ) ||
                                                                m.message.includes(
                                                                    "The conversion has been completed successfully"
                                                                ) ||
                                                                m.message.includes("confirmó que la conversión es exitosa") ||
                                                                m.message.includes("confirmó que la conversión es exitosa") ||
                                                                m.message.includes(
                                                                    "confirmed that the conversion is successful"
                                                                ))
                                                            ? "#E6F3E9" //Convertidor de pruebas, mensaje de exito, azul
                                                            : m.converter_bot === true
                                                                ? "#FFF2DA" //Convertidor de pruebas, naranja
                                                                : (!ITTeam.includes(todos.userInfo.email) &&
                                                                    m.user &&
                                                                    !ITTeam.includes(m.user.email)) ||
                                                                    (ITTeam.includes(todos.userInfo.email) &&
                                                                        (!("user" in m) || (m.user && ITTeam.includes(m.user.email))))
                                                                    ? "#E5D1FF" // Que el usuario actual no sea de ITTeam. Otro user diferente de ITTeam el color es lila O que el usuario actual sea de ITTeam. Bot o user de ITTeam el color es lila
                                                                    : "#00FFFF40",
                                            py: 1,
                                            px: 2,
                                            borderRadius: 3,
                                            ml: m.user && todos.userInfo._id === m.user._id ? 20 : 0,
                                            mr: m.user && todos.userInfo._id === m.user._id ? 0 : 20,
                                            minWidth: "20%",
                                        }}
                                    >
                                        <Stack direction="column">
                                            <ListItemText
                                                primary={
                                                    <Stack direction="row" alignItems="center">
                                                        <Typography fontWeight={600}>
                                                            {m.user
                                                                ? m.user.user_name
                                                                : m.converter_bot
                                                                    ? "Convertidor de pruebas"
                                                                    : "KiiperBot"}{" "}
                                                            {t("inbox.commented")}:
                                                        </Typography>
                                                    </Stack>
                                                }
                                            ></ListItemText>
                                            <ListItemText
                                                primary={
                                                    <>
                                                        {m.message.includes("\n") ? (
                                                            m.message.split("\n").map((line, index) => (
                                                                <>
                                                                    {line}
                                                                    <br />
                                                                </>
                                                            ))
                                                        ) : m.message.includes("<b>") ? (
                                                            <Typography
                                                                dangerouslySetInnerHTML={{
                                                                    __html: m.message,
                                                                }}
                                                            ></Typography>
                                                        ) : (
                                                            m.message
                                                        )}
                                                        {m.attachments &&
                                                            m.attachments.map((item, index) => (
                                                                <Stack
                                                                    key={"stack_" + index}
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    justifyContent="end"
                                                                    width="fit-content"
                                                                    sx={{
                                                                        backgroundColor: "#FFF",
                                                                        borderRadius: 2,
                                                                        py: 2,
                                                                        pl: 3,
                                                                        pr: 4,
                                                                        my: 1,
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() =>
                                                                        downloadFile(item.originalname, item.filenames3)
                                                                    }
                                                                >
                                                                    <Stack
                                                                        direction="row"
                                                                        spacing={2}
                                                                        alignItems="center"
                                                                        justifyContent="start"
                                                                    >
                                                                        <Box
                                                                            component="img"
                                                                            sx={{
                                                                                height: 40,
                                                                                width: 40,
                                                                                maxHeight: { xs: 40, md: 40 },
                                                                                maxWidth: { xs: 40, md: 40 },
                                                                            }}
                                                                            src={getIcon(item.originalname)}
                                                                        />
                                                                        <Box sx={{ textAlign: "start" }}>
                                                                            <Typography variant="h4">
                                                                                {item.originalname}
                                                                            </Typography>
                                                                            <Typography fontSize={12} fontWeight={600}>
                                                                                {formatBytes(item.size)}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Stack>
                                                                </Stack>
                                                            ))}
                                                    </>
                                                }
                                            ></ListItemText>
                                            <ListItemText
                                                secondary={
                                                    <Typography fontSize={12} color="gray">
                                                        {moment(convertTZ(m.date, "America/New_York")).format(
                                                            `${todos.dateFormat} hh:mm A`
                                                        )}
                                                    </Typography>
                                                }
                                            ></ListItemText>
                                        </Stack>
                                    </Box>
                                </ListItem>
                                {m.buttons === true && (
                                    <Stack spacing={1} justifyContent="end" mr={1}>
                                        <Box display="flex" justifyContent="end">
                                            <Button
                                                variant="contained"
                                                sx={{ fontWeight: 500 }}
                                                onClick={(e) => development(e)}
                                                disabled={showButtons}
                                            >
                                                Devolver a desarrollo 🔧
                                            </Button>
                                        </Box>
                                        <Box display="flex" justifyContent="end">
                                            <Button
                                                variant="contained"
                                                sx={{ fontWeight: 500 }}
                                                onClick={(e) => success(e)}
                                                disabled={showButtons}
                                            >
                                                La conversión es correcta ✅
                                            </Button>
                                        </Box>
                                    </Stack>
                                )}
                            </>
                        ))}
                    </List>
                    <Divider />
                    <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ backgroundColor: "#F9F8FD", borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}
                    >
                        <Grid item xs={10} sx={{ px: 3, py: 2 }}>
                            <TextField
                                inputRef={inputRef}
                                variant="standard"
                                placeholder={chatDisabled ? t("inbox.disabledChat") : loadingFile ? t("inbox.disabledChat2") : t("inbox.queryHere")}
                                fullWidth
                                name="name"
                                disabled={!room || chatDisabled || loadingFile}
                                InputProps={{ disableUnderline: true }}
                                onKeyPress={onKeyPress}
                                onKeyUp={handleKeyUp}
                                multiline
                                sx={{
                                    input: {
                                        "&::placeholder": {
                                            color: "#131f3e",
                                            fontWeight: 600
                                        },
                                        "&.Mui-disabled": {
                                            "&::placeholder": {
                                                opacity: 1,
                                            },
                                        },
                                    },
                                }}
                                onDragOver={handleDragOver}
                                onDrop={handleFileChange}
                            />
                        </Grid>
                        <Grid item sx={{ pr: 3 }}>
                            <Stack direction="row" sx={{ display: "flex", alignItems: "center" }} spacing={1}>
                                <IconButton component="label" disabled={!room || fileLimit || chatDisabled || loadingFile}>
                                    <AttachFileIcon fontSize="small" sx={{ color: "#131F3EB2" }} />
                                    <VisuallyHiddenInput
                                        type="file"
                                        onChange={handleFileChange}
                                        multiple
                                        accept="application/pdf, image/*, .csv, .txt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    />
                                </IconButton>
                                <IconButton type="submit" disabled={!room || chatDisabled || loadingFile}>
                                    <SendIcon sx={{ color: "#131F3EB2" }} fontSize="small" />
                                </IconButton>
                            </Stack>
                        </Grid>
                        {progress > 0 && <LinearProgressWithLabel value={progress} />}
                        {selectedFiles.map((item, index) => (
                            <Grid
                                item
                                key={item.name}
                                xs={12}
                                sx={{
                                    padding:
                                        selectedFiles.length - 1 === index ? "5px 25px 15px 25px" : "0px 25px 5px 25px",
                                }}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ backgroundColor: "#D9D9D9", borderRadius: 2, padding: "10px 10px" }}
                                >
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Box
                                            component="img"
                                            sx={{
                                                height: 40,
                                                width: 40,
                                                maxHeight: { xs: 40, md: 40 },
                                                maxWidth: { xs: 40, md: 40 },
                                            }}
                                            src={getIcon(item.filename)}
                                        />
                                        <Box>
                                            <Typography variant="h4">{item.filename}</Typography>
                                            <Typography fontSize={12} fontWeight={600}>
                                                {formatBytes(item.size)}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                    <IconButton onClick={() => deleteFiles(index)}>
                                        <CancelIcon />
                                    </IconButton>
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                </form>
            )}
            <CustomPopover
                id={id}
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClosePop}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                sx={{ borderRadius: 20 }}
            >
                <Box sx={{ padding: "10px 30px", backgroundColor: "#6544DB" }}>
                    <Typography variant="h2" sx={{ color: "white", pb: 1 }}>
                        {selectedCase.company_name}
                    </Typography>
                    <Typography sx={{ color: "white", fontSize: 12 }}>
                        {t("inbox.origin")}:{" "}
                        {selectedCase.case_reason === 1
                            ? t("inbox.conversions")
                            : selectedCase.case_reason === 2
                                ? t("inbox.newBank")
                                : t("inbox.directRequest")}
                    </Typography>
                    <Typography sx={{ color: "white", fontSize: 12 }}>
                        {t("inbox.interID")}: {selectedCase.case_number}
                    </Typography>
                    {selectedCase.case_reason === 1 && (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography sx={{ color: "white", fontSize: 12 }}>
                                {t("inbox.convID")}: {selectedCase.conversion_id}
                            </Typography>
                            <Chip
                                label={
                                    selectedCase.conversion_status === 3
                                        ? t("converter.successful")
                                        : selectedCase.conversion_status === 4
                                            ? t("converter.inReview")
                                            : t("converter.invalid")
                                }
                                color={
                                    selectedCase.conversion_status === 3
                                        ? "success"
                                        : selectedCase.conversion_status === 4
                                            ? "warning"
                                            : "error"
                                }
                                size="small"
                                sx={{ color: "white", fontSize: 12 }}
                            />
                        </Stack>
                    )}
                    {selectedCase.case_reason === 1 && (
                        <Typography sx={{ color: "white", fontSize: 12 }}>
                            {selectedCase.bank_name} - {selectedCase.bank_country} - {selectedCase.bank_currency}
                        </Typography>
                    )}
                </Box>
                <Box sx={{ padding: "10px 30px" }}>
                    <Typography variant="h2" color="primary" sx={{ pb: 1 }}>
                        {selectedCase.case_user}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={0.5} display="flex" sx={{ pb: 1 }}>
                        <Typography sx={{ textDecoration: "underline" }}>{selectedCase.case_user_email}</Typography>
                        <CopyToClipboard text={selectedCase.case_user_email} onCopy={handleCopy}>
                            <div>
                                <LightTooltip title={copied ? t("inbox.copied") : t("inbox.copy")} position="bottom">
                                    <FileCopyIcon fontSize="inherit" sx={{ cursor: "pointer" }} />
                                </LightTooltip>
                            </div>
                        </CopyToClipboard>
                    </Stack>
                    <Typography sx={{ pb: 1 }}>
                        {selectedCase.phone_code} {selectedCase.phone_number}
                    </Typography>
                </Box>
            </CustomPopover>
        </>
    );
};

export default ChatBox;
