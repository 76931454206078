import moment from "moment";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DashboardIcon from "@mui/icons-material/Dashboard";

/** MUI icons */
import { useTranslation } from "react-i18next";
import { useState } from "react";

import TablePaginationActions from "../ui/TablePagActions";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import subscriptionService from "../../services/subscription";
import SimpleDialog from "../ui/SimpleDialog";
import { Invoice } from "../subscription/Invoice";
import FiltersButtonBilling from "./FiltersButtonBilling";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.03)",
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        invoice_number: <Skeleton variant="text" animation="wave" />,
        franchise_name: <Skeleton variant="text" animation="wave" />,
        creation_date: <Skeleton variant="text" animation="wave" />,
        concept: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
    });
}

const plansInfo = [
    {
        planId: 1,
        credits: 5,
    },
    {
        planId: 2,
        credits: 15,
    },
    {
        planId: 3,
        credits: 30,
    },
    {
        planId: 4,
        credits: 50,
    },
    {
        planId: 5,
        credits: 200,
    },
    {
        planId: 6,
        credits: 500,
    },
];

const Billing = (props) => {
    const { setIsLoading, setOption, subscriptionFilter, countriesFilter, loadingF } = props;

    const [t] = useTranslation("global");
    /* React redux */
    const todos = useSelector((state) => state.value);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [rows, setRows] = useState(rowsSkeleton);
    const [invoiceInfo, setInvoiceInfo] = useState(null);
    const [rowsOG, setRowsOG] = useState([]);
    const [openBill, setOpenBill] = useState(false);
    const [total, setTotal] = useState(0);
    const [paid, setPaid] = useState(0);
    const [pending, setPending] = useState(0);

    const columns = [
        { name: "invoice_number", title: t("converter.number"), align: "center" },
        { name: "franchise_name", title: t("navBar.subscription"), align: "left", minWidth: 160 },
        { name: "creation_date", title: t("inbox.billDate"), align: "center" },
        { name: "concept", title: t("payment.concept"), align: "left" },
        { name: "amount", title: t("credits.amount"), align: "center" },
        { name: "status", title: t("inbox.statusBill"), align: "left" },
        { name: "actions", title: <></> },
    ];

    const handleCloseModal = () => {
        setOpenBill(false);
    };

    const downloadBill = async (invoice) => {
        setIsLoading(true);
        await subscriptionService
            .generateInvoice({ invoice_id: invoice.id })
            .then(async (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Invoice #" + invoice.numberInv + ".pdf");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => console.log(err));
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            const getConcept = (concept) => {
                const finalConcept = [];
                for (let i = 0; i < concept.length; i++) {
                    if (concept[i].type === 1) {
                        finalConcept.push(
                            t("subscription.activation") +
                            " Plan " +
                            plansInfo.find((item) => item.planId === concept[i].value).credits
                        );
                    } else if (concept[i].type === 2) {
                        finalConcept.push(
                            t("subscription.renewal") +
                            " Plan " +
                            plansInfo.find((item) => item.planId === concept[i].value).credits
                        );
                    } else if (concept[i].type === 3 || concept[i].type === 4) {
                        finalConcept.push(
                            t("payment.update") +
                            " Plan " +
                            plansInfo.find((item) => item.planId === concept[i].value).credits
                        );
                    } else {
                        finalConcept.push(
                            t("credits.buySingleCred").charAt(0).toUpperCase() +
                            t("credits.buySingleCred").slice(1) +
                            " " +
                            concept[i].value
                        );
                    }
                }
                return finalConcept.join(" + ");
            };

            const handleOpenBill = async (invoice) => {
                if (invoice) {
                    setIsLoading(true);
                    await subscriptionService
                        .getInvoiceDetails({ invoice_id: invoice })
                        .then((data) => {
                            setInvoiceInfo(data);
                            setOpenBill(true);
                        })
                        .catch((err) => console.log(err));
                    setIsLoading(false);
                }
            };

            await subscriptionService
                .getAllInvoices()
                .then((data) => {
                    let total = 0;
                    let pending = 0;
                    let paid = 0;

                    const notAssingData = data.map((item) => {
                        total += item.totalAmount;

                        if (item.invoice_status === 1) {
                            paid += item.totalAmount;
                        }
                        else {
                            pending += item.totalAmount;
                        }

                        return {
                            id: item._id,
                            numberInv: item.invoice_number,
                            invoice_number: (
                                <Button size="small" onClick={() => handleOpenBill(item._id)}>
                                    #{item.invoice_number}
                                </Button>
                            ),
                            franchise_name: item.franchise_id?.franchise_name,
                            creation_date: moment.utc(item.creation_date).format(todos.dateFormat),
                            concept: item.invoice_description ? getConcept(item.invoice_description) : "",
                            amount: parseFloat(item.totalAmount).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                            }),
                            status_name: item.invoice_status === 1 ? t("payment.paid") : t("payment.pending"),
                            status:
                                item.invoice_status === 1 ? (
                                    <Stack direction="row" justifyContent="left" spacing={1} alignItems="center">
                                        <CheckIcon fontSize="small" color="success" />
                                        <Typography>{t("payment.paid")}</Typography>
                                    </Stack>
                                ) : (
                                    <Stack direction="row" justifyContent="left" spacing={1} alignItems="center">
                                        <AccessTimeIcon fontSize="small" color="warning" />
                                        <Typography>{t("payment.pending")}</Typography>
                                    </Stack>
                                ),
                        };
                    });

                    setTotal(total);
                    setPending(pending);
                    setPaid(paid);
                    setRows(notAssingData);
                    setRowsOG(notAssingData);
                })
                .catch((err) => {
                    console.log(err);
                });
        })();
    }, [setIsLoading, t, todos.amountFormat, todos.dateFormat]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);

        const filterCases = rowsOG.filter(
            (item) =>
                item.numberInv.includes(event.target.value) ||
                item.franchise_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.concept.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.status_name.toLowerCase().includes(event.target.value.toLowerCase())
        );

        setRows(filterCases);
    };

    const goToDashboard = () => {
        setOption(null);
    }

    const getConcept = (concept) => {
        const finalConcept = [];
        for (let i = 0; i < concept.length; i++) {
            if (concept[i].type === 1) {
                finalConcept.push(
                    t("subscription.activation") +
                    " Plan " +
                    plansInfo.find((item) => item.planId === concept[i].value).credits
                );
            } else if (concept[i].type === 2) {
                finalConcept.push(
                    t("subscription.renewal") +
                    " Plan " +
                    plansInfo.find((item) => item.planId === concept[i].value).credits
                );
            } else if (concept[i].type === 3 || concept[i].type === 4) {
                finalConcept.push(
                    t("payment.update") +
                    " Plan " +
                    plansInfo.find((item) => item.planId === concept[i].value).credits
                );
            } else {
                finalConcept.push(
                    t("credits.buySingleCred").charAt(0).toUpperCase() +
                    t("credits.buySingleCred").slice(1) +
                    " " +
                    concept[i].value
                );
            }
        }
        return finalConcept.join(" + ");
    };

    const handleOpenBill = async (invoice) => {
        if (invoice) {
            setIsLoading(true);
            await subscriptionService
                .getInvoiceDetails({ invoice_id: invoice })
                .then((data) => {
                    setInvoiceInfo(data);
                    setOpenBill(true);
                })
                .catch((err) => console.log(err));
            setIsLoading(false);
        }
    };


    return (
        <>
            <Card>
                <CardContent sx={{ padding: 0, paddingBottom: "12px !important" }}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ pb: 2, pt: 2, px: { xs: 1, md: 5 } }}
                    >
                        <Grid item>
                            <Typography variant="h1" color="primary">
                                {t("payment.billing")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    <Button variant="contained" endIcon={<DashboardIcon />} onClick={goToDashboard} sx={{py: "12px"}}>
                                        Ver dashboard
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {loadingF && <FiltersButtonBilling
                                        subscriptionFilter={subscriptionFilter}
                                        countriesFilter={countriesFilter}
                                        setRows={setRows}
                                        handleOpenBill={handleOpenBill}
                                        todos={todos}
                                        getConcept={getConcept}
                                        setPage={setPage}
                                    />}
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="filled"
                                        hiddenLabel
                                        placeholder="Buscar..."
                                        //size="small"
                                        value={searchValue}
                                        onChange={handleChangeSearchValue}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2} sx={{ py: 2 }}>
                                <Typography>
                                    <b>Total:</b> $
                                    {parseFloat(total).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                    })}
                                </Typography>
                                <Typography>
                                    <b>{t("payment.paid")}:</b> $
                                    {parseFloat(paid).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                    })}
                                </Typography>
                                <Typography>
                                    <b>{t("payment.pending")}:</b> $
                                    {parseFloat(pending).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                    })}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Box
                component="main"
                sx={{
                    px: { xs: 2, md: 5 },
                    backgroundColor: "#F8F8F9",
                    pb: 4,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {rows.length > 0 ? (<TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        {columns.map((item) => (
                                            <StyledTableCell
                                                key={item.name}
                                                align={item.align ?? "left"}
                                                style={{ minWidth: item.minWidth }}
                                            >
                                                {item.title}
                                            </StyledTableCell>
                                        ))}
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row) => (
                                        <StyledTableRow
                                            key={row.id}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <StyledTableCell align="center">{row.invoice_number}</StyledTableCell>
                                            <StyledTableCell align="left">{row.franchise_name}</StyledTableCell>
                                            <StyledTableCell align="center">{row.creation_date}</StyledTableCell>
                                            <StyledTableCell align="left">{row.concept}</StyledTableCell>
                                            <StyledTableCell align="center">{row.amount}</StyledTableCell>
                                            <StyledTableCell align="left">{row.status}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <IconButton color="primary" onClick={() => downloadBill(row)}>
                                                    <DownloadIcon fontSize="small" />
                                                </IconButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[
                                                10,
                                                25,
                                                50,
                                                { label: t("miscellaneous.all"), value: -1 },
                                            ]}
                                            colSpan={12}
                                            count={rows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "rowsPerPage",
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={TablePaginationActions}
                                            labelRowsPerPage={t("dashboard.rowsPerPage")}
                                            labelDisplayedRows={(page) =>
                                                `${page.from}-${page.to === -1 ? page.count : page.to} ${t(
                                                    "team.unlinkText2"
                                                )} ${page.count}`
                                            }
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>)
                            : <>
                                <Typography fontSize={14} textAlign="center" sx={{ mt: 3 }}>{t("dashboard.noInfo")}</Typography>
                            </>}
                    </Grid>
                </Grid>
            </Box>
            <SimpleDialog
                open={openBill}
                handleClose={handleCloseModal}
                maxWidth="md"
                content={<Invoice invoiceInfo={invoiceInfo} />}
                invoice={true}
            />
        </>
    );
};

export default Billing;
