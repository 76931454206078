import io from "socket.io-client";
let socket;
export const initiateSocket = (room, user, type, correlative) => {
    socket = io(process.env.REACT_APP_URL);
    if (socket && room) socket.emit("join", { room, user, type, correlative });
};
export const disconnectSocket = (room, user, type) => {
    if (socket) {
        socket.emit("customDisconnect", { room, user, type });
        socket.disconnect();
    }
};
export const subscribeToChat = (cb) => {
    if (!socket) return true;
    socket.on("chat", (msg) => {
        return cb(null, msg);
    });
};
export const sendMessage = (e, room, message, user, input, type, franchise_name) => {
    e.preventDefault();
    if (socket) {
        if (message.trim()) {
            socket.emit("chat", { message, room, user, type, franchise_name });
            input.current.value = ""
        }
    }
};

export const newUserResponse = (cb) => {
    if (!socket) return true;
    socket.on("newUserResponse", (data) => {
        return cb(null, data);
    });
};

export const emitMessage = (room, messageId) => {
    if (socket) {
        socket.emit('chatEmitMessage', { room, messageId });
    }
}

export const initiateSocketIntern = (room, user) => {
    socket = io(process.env.REACT_APP_URL);
    if (socket && room) socket.emit("joinIntern", { room, user });
};

export const subscribeToChatIntern = (cb) => {
    if (!socket) return true;
    socket.on("chatIntern", (msg) => {
        return cb(null, msg);
    });
};

export const newUserResponseIntern = (cb) => {
    if (!socket) return true;
    socket.on("newUserResponseIntern", (data) => {
        return cb(null, data);
    });
};

export const disconnectSocketIntern = (room, user) => {
    if (socket) {
        socket.emit("customDisconnectIntern", { room, user });
        socket.disconnect();
    }
};

export const sendMessageIntern = (e, room, message, user, input) => {
    e.preventDefault();
    if (socket) {
        if (message.trim()) {
            socket.emit("chatIntern", { message, room, user });
            input.current.value = ""
        }
    }
};

export const emitMessageIntern = (room, messageId) => {
    if (socket) {
        socket.emit('chatEmitMessageIntern', { room, messageId });
    }
}