import { Box } from "@mui/material";

import classes from "./BackGeneral.module.css";

const BackGeneral = (props) => {
    const { children, fix } = props;

    return (
        <Box className={classes.background} sx={{
            background: !fix ? ({
                md: "linear-gradient(to bottom, #0000 7%, rgba(19, 31, 62, 0.03) 7.1%) top /2000px 1730px no-repeat #fff",
                lg: "linear-gradient(to bottom, #0000 3.2%, rgba(19, 31, 62, 0.03) 3.3%) top /4000px 3730px no-repeat #fff",
                xl: "linear-gradient(to bottom, #0000 2.1%, rgba(19, 31, 62, 0.03) 2.2%) top /6000px 5730px no-repeat #fff"
            }) : ({
                md: "linear-gradient(to bottom, #0000 10%, rgba(19, 31, 62, 0.03) 10.1%) top /2000px 1730px no-repeat #fff",
                lg: "linear-gradient(to bottom, #0000 4.7%, rgba(19, 31, 62, 0.03) 4.8%) top /4000px 3730px no-repeat #fff",
                xl: "linear-gradient(to bottom, #0000 3.0%, rgba(19, 31, 62, 0.03) 3.1%) top /6000px 5730px no-repeat #fff"
            }),
        }}>
            {children}
        </Box>
    );
};

export default BackGeneral;
