import axios from "axios";

/* Script que contiene las llamadas a la API */
const conversion = {
    getGroupedConversionDetails: (params) => {
        const request = axios.get("/getGroupedConversionDetails", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    getGroupedConversions: (params, options = {}) => {
        const request = axios.get("/getGroupedConversions", { params: params, ...options });

        return request.then((response) => response.data);
    },

    // Petición para borrar el archivo agrupado de BD
    deleteConversionFromGroup: (params) => {
        const request = axios.post("/deleteConversionFromGroup", params);

        return request.then((response) => response);
    },

    // Petición que retorna el id de la conversion agrupada
    createGroupedConversion: (params) => {
        const request = axios.post("/createGroupedConversion", params);

        return request.then((response) => response.data);
    },

    // Petición para cargar el archivo que se va a convertir
    uploadFileS3: (params, options) => {
        const request = axios.post("/uploadFileS3", params, options);

        return request.then((response) => response.data);
    },

    // Petición para cargar multiples archivos que se va a convertir
    uploadMulFileS3: (params, options) => {
        const request = axios.post("/uploadMulFileS3", params, options);

        return request.then((response) => response.data);
    },

    // Petición para cargar multiples archivos que se va a convertir
    uploadFilesChat: (params, options) => {
        const request = axios.post("/uploadFilesChat", params, options);

        return request.then((response) => response.data);
    },

    // Petición para borrar varios archivos en s3
    deleteMulFileS3: (params, options) => {
        const request = axios.post("/deleteMulFileS3", params, options);

        return request.then((response) => response);
    },

    getConversions: (params, options = {}) => {
        const request = axios.get("/getConversions", { params: params, ...options });

        return request.then((response) => response.data);
    },

    // Petición para convertir el archivo cargado en S3
    checkFile: (params) => {
        const request = axios.post("/checkFile", params);

        return request.then((response) => {
            // check for error response
            if (response.status === 200) {
                return response.data;
            } else {
                return response.status;
            }
        });
    },
    // Petición para convertir el archivo cargado en S3
    convertBankStatement: (fetchEndpoint, params) => {
        const request = axios.post(fetchEndpoint, params);

        return request.then((response) => {
            // check for error response

            if (response.status === 200) {
                return response.data;
            } else {
                return response.status;
            }
        });
    },

    getConversionUsers: (params, options = {}) => {
        const request = axios.get("/getConversionUsers", {
            params: params,
            ...options,
        });

        return request.then((response) => response.data);
    },

    getBankStatementsByConversion: (params) => {
        const request = axios.get("/getBankStatementsByConversion", { params: params, responseType: "blob" });

        return request.then((response) => response);
    },

    getConversionDetails: (params) => {
        const request = axios.get("/getConversionDetails", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    downloadConversion: (params) => {
        const request = axios.get("/downloadConversion", { params: params });

        return request.then((response) => response.data);
    },

    getConversionsByFranchise: (params) => {
        const request = axios.get("/getConversionsByFranchise", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    getConversionUsersByFranchise: (params) => {
        const request = axios.get("/getConversionUsersByFranchise", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    getFileS3: (params) => {
        const request = axios.get("/getFileS3", { params: params, responseType: "blob" });

        return request.then((response) => response);
    },

    // Petición para notificar a soporte sobre un intento fallido por cargar un archivo pesado
    notifySupport: (params) => {
        const request = axios.post("/notifySupport", params);

        return request.then((response) => response);
    },

    // Petición para obtener url de Xero
    getImportUrl: (params) => {
        const request = axios.get("/getImportUrl", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Petición para probar la conversion en el chat de soporte
    testBankStatementChat: (params) => {
        const request = axios.post("/testBankStatementChat", params);

        return request.then((response) => {
            // check for error response

            if (response.status === 200) {
                return response.data;
            } else {
                return response.status;
            }
        });
    },

    // Peticion para descargar reporte de conversiones
    generateConversionsReport: (params) => {
        const request = axios.get("/generateConversionsReport", { params: params, responseType: "blob" });

        return request.then((response) => response);
    },

    // Petición para borrar el archivo en s3
    deleteFileS3: (params, options) => {
        const request = axios.post("/deleteFileS3", params, options);

        return request.then((response) => response);
    },

    // Petición para obtener los datos de la conversion
    getTempConversionDetails: (params) => {
        const request = axios.get("/getTempConversionDetails", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    getFileUrlS3: (params) => {
        const request = axios.get("/getFileUrlS3", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    getConversionStatus: (params) => {
        const request = axios.get("/getConversionStatus", {
            params: params,
        });

        return request.then((response) => response);
    },

    // Obtenemos los cheques convertidos
    getCheckConversions: (params, options = {}) => {
        const request = axios.get("/getCheckConversions", { params: params, ...options });

        return request.then((response) => response.data);
    },
    // Obtenemos los cheques multiples convertidos
    getGroupedCheckConversions: (params, options = {}) => {
        const request = axios.get("/getGroupedCheckConversions", { params: params, ...options });

        return request.then((response) => response.data);
    },

    // Obtenemos los cuentas contables de la empresa
    getChartOfAccounts: (params) => {
        const request = axios.get("/getChartOfAccounts", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Obtenemos los contactos de una empresa
    getContacts: (params) => {
        const request = axios.get("/getContacts", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Sincronizamos los cheques
    syncChecks: (params) => {
        const request = axios.post("/syncChecks", params);

        return request.then((response) => response);
    },

    // Marcamos los cheques como registrados
    markCheckAsRegistered: (params) => {
        const request = axios.post("/markCheckAsRegistered", params);

        return request.then((response) => response);
    },

    // Petición para cargar el archivo estados de cuenta que se va a convertir
    uploadFileBankStatement: (params, options) => {
        const request = axios.post("/uploadFileBankStatement", params, options);

        return request.then((response) => response);
    },

    // Obtenemos los taxes de Xero
    getXeroTaxRates: (params) => {
        const request = axios.get("/getXeroTaxRates", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Sincronizamos las facturas
    syncInvoices: (params) => {
        const request = axios.post("/syncInvoices", params);

        return request.then((response) => response);
    },

    // Obtenemos los items de Xero
    getXeroItems: (params) => {
        const request = axios.get("/getXeroItems", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Sincronizamos las facturas consolidadas
    syncConsolidatedInvoices: (params) => {
        const request = axios.post("/syncConsolidatedInvoices", params);

        return request.then((response) => response.data);
    },

    // Descarga de facturas consolidadas
    downloadConsolidatedInvoice: (params) => {
        const request = axios.get("/downloadConsolidatedInvoice", { params: params });

        return request.then((response) => response.data);
    },

    downloadHistoryConsolidatedInvoices: (params) => {
        const request = axios.get("/downloadHistoryConsolidatedInvoices", { params: params });

        return request.then((response) => response.data);
    },

    addLineItem: (params) => {
        const request = axios.post("/addLineItem", params);

        return request.then((response) => response);
    },
};

export default conversion;
