/** Components ----------------------------------------------------------- */
import { createTheme } from "@mui/material/styles";

/** Global variables ----------------------------------------------------------- */
import GoldplayMedium from "./fonts/Goldplay-Medium.otf";
import GoldplaySemiBold from "./fonts/Goldplay-SemiBold.otf";
import GoldplayBold from "./fonts/Goldplay-Bold.otf";

/* Theme settings */
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 300,
            sm: 600,
            md: 1280,
            lg: 1680,
            xl: 2560,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 50,
        },
    },
    palette: {
        primary: {
            main: "#4a22d4",
        },
        secondary: {
            main: "#00ffff",
        },
        text: {
            primary: "#131f3e",
            secondary: "#927ae5",
        },
        error: {
            main: "#FF007A", // Reemplaza este valor con el color de error que desees
        },
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: "Goldplay, sans-serif",
        fontWeightMedium: 500,
        h1: {
            fontFamily: "Goldplay, sans-serif",
            fontWeight: 600,
            fontSize: 18,
        },
        h2: {
            fontFamily: "Goldplay, sans-serif",
            fontWeight: 600,
            fontSize: 16,
        },
        h3: {
            fontFamily: "Goldplay, sans-serif",
            fontWeight: 600,
            fontSize: 15,
        },
        h4: {
            fontFamily: "Goldplay, sans-serif",
            fontWeight: 600,
            fontSize: 14,
        },
        subtitle1: {
            fontFamily: "Goldplay, sans-serif",
            fontWeight: 500,
            fontSize: 16,
        },
        subtitle2: {
            fontFamily: "Goldplay, sans-serif",
            fontWeight: 600,
            fontSize: 25,
        },
        body1: {
            fontFamily: "Goldplay, sans-serif",
            fontWeight: 500,
            fontSize: 14,
        },
        body2: {
            fontFamily: "Goldplay, sans-serif",
            fontWeight: 500,
            fontSize: 16,
        },
        button: {
            fontFamily: "Goldplay, sans-serif",
            fontWeight: 600,
            fontSize: 15,
            textTransform: "none",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
                font-family: 'Goldplay';
                font-style: normal;
                font-display: swap;
                font-weight: 500;
                src: local('Goldplay'), local('Goldplay-Medium'), url(${GoldplayMedium}) format('opentype');
            }
            
            @font-face {
                font-family: 'Goldplay';
                font-style: normal;
                font-display: swap;
                font-weight: 600;
                src: local('Goldplay'), local('Goldplay-SemiBold'), url(${GoldplaySemiBold}) format('opentype');
            }

            @font-face {
                font-family: 'Goldplay';
                font-style: normal;
                font-display: swap;
                font-weight: 700;
                src: local('Goldplay'), local('Goldplay-Bold'), url(${GoldplayBold}) format('opentype');
            }
          `,
        },
    },
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "#4a22d4",
                "& button:hover": {
                    backgroundColor: "#927ae5",
                },
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                "& span": {
                    color: "#4a22d4",
                },
                "& p": {
                    fontWeight: "600",
                    color: "#4a22d4",
                    fontSize: 14,
                },
            },
            daysHeader: {
                "& span": {
                    fontWeight: "600",
                    color: "#927ae5",
                    fontSize: 10,
                },
            },
        },
        MuiPickersDay: {
            day: {
                color: "#131f3e",
                "& p": {
                    fontWeight: "600",
                    fontSize: 14,
                },
            },
            daySelected: {
                color: "#131f3e",
                backgroundColor: "#e4def9",
                "&:hover": {
                    backgroundColor: "#e4def9",
                },
            },
            current: {
                color: "#4a22d4",
            },
        },
    },
});

export default theme;
