/** React imports */
import { useTranslation } from "react-i18next";

/** MUI imports */
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#4A22D4",
    color: "#FFF",
    fontStyle: "normal",
    fontWeight: "350",
    border: "1px solid #4A22D4",
    borderRadius: "10px",
    "&:hover": {
        backgroundColor: "#2f3190",
        border: "1px solid #2f3190",
    },
    "&:disabled": {
        backgroundColor: "#B3B3B3",
        border: "1px solid #B3B3B3",
        color: "#FFF",
    },
}));

const AddCompanyButtons = (props) => {
    const [t] = useTranslation("global");

    const { activeStep, steps, handleNext, createCompany, onClose, isErrorName, smbInfo } = props;

    return (
        <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
            <Button
                color="inherit"
                //disabled={activeStep === 0}
                onClick={onClose}
                sx={{ mr: 1, color: "#4a22d4" }}
            >
                {activeStep === 0 ? t("dialog.skip2") : t("dialog.skip")}
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep < steps.length - 1 && (
                <ColorButton
                    onClick={handleNext}
                    disabled={isErrorName || !smbInfo.companyName.trim() || !smbInfo.country || smbInfo.systems.length === 0 || !smbInfo.ecActivity}
                    sx={{
                        "&:hover": {
                            backgroundColor: "#2f3190",
                            border: "1px solid #2f3190",
                        },
                    }}
                    disableElevation
                    variant="contained"
                >
                    {t("dialog.continue")}
                </ColorButton>
            )}
            {activeStep === steps.length - 1 && (
                <ColorButton onClick={createCompany} disableElevation variant="contained">
                    {t("dialog.continue")}
                </ColorButton>
            )}
        </Box>
    );
};

export default AddCompanyButtons;
