import axios from "axios";

/* Script que contiene las llamadas a la API */
const userFormat = {
    
    updateDefaultFormat: (params) => {
        const request = axios.post("/updateDefaultFormat", params);

        return request.then((response) => response);
    },

    getUserFormat: (id, options = {}) => {
        const request = axios.get(`/getUserFormat?company_id=${id}`, {...options});

        return request.then((response) => response.data);
    },
}

export default userFormat;
