/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import introJs from "intro.js";

/** MUI icons */
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loading from "../../../assets/loading.png";
import DownloadIcon from "@mui/icons-material/Download";
import SyncIcon from "@mui/icons-material/Sync";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { read, utils } from "xlsx";

/** MUI imports */
import { Box, Stack, Typography, Skeleton, IconButton, Button } from "@mui/material";
import LightTooltip from "../../ui/LightTooltip";
import GridUI from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import ChatIcon from "../../../assets/chat-icon.svg";
import BaseSnackbar from "../../ui/BaseSnackbar";
import SimpleBackdrop from "../../ui/SimpleBackdrop";
import { useDispatch, useSelector } from "react-redux";
import conversionService from "../../../services/conversion";
import casesService from "../../../services/case";
import { initiateSocket, newUserResponse, subscribeToChat } from "../../../services/Socket";
import ChatModal from "../../ui/Chat";
import { addTutorial } from "../../../redux/userSlice";
import SimpleDialog from "../../ui/SimpleDialog";

import invoicesService from "../../../services/invoices";
import FiltersButtonInvoices from "../FiltersButtonInvoices";

import { fetchFileFromS3, getFileExtension, zeroPad } from "../../../utils/utils";
import BaseTable from "../BaseTable";

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        number: <Skeleton variant="text" animation="wave" />,
        uploadDate: <Skeleton variant="text" animation="wave" />,
        invoiceDate: <Skeleton variant="text" animation="wave" />,
        amount: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        chat: <Skeleton variant="text" animation="wave" />,
    });
}

const ConsolidatedInvoicesTable = (props) => {
    const {
        consolidatedConversions,
        usersFilters,
        company_id,
        setConsolidatedConversions,
        franchise_id,
        grupal,
        showSkeleton,
        setShowSkeleton,
        setIsLoading,
        setFilters,
        filters,
        tutorial,
        setConvertDoneNew,
        setCorrelative,
        setFormat,
        setConversionId,
        setFileNameSaved,
        setShowPrevConsolidated,
        setFileData,
        setFileNameFull,
        setConversionData,
        download,
        setDownload,
        handleShowPrevDownload,
        setModalType,
        integration,
        setShowSync,
        columns,
        tableColumnExtensions,
        reloadInvoices,
        setRowsModal,
        setOpenPreview,
        setHTML,
        conversion_type,
        setBasePreviewData
    } = props;

    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const [searchValue, setSearchState] = useState("");
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [rows, setRows] = useState([]);
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState();
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));
    const [caseInfo, setCaseInfo] = useState({});
    const [selectionAcc, setSelectionAcc] = useState([]);
    const [showModalReg, setShowModalReg] = useState(false);

    useEffect(() => {
        setSelectionAcc([])
        setSearchState("")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversion_type])

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const synChecks = () => {
        const arraySync = [];

        selectionAcc.forEach((selectedRow) => {
            arraySync.push(rows[selectedRow]);
        });

        const newArr = arraySync.map((item) => ({
            _id: item.id,
            check_number: item.check,
            check_pay_to: item.contact,
            check_date: item.startDate,
            check_description: item.concept,
            formatted_amount: item.balance,
        }));

        setConversionData({ checks: newArr });

        setShowSync(true);
        setShowPrevConsolidated(true);
    };

    const handleCloseRegModal = () => {
        setShowModalReg(false);
    };

    const handleRegister = async () => {
        setIsLoading(true);

        const arrayValidation = [];

        selectionAcc.forEach((selectedRow) => {
            arrayValidation.push(rows[selectedRow].id);
        });
        const params = {
            checks_id: arrayValidation,
        };

        await conversionService
            .markCheckAsRegistered(params)
            .then(async () => {
                setSelectionAcc([]);
                await reloadInvoices();
            })
            .catch((err) => {
                console.log(err);
            });

        setShowModalReg(false);
        setIsLoading(false);
    };

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    useEffect(() => {
        if ((todos.steps.step8 || todos.steps.step9) && tutorial && download) {
            let number = "";

            if (todos.steps.step8) {
                number = "8";
            } else {
                number = "9";
            }
            setTimeout(() => {
                introJs()
                    .setOptions({
                        disableInteraction: true,
                        exitOnOverlayClick: false,
                        keyboardNavigation: false,
                        nextLabel: t("dialog.continue"),
                        prevLabel: t("miscellaneous.goBack"),
                        doneLabel: t("miscellaneous.accept"),
                        steps: [
                            {
                                title: `${t("miscellaneous.step")} ${number}`,
                                element: "#account8-tutorial",
                                intro: t("miscellaneous.tutorialConv5"),
                            },
                        ],
                    })
                    .onexit(() => {
                        if (todos.steps.step8) {
                            dispatch(addTutorial({ ...todos.steps, step8: false }));
                        } else {
                            dispatch(addTutorial({ ...todos.steps, step9: false }));
                        }
                        setDownload(false);
                    })
                    .start();
            }, 1000);
        }
    }, [dispatch, download, setDownload, t, todos.steps, tutorial]);

    useEffect(() => {
        const handleShowPrev = async (convID) => {
            setOpenBackdrop(true);
            let converInfo = {};

            let params2 = { invoice_id: convID };
            await invoicesService
                .getConsolidatedInvoiceDetails(params2)
                .then(async (data) => {
                    setConversionData(data);
                    converInfo = data;
                })
                .catch((err) => {
                    console.log(err);
                });

            setCorrelative(converInfo.correlative);
            setConversionId(converInfo._id);
            setFileNameSaved(converInfo.uploaded_file.replace(/\.[^/.]+$/, ""));

            const data = await fetchFileFromS3(converInfo.file_name);

            if (!data) {
                setTexto(t("converter.dnldError"));
                setAlertType("error");
                setShow(true);
            } else {
                setFileData(data);
                if (getFileExtension(converInfo.file_name) === "xlsx" || getFileExtension(converInfo.file_name) === "xls") {
                    const f = await data.arrayBuffer();

                    /* parse workbook */
                    const workbook = read(f, { type: "binary" });
                    /* get first worksheet */
                    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    /* generate and display HTML */
                    const table = utils.sheet_to_html(worksheet);
                    setHTML(table);
                }
            }

            setFileNameFull(converInfo.uploaded_file);

            setShowPrevConsolidated(true);
            setOpenBackdrop(false);
        };

        const connectSocket = async (case_id, correlative, status) => {
            let typeChat = "general";

            if (status === 2 || status === 5) {
                typeChat = "conversion";
            }

            setRoom(case_id);
            initiateSocket(case_id, todos.userInfo, typeChat, correlative);
            subscribeToChat((err, data) => {
                if (err) return;
                setChat((oldChats) => [...oldChats, data]);
            });
            newUserResponse((err, data) => {
                if (err) return;
                setUsers(data);
            });
            setOpen(true);
        };

        if (showSkeleton) {
            setRows(rowsSkeleton);
        } else {
            const handleClickChat = async (statusConv, conversionID, correlative) => {
                setIsLoading(true);

                let params = {
                    company_id: todos.companyID,
                    franchise_id: todos.franchiseID,
                    user_email: todos.userInfo.email,
                    type: "conversion",
                    conversion_id: conversionID,
                    conversion_type: "consolidated"
                };

                let caseInfo = null;

                await casesService
                    .getCaseInfo(params)
                    .then(async (response) => {
                        if (response) {
                            setCaseInfo(response.caseInfo);
                            caseInfo = response.caseInfo;
                            setStatus("");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                if (!caseInfo) {
                    await casesService
                        .createNewCase(params)
                        .then(async () => {
                            await casesService
                                .getCaseInfo(params)
                                .then(async (data) => {
                                    setCaseInfo(data.caseInfo);
                                    caseInfo = data.caseInfo;
                                    setStatus(1);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }

                await connectSocket(caseInfo._id, correlative, statusConv);

                setIsLoading(false);
            };

            const previewInvoice = async (filename, id) => {

                setIsLoading(true);

                const data = await fetchFileFromS3(filename);

                if (!data) {
                    setTexto(t("converter.dnldError"));
                    setAlertType("error");
                    setShow(true);
                } else {
                    if (getFileExtension(filename) === "xlsx" || getFileExtension(filename) === "xls") {
                        const f = await data.arrayBuffer();

                        /* parse workbook */
                        const workbook = read(f, { type: "binary" });
                        /* get first worksheet */
                        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                        /* generate and display HTML */
                        const table = utils.sheet_to_html(worksheet);
                        setHTML(table);
                    }
                    setFileData(data);
                    setBasePreviewData({
                        id: id,
                        type: "consolidated",
                    })
                    setFileNameFull(filename);
                }

                setIsLoading(false);

                setOpenPreview(true);
            };

            const successConv = consolidatedConversions.filter((item) => item.id_status === 1).map((item) => item._id);
            const lastSuccess = successConv.length > 0 ? successConv[0] : null;

            if (consolidatedConversions.length > 0) {
                // 1: En proceso
                // 2: En revisión
                // 3: Por sincronizar
                // 4: Por descargar
                // 5: Sincronizando
                // 6: Sincronizado
                // 7: Registro manual
                // 8: Sincronizado manual
                // 9: Descargado
                // 10: Inválida

                setRows(
                    consolidatedConversions.map((item) => ({
                        id: item._id,
                        number:
                            <LightTooltip title={item.invoice_id} aria-label="lastTrans">
                                <Button
                                    onClick={() => previewInvoice(item.file_name, item._id)}
                                >
                                    {item.correlative != null && item.correlative !== undefined
                                        ? "#" + zeroPad(item.correlative, 5)
                                        : ""}
                                </Button>
                            </LightTooltip>,
                        uploadDate: moment.utc(item.current_date).format(todos.dateFormat),
                        amount: item.formatted_invoice_total
                            ? parseFloat(item.formatted_invoice_total).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })
                            : "",
                        invoiceDate:
                            item.formatted_invoice_date === null
                                ? "N/A"
                                : item.formatted_invoice_date
                                    ? moment.utc(item.formatted_invoice_date, "DD-MM-YYYY").format(todos.dateFormat)
                                    : "",
                        user: item?.user_id?.user_name ?? "",
                        status: (
                            <Stack direction="row" alignItems="center" justifyContent="center">
                                {(item.id_status === 1 || item.id_status === 5) && (
                                    <img src={Loading} alt="loading" width={20} />
                                )}
                                {item.id_status === 2 && (
                                    <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                )}
                                {item.id_status === 3 && (
                                    <UploadFileIcon sx={{ color: "#4a22d4" }} fontSize="inherit" />
                                )}
                                {item.id_status === 4 && <DownloadIcon sx={{ color: "#4a22d4" }} fontSize="inherit" />}
                                {(item.id_status === 6 || item.id_status === 7) && (
                                    <UploadFileIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />
                                )}
                                {item.id_status === 8 && (
                                    <UploadFileIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                )}
                                {item.id_status === 9 && <DownloadIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />}
                                {(item.id_status === 10 || item.id_status === 11) && <CloseIcon sx={{ color: "#DA0069" }} fontSize="inherit" />}
                                &nbsp;
                                <Typography>
                                    {item.id_status === 1
                                        ? t("converter.inProc")
                                        : item.id_status === 2
                                            ? t("converter.inReview")
                                            : item.id_status === 3
                                                ? t("converter.toBeSynch")
                                                : item.id_status === 4
                                                    ? t("converter.convertible")
                                                    : item.id_status === 5
                                                        ? t("converter.synchronizing")
                                                        : item.id_status === 6
                                                            ? t("converter.synchronized")
                                                            : item.id_status === 7
                                                                ? t("converter.manualReg")
                                                                : item.id_status === 8
                                                                    ? t("converter.partialSync")
                                                                    : item.id_status === 9
                                                                        ? t("converter.downloaded")
                                                                        : item.id_status === 10
                                                                            ? t("converter.invalid2")
                                                                            : t("navBar.cancelled")}
                                </Typography>
                            </Stack>
                        ),
                        chat:
                            item.id_status === 1 || item.id_status === 2 || item.id_status >= 5 ? (
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <LightTooltip title={t("converter.tooltipChat")} position="top">
                                        <IconButton
                                            onClick={() =>
                                                handleClickChat(
                                                    item.id_status,
                                                    item._id,
                                                    `#${zeroPad(item.correlative, 5)}`
                                                )
                                            }
                                            size="small"
                                            color="primary"
                                        >
                                            <Box component="img" src={ChatIcon} sx={{ width: 17 }} />
                                        </IconButton>
                                    </LightTooltip>
                                    {item.id_status >= 6 && item.id_status <= 9 && (
                                        <IconButton
                                            onClick={() => handleShowPrev(item._id)}
                                            size="small"
                                            color="primary"
                                            id={
                                                lastSuccess !== null && lastSuccess === item._id
                                                    ? "account8-tutorial"
                                                    : ""
                                            }
                                        >
                                            <DownloadIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </Stack>
                            ) : item.id_status === 3 || item.id_status === 4 ? (
                                <LightTooltip title={t("converter.checkDetail")}>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleShowPrev(item._id)}
                                        color="primary"
                                    >
                                        <VisibilityIcon color="primary" sx={{ fontSize: 20 }} />
                                    </IconButton>
                                </LightTooltip>
                            ) : (
                                ""
                            ),
                    }))
                );
            } else {
                setRows([]);
            }
        }
    }, [
        consolidatedConversions,
        language,
        setConversionData,
        setConversionId,
        setConvertDoneNew,
        setCorrelative,
        setFileData,
        setFileNameFull,
        setFileNameSaved,
        setFormat,
        setIsLoading,
        setShowPrevConsolidated,
        showSkeleton,
        t,
        todos.amountFormat,
        todos.companyID,
        todos.dateFormat,
        todos.franchiseID,
        todos.userInfo,
        handleShowPrevDownload,
        setModalType,
        setRowsModal,
        setOpenPreview,
        setHTML,
        setBasePreviewData
    ]);

    const downloadReport = async () => {
        setIsLoading(true);

        let fileName = `InvoiceHistory-${moment().format("MMDDYYYY")}.csv`;

        let conversionIds = rows?.map((e) => e.id);

        if (!showSkeleton && conversionIds?.join(",") !== "0,1,2,3,4,5,6,7,8,9") {
            if (selectionAcc?.length > 0) {
                let finalIds = [];
                conversionIds.forEach((e, i) => {
                    if (selectionAcc.includes(i)) {
                        finalIds.push(e);
                    }
                });

                conversionIds = finalIds;
            }

            conversionIds = conversionIds?.length > 0 ? conversionIds.join(",") : "";

            await conversionService
                .downloadHistoryConsolidatedInvoices({
                    company_id: todos.companyID,
                    file_name: fileName,
                    conversionIds: conversionIds,
                })
                .then((response) => {
                    // Crea un enlace temporal y haz clic en él para iniciar la descarga
                    const link = document.createElement("a");
                    link.href = response.url;
                    document.body.appendChild(link);
                    link.click();

                    // Limpia el enlace temporal
                    document.body.removeChild(link);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        setIsLoading(false);
    };

    return (
        <>
            <Box
                component="main"
                sx={{
                    mx: { xs: 2, md: 2 },
                    px: { xs: 2, md: 3 },
                    backgroundColor: "#F8F8F9",
                    pt: 3,
                    borderRadius: 2,
                }}
            >
                <GridUI container spacing={2} alignItems="center" justifyContent="space-between">
                    <GridUI item>
                        <Typography variant="h1">{t("converter.consolidatedInvHist")}</Typography>
                    </GridUI>
                    <GridUI item xs={12} md={6} lg={4}>
                        <GridUI container spacing={2} alignItems="center" justifyContent="flex-end">
                            <GridUI item xs={12} md={4} xl={3}>
                                <Button
                                    fullWidth
                                    onClick={downloadReport}
                                    disableElevation
                                    variant="outlined"
                                    endIcon={<DownloadIcon fontSize="inherit" />}
                                    sx={{
                                        py: "12px",
                                        backgroundColor: "white",
                                    }}
                                >
                                    {t("converter.download")}
                                </Button>
                            </GridUI>
                            <GridUI item xs={12} md={4} xl={3}>
                                <Button
                                    fullWidth
                                    onClick={synChecks}
                                    disableElevation
                                    variant="contained"
                                    endIcon={<SyncIcon fontSize="inherit" />}
                                    sx={{
                                        py: "12px",
                                    }}
                                    disabled={
                                        selectionAcc.length === 0 ||
                                        selectionAcc.some((item) => rows[item].original_status !== 3) ||
                                        integration !== 1
                                    }
                                >
                                    {t("converter.sync")}
                                </Button>
                            </GridUI>
                        </GridUI>
                    </GridUI>
                </GridUI>
                <GridUI container justifyContent="end" alignItems="end" spacing={2} mt={0}>
                    <GridUI item sx={{ mb: { xs: 1, md: -8.3 }, zIndex: 1, mr: { xs: 0, md: 37 } }}>
                        <FiltersButtonInvoices
                            users={usersFilters}
                            company={company_id}
                            setConversions={setConsolidatedConversions}
                            setShowSkeleton={setShowSkeleton}
                            franchise={franchise_id}
                            grupal={grupal}
                            setFilters={setFilters}
                            filters={filters}
                            invoiceType="consolidated"
                            conversion_type={conversion_type}
                        />
                    </GridUI>
                    <GridUI item xs={12}>
                        <BaseTable
                            rows={rows}
                            columns={columns}
                            selectionAcc={selectionAcc}
                            setSelectionAcc={setSelectionAcc}
                            searchValue={searchValue}
                            setSearchState={setSearchState}
                            tableColumnExtensions={tableColumnExtensions}
                        />
                    </GridUI>
                </GridUI>
            </Box>
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
            <SimpleBackdrop open={openBackdrop} />
            <ChatModal
                room={room}
                setRoom={setRoom}
                open={open}
                setOpen={setOpen}
                setChat={setChat}
                chat={chat}
                type={status}
                users={users}
                franchiseName={
                    todos.allComData?.find(
                        (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                    )?.franchise_name
                }
                setCaseInfo={setCaseInfo}
                caseInfo={caseInfo}
            />
            <SimpleDialog
                open={showModalReg}
                handleClose={handleCloseRegModal}
                maxWidth="sm"
                content={
                    <Box sx={{ px: 4 }}>
                        <Typography variant="h2" color="primary" sx={{ textAlign: "center", pb: 2 }}>
                            {t("converter.registerModalTitle")}
                        </Typography>
                        <Typography sx={{ pb: 2 }}>{t("converter.registerModalContent")}</Typography>

                        <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
                            <Button onClick={handleCloseRegModal}>{t("register.back")}</Button>
                            <Button variant="contained" disableElevation onClick={handleRegister}>
                                {t("subscription.confirm")}
                            </Button>
                        </Stack>
                    </Box>
                }
            />
        </>
    );
};

export default ConsolidatedInvoicesTable;
