import {
    Grid,
    TextField,
    Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const EditContactChecks = (props) => {
    const {
        extContactName,
        contactXeroUpdated,
        setContactXeroUpdated,
    } = props;

    const [t] = useTranslation("global");

    const handleChangeXeroContact = (event) => {
        event.preventDefault();
        setContactXeroUpdated(event.target.value);
    }

    return (
        <Grid id="checksContacts" container alignItems="center" spacing={2} pl={5} pr={1}>
            <Grid item xs={12} md={12}>
                <Typography variant="h3">{t("converter.extContact")}</Typography>
                <Typography>{extContactName}</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <CustomTextField
                    fullWidth
                    label={t("converter.newContactText")}
                    name="extContactName"
                    value={contactXeroUpdated}
                    onChange={handleChangeXeroContact}
                    variant="filled"
                    inputProps={{
                        fontStyle: "normal",
                        color: "#131F3E",
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default EditContactChecks;
