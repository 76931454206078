import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import authService from "../../services/auth";
import { useSearchParams } from "react-router-dom";

/* Componentes */
import {
    Typography,
    Grid,
    Button,
    Paper,
    InputAdornment,
    Alert,
    Link,
    Checkbox,
    FormControlLabel,
    Stack,
    Box,
} from "@mui/material";
import CustTextField from "../ui/CustTextField";
import BaseCard from "../ui/BaseCard";
import LightTooltip from "../ui/LightTooltip";

/* Estilos */
import classes from "./TryKiiper.module.css";

/** MUI icons */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import userService from "../../services/user";

const TryKiiperCard = (props) => {
    const { setShow, setTexto, setAlertType, handleShowTryCard, setIsLoading, userEmail, tryKiiperWeb, language } =
        props;

    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    const [searchParams] = useSearchParams();
    const [isError, setIsError] = useState(false);
    const [emailVal, setEmailVal] = useState("");
    const [name, setName] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [verified, setVerified] = useState(false);
    const [gotTrial, setGotTrial] = useState(false);
    const [disabledButton, setDisabledButton] = useState(true);
    const [checkTandC, setCheckTandC] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [counter, setCounter] = useState(120);
    const [userId, setUserId] = useState(null);
    const [termsLink, setTermsLink] = useState(
        sessionStorage.getItem("lng") === "es" || language === "es" || i18n.language === "es"
            ? "https://kiiper.app/es/terms-and-conditions"
            : "https://kiiper.app/terms-and-conditions/"
    );

    const createUserTrial = async () => {
        if (emailVal.trim() && name.trim()) {
            if (/.+@.+\..+/.test(emailVal)) {
                setIsLoading(true);

                if (userId) {
                    //Actualizar
                    let params = {
                        _id: userId,
                        user_email: emailVal,
                        user_name: name,
                        language: language
                            ? language
                            : sessionStorage.getItem("lng")
                            ? sessionStorage.getItem("lng")
                            : "en",
                    };

                    await authService
                        .updateUnverifiedUser(params)
                        .then(() => {
                            setGotTrial(true);
                        })
                        .catch((err) => {
                            setIsError(true);
                            setAlertType("error");
                            setShow(true);
                        });
                } else {
                    const params = {
                        user_email: emailVal,
                        user_name: name,
                        language: language
                            ? language
                            : sessionStorage.getItem("lng")
                            ? sessionStorage.getItem("lng")
                            : "en",
                    };

                    await authService
                        .createNewUser(params)
                        .then(() => {
                            setGotTrial(true);
                        })
                        .catch((err) => console.log(err));
                }

                if (tryKiiperWeb) {
                    searchParams.delete("try-kiiper");
                    searchParams.delete("email");
                }

                setIsError(false);
                setIsLoading(false);
            } else {
                setIsError(true);
            }
        } else {
            setIsError(true);
        }
    };

    const handleChangeEmail = async (event) => {
        setEmailVal(event.target.value);
        verifyEmail(event.target.value);
    };

    const verifyEmail = async (email) => {
        if (!userId && email.trim() && /.+@.+\..+/.test(email)) {
            await authService
                .verifyExistingUser(email.trim())
                .then(() => {
                    setVerified(false);
                    setIsValidEmail(true);
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 404:
                            setVerified(true);
                            setIsValidEmail(false);
                            setIsError(false);
                            break;
                        default:
                            break;
                    }
                });
        }
    };

    const handleChangeName = (event) => {
        setName(event.target.value);
    };

    const handleChangeCheck = (event) => {
        setCheckTandC(event.target.checked);
    };

    const handleChangeTrialEmail = async () => {
        await userService
            .getUserData(emailVal)
            .then(async (response) => {
                setUserId(response._id);
            })
            .catch((error) => {
                console.log(error.response);
            });

        setGotTrial(false);
    };

    const handleGoLogin = async () => {
        setIsError(false);
        setIsValidEmail(false);
        setEmailVal("");
        setName("");
        handleShowTryCard();
        setUserId(null);
    };

    useEffect(() => {
        if (/.+@.+\..+/.test(emailVal) && name.trim() && !isValidEmail && verified && checkTandC) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [emailVal, name, isValidEmail, verified, checkTandC]);

    const handleResend = async () => {
        const params = {
            user_email: emailVal,
            user_name: name,
            language: sessionStorage.getItem("lng"),
        };

        setDisabled(true);

        await authService
            .resend(params)
            .then(() => {
                setGotTrial(true);
                setTexto(t("login.verifyEmail"));
                setAlertType("success");
                setShow(true);

                if (tryKiiperWeb) {
                    searchParams.delete("try-kiiper");
                    searchParams.delete("email");
                }
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        if (disabled && counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        }
        if (disabled && counter === 0) {
            setDisabled(false);
            setCounter(120);
        }
    }, [counter, disabled]);

    useEffect(() => {
        if (userEmail) {
            const verifyEmailKiiper = async (email) => {
                if (!userId && email.trim() && /.+@.+\..+/.test(email)) {
                    await authService
                        .verifyExistingUser(email)
                        .then(() => {
                            setVerified(false);
                            setIsValidEmail(true);
                        })
                        .catch((error) => {
                            switch (error.response.status) {
                                case 404:
                                    setVerified(true);
                                    setIsValidEmail(false);
                                    setIsError(false);
                                    break;
                                default:
                                    break;
                            }
                        });
                }
            };
            setEmailVal(userEmail);
            verifyEmailKiiper(userEmail);
        }
    }, [userEmail, userId]);

    const handleTooltip = () => {
        if (emailVal.length > 26) {
            return (
                <LightTooltip title={emailVal}>
                    <span>{emailVal}</span>
                </LightTooltip>
            );
        } else {
            return emailVal;
        }
    };

    useEffect(() => {
        if (i18n.language) {
            setTermsLink(
                i18n.language === "es"
                    ? "https://kiiper.app/es/terms-and-conditions"
                    : "https://kiiper.app/terms-and-conditions/"
            );
        }
    }, [i18n.language]);

    return (
        <BaseCard>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                {!gotTrial ? (
                    <>
                        <Grid item xs={12}>
                            <Typography className={classes.title} gutterBottom>
                                {t("login.readyKiiper")}
                            </Typography>
                            <Typography>{t("login.tryKiiper")}</Typography>
                            <ul className={classes.checkList}>
                                <li>{t("register.listConv")}</li>
                                <li>{t("register.listPotent")}</li>
                                <li>{t("register.listNotReq")}</li>
                            </ul>
                        </Grid>
                        <Grid item xs={12}>
                            <CustTextField
                                id="outlined-name"
                                label={t("login.firstName")}
                                value={name}
                                onChange={handleChangeName}
                                error={isError && !name}
                                shrink={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustTextField
                                id="outlined-email"
                                label={t("login.emailCorp")}
                                error={isError || isValidEmail}
                                value={emailVal}
                                onChange={handleChangeEmail}
                                InputProps={{
                                    endAdornment:
                                        (((emailVal.trim() && !/.+@.+\..+/.test(emailVal)) ||
                                            (emailVal.trim() && isValidEmail)) && (
                                            <InputAdornment position="end">
                                                <CancelIcon color="error" />
                                            </InputAdornment>
                                        )) ||
                                        (emailVal.trim() && /.+@.+\..+/.test(emailVal) && !isValidEmail && verified && (
                                            <InputAdornment position="end">
                                                <CheckCircleIcon sx={{ color: "#00B147" }} />
                                            </InputAdornment>
                                        )),
                                }}
                                helperText={
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        {emailVal.trim() && isValidEmail && (
                                            <Typography onClick={handleGoLogin} size="small">
                                                {t("login.dontExistLog")}
                                            </Typography>
                                        )}{" "}
                                        {emailVal.trim() && isValidEmail && (
                                            <Typography
                                                onClick={handleGoLogin}
                                                sx={{ ml: 0.5, color: "#4A22D4", cursor: "pointer", fontWeight: 600 }}
                                                size="small"
                                            >
                                                {t("login.dontExistLog1")}
                                            </Typography>
                                        )}
                                    </Box>
                                }
                                shrink={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.message}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={checkTandC} onChange={handleChangeCheck} size="small" />
                                    }
                                    label={
                                        <Typography>
                                            {t("login.tryText")}{" "}
                                            <Link
                                                sx={{ fontWeight: 600, textDecoration: "none" }}
                                                href={termsLink}
                                                target="_blank"
                                            >
                                                {t("login.tryText3")}
                                            </Link>{` ${t("register.andThe")} `}<Link
                                                sx={{ fontWeight: 600, textDecoration: "none" }}
                                                href={termsLink}
                                                target="_blank"
                                            >
                                                {t("register.privacyPol")}
                                            </Link>
                                        </Typography>
                                    }
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" fullWidth onClick={createUserTrial} disabled={disabledButton}>
                                {t("login.testKiiper")}
                            </Button>
                        </Grid>
                        {!isValidEmail && (
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                    <Typography className={classes.haveAcc}>{t("login.haveAcc")}</Typography>
                                    <Button onClick={handleGoLogin} className={classes.login} size="small">
                                        {t("login.loginText2")}
                                    </Button>
                                </Stack>
                            </Grid>
                        )}
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Typography className={classes.titleVerify} gutterBottom>
                                {t("login.verifyMail")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Alert severity="success">
                                {t("login.verifyTo")}
                                <b
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: "100%",
                                        display: "inline-block",
                                        cursor: "default",
                                    }}
                                >
                                    {handleTooltip()}
                                </b>
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.messageVerify}>
                                <Typography component="span">{t("login.verifyText")}</Typography>
                                <Typography fontWeight={600} component="span">
                                    {" " + t("login.verifyText2")}
                                </Typography>
                                <Typography component="span">{" " + t("login.verifyText3")}</Typography>
                                <Typography fontWeight={600} component="span">
                                    {" " + t("login.verifyText4")}
                                </Typography>{" "}
                                <br />
                                <Link
                                    sx={{ fontWeight: 600, p: 0, textDecoration: "none" }}
                                    component={Button}
                                    onClick={handleResend}
                                    disabled={disabled}
                                >
                                    {t("login.resend2")}{" "}
                                    {disabled ? `${t("login.on")} ${counter} ${t("login.sec")}` : ""}
                                </Link>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                <Button onClick={handleChangeTrialEmail} className={classes.login}>
                                    {t("login.wrongEmail")}
                                </Button>
                            </Stack>
                        </Grid>
                    </>
                )}
            </Grid>
        </BaseCard>
    );
};

export default TryKiiperCard;
