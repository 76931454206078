import axios from "axios";

/* Script que contiene las llamadas a la API */
const accounts = {
    // Petición para listar las cuentas de una empresa.
    getAccounts: (params, options = {}) => {
        const request = axios.get("/getAccounts", { params: params, ...options });

        return request.then((response) => response.data);
    },
    getBanks: (params) => {
        const request = axios.get(`/getBanks`, {
            params: params,
        });

        return request.then((response) => response.data);
    },
    getBanksFilter: (params) => {
        const request = axios.get(`/getBanksFilter`, {
            params: params,
        });

        return request.then((response) => response.data);
    },
    getCurrencies: (params) => {
        const request = axios.get(`/getCurrencies`, {
            params: params,
        });

        return request.then((response) => response.data);
    },
    getCountries: (params) => {
        const request = axios.get(`/getCountries`, {
            params: params,
        });

        return request.then((response) => response.data);
    },
    disableAccounts: (params) => {
        const request = axios.post("/disableAccounts", params);

        return request.then((response) => response);
    },
    getAccountDetails: (accountID) => {
        const request = axios.get("/getAccountDetails", {
            params: {
                account_id: accountID,
            },
        });

        return request.then((response) => response.data);
    },
    // Peticion para actualizar una cuenta
    updateAccount: (params) => {
        const request = axios.post("/updateAccount", params);

        return request.then((response) => response);
    },
    // Peticion para crear una cuenta
    addAccount: (params) => {
        const request = axios.post("/addAccount", params);

        return request.then((response) => response);
    },
    // Petición para archivar una cuenta.
    archiveAccount: (params) => {
        const request = axios.post("/archiveAccount", params);

        return request.then((response) => response);
    },
    // Petición para desarchivar una cuenta.
    restoreAccount: (params) => {
        const request = axios.post("/restoreAccount", params);

        return request.then((response) => response);
    },
    // Petición para obtener cuentas bancarias de clientes en Xero y QB
    updateSystemAccounts: (params) => {
        const request = axios.post("/updateSystemAccounts", params);

        return request.then((response) => response);
    },
    // Petición para combinar cuentas bancarias
    combineAccounts: (params) => {
        const request = axios.post("/combineAccounts", params);

        return request.then((response) => response);
    },
    reactivateAccounts: (params) => {
        const request = axios.put("/reactivateAccounts", params);

        return request.then((response) => response);
    },
    // Petición para listar las cuentas de las empresas de una franquicia/firma.
    getAccountsByFranchise: (params) => {
        const request = axios.get("/getAccountsByFranchise", {
            params: params,
        });

        return request.then((response) => response.data);
    },
    // Peticion para descargar reporte general
    generateGeneralReport: () => {
        const request = axios.get("/generateGeneralReport", { responseType: "blob" });

        return request.then((response) => response);
    },
    // Set plaid token
    setAccessToken: (params) => {
        const request = axios.post("/set_access_token", params);

        return request.then((response) => response);
    },
    // Set plaid token
    createLinkToken: (params) => {
        const request = axios.post("/create_link_token", params);

        return request.then((response) => response.data);
    },
    // Petición para listar las cuentas bancarias de xero
    getReconciliationBanks: (params) => {
        const request = axios.get("/getReconciliationBanks", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Petición para listar detalles de cuentas bancarias
    getReconciliationBankDetails: (params) => {
        const request = axios.get("/getReconciliationBankDetails", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Petición para listar las transacciones del sistema
    getTransactionsSystem: (params) => {
        const request = axios.get("/getTransactionsSystem", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Petición para listar los contactos de Xero
    getContacts: (params) => {
        const request = axios.get("/getContacts", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Petición para listar los cuentas de Xero
    getChartOfAccounts: (params) => {
        const request = axios.get("/getChartOfAccounts", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Petición para conciliar cuentas de Xero
    reconcile: (params) => {
        const request = axios.post("/reconcile", params);

        return request.then((response) => response.data);
    },
};

export default accounts;
