import axios from "axios";

/* Script que contiene las llamadas a la API */
const cases = {
    // Peticion para obtener los casos
    getCases: (params) => {
        const request = axios.get("/getCases", {
            params: params,
        });

        return request.then((response) => response.data);
    },
    markCaseAsResolved: (params) => {
        const request = axios.post("/markCaseAsResolved", params);

        return request.then((response) => response);
    },
    // Peticion para obtener los casos internos
    getAdminCases: (params) => {
        const request = axios.get("/getAdminCases", {
            params: params,
        });

        return request.then((response) => response.data);
    },
    // Peticion para asignar un caso
    assignAdminCase: (params) => {
        const request = axios.put("/assignAdminCase", params);

        return request.then((response) => response);
    },
    // Peticion para reabrir un caso
    reopenAdminCase: (params) => {
        const request = axios.put("/reopenAdminCase", params);

        return request.then((response) => response);
    },

    getChatFileS3: (params) => {
        const request = axios.get("/getChatFileS3", { params: params, responseType: "blob" });

        return request.then((response) => response);
    },

    getMessagesCases: (params) => {
        const request = axios.get("/getMessagesCases", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Peticion para eliminar un caso
    deleteCase: (params) => {
        const request = axios.delete("/removeCase", {
            params: params,
        });

        return request.then((response) => response);
    },

    // Peticion para obtener informacion de un caso
    getCaseInfo: (params) => {
        const request = axios.get("/getCaseInfo", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Peticion para crear un nuevo caso
    createNewCase: (params) => {
        const request = axios.post("/createNewCase", params);

        return request.then((response) => response.data);
    },

    // Peticion para obtener informacion de todos los clientes 
    getClients: (params) => {
        const request = axios.get("/getClients", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Peticion para retornar un caso a desarrollo en el convertidor de pruebas
    returnAdminCase: (params) => {
        const request = axios.put("/returnAdminCase", params);

        return request.then((response) => response.data);
    },

    // Peticion para aprobar un caso del convertidor de pruebas
    approveAdminCase: (params) => {
        const request = axios.post("/approveAdminCase", params);

        return request.then((response) => response.data);
    },

    // Peticion para guardar un mensaje del bot de convertidor de pruebas
    botMessage: (params) => {
        const request = axios.post("/botMessage", params);

        return request.then((response) => response.data);
    },

    verifyCases: (params) => {
        const request = axios.get("/verifyCases", {
            params: params,
        });

        return request.then((response) => response.data);
    },
};

export default cases;
