import { Box, IconButton } from "@mui/material";

import { useTranslation } from "react-i18next";
import LightTooltip from "../ui/LightTooltip";
import DownloadIcon from "@mui/icons-material/Download";
import conversionService from "../../services/conversion";

const BaseActionsPreviewFile = (props) => {
    const { basePreviewData } = props;
    const [t] = useTranslation("global");

    const downloadFile = async () => {
        let params = {
            type: basePreviewData.type,
        };
        if (basePreviewData.id) {
            params.conversion_id = basePreviewData.id
        } else {
            params.file_s3 = basePreviewData.file_s3
            params.file = basePreviewData.file
        }
        
        await conversionService
            .getFileUrlS3(params)
            .then((response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Box>
            <LightTooltip title={t("converter.download")}>
                <IconButton
                    edge="end"
                    aria-label="archive"
                    onClick={() => downloadFile()}
                >
                    <DownloadIcon color="primary" />
                </IconButton>
            </LightTooltip>
        </Box>
    );
};

export default BaseActionsPreviewFile;
