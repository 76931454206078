/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI icons */
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/es";
import dashboardService from "../../services/dashboard";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FiltersPopover from "../ui/FiltersPopover";

/** MUI imports */
import {
    Typography,
    Grid,
    FormControl,
    InputLabel,
    Select,
    FilledInput,
    MenuItem,
    TextField,
    Checkbox,
} from "@mui/material";

const defaultFilter = {
    filtersArray: [
        {
            type: "",
            value: "",
            since: "",
            until: "",
            multiple: [],
        },
    ],
};

const FiltersButton = (props) => {
    const { banks, setShowSkeleton, franchise, company, setDashboard, grupal, companiesFilters, showSkeleton } = props;
    const [t] = useTranslation("global");

    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useState(defaultFilter);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const attributes =
        (franchise && !company) || grupal
            ? [
                  { id: 1, name: t("dashboard.company"), query: "company_id" },
                  { id: 2, name: t("dashboard.bank"), query: "bank_id" },
                  { id: 3, name: t("dashboard.lastTrans"), query: "transaction" },
                  { id: 4, name: t("dashboard.lastPeriod"), query: "period" },
                  { id: 5, name: t("dashboard.latestBalance"), query: "balance" },
              ]
            : [
                  { id: 2, name: t("dashboard.bank"), query: "bank_id" },
                  { id: 3, name: t("dashboard.lastTrans"), query: "transaction" },
                  { id: 4, name: t("dashboard.lastPeriod"), query: "period" },
                  { id: 5, name: t("dashboard.latestBalance"), query: "balance" },
              ];

    const limit = (franchise && !company) || grupal ? 5 : 4;

    const addFilter = () => {
        let array = [...filters.filtersArray];
        if (array.length < limit) {
            setFilters({
                ...filters,
                filtersArray: [
                    ...filters.filtersArray,
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                        multiple: [],
                    },
                ],
            });
        }
    };

    const removeFilter = async (index) => {
        let array = [...filters.filtersArray];

        if (index !== -1) {
            array.splice(index, 1);
            setFilters({
                ...filters,
                filtersArray: array,
            });

            setShowSkeleton(true);
            let objectFinal = {};

            array.forEach((item) => {
                const query = attributes.find((item2) => item2.id === item.type).query;
                if (item.value && item.type) {
                    objectFinal[query] = item.value;
                }
                if (item.type && item.since) {
                    objectFinal[query + "_since"] = query !== "balance" ? item.since.format("YYYY-MM-DD") : item.since;
                }
                if (item.type && item.until) {
                    objectFinal[query + "_until"] = query !== "balance" ? item.until.format("YYYY-MM-DD") : item.until;
                }
                if (item.multiple && item.multiple.length > 0) {
                    objectFinal[query] = item.multiple;
                }
            });

            await dashboardService
                .getDashboard({
                    company_id: company ?? undefined,
                    franchise_id: franchise ?? undefined,
                    grouped: grupal ?? false,
                    ...objectFinal,
                })
                .then((response) => {
                    if (response.length > 0) {
                        setDashboard(response);
                    } else {
                        setDashboard([]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            setShowSkeleton(false);
        }
    };

    const removeFirstFilter = async () => {
        setShowSkeleton(true);
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                    since: "",
                    until: "",
                    multiple: [],
                },
            ],
        });

        await dashboardService
            .getDashboard({
                company_id: company ?? undefined,
                franchise_id: franchise ?? undefined,
                grouped: grupal ?? false,
            })
            .then((response) => {
                if (response.length > 0) {
                    setDashboard(response);
                } else {
                    setDashboard([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        setShowSkeleton(false);
    };

    const handleChangeType = (event, index) => {
        let aux = [...filters.filtersArray];
        aux[index].type = event.target.value;
        aux[index].value = "";
        aux[index].since = "";
        aux[index].until = "";
        aux[index].multiple = [];

        setFilters({
            ...filters,
            filtersArray: aux,
        });
    };

    const handleChangeFilter = async (event, index, filterType) => {
        const aux = [...filters.filtersArray];

        if (filterType === 1 || filterType === 2) {
            aux[index].value = "";
            aux[index].since = "";
            aux[index].until = "";
            aux[index].multiple = event.target.value;
        } else {
            aux[index].value = event.target.value;
            aux[index].since = "";
            aux[index].until = "";
            aux[index].multiple = [];
        }

        setShowSkeleton(true);

        let objectFinal = {};

        aux.forEach((item) => {
            const query = attributes.find((item2) => item2.id === item.type).query;
            if (item.value && item.type) {
                objectFinal[query] = item.value;
            }
            if (item.type && item.since) {
                objectFinal[query + "_since"] = query !== "balance" ? item.since.format("YYYY-MM-DD") : item.since;
            }
            if (item.type && item.until) {
                objectFinal[query + "_until"] = query !== "balance" ? item.until.format("YYYY-MM-DD") : item.until;
            }
            if (item.multiple && item.multiple.length > 0) {
                let auxArr = [];
                const finalIDS = item.multiple.map((item) => {
                    if (item.includes(",")) {
                        auxArr = auxArr.concat(item.split(","));
                    }
                    return item;
                }).filter(item => !item.includes(","));
                
                objectFinal[query] = finalIDS.concat(auxArr);
            }
        });

        setFilters({
            ...filters,
            filtersArray: aux,
        });

        await dashboardService
            .getDashboard({
                company_id: company ?? undefined,
                franchise_id: franchise ?? undefined,
                grouped: grupal ?? false,
                ...objectFinal,
            })
            .then((response) => {
                if (response.length > 0) {
                    setDashboard(response);
                } else {
                    setDashboard([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        setShowSkeleton(false);
    };

    const handleChangeFilterRange = async (event, index, filterType, range) => {
        let aux = [...filters.filtersArray];
        switch (filterType) {
            case 5: // Saldo final
                aux[index][range] = event.target.value;
                break;
            case 3: // Ultimo movimiento
            case 4: // Ultima conver.
            case 6: // Periodo
                aux[index][range] = event;
                break;
            default:
                break;
        }

        aux[index].value = "";

        setShowSkeleton(true);

        let objectFinal = {};

        aux.forEach((item) => {
            const query = attributes.find((item2) => item2.id === item.type).query;
            if (item.value && item.type) {
                objectFinal[query] = item.value;
            }
            if (item.type && item.since) {
                objectFinal[query + "_since"] = query !== "balance" ? item.since.format("YYYY-MM-DD") : item.since;
            }
            if (item.type && item.until) {
                objectFinal[query + "_until"] = query !== "balance" ? item.until.format("YYYY-MM-DD") : item.until;
            }
            if (item.multiple && item.multiple.length > 0) {
                objectFinal[query] = item.multiple;
            }
        });

        setFilters({
            ...filters,
            filtersArray: aux,
        });

        await dashboardService
            .getDashboard({
                company_id: company ?? undefined,
                franchise_id: franchise ?? undefined,
                grouped: grupal ?? false,
                ...objectFinal,
            })
            .then((response) => {
                if (response.length > 0) {
                    setDashboard(response);
                } else {
                    setDashboard([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        setShowSkeleton(false);
    };

    const cleanFilters = async (e) => {
        if (e.target.classList.contains("MuiAvatar-img")) {
            setShowSkeleton(true);
            setFilters({
                filtersArray: [
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                        multiple: [],
                    },
                ],
            });

            await dashboardService
                .getDashboard({
                    company_id: company ?? undefined,
                    franchise_id: franchise ?? undefined,
                    grouped: grupal ?? false,
                })
                .then((response) => {
                    if (response.length > 0) {
                        setDashboard(response);
                    } else {
                        setDashboard([]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            setShowSkeleton(false);
        }
    };

    useEffect(() => {
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                    since: "",
                    until: "",
                    multiple: [],
                },
            ],
        });
    }, [company, franchise, grupal]);

    return (
        <FiltersPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            cleanFilters={cleanFilters}
            handleClick={handleClick}
            length={
                filters.filtersArray.filter(
                    (item) => item.type && (item.value || item.since || item.until || item.multiple.length > 0)
                ).length
            }
            addFilter={addFilter}
            disabled={filters.filtersArray.length === limit}
            disabledButton={showSkeleton}
        >
            {filters.filtersArray.map((value, index) => (
                <Grid container spacing={2} alignItems="center" key={"dashComponent_" + index} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel
                                        id="clientStatus-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#131F3E",
                                        }}
                                    >
                                        {t("filters.attribute")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        IconComponent={(props) => {
                                            if (props.className.includes("MuiSelect-iconOpen")) {
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(270deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform: "rotate(90deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }}
                                        value={value.type}
                                        displayEmpty
                                        onChange={(e) => handleChangeType(e, index)}
                                        className="ddlGeneral"
                                        input={
                                            <FilledInput
                                                label={t("filters.attribute")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#131F3E",
                                                }}
                                            />
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        {value.type !== ""
                                            ? attributes
                                                  .filter(
                                                      (item) =>
                                                          !filters.filtersArray
                                                              .map((item) => item.type)
                                                              .includes(item.id)
                                                  )
                                                  .concat(attributes.filter((item) => item.id === value.type))
                                                  .map((item) => (
                                                      <MenuItem value={item.id} key={"attr_" + item.id}>
                                                          <Typography
                                                              variant="body1"
                                                              component="span"
                                                              align="center"
                                                              color="textPrimary"
                                                              sx={{
                                                                  fontStyle: "normal",
                                                              }}
                                                          >
                                                              {item.name}
                                                          </Typography>
                                                      </MenuItem>
                                                  ))
                                            : attributes
                                                  .filter(
                                                      (item) =>
                                                          !filters.filtersArray
                                                              .map((item) => item.type)
                                                              .includes(item.id)
                                                  )
                                                  .map((item) => (
                                                      <MenuItem value={item.id} key={"attr_" + item.id}>
                                                          <Typography
                                                              variant="body1"
                                                              component="span"
                                                              align="center"
                                                              color="textPrimary"
                                                              sx={{
                                                                  fontStyle: "normal",
                                                              }}
                                                          >
                                                              {item.name}
                                                          </Typography>
                                                      </MenuItem>
                                                  ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {value.type === "" && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled" disabled>
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.type}
                                            displayEmpty
                                            onChange={(e) => handleChangeType(e, index)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        ></Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 1 && ((franchise && !company) || grupal) && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.multiple}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                            multiple
                                            renderValue={(selected) => {
                                                return selected
                                                    .map((item) => {
                                                        return companiesFilters.filter((item2) => item2.id === item)[0]
                                                            .name;
                                                    })
                                                    .join(", ");
                                            }}
                                        >
                                            {companiesFilters.map((item) => (
                                                <MenuItem value={item.id} key={"company_" + item.id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        <Checkbox checked={value.multiple.includes(item.id)} />
                                                        {item.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 2 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.multiple}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                            multiple
                                            renderValue={(selected) => {
                                                return selected
                                                    .map((item) => {
                                                        return banks.find((item2) => item2._id === item).bank_name;
                                                    })
                                                    .join(", ");
                                            }}
                                        >
                                            {banks.map((item) => (
                                                <MenuItem value={item._id} key={"banks_" + item._id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        <Checkbox checked={value.multiple.includes(item._id)} />
                                                        {item.bank_name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 5 && (
                                <>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label={
                                                <Typography
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                >
                                                    {t("filters.value") + " " + t("filters.since").toLowerCase()}
                                                </Typography>
                                            }
                                            value={value.since}
                                            onChange={(e) => handleChangeFilterRange(e, index, value.type, "since")}
                                            variant="filled"
                                            type="number"
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            label={
                                                <Typography
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                >
                                                    {t("filters.value") + " " + t("filters.until").toLowerCase()}
                                                </Typography>
                                            }
                                            value={value.until}
                                            onChange={(e) => handleChangeFilterRange(e, index, value.type, "until")}
                                            variant="filled"
                                            type="number"
                                        />
                                    </Grid>
                                </>
                            )}
                            {(value.type === 3 || value.type === 4) && (
                                <>
                                    <Grid item xs={6} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <DatePicker
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    >
                                                        {t("filters.since")}
                                                    </Typography>
                                                }
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        style: { width: "100%" },
                                                        variant: "filled",
                                                        readOnly: true,
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                maxDate={
                                                    filters.filtersArray[index]?.until
                                                        ? filters.filtersArray[index].until
                                                        : null
                                                }
                                                value={value.since ? value.since : null}
                                                onChange={(e) => handleChangeFilterRange(e, index, value.type, "since")}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <DatePicker
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    >
                                                        {t("filters.until")}
                                                    </Typography>
                                                }
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        style: { width: "100%" },
                                                        variant: "filled",
                                                        readOnly: true,
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                minDate={
                                                    filters.filtersArray[index]?.since
                                                        ? filters.filtersArray[index].since
                                                        : null
                                                }
                                                value={value.until ? value.until : null}
                                                onChange={(e) => handleChangeFilterRange(e, index, value.type, "until")}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={1} display="flex" justifyContent="center">
                        {filters.filtersArray.length > 1 && (
                            <DeleteIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() => removeFilter(index)}
                            />
                        )}
                        {filters.filtersArray.length === 1 &&
                            (value.value !== "" ||
                                value.since !== "" ||
                                value.until !== "" ||
                                value.multiple.length > 0) && (
                                <DeleteIcon
                                    color="primary"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => removeFirstFilter()}
                                />
                            )}
                    </Grid>
                </Grid>
            ))}
        </FiltersPopover>
    );
};

export default FiltersButton;
