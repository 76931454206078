import { Grid, Button } from "@mui/material";

import { useTranslation } from "react-i18next";

const DownloadFormatButtons = (props) => {
    const { handleCloseDialog, downloadQBO, accountNum, accountType, bankInst, downloadFormat } = props;

    const [t] = useTranslation("global");

    return (
        <Grid container justifyContent="space-evenly">
            <Grid item>
                <Button
                    size="large"
                    disableElevation
                    variant="outlined"
                    onClick={handleCloseDialog}
                    color="primary"
                    sx={{
                        borderRadius: 3,
                        fontSize: 15,
                        px: 4,
                        py: 1.3
                    }}
                >
                    {t("team.cancel")}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    size="large"
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={downloadQBO}
                    sx={{
                        borderRadius: 3,
                        fontSize: 15,
                        px: 4,
                        py: 1.3
                    }}
                    disabled={((downloadFormat === 15 || downloadFormat === 16) && (!accountNum.trim() || accountType === "" || !bankInst)) || !downloadFormat}
                >
                    {t("payment.download")}
                </Button>
            </Grid>
        </Grid>
    );
};

export default DownloadFormatButtons;
