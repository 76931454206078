import React from "react";
import SimpleDialog from "../ui/SimpleDialog";
import { Grid, Typography } from "@mui/material";
import Loading from "../../assets/loading.png";
import { useTranslation } from "react-i18next";

const BackgroundDialog = (props) => {
    const { open, handleClose, convNum, checks } = props;

    const [t] = useTranslation("global");

    const actionsContent = (
        <Grid container spacing={2} alignItems="center" justifyContent="space-evenly">
            <Grid item>
                <img src={Loading} alt="loading" />
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography variant="h1">{`${t("converter.yourConv")} #${convNum} ${t(
                    "converter.inProgress"
                )}`}</Typography>
                <Typography pt={1}>{checks ? t("converter.backTextChecks") : t("converter.backText")}</Typography>
            </Grid>
        </Grid>
    );

    return (
        <>
            <SimpleDialog open={open} handleClose={handleClose} maxWidth="sm" content={actionsContent} />
        </>
    );
};

export default BackgroundDialog;
