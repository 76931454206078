import {
    Button,
    Grid,
    Stack,
} from "@mui/material";

import { useTranslation } from "react-i18next";

const ReturnInvoiceButtons = (props) => {
    const {emailReturn, errorReturn, returnInvoice, handleCloseDialog} = props;

    const [t] = useTranslation("global");

    return (
        <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }} alignItems="center">
            <Button
                size="large"
                disableElevation
                onClick={handleCloseDialog}
                sx={{
                    borderRadius: 3,
                    fontSize: 15,
                    px: 3,
                    py: 1,
                    whiteSpace: "nowrap"
                }}
            >
                    {t("miscellaneous.goBack")}
            </Button>
            <Grid container justifyContent="end" spacing={2}>
                <Grid item>
                    <Button
                        size="large"
                        disableElevation
                        variant="outlined"
                        onClick={() => returnInvoice(1)}
                        color="primary"
                        sx={{
                            borderRadius: 3,
                            fontSize: 15,
                            px: 3,
                            py: 1
                        }}
                    >
                        {t("converter.returnWsending")}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        size="large"
                        disableElevation
                        variant="contained"
                        onClick={() => returnInvoice(2)}
                        color="primary"
                        sx={{
                            borderRadius: 3,
                            fontSize: 15,
                            px: 3,
                            py: 1
                        }}
                        disabled={emailReturn === "" || errorReturn === "" || (emailReturn !== "" && !/.+@.+\..+/.test(emailReturn))}
                    >
                        {t("converter.send")}
                    </Button>
                </Grid>
            </Grid>
        </Stack>
    );
};

export default ReturnInvoiceButtons;
