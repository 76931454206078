const businessActivity = [
	{
		id: 1,
		name: "Servicios financieros y contables",
        translation: "businessAct1"
	},
	{
		id: 2,
		name: "Agricultura y ganadería",
        translation: "businessAct2"
	},
	{
		id: 3,
        name: "Bienes de consumo",
        translation: "businessAct3"
	},
	{
		id: 4,
        name: "Comercio electrónico",
        translation: "businessAct4"
	},
	{
		id: 5,
        name: "Comercio y establecimientos",
        translation: "businessAct5"
	},
    {
		id: 6,
        name: "Construcción",
        translation: "businessAct6"
    },
	{
		id: 7,
        name: "Deporte y ocio",
        translation: "businessAct7"
	},
	{
		id: 8,
        name: "Energía y medio ambiente",
        translation: "businessAct8"
	},
	{
		id: 9,
        name: "Seguros y bienes inmuebles",
        translation: "businessAct9"
	},
	{
		id: 10,
        name: "Internet",
        translation: "businessAct10"
	},
	{
		id: 11,
        name: "Logística y transporte",
        translation: "businessAct11"
	},
	{
		id: 12,
        name: "Medios de comunicación y marketing",
        translation: "businessAct12"
	},
    {
        id: 13,
        name: "Metalurgia y electrónica",
        translation: "businessAct13"
    },
	{
		id: 14,
		name: "Productos químicos y materias primas",
        translation: "businessAct14"
	},
	{
		id: 15,
		name: "Salud e industria farmacéutica",
        translation: "businessAct15"
	},
	{
		id: 16,
		name: "Sociedad",
        translation: "businessAct16"
	},
	{
		id: 17,
		name: "Tecnología y telecomunicaciones",
        translation: "businessAct17"
	},
	{
		id: 18,
		name: "Turismo y hostelería",
        translation: "businessAct18"
	},
	{
		id: 19,
		name: "Otro",
        translation: "businessAct19"
	}
]

export default businessActivity;
