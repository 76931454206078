import { Box, Button, Card, CardContent, CardHeader, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";
import ConversionPack from "../ConversionPack";
import ShoppingSummary from "./ShoppingSummary";
import ShoppingItems from "./ShoppingItems";
import subscriptionService from "../../../services/subscription";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";

const ShoppingCart = (props) => {
    const {
        franchise_id,
        company_id,
        cartItems,
        setCartItems,
        setShoppingCartOpen,
        setIsLoading,
        setActivePlan,
        activePlan,
        customCredits,
        setCustomCredits,
        planCredits,
        plansInfo,
        currentPlan,
        setPlanCredits,
        creditsInfo,
        setPlansDraw,
        currentPlanInfo,
        setCurrentPlanInfo,
        setTexto,
        setAlertType,
        setShow,
        payments,
        setReload,
        setValue,
        reloadData,
        setSuccessReactivate,
        mainSkeleton,
        franchiseDetails
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    
    const clearAll = async () => {
        setIsLoading(true);

        const params = {
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
            cart_lines: [],
        };

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        if (currentPlan !== null && currentPlan !== "") {
            setActivePlan(currentPlan);
            if (currentPlan === 1) {
                setPlanCredits(creditsInfo.slice(0, 2));
            } else if (currentPlan === 2) {
                setPlanCredits(creditsInfo.slice(1, 3));
            } else if (currentPlan === 3) {
                setPlanCredits(creditsInfo.slice(2, 4));
            } else if (currentPlan === 4) {
                setPlanCredits(creditsInfo.slice(4, 6));
            } else if (currentPlan === 5) {
                setPlanCredits(creditsInfo.slice(5, 7));
            } else {
                setPlanCredits(creditsInfo.slice(7, 9));
            }

            if (currentPlan === 1 || currentPlan === 2 || currentPlan === 3) {
                setPlansDraw(plansInfo.slice(0, 3));
            } else {
                setPlansDraw(plansInfo.slice(3, 6));
            }
        } else {
            setActivePlan(null);
        }

        setIsLoading(false);

        setCartItems([]);
        setShoppingCartOpen(false);
    };

    const deleteItem = async (val) => {
        if (val.subscription_plan) {
            //Se deben borrar los paquetes de créditos asociados al plan
            clearAll();
        } else {
            const newArr = cartItems.filter((item) => item.line_qty !== val.line_qty);

            setIsLoading(true);

            const params = {
                franchise_id: todos.franchiseID,
                company_id: todos.companyID,
                cart_lines: newArr,
            };

            await subscriptionService
                .updateCart(params)
                .then()
                .catch((error) => {
                    console.log(error);
                });

            setIsLoading(false);

            setCartItems(newArr);

            if (newArr.length === 0) {
                setShoppingCartOpen(false);
            }
        }
    };

    const goBackPlan = () => {
        setValue(0);
        setShoppingCartOpen(false);
    };

    return (
        <Box paddingX={{ xs: 0, md: 2, lg: 8 }} paddingY={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button startIcon={<ArrowBackIcon />} onClick={goBackPlan}>
                        {t("plans.goBack")}
                    </Button>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Card sx={{ p: 2 }}>
                        <CardHeader
                            action={
                                <Button aria-label="clean" onClick={clearAll}>
                                    {t("subscription.cleanAll")}
                                </Button>
                            }
                            title={t("subscription.cart")}
                            titleTypographyProps={{ variant: "h1", fontSize: 22 }}
                        />
                        <CardContent>
                            <ShoppingItems
                                cartItems={cartItems}
                                deleteItem={deleteItem}
                                setCartItems={setCartItems}
                                plansInfo={plansInfo}
                                currentPlan={currentPlan}
                                setIsLoading={setIsLoading}
                                company_id={company_id}
                                franchise_id={franchise_id}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Card sx={{ p: 2 }}>
                        <CardHeader
                            title={t("subscription.orderSummary")}
                            titleTypographyProps={{ variant: "h1", fontSize: 22 }}
                        />
                        <CardContent>
                            <ShoppingSummary
                                cartItems={cartItems}
                                currentPlanInfo={currentPlanInfo}
                                plansInfo={plansInfo}
                                setCartItems={setCartItems}
                                setShoppingCartOpen={setShoppingCartOpen}
                                setCurrentPlanInfo={setCurrentPlanInfo}
                                setTextoPlan={setTexto}
                                setAlertTypePlan={setAlertType}
                                setShowPlan={setShow}
                                payments={payments}
                                company_id={company_id}
                                franchise_id={franchise_id}
                                setActivePlan={setActivePlan}
                                setReload={setReload}
                                reloadData={reloadData}
                                setSuccessReactivate={setSuccessReactivate}
                                franchiseDetails={franchiseDetails}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Card sx={{ px: 4, pt: 1, mt: 2 }}>
                <CardHeader
                    title={t("credits.addCreditsCart")}
                    titleTypographyProps={{ variant: "h1", fontSize: 22, color: "primary" }}
                    sx={{ pb: 0 }}
                />
                <CardContent sx={{ p: 0 }}>
                    <ConversionPack
                        company_id={company_id}
                        franchise_id={franchise_id}
                        cartItems={cartItems}
                        setCartItems={setCartItems}
                        setIsLoading={setIsLoading}
                        activePlan={activePlan}
                        customCredits={customCredits}
                        setCustomCredits={setCustomCredits}
                        planCredits={planCredits}
                        isCart={true}
                        handleOpenCart={() => setShoppingCartOpen(true)}
                        currentPlan={currentPlan}
                        plansInfo={plansInfo}
                        mainSkeleton={mainSkeleton}
                    />
                </CardContent>
            </Card>
        </Box>
    );
};

export default ShoppingCart;
