import axios from "axios";

/* Script que contiene las llamadas a la API */
const invoices = {
    getInvoiceConversions: (params) => {
        const request = axios.get("/getInvoiceConversions", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    getGroupedInvoiceConversions: (params) => {
        const request = axios.get("/getGroupedInvoiceConversions", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    convertInvoices: (params) => {
        const request = axios.post("/convertInvoices", params);

        return request.then((response) => response);
    },

    // Petición que retorna el id de la conversion agrupada
    createGroupedConversionInvoice: (params) => {
        const request = axios.post("/createGroupedConversionInvoice", params);

        return request.then((response) => response.data);
    },

    getGroupedInvoiceDetails: (params) => {
        const request = axios.get("/getGroupedInvoiceDetails", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    getAIInvoiceDetails: (params) => {
        const request = axios.get("/getAIInvoiceDetails", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    downloadInvoiceConversion: (params) => {
        const request = axios.get("/downloadInvoiceConversion", { params: params });

        return request.then((response) => response.data);
    },

    // Retornar factura
    invoiceReturn: (params) => {
        const request = axios.post("/invoiceReturn", params);

        return request.then((response) => response.data);
    },

    getHistorySync: (params) => {
        const request = axios.get("/getHistorySync", { params: params });

        return request.then((response) => response.data);
    },

    getConsolidatedInvoices: (params) => {
        const request = axios.get("/getConsolidatedInvoices", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    // Petición para cargar el archivo que se va a convertir
    uploadConsolidatedFileS3: (params, options) => {
        const request = axios.post("/uploadConsolidatedFileS3", params, options);

        return request.then((response) => response.data);
    },

    convertConsolidatedInvoices: (params) => {
        const request = axios.post("/convertConsolidatedInvoices", params);

        return request.then((response) => response);
    },

    getConsolidatedInvoiceDetails: (params) => {
        const request = axios.get("/getConsolidatedInvoiceDetails", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    getInvoicesConversionUsers: (params) => {
        const request = axios.get("/getInvoicesConversionUsers", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    updateInvoices: (params) => {
        const request = axios.post("/updateInvoices", params);

        return request.then((response) => response);
    },
};

export default invoices;
