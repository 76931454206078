import { PaymentElement, AddressElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { Button, Stack } from "@mui/material";

import payService from "../../services/payment_methods";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BaseSnackbar from "../ui/BaseSnackbar";

export default function CheckoutForm(props) {
    const { setOpenModal, totalAmount, setIsLoading } = props;

    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState("");
    const [address, setAddress] = useState("");
    const [name, setName] = useState("");
    const [show, setShow] = useState(false);

    /* React redux */
    const todos = useSelector((state) => state.value);
    const [t] = useTranslation("global");

    const handleError = (error) => {
        setIsLoading(false);
        setMessage(error.message);
        setShow(true);
    };

    const handleChangeAddress = (event) => {
        if (event.complete) {
            // Extract potentially complete address
            setAddress(event.value.address);
            setName(event.value.name);
        }
    };

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        let clientSecret = "";
        
        const params = {
            total_amount: totalAmount,
            company_id: todos.companyID,
            franchise_id: todos.franchiseID,
        };

        await payService
            .getPaymentMethods(params)
            .then((response) => {
                response.forEach((element) => {
                    if (element.customer_id) {
                        params.customer_id = element.customer_id;
                    }
                });
            })
            .catch((err) => console.log(err.message));

        await payService
            .createPaymentIntent(params)
            .then((response) => {
                clientSecret = response.clientSecret;
            })
            .catch((err) => console.log(err.response.data.error));

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
                return_url: `${window.location.origin}/subscription`,
            },
            redirect: "if_required",
        });

        if (paymentIntent) {
            const params = {
                payID: paymentIntent.payment_method,
                company_id: todos.companyID,
                franchise_id: todos.franchiseID,
                card_holder: name,
                address: address,
                firstPay: true
            };

            await payService
                .addPaymentMethod(params)
                .then()
                .catch((err) => console.log(err.message));
        }

        if (error) {
            handleError(error);
        } else if (paymentIntent) {
            setOpenModal(true);
        }

        setIsLoading(false);
    };

    return (
        <>
            <PaymentElement />
            <AddressElement options={{ mode: "billing" }} onChange={handleChangeAddress} />
            <Stack spacing={2} pt={2}>
                <Button
                    variant="contained"
                    disabled={!stripe || !elements}
                    id="submit"
                    sx={{ mt: 2 }}
                    onClick={handleSubmit}
                >
                    {t("credits.pay")}
                </Button>
            </Stack>
            {/* Muestra los mensajes */}
            <BaseSnackbar type="error" show={show} message={message} onClose={handleClose} />
        </>
    );
}
