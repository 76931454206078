import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "intro.js/introjs.css";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import App from "./App";
import { Provider } from "react-redux";
import { persistor, store } from './redux/store';
import { PersistGate } from "redux-persist/integration/react";

/* MUI */
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./themeSettings";

/** Global variables */
import global_es from "./i18n/es/global.json";
import global_en from "./i18n/en/global.json";

/* Utils */
import util from "./components/Js/util";

i18next.init({
    interpolation: { escapeValue: false },
    lng: util.getSessionStorageLanguage(),
    fallbackLng: util.getSessionStorageLanguage(),
    resources: {
        es: {
            global: global_es,
        },
        en: {
            global: global_en,
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <I18nextProvider i18n={i18next}>
                            <App />
                        </I18nextProvider>
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>
);
