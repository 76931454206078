/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Box,
    Divider,
    Button,
    Paper,
    Grid,
    Stack,
    IconButton,
} from "@mui/material";

/** Components */
import LightTooltip from "../ui/LightTooltip";
import creditsInfo from "../Js/creditsInfo";

/** Icons */
import Visa from "../../assets/visa.svg";
import Mastercard from "../../assets/mastercard.svg";
import Amex from "../../assets/amex.svg";
import Discover from "../../assets/discover.svg";
import Diners from "../../assets/diners.svg";
import JCB from "../../assets/jcb.svg";
import UnionPay from "../../assets/unionpay.svg";

/** MUI Icons */
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

/** Styles */
import classes from "./AddCreditPacksDialog.module.css";
import { styled } from "@mui/material/styles";

/** Services */
import { useDispatch, useSelector } from "react-redux";
import CardCredit from "../subscription/CardCredit";
import CustomCardCredit from "../subscription/CustomCardCredit";

import { useNavigate } from "react-router-dom";

import payService from "../../services/payment_methods";
import companyService from "../../services/company";
import subscriptionService from "../../services/subscription";
import SimpleDialog from "../ui/SimpleDialog";

import moment from "moment";
import { addCredits, addSubscription } from "../../redux/userSlice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 20,
    },
    "& .MuiDialogTitle-root": {
        fontSize: 22,
        fontWeight: 600,
        color: "#4a22d4",
        padding: "30px 40px 5px 40px",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(0),
    },
}));

const AddCreditPacksDialog = (props) => {
    const {
        open,
        onClose,
        getPaymentMethods,
        setShowAddPay,
        setShowChangePay,
        activePlan,
        paymentMethod,
        setIsLoading,
        setCustomCredits,
        customCredits,
    } = props;

    const todos = useSelector((state) => state.value);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component States */
    const [cartItems, setCartItems] = useState([]);
    const [planCredits, setPlanCredits] = useState([]);
    const [payStatus, setPayStatus] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const sumTotal = cartItems.reduce((acumulador, elemento) => acumulador + elemento.line_amount, 0);

    const sumCredits = cartItems.reduce((acumulador, elemento) => acumulador + elemento.line_qty, 0);

    const plansInfo = [
        {
            planId: 1,
            planTitle: t("plans.essential"),
            cost: 7.75,
            colorTop: "#484949",
            data: {
                credits: 5,
                users: 1,
                roles: true,
                storage: t("plans.unlimited"),
                support: t("plans.standard"),
                integrations: [],
                xeroDashboard: false,
                training: false,
            },
            customCredits: 1.8,
        },
        {
            planId: 2,
            planTitle: t("plans.advanced"),
            cost: 19.75,
            colorTop: "#a35eff",
            data: {
                credits: 15,
                users: 2,
                roles: true,
                storage: t("plans.unlimited"),
                support: t("plans.standard"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
                training: false,
            },
            customCredits: 1.7,
        },
        {
            planId: 3,
            planTitle: t("plans.premium"),
            cost: 35.75,
            colorTop: "#ffab4c",
            data: {
                credits: 30,
                users: 3,
                roles: true,
                storage: t("plans.unlimited"),
                support: t("plans.standard"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
                training: false,
            },
            customCredits: 1.6,
        },
        {
            planId: 4,
            planTitle: t("plans.professional"),
            cost: 55.75,
            colorTop: "#00B147",
            data: {
                credits: 50,
                users: 5,
                roles: true,
                support: t("plans.standard"),
                storage: t("plans.unlimited"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
                training: t("plans.standard"),
            },
            customCredits: 1.5,
        },
        {
            planId: 5,
            planTitle: t("plans.business"),
            cost: 189.75,
            colorTop: "#00FFFF",
            data: {
                credits: 200,
                users: 6,
                roles: true,
                support: t("plans.priority"),
                storage: t("plans.unlimited"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
                training: t("plans.custom"),
            },
            customCredits: 1.4,
        },
        {
            planId: 6,
            planTitle: t("plans.corporate"),
            cost: 425.75,
            colorTop: "#FF007A",
            data: {
                credits: 500,
                users: t("plans.unlimited"),
                roles: true,
                support: t("plans.priority"),
                storage: t("plans.unlimited"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
                training: t("plans.custom"),
            },
            customCredits: 1.3,
        },
    ];

    /** Component functions */
    useEffect(() => {
        (async () => {
            const params = {
                franchise_id: todos.franchiseID,
            };

            //Se llena el carrito si hay
            await subscriptionService
                .getShoppingCart(params)
                .then((response) => {
                    if (response && response.cart_lines.length > 0) {
                        const cartItems = response.cart_lines.map((value) => {
                            const newCart = {
                                line_type: value.line_type,
                                line_amount: value.line_amount,
                                line_unit_amount: value.line_unit_amount,
                                line_qty: value.line_qty,
                                line_since_date: value.line_since_date,
                                line_end_date: value.line_end_date,
                            };
                            return newCart;
                        });
                        setCartItems(cartItems);
                    }
                })
                .catch((err) => console.log(err));
        })();
    }, [todos.companyID, todos.franchiseID]);

    const goToPlans = () => {
        navigate("/subscription");
    };

    const handleCloseModalCredits = () => {
        setOpenModal(false);
        resetCart();
        onClose();
    };

    const changePaymentMethod = (hasCard) => {
        onClose();
        if (hasCard) {
            setShowChangePay(true);
        } else {
            setShowAddPay(true);
        }
    };

    const deleteItem = async (item) => {
        let packsFilter = cartItems.filter(
            (pack) => pack.line_type !== item.line_type || pack.line_amount !== item.line_amount
        );

        const params = {
            franchise_id: todos.franchiseID,
            cart_lines: packsFilter,
        };

        setIsLoading(true);

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);

        setCartItems(packsFilter);
    };

    const handleCreditsSelect = async (value) => {
        const isFound = cartItems.find((item) => item.line_qty === value.credits && item.line_type === 5);

        if (!isFound) {
            const newVAl = {
                line_type: 5,
                line_amount:
                    value.discount.length > 0 && value.discount.map((item) => item.planId).includes(activePlan)
                        ? value.discount.find((item) => item.planId === activePlan).cost
                        : value.cost,
                line_unit_amount: (value.discount.length > 0 &&
                value.discount.map((item) => item.planId).includes(activePlan)
                    ? value.discount.find((item) => item.planId === activePlan).cost / value.credits
                    : value.cost / value.credits
                ).toFixed(2),
                line_qty: value.credits,
                line_since_date: moment().format(),
                line_end_date: moment().add(1, "M").format(),
            };

            const params = {
                franchise_id: todos.franchiseID,
                cart_lines: [...cartItems, newVAl],
            };

            setIsLoading(true);

            await subscriptionService
                .updateCart(params)
                .then()
                .catch((error) => {
                    console.log(error);
                });

            setIsLoading(false);

            setCartItems([...cartItems, newVAl]);
        }
    };

    const handleCustomCreditsSelect = (data) => {
        if (!(data === -1 && (customCredits === 1 || customCredits === 0 || isNaN(customCredits)))) {
            if (isNaN(customCredits)) {
                setCustomCredits(1);
            } else {
                setCustomCredits((prevState) => parseInt(prevState) + parseInt(data));
            }
        }
    };

    const handleCustomCreditsSelectInput = (e) => {
        if (e.target.value >= 0) {
            setCustomCredits(parseInt(e.target.value));
        }
    };

    const handleAdd = async () => {
        if (customCredits > 0 && !isNaN(customCredits)) {
            const isFound = cartItems.find((item) => item.line_type === 6);

            const params = {
                franchise_id: todos.franchiseID,
            };

            let totalCreditCost = activePlan
                ? customCredits * plansInfo.find((item) => item.planId === activePlan).customCredits
                : customCredits * 2.0;

            if (isFound) {
                var index = cartItems.map((item) => item.line_type).indexOf(6);

                cartItems[index].line_amount = totalCreditCost;
                cartItems[index].line_unit_amount = totalCreditCost / customCredits;
                cartItems[index].line_qty = customCredits;

                params.cart_lines = [...cartItems];

                setCartItems([...cartItems]);
            } else {
                const newVAl = {
                    line_type: 6,
                    line_amount: totalCreditCost,
                    line_unit_amount: totalCreditCost / customCredits,
                    line_qty: customCredits,
                    line_since_date: moment().format(),
                    line_end_date: moment().add(1, "M").format(),
                };

                params.cart_lines = [...cartItems, newVAl];

                setCartItems([...cartItems, newVAl]);
            }

            setIsLoading(true);

            await subscriptionService
                .updateCart(params)
                .then()
                .catch((error) => {
                    console.log(error);
                });

            setIsLoading(false);
        }
    };

    const resetCart = async () => {
        setIsLoading(true);

        const params = {
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
            cart_lines: [],
        };

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        await subscriptionService
            .getFranchiseCredits({ franchise_id: todos.franchiseID })
            .then((response) => dispatch(addCredits(response)))
            .catch((err) => console.log(err));

        await companyService
            .getFranchiseDetails(todos.franchiseID)
            .then((data) => {
                dispatch(
                    addSubscription({
                        value: data.id_status,
                        last_value: data.id_last_status,
                        plan: data.subscription_plan,
                    })
                );
            })
            .catch((error) => {
                console.log(error);
            });

        setCartItems([]);

        setIsLoading(false);
    };

    const creditsContent = (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                {payStatus ? (
                    <CheckCircleIcon sx={{ fontSize: 70, color: "#00B147" }} />
                ) : (
                    <CancelIcon sx={{ fontSize: 70, color: "#ED1A1A" }} />
                )}
            </Grid>
            <Grid item>
                <Typography fontSize={20} fontWeight={700}>
                    {payStatus ? t("dialog.successPay") : t("dialog.errorPay")}
                </Typography>
                {payStatus ? (
                    <Typography fontSize={16}>
                        {`${sumCredits === 1 ? t("dialog.creditsNew1") : t("dialog.creditsNew")} `}
                        <b style={{ color: "#4a22d4" }}>{`${sumCredits} ${
                            sumCredits === 1 ? t("dialog.creditsText") : t("dialog.creditsText")
                        }`}</b>
                    </Typography>
                ) : (
                    <Typography fontSize={16}>{t("dialog.errorPayText")}</Typography>
                )}
            </Grid>
        </Grid>
    );

    const handlePayment = async () => {
        if (paymentMethod) {
            const params = {
                total_amount: parseInt(sumTotal.toFixed(2).toString().replace(".", "") ?? 0),
                franchise_id: todos.franchiseID,
                customer_id: paymentMethod.customer_id,
                payment_id: paymentMethod.payment_id,
                payment_method_id: paymentMethod._id,
            };
            setIsLoading(true);

            await payService
                .chargeSavedCard(params)
                .then(async () => {
                    setPayStatus(true);
                })
                .catch((err) => {
                    console.log(err.response.data);
                    setPayStatus(false);
                    // Se envía un correo de pago manual fallido
                    payService.sendEmailFailedPayment(params);
                });
            setOpenModal(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getPaymentMethods();

        if (todos.subscription.plan === 1) {
            setPlanCredits(creditsInfo.slice(0, 2));
        } else if (todos.subscription.plan === 2) {
            setPlanCredits(creditsInfo.slice(1, 3));
        } else if (todos.subscription.plan === 3) {
            setPlanCredits(creditsInfo.slice(2, 4));
        } else if (todos.subscription.plan === 4) {
            setPlanCredits(creditsInfo.slice(4, 6));
        } else if (todos.subscription.plan === 5) {
            setPlanCredits(creditsInfo.slice(5, 7));
        } else {
            setPlanCredits(creditsInfo.slice(7, 9));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [todos.subscription]);

    return (
        <BootstrapDialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">
                <Typography fontSize={24} fontWeight={600}>
                    {activePlan ? t("credits.upgradePlan") : t("credits.subsPlanNow")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box className={classes.spacing}>
                    <Paper
                        square={false}
                        sx={{ backgroundColor: activePlan ? "#FCE3063D" : "#00FFFF3D", mb: 2, p: "20px 30px" }}
                    >
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={12} md={8}>
                                <Typography color="primary" fontSize={24} fontWeight={600}>
                                    {activePlan ? t("credits.upPosibilities") : t("credits.nextStep")}
                                </Typography>
                                <Typography>
                                    {activePlan ? t("credits.scaleBetterBen") : t("credits.subsBetterToYou")}
                                    <b>{activePlan ? t("credits.upPlanNow") : t("credits.neverNoConver")}</b>
                                </Typography>
                            </Grid>
                            <Grid item sx={{ textAlign: "center" }}>
                                <Typography color="primary">
                                    {`${t("credits.untilTo")} $7.75/${t("plans.month")}`}
                                </Typography>
                                <Button variant="contained" color="primary" onClick={goToPlans}>
                                    {t("credits.seePlanSubs")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container spacing={5}>
                        {activePlan &&
                            planCredits.map((item) => (
                                <Grid item xs={12} md={4} key={item.creditId}>
                                    <CardCredit
                                        data={item}
                                        handle={handleCreditsSelect}
                                        activePlan={activePlan}
                                        cartItems={cartItems}
                                        plansInfo={plansInfo}
                                    />
                                </Grid>
                            ))}
                        <Grid item xs={12} md={4}>
                            <CustomCardCredit
                                customCredits={customCredits}
                                handle={handleCustomCreditsSelect}
                                cost={
                                    activePlan ? plansInfo.find((item) => item.planId === activePlan).customCredits : 2
                                }
                                handleAdd={handleAdd}
                                cartItems={cartItems}
                                handleInput={handleCustomCreditsSelectInput}
                                noTotal={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box className={classes.resumeCard} sx={{ mb: 0, mt: 3 }}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} md={4} sx={{ p: "20px 0 20px 40px" }}>
                            <Typography fontStyle="normal" fontWeight={600} fontSize={24} color="#131F3E">
                                {t("credits.paymentMethods")}
                            </Typography>
                            <Button onClick={() => changePaymentMethod(paymentMethod?.card_number)}>
                                {"+ " + (!paymentMethod?.card_number ? t("credits.add") : t("credits.editOrChange"))}
                            </Button>
                            {paymentMethod?.card_number && (
                                <Stack direction="row" alignItems="center" sx={{ mt: 2 }} spacing={2}>
                                    <Box
                                        component="img"
                                        src={
                                            paymentMethod.brand === "visa"
                                                ? Visa
                                                : paymentMethod.brand === "mastercard"
                                                ? Mastercard
                                                : paymentMethod.brand === "amex"
                                                ? Amex
                                                : paymentMethod.brand === "discover"
                                                ? Discover
                                                : paymentMethod.brand === "diners"
                                                ? Diners
                                                : paymentMethod.brand === "unionpay"
                                                ? UnionPay
                                                : JCB
                                        }
                                        sx={{ width: 45 }}
                                    />
                                    <Typography fontWeight={600}>
                                        {`${
                                            paymentMethod?.brand.charAt(0).toUpperCase() + paymentMethod?.brand.slice(1)
                                        } ${t("credits.endingIn")} *${paymentMethod?.card_number.split("-").pop()}`}
                                    </Typography>
                                </Stack>
                            )}
                        </Grid>
                        <Grid item>
                            <Divider orientation="vertical" className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} md={7} sx={{ p: { xs: "20px 40px 20px 30px", md: "20px 40px 20px 0px" } }}>
                            <Typography
                                fontStyle="normal"
                                fontWeight={600}
                                fontSize={24}
                                sx={{ pb: 2 }}
                                color="#131F3E"
                            >
                                {t("subscription.orderSummary")}
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <Box minWidth={500}>
                                    {cartItems.map((item) => (
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            key={item.line_qty}
                                            spacing={2}
                                            sx={{ mb: 1 }}
                                        >
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {t("subscription.packageOf")} {item.line_qty}{" "}
                                                    {item.line_qty === 1
                                                        ? t("credits.conversion")
                                                        : t("credits.credits")}
                                                </Typography>
                                                <Typography color="primary">
                                                    +{item.line_qty} {t("subscription.newCred")}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Typography variant="h3">
                                                        $
                                                        {parseFloat(item.line_amount).toLocaleString(
                                                            todos.amountFormat,
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}
                                                        {item.subscription_plan && `/${t("plans.month")}`}
                                                    </Typography>
                                                    <IconButton onClick={() => deleteItem(item)}>
                                                        <DeleteIcon sx={{ fontSize: "18px", color: "#4A22D4" }} />
                                                    </IconButton>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    <Divider sx={{ pr: 2 }} />
                                    <Grid container justifyContent="space-between" spacing={1} sx={{ my: 1 }}>
                                        <Grid item>
                                            <Typography variant="h3">Total</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3" sx={{ pr: 2 }}>
                                                $
                                                {parseFloat(sumTotal).toLocaleString(todos.amountFormat, {
                                                    minimumFractionDigits: 2,
                                                })}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={!paymentMethod?.card_number || cartItems.length === 0}
                                        className={classes.buttonPurple}
                                        onClick={handlePayment}
                                    >
                                        {t("credits.pay")}
                                    </Button>
                                    <LightTooltip title={t("credits.creditValidity")}>
                                        <Stack direction="row" spacing={1} sx={{ mt: 1 }} alignItems="center">
                                            <InfoIcon color="primary" sx={{ fontSize: 15 }} />
                                            <Typography
                                                fontStyle="normal"
                                                fontWeight={500}
                                                fontSize={12}
                                                color={"#4A22D4"}
                                            >
                                                {t("credits.terms")}
                                            </Typography>
                                        </Stack>
                                    </LightTooltip>
                                    <Typography sx={{ maxWidth: "210px", fontSize: 12, textAlign: "left", mt: 1 }}>
                                        {t("credits.conditionWarning")}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <SimpleDialog
                open={openModal}
                handleClose={handleCloseModalCredits}
                maxWidth="sm"
                content={creditsContent}
            />
        </BootstrapDialog>
    );
};

export default AddCreditPacksDialog;
