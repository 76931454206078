import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const GroupedButtons = (props) => {
    const { convertAll, handleCloseDialog, isHist, modalType } = props;

    const [t] = useTranslation("global");

    return (
        <Grid container justifyContent={modalType === "sync" ? "end" : "space-between"} sx={{ px: 8 }}>
            {modalType !== "sync" && <Grid item>
                <Button
                    disableElevation
                    onClick={handleCloseDialog}
                    color="primary"
                    sx={{
                        px: 2,
                        borderRadius: 2,
                        fontSize: 15,
                    }}
                >
                    {t("miscellaneous.goBack")}
                </Button>
            </Grid>}
            {!isHist && (
                <Grid item>
                    <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={convertAll}
                        sx={{
                            borderRadius: 2,
                            px: 2,
                            fontSize: 15,
                        }}
                    >
                        {t("dialog.continue")}
                    </Button>
                </Grid>
            )}
            {modalType === "sync" && <Grid item>
                <Button
                    disableElevation
                    onClick={handleCloseDialog}
                    color="primary"
                    variant="contained"
                    sx={{
                        px: 2,
                        borderRadius: 2,
                        fontSize: 15,
                        marginRight: "auto"
                    }}
                >
                    {t("dialog.continue")}
                </Button>
            </Grid>}
        </Grid>
    );
};

export default GroupedButtons;
