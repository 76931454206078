import { Box, Chip, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import subscriptionService from "../../../services/subscription";

/* Estilos */
import classes from "./ShoppingItems.module.css";

/* Iconos */
import CancelIcon from "@mui/icons-material/Cancel";
import PushPinIcon from "@mui/icons-material/PushPin";
import EastIcon from "@mui/icons-material/East";
import ClearIcon from "@mui/icons-material/Clear";
import XeroLogo from "../../../assets/xero.svg";
import QuickBooksLogo from "../../../assets/quickbooks.svg";

import LightTooltip from "../../ui/LightTooltip";

const ShoppingItems = (props) => {
    const { cartItems, deleteItem, setCartItems, plansInfo, currentPlan, setIsLoading, franchise_id, company_id } =
        props;

    const [t, i18n] = useTranslation("global");

    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));

    const selectedPlan = plansInfo.find((item2) => item2.planId === currentPlan);

    const newPlan = cartItems.find((element) => element.subscription_plan);

    const handlePush = async (item) => {
        let newArr = [];

        newArr = cartItems.map((c) => {
            if (c.line_qty === item.line_qty) {
                c.fixed = !c.fixed;
            }
            return c;
        });

        setIsLoading(true);

        const params = {
            franchise_id: franchise_id,
            company_id: company_id,
            cart_lines: newArr,
        };

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);

        setCartItems(newArr);
    };

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    return (
        <Grid container spacing={1}>
            {cartItems.map((item) =>
                item.subscription_plan ? (
                    <Grid item xs={12} key={item.line_qty}>
                        <Paper variant="outlined" className={classes.cardItems}>
                            <Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
                                <Stack direction="row" alignItems="center" spacing={4}>
                                    <Box component="img" className={classes.imagePlan} src={item.image} />
                                    <Box className={classes.description}>
                                        <Typography color="primary" variant="h1">
                                            Plan {item.planType}
                                        </Typography>
                                        <Chip
                                            label={
                                                item.line_type === 1
                                                    ? t("subscription.activation")
                                                    : item.line_type === 2
                                                        ? t("subscription.renewal")
                                                        : item.line_type === 3
                                                            ? t("subscription.downgrade")
                                                            : item.line_type === 4
                                                                ? t("subscription.upgrade")
                                                                : item.line_type === 7
                                                                    ? t("subscription.cancel")
                                                                    : item.line_type === 8
                                                                        ? t("subscription.reactivate")
                                                                        : ""
                                            }
                                            size="small"
                                            className={
                                                item.line_type === 7
                                                    ? classes.chipCustCancel
                                                    : item.line_type === 4
                                                        ? classes.chipCustOut
                                                        : item.line_type === 3
                                                            ? classes.chipCustCancelOut
                                                            : classes.chipCust
                                            }
                                            variant={
                                                item.line_type === 7 || item.line_type === 8 ? "filled" : "outlined"
                                            }
                                        />
                                    </Box>
                                    <Box className={classes.description}>
                                        {item.line_type === 4 || item.line_type === 3 || item.line_type === 7 ? (
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <Typography
                                                    fontWeight={600}
                                                    color={item.line_type === 4 ? "#00B147" : "error"}
                                                >
                                                    {selectedPlan?.data.credits}
                                                </Typography>
                                                <EastIcon fontSize="small" color="primary" />
                                                <Typography
                                                    fontWeight={600}
                                                    color={item.line_type === 4 ? "#00B147" : "error"}
                                                >
                                                    {item.line_type === 7 ? 0 : item.planData.credits}
                                                </Typography>
                                                <Typography>{t("subscription.conversions")}</Typography>
                                            </Stack>
                                        ) : (
                                            <Typography>
                                                <b style={{ color: "#4a22d4" }}>{item.planData.credits}</b>{" "}
                                                {t("subscription.conversions")}
                                            </Typography>
                                        )}
                                        {item.line_type === 4 || item.line_type === 3 || item.line_type === 7 ? (
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <Typography
                                                    fontWeight={600}
                                                    color={item.line_type === 4 ? "#00B147" : "error"}
                                                >
                                                    {selectedPlan?.data.users === "unlimited" || selectedPlan?.data.users === "ilimitado" ? t("payment.unlimited") : selectedPlan?.data.users}
                                                </Typography>
                                                <EastIcon fontSize="small" color="primary" />
                                                <Typography
                                                    fontWeight={600}
                                                    color={item.line_type === 4 ? "#00B147" : "error"}
                                                >
                                                    {item.line_type === 7
                                                        ? 1
                                                        : item.planData.users === t("plans.unlimited")
                                                            ? t("subscription.unliUsers")
                                                            : item.planData.users}
                                                </Typography>
                                                {item.planData.users !== t("plans.unlimited") && (
                                                    <Typography>{item.planData.users === 1 ? t("subscription.users1") : t("subscription.users")}</Typography>
                                                )}
                                            </Stack>
                                        ) : item.planData.users === t("plans.unlimited") ? (
                                            <Typography>{t("subscription.unliUsers")}</Typography>
                                        ) : (
                                            <Typography>
                                                <b style={{ color: "#4a22d4" }}>{item.planData.users}</b>{" "}
                                                {item.planData.users === 1 ? t("subscription.users1") : t("subscription.users")}
                                            </Typography>
                                        )}
                                        <Stack direction="row" alignContent="center" spacing={1}>
                                            {item.line_type === 7 && <ClearIcon fontSize="small" color="error" />}
                                            <Typography>
                                                {
                                                    language === "en" ? (
                                                        <>
                                                            <b>{newPlan.planData.support}</b>{" "}
                                                            {t("plans.support3")}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {t("plans.support")}{" "}
                                                            <b>{newPlan.planData.support}</b>
                                                        </>
                                                    )
                                                }
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" alignContent="center" spacing={1}>
                                            {item.line_type === 7 && <ClearIcon fontSize="small" color="error" />}
                                            <Typography>
                                                {
                                                    language === "en" ? (
                                                        <>
                                                            <b>{t("plans.storage")}</b>{" "}
                                                            {t("plans.unlimited")}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {t("plans.storage")}{" "}
                                                            <b>{newPlan.planData.storage}</b>
                                                        </>
                                                    )
                                                }
                                            </Typography>
                                        </Stack>

                                        {(selectedPlan?.data.integrations.length > 0 ||
                                            newPlan.planData.integrations.length > 0) && (
                                                <Stack direction="row" alignContent="center" spacing={1}>
                                                    {(newPlan.planData.integrations.length === 0 ||
                                                        item.line_type === 7) && (
                                                            <ClearIcon fontSize="small" color="error" />
                                                        )}
                                                    <Typography sx={{ mr: 1 }}>{t("plans.integrations")}</Typography>
                                                    {(newPlan.planData.integrations.includes("Xero") ||
                                                        selectedPlan?.data.integrations.includes("Xero")) && (
                                                            <Box
                                                                component="img"
                                                                sx={{
                                                                    height: 15,
                                                                    width: 15,
                                                                    maxHeight: { xs: 15, md: 15 },
                                                                    maxWidth: { xs: 15, md: 15 },
                                                                }}
                                                                src={XeroLogo}
                                                            />
                                                        )}
                                                    {(newPlan.planData.integrations.includes("Quickbooks") ||
                                                        selectedPlan?.data.integrations.includes("Quickbooks")) && (
                                                            <Box
                                                                component="img"
                                                                sx={{
                                                                    height: 15,
                                                                    width: 75,
                                                                    maxHeight: { xs: 15, md: 15 },
                                                                    maxWidth: { xs: 75, md: 75 },
                                                                    ml: 0.5,
                                                                }}
                                                                src={QuickBooksLogo}
                                                            />
                                                        )}
                                                </Stack>
                                            )}
                                        {(selectedPlan?.data.xeroDashboard || newPlan.planData.xeroDashboard) && (
                                            <Stack direction="row" alignContent="center" spacing={1}>
                                                {(!newPlan.planData.xeroDashboard || item.line_type === 7) && (
                                                    <ClearIcon fontSize="small" color="error" />
                                                )}
                                                <Typography>
                                                    {language === "en" ? (
                                                        <>
                                                            <b>Xero</b>{" " + t("plans.xeroDashboard1")}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {t("plans.xeroDashboard1") + " "}<b>Xero</b>
                                                        </>
                                                    )}
                                                </Typography>
                                            </Stack>
                                        )}
                                    </Box>
                                </Stack>
                                <IconButton aria-label="delete" onClick={() => deleteItem(item)}>
                                    <CancelIcon />
                                </IconButton>
                            </Stack>
                        </Paper>
                    </Grid>
                ) : (
                    <Grid item xs={12} md={6} key={item.line_qty}>
                        <Paper variant="outlined" className={item.fixed ? classes.cardItemsPin : classes.cardItems}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography color="primary" variant="h1" fontSize={28}>
                                        +{item.line_qty}
                                    </Typography>
                                    <Box className={classes.description}>
                                        <Typography color="primary" variant="h1">
                                            {item.line_qty === 1 ? t("subscription.extraCredits1") : t("subscription.extraCredits")}
                                        </Typography>
                                        {item.fixed && (
                                            <Typography fontSize={12} color="primary">
                                                {t("plans.fixedCred")}
                                            </Typography>
                                        )}
                                    </Box>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <IconButton aria-label="delete" onClick={() => deleteItem(item)}>
                                        <CancelIcon />
                                    </IconButton>
                                    {currentPlan && (
                                        <LightTooltip title={t("plans.pinTool")}>
                                            <IconButton aria-label="pin" onClick={() => handlePush(item)}>
                                                <PushPinIcon color={item.fixed ? "primary" : "default"} />
                                            </IconButton>
                                        </LightTooltip>
                                    )}
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                )
            )}
        </Grid>
    );
};

export default ShoppingItems;
