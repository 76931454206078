import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/* Componentes */
import BackLogin from "../components/layout/BackLogin";
import LoginCard from "../components/auth/LoginCard";
import ForgotCard from "../components/auth/ForgotCard";
import BaseSnackbar from "../components/ui/BaseSnackbar";
import ResetCard from "../components/auth/ResetCard";
import TryKiiperCard from "../components/auth/TryKiiperCard";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";
import ExpiredCard from "../components/auth/ExpiredCard";
import VerifiedCard from "../components/auth/VerifiedCard";
import UserRegisterP from "./UserRegisterP";

import authService from "../services/auth";

import { clearInfo } from "../redux/userSlice";
import ITTeam from "../components/Js/ITTeam";

const Authentication = () => {
    const todos = useSelector((state) => state.value);

    const navigate = useNavigate();
    /* React redux */
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const [showForgotCard, setShowForgotCard] = useState(false);
    const [showResetCard, setShowResetCard] = useState(false);
    const [showTryCard, setShowTryCard] = useState(false);
    const [showExpiredCard, setShowExpiredCard] = useState(false);
    const [showVerifiedCard, setShowVerifiedCard] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [independentUser, setIndependentUser] = useState(false);
    const [register, setRegister] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [invitation, setInvitation] = useState("");
    const isValid = searchParams.has("user-email") && searchParams.has("type");
    const isEmail = searchParams.has("template-email") && searchParams.has("tab");
    const isCart = searchParams.has("cart") && searchParams.has("tab");
    const language = searchParams.get("language");

    const tab = searchParams.get("tab");
    const tryKiiper = searchParams.get("try-kiiper");
    const emailWebKiiper = searchParams.get("email");
    const [invitationId, setInvitationId] = useState("");

    const handleShowForgotCard = () => setShowForgotCard((show) => !show);
    const handleShowResetCard = () => setShowResetCard((show) => !show);
    const handleShowTryCard = () => setShowTryCard((show) => !show);
    const handleShowExpiredCard = () => setShowExpiredCard((show) => !show);
    const handleShowVerifiedCard = () => setShowVerifiedCard((show) => !show);
    const handleShowRegister = () => setShowRegister((show) => !show);

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const resetLogin = () => {
        if (isValid) {
            searchParams.delete("user-email");
            searchParams.delete("type");
            searchParams.delete("language");
            if (searchParams.has("expired")) {
                searchParams.delete("expired");
            }
        }

        setSearchParams(searchParams);
        setShowForgotCard(false);
        setShowResetCard(false);
        setShowTryCard(false);
        setShowExpiredCard(false);
    };

    useEffect(() => {
        (async () => {
            await authService
                .checkSession()
                .then(async (isActive) => {
                    if (!isActive) {
                        if(!isValid){
                            dispatch(clearInfo());
                        }
                    } else {
                        if (ITTeam.includes(todos?.userInfo?.email)) {
                            navigate("/inbox");
                        } else {
                            if (isEmail) {
                                searchParams.delete("template-email");
                                searchParams.delete("tab");
                                searchParams.delete("cart");
                                navigate("/dashboard", {
                                    state: {
                                        redirect: true,
                                        tab: tab,
                                        isCart: isCart,
                                    }
                                });
                            } else {
                                navigate("/dashboard", {
                                    state: {
                                        redirect: true
                                    }
                                });
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log(error.response);
                });

            if (tryKiiper) {
                setShowTryCard(true);
            }

            if (isValid) {
                if (searchParams.has("expired")) {
                    setShowExpiredCard(true);
                } else {
                    if (searchParams.get("type") === "1") {
                        setShowResetCard(true);
                    }
                    if (searchParams.get("type") === "2") {
                        if (searchParams.has("verified") && searchParams.get("verified") === "true") {
                            setShowVerifiedCard(true);
                        } else {
                            setUserEmail(searchParams.get("user-email"));
                            setInvitation(searchParams.get("invitation"));
                            setInvitationId(searchParams.get("key"));
                            setShowRegister(true);
                            setIndependentUser(true);
                            setRegister(true);
                        }
                    }
                }
            }
        })();
    }, [isValid, navigate, searchParams, dispatch, todos.userInfo, isEmail, tab, isCart, tryKiiper]);

    return (
        <>
            {!showRegister ? (
                <BackLogin resetLogin={resetLogin} showTryCard={showTryCard} showExpiredCard={showExpiredCard} language={language}>
                    {!showForgotCard && !showTryCard && !showResetCard && !showExpiredCard && !showVerifiedCard && (
                        <LoginCard
                            handleShowForgotCard={handleShowForgotCard}
                            handleShowTryCard={handleShowTryCard}
                            setShow={setShow}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setIsLoading={setIsLoading}
                            setShowRegister={setShowRegister}
                            setIndependentUser={setIndependentUser}
                            setRegister={setRegister}
                            setUserEmail={setUserEmail}
                            isEmail={isEmail}
                            isCart={isCart}
                            tab={tab ?? parseFloat(tab)}
                            language={language}
                        />
                    )}
                    {showForgotCard && (
                        <ForgotCard
                            handleShowForgotCard={handleShowForgotCard}
                            setShow={setShow}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setIsLoading={setIsLoading}
                        />
                    )}
                    {showResetCard && (
                        <ResetCard
                            handleShowResetCard={handleShowResetCard}
                            setShow={setShow}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setIsLoading={setIsLoading}
                            email={searchParams.get("user-email")}
                            resetLogin={resetLogin}
                            language={language}
                        />
                    )}
                    {showTryCard && (
                        <TryKiiperCard
                            setShow={setShow}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            handleShowTryCard={handleShowTryCard}
                            setIsLoading={setIsLoading}
                            userEmail={emailWebKiiper}
                            tryKiiperWeb={tryKiiper}
                            language={language}
                        />
                    )}
                    {showExpiredCard && (
                        <ExpiredCard
                            setShow={setShow}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            handleShowExpiredCard={handleShowExpiredCard}
                            setIsLoading={setIsLoading}
                            email={searchParams.get("user-email")}
                            isExpiredInv={searchParams.get("type")}
                            language={language}
                        />
                    )}
                    {showVerifiedCard && (
                        <VerifiedCard
                            handleShowVerifiedCard={handleShowVerifiedCard}
                            email={searchParams.get("user-email")}
                            language={language}
                        />
                    )}
                    <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleClose} />
                    <SimpleBackdrop open={isLoading} />
                </BackLogin>
            ) : (
                <UserRegisterP
                    userEmail={userEmail}
                    handleShowRegister={handleShowRegister}
                    independentUser={independentUser}
                    register={register}
                    invitation={invitation}
                    language={language}
                    invitationId={invitationId}
                />
            )}
        </>
    );
};

export default Authentication;
