/** React imports */
import { useTranslation } from "react-i18next";

/** MUI imports */
import { Grid, Card, CardContent, Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

/** Images **/
import XeroLogo from "../../assets/xero.svg";
import QuickBooksLogo from "../../assets/quickbooks.svg";
import KiiperLogo from "../../assets/kiiper.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LightTooltip from "../ui/LightTooltip";

const CustomCard = styled(Card)(({ theme }) => ({
    display: "flex",
    minHeight: 140,
    justifyContent: "space-evenly",
    backgroundColor: "rgba(44, 159, 28, 0.1)",
    alignItems: "center",
    transition: "transform 0.2s", // Add a transition for a smooth effect
    "&:hover": {
        transform: "scale(1.05)", // Scale the card on hover
        backgroundColor: "rgba(0, 255, 255, 0.2)",
    },
}));

const ColorButton2 = styled(Button)(({ theme }) => ({
    letterSpacing: "-0.22px",
    backgroundColor: "#B3B3B3",
    color: "#FFF",
    fontStyle: "normal",
    fontWeight: "350",
    border: "1px solid #B3B3B3",
    borderRadius: "6px",
    fontSize: "12px",
    paddingLeft: "22px",
    paddingRight: "22px",
    paddingTop: "2px",
    paddingBottom: "2px",
    whiteSpace: "nowrap",
    "&:disabled": {
        backgroundColor: "#B3B3B3",
        border: "1px solid #B3B3B3",
        color: "#FFF",
    },
}));

const AddCompanyStep2 = (props) => {
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const { integ, handleInteg, smbInfo } = props;

    const [textButton1, setTextButton1] = useState(t("dialog.connect"));
    const [textButton2, setTextButton2] = useState(t("dialog.connect"));
    const [textButton3, setTextButton3] = useState(t("dialog.select"));
    const [showIntegration, setShowIntegration] = useState(false);

    useEffect(() => {
        if (todos?.subscription) {
            if (
                todos.subscription.value !== 2 &&
                todos.subscription.value !== 5 &&
                !(todos.subscription.value === 3 && todos.subscription.plan === 1)
            ) {
                setShowIntegration(true);
            } else {
                setShowIntegration(false);
            }
        }
    }, [todos.subscription]);

    useEffect(() => {
        (async () => {
            if (integ === 1) {
                setTextButton1(t("dialog.connected"));
                setTextButton2(t("dialog.connect"));
                setTextButton3(t("dialog.select"));
            } else if (integ === 2) {
                setTextButton2(t("dialog.connected"));
                setTextButton1(t("dialog.connect"));
                setTextButton3(t("dialog.select"));
            } else if (integ === 3) {
                setTextButton3(t("dialog.selected"));
                setTextButton1(t("dialog.connect"));
                setTextButton2(t("dialog.connect"));
            }
        })();
    }, [integ, t]);

    const handleOver = (index) => {
        if (index === 1) {
            if (integ === 1) {
                setTextButton1(t("dialog.disconnect"));
            } else {
                setTextButton1(t("dialog.connect"));
            }
        } else if (index === 2) {
            if (integ === 2) {
                setTextButton2(t("dialog.disconnect"));
            } else {
                setTextButton2(t("dialog.connect"));
            }
        }
    };

    const handleOut = (index) => {
        if (index === 1) {
            if (integ === 1) {
                setTextButton1(t("dialog.connected"));
            } else {
                setTextButton1(t("dialog.connect"));
            }
        } else if (index === 2) {
            if (integ === 2) {
                setTextButton2(t("dialog.connected"));
            } else {
                setTextButton2(t("dialog.connect"));
            }
        }
    };

    return (
        <Grid container justifyContent="center" sx={{ mt: 1 }} spacing={3}>
            <Grid item xs={12}>
                <Typography fontStyle="normal" fontSize={18} fontWeight={600} sx={{ color: "#131F3E" }}>
                    {t("dialog.method")}
                </Typography>
            </Grid>
            <Grid item xs={11} md={4}>
                <CustomCard
                    elevation={0}
                    sx={{
                        transform: integ && integ === 1 ? "scale(1.06)" : "scale(1.0)", // Scale the card on hover
                        backgroundColor: integ && integ === 1 ? "#ccffff" : "#e8f7fb",
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            height: 80,
                            width: 80,
                            maxHeight: { xs: 80, md: 80 },
                            maxWidth: { xs: 80, md: 80 },
                        }}
                        src={XeroLogo}
                    />
                    <Box>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                            <LightTooltip title={!showIntegration ? t("dashboard.tooltipIntegrations") : ""}>
                                <span>
                                    <ColorButton2
                                        onClick={() => handleInteg(1)}
                                        disableElevation
                                        endIcon={integ && integ === 1 ? <CheckIcon sx={{ width: "14px" }} /> : <></>}
                                        variant="contained"
                                        // disabled={subscription === "freeTrial" || integ === 2}
                                        onMouseOver={() => handleOver(1)}
                                        onMouseOut={() => handleOut(1)}
                                        sx={{
                                            backgroundColor: integ && integ === 1 ? "#131F3E" : "#4A22D4",
                                            border: integ && integ === 1 ? "1px solid #131F3E" : "1px solid #4A22D4",
                                            color: integ && integ === 1 ? "#00FFFF" : "#FFF",
                                            "&:hover": {
                                                backgroundColor: integ && integ === 1 ? "#131F3E" : "#3C1BAD",
                                                border:
                                                    integ && integ === 1 ? "1px solid #131F3E" : "1px solid #3C1BAD",
                                                color: integ && integ === 1 ? "#00FFFF" : "#FFF",
                                            },
                                        }}
                                        disabled={!showIntegration}
                                    >
                                        {textButton1}
                                    </ColorButton2>
                                </span>
                            </LightTooltip>
                            {integ === 1 && smbInfo.system_subscription_name && (
                                <LightTooltip
                                    title={
                                        smbInfo.system_subscription_name.length <= 50
                                            ? ""
                                            : smbInfo.system_subscription_name
                                    }
                                >
                                    <Typography
                                        sx={{
                                            paddingTop: 1.5,
                                            width: 120,
                                            fontSize: 12,
                                        }}
                                        variant="h4"
                                    >
                                        {smbInfo.system_subscription_name.length <= 50
                                            ? smbInfo.system_subscription_name
                                            : `${smbInfo.system_subscription_name.substring(0, 50)}...`}
                                    </Typography>
                                </LightTooltip>
                            )}
                        </CardContent>
                    </Box>
                </CustomCard>
            </Grid>
            <Grid item xs={11} md={4}>
                <CustomCard
                    elevation={0}
                    sx={{
                        transform: integ && integ === 2 ? "scale(1.06)" : "scale(1.0)", // Scale the card on hover
                        backgroundColor: integ && integ === 2 ? "#ccffff" : "#e8f7fb",
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            height: 110,
                            width: 110,
                            maxHeight: { xs: 110, md: 110 },
                            maxWidth: { xs: 110, md: 110 },
                        }}
                        src={QuickBooksLogo}
                    />
                    <Box>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                            <LightTooltip title={!showIntegration ? t("dashboard.tooltipIntegrations") : ""}>
                                <span>
                                    <ColorButton2
                                        onClick={() => handleInteg(2)}
                                        endIcon={integ && integ === 2 ? <CheckIcon sx={{ width: "14px" }} /> : <></>}
                                        disableElevation
                                        variant="contained"
                                        // disabled={subscription === "freeTrial" || integ === 1}
                                        onMouseOver={() => handleOver(2)}
                                        onMouseOut={() => handleOut(2)}
                                        sx={{
                                            backgroundColor: integ && integ === 2 ? "#131F3E" : "#4A22D4",
                                            border: integ && integ === 2 ? "1px solid #131F3E" : "1px solid #4A22D4",
                                            color: integ && integ === 2 ? "#00FFFF" : "#FFF",
                                            "&:hover": {
                                                backgroundColor: integ && integ === 2 ? "#131F3E" : "#3C1BAD",
                                                border:
                                                    integ && integ === 2 ? "1px solid #131F3E" : "1px solid #3C1BAD",
                                                color: integ && integ === 2 ? "#00FFFF" : "#FFF",
                                            },
                                        }}
                                        disabled={!showIntegration}
                                    >
                                        {textButton2}
                                    </ColorButton2>
                                </span>
                            </LightTooltip>
                            {integ === 2 && smbInfo.system_subscription_name && (
                                <LightTooltip
                                    title={
                                        smbInfo.system_subscription_name.length <= 50
                                            ? ""
                                            : smbInfo.system_subscription_name
                                    }
                                >
                                    <Typography
                                        sx={{
                                            paddingTop: 1.5,
                                            width: 120,
                                            fontSize: 12,
                                        }}
                                        variant="h4"
                                    >
                                        {smbInfo.system_subscription_name.length <= 50
                                            ? smbInfo.system_subscription_name
                                            : `${smbInfo.system_subscription_name.substring(0, 50)}...`}
                                    </Typography>
                                </LightTooltip>
                            )}
                        </CardContent>
                    </Box>
                </CustomCard>
            </Grid>
            <Grid item xs={11} md={4}>
                <CustomCard
                    elevation={0}
                    sx={{
                        transform: integ && integ === 3 ? "scale(1.06)" : "scale(1.0)", // Scale the card on hover
                        backgroundColor: integ && integ === 3 ? "#ccffff" : "#e8f7fb",
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            height: 80,
                            width: 80,
                            maxHeight: { xs: 80, md: 80 },
                            maxWidth: { xs: 80, md: 80 },
                        }}
                        src={KiiperLogo}
                    />
                    <Box>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                            <Box>
                                <ColorButton2
                                    onClick={() => handleInteg(3)}
                                    endIcon={integ === 3 ? <CheckIcon sx={{ width: "14px" }} /> : <></>}
                                    disableElevation
                                    variant="contained"
                                    disabled={integ === 1 || integ === 2}
                                    sx={{
                                        backgroundColor: integ && integ === 3 ? "#131F3E" : "#4A22D4",
                                        border: integ && integ === 3 ? "1px solid #131F3E" : "1px solid #4A22D4",
                                        color: integ && integ === 3 ? "#00FFFF" : "#FFF",
                                        "&:hover": {
                                            backgroundColor: integ && integ === 3 ? "#131F3E" : "#3C1BAD",
                                            border: integ && integ === 3 ? "1px solid #131F3E" : "1px solid #3C1BAD",
                                            color: integ && integ === 3 ? "#00FFFF" : "#FFF",
                                        },
                                    }}
                                >
                                    {textButton3}
                                </ColorButton2>
                            </Box>
                        </CardContent>
                    </Box>
                    <Box sx={{ position: "absolute", px: 2, pb: 1.8, bottom: 0 }}>
                        <Typography color="#131f3e" fontWeight={600} fontSize={13} lineHeight={1.2}>
                            {t("dialog.manual")}
                        </Typography>
                    </Box>
                </CustomCard>
            </Grid>
        </Grid>
    );
};

export default AddCompanyStep2;
