const ITTeam = [
    "jalvarez@gca.digital",
    "btorres@gca.digital",
    "mmedina@gca.digital",
    "jrojas@gca.digital",
    "dloyo@gca.digital",
    "gloyo@gca.digital",
    "galbani@gca.digital",
    "pherrera@gca.digital",
    "kgarcia@gca.digital",
];

export default ITTeam;
