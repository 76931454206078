import { useState } from "react";

/* Componentes */
import LightTooltip from "../ui/LightTooltip";
import { Box, Button, Hidden, ListItemButton, ListItemText, Popover, Typography } from "@mui/material";

/** Icons/images */
import TranslateIcon from "@mui/icons-material/Translate";
import { useTranslation } from "react-i18next";

/* Estilos */
import classes from "./LangButton.module.css";

const LangButton = (props) => {
    const { lang } = props;

    /** Internationalization i18n */
    const [t, i18n] = useTranslation("global");

    const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
    const [language, setLanguage] = useState(lang === "es" ? "Español" : lang === "en" ? "English" : sessionStorage.getItem("lng") === "es" ? "Español" : "English");
    const [languageMobile, setlanguageMobile] = useState(lang === "es" ? "ES" : lang === "en" ? "EN" : sessionStorage.getItem("lng") === "es" ? "ES" : "EN");

    /** Global variables */
    const openPopoverLanguage = Boolean(languageAnchorEl);
    const id = openPopoverLanguage ? "simple-popover" : undefined;

    const handleClickLanguagePopover = (event) => {
        setLanguageAnchorEl(event.currentTarget);
    };

    const handleCloseLanguagePopover = () => {
        setLanguageAnchorEl(null);
    };

    const updateLanguage = (lng) => {
        if (lng === "English") {
            setLanguage(lng);
            setlanguageMobile("EN");
            sessionStorage.setItem("lng", "en");
            i18n.changeLanguage("en");
        } else {
            setLanguage(lng);
            setlanguageMobile("ES");
            sessionStorage.setItem("lng", "es");
            i18n.changeLanguage("es");
        }
    };

    return (
        <Box className={classes.space}>
            <LightTooltip title={t("login.changeLng")}>
                <Button
                    aria-controls="language-menu"
                    aria-haspopup="true"
                    onClick={handleClickLanguagePopover}
                    className={classes.button}
                >
                    <TranslateIcon className={classes.icon} />
                    <Hidden only={["xs"]}>
                        <Typography variant="button" align="center" className={classes.commonWhite}>
                            {language}
                        </Typography>
                    </Hidden>
                    <Hidden only={["sm", "md", "lg", "xl"]}>
                        <Typography variant="button" align="center" className={classes.commonWhite}>
                            {languageMobile}
                        </Typography>
                    </Hidden>
                </Button>
            </LightTooltip>
            <Popover
                id={id}
                open={openPopoverLanguage}
                anchorEl={languageAnchorEl}
                onClose={handleCloseLanguagePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                PaperProps={{
                    sx: {
                        p: "8px 0px",
                        borderRadius: 5,
                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                        backgroundColor: "#F8F9F8",
                    },
                }}
            >
                <ListItemButton
                    onClick={() => {
                        handleCloseLanguagePopover();
                        updateLanguage("English");
                    }}
                    className={classes.Listbutton}
                >
                    <ListItemText primary="English" />
                </ListItemButton>
                <ListItemButton
                    onClick={() => {
                        handleCloseLanguagePopover();
                        updateLanguage("Español");
                    }}
                    className={classes.Listbutton}
                >
                    <ListItemText primary="Español" />
                </ListItemButton>
            </Popover>
        </Box>
    );
};

export default LangButton;
