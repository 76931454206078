import axios from "axios";

/* Script que contiene las llamadas a la API */
const team = {
    // Función para invitar usuario a una empresa o firma
    inviteUser: (params) => {
        const request = axios.post("/inviteUser", params);

        return request.then((response) => response);
    },

    // Función para desvincular un usuario
    unlinkUser: (params) => {
        const request = axios.post("/unlinkUser", params);

        return request.then((response) => response);
    },

    // Función para vincular un usuario
    linkUser: (params) => {
        const request = axios.post("/linkUser", params);

        return request.then((response) => response);
    },

    // Función para transferir propiedad
    transferOwnership: (params) => {
        const request = axios.post("/transferOwnership", params);

        return request.then((response) => response);
    },

    // Función para transferir propiedad
    resendInvitation: (params) => {
        const request = axios.post("/resendInvitation", params);

        return request.then((response) => response);
    },

    // Función para listar todas las invitaciones
    getInvitations: (values) => {
        const request = axios.get("/getInvitations", {
            params: {
                company_id: values.companyID,
                franchise_id: values.franchiseID,
            },
        });

        return request.then((response) => response.data);
    },

    // Función para obtener los permisos del usuario
    getUserCompanyDetails: (values) => {
        const request = axios.get("/getUserCompanyDetails", {
            params: {
                user_id: values.userID,
                company_id: values.companyID,
                franchise_id: values.franchiseID,
            },
        });

        return request.then((response) => response.data);
    },

    // Función para actualizar las invitaciones
    updateInvitation: (params) => {
        const request = axios.post("/updateInvitation", params);

        return request.then((response) => response);
    },

    // Función para anular la invitacion
    cancelInvitation: (params) => {
        const request = axios.post("/cancelInvitation", params);

        return request.then((response) => response);
    },

    // Otorgar permiso a la firma
    grantCompanyAccess: (params) => {
        const request = axios.post("/grantCompanyAccess", params);

        return request.then((response) => response);
    },
};

export default team;
