const quickbooksBIDs = [
    { value: "64491", value2: "64491", value3: "64491", value4: "64491", name: "121 Financial Credit Union (64491)" },
    { value: "69487", value2: "69487", value3: "69487", value4: "69487", name: "121 Financial Credit Union EWC (69487)" },
    { value: "13818", value2: "13818", value3: "13818", value4: "13818", name: "1NB - OK (13818)" },
    { value: "00527", value2: "00527", value3: "00527", value4: "13818", name: "1NB - OK-DC (00527)" },
    { value: "28578", value2: "28578", value3: "28578", value4: "28578", name: "1st Advantage Bank (28578)" },
    { value: "67653", value2: "67653", value3: "67653", value4: "67653", name: "1st Advantage FCU (67653)" },
    { value: "67652", value2: "67652", value3: "67652", value4: "67652", name: "1st Advantage FCU - QB (67652)" },
    { value: "68532", value2: "68532", value3: "68532", value4: "68532", name: "1st Advantage FCU CC (68532)" },
    { value: "31893", value2: "31893", value3: "31893", value4: "31893", name: "1st Bank Yuma (31893)" },
    { value: "54063", value2: "54063", value3: "54063", value4: "54063", name: "1st Bank of Sea Isle City Business (54063)" },
    { value: "53790", value2: "53790", value3: "53790", value4: "53790", name: "1st CNB Upper Sandusky RO (53790)" },
    { value: "14343", value2: "14343", value3: "14343", value4: "14343", name: "1st Capital Bank (14343)" },
    { value: "67139", value2: "67139", value3: "67139", value4: "67139", name: "1st Capital Bank - CA (67139)" },
    { value: "08759", value2: "08759", value3: "08759", value4: "08759", name: "1st Capital Bank - DC (08759)" },
    { value: "09131", value2: "09131", value3: "09131", value4: "09131", name: "1st Capital Bank Biz (09131)" },
    { value: "08762", value2: "08762", value3: "08762", value4: "08762", name: "1st Capital Bank Biz - DC (08762)" },
    { value: "67095", value2: "67095", value3: "67095", value4: "67095", name: "1st Capital Bank Business (67095)" },
    { value: "67137", value2: "67137", value3: "67137", value4: "67137", name: "1st Capital Bank Business Direct (67137)" },
    { value: "67138", value2: "67138", value3: "67138", value4: "67138", name: "1st Capital Bank Direct (67138)" },
    { value: "51513", value2: "51513", value3: "51513", value4: "51513", name: "1st Century Bank-division of MFB (51513)" },
    { value: "10811", value2: "10811", value3: "10811", value4: "10811", name: "1st Colonial Community Bank (10811)" },
    { value: "70012", value2: "70012", value3: "70012", value4: "70012", name: "1st Colonial Community Bank DC (70012)" },
    { value: "14649", value2: "14649", value3: "14649", value4: "14649", name: "1st Community Bank Corpus Christi (14649)" },
    { value: "03974", value2: "03974", value3: "03974", value4: "03974", name: "1st Community Credit Union (03974)" },
    { value: "02343", value2: "02343", value3: "02343", value4: "02343", name: "1st Community FCU (02343)" },
    { value: "67599", value2: "67599", value3: "67599", value4: "67599", name: "1st Fed of Central IL (67599)" },
    { value: "18010", value2: "18010", value3: "18010", value4: "18010", name: "1st MidAmerica CU (18010)" },
    { value: "70480", value2: "70480", value3: "70480", value4: "70480", name: "1st MidAmerica CU - Bus (70480)" },
    { value: "10460", value2: "10460", value3: "10460", value4: "10460", name: "1st National Bank of Lebanon Ohio (10460)" },
    { value: "02305", value2: "02305", value3: "02305", value4: "02305", name: "1st National Bank of Scotia (02305)" },
    { value: "15123", value2: "15123", value3: "15123", value4: "15123", name: "1st Security Bank - Roundup (15123)" },
    { value: "68793", value2: "68793", value3: "68793", value4: "68793", name: "1st Security Bank of WA-QB DC (68793)" },
    { value: "06394", value2: "06394", value3: "06394", value4: "06394", name: "1st Security Bank of Washington-QB (06394)" },
    { value: "14479", value2: "14479", value3: "14479", value4: "14479", name: "1st Source Bank Business Online (14479)" },
    { value: "12734", value2: "12734", value3: "12734", value4: "12734", name: "1st State Bank -MI (12734)" },
    { value: "05054", value2: "05054", value3: "05054", value4: "05054", name: "1st Summit Bank - QB (05054)" },
    { value: "51753", value2: "51753", value3: "51753", value4: "51753", name: "1st United Bank of Faribault (51753)" },
    { value: "67227", value2: "67227", value3: "67227", value4: "67227", name: "1st United Bank of Faribault Bus (67227)" },
    { value: "02452", value2: "02452", value3: "02452", value4: "02452", name: "1st United Services Credit Union (02452)" },
    { value: "12890", value2: "12890", value3: "12890", value4: "12890", name: "1st Valley Credit Union (12890)" },
    { value: "01478", value2: "01478", value3: "01478", value4: "01478", name: "21st Century Bank-North (01478)" },
    { value: "67904", value2: "67904", value3: "67904", value4: "67904", name: "22nd State Bank Business (67904)" },
    { value: "67903", value2: "67903", value3: "67903", value4: "67903", name: "22nd State Bank Retail (67903)" },
    { value: "11089", value2: "11089", value3: "11089", value4: "11089", name: "360 Federal Credit Union (11089)" },
    { value: "62862", value2: "62862", value3: "62862", value4: "62862", name: "3Rivers Credit Card (62862)" },
    { value: "18184", value2: "18184", value3: "18184", value4: "18184", name: "4Front Credit Union (18184)" },
    { value: "07934", value2: "07934", value3: "07934", value4: "18184", name: "4Front Credit Union-QB (07934)" },
    { value: "70184", value2: "70184", value3: "70184", value4: "70184", name: "4u Credit Union (70184)" },
    { value: "05533", value2: "05533", value3: "05533", value4: "05533", name: "5 Star Bank (05533)" },
    { value: "50475", value2: "50475", value3: "50475", value4: "50475", name: "5 Star Bank - BB (50475)" },
    { value: "00485", value2: "00485", value3: "00485", value4: "00485", name: "5 Star Bank QuickBooks (00485)" },
    { value: "67959", value2: "67959", value3: "67959", value4: "67959", name: "5 Star Community CU - WC (67959)" },
    { value: "04133", value2: "04133", value3: "04133", value4: "04133", name: "5Point DC (04133)" },
    { value: "68031", value2: "68031", value3: "68031", value4: "68031", name: "5Point DC Business (68031)" },
    { value: "09344", value2: "09344", value3: "09344", value4: "09344", name: "5Point Web Connect (09344)" },
    { value: "63192", value2: "63192", value3: "63192", value4: "63192", name: "7 17 Credit Union- QuickBooks (63192)" },
    { value: "16125", value2: "16125", value3: "16125", value4: "16125", name: "802 Credit Union (16125)" },
    { value: "69409", value2: "69409", value3: "69409", value4: "16125", name: "802 Credit Union QBDC (69409)" },
    { value: "57096", value2: "57096", value3: "57096", value4: "57096", name: "A+ Federal Credit Union (57096)" },
    { value: "00227", value2: "00227", value3: "00227", value4: "00227", name: "AAA Dollars MasterCard (00227)" },
    { value: "09800", value2: "09800", value3: "09800", value4: "09800", name: "AB&T (09800)" },
    { value: "69361", value2: "69361", value3: "69361", value4: "69361", name: "AB&T Gold-TM (69361)" },
    { value: "69362", value2: "69362", value3: "69362", value4: "69362", name: "AB&T Gold-TM-DC (69362)" },
    { value: "09803", value2: "09803", value3: "09803", value4: "09800", name: "AB&T WC (09803)" },
    { value: "57150", value2: "57150", value3: "57150", value4: "57147", name: "ABCO FCU_QB (57150)" },
    { value: "57147", value2: "57147", value3: "57147", value4: "57147", name: "ABCO Federal Credit Union (57147)" },
    { value: "69964", value2: "69964", value3: "69964", value4: "69964", name: "ABDallas - Business Direct (69964)" },
    { value: "69963", value2: "69963", value3: "69963", value4: "69963", name: "ABDallas - Business WC (69963)" },
    { value: "69657", value2: "69657", value3: "69657", value4: "69657", name: "ABDallas DC (69657)" },
    { value: "69656", value2: "69656", value3: "69656", value4: "69656", name: "ABDallas WC (69656)" },
    { value: "06451", value2: "06451", value3: "06451", value4: "06451", name: "ABNB FCU (06451)" },
    { value: "69480", value2: "69480", value3: "69480", value4: "69480", name: "ABNB FCU CC (69480)" },
    { value: "54669", value2: "54669", value3: "54669", value4: "54669", name: "ACC CentreSuite (54669)" },
    { value: "06071", value2: "06071", value3: "06071", value4: "06071", name: "ACMG Federal Credit Union - QB (06071)" },
    { value: "27408", value2: "27408", value3: "27408", value4: "27408", name: "ACNB Bank (27408)" },
    { value: "18817", value2: "18817", value3: "18817", value4: "18817", name: "ACU-America's Credit Union (18817)" },
    { value: "00519", value2: "00519", value3: "00519", value4: "00519", name: "ACUME Credit Union (00519)" },
    { value: "08270", value2: "08270", value3: "08270", value4: "08270", name: "AERO Federal Credit Union (08270)" },
    { value: "18570", value2: "18570", value3: "18570", value4: "18570", name: "AIM Credit Union (18570)" },
    { value: "04050", value2: "04050", value3: "04050", value4: "04050", name: "ALBANK (04050)" },
    { value: "54378", value2: "54378", value3: "54378", value4: "54378", name: "ALPS FCU (54378)" },
    { value: "05111", value2: "05111", value3: "05111", value4: "05111", name: "AMG National Trust Bank - PFM DC (05111)" },
    { value: "70356", value2: "70356", value3: "70356", value4: "70356", name: "ANB Bank QBWC (70356)" },
    { value: "12344", value2: "12344", value3: "12344", value4: "12344", name: "ANB Credit Card (12344)" },
    { value: "18520", value2: "18520", value3: "18520", value4: "18520", name: "ANBTX Cash Management (18520)" },
    { value: "67676", value2: "67676", value3: "67676", value4: "67676", name: "ANBTX Cash Management DC (67676)" },
    { value: "07135", value2: "07135", value3: "07135", value4: "07135", name: "AOD Federal Credit Union (07135)" },
    { value: "13122", value2: "13122", value3: "13122", value4: "13122", name: "APCI FCU (13122)" },
    { value: "68326", value2: "68326", value3: "68326", value4: "68326", name: "APCO ECU (68326)" },
    { value: "00298", value2: "00298", value3: "00298", value4: "00298", name: "APCU/Center Parc (00298)" },
    { value: "00361", value2: "00361", value3: "00361", value4: "00361", name: "APCU/Center Parc- QB WC (00361)" },
    { value: "18406", value2: "18406", value3: "18406", value4: "18406", name: "APL FCU (18406)" },
    { value: "04897", value2: "04897", value3: "04897", value4: "04897", name: "AT&T Universal Card (04897)" },
    { value: "05973", value2: "05973", value3: "05973", value4: "05973", name: "AVB Bank (05973)" },
    { value: "68369", value2: "68369", value3: "68369", value4: "05973", name: "AVB Bank - DC (68369)" },
    { value: "05150", value2: "05150", value3: "05150", value4: "05132", name: "Abacus Federal Savings Bank-WC (05150)" },
    { value: "00746", value2: "00746", value3: "00746", value4: "00746", name: "Abbeville First Bank - QB (00746)" },
    { value: "67588", value2: "67588", value3: "67588", value4: "67588", name: "AbbyBank Business (67588)" },
    { value: "17529", value2: "17529", value3: "17529", value4: "17529", name: "Aberdeen Federal Credit Union (17529)" },
    { value: "03716", value2: "03716", value3: "03716", value4: "03716", name: "Aberdeen Proving Ground FCU (03716)" },
    { value: "03449", value2: "03449", value3: "03449", value4: "03449", name: "Abington Bank (03449)" },
    { value: "63639", value2: "63639", value3: "63639", value4: "63639", name: "Abington Bank - Business (63639)" },
    { value: "17811", value2: "17811", value3: "17811", value4: "17811", name: "AbleBanking (17811)" },
    { value: "67301", value2: "67301", value3: "67301", value4: "67301", name: "Academy Bank - DC (67301)" },
    { value: "67298", value2: "67298", value3: "67298", value4: "67298", name: "Academy Bank-KC (67298)" },
    { value: "68511", value2: "68511", value3: "68511", value4: "68511", name: "Acadia FCU (68511)" },
    { value: "12698", value2: "12698", value3: "12698", value4: "12698", name: "Acadiana Medical FCU (12698)" },
    { value: "52608", value2: "52608", value3: "52608", value4: "52608", name: "Accentra CU Credit Card (52608)" },
    { value: "55851", value2: "55851", value3: "55851", value4: "55851", name: "Accentra Credit Union QB (55851)" },
    { value: "58785", value2: "58785", value3: "58785", value4: "58785", name: "Access Bank-Business (58785)" },
    { value: "38367", value2: "38367", value3: "38367", value4: "38367", name: "Access Community Credit Union (38367)" },
    { value: "63288", value2: "63288", value3: "63288", value4: "63288", name: "Achieva Credit Union (63288)" },
    { value: "12898", value2: "12898", value3: "12898", value4: "12898", name: "Achieve Financial CU (12898)" },
    { value: "50763", value2: "50763", value3: "50763", value4: "50763", name: "Achieve Online Access Credit Cards (50763)" },
    { value: "04728", value2: "04728", value3: "04728", value4: "04728", name: "Adams Bank and Trust (04728)" },
    { value: "59415", value2: "59415", value3: "59415", value4: "59415", name: "Adams Community Bank (59415)" },
    { value: "59418", value2: "59418", value3: "59418", value4: "59415", name: "Adams Community Bank - DC (59418)" },
    { value: "69994", value2: "69994", value3: "69994", value4: "69994", name: "Adams County Bank (69994)" },
    { value: "69995", value2: "69995", value3: "69995", value4: "69995", name: "Adams County Bank QB WC (69995)" },
    { value: "05906", value2: "05906", value3: "05906", value4: "05906", name: "Adams State Bank (05906)" },
    { value: "02155", value2: "02155", value3: "02155", value4: "02155", name: "Addition Financial (02155)" },
    { value: "49644", value2: "49644", value3: "49644", value4: "49644", name: "AdelFi (49644)" },
    { value: "69893", value2: "69893", value3: "69893", value4: "69893", name: "Adelphi Bank (69893)" },
    { value: "69894", value2: "69894", value3: "69894", value4: "69894", name: "Adelphi Bank Biz (69894)" },
    { value: "69896", value2: "69896", value3: "69896", value4: "69894", name: "Adelphi Bank Biz QBDC (69896)" },
    { value: "69895", value2: "69895", value3: "69895", value4: "69893", name: "Adelphi Bank QBDC (69895)" },
    { value: "58782", value2: "58782", value3: "58782", value4: "58782", name: "Adirondack Bank Utica,NY (58782)" },
    { value: "12170", value2: "12170", value3: "12170", value4: "12170", name: "Adirondack Regional FCU (12170)" },
    { value: "12728", value2: "12728", value3: "12728", value4: "12728", name: "Adirondack Regional Federal CU (12728)" },
    { value: "05629", value2: "05629", value3: "05629", value4: "05629", name: "Adirondack Trust Company (05629)" },
    { value: "69873", value2: "69873", value3: "69873", value4: "69873", name: "Adirondack Trust Company QBWC (69873)" },
    { value: "51891", value2: "51891", value3: "51891", value4: "51891", name: "Adrian Bank (51891)" },
    { value: "67757", value2: "67757", value3: "67757", value4: "67757", name: "Advantage FCU (67757)" },
    { value: "17023", value2: "17023", value3: "17023", value4: "17023", name: "Advantage FCU - QB (17023)" },
    { value: "18915", value2: "18915", value3: "18915", value4: "18915", name: "Advantis Credit Union WC (18915)" },
    { value: "69370", value2: "69370", value3: "69370", value4: "69370", name: "Adventure Credit Union (69370)" },
    { value: "07120", value2: "07120", value3: "07120", value4: "07120", name: "Advia Credit Union (07120)" },
    { value: "67358", value2: "67358", value3: "67358", value4: "67358", name: "Affinity Bank (67358)" },
    { value: "67360", value2: "67360", value3: "67360", value4: "67360", name: "Affinity Bank QBDC (67360)" },
    { value: "00506", value2: "00506", value3: "00506", value4: "00506", name: "Affinity Credit Union (00506)" },
    { value: "18732", value2: "18732", value3: "18732", value4: "18732", name: "Affinity FCU WC QB (18732)" },
    { value: "52194", value2: "52194", value3: "52194", value4: "52194", name: "Affinity Group Credit Union (52194)" },
    { value: "16930", value2: "16930", value3: "16930", value4: "16930", name: "Affinity Plus FCU (16930)" },
    { value: "68852", value2: "68852", value3: "68852", value4: "68852", name: "Ag New Mexico (68852)" },
    { value: "68853", value2: "68853", value3: "68853", value4: "68853", name: "Ag Texas (68853)" },
    { value: "68020", value2: "68020", value3: "68020", value4: "68020", name: "Agility Bank, N.A. (68020)" },
    { value: "04958", value2: "04958", value3: "04958", value4: "04958", name: "Agriculture Federal Credit Union (04958)" },
    { value: "49962", value2: "49962", value3: "49962", value4: "49962", name: "AlaTrust Credit Union (49962)" },
    { value: "68854", value2: "68854", value3: "68854", value4: "68854", name: "Alabama Ag Credit (68854)" },
    { value: "02736", value2: "02736", value3: "02736", value4: "02736", name: "Alabama Central Credit Union (02736)" },
    { value: "01910", value2: "01910", value3: "01910", value4: "01910", name: "Alabama Credit Union (01910)" },
    { value: "62487", value2: "62487", value3: "62487", value4: "62487", name: "Alabama Credit Union-DC (62487)" },
    { value: "68873", value2: "68873", value3: "68873", value4: "68873", name: "Alabama Farm Credit (68873)" },
    { value: "58293", value2: "58293", value3: "58293", value4: "58293", name: "Alabama ONE (58293)" },
    { value: "14010", value2: "14010", value3: "14010", value4: "14010", name: "Alabama One Credit Union (14010)" },
    { value: "18573", value2: "18573", value3: "18573", value4: "18573", name: "Alabama State Employees CU (18573)" },
    { value: "06995", value2: "06995", value3: "06995", value4: "06995", name: "Alabama Teachers Credit Union (06995)" },
    { value: "17925", value2: "17925", value3: "17925", value4: "17925", name: "Alamosa State Bank - QBO (17925)" },
    { value: "05399", value2: "05399", value3: "05399", value4: "05399", name: "Alaska Air Group Credit Union (05399)" },
    { value: "68480", value2: "68480", value3: "68480", value4: "68480", name: "Alcoa Pittsburgh FCU (68480)" },
    { value: "50310", value2: "50310", value3: "50310", value4: "50310", name: "Alcoa Tenn Federal Credit Union (50310)" },
    { value: "70265", value2: "70265", value3: "70265", value4: "70265", name: "Alden State Bank BO (70265)" },
    { value: "70263", value2: "70263", value3: "70263", value4: "70265", name: "Alden State Bank BODC (70263)" },
    { value: "26928", value2: "26928", value3: "26928", value4: "26928", name: "Alden State Bank RO (26928)" },
    { value: "70262", value2: "70262", value3: "70262", value4: "26928", name: "Alden State Bank RODC (70262)" },
    { value: "18787", value2: "18787", value3: "18787", value4: "18787", name: "Alerus - Business (18787)" },
    { value: "05142", value2: "05142", value3: "05142", value4: "05142", name: "Alerus - Personal (05142)" },
    { value: "67759", value2: "67759", value3: "67759", value4: "18787", name: "Alerus QuickBooks DC (67759)" },
    { value: "62985", value2: "62985", value3: "62985", value4: "62985", name: "Algonquin State Bank, N. A. (62985)" },
    { value: "70185", value2: "70185", value3: "70185", value4: "70185", name: "Alhambra CU (70185)" },
    { value: "70186", value2: "70186", value3: "70186", value4: "70186", name: "Alhambra CU - DC (70186)" },
    { value: "01987", value2: "01987", value3: "01987", value4: "01987", name: "Align Credit Union (01987)" },
    { value: "26370", value2: "26370", value3: "26370", value4: "26370", name: "Alive Credit Union (26370)" },
    { value: "19872", value2: "19872", value3: "19872", value4: "19872", name: "All America Bank (19872)" },
    { value: "09972", value2: "09972", value3: "09972", value4: "09972", name: "AllCom Credit Union (09972)" },
    { value: "16327", value2: "16327", value3: "16327", value4: "16327", name: "AllNations Bank (16327)" },
    { value: "16330", value2: "16330", value3: "16330", value4: "16330", name: "AllNations Bank QBDC (16330)" },
    { value: "18583", value2: "18583", value3: "18583", value4: "18583", name: "AllSouth Federal Credit Union (18583)" },
    { value: "69589", value2: "69589", value3: "69589", value4: "69589", name: "Allegacy FCU - Bus (69589)" },
    { value: "10275", value2: "10275", value3: "10275", value4: "10275", name: "Allegacy Federal Credit Union (10275)" },
    { value: "53193", value2: "53193", value3: "53193", value4: "53193", name: "Allegan Credit Union (53193)" },
    { value: "68071", value2: "68071", value3: "68071", value4: "68071", name: "Allegiance Credit Union QB (68071)" },
    { value: "20220", value2: "20220", value3: "20220", value4: "20220", name: "Allegius CU (20220)" },
    { value: "49989", value2: "49989", value3: "49989", value4: "49989", name: "Alliance Bank (49989)" },
    { value: "11340", value2: "11340", value3: "11340", value4: "11340", name: "Alliance Bank & Trust - NC (11340)" },
    { value: "06986", value2: "06986", value3: "06986", value4: "06989", name: "Alliance Bank (TX) (06986)" },
    { value: "04165", value2: "04165", value3: "04165", value4: "04165", name: "Alliance Bank - IN (04165)" },
    { value: "06989", value2: "06989", value3: "06989", value4: "06989", name: "Alliance Bank - TX (06989)" },
    { value: "55668", value2: "55668", value3: "55668", value4: "55668", name: "Alliance Bank Central Texas (55668)" },
    { value: "70222", value2: "70222", value3: "70222", value4: "35781", name: "Alliance Bank of Mondovi QBDC (70222)" },
    { value: "35781", value2: "35781", value3: "35781", value4: "35781", name: "Alliance Bank of Mondovi WI (35781)" },
    { value: "04034", value2: "04034", value3: "04034", value4: "04034", name: "Alliance Bank, MN-Business (04034)" },
    { value: "69474", value2: "69474", value3: "69474", value4: "55668", name: "Alliance Bk Central Texas DC (69474)" },
    { value: "70177", value2: "70177", value3: "70177", value4: "70177", name: "Alliance CU - DC (70177)" },
    { value: "02840", value2: "02840", value3: "02840", value4: "02840", name: "Alliance CU - MO (02840)" },
    { value: "07094", value2: "07094", value3: "07094", value4: "07094", name: "Alliance Catholic Credit Union (07094)" },
    { value: "17586", value2: "17586", value3: "17586", value4: "17586", name: "Alliant Bank (17586)" },
    { value: "04543", value2: "04543", value3: "04543", value4: "04543", name: "Alliant Credit Union (04543)" },
    { value: "10285", value2: "10285", value3: "10285", value4: "10285", name: "Allied First Bank (10285)" },
    { value: "02458", value2: "02458", value3: "02458", value4: "02458", name: "Alltru Credit Union (02458)" },
    { value: "07288", value2: "07288", value3: "07288", value4: "07288", name: "Alltrust Credit Union (07288)" },
    { value: "67184", value2: "67184", value3: "67184", value4: "67184", name: "Allwealth Federal Credit Union (67184)" },
    { value: "58596", value2: "58596", value3: "58596", value4: "58596", name: "Alma Bank BeB (58596)" },
    { value: "04877", value2: "04877", value3: "04877", value4: "04877", name: "Alpine (04877)" },
    { value: "64317", value2: "64317", value3: "64317", value4: "64317", name: "Alpine Bank (64317)" },
    { value: "64320", value2: "64320", value3: "64320", value4: "64317", name: "Alpine Bank - DL (64320)" },
    { value: "68414", value2: "68414", value3: "68414", value4: "68414", name: "Alpine Bank Commercial (68414)" },
    { value: "68415", value2: "68415", value3: "68415", value4: "68414", name: "Alpine Bank Commercial-DC (68415)" },
    { value: "37074", value2: "37074", value3: "37074", value4: "37074", name: "Alpine Capital Bank (37074)" },
    { value: "08627", value2: "08627", value3: "08627", value4: "08627", name: "Alpine Capital Bank DC (08627)" },
    { value: "04267", value2: "04267", value3: "04267", value4: "04267", name: "Alta Vista CU (04267)" },
    { value: "68634", value2: "68634", value3: "68634", value4: "68634", name: "Alta Vista Credit Union - WC (68634)" },
    { value: "69606", value2: "69606", value3: "69606", value4: "69606", name: "Alta Vista Credit Union BB (69606)" },
    { value: "16513", value2: "16513", value3: "16513", value4: "16513", name: "AltaOne Federal Credit Union (16513)" },
    { value: "05108", value2: "05108", value3: "05108", value4: "05108", name: "AltaOne Federal Credit Union CC (05108)" },
    { value: "27855", value2: "27855", value3: "27855", value4: "27855", name: "Altamaha Bank & Trust (27855)" },
    { value: "69876", value2: "69876", value3: "69876", value4: "69876", name: "Altamaha Bank & Trust Company-DC (69876)" },
    { value: "63441", value2: "63441", value3: "63441", value4: "63441", name: "Altana Federal Credit Union (63441)" },
    { value: "13401", value2: "13401", value3: "13401", value4: "13401", name: "Alternatives FCU (13401)" },
    { value: "69703", value2: "69703", value3: "69703", value4: "69703", name: "Altonized Community FCU (69703)" },
    { value: "00197", value2: "00197", value3: "00197", value4: "00197", name: "Altoona Area Emp FCU CC (00197)" },
    { value: "31179", value2: "31179", value3: "31179", value4: "31179", name: "Altoona First Savings Bank (31179)" },
    { value: "09227", value2: "09227", value3: "09227", value4: "31179", name: "Altoona First Savings Bank - QBWC (09227)" },
    { value: "16090", value2: "16090", value3: "16090", value4: "54648", name: "Altra Federal Credit Union (16090)" },
    { value: "54648", value2: "54648", value3: "54648", value4: "54648", name: "Altra Federal Credit Union-QB WC (54648)" },
    { value: "00060", value2: "00060", value3: "00060", value4: "00060", name: "Altura Credit Union (00060)" },
    { value: "09567", value2: "09567", value3: "09567", value4: "09567", name: "Alva State Bank & Trust (09567)" },
    { value: "51129", value2: "51129", value3: "51129", value4: "51129", name: "Amalgamated Bank -Business (51129)" },
    { value: "08240", value2: "08240", value3: "08240", value4: "08240", name: "Amalgamated Bank Bus QB DC (08240)" },
    { value: "69520", value2: "69520", value3: "69520", value4: "69520", name: "Amalgamated Bank of Chicago DC (69520)" },
    { value: "68425", value2: "68425", value3: "68425", value4: "68425", name: "Amalgamated Bank of Chicago-Link (68425)" },
    { value: "04374", value2: "04374", value3: "04374", value4: "04374", name: "Amarillo National Bank (04374)" },
    { value: "67833", value2: "67833", value3: "67833", value4: "67833", name: "Ambler Savings Bank (67833)" },
    { value: "67784", value2: "67784", value3: "67784", value4: "67784", name: "Ambler Savings Bank - DC (67784)" },
    { value: "67711", value2: "67711", value3: "67711", value4: "67711", name: "Amboy Digital Banking (67711)" },
    { value: "13375", value2: "13375", value3: "13375", value4: "13375", name: "Amegy Bank of Texas - Direct (13375)" },
    { value: "01494", value2: "01494", value3: "01494", value4: "01494", name: "Amegy Bank of Texas - WEB (01494)" },
    { value: "03134", value2: "03134", value3: "03134", value4: "03134", name: "Amerant Bank Business WC (03134)" },
    { value: "70267", value2: "70267", value3: "70267", value4: "70267", name: "Amerant Bank DC (70267)" },
    { value: "55578", value2: "55578", value3: "55578", value4: "55578", name: "Amerant Bank Pers WC (55578)" },
    { value: "09971", value2: "09971", value3: "09971", value4: "09971", name: "Amerant Bank-Business-QB Online (09971)" },
    { value: "12674", value2: "12674", value3: "12674", value4: "12674", name: "Amerasia Bank (12674)" },
    { value: "68067", value2: "68067", value3: "68067", value4: "68067", name: "Amerasia Bank DC (68067)" },
    { value: "00954", value2: "00954", value3: "00954", value4: "00954", name: "AmeriCU Credit Union - Q (00954)" },
    { value: "15166", value2: "15166", value3: "15166", value4: "15166", name: "AmeriCU Credit Union WC-QB (15166)" },
    { value: "00771", value2: "00771", value3: "00771", value4: "00771", name: "AmeriChoice FCU (00771)" },
    { value: "53937", value2: "53937", value3: "53937", value4: "53937", name: "AmeriFirst Bank (53937)" },
    { value: "05453", value2: "05453", value3: "05453", value4: "05453", name: "AmeriServ Business Bank (05453)" },
    { value: "04145", value2: "04145", value3: "04145", value4: "04145", name: "AmeriState Bank (04145)" },
    { value: "68060", value2: "68060", value3: "68060", value4: "68060", name: "AmeriState Bank-DC (68060)" },
    { value: "09365", value2: "09365", value3: "09365", value4: "09365", name: "America First Credit Union (09365)" },
    { value: "11898", value2: "11898", value3: "11898", value4: "11898", name: "America's Christian Credit Union (11898)" },
    { value: "17985", value2: "17985", value3: "17985", value4: "17985", name: "America's First FCU (17985)" },
    { value: "25203", value2: "25203", value3: "25203", value4: "25203", name: "American 1 FCU (25203)" },
    { value: "09313", value2: "09313", value3: "09313", value4: "09313", name: "American Airlines AAdvantage (09313)" },
    { value: "63537", value2: "63537", value3: "63537", value4: "63537", name: "American Bank (63537)" },
    { value: "16863", value2: "16863", value3: "16863", value4: "16863", name: "American Bank & Trust (16863)" },
    { value: "63699", value2: "63699", value3: "63699", value4: "63699", name: "American Bank & Trust - BusQB (63699)" },
    { value: "13308", value2: "13308", value3: "13308", value4: "13308", name: "American Bank & Trust -Business (13308)" },
    { value: "06087", value2: "06087", value3: "06087", value4: "06087", name: "American Bank & Trust Co, Inc. (06087)" },
    { value: "51606", value2: "51606", value3: "51606", value4: "51606", name: "American Bank & Trust Company (51606)" },
    { value: "53166", value2: "53166", value3: "53166", value4: "53166", name: "American Bank & Trust Company, OK (53166)" },
    { value: "64695", value2: "64695", value3: "64695", value4: "64692", name: "American Bank & Trust Company-DC (64695)" },
    { value: "64692", value2: "64692", value3: "64692", value4: "64692", name: "American Bank - LA (64692)" },
    { value: "01781", value2: "01781", value3: "01781", value4: "01781", name: "American Bank BO (01781)" },
    { value: "06608", value2: "06608", value3: "06608", value4: "06608", name: "American Bank Business - Direct (06608)" },
    { value: "06605", value2: "06605", value3: "06605", value4: "06605", name: "American Bank Business - Web (06605)" },
    { value: "12976", value2: "12976", value3: "12976", value4: "12976", name: "American Bank IB - QB (12976)" },
    { value: "05262", value2: "05262", value3: "05262", value4: "05262", name: "American Bank of Commerce (05262)" },
    { value: "09651", value2: "09651", value3: "09651", value4: "09651", name: "American Bank of Montana (09651)" },
    { value: "20286", value2: "20286", value3: "20286", value4: "20286", name: "American Bank of Oklahoma (20286)" },
    { value: "03113", value2: "03113", value3: "03113", value4: "03113", name: "American Bank- QBWC (03113)" },
    { value: "16603", value2: "16603", value3: "16603", value4: "16603", name: "American Broadcast EFCU Q (16603)" },
    { value: "16606", value2: "16606", value3: "16606", value4: "16606", name: "American Broadcast EFCU QBooks (16606)" },
    { value: "03929", value2: "03929", value3: "03929", value4: "03929", name: "American BusBank DC (03929)" },
    { value: "05584", value2: "05584", value3: "05584", value4: "05584", name: "American Business Bank Business (05584)" },
    { value: "68273", value2: "68273", value3: "68273", value4: "68273", name: "American Com Bk of IN (68273)" },
    { value: "68274", value2: "68274", value3: "68274", value4: "68274", name: "American Com Bk of IN - DC (68274)" },
    { value: "68275", value2: "68275", value3: "68275", value4: "68275", name: "American Com Bk of IN Biz (68275)" },
    { value: "68276", value2: "68276", value3: "68276", value4: "68276", name: "American Com Bk of IN Biz - DC (68276)" },
    { value: "64464", value2: "64464", value3: "64464", value4: "64464", name: "American Commerce BK (64464)" },
    { value: "64467", value2: "64467", value3: "64467", value4: "64464", name: "American Commerce BK - DL (64467)" },
    { value: "67059", value2: "67059", value3: "67059", value4: "67059", name: "American Commercial B&T - OLB (67059)" },
    { value: "67646", value2: "67646", value3: "67646", value4: "67646", name: "American Commercial B&T DC (67646)" },
    { value: "57996", value2: "57996", value3: "57996", value4: "57996", name: "American Community Bank & Trust (57996)" },
    { value: "06551", value2: "06551", value3: "06551", value4: "06551", name: "American Community Bank NY (06551)" },
    { value: "52572", value2: "52572", value3: "52572", value4: "52572", name: "American Exchange Bank (52572)" },
    { value: "68370", value2: "68370", value3: "68370", value4: "68370", name: "American Exchange Bank - DC (68370)" },
    { value: "68368", value2: "68368", value3: "68368", value4: "68368", name: "American Exchange Bank - OK (68368)" },
    { value: "03101", value2: "03101", value3: "03101", value4: "03101", name: "American Express (03101)" },
    { value: "69906", value2: "69906", value3: "69906", value4: "69906", name: "American Express Delegate (69906)" },
    { value: "70199", value2: "70199", value3: "70199", value4: "70199", name: "American Express QBDT (70199)" },
    { value: "03106", value2: "03106", value3: "03106", value4: "03101", name: "American Express Web Connect (03106)" },
    { value: "51078", value2: "51078", value3: "51078", value4: "51078", name: "American Federal Bank ND (51078)" },
    { value: "11103", value2: "11103", value3: "11103", value4: "11103", name: "American First CU Brea CA (11103)" },
    { value: "19161", value2: "19161", value3: "19161", value4: "19161", name: "American First National Bank (19161)" },
    { value: "03413", value2: "03413", value3: "03413", value4: "03413", name: "American First National Bank-QB (03413)" },
    { value: "01445", value2: "01445", value3: "01445", value4: "01445", name: "American Heritage Bank (01445)" },
    { value: "00996", value2: "00996", value3: "00996", value4: "00996", name: "American Heritage FCU (00996)" },
    { value: "59196", value2: "59196", value3: "59196", value4: "59196", name: "American Interstate Bank -Business (59196)" },
    { value: "17941", value2: "17941", value3: "17941", value4: "17941", name: "American Momentum Bank (17941)" },
    { value: "17950", value2: "17950", value3: "17950", value4: "17941", name: "American Momentum Bank - QBWC (17950)" },
    { value: "67764", value2: "67764", value3: "67764", value4: "67764", name: "American National Bank NE - DC (67764)" },
    { value: "67765", value2: "67765", value3: "67765", value4: "67765", name: "American National Bank NE - WC (67765)" },
    { value: "12677", value2: "12677", value3: "12677", value4: "12677", name: "American National Bank WF Bus (12677)" },
    { value: "08022", value2: "08022", value3: "08022", value4: "08022", name: "American National Bank WF Per (08022)" },
    { value: "12686", value2: "12686", value3: "12686", value4: "12686", name: "American National Bank WF Per QBDC (12686)" },
    { value: "12680", value2: "12680", value3: "12680", value4: "12677", name: "American National Bank WF QBDC (12680)" },
    { value: "09531", value2: "09531", value3: "09531", value4: "09531", name: "American National Bank of Minn (09531)" },
    { value: "16486", value2: "16486", value3: "16486", value4: "16486", name: "American National Bank of TX (16486)" },
    { value: "17914", value2: "17914", value3: "17914", value4: "17914", name: "American National Bank of TX DC (17914)" },
    { value: "08816", value2: "08816", value3: "08816", value4: "08816", name: "American National Bank, FL (08816)" },
    { value: "14949", value2: "14949", value3: "14949", value4: "14949", name: "American National Bank, FL - WEB (14949)" },
    { value: "68664", value2: "68664", value3: "68664", value4: "68664", name: "American National Bank-AMNB (68664)" },
    { value: "68665", value2: "68665", value3: "68665", value4: "68665", name: "American National Bank-AMNB-DC (68665)" },
    { value: "50880", value2: "50880", value3: "50880", value4: "50880", name: "American National Bank-Ardmore (50880)" },
    { value: "08315", value2: "08315", value3: "08315", value4: "10936", name: "American National Bank-DC (08315)" },
    { value: "10936", value2: "10936", value3: "10936", value4: "10936", name: "American National Bank-Danville (10936)" },
    { value: "68563", value2: "68563", value3: "68563", value4: "68563", name: "American National Bank-TM (68563)" },
    { value: "68564", value2: "68564", value3: "68564", value4: "68563", name: "American National Bank-TM-DC (68564)" },
    { value: "67559", value2: "67559", value3: "67559", value4: "67559", name: "American National Bk-Personal (67559)" },
    { value: "53109", value2: "53109", value3: "53109", value4: "53109", name: "American National-Mt Pleasant (53109)" },
    { value: "68219", value2: "68219", value3: "68219", value4: "68219", name: "American Natl Bank-Personal-DC (68219)" },
    { value: "04424", value2: "04424", value3: "04424", value4: "04424", name: "American Pride Bank (04424)" },
    { value: "07298", value2: "07298", value3: "07298", value4: "07298", name: "American Pride Bank DC (07298)" },
    { value: "68081", value2: "68081", value3: "68081", value4: "53076", name: "American Riviera Bank DC (68081)" },
    { value: "70230", value2: "70230", value3: "70230", value4: "70230", name: "American Riviera Business DC (70230)" },
    { value: "70228", value2: "70228", value3: "70228", value4: "70228", name: "American Riviera Business WC (70228)" },
    { value: "53076", value2: "53076", value3: "53076", value4: "53076", name: "American Riviera WC (53076)" },
    { value: "65394", value2: "65394", value3: "65394", value4: "65394", name: "American Savings Bank - Hawaii (65394)" },
    { value: "16197", value2: "16197", value3: "16197", value4: "16197", name: "American State Bank (16197)" },
    { value: "04814", value2: "04814", value3: "04814", value4: "04814", name: "American State Bank & Trust, ND (04814)" },
    { value: "18762", value2: "18762", value3: "18762", value4: "18762", name: "American State Bank - TX (18762)" },
    { value: "05648", value2: "05648", value3: "05648", value4: "05648", name: "American State Bank - TX-DL (05648)" },
    { value: "36702", value2: "36702", value3: "36702", value4: "36702", name: "American State Bank of Grygla (36702)" },
    { value: "09485", value2: "09485", value3: "09485", value4: "09485", name: "American State Bank of Grygla - DC (09485)" },
    { value: "05210", value2: "05210", value3: "05210", value4: "05210", name: "American State Bank-IA-QB (05210)" },
    { value: "07226", value2: "07226", value3: "07226", value4: "07226", name: "American United FCU (07226)" },
    { value: "12296", value2: "12296", value3: "12296", value4: "12296", name: "AmericanBank Online Biz (12296)" },
    { value: "04152", value2: "04152", value3: "04152", value4: "04152", name: "Americana Community Bank (04152)" },
    { value: "69691", value2: "69691", value3: "69691", value4: "69691", name: "Ameris Bank - GA (69691)" },
    { value: "16791", value2: "16791", value3: "16791", value4: "16791", name: "Ameris Bank BIB (16791)" },
    { value: "70420", value2: "70420", value3: "70420", value4: "69691", name: "Ameris Bank QBWC (70420)" },
    { value: "03128", value2: "03128", value3: "03128", value4: "03128", name: "Amistad Bank (03128)" },
    { value: "14619", value2: "14619", value3: "14619", value4: "14619", name: "Amplify CU (14619)" },
    { value: "05199", value2: "05199", value3: "05199", value4: "05199", name: "Anahuac National Bank (05199)" },
    { value: "04694", value2: "04694", value3: "04694", value4: "05199", name: "Anahuac National Bank-DC (04694)" },
    { value: "59151", value2: "59151", value3: "59151", value4: "59151", name: "Anchor Bank (59151)" },
    { value: "05555", value2: "05555", value3: "05555", value4: "05555", name: "Anchor Bank - BeB DC (05555)" },
    { value: "64752", value2: "64752", value3: "64752", value4: "64752", name: "Anchor Bank Credit Card (64752)" },
    { value: "17325", value2: "17325", value3: "17325", value4: "17325", name: "Anchor D Bank (17325)" },
    { value: "68096", value2: "68096", value3: "68096", value4: "68096", name: "Anchor State Bank (68096)" },
    { value: "04178", value2: "04178", value3: "04178", value4: "18810", name: "Anderson Brothers  Bank - DC (04178)" },
    { value: "18810", value2: "18810", value3: "18810", value4: "18810", name: "Anderson Brothers Bank (18810)" },
    { value: "25416", value2: "25416", value3: "25416", value4: "25416", name: "Andover State Bank (25416)" },
    { value: "12140", value2: "12140", value3: "12140", value4: "12140", name: "Andover State Bank - QB DC (12140)" },
    { value: "20016", value2: "20016", value3: "20016", value4: "20016", name: "Andrew Johnson Bank (20016)" },
    { value: "06380", value2: "06380", value3: "06380", value4: "06380", name: "Andrews Federal Credit Union (06380)" },
    { value: "12596", value2: "12596", value3: "12596", value4: "12596", name: "Andrews Federal Credit Union-QBDC (12596)" },
    { value: "09921", value2: "09921", value3: "09921", value4: "09921", name: "Androscoggin Bank (09921)" },
    { value: "05474", value2: "05474", value3: "05474", value4: "09921", name: "Androscoggin Bank QB DC (05474)" },
    { value: "63114", value2: "63114", value3: "63114", value4: "63114", name: "Aneca Federal Credit Union (63114)" },
    { value: "31575", value2: "31575", value3: "31575", value4: "31575", name: "Angelina Savings Bank (31575)" },
    { value: "17794", value2: "17794", value3: "17794", value4: "17794", name: "Anoka Hennepin CU - QuickBooks (17794)" },
    { value: "12267", value2: "12267", value3: "12267", value4: "12267", name: "Anstaff Bank - Personal (12267)" },
    { value: "00743", value2: "00743", value3: "00743", value4: "00743", name: "Anstaff Bank Credit Card (00743)" },
    { value: "04413", value2: "04413", value3: "04413", value4: "04413", name: "Anthem Bank & Trust (04413)" },
    { value: "68899", value2: "68899", value3: "68899", value4: "68899", name: "Antwerp Exchange Bank Business (68899)" },
    { value: "09565", value2: "09565", value3: "09565", value4: "09565", name: "Anytown Bank-CA New (09565)" },
    { value: "06158", value2: "06158", value3: "06158", value4: "06158", name: "Apex Bank (06158)" },
    { value: "06161", value2: "06161", value3: "06161", value4: "06161", name: "Apex Bank Web Connect QuickBooks (06161)" },
    { value: "14274", value2: "14274", value3: "14274", value4: "14274", name: "Apollo Trust Company - Personal Banking (14274)" },
    { value: "09881", value2: "09881", value3: "09881", value4: "09881", name: "Appalachian Community FCU (09881)" },
    { value: "18940", value2: "18940", value3: "18940", value4: "18940", name: "Apple Card WC (18940)" },
    { value: "16543", value2: "16543", value3: "16543", value4: "16543", name: "Apple FCU (16543)" },
    { value: "16110", value2: "16110", value3: "16110", value4: "16110", name: "Apple River State Bank (16110)" },
    { value: "67973", value2: "67973", value3: "67973", value4: "67973", name: "Arbor Bank-Arbor Direct Dir Conn (67973)" },
    { value: "62874", value2: "62874", value3: "62874", value4: "62874", name: "Arbor Bank-Arbor Direct Web Conn (62874)" },
    { value: "15549", value2: "15549", value3: "15549", value4: "15549", name: "Arbor Bank-Std Online Banking (15549)" },
    { value: "41904", value2: "41904", value3: "41904", value4: "41904", name: "Arbor Financial Credit Union (41904)" },
    { value: "03784", value2: "03784", value3: "03784", value4: "03784", name: "Arcadian Bank (03784)" },
    { value: "09392", value2: "09392", value3: "09392", value4: "09392", name: "Arcadian Bank - QBDC (09392)" },
    { value: "02999", value2: "02999", value3: "02999", value4: "02999", name: "Arcola First Bank (02999)" },
    { value: "67244", value2: "67244", value3: "67244", value4: "67244", name: "Argent FCU Web Connect QB (67244)" },
    { value: "17838", value2: "17838", value3: "17838", value4: "17838", name: "Arize Federal Credit Union (17838)" },
    { value: "51669", value2: "51669", value3: "51669", value4: "51669", name: "Arizona Bank & Trust (51669)" },
    { value: "08200", value2: "08200", value3: "08200", value4: "08200", name: "Arizona Central CU IB WC QBO (08200)" },
    { value: "67971", value2: "67971", value3: "67971", value4: "67971", name: "Arizona Financial CU Business (67971)" },
    { value: "11179", value2: "11179", value3: "11179", value4: "11179", name: "Arizona Financial Credit Union (11179)" },
    { value: "08839", value2: "08839", value3: "08839", value4: "08839", name: "Arizona Financial Credit Union-QB (08839)" },
    { value: "17176", value2: "17176", value3: "17176", value4: "17176", name: "Arkansas Best FCU -New (17176)" },
    { value: "17179", value2: "17179", value3: "17179", value4: "17176", name: "Arkansas Best FCU QB - New (17179)" },
    { value: "50532", value2: "50532", value3: "50532", value4: "50532", name: "Arkansas Federal Credit Union (50532)" },
    { value: "39309", value2: "39309", value3: "39309", value4: "39309", name: "Arkansas Superior FCU (39309)" },
    { value: "69779", value2: "69779", value3: "69779", value4: "39309", name: "Arkansas Superior FCU QBDC (69779)" },
    { value: "16483", value2: "16483", value3: "16483", value4: "16483", name: "Arlington Community Federal CU (16483)" },
    { value: "03497", value2: "03497", value3: "03497", value4: "03497", name: "Arlington State Bank MN (03497)" },
    { value: "68430", value2: "68430", value3: "68430", value4: "03497", name: "Arlington State Bank QBDC (68430)" },
    { value: "11518", value2: "11518", value3: "11518", value4: "11518", name: "Armed Forces Bank (11518)" },
    { value: "67302", value2: "67302", value3: "67302", value4: "67302", name: "Armed Forces Bank - DC (67302)" },
    { value: "12092", value2: "12092", value3: "12092", value4: "12092", name: "Armor Bank BO (12092)" },
    { value: "70337", value2: "70337", value3: "70337", value4: "12092", name: "Armor Bank BODC (70337)" },
    { value: "69445", value2: "69445", value3: "69445", value4: "69445", name: "Armor Bank RO (69445)" },
    { value: "70336", value2: "70336", value3: "70336", value4: "70336", name: "Armor Bank RODC (70336)" },
    { value: "01429", value2: "01429", value3: "01429", value4: "01429", name: "Armstrong Bank (01429)" },
    { value: "15085", value2: "15085", value3: "15085", value4: "15085", name: "Aroostook County Federal S&L (15085)" },
    { value: "68028", value2: "68028", value3: "68028", value4: "68028", name: "Arrowhead Bank - QBDC (68028)" },
    { value: "05249", value2: "05249", value3: "05249", value4: "05249", name: "Arrowhead Bank, Texas (05249)" },
    { value: "00616", value2: "00616", value3: "00616", value4: "00616", name: "Arrowhead Credit Union (00616)" },
    { value: "00442", value2: "00442", value3: "00442", value4: "00442", name: "Arsenal Credit Union (00442)" },
    { value: "08906", value2: "08906", value3: "08906", value4: "08906", name: "Artesia CU (08906)" },
    { value: "68147", value2: "68147", value3: "68147", value4: "68147", name: "Arthur State Bank (68147)" },
    { value: "06086", value2: "06086", value3: "06086", value4: "06086", name: "Arthur State Bank Credit Card (06086)" },
    { value: "68148", value2: "68148", value3: "68148", value4: "68147", name: "Arthur State Bank WC (68148)" },
    { value: "02050", value2: "02050", value3: "02050", value4: "02050", name: "Artisans' Bank (02050)" },
    { value: "70382", value2: "70382", value3: "70382", value4: "70382", name: "Arundel Federal Savings Bank WC (70382)" },
    { value: "70505", value2: "70505", value3: "70505", value4: "70505", name: "Arundel Federal Savings Bank-DC (70505)" },
    { value: "69457", value2: "69457", value3: "69457", value4: "69457", name: "Arvest Bank - EWC (69457)" },
    { value: "08019", value2: "08019", value3: "08019", value4: "08019", name: "Arvest Bank - Web Connect (08019)" },
    { value: "04294", value2: "04294", value3: "04294", value4: "04294", name: "Ascend Federal Credit Union - IB (04294)" },
    { value: "15427", value2: "15427", value3: "15427", value4: "15427", name: "Ascendia Bank (15427)" },
    { value: "17395", value2: "17395", value3: "17395", value4: "17395", name: "Ascent Bank-QB (17395)" },
    { value: "01576", value2: "01576", value3: "01576", value4: "01576", name: "Ascent Credit Union (01576)" },
    { value: "67459", value2: "67459", value3: "67459", value4: "67459", name: "Ascentra CU (67459)" },
    { value: "67460", value2: "67460", value3: "67460", value4: "67460", name: "Ascentra CU QB (67460)" },
    { value: "26718", value2: "26718", value3: "26718", value4: "26718", name: "Ashland Credit Union (26718)" },
    { value: "68942", value2: "68942", value3: "68942", value4: "68942", name: "Ashland Credit Union - QB (68942)" },
    { value: "67993", value2: "67993", value3: "67993", value4: "67993", name: "Asian Bank (67993)" },
    { value: "67994", value2: "67994", value3: "67994", value4: "67993", name: "Asian Bank - DC (67994)" },
    { value: "09221", value2: "09221", value3: "09221", value4: "09221", name: "Aspen Federal Credit Union (09221)" },
    { value: "05114", value2: "05114", value3: "05114", value4: "05114", name: "Aspen Federal Credit Union - CC (05114)" },
    { value: "68801", value2: "68801", value3: "68801", value4: "68801", name: "Aspire Community FCU CC (68801)" },
    { value: "07073", value2: "07073", value3: "07073", value4: "07073", name: "Aspire Financial (07073)" },
    { value: "07076", value2: "07076", value3: "07076", value4: "07073", name: "Aspire Financial - DC (07076)" },
    { value: "07177", value2: "07177", value3: "07177", value4: "07177", name: "Assemblies of God Credit Union (07177)" },
    { value: "02440", value2: "02440", value3: "02440", value4: "02440", name: "Associated Bank (02440)" },
    { value: "20106", value2: "20106", value3: "20106", value4: "20106", name: "Associated Credit Union of Texas (20106)" },
    { value: "67778", value2: "67778", value3: "67778", value4: "67778", name: "Associated School Employees CU (67778)" },
    { value: "53658", value2: "53658", value3: "53658", value4: "53658", name: "Astera Credit Union (53658)" },
    { value: "17640", value2: "17640", value3: "17640", value4: "17640", name: "Astra Bank (17640)" },
    { value: "67286", value2: "67286", value3: "67286", value4: "67286", name: "Athol Credit Union Business (67286)" },
    { value: "06499", value2: "06499", value3: "06499", value4: "06499", name: "Athol Savings Bank BB WC QB (06499)" },
    { value: "70110", value2: "70110", value3: "70110", value4: "70110", name: "Athol Savings Bank QB DC (70110)" },
    { value: "68422", value2: "68422", value3: "68422", value4: "68422", name: "Atlanta Postal Credit Union CC (68422)" },
    { value: "49434", value2: "49434", value3: "49434", value4: "49434", name: "Atlantic City Federal CU (49434)" },
    { value: "54999", value2: "54999", value3: "54999", value4: "54999", name: "Atlantic FCU (54999)" },
    { value: "68005", value2: "68005", value3: "68005", value4: "68005", name: "Atlantic Financial FCU QB (68005)" },
    { value: "21279", value2: "21279", value3: "21279", value4: "21279", name: "Atlantic Financial Federal Credit Union (21279)" },
    { value: "06173", value2: "06173", value3: "06173", value4: "06173", name: "Atlantic Union Bank Business DC (06173)" },
    { value: "06170", value2: "06170", value3: "06170", value4: "06170", name: "Atlantic Union Bank Business WC (06170)" },
    { value: "10778", value2: "10778", value3: "10778", value4: "10778", name: "Atlantic Union Bank Personal DC (10778)" },
    { value: "57708", value2: "57708", value3: "57708", value4: "57708", name: "Atlantic Union Bank Personal WC (57708)" },
    { value: "07277", value2: "07277", value3: "07277", value4: "07277", name: "Atomic Credit Union (07277)" },
    { value: "01313", value2: "01313", value3: "01313", value4: "01313", name: "Auburn Bank (01313)" },
    { value: "05615", value2: "05615", value3: "05615", value4: "05615", name: "Auburn Banking Co KY (05615)" },
    { value: "67531", value2: "67531", value3: "67531", value4: "67531", name: "Auburn Savings Bank (67531)" },
    { value: "67532", value2: "67532", value3: "67532", value4: "67531", name: "Auburn Savings Bank QBDC (67532)" },
    { value: "56697", value2: "56697", value3: "56697", value4: "56697", name: "Auburn State Bank (56697)" },
    { value: "55785", value2: "55785", value3: "55785", value4: "55785", name: "Audubon State Bank (IA) (55785)" },
    { value: "11017", value2: "11017", value3: "11017", value4: "11017", name: "Austin Bank Texas (11017)" },
    { value: "12753", value2: "12753", value3: "12753", value4: "12753", name: "Austin Capital Bank SSB (12753)" },
    { value: "63711", value2: "63711", value3: "63711", value4: "63711", name: "Austin Capital Bank SSB QB (63711)" },
    { value: "50247", value2: "50247", value3: "50247", value4: "50247", name: "Austin County (50247)" },
    { value: "00912", value2: "00912", value3: "00912", value4: "00912", name: "Austin Telco Federal Credit Union (00912)" },
    { value: "04866", value2: "04866", value3: "04866", value4: "04866", name: "Autotruck Financial Credit Union (04866)" },
    { value: "11495", value2: "11495", value3: "11495", value4: "11495", name: "Avadian Credit Union (11495)" },
    { value: "57063", value2: "57063", value3: "57063", value4: "57063", name: "Availa Bank (57063)" },
    { value: "09158", value2: "09158", value3: "09158", value4: "09158", name: "Availa Bank Cash Management (09158)" },
    { value: "68619", value2: "68619", value3: "68619", value4: "68619", name: "Availa Bank Cash Mgmt QB DC (68619)" },
    { value: "68547", value2: "68547", value3: "68547", value4: "68547", name: "Availa Bank Online Banking QB DC (68547)" },
    { value: "17266", value2: "17266", value3: "17266", value4: "17266", name: "Avenir Financial FCU - QB (17266)" },
    { value: "03023", value2: "03023", value3: "03023", value4: "03023", name: "Aventa Credit Union (03023)" },
    { value: "67659", value2: "67659", value3: "67659", value4: "67659", name: "Avestar Credit Union (67659)" },
    { value: "11707", value2: "11707", value3: "11707", value4: "11707", name: "Avidbank (11707)" },
    { value: "02163", value2: "02163", value3: "02163", value4: "02163", name: "Avidia Bank - Business Express (02163)" },
    { value: "54003", value2: "54003", value3: "54003", value4: "54003", name: "Awakon Federal CU (54003)" },
    { value: "64758", value2: "64758", value3: "64758", value4: "64758", name: "Axiom Bank (64758)" },
    { value: "67179", value2: "67179", value3: "67179", value4: "67179", name: "Axiom Bank DC (67179)" },
    { value: "05747", value2: "05747", value3: "05747", value4: "05747", name: "Axos Bank Business DC (05747)" },
    { value: "53793", value2: "53793", value3: "53793", value4: "53793", name: "Axos Bank Business WC (53793)" },
    { value: "16164", value2: "16164", value3: "16164", value4: "16164", name: "Azura Credit Union (16164)" },
    { value: "11020", value2: "11020", value3: "11020", value4: "11020", name: "BAC Community Bank (11020)" },
    { value: "03582", value2: "03582", value3: "03582", value4: "03582", name: "BANK - IA (03582)" },
    { value: "69193", value2: "69193", value3: "69193", value4: "69193", name: "BANK360 (69193)" },
    { value: "69531", value2: "69531", value3: "69531", value4: "69531", name: "BAYCEL (69531)" },
    { value: "05798", value2: "05798", value3: "05798", value4: "05798", name: "BB Americas - BeB (05798)" },
    { value: "07498", value2: "07498", value3: "07498", value4: "07498", name: "BB&T - Credit Card Connection (07498)" },
    { value: "09621", value2: "09621", value3: "09621", value4: "09621", name: "BB&T Bankcard & Merchant Services (09621)" },
    { value: "00780", value2: "00780", value3: "00780", value4: "00780", name: "BCB Community Bank (00780)" },
    { value: "69971", value2: "69971", value3: "69971", value4: "00780", name: "BCB Community Bank DC (69971)" },
    { value: "07799", value2: "07799", value3: "07799", value4: "07799", name: "BCBank (07799)" },
    { value: "69988", value2: "69988", value3: "69988", value4: "69988", name: "BCBank QBDC (69988)" },
    { value: "06940", value2: "06940", value3: "06940", value4: "06940", name: "BCU (Baxter Credit Union) (06940)" },
    { value: "68141", value2: "68141", value3: "68141", value4: "68141", name: "BECU Online Business (68141)" },
    { value: "68140", value2: "68140", value3: "68140", value4: "68140", name: "BECU Online Business QBDC (68140)" },
    { value: "09476", value2: "09476", value3: "09476", value4: "09476", name: "BHCU (09476)" },
    { value: "68500", value2: "68500", value3: "68500", value4: "68500", name: "BHI (68500)" },
    { value: "68124", value2: "68124", value3: "68124", value4: "68124", name: "BLC Community Bank (68124)" },
    { value: "11467", value2: "11467", value3: "11467", value4: "11467", name: "BMI FCU (11467)" },
    { value: "17392", value2: "17392", value3: "17392", value4: "17392", name: "BMO Financial Group (17392)" },
    { value: "69508", value2: "69508", value3: "69508", value4: "69508", name: "BMO Harris Bank EWC (69508)" },
    { value: "17614", value2: "17614", value3: "17614", value4: "17614", name: "BMO U.S. (17614)" },
    { value: "54093", value2: "54093", value3: "54093", value4: "54093", name: "BMO U.S. - Credit Cards (54093)" },
    { value: "53742", value2: "53742", value3: "53742", value4: "53742", name: "BMO U.S. - OLBB (53742)" },
    { value: "09931", value2: "09931", value3: "09931", value4: "09931", name: "BMO U.S. - Retail, SB (09931)" },
    { value: "53436", value2: "53436", value3: "53436", value4: "53436", name: "BNA Bank (53436)" },
    { value: "04771", value2: "04771", value3: "04771", value4: "04771", name: "BNC National Bank (04771)" },
    { value: "70120", value2: "70120", value3: "70120", value4: "70120", name: "BNC National Bank-Bus (70120)" },
    { value: "70121", value2: "70121", value3: "70121", value4: "70121", name: "BNC National Bank-Bus-DC (70121)" },
    { value: "07478", value2: "07478", value3: "07478", value4: "07478", name: "BNC National Bank-DC (07478)" },
    { value: "18489", value2: "18489", value3: "18489", value4: "18489", name: "BNY Mellon - BeB (18489)" },
    { value: "18585", value2: "18585", value3: "18585", value4: "18585", name: "BNY Mellon -CeB (18585)" },
    { value: "10907", value2: "10907", value3: "10907", value4: "10907", name: "BOK Financial (10907)" },
    { value: "12024", value2: "12024", value3: "12024", value4: "12024", name: "BOK Financial - formerly Mobank (12024)" },
    { value: "01413", value2: "01413", value3: "01413", value4: "01413", name: "BOK Financial-Bank of Arizona (01413)" },
    { value: "10433", value2: "10433", value3: "10433", value4: "10433", name: "BOK Financial-Bank of Arkansas (10433)" },
    { value: "69397", value2: "69397", value3: "69397", value4: "69397", name: "BOKF NA Commercial (69397)" },
    { value: "53520", value2: "53520", value3: "53520", value4: "53520", name: "BOND Community FCU (53520)" },
    { value: "04593", value2: "04593", value3: "04593", value4: "04593", name: "BP Federal Credit Union (04593)" },
    { value: "70201", value2: "70201", value3: "70201", value4: "04593", name: "BP Federal Credit Union - DC (70201)" },
    { value: "17706", value2: "17706", value3: "17706", value4: "17706", name: "BTC Bank (17706)" },
    { value: "69368", value2: "69368", value3: "69368", value4: "69368", name: "BTC Bank-TM (69368)" },
    { value: "16626", value2: "16626", value3: "16626", value4: "16626", name: "Badger Bank (16626)" },
    { value: "03542", value2: "03542", value3: "03542", value4: "03542", name: "Baker Boyer Bank - DL (03542)" },
    { value: "51765", value2: "51765", value3: "51765", value4: "51765", name: "Baker Boyer Bank - NetTeller (51765)" },
    { value: "53733", value2: "53733", value3: "53733", value4: "53733", name: "Baldwin State Bank (53733)" },
    { value: "00484", value2: "00484", value3: "00484", value4: "00484", name: "Ballston Spa National Bank (00484)" },
    { value: "08795", value2: "08795", value3: "08795", value4: "08795", name: "Baltic State Bank (08795)" },
    { value: "08798", value2: "08798", value3: "08798", value4: "08795", name: "Baltic State Bank - QBWC (08798)" },
    { value: "11489", value2: "11489", value3: "11489", value4: "11489", name: "Banc of California (11489)" },
    { value: "15129", value2: "15129", value3: "15129", value4: "11489", name: "Banc of California - Direct (15129)" },
    { value: "67505", value2: "67505", value3: "67505", value4: "67505", name: "Banc of California Business QBDC (67505)" },
    { value: "18688", value2: "18688", value3: "18688", value4: "18688", name: "Banc of California Business WC (18688)" },
    { value: "68661", value2: "68661", value3: "68661", value4: "68661", name: "BancCentral Business (68661)" },
    { value: "03281", value2: "03281", value3: "03281", value4: "03281", name: "BancCentral Credit Card (03281)" },
    { value: "10459", value2: "10459", value3: "10459", value4: "10459", name: "BancFirst (10459)" },
    { value: "03344", value2: "03344", value3: "03344", value4: "03344", name: "BancFirst Business (03344)" },
    { value: "08779", value2: "08779", value3: "08779", value4: "08779", name: "BancFirst QB (08779)" },
    { value: "51732", value2: "51732", value3: "51732", value4: "51732", name: "Banco Popular Puerto Rico (51732)" },
    { value: "67597", value2: "67597", value3: "67597", value4: "67597", name: "Banco Popular de Puerto Rico (67597)" },
    { value: "69460", value2: "69460", value3: "69460", value4: "69460", name: "Banco Popular de Puerto Rico EWC (69460)" },
    { value: "15508", value2: "15508", value3: "15508", value4: "15508", name: "Banco Popular de Puerto Rico PR (15508)" },
    { value: "50661", value2: "50661", value3: "50661", value4: "50661", name: "Bancorp - Business (50661)" },
    { value: "67280", value2: "67280", value3: "67280", value4: "67280", name: "BancorpSouth Commercial Center (67280)" },
    { value: "09474", value2: "09474", value3: "09474", value4: "09474", name: "Bandera Bank (09474)" },
    { value: "09515", value2: "09515", value3: "09515", value4: "09515", name: "Banesco USA (09515)" },
    { value: "09518", value2: "09518", value3: "09518", value4: "09515", name: "Banesco USA - Direct (09518)" },
    { value: "12628", value2: "12628", value3: "12628", value4: "12628", name: "Bangor Savings - Direct (12628)" },
    { value: "53265", value2: "53265", value3: "53265", value4: "53265", name: "Bangor Savings - Treasury WCQB (53265)" },
    { value: "12631", value2: "12631", value3: "12631", value4: "12631", name: "Bangor Savings - Web (12631)" },
    { value: "70477", value2: "70477", value3: "70477", value4: "70477", name: "Bangor Savings Bank Bus CC (70477)" },
    { value: "69568", value2: "69568", value3: "69568", value4: "69568", name: "Bangor Savings Bank CC (69568)" },
    { value: "04357", value2: "04357", value3: "04357", value4: "04357", name: "Bank & Trust Company WC (04357)" },
    { value: "68143", value2: "68143", value3: "68143", value4: "68143", name: "Bank 1st (68143)" },
    { value: "06093", value2: "06093", value3: "06093", value4: "06093", name: "Bank 21 (06093)" },
    { value: "07930", value2: "07930", value3: "07930", value4: "07930", name: "Bank 34 -IB Quicken-Quickbooks DC (07930)" },
    { value: "69635", value2: "69635", value3: "69635", value4: "69635", name: "Bank 34 Business DC (69635)" },
    { value: "07933", value2: "07933", value3: "07933", value4: "07933", name: "Bank 34 WC BB QuickBooks (07933)" },
    { value: "70148", value2: "70148", value3: "70148", value4: "70148", name: "Bank Central - Bus (70148)" },
    { value: "12003", value2: "12003", value3: "12003", value4: "12003", name: "Bank First (12003)" },
    { value: "59070", value2: "59070", value3: "59070", value4: "59070", name: "Bank First National Credit Card (59070)" },
    { value: "19617", value2: "19617", value3: "19617", value4: "19617", name: "Bank Five Nine (19617)" },
    { value: "67098", value2: "67098", value3: "67098", value4: "67098", name: "Bank Five Nine Business DC (67098)" },
    { value: "67099", value2: "67099", value3: "67099", value4: "67099", name: "Bank Five Nine Business WC (67099)" },
    { value: "67519", value2: "67519", value3: "67519", value4: "67519", name: "Bank Forward (67519)" },
    { value: "07676", value2: "07676", value3: "07676", value4: "07676", name: "Bank Hillsboro (07676)" },
    { value: "68644", value2: "68644", value3: "68644", value4: "68644", name: "Bank Hillsboro QBDC (68644)" },
    { value: "05294", value2: "05294", value3: "05294", value4: "05294", name: "Bank Independent (05294)" },
    { value: "05237", value2: "05237", value3: "05237", value4: "05237", name: "Bank Independent - DC (05237)" },
    { value: "68348", value2: "68348", value3: "68348", value4: "68348", name: "Bank Independent - Treasury (68348)" },
    { value: "68349", value2: "68349", value3: "68349", value4: "68348", name: "Bank Independent - Treasury - DC (68349)" },
    { value: "69751", value2: "69751", value3: "69751", value4: "69751", name: "Bank Irvine (69751)" },
    { value: "69752", value2: "69752", value3: "69752", value4: "69751", name: "Bank Irvine QBDC (69752)" },
    { value: "50403", value2: "50403", value3: "50403", value4: "50403", name: "Bank Michigan (50403)" },
    { value: "64026", value2: "64026", value3: "64026", value4: "64026", name: "Bank Midwest BeB (64026)" },
    { value: "68477", value2: "68477", value3: "68477", value4: "68477", name: "Bank Midwest Business (68477)" },
    { value: "68478", value2: "68478", value3: "68478", value4: "68478", name: "Bank Midwest Business QBDC (68478)" },
    { value: "68475", value2: "68475", value3: "68475", value4: "68475", name: "Bank Midwest Personal (68475)" },
    { value: "68476", value2: "68476", value3: "68476", value4: "68476", name: "Bank Midwest Personal QBDC (68476)" },
    { value: "05963", value2: "05963", value3: "05963", value4: "05963", name: "Bank OZK (05963)" },
    { value: "16066", value2: "16066", value3: "16066", value4: "16066", name: "Bank OZK - Business (16066)" },
    { value: "59205", value2: "59205", value3: "59205", value4: "59205", name: "Bank OZK- Business (59205)" },
    { value: "12654", value2: "12654", value3: "12654", value4: "12654", name: "Bank Of The Rockies, NA (12654)" },
    { value: "15201", value2: "15201", value3: "15201", value4: "15201", name: "Bank Pacific, Ltd (15201)" },
    { value: "03881", value2: "03881", value3: "03881", value4: "03881", name: "Bank Rhode Island (03881)" },
    { value: "11703", value2: "11703", value3: "11703", value4: "11703", name: "Bank Rhode Island WebConnect (11703)" },
    { value: "05369", value2: "05369", value3: "05369", value4: "05369", name: "Bank Rhode Island WebConnect-CM (05369)" },
    { value: "03347", value2: "03347", value3: "03347", value4: "03347", name: "Bank at United Commercial (03347)" },
    { value: "16057", value2: "16057", value3: "16057", value4: "16057", name: "Bank at United WC (16057)" },
    { value: "52569", value2: "52569", value3: "52569", value4: "52569", name: "Bank of Abbeville & Trust Company (52569)" },
    { value: "69519", value2: "69519", value3: "69519", value4: "69519", name: "Bank of Advance (69519)" },
    { value: "09197", value2: "09197", value3: "09197", value4: "09197", name: "Bank of Akron Business (09197)" },
    { value: "09200", value2: "09200", value3: "09200", value4: "09200", name: "Bank of Akron Business DC (09200)" },
    { value: "09191", value2: "09191", value3: "09191", value4: "09191", name: "Bank of Akron Consumer (09191)" },
    { value: "10431", value2: "10431", value3: "10431", value4: "10431", name: "Bank of Albuquerque N.A. (10431)" },
    { value: "58887", value2: "58887", value3: "58887", value4: "58887", name: "Bank of Alma (58887)" },
    { value: "05358", value2: "00000", value3: "05358", value4: "05358", name: "Bank of America (05358)" },
    { value: "54111", value2: "54111", value3: "54111", value4: "54111", name: "Bank of America - CashPro (54111)" },
    { value: "69546", value2: "69546", value3: "69546", value4: "69546", name: "Bank of America - New (69546)" },
    { value: "11162", value2: "11162", value3: "05358", value4: "06526", name: "Bank of America - QB WebConnect (11162)" },
    { value: "03813", value2: "03813", value3: "03813", value4: "03813", name: "Bank of Ann Arbor (03813)" },
    { value: "04226", value2: "04226", value3: "04226", value4: "04226", name: "Bank of Ann Arbor QBDC (04226)" },
    { value: "09624", value2: "09624", value3: "09624", value4: "09624", name: "Bank of Bartlett (09624)" },
    { value: "64836", value2: "64836", value3: "64836", value4: "64836", name: "Bank of Bartlett - Business (64836)" },
    { value: "03081", value2: "03081", value3: "03081", value4: "03081", name: "Bank of Beaver City (03081)" },
    { value: "04180", value2: "04180", value3: "04180", value4: "04180", name: "Bank of Belle Glade (04180)" },
    { value: "09665", value2: "09665", value3: "09665", value4: "09665", name: "Bank of Belleville (09665)" },
    { value: "12632", value2: "12632", value3: "12632", value4: "09665", name: "Bank of Belleville - QBDC (12632)" },
    { value: "12014", value2: "12014", value3: "12014", value4: "12014", name: "Bank of Bird-in-Hand (12014)" },
    { value: "12017", value2: "12017", value3: "12017", value4: "12014", name: "Bank of Bird-in-Hand WC (12017)" },
    { value: "15204", value2: "15204", value3: "15204", value4: "15204", name: "Bank of Blue Valley (15204)" },
    { value: "21282", value2: "21282", value3: "21282", value4: "21282", name: "Bank of Botetourt (21282)" },
    { value: "63573", value2: "63573", value3: "63573", value4: "63573", name: "Bank of Botetourt Credit Card (63573)" },
    { value: "09600", value2: "09600", value3: "09600", value4: "09600", name: "Bank of Bozeman (09600)" },
    { value: "16698", value2: "16698", value3: "16698", value4: "16698", name: "Bank of Brenham, NA (16698)" },
    { value: "03392", value2: "03392", value3: "03392", value4: "03392", name: "Bank of Brewton (03392)" },
    { value: "58101", value2: "58101", value3: "58101", value4: "58101", name: "Bank of Bridger, N.A. (58101)" },
    { value: "67259", value2: "67259", value3: "67259", value4: "67259", name: "Bank of Brodhead (67259)" },
    { value: "34752", value2: "34752", value3: "34752", value4: "34752", name: "Bank of Brookhaven (34752)" },
    { value: "69672", value2: "69672", value3: "69672", value4: "69672", name: "Bank of Buffalo - KY (69672)" },
    { value: "69561", value2: "69561", value3: "69561", value4: "69561", name: "Bank of Burlington (69561)" },
    { value: "69562", value2: "69562", value3: "69562", value4: "69561", name: "Bank of Burlington DC (69562)" },
    { value: "17208", value2: "17208", value3: "17208", value4: "17208", name: "Bank of Cadiz & Trust Co. (17208)" },
    { value: "57075", value2: "57075", value3: "57075", value4: "57075", name: "Bank of Canton, MA (57075)" },
    { value: "06959", value2: "06959", value3: "06959", value4: "06959", name: "Bank of Cave City (06959)" },
    { value: "13380", value2: "13380", value3: "13380", value4: "13380", name: "Bank of Central Florida (13380)" },
    { value: "14241", value2: "14241", value3: "14241", value4: "14241", name: "Bank of Central Florida BIB (14241)" },
    { value: "67603", value2: "67603", value3: "67603", value4: "67603", name: "Bank of Charles Town (67603)" },
    { value: "68102", value2: "68102", value3: "68102", value4: "68102", name: "Bank of Charles Town - Business (68102)" },
    { value: "07406", value2: "07406", value3: "07406", value4: "07403", name: "Bank of Charles Town QBWC (07406)" },
    { value: "09926", value2: "09926", value3: "09926", value4: "09926", name: "Bank of Charlotte County (09926)" },
    { value: "18396", value2: "18396", value3: "18396", value4: "18396", name: "Bank of Clarke (18396)" },
    { value: "68237", value2: "68237", value3: "68237", value4: "68237", name: "Bank of Clarke BO QBDC (68237)" },
    { value: "04187", value2: "04187", value3: "04187", value4: "04187", name: "Bank of Clarkson KY (04187)" },
    { value: "21192", value2: "21192", value3: "21192", value4: "21192", name: "Bank of Cleveland (21192)" },
    { value: "15946", value2: "15946", value3: "15946", value4: "15946", name: "Bank of Colorado BO (15946)" },
    { value: "15949", value2: "15949", value3: "15949", value4: "15946", name: "Bank of Colorado BODC (15949)" },
    { value: "68178", value2: "68178", value3: "68178", value4: "68177", name: "Bank of Colorado Biz Premium (68178)" },
    { value: "68177", value2: "68177", value3: "68177", value4: "68177", name: "Bank of Colorado Bus Premium (68177)" },
    { value: "11662", value2: "11662", value3: "11662", value4: "11662", name: "Bank of Colorado RO (11662)" },
    { value: "15943", value2: "15943", value3: "15943", value4: "11662", name: "Bank of Colorado RO DC (15943)" },
    { value: "70306", value2: "70306", value3: "70306", value4: "70306", name: "Bank of Columbia BO (70306)" },
    { value: "12346", value2: "12346", value3: "12346", value4: "12346", name: "Bank of Commerce & Trust Co. (12346)" },
    { value: "50619", value2: "50619", value3: "50619", value4: "50619", name: "Bank of Commerce & Trust IB QBs (50619)" },
    { value: "67786", value2: "67786", value3: "67786", value4: "67786", name: "Bank of Commerce - Duncan OK (67786)" },
    { value: "67787", value2: "67787", value3: "67787", value4: "67787", name: "Bank of Commerce - Duncan OK Bus (67787)" },
    { value: "64887", value2: "64887", value3: "64887", value4: "64887", name: "Bank of Commerce - Greenwood, MS (64887)" },
    { value: "14439", value2: "14439", value3: "14439", value4: "14439", name: "Bank of Commerce - Oklahoma (14439)" },
    { value: "08294", value2: "08294", value3: "08294", value4: "08294", name: "Bank of Commerce QB DC (08294)" },
    { value: "05721", value2: "05721", value3: "05721", value4: "05721", name: "Bank of Crocker (05721)" },
    { value: "55917", value2: "55917", value3: "55917", value4: "55917", name: "Bank of Dade (55917)" },
    { value: "19812", value2: "19812", value3: "19812", value4: "19812", name: "Bank of DeSoto (19812)" },
    { value: "53736", value2: "53736", value3: "53736", value4: "53736", name: "Bank of Deerfield (53736)" },
    { value: "02882", value2: "02882", value3: "02882", value4: "02882", name: "Bank of Deerfield Credit Card (02882)" },
    { value: "11165", value2: "11165", value3: "11165", value4: "11165", name: "Bank of Denver (11165)" },
    { value: "09251", value2: "09251", value3: "09251", value4: "11165", name: "Bank of Denver Direct (09251)" },
    { value: "05782", value2: "05782", value3: "05782", value4: "05782", name: "Bank of Dickson (05782)" },
    { value: "68936", value2: "68936", value3: "68936", value4: "68936", name: "Bank of Dudley DC BB (68936)" },
    { value: "52734", value2: "52734", value3: "52734", value4: "52734", name: "Bank of Dudley QB (52734)" },
    { value: "04927", value2: "04927", value3: "04927", value4: "04927", name: "Bank of Eastern Oregon (04927)" },
    { value: "04248", value2: "04248", value3: "04248", value4: "04248", name: "Bank of Edmonson County (04248)" },
    { value: "06323", value2: "06323", value3: "06323", value4: "06323", name: "Bank of Erath (06323)" },
    { value: "08416", value2: "08416", value3: "08416", value4: "08416", name: "Bank of Estes Park (08416)" },
    { value: "34938", value2: "34938", value3: "34938", value4: "34938", name: "Bank of Evergreen (34938)" },
    { value: "56601", value2: "56601", value3: "56601", value4: "34938", name: "Bank of Evergreen - DC (56601)" },
    { value: "09989", value2: "09989", value3: "09989", value4: "09989", name: "Bank of Farmington (09989)" },
    { value: "64134", value2: "64134", value3: "64134", value4: "64134", name: "Bank of Forest (64134)" },
    { value: "32226", value2: "32226", value3: "32226", value4: "32226", name: "Bank of Frankewing TN (32226)" },
    { value: "12020", value2: "12020", value3: "12020", value4: "12020", name: "Bank of Franklin (12020)" },
    { value: "62217", value2: "62217", value3: "62217", value4: "62217", name: "Bank of Franklin County - MO (62217)" },
    { value: "68837", value2: "68837", value3: "68837", value4: "68837", name: "Bank of Franklin County QBDC (68837)" },
    { value: "14802", value2: "14802", value3: "14802", value4: "14802", name: "Bank of Glen Burnie (14802)" },
    { value: "02169", value2: "02169", value3: "02169", value4: "02169", name: "Bank of Grand Lake - OK (02169)" },
    { value: "54684", value2: "54684", value3: "54684", value4: "54684", name: "Bank of Guam - SF ifit CA (54684)" },
    { value: "54681", value2: "54681", value3: "54681", value4: "54681", name: "Bank of Guam ifit (54681)" },
    { value: "69881", value2: "69881", value3: "69881", value4: "69881", name: "Bank of Guam ifit QB DC (69881)" },
    { value: "02411", value2: "02411", value3: "02411", value4: "02411", name: "Bank of Halls Business (02411)" },
    { value: "02414", value2: "02414", value3: "02414", value4: "02414", name: "Bank of Halls Retail (02414)" },
    { value: "19908", value2: "19908", value3: "19908", value4: "19908", name: "Bank of Hartington (19908)" },
    { value: "68560", value2: "68560", value3: "68560", value4: "68560", name: "Bank of Hartington - Business (68560)" },
    { value: "70347", value2: "70347", value3: "70347", value4: "70347", name: "Bank of Hawaii DC (70347)" },
    { value: "03920", value2: "03920", value3: "03920", value4: "03920", name: "Bank of Hawaii WC (03920)" },
    { value: "09436", value2: "09436", value3: "09436", value4: "09436", name: "Bank of Hays (09436)" },
    { value: "26091", value2: "26091", value3: "26091", value4: "26091", name: "Bank of Hazlehurst (26091)" },
    { value: "67196", value2: "67196", value3: "67196", value4: "67196", name: "Bank of Herrin QBDC (67196)" },
    { value: "01112", value2: "01112", value3: "01112", value4: "01112", name: "Bank of Holland (01112)" },
    { value: "63435", value2: "63435", value3: "63435", value4: "63435", name: "Bank of Holly Springs CC (63435)" },
    { value: "68247", value2: "68247", value3: "68247", value4: "68247", name: "Bank of Holyrood (68247)" },
    { value: "12987", value2: "12987", value3: "12987", value4: "12987", name: "Bank of Hope (12987)" },
    { value: "09242", value2: "09242", value3: "09242", value4: "09242", name: "Bank of Hope - Business Direct (09242)" },
    { value: "51345", value2: "51345", value3: "51345", value4: "51345", name: "Bank of Hope - Corporate Online (51345)" },
    { value: "17635", value2: "17635", value3: "17635", value4: "17635", name: "Bank of Hope -DC (17635)" },
    { value: "69389", value2: "69389", value3: "69389", value4: "69389", name: "Bank of Hope CC (69389)" },
    { value: "68509", value2: "68509", value3: "68509", value4: "68509", name: "Bank of Houston DC Business (68509)" },
    { value: "68792", value2: "68792", value3: "68792", value4: "68792", name: "Bank of Houston Personal (68792)" },
    { value: "68508", value2: "68508", value3: "68508", value4: "68508", name: "Bank of Houston WC Business (68508)" },
    { value: "58512", value2: "58512", value3: "58512", value4: "58512", name: "Bank of Hydro (58512)" },
    { value: "51393", value2: "51393", value3: "51393", value4: "51393", name: "Bank of Iberia (51393)" },
    { value: "08354", value2: "08354", value3: "08354", value4: "08354", name: "Bank of Idaho Business (08354)" },
    { value: "00606", value2: "00606", value3: "00606", value4: "00606", name: "Bank of Idaho Online Banking (00606)" },
    { value: "02223", value2: "02223", value3: "02223", value4: "02223", name: "Bank of Jackson Hole (02223)" },
    { value: "69773", value2: "69773", value3: "69773", value4: "69773", name: "Bank of Jackson Hole - Bus (69773)" },
    { value: "69908", value2: "69908", value3: "69908", value4: "69908", name: "Bank of Jamestown - KY (69908)" },
    { value: "54231", value2: "54231", value3: "54231", value4: "54231", name: "Bank of Labor - Business (54231)" },
    { value: "16290", value2: "16290", value3: "16290", value4: "16290", name: "Bank of Lake Mills (16290)" },
    { value: "12575", value2: "12575", value3: "12575", value4: "12575", name: "Bank of Lake Mills DC (12575)" },
    { value: "05966", value2: "05966", value3: "05966", value4: "05966", name: "Bank of Lake Village (05966)" },
    { value: "67760", value2: "67760", value3: "67760", value4: "67760", name: "Bank of Lake Village Business (67760)" },
    { value: "67211", value2: "67211", value3: "67211", value4: "67211", name: "Bank of Lewellen (67211)" },
    { value: "56754", value2: "56754", value3: "56754", value4: "56754", name: "Bank of Lexington (56754)" },
    { value: "49854", value2: "49854", value3: "49854", value4: "49854", name: "Bank of Lincoln County-TN (49854)" },
    { value: "67707", value2: "67707", value3: "67707", value4: "67707", name: "Bank of Lindsay (67707)" },
    { value: "67708", value2: "67708", value3: "67708", value4: "67708", name: "Bank of Lindsay QBDC (67708)" },
    { value: "01697", value2: "01697", value3: "01697", value4: "01697", name: "Bank of Little Rock (01697)" },
    { value: "70099", value2: "70099", value3: "70099", value4: "70099", name: "Bank of Locust Grove - DC (70099)" },
    { value: "70098", value2: "70098", value3: "70098", value4: "70098", name: "Bank of Locust Grove - WC (70098)" },
    { value: "62142", value2: "62142", value3: "62142", value4: "62142", name: "Bank of Lumber City (62142)" },
    { value: "53382", value2: "53382", value3: "53382", value4: "53382", name: "Bank of Madison (53382)" },
    { value: "70372", value2: "70372", value3: "70372", value4: "70372", name: "Bank of Madison - DC (70372)" },
    { value: "00521", value2: "00521", value3: "00521", value4: "00521", name: "Bank of Maple Plain (00521)" },
    { value: "00524", value2: "00524", value3: "00524", value4: "00524", name: "Bank of Maple Plain-DC (00524)" },
    { value: "00727", value2: "00727", value3: "00727", value4: "00727", name: "Bank of Marin (00727)" },
    { value: "06739", value2: "06739", value3: "06739", value4: "06739", name: "Bank of Marin - OLB (06739)" },
    { value: "24348", value2: "24348", value3: "24348", value4: "24348", name: "Bank of Mauston WI (24348)" },
    { value: "27906", value2: "27906", value3: "27906", value4: "27906", name: "Bank of Maysville (27906)" },
    { value: "68686", value2: "68686", value3: "68686", value4: "68686", name: "Bank of Maysville QB (68686)" },
    { value: "51159", value2: "51159", value3: "51159", value4: "51159", name: "Bank of Millbrook (51159)" },
    { value: "05318", value2: "05318", value3: "05318", value4: "05318", name: "Bank of Millbrook Credit Card (05318)" },
    { value: "67517", value2: "67517", value3: "67517", value4: "67517", name: "Bank of Monroe (67517)" },
    { value: "57555", value2: "57555", value3: "57555", value4: "57555", name: "Bank of Montana-Missoula (57555)" },
    { value: "21285", value2: "21285", value3: "21285", value4: "21285", name: "Bank of Montgomery (21285)" },
    { value: "58395", value2: "58395", value3: "58395", value4: "58395", name: "Bank of New England - Salem, NH (58395)" },
    { value: "14703", value2: "14703", value3: "14703", value4: "14703", name: "Bank of New Hampshire (14703)" },
    { value: "04193", value2: "04193", value3: "04193", value4: "04193", name: "Bank of New Hampshire - QB (04193)" },
    { value: "03248", value2: "03248", value3: "03248", value4: "03248", name: "Bank of Newington (03248)" },
    { value: "09311", value2: "09311", value3: "09311", value4: "09311", name: "Bank of O'Fallon IL (09311)" },
    { value: "03015", value2: "03015", value3: "03015", value4: "03015", name: "Bank of Oak Ridge (03015)" },
    { value: "68903", value2: "68903", value3: "68903", value4: "68903", name: "Bank of Oak Ridge - LA (68903)" },
    { value: "12653", value2: "12653", value3: "12653", value4: "12653", name: "Bank of Oak Ridge Biz (12653)" },
    { value: "12656", value2: "12656", value3: "12656", value4: "12653", name: "Bank of Oak Ridge Biz DC (12656)" },
    { value: "70395", value2: "70395", value3: "70395", value4: "70395", name: "Bank of Oak Ridge Bus CC (70395)" },
    { value: "02891", value2: "02891", value3: "02891", value4: "02891", name: "Bank of Oak Ridge Credit Card (02891)" },
    { value: "18282", value2: "18282", value3: "18282", value4: "18282", name: "Bank of Ocean City (18282)" },
    { value: "68221", value2: "68221", value3: "68221", value4: "68221", name: "Bank of Odessa MO (68221)" },
    { value: "68337", value2: "68337", value3: "68337", value4: "68337", name: "Bank of Odessa MO QBDC (68337)" },
    { value: "10432", value2: "10432", value3: "10432", value4: "10432", name: "Bank of Oklahoma N.A. (10432)" },
    { value: "06373", value2: "06373", value3: "06373", value4: "06373", name: "Bank of Old Monroe - BB WC for QB (06373)" },
    { value: "50796", value2: "50796", value3: "50796", value4: "50796", name: "Bank of Pontiac-IL (50796)" },
    { value: "68993", value2: "68993", value3: "68993", value4: "68993", name: "Bank of Prague (68993)" },
    { value: "68994", value2: "68994", value3: "68994", value4: "68994", name: "Bank of Prague- DC (68994)" },
    { value: "12609", value2: "12609", value3: "12609", value4: "12609", name: "Bank of Prairie Village (12609)" },
    { value: "69402", value2: "69402", value3: "69402", value4: "69402", name: "Bank of Prairie du Sac Bus (69402)" },
    { value: "69403", value2: "69403", value3: "69403", value4: "69403", name: "Bank of Prairie du Sac Bus QBDC (69403)" },
    { value: "03424", value2: "03424", value3: "03424", value4: "03424", name: "Bank of Prairie du Sac Pers (03424)" },
    { value: "69401", value2: "69401", value3: "69401", value4: "69401", name: "Bank of Prairie du Sac Pers QBDC (69401)" },
    { value: "56970", value2: "56970", value3: "56970", value4: "56970", name: "Bank of Rantoul (56970)" },
    { value: "57711", value2: "57711", value3: "57711", value4: "57711", name: "Bank of Romney (57711)" },
    { value: "09293", value2: "09293", value3: "09293", value4: "09293", name: "Bank of Southern CA Bus DC (09293)" },
    { value: "07337", value2: "07337", value3: "07337", value4: "07337", name: "Bank of Southern CA Bus WC (07337)" },
    { value: "14991", value2: "14991", value3: "14991", value4: "14991", name: "Bank of Southern CA Per (14991)" },
    { value: "54402", value2: "54402", value3: "54402", value4: "14991", name: "Bank of Southern CA Per QBDC (54402)" },
    { value: "70426", value2: "70426", value3: "70426", value4: "70426", name: "Bank of Springfield Bus CC (70426)" },
    { value: "19629", value2: "19629", value3: "19629", value4: "19629", name: "Bank of Springfield-eBusiness (19629)" },
    { value: "67203", value2: "67203", value3: "67203", value4: "67203", name: "Bank of Springfield-eBusiness DC (67203)" },
    { value: "03543", value2: "03543", value3: "03543", value4: "03543", name: "Bank of St. Francisville (03543)" },
    { value: "06466", value2: "06466", value3: "06466", value4: "06466", name: "Bank of Star Valley (06466)" },
    { value: "70503", value2: "70503", value3: "70503", value4: "70503", name: "Bank of Steinauer (70503)" },
    { value: "16681", value2: "16681", value3: "16681", value4: "16681", name: "Bank of Stockton (16681)" },
    { value: "16897", value2: "16897", value3: "16897", value4: "16681", name: "Bank of Stockton -QB (16897)" },
    { value: "50727", value2: "50727", value3: "50727", value4: "50727", name: "Bank of Sullivan (50727)" },
    { value: "17610", value2: "17610", value3: "17610", value4: "17610", name: "Bank of Sun Prairie (17610)" },
    { value: "65199", value2: "65199", value3: "65199", value4: "65199", name: "Bank of Sun Prairie CC (65199)" },
    { value: "35292", value2: "35292", value3: "35292", value4: "35292", name: "Bank of Sunset and Trust Company (35292)" },
    { value: "10544", value2: "10544", value3: "10544", value4: "10544", name: "Bank of Tampa (10544)" },
    { value: "02523", value2: "02523", value3: "02523", value4: "02523", name: "Bank of Tennessee (02523)" },
    { value: "05570", value2: "05570", value3: "05570", value4: "05570", name: "Bank of Texas (05570)" },
    { value: "10430", value2: "10430", value3: "10430", value4: "10430", name: "Bank of Texas N.A. (10430)" },
    { value: "04906", value2: "04906", value3: "04906", value4: "04906", name: "Bank of Travelers Rest - Personal (04906)" },
    { value: "59211", value2: "59211", value3: "59211", value4: "59211", name: "Bank of Travelers Rest Credit Card (59211)" },
    { value: "64770", value2: "64770", value3: "64770", value4: "64770", name: "Bank of Utah (64770)" },
    { value: "02897", value2: "02897", value3: "02897", value4: "02897", name: "Bank of Utah QB (02897)" },
    { value: "02843", value2: "02843", value3: "02843", value4: "02843", name: "Bank of Versailles - MO (02843)" },
    { value: "02846", value2: "02846", value3: "02846", value4: "02843", name: "Bank of Versailles - MO DC (02846)" },
    { value: "50388", value2: "50388", value3: "50388", value4: "50388", name: "Bank of Washington - MO (50388)" },
    { value: "67674", value2: "67674", value3: "67674", value4: "67674", name: "Bank of Washington -DC (67674)" },
    { value: "33921", value2: "33921", value3: "33921", value4: "33921", name: "Bank of Western Oklahoma OK (33921)" },
    { value: "68444", value2: "68444", value3: "68444", value4: "68444", name: "Bank of Wiggins (68444)" },
    { value: "03527", value2: "03527", value3: "03527", value4: "03416", name: "Bank of Winnfield & Trust - WC (03527)" },
    { value: "03416", value2: "03416", value3: "03416", value4: "03416", name: "Bank of Winnfield & Trust Company (03416)" },
    { value: "03149", value2: "03149", value3: "03149", value4: "03149", name: "Bank of Wisconsin Dells Business (03149)" },
    { value: "17634", value2: "17634", value3: "17634", value4: "17634", name: "Bank of Yazoo (17634)" },
    { value: "09224", value2: "09224", value3: "09224", value4: "09224", name: "Bank of Yazoo Credit Card (09224)" },
    { value: "34326", value2: "34326", value3: "34326", value4: "34326", name: "Bank of York (34326)" },
    { value: "56004", value2: "56004", value3: "56004", value4: "56004", name: "Bank of Zachary (56004)" },
    { value: "09945", value2: "09945", value3: "09945", value4: "09945", name: "Bank of the Bluegrass & Trust Co (09945)" },
    { value: "09305", value2: "09305", value3: "09305", value4: "09305", name: "Bank of the Bluegrass Trust Co_DC (09305)" },
    { value: "07461", value2: "07461", value3: "07461", value4: "07461", name: "Bank of the Flint Hills (07461)" },
    { value: "12657", value2: "12657", value3: "12657", value4: "12657", name: "Bank of the James - VA (12657)" },
    { value: "67614", value2: "67614", value3: "67614", value4: "67614", name: "Bank of the James Bus DC (67614)" },
    { value: "55812", value2: "55812", value3: "55812", value4: "12657", name: "Bank of the James Direct (55812)" },
    { value: "13776", value2: "13776", value3: "13776", value4: "13776", name: "Bank of the Lowcountry (13776)" },
    { value: "62277", value2: "62277", value3: "62277", value4: "62277", name: "Bank of the Lowcountry Business (62277)" },
    { value: "02619", value2: "02619", value3: "02619", value4: "02619", name: "Bank of the Pacific (02619)" },
    { value: "67644", value2: "67644", value3: "67644", value4: "02619", name: "Bank of the Pacific - WA (67644)" },
    { value: "12975", value2: "12975", value3: "12975", value4: "12975", name: "Bank of the Panhandle (12975)" },
    { value: "56409", value2: "56409", value3: "56409", value4: "56409", name: "Bank of the Plains (56409)" },
    { value: "10488", value2: "10488", value3: "10488", value4: "10488", name: "Bank of the Sierra (10488)" },
    { value: "63609", value2: "63609", value3: "63609", value4: "63609", name: "Bank of the Sierra - DL (63609)" },
    { value: "70264", value2: "70264", value3: "70264", value4: "70264", name: "Bank of the Sierra Business (70264)" },
    { value: "70266", value2: "70266", value3: "70266", value4: "70264", name: "Bank of the Sierra Business-DC (70266)" },
    { value: "57192", value2: "57192", value3: "57192", value4: "57192", name: "Bank of the Southwest (NM) (57192)" },
    { value: "16704", value2: "16704", value3: "16704", value4: "16704", name: "Bank of the Valley (16704)" },
    { value: "12015", value2: "12015", value3: "12015", value4: "12015", name: "Bank of the West - DFW (12015)" },
    { value: "00889", value2: "00889", value3: "00889", value4: "00889", name: "Bank of the West - WebDirect (00889)" },
    { value: "68363", value2: "68363", value3: "68363", value4: "68363", name: "Bank of the West Treasury (68363)" },
    { value: "18712", value2: "18712", value3: "18712", value4: "18712", name: "Bank-Fund Staff FCU - DC (18712)" },
    { value: "18718", value2: "18718", value3: "18718", value4: "18718", name: "Bank-Fund Staff Federal CU WC (18718)" },
    { value: "00950", value2: "00950", value3: "00950", value4: "00950", name: "Bank3 (00950)" },
    { value: "06122", value2: "06122", value3: "06122", value4: "06122", name: "Bank3 - DC (06122)" },
    { value: "69194", value2: "69194", value3: "69194", value4: "69193", name: "Bank360 QBDC (69194)" },
    { value: "15648", value2: "15648", value3: "15648", value4: "15648", name: "Bank7 (15648)" },
    { value: "65055", value2: "65055", value3: "65055", value4: "65055", name: "Bank7 Business Direct (65055)" },
    { value: "65058", value2: "65058", value3: "65058", value4: "65058", name: "Bank7 Personal Direct (65058)" },
    { value: "49440", value2: "49440", value3: "49440", value4: "49440", name: "BankAtlantic BOB (49440)" },
    { value: "06410", value2: "06410", value3: "06410", value4: "06410", name: "BankChampaign (06410)" },
    { value: "11919", value2: "11919", value3: "11919", value4: "11919", name: "BankCherokee (11919)" },
    { value: "08465", value2: "08465", value3: "08465", value4: "08465", name: "BankCherokee Business (08465)" },
    { value: "10215", value2: "10215", value3: "10215", value4: "10215", name: "BankFinancial (10215)" },
    { value: "67660", value2: "67660", value3: "67660", value4: "67660", name: "BankFinancial Business DC (67660)" },
    { value: "09457", value2: "09457", value3: "09457", value4: "09457", name: "BankFinancial WC QB (09457)" },
    { value: "06889", value2: "06889", value3: "06889", value4: "06889", name: "BankFirst Financial Services (06889)" },
    { value: "09124", value2: "09124", value3: "09124", value4: "09124", name: "BankFive - QBooks (09124)" },
    { value: "14772", value2: "14772", value3: "14772", value4: "14772", name: "BankFlorida (14772)" },
    { value: "69096", value2: "69096", value3: "69096", value4: "69096", name: "BankFlorida-DC (69096)" },
    { value: "69073", value2: "69073", value3: "69073", value4: "69073", name: "BankFlorida-TM (69073)" },
    { value: "69076", value2: "69076", value3: "69076", value4: "69076", name: "BankFlorida-TM-DC (69076)" },
    { value: "05345", value2: "05345", value3: "05345", value4: "05345", name: "BankGloucester - WC QB (05345)" },
    { value: "06152", value2: "06152", value3: "06152", value4: "06152", name: "BankIowa Business (06152)" },
    { value: "00399", value2: "00399", value3: "00399", value4: "00399", name: "BankNewport (00399)" },
    { value: "11430", value2: "11430", value3: "11430", value4: "11430", name: "BankNorth (11430)" },
    { value: "16128", value2: "16128", value3: "16128", value4: "16128", name: "BankORION (16128)" },
    { value: "12366", value2: "12366", value3: "12366", value4: "12366", name: "BankOkolona (12366)" },
    { value: "05476", value2: "05476", value3: "05476", value4: "05476", name: "BankPlus - Internet Banking QB (05476)" },
    { value: "70431", value2: "70431", value3: "70431", value4: "70431", name: "BankPlus Bus CC (70431)" },
    { value: "68021", value2: "68021", value3: "68021", value4: "68021", name: "BankPlus- Business Banking QB DC (68021)" },
    { value: "03515", value2: "03515", value3: "03515", value4: "03515", name: "BankProv (03515)" },
    { value: "03518", value2: "03518", value3: "03518", value4: "03515", name: "BankProv - QBWC (03518)" },
    { value: "18012", value2: "18012", value3: "18012", value4: "18012", name: "BankSouth - Dothan (18012)" },
    { value: "06202", value2: "06202", value3: "06202", value4: "06202", name: "BankSouth WC QuickBooks (06202)" },
    { value: "53946", value2: "53946", value3: "53946", value4: "53946", name: "BankStar Financial (53946)" },
    { value: "00864", value2: "00864", value3: "00864", value4: "00864", name: "BankTennessee (00864)" },
    { value: "70403", value2: "70403", value3: "70403", value4: "70403", name: "BankTennessee DC (70403)" },
    { value: "03356", value2: "03356", value3: "03356", value4: "03356", name: "BankUnited Business - New (03356)" },
    { value: "52284", value2: "52284", value3: "52284", value4: "52284", name: "BankUnited Business Banking WCQB (52284)" },
    { value: "69770", value2: "69770", value3: "69770", value4: "69770", name: "BankUnited N.A. (69770)" },
    { value: "69651", value2: "69651", value3: "69651", value4: "69651", name: "BankUnited, N.A. Credit Card (69651)" },
    { value: "56418", value2: "56418", value3: "56418", value4: "56418", name: "BankVista - MN (56418)" },
    { value: "68234", value2: "68234", value3: "68234", value4: "68234", name: "BankVista MN Business Banking (68234)" },
    { value: "68235", value2: "68235", value3: "68235", value4: "68235", name: "BankVista MN Business QB DC (68235)" },
    { value: "11575", value2: "11575", value3: "11575", value4: "11575", name: "BankWest - South Dakota (11575)" },
    { value: "12552", value2: "12552", value3: "12552", value4: "12552", name: "Bankcda (12552)" },
    { value: "53055", value2: "53055", value3: "53055", value4: "53055", name: "Bankers Trust Co-Des Moines BeB (53055)" },
    { value: "68249", value2: "68249", value3: "68249", value4: "68249", name: "Bankwell (68249)" },
    { value: "68250", value2: "68250", value3: "68250", value4: "68250", name: "Bankwell QBDC (68250)" },
    { value: "68696", value2: "68696", value3: "68696", value4: "68696", name: "Bankwest MN (68696)" },
    { value: "64395", value2: "64395", value3: "64395", value4: "64395", name: "Banner Bank Online (64395)" },
    { value: "18631", value2: "18631", value3: "18631", value4: "18631", name: "Banner Bank Online -QB (18631)" },
    { value: "64485", value2: "64485", value3: "64485", value4: "64485", name: "Banner Capital Bank (64485)" },
    { value: "01399", value2: "01399", value3: "01399", value4: "01399", name: "Banterra Bank (01399)" },
    { value: "12143", value2: "12143", value3: "12143", value4: "12143", name: "Banterra Bank - QB DC (12143)" },
    { value: "69191", value2: "69191", value3: "69191", value4: "69191", name: "Banterra Bank Premier BB - DC (69191)" },
    { value: "05180", value2: "05180", value3: "05180", value4: "05180", name: "Banterra Bank WCQB (05180)" },
    { value: "03996", value2: "03996", value3: "03996", value4: "03996", name: "Bar Harbor Bank & Trust (03996)" },
    { value: "03029", value2: "03029", value3: "03029", value4: "03996", name: "Bar Harbor Bank & Trust - DL (03029)" },
    { value: "59106", value2: "59106", value3: "59106", value4: "59106", name: "Baraboo National Bank - WI (59106)" },
    { value: "04351", value2: "04351", value3: "04351", value4: "04351", name: "Barclays Bank Delaware (04351)" },
    { value: "69448", value2: "69448", value3: "69448", value4: "69448", name: "Barclays Bank Delaware EWC (69448)" },
    { value: "69643", value2: "69643", value3: "69643", value4: "69643", name: "Barksdale FCU - BB DC (69643)" },
    { value: "57444", value2: "57444", value3: "57444", value4: "57444", name: "Barksdale FCU - WC QuickBooks (57444)" },
    { value: "03214", value2: "03214", value3: "03214", value4: "03214", name: "Barrington Bank & Trust Bus (03214)" },
    { value: "03825", value2: "03825", value3: "03825", value4: "03825", name: "Barrington Bank & Trust IBB (03825)" },
    { value: "67602", value2: "67602", value3: "67602", value4: "67602", name: "Barwick Banking Co Biz QBDC (67602)" },
    { value: "67600", value2: "67600", value3: "67600", value4: "67600", name: "Barwick Banking Co Consumer (67600)" },
    { value: "67601", value2: "67601", value3: "67601", value4: "67601", name: "Barwick Banking Co Consumer QBDC (67601)" },
    { value: "67972", value2: "67972", value3: "67972", value4: "67972", name: "Barwick Banking Co. Biz (67972)" },
    { value: "68637", value2: "68637", value3: "68637", value4: "68637", name: "Basile State Bank (68637)" },
    { value: "17241", value2: "17241", value3: "17241", value4: "17241", name: "Bask Bank (17241)" },
    { value: "68654", value2: "68654", value3: "68654", value4: "68654", name: "Bath Savings Institution BO (68654)" },
    { value: "68655", value2: "68655", value3: "68655", value4: "68655", name: "Bath Savings Institution BO QB (68655)" },
    { value: "11857", value2: "11857", value3: "11857", value4: "11857", name: "Bath Savings Institution RO (11857)" },
    { value: "68653", value2: "68653", value3: "68653", value4: "68653", name: "Bath Savings Institution RO QBDC (68653)" },
    { value: "70437", value2: "70437", value3: "70437", value4: "70437", name: "Bay Atlantic FCU Bus CC (70437)" },
    { value: "00671", value2: "00671", value3: "00671", value4: "00671", name: "Bay Bank - Business (00671)" },
    { value: "03446", value2: "03446", value3: "03446", value4: "03446", name: "Bay Bank - RO (03446)" },
    { value: "63999", value2: "63999", value3: "63999", value4: "63999", name: "Bay Federal Credit Union - QB (63999)" },
    { value: "13029", value2: "13029", value3: "13029", value4: "13029", name: "Bay Port State Bank (13029)" },
    { value: "02861", value2: "02861", value3: "02861", value4: "02861", name: "Bay State Savings Bank (02861)" },
    { value: "02858", value2: "02858", value3: "02858", value4: "02861", name: "Bay State Savings Bank - QB Web (02858)" },
    { value: "07790", value2: "07790", value3: "07790", value4: "07790", name: "Bay-Vanguard Federal Savings Bank (07790)" },
    { value: "12137", value2: "12137", value3: "12137", value4: "12137", name: "BayCoast Bank - Business QB DC (12137)" },
    { value: "06406", value2: "06406", value3: "06406", value4: "06406", name: "BayCoast Bank BB - QBWC (06406)" },
    { value: "00974", value2: "00974", value3: "00974", value4: "00974", name: "BayCoast Bank IB DC QB (00974)" },
    { value: "06496", value2: "06496", value3: "06496", value4: "06496", name: "BayCoast Bank IB QB (06496)" },
    { value: "08057", value2: "08057", value3: "08057", value4: "08057", name: "BayPort CU (08057)" },
    { value: "69373", value2: "69373", value3: "69373", value4: "69373", name: "BayPort Credit Union - Bus (69373)" },
    { value: "68079", value2: "68079", value3: "68079", value4: "68079", name: "BayVanguard QBDC (68079)" },
    { value: "25173", value2: "25173", value3: "25173", value4: "25173", name: "Bayfirst (25173)" },
    { value: "12293", value2: "12293", value3: "12293", value4: "25173", name: "Bayfirst DC (12293)" },
    { value: "69830", value2: "69830", value3: "69830", value4: "69830", name: "Beach Cities Commercial Bank (69830)" },
    { value: "69831", value2: "69831", value3: "69831", value4: "69831", name: "Beach Cities Commercial Bank DC (69831)" },
    { value: "51630", value2: "51630", value3: "51630", value4: "51630", name: "Beach Municipal FCU CC (51630)" },
    { value: "16677", value2: "16677", value3: "16677", value4: "16677", name: "Beacon Business Bank (16677)" },
    { value: "68335", value2: "68335", value3: "68335", value4: "68335", name: "Beacon Business Bank QB Direct (68335)" },
    { value: "05096", value2: "05096", value3: "05096", value4: "05096", name: "Beacon Community Bank (05096)" },
    { value: "68674", value2: "68674", value3: "68674", value4: "68674", name: "Beacon Credit Union - IN (68674)" },
    { value: "01634", value2: "01634", value3: "01634", value4: "01634", name: "Beacon Credit Union - mybcu (01634)" },
    { value: "12309", value2: "12309", value3: "12309", value4: "12309", name: "Beacon Federal Credit Union (12309)" },
    { value: "14676", value2: "14676", value3: "14676", value4: "14676", name: "Beartooth Bank (14676)" },
    { value: "70350", value2: "70350", value3: "70350", value4: "70350", name: "Beauregard FSB (70350)" },
    { value: "69302", value2: "69302", value3: "69302", value4: "69302", name: "Beaver Valley FCU -PA (69302)" },
    { value: "28488", value2: "28488", value3: "28488", value4: "28488", name: "Bedford Bank (28488)" },
    { value: "69529", value2: "69529", value3: "69529", value4: "69529", name: "Bedford Federal Savings Bank BO (69529)" },
    { value: "25857", value2: "25857", value3: "25857", value4: "25857", name: "Bedford Federal Savings Bank RO (25857)" },
    { value: "03298", value2: "03298", value3: "03298", value4: "03298", name: "Belco Community Credit Union (03298)" },
    { value: "51117", value2: "51117", value3: "51117", value4: "51117", name: "Belgrade State Bank (51117)" },
    { value: "01087", value2: "01087", value3: "01087", value4: "01087", name: "Bell Bank (01087)" },
    { value: "17425", value2: "17425", value3: "17425", value4: "17425", name: "Bellco Credit Union - CO (17425)" },
    { value: "63753", value2: "63753", value3: "63753", value4: "63753", name: "Bellevue State Bank_QB (63753)" },
    { value: "67064", value2: "67064", value3: "67064", value4: "67064", name: "Belmont Bank & Trust Co Bus (67064)" },
    { value: "67065", value2: "67065", value3: "67065", value4: "67065", name: "Belmont Bank & Trust Co Bus DC (67065)" },
    { value: "67066", value2: "67066", value3: "67066", value4: "67066", name: "Belmont Bank & Trust Co DC (67066)" },
    { value: "18372", value2: "18372", value3: "18372", value4: "18372", name: "Belmont Bank & Trust Company (18372)" },
    { value: "68970", value2: "68970", value3: "68970", value4: "68970", name: "Belmont Savings Bank Bus (68970)" },
    { value: "67585", value2: "67585", value3: "67585", value4: "67585", name: "Belt Valley Bank (67585)" },
    { value: "18301", value2: "18301", value3: "18301", value4: "18301", name: "Benchmark Bank (18301)" },
    { value: "18304", value2: "18304", value3: "18304", value4: "18304", name: "Benchmark Bank -QB (18304)" },
    { value: "08195", value2: "08195", value3: "08195", value4: "08195", name: "Benchmark Community Bank-BeB (08195)" },
    { value: "19593", value2: "19593", value3: "19593", value4: "19593", name: "Benchmark Community Bank-QB (19593)" },
    { value: "58125", value2: "58125", value3: "58125", value4: "58125", name: "Bendena State Bank (58125)" },
    { value: "03686", value2: "03686", value3: "03686", value4: "03686", name: "Beneficial State Bank (03686)" },
    { value: "03689", value2: "03689", value3: "03689", value4: "03686", name: "Beneficial State Bank -DC (03689)" },
    { value: "62508", value2: "62508", value3: "62508", value4: "15990", name: "Bennington QuickBooks DC (62508)" },
    { value: "15990", value2: "15990", value3: "15990", value4: "15990", name: "Bennington State Bank, Kansas (15990)" },
    { value: "18405", value2: "18405", value3: "18405", value4: "18405", name: "Benton State Bank (18405)" },
    { value: "55692", value2: "55692", value3: "55692", value4: "55692", name: "Berkshire Bank WC (55692)" },
    { value: "55743", value2: "55743", value3: "55743", value4: "55743", name: "Berkshire Bank-Business Solutions (55743)" },
    { value: "70179", value2: "70179", value3: "70179", value4: "70179", name: "Bethpage FCU Credit Card (70179)" },
    { value: "04271", value2: "04271", value3: "04271", value4: "04271", name: "Bethpage Federal Credit Union - NY (04271)" },
    { value: "67180", value2: "67180", value3: "67180", value4: "67180", name: "Better Banks Cash Management (67180)" },
    { value: "68554", value2: "68554", value3: "68554", value4: "68554", name: "Better Banks Digital Banking (68554)" },
    { value: "03208", value2: "03208", value3: "03208", value4: "03208", name: "Beverly Bank & Trust Bus (03208)" },
    { value: "03834", value2: "03834", value3: "03834", value4: "03834", name: "Beverly Bank & Trust IBB (03834)" },
    { value: "65295", value2: "65295", value3: "65295", value4: "65295", name: "Beverly Bank-BUS (65295)" },
    { value: "67321", value2: "67321", value3: "67321", value4: "67321", name: "Big Bend Banks (67321)" },
    { value: "67322", value2: "67322", value3: "67322", value4: "67322", name: "Big Bend Banks QBDC (67322)" },
    { value: "68064", value2: "68064", value3: "68064", value4: "68064", name: "Big Horn Federal Business (68064)" },
    { value: "68066", value2: "68066", value3: "68066", value4: "68064", name: "Big Horn Federal Business DC (68066)" },
    { value: "68063", value2: "68063", value3: "68063", value4: "68063", name: "Big Horn Federal Personal (68063)" },
    { value: "68065", value2: "68065", value3: "68065", value4: "68063", name: "Big Horn Federal Personal DC (68065)" },
    { value: "63897", value2: "63897", value3: "63897", value4: "63897", name: "Billings Federal Credit Union (63897)" },
    { value: "01623", value2: "01623", value3: "01623", value4: "01623", name: "Bippus State Bank (01623)" },
    { value: "24408", value2: "24408", value3: "24408", value4: "24408", name: "Bison FCU (24408)" },
    { value: "07805", value2: "07805", value3: "07805", value4: "07805", name: "Bison State Bank (07805)" },
    { value: "07808", value2: "07808", value3: "07808", value4: "07805", name: "Bison State Bank - DC (07808)" },
    { value: "58179", value2: "58179", value3: "58179", value4: "58179", name: "Bitterroot Community Federal CU (58179)" },
    { value: "18903", value2: "18903", value3: "18903", value4: "18903", name: "Black Hills Community Bank, N.A. (18903)" },
    { value: "16549", value2: "16549", value3: "16549", value4: "16549", name: "Black Hills FCU - WC for QB (16549)" },
    { value: "07061", value2: "07061", value3: "07061", value4: "07061", name: "Black Hills Federal CU (07061)" },
    { value: "68296", value2: "68296", value3: "68296", value4: "68296", name: "Blackhawk Bank & Trust IL Business (68296)" },
    { value: "53970", value2: "53970", value3: "53970", value4: "53970", name: "Blackhawk Bank & Trust IL Personal (53970)" },
    { value: "68297", value2: "68297", value3: "68297", value4: "68297", name: "Blackhawk Bank & Trust IL QB DC (68297)" },
    { value: "10676", value2: "10676", value3: "10676", value4: "10676", name: "Blackhawk Community Credit Union (10676)" },
    { value: "17739", value2: "17739", value3: "17739", value4: "17739", name: "Blaze Hiway CU (17739)" },
    { value: "02128", value2: "02128", value3: "02128", value4: "02128", name: "Blaze Spire Credit Union (02128)" },
    { value: "54048", value2: "54048", value3: "54048", value4: "54048", name: "Bloomsdale Bank (54048)" },
    { value: "26793", value2: "26793", value3: "26793", value4: "26793", name: "BluCurrent Credit Union (26793)" },
    { value: "70096", value2: "70096", value3: "70096", value4: "70096", name: "Blue Coast FCU (70096)" },
    { value: "70466", value2: "70466", value3: "70466", value4: "70466", name: "Blue Coast FCU Bus CC (70466)" },
    { value: "62181", value2: "62181", value3: "62181", value4: "62181", name: "Blue Federal Credit Union (62181)" },
    { value: "08900", value2: "08900", value3: "08900", value4: "08900", name: "Blue Foundry Bank (08900)" },
    { value: "08903", value2: "08903", value3: "08903", value4: "08900", name: "Blue Foundry Bank - QBWC (08903)" },
    { value: "69783", value2: "69783", value3: "69783", value4: "69783", name: "Blue Grass Federal (69783)" },
    { value: "69782", value2: "69782", value3: "69782", value4: "69782", name: "Blue Grass Federal QB (69782)" },
    { value: "32433", value2: "32433", value3: "32433", value4: "32433", name: "Blue Grass Savings Bank (32433)" },
    { value: "12038", value2: "12038", value3: "12038", value4: "12038", name: "Blue Grass Savings Bank - DC (12038)" },
    { value: "05468", value2: "05468", value3: "05468", value4: "05468", name: "Blue Hills Bank Business (05468)" },
    { value: "01343", value2: "01343", value3: "01343", value4: "01343", name: "Blue Ridge Bank & Trust Co. (01343)" },
    { value: "63051", value2: "63051", value3: "63051", value4: "63051", name: "Blue Ridge Bank - SC (63051)" },
    { value: "16047", value2: "16047", value3: "16047", value4: "16047", name: "Blue Ridge Bank N.A. (16047)" },
    { value: "68266", value2: "68266", value3: "68266", value4: "16047", name: "Blue Ridge Bank-DC (68266)" },
    { value: "09149", value2: "09149", value3: "09149", value4: "09149", name: "Blue Sky Bank - Business (09149)" },
    { value: "09146", value2: "09146", value3: "09146", value4: "09146", name: "Blue Sky Bank - Consumer (09146)" },
    { value: "17493", value2: "17493", value3: "17493", value4: "17493", name: "BlueOx Credit Union (17493)" },
    { value: "57930", value2: "57930", value3: "57930", value4: "57930", name: "Blueharbor Bank - DC (57930)" },
    { value: "68512", value2: "68512", value3: "68512", value4: "68512", name: "Bluestone Bank DC PFM (68512)" },
    { value: "17466", value2: "17466", value3: "17466", value4: "17466", name: "Bluestone Federal CU (17466)" },
    { value: "09359", value2: "09359", value3: "09359", value4: "09359", name: "Bodcaw Bank (09359)" },
    { value: "09362", value2: "09362", value3: "09362", value4: "09359", name: "Bodcaw Bank - DC (09362)" },
    { value: "11974", value2: "11974", value3: "11974", value4: "11974", name: "Boeing Employees' CU QBWC (11974)" },
    { value: "03670", value2: "03670", value3: "03670", value4: "03670", name: "Boeing Employees' Credit Union (03670)" },
    { value: "19611", value2: "19611", value3: "19611", value4: "19611", name: "Bogota Savings Bank NJ (19611)" },
    { value: "18270", value2: "18270", value3: "18270", value4: "18270", name: "Bonanza Valley State Bank (18270)" },
    { value: "53919", value2: "53919", value3: "53919", value4: "53919", name: "Border Bank (53919)" },
    { value: "68142", value2: "68142", value3: "68142", value4: "68142", name: "Bossier FCU - Direct (68142)" },
    { value: "17734", value2: "17734", value3: "17734", value4: "17734", name: "Bossier Federal CU - Web (17734)" },
    { value: "20064", value2: "20064", value3: "20064", value4: "20064", name: "Boston Firefighters Credit Union (20064)" },
    { value: "13329", value2: "13329", value3: "13329", value4: "13329", name: "Boulder Dam Credit Union (13329)" },
    { value: "09101", value2: "09101", value3: "09101", value4: "09101", name: "Boulder Dam Credit Union QB (09101)" },
    { value: "52812", value2: "52812", value3: "52812", value4: "52812", name: "Bradford National Bank - Business (52812)" },
    { value: "57603", value2: "57603", value3: "57603", value4: "57603", name: "Bradford National Bank - Consumer (57603)" },
    { value: "55587", value2: "55587", value3: "55587", value4: "55587", name: "Branch Banking & Trust (55587)" },
    { value: "67762", value2: "67762", value3: "67762", value4: "67762", name: "Brannen Bank - FL (67762)" },
    { value: "67763", value2: "67763", value3: "67763", value4: "67763", name: "Brannen Bank - QB (67763)" },
    { value: "34743", value2: "34743", value3: "34743", value4: "34743", name: "Branson Bank (34743)" },
    { value: "68248", value2: "68248", value3: "68248", value4: "68248", name: "Branson Bank QBDC (68248)" },
    { value: "67357", value2: "67357", value3: "67357", value4: "67357", name: "Brattleboro Savings (67357)" },
    { value: "67359", value2: "67359", value3: "67359", value4: "67359", name: "Brattleboro Savings - QB (67359)" },
    { value: "50781", value2: "50781", value3: "50781", value4: "50781", name: "Bravera Bank (50781)" },
    { value: "11325", value2: "11325", value3: "11325", value4: "11325", name: "Brazos Valley Schools Credit Union (11325)" },
    { value: "54144", value2: "54144", value3: "54144", value4: "54144", name: "Bremer Bank - Business (54144)" },
    { value: "01492", value2: "01492", value3: "01492", value4: "01492", name: "Brenham National Bank (01492)" },
    { value: "57672", value2: "57672", value3: "57672", value4: "57672", name: "Brentwood Bank PA (57672)" },
    { value: "68242", value2: "68242", value3: "68242", value4: "68242", name: "Brentwood Bank PA QBDC (68242)" },
    { value: "69510", value2: "69510", value3: "69510", value4: "69510", name: "Brex EWC (69510)" },
    { value: "53778", value2: "53778", value3: "53778", value4: "53778", name: "Bridge City Bank (53778)" },
    { value: "62553", value2: "62553", value3: "62553", value4: "62553", name: "Bridgehampton National Bank CCard (62553)" },
    { value: "03408", value2: "03408", value3: "03408", value4: "03408", name: "Bridgewater Bank (03408)" },
    { value: "12236", value2: "12236", value3: "12236", value4: "12236", name: "Bridgewater Bank - The Bridge (12236)" },
    { value: "50379", value2: "50379", value3: "50379", value4: "50379", name: "Bridgewater Savings Bank - BeB (50379)" },
    { value: "52407", value2: "52407", value3: "52407", value4: "52407", name: "BrightStar Credit Union (52407)" },
    { value: "12314", value2: "12314", value3: "12314", value4: "12314", name: "Brighton Bank - DC (12314)" },
    { value: "64650", value2: "64650", value3: "64650", value4: "64650", name: "Brighton Bank - TN (64650)" },
    { value: "64653", value2: "64653", value3: "64653", value4: "64650", name: "Brighton Bank - TN_DC (64653)" },
    { value: "06554", value2: "06554", value3: "06554", value4: "06554", name: "Bristol County Savings Bank (06554)" },
    { value: "06659", value2: "06659", value3: "06659", value4: "06554", name: "Bristol County Savings Bank WC QB (06659)" },
    { value: "70013", value2: "70013", value3: "70013", value4: "70013", name: "Broadstreet Bank SSB (70013)" },
    { value: "10517", value2: "10517", value3: "10517", value4: "10517", name: "Broadview FCU (10517)" },
    { value: "11402", value2: "11402", value3: "11402", value4: "11402", name: "Broadway Bank (11402)" },
    { value: "06950", value2: "06950", value3: "06950", value4: "06950", name: "Broadway Bank - iBIZ (06950)" },
    { value: "03185", value2: "03185", value3: "03185", value4: "03185", name: "Brokaw Credit Union CC (03185)" },
    { value: "63870", value2: "63870", value3: "63870", value4: "63870", name: "Bronco FCU Credit Card (63870)" },
    { value: "04984", value2: "04984", value3: "04984", value4: "04984", name: "Bronco Federal Credit Union (04984)" },
    { value: "03875", value2: "03875", value3: "03875", value4: "03875", name: "Brookline Bank DirectConnect (03875)" },
    { value: "09826", value2: "09826", value3: "09826", value4: "09826", name: "Brookline Bank WebConnect (09826)" },
    { value: "05372", value2: "05372", value3: "05372", value4: "05372", name: "Brookline Bank WebConnect-CM (05372)" },
    { value: "61245", value2: "61245", value3: "61245", value4: "61245", name: "Brooklyn Cooperative (61245)" },
    { value: "26235", value2: "26235", value3: "26235", value4: "26235", name: "Bruning State Bank (26235)" },
    { value: "08825", value2: "08825", value3: "08825", value4: "08825", name: "Bryant Bank (08825)" },
    { value: "00987", value2: "00987", value3: "00987", value4: "00987", name: "Bryant Bank - Web Connect (00987)" },
    { value: "00764", value2: "00764", value3: "00764", value4: "00764", name: "Bryant State Bank (00764)" },
    { value: "00767", value2: "00767", value3: "00767", value4: "00767", name: "Bryant State Bank-DC (00767)" },
    { value: "34722", value2: "34722", value3: "34722", value4: "34722", name: "Buckeye Community Bank (34722)" },
    { value: "70369", value2: "70369", value3: "70369", value4: "70369", name: "Buckeye Community Bank-DC (70369)" },
    { value: "05246", value2: "05246", value3: "05246", value4: "05246", name: "Buckeye State Bank-BO (05246)" },
    { value: "15789", value2: "15789", value3: "15789", value4: "15789", name: "Buckholts State Bank (15789)" },
    { value: "09434", value2: "09434", value3: "09434", value4: "09434", name: "Buena Vista National Bank (09434)" },
    { value: "00944", value2: "00944", value3: "00944", value4: "00944", name: "Buffalo Federal Bank (00944)" },
    { value: "04485", value2: "04485", value3: "04485", value4: "04485", name: "Building Trades Credit Union (04485)" },
    { value: "02306", value2: "02306", value3: "02306", value4: "02306", name: "Builtwell Bank (02306)" },
    { value: "67975", value2: "67975", value3: "67975", value4: "67975", name: "Bulldog FCU (67975)" },
    { value: "01505", value2: "01505", value3: "01505", value4: "01505", name: "Burke & Herbert Bank & Trust (01505)" },
    { value: "70258", value2: "70258", value3: "70258", value4: "68330", name: "Burke & Herbert Bank-Comm DC (70258)" },
    { value: "68330", value2: "68330", value3: "68330", value4: "68330", name: "Burke & Herbert Bank-Commercial (68330)" },
    { value: "07499", value2: "07499", value3: "07499", value4: "07499", name: "Burling Bank - Business (07499)" },
    { value: "03672", value2: "03672", value3: "03672", value4: "03672", name: "Burling Bank - Retail (03672)" },
    { value: "67308", value2: "67308", value3: "67308", value4: "67308", name: "Busey Bank - BA - DC (67308)" },
    { value: "55803", value2: "55803", value3: "55803", value4: "55803", name: "Busey Bank BA QB (55803)" },
    { value: "12179", value2: "12179", value3: "12179", value4: "12179", name: "Busey Bank IL (12179)" },
    { value: "12182", value2: "12182", value3: "12182", value4: "12179", name: "Busey Bank WC (12182)" },
    { value: "67661", value2: "67661", value3: "67661", value4: "67661", name: "Busey Bank-TM (67661)" },
    { value: "67662", value2: "67662", value3: "67662", value4: "67661", name: "Busey Bank-TM-DC (67662)" },
    { value: "58077", value2: "58077", value3: "58077", value4: "58077", name: "Business Bank of St. Louis Credit (58077)" },
    { value: "11441", value2: "11441", value3: "11441", value4: "11441", name: "Byline Bank - BIB (11441)" },
    { value: "58161", value2: "58161", value3: "58161", value4: "58161", name: "Byron Bank IL  Business (58161)" },
    { value: "68663", value2: "68663", value3: "68663", value4: "68663", name: "C&F Bank - Bus DC (68663)" },
    { value: "52146", value2: "52146", value3: "52146", value4: "52146", name: "C&F Bank - Direct Connect (52146)" },
    { value: "19218", value2: "19218", value3: "19218", value4: "19218", name: "C&F Financial Corp (19218)" },
    { value: "65463", value2: "65463", value3: "65463", value4: "65463", name: "C-B-W Schools Federal Credit Union (65463)" },
    { value: "22086", value2: "22086", value3: "22086", value4: "22086", name: "C-Plant FCU (22086)" },
    { value: "57270", value2: "57270", value3: "57270", value4: "22086", name: "C-Plant FCU_DC (57270)" },
    { value: "63189", value2: "63189", value3: "63189", value4: "63189", name: "C3bank-business (63189)" },
    { value: "00473", value2: "00473", value3: "00473", value4: "00473", name: "C3bank-business direct (00473)" },
    { value: "63279", value2: "63279", value3: "63279", value4: "63279", name: "C3bank-consumer (63279)" },
    { value: "00470", value2: "00470", value3: "00470", value4: "00470", name: "C3bank-consumer direct (00470)" },
    { value: "04688", value2: "04688", value3: "04688", value4: "04688", name: "CACU Commercial Business (04688)" },
    { value: "05780", value2: "05780", value3: "05780", value4: "05780", name: "CAHP Credit Union New (05780)" },
    { value: "05777", value2: "05777", value3: "05777", value4: "05780", name: "CAHP Credit Union QB Direct (05777)" },
    { value: "68222", value2: "68222", value3: "68222", value4: "68222", name: "CB Grayson QBDC (68222)" },
    { value: "12408", value2: "12408", value3: "12408", value4: "12408", name: "CB&S Bank (12408)" },
    { value: "07019", value2: "07019", value3: "07019", value4: "07019", name: "CBBC Bank (07019)" },
    { value: "70018", value2: "70018", value3: "70018", value4: "70018", name: "CBBC Bank- DC (70018)" },
    { value: "59688", value2: "59688", value3: "59688", value4: "59685", name: "CBFC-Quickbooks (59688)" },
    { value: "63210", value2: "63210", value3: "63210", value4: "63210", name: "CBFC-Quickbooks Business (63210)" },
    { value: "59685", value2: "59685", value3: "59685", value4: "59685", name: "CBFC-Quicken & QuickBooks (59685)" },
    { value: "04460", value2: "04460", value3: "04460", value4: "04457", name: "CBI Bank & Trust-DL (04460)" },
    { value: "04457", value2: "04457", value3: "04457", value4: "04457", name: "CBI Family of Banks (04457)" },
    { value: "64389", value2: "64389", value3: "64389", value4: "64389", name: "CBOPC Business (64389)" },
    { value: "08783", value2: "08783", value3: "08783", value4: "08783", name: "CBPH Business (08783)" },
    { value: "08780", value2: "08780", value3: "08780", value4: "08780", name: "CBPH Business Direct (08780)" },
    { value: "08777", value2: "08777", value3: "08777", value4: "08777", name: "CBPH Direct (08777)" },
    { value: "08792", value2: "08792", value3: "08792", value4: "08792", name: "CBR Business (08792)" },
    { value: "08789", value2: "08789", value3: "08789", value4: "08789", name: "CBR Business Direct (08789)" },
    { value: "08786", value2: "08786", value3: "08786", value4: "08786", name: "CBR Direct (08786)" },
    { value: "68321", value2: "68321", value3: "68321", value4: "68321", name: "CBTx - Business Online Banking (68321)" },
    { value: "03721", value2: "03721", value3: "03721", value4: "03721", name: "CCB Community Bank (03721)" },
    { value: "67078", value2: "67078", value3: "67078", value4: "67078", name: "CCBank BeB DC (67078)" },
    { value: "11740", value2: "11740", value3: "11740", value4: "11740", name: "CCBank BeB Web (11740)" },
    { value: "67080", value2: "67080", value3: "67080", value4: "67080", name: "CCBank CeB DC (67080)" },
    { value: "64719", value2: "64719", value3: "64719", value4: "64719", name: "CDC Credit Card (64719)" },
    { value: "00567", value2: "00567", value3: "00567", value4: "00567", name: "CDC FCU (00567)" },
    { value: "52446", value2: "52446", value3: "52446", value4: "52446", name: "CEFCU (52446)" },
    { value: "53049", value2: "53049", value3: "53049", value4: "53049", name: "CES CU (53049)" },
    { value: "67912", value2: "67912", value3: "67912", value4: "67912", name: "CFB Heartland (67912)" },
    { value: "67913", value2: "67913", value3: "67913", value4: "67912", name: "CFB Heartland IL QB (67913)" },
    { value: "12188", value2: "12188", value3: "12188", value4: "12188", name: "CFBank Bus DC (12188)" },
    { value: "05733", value2: "05733", value3: "05733", value4: "05733", name: "CFBank Bus WC (05733)" },
    { value: "69619", value2: "69619", value3: "69619", value4: "69619", name: "CFBank Online Banking DC (69619)" },
    { value: "67947", value2: "67947", value3: "67947", value4: "67947", name: "CFBank Personal (67947)" },
    { value: "58086", value2: "58086", value3: "58086", value4: "58086", name: "CFCU Community CU Credit Card (58086)" },
    { value: "03123", value2: "03123", value3: "03123", value4: "03123", name: "CFCU Community Credit Union (03123)" },
    { value: "04265", value2: "04265", value3: "04265", value4: "04265", name: "CFG Community Bank - BO (04265)" },
    { value: "67967", value2: "67967", value3: "67967", value4: "04265", name: "CFG Community Bank - DC (67967)" },
    { value: "09678", value2: "09678", value3: "09678", value4: "09678", name: "CFSB (09678)" },
    { value: "68224", value2: "68224", value3: "68224", value4: "09678", name: "CFSB-QBDC (68224)" },
    { value: "67905", value2: "67905", value3: "67905", value4: "67905", name: "CFSBank Business (67905)" },
    { value: "67906", value2: "67906", value3: "67906", value4: "67906", name: "CFSBank Business QB Direct (67906)" },
    { value: "04031", value2: "04031", value3: "04031", value4: "04031", name: "CIBC Bank USA - Business Banking (04031)" },
    { value: "06688", value2: "06688", value3: "06688", value4: "06688", name: "CIBC Bank USA - Direct Connect (06688)" },
    { value: "00959", value2: "00959", value3: "00959", value4: "00959", name: "CLB The Community Bank (00959)" },
    { value: "00917", value2: "00917", value3: "00917", value4: "00917", name: "CME Federal Credit Union (00917)" },
    { value: "67642", value2: "67642", value3: "67642", value4: "67642", name: "CNB - Bossier QBWC (67642)" },
    { value: "04614", value2: "04614", value3: "04614", value4: "04614", name: "CNB Bank (04614)" },
    { value: "16086", value2: "16086", value3: "16086", value4: "16086", name: "CNB Bank & Trust, NA (16086)" },
    { value: "70049", value2: "70049", value3: "70049", value4: "16086", name: "CNB Bank & Trust, NA-QBDC (70049)" },
    { value: "51687", value2: "51687", value3: "51687", value4: "51687", name: "CNB Bank - Berkeley Springs, WV (51687)" },
    { value: "65373", value2: "65373", value3: "65373", value4: "04614", name: "CNB Bank - BoB - Bus (65373)" },
    { value: "08731", value2: "08731", value3: "08731", value4: "08731", name: "CNB Bank NM (08731)" },
    { value: "70217", value2: "70217", value3: "70217", value4: "08731", name: "CNB Bank NM QBDC (70217)" },
    { value: "70197", value2: "70197", value3: "70197", value4: "70197", name: "CNB Bank WV Business DC (70197)" },
    { value: "70198", value2: "70198", value3: "70198", value4: "70198", name: "CNB Bank WV Business WC (70198)" },
    { value: "70202", value2: "70202", value3: "70202", value4: "70202", name: "CNB Bank WV Consumer DC (70202)" },
    { value: "13462", value2: "13462", value3: "13462", value4: "13462", name: "CNB Commercial Card Online (13462)" },
    { value: "63165", value2: "63165", value3: "63165", value4: "63165", name: "CNB St Louis Bank (63165)" },
    { value: "68103", value2: "68103", value3: "68103", value4: "68103", name: "CNB St Louis Bank MO (68103)" },
    { value: "63162", value2: "63162", value3: "63162", value4: "63162", name: "CNB St Louis Bank QB (63162)" },
    { value: "12662", value2: "12662", value3: "12662", value4: "12662", name: "CNB TX Business (12662)" },
    { value: "12665", value2: "12665", value3: "12665", value4: "12665", name: "CNB TX Business Direct (12665)" },
    { value: "08873", value2: "08873", value3: "08873", value4: "08873", name: "CO-OP Credit Union Credit Card (08873)" },
    { value: "00493", value2: "00493", value3: "00493", value4: "00493", name: "CODE Credit Union (00493)" },
    { value: "56562", value2: "56562", value3: "56562", value4: "56562", name: "COPOCO Community Credit Union (56562)" },
    { value: "69960", value2: "69960", value3: "69960", value4: "69960", name: "COREBANK - OK (69960)" },
    { value: "69961", value2: "69961", value3: "69961", value4: "69960", name: "COREBANK - OK QBDC (69961)" },
    { value: "08525", value2: "08525", value3: "08525", value4: "08525", name: "CPM Federal CU (08525)" },
    { value: "33999", value2: "33999", value3: "33999", value4: "33999", name: "CS Bank-Eureka Springs (33999)" },
    { value: "15001", value2: "15001", value3: "15001", value4: "15001", name: "CSB, Perry FL-QB (15001)" },
    { value: "69412", value2: "69412", value3: "69412", value4: "69412", name: "CSB-Woodville,TX For Business (69412)" },
    { value: "69195", value2: "69195", value3: "69195", value4: "69195", name: "CSE FCU (69195)" },
    { value: "18531", value2: "18531", value3: "18531", value4: "18531", name: "CSE Federal Credit Union (18531)" },
    { value: "06700", value2: "06700", value3: "06700", value4: "06700", name: "CU Hawaii FCU (06700)" },
    { value: "18657", value2: "18657", value3: "18657", value4: "18657", name: "CUSA FCU (18657)" },
    { value: "08273", value2: "08273", value3: "08273", value4: "08273", name: "CUSA FCU Credit Card (08273)" },
    { value: "08582", value2: "08582", value3: "08582", value4: "08582", name: "CUSB Bank (08582)" },
    { value: "06572", value2: "06572", value3: "06572", value4: "06572", name: "Cache Valley Bank (06572)" },
    { value: "17806", value2: "17806", value3: "17806", value4: "06572", name: "Cache Valley Bank - DC (17806)" },
    { value: "14926", value2: "14926", value3: "14926", value4: "14926", name: "Cadence Bank Allegro (14926)" },
    { value: "18679", value2: "18679", value3: "18679", value4: "18679", name: "Cadence Bank DC (18679)" },
    { value: "14572", value2: "14572", value3: "14572", value4: "14572", name: "Cadence Bank WC (14572)" },
    { value: "68292", value2: "68292", value3: "68292", value4: "68292", name: "Cadence Commercial Center DC (68292)" },
    { value: "06446", value2: "06446", value3: "06446", value4: "06446", name: "Cal State L.A. Federal C.U. Card (06446)" },
    { value: "50985", value2: "50985", value3: "50985", value4: "50985", name: "Cal-Com FCU (50985)" },
    { value: "56190", value2: "56190", value3: "56190", value4: "50985", name: "Cal-Com FCU_DC (56190)" },
    { value: "01897", value2: "01897", value3: "01897", value4: "01897", name: "CalPrivate Bank (01897)" },
    { value: "68782", value2: "68782", value3: "68782", value4: "68782", name: "Calcite Credit Union (68782)" },
    { value: "10830", value2: "10830", value3: "10830", value4: "10830", name: "Caldwell Bank & Trust (10830)" },
    { value: "11969", value2: "11969", value3: "11969", value4: "11969", name: "Calhoun Liberty Employees CU (11969)" },
    { value: "13498", value2: "13498", value3: "13498", value4: "13498", name: "California Bank & Trust - Direct (13498)" },
    { value: "06668", value2: "06668", value3: "06668", value4: "06668", name: "California Bank & Trust - WEB (06668)" },
    { value: "54072", value2: "54072", value3: "54072", value4: "54072", name: "California Bank of Commerce - CA (54072)" },
    { value: "18936", value2: "18936", value3: "18936", value4: "18936", name: "California Business Bank-Business (18936)" },
    { value: "14938", value2: "14938", value3: "14938", value4: "14938", name: "California Credit Union - QB (14938)" },
    { value: "69799", value2: "69799", value3: "69799", value4: "69799", name: "California International Bank NA (69799)" },
    { value: "70105", value2: "70105", value3: "70105", value4: "70105", name: "Call Federal (70105)" },
    { value: "49824", value2: "49824", value3: "49824", value4: "49824", name: "Call Federal CU Visa Credit Card (49824)" },
    { value: "56985", value2: "56985", value3: "56985", value4: "56985", name: "Caltech Employees FCU (56985)" },
    { value: "03263", value2: "03263", value3: "03263", value4: "03263", name: "Cambridge Savings Bank - MA (03263)" },
    { value: "59235", value2: "59235", value3: "59235", value4: "59235", name: "Cambridge Savings Bank WC QB (59235)" },
    { value: "11052", value2: "11052", value3: "11052", value4: "11052", name: "Cambridge Trust Bus Web Connect (11052)" },
    { value: "00739", value2: "00739", value3: "00739", value4: "00739", name: "Cambridge Trust Direct Connect (00739)" },
    { value: "51291", value2: "51291", value3: "51291", value4: "51291", name: "Camden National Bank (51291)" },
    { value: "69190", value2: "69190", value3: "69190", value4: "51291", name: "Camden National Bank DC (69190)" },
    { value: "64965", value2: "64965", value3: "64965", value4: "64965", name: "Camino Federal Credit Union (64965)" },
    { value: "01185", value2: "01185", value3: "01185", value4: "01185", name: "Campus Federal Credit Union (01185)" },
    { value: "54066", value2: "54066", value3: "54066", value4: "54066", name: "Campus USA Credit Union (54066)" },
    { value: "17650", value2: "17650", value3: "17650", value4: "54066", name: "Campus USA Credit Union - QB DC (17650)" },
    { value: "18403", value2: "18403", value3: "18403", value4: "18400", name: "Canandaigua Nat Bank & Trust-QB (18403)" },
    { value: "18400", value2: "18400", value3: "18400", value4: "18400", name: "Canandaigua National Bank &Trust (18400)" },
    { value: "67819", value2: "67819", value3: "67819", value4: "67819", name: "Canopy Credit Union (67819)" },
    { value: "01290", value2: "01290", value3: "01290", value4: "01290", name: "Canvas CU - Personal Banking (01290)" },
    { value: "14434", value2: "14434", value3: "14434", value4: "14434", name: "Canyon State Credit Union (14434)" },
    { value: "04211", value2: "04211", value3: "04211", value4: "04211", name: "Cap Com Federal CU- QuickBooks (04211)" },
    { value: "04564", value2: "04564", value3: "04564", value4: "04564", name: "CapEd Credit Union - ID (04564)" },
    { value: "70141", value2: "70141", value3: "70141", value4: "70141", name: "CapFed Direct (70141)" },
    { value: "03917", value2: "03917", value3: "03917", value4: "03917", name: "CapFed Web (03917)" },
    { value: "15540", value2: "15540", value3: "15540", value4: "15540", name: "CapStar Bank (15540)" },
    { value: "68711", value2: "68711", value3: "68711", value4: "68711", name: "Cape Ann Savings Bank (68711)" },
    { value: "68712", value2: "68712", value3: "68712", value4: "68712", name: "Cape Ann Savings Bank  Bus (68712)" },
    { value: "04041", value2: "04041", value3: "04041", value4: "04041", name: "Cape Cod Five Cents SB-Bus IB (04041)" },
    { value: "67544", value2: "67544", value3: "67544", value4: "67544", name: "Cape Cod Five Cents SB-Personal (67544)" },
    { value: "15843", value2: "15843", value3: "15843", value4: "15843", name: "Capital Bank - Rockville, MD (15843)" },
    { value: "16653", value2: "16653", value3: "16653", value4: "16653", name: "Capital Bank Texas (16653)" },
    { value: "64374", value2: "64374", value3: "64374", value4: "64374", name: "Capital Bank-Rockville MD-BO (64374)" },
    { value: "18751", value2: "18751", value3: "18751", value4: "18751", name: "Capital CU WI - DirectConnect (18751)" },
    { value: "59304", value2: "59304", value3: "59304", value4: "59304", name: "Capital CU WI - WC (59304)" },
    { value: "62607", value2: "62607", value3: "62607", value4: "62607", name: "Capital City-new (62607)" },
    { value: "08324", value2: "08324", value3: "08324", value4: "08324", name: "Capital Credit Union - ND (08324)" },
    { value: "69809", value2: "69809", value3: "69809", value4: "08324", name: "Capital Credit Union - ND DC (69809)" },
    { value: "69811", value2: "69811", value3: "69811", value4: "69811", name: "Capital Credit Union Biz - ND (69811)" },
    { value: "69810", value2: "69810", value3: "69810", value4: "69811", name: "Capital Credit Union Biz-ND QBDC (69810)" },
    { value: "68874", value2: "68874", value3: "68874", value4: "68874", name: "Capital Farm Credit (68874)" },
    { value: "68869", value2: "68869", value3: "68869", value4: "68869", name: "Capital Markets (68869)" },
    { value: "07028", value2: "07028", value3: "07028", value4: "07028", name: "Capital One - Credit Cards (07028)" },
    { value: "17410", value2: "17410", value3: "17410", value4: "17410", name: "Capital One Bank - New (17410)" },
    { value: "07492", value2: "07492", value3: "07492", value4: "07492", name: "Capital One Card Services (07492)" },
    { value: "54696", value2: "54696", value3: "54696", value4: "54696", name: "Capital One Comm Card Connect (54696)" },
    { value: "12178", value2: "12178", value3: "12178", value4: "12178", name: "Capital One Commercial Services (12178)" },
    { value: "15496", value2: "15496", value3: "15496", value4: "15496", name: "Capital One Intellix Services (15496)" },
    { value: "53127", value2: "53127", value3: "53127", value4: "53127", name: "Capitol Bank Madison (53127)" },
    { value: "17310", value2: "17310", value3: "17310", value4: "17310", name: "Capitol Credit Union (17310)" },
    { value: "70130", value2: "70130", value3: "70130", value4: "70130", name: "Capitol Federal Business Direct (70130)" },
    { value: "13956", value2: "13956", value3: "13956", value4: "13956", name: "Capitol Federal Business Web (13956)" },
    { value: "54816", value2: "54816", value3: "54816", value4: "39801", name: "Capitol View CU_DC (54816)" },
    { value: "39801", value2: "39801", value3: "39801", value4: "39801", name: "Capitol View Credit Union Des M (39801)" },
    { value: "67852", value2: "67852", value3: "67852", value4: "67852", name: "Capon Valley Bank Business (67852)" },
    { value: "69774", value2: "69774", value3: "69774", value4: "69774", name: "Capra Bank (69774)" },
    { value: "70169", value2: "70169", value3: "70169", value4: "70169", name: "Capra Bank Business (70169)" },
    { value: "15606", value2: "15606", value3: "15606", value4: "15606", name: "Capstar Bank - BIB (15606)" },
    { value: "14584", value2: "14584", value3: "14584", value4: "14584", name: "Capstone Bank AL-QB (14584)" },
    { value: "03838", value2: "03838", value3: "03838", value4: "03838", name: "Card Download Services (03838)" },
    { value: "01867", value2: "01867", value3: "01867", value4: "01867", name: "Card Services Online (01867)" },
    { value: "69463", value2: "69463", value3: "69463", value4: "69463", name: "Caribe FCU Business (69463)" },
    { value: "69464", value2: "69464", value3: "69464", value4: "69464", name: "Caribe FCU Business QBDC (69464)" },
    { value: "24237", value2: "24237", value3: "24237", value4: "24237", name: "Caribe Federal Credit Union (24237)" },
    { value: "69462", value2: "69462", value3: "69462", value4: "69462", name: "Caribe Federal Credit Union QBDC (69462)" },
    { value: "04590", value2: "04590", value3: "04590", value4: "04590", name: "Caro Federal Credit Union (04590)" },
    { value: "09943", value2: "09943", value3: "09943", value4: "09943", name: "Carolina Bank and Trust (09943)" },
    { value: "02193", value2: "02193", value3: "02193", value4: "02193", name: "Carolina Foothills FCU (02193)" },
    { value: "04568", value2: "04568", value3: "04568", value4: "04568", name: "Carolinas Telco FCU (04568)" },
    { value: "01859", value2: "01859", value3: "01859", value4: "01859", name: "Carolinas Telco FCU credit card (01859)" },
    { value: "12257", value2: "12257", value3: "12257", value4: "12257", name: "Carroll County Trust Company (12257)" },
    { value: "16926", value2: "16926", value3: "16926", value4: "16926", name: "Carrollton Bank (16926)" },
    { value: "70204", value2: "70204", value3: "70204", value4: "70204", name: "Carrollton Bank - Business DC (70204)" },
    { value: "70203", value2: "70203", value3: "70203", value4: "70203", name: "Carrollton Bank - Business WC (70203)" },
    { value: "18171", value2: "18171", value3: "18171", value4: "18171", name: "Carrollton Bank DC (18171)" },
    { value: "67300", value2: "67300", value3: "67300", value4: "67300", name: "Carson Bank (67300)" },
    { value: "67121", value2: "67121", value3: "67121", value4: "67121", name: "Carson Community Bank (67121)" },
    { value: "17815", value2: "17815", value3: "17815", value4: "17815", name: "Carter Bank & Trust (17815)" },
    { value: "08855", value2: "08855", value3: "08855", value4: "08855", name: "Carter Bank & Trust - Treasury (08855)" },
    { value: "50565", value2: "50565", value3: "50565", value4: "50565", name: "Carter Credit Union (50565)" },
    { value: "69636", value2: "69636", value3: "69636", value4: "69636", name: "Carthage Savings (69636)" },
    { value: "69639", value2: "69639", value3: "69639", value4: "69636", name: "Carthage Savings - QBWC (69639)" },
    { value: "67965", value2: "67965", value3: "67965", value4: "67965", name: "Carver Federal Savings Bank (67965)" },
    { value: "05650", value2: "05650", value3: "05650", value4: "05650", name: "Cascade Community FCU (05650)" },
    { value: "16726", value2: "16726", value3: "16726", value4: "16726", name: "Cascade Federal Credit Union (16726)" },
    { value: "53493", value2: "53493", value3: "53493", value4: "53493", name: "Casco Federal Credit Union (53493)" },
    { value: "12789", value2: "12789", value3: "12789", value4: "12789", name: "Casey County Bank (12789)" },
    { value: "07328", value2: "07328", value3: "07328", value4: "07328", name: "Casey County Bank - DC (07328)" },
    { value: "32877", value2: "32877", value3: "32877", value4: "32877", name: "Casey State Bank (32877)" },
    { value: "01057", value2: "01057", value3: "01057", value4: "01057", name: "Cashmere Valley Bank (01057)" },
    { value: "04721", value2: "04721", value3: "04721", value4: "04721", name: "Cashmere Valley Bank-Direct (04721)" },
    { value: "16555", value2: "16555", value3: "16555", value4: "16555", name: "Cass Commercial Bank - BeB (16555)" },
    { value: "07657", value2: "07657", value3: "07657", value4: "07657", name: "Castle Rock Bank WC (07657)" },
    { value: "35478", value2: "35478", value3: "35478", value4: "35478", name: "Castroville State Bank (35478)" },
    { value: "68336", value2: "68336", value3: "68336", value4: "68336", name: "Catalyst Bank (68336)" },
    { value: "09617", value2: "09617", value3: "09617", value4: "09617", name: "Cathay Bank - Business Online Bank (09617)" },
    { value: "70181", value2: "70181", value3: "70181", value4: "70181", name: "Cathay Bank DC (70181)" },
    { value: "50430", value2: "50430", value3: "50430", value4: "50430", name: "Catholic Aid Association (50430)" },
    { value: "49602", value2: "49602", value3: "49602", value4: "49602", name: "Catskill Hudson Bank - NY (49602)" },
    { value: "30531", value2: "30531", value3: "30531", value4: "30531", name: "Cattaraugus County Bank NY (30531)" },
    { value: "24069", value2: "24069", value3: "24069", value4: "24069", name: "Cattle Bank & Trust (24069)" },
    { value: "08492", value2: "08492", value3: "08492", value4: "08492", name: "Cattlemans Bank Business Direct (08492)" },
    { value: "13701", value2: "13701", value3: "13701", value4: "13701", name: "Cattlemens Bank (13701)" },
    { value: "08498", value2: "08498", value3: "08498", value4: "08498", name: "Cattlemens Bank Business (08498)" },
    { value: "08495", value2: "08495", value3: "08495", value4: "08495", name: "Cattlemens Bank Direct (08495)" },
    { value: "68085", value2: "68085", value3: "68085", value4: "68085", name: "Cayuga Lake NB Business QB DC (68085)" },
    { value: "33003", value2: "33003", value3: "33003", value4: "33003", name: "Cayuga Lake NB Personal (33003)" },
    { value: "68014", value2: "68014", value3: "68014", value4: "68014", name: "Cayuga Lake NB Personal QB DC (68014)" },
    { value: "70430", value2: "70430", value3: "70430", value4: "70430", name: "Cayuga Lake National Bank BUS CC (70430)" },
    { value: "68084", value2: "68084", value3: "68084", value4: "68084", name: "Cayuga Lake National Bank Bus (68084)" },
    { value: "68082", value2: "68082", value3: "68082", value4: "68082", name: "Cedar Falls Community CU (68082)" },
    { value: "17749", value2: "17749", value3: "17749", value4: "17749", name: "Cedar Point FCU - MD (17749)" },
    { value: "70483", value2: "70483", value3: "70483", value4: "70483", name: "Cedar Point FCU MD QBDC (70483)" },
    { value: "18004", value2: "18004", value3: "18004", value4: "52638", name: "Cedar Rapids Bank & Trust - QB DC (18004)" },
    { value: "06881", value2: "06881", value3: "06881", value4: "06881", name: "Cedar Rapids Bank & Trust Ret. QB (06881)" },
    { value: "09613", value2: "09613", value3: "09613", value4: "09613", name: "Cedar Rapids Bank & Trust Retail (09613)" },
    { value: "52638", value2: "52638", value3: "52638", value4: "52638", name: "Cedar Rapids Bank & Trust-Business (52638)" },
    { value: "12101", value2: "12101", value3: "12101", value4: "12101", name: "Cedar Rapids State Bank (12101)" },
    { value: "12254", value2: "12254", value3: "12254", value4: "12101", name: "Cedar Rapids State Bank QB DC (12254)" },
    { value: "36600", value2: "36600", value3: "36600", value4: "36600", name: "Cedar Security Bank (36600)" },
    { value: "67190", value2: "67190", value3: "67190", value4: "36600", name: "Cedar Security Bank - DC (67190)" },
    { value: "67131", value2: "67131", value3: "67131", value4: "67131", name: "Cedar Valley Bank and Trust (67131)" },
    { value: "31941", value2: "31941", value3: "31941", value4: "31941", name: "CedarStone Bank (31941)" },
    { value: "34101", value2: "34101", value3: "34101", value4: "34101", name: "CenBank - MN (34101)" },
    { value: "65376", value2: "65376", value3: "65376", value4: "65376", name: "CenTrust Bank - Business (65376)" },
    { value: "69574", value2: "69574", value3: "69574", value4: "69574", name: "Cendera Bank NA BO (69574)" },
    { value: "05177", value2: "05177", value3: "05177", value4: "02851", name: "Centennial Bank - QBDC (05177)" },
    { value: "52761", value2: "52761", value3: "52761", value4: "52761", name: "Centennial Bank-TN (52761)" },
    { value: "02851", value2: "02851", value3: "02851", value4: "02851", name: "Centennial Bank-my100bank.com (02851)" },
    { value: "07139", value2: "07139", value3: "07139", value4: "07139", name: "Center National Bank (07139)" },
    { value: "12399", value2: "12399", value3: "12399", value4: "12399", name: "CenterBank - Milford (12399)" },
    { value: "03203", value2: "03203", value3: "03203", value4: "03203", name: "Centera Bank (03203)" },
    { value: "59421", value2: "59421", value3: "59421", value4: "59421", name: "Centier Bank - Business WC QB (59421)" },
    { value: "68683", value2: "68683", value3: "68683", value4: "68683", name: "Centier Bank IN (68683)" },
    { value: "68684", value2: "68684", value3: "68684", value4: "68683", name: "Centier Bank QBDC (68684)" },
    { value: "16753", value2: "16753", value3: "16753", value4: "16753", name: "Centra Credit Union (16753)" },
    { value: "13629", value2: "13629", value3: "13629", value4: "13629", name: "Central BOLO - Personal (13629)" },
    { value: "03476", value2: "03476", value3: "03476", value4: "03476", name: "Central Bank & Trust (03476)" },
    { value: "70508", value2: "70508", value3: "70508", value4: "70508", name: "Central Bank & Trust Bus CC (70508)" },
    { value: "63399", value2: "63399", value3: "63399", value4: "63399", name: "Central Bank - KY Consumer (63399)" },
    { value: "51681", value2: "51681", value3: "51681", value4: "51681", name: "Central Bank AR (51681)" },
    { value: "56100", value2: "56100", value3: "56100", value4: "56100", name: "Central Bank FL (56100)" },
    { value: "18780", value2: "18780", value3: "18780", value4: "18780", name: "Central Bank Houston (18780)" },
    { value: "67943", value2: "67943", value3: "67943", value4: "18780", name: "Central Bank Houston - QB DC (67943)" },
    { value: "70211", value2: "70211", value3: "70211", value4: "70211", name: "Central Bank IA & SD - Bus CC (70211)" },
    { value: "04830", value2: "04830", value3: "04830", value4: "04830", name: "Central Bank Illinois (04830)" },
    { value: "63405", value2: "63405", value3: "63405", value4: "63405", name: "Central Bank Jeff.Co KY Consumer (63405)" },
    { value: "63408", value2: "63408", value3: "63408", value4: "63408", name: "Central Bank Jeff.Co KY QBDirect (63408)" },
    { value: "70149", value2: "70149", value3: "70149", value4: "70149", name: "Central Bank MO - Bus (70149)" },
    { value: "06079", value2: "06079", value3: "06079", value4: "06079", name: "Central Bank MO - Personal (06079)" },
    { value: "10290", value2: "10290", value3: "10290", value4: "10290", name: "Central Bank Utah (10290)" },
    { value: "05530", value2: "05530", value3: "05530", value4: "05530", name: "Central Bank Utah QB WC PR-BB (05530)" },
    { value: "06481", value2: "06481", value3: "06481", value4: "06481", name: "Central Bank Utah QBDC BB (06481)" },
    { value: "13659", value2: "13659", value3: "13659", value4: "13659", name: "Central Bank of Audrain Co-Pers (13659)" },
    { value: "13623", value2: "13623", value3: "13623", value4: "13623", name: "Central Bank of Boone County-Pers (13623)" },
    { value: "13683", value2: "13683", value3: "13683", value4: "13683", name: "Central Bank of Branson - Pers (13683)" },
    { value: "07082", value2: "07082", value3: "07082", value4: "07082", name: "Central Bank of Kansas City (07082)" },
    { value: "07085", value2: "07085", value3: "07085", value4: "07082", name: "Central Bank of Kansas City WC (07085)" },
    { value: "13635", value2: "13635", value3: "13635", value4: "13635", name: "Central Bank of Moberly - Personal (13635)" },
    { value: "13641", value2: "13641", value3: "13641", value4: "13641", name: "Central Bank of Oklahoma - Pers (13641)" },
    { value: "13689", value2: "13689", value3: "13689", value4: "13689", name: "Central Bank of Sedalia - Personal (13689)" },
    { value: "13671", value2: "13671", value3: "13671", value4: "13671", name: "Central Bank of St. Louis - Pers (13671)" },
    { value: "13653", value2: "13653", value3: "13653", value4: "13653", name: "Central Bank of Warrensburg - Pers (13653)" },
    { value: "13665", value2: "13665", value3: "13665", value4: "13665", name: "Central Bank of the Midwest - Pers (13665)" },
    { value: "13647", value2: "13647", value3: "13647", value4: "13647", name: "Central Bank of the Ozarks - Pers (13647)" },
    { value: "63402", value2: "63402", value3: "63402", value4: "63402", name: "Central Bank, KY QB Direct (63402)" },
    { value: "06204", value2: "06204", value3: "06204", value4: "06204", name: "Central Bank, Kentucky - Business (06204)" },
    { value: "09161", value2: "09161", value3: "09161", value4: "09161", name: "Central Bank-Business (09161)" },
    { value: "63462", value2: "63462", value3: "63462", value4: "63462", name: "Central Bank-Centre Suite (63462)" },
    { value: "51981", value2: "51981", value3: "51981", value4: "51981", name: "Central Bank-IA (51981)" },
    { value: "17928", value2: "17928", value3: "17928", value4: "17928", name: "Central Bank-Savannah (17928)" },
    { value: "69479", value2: "69479", value3: "69479", value4: "69479", name: "Central Credit Union of Florida (69479)" },
    { value: "19488", value2: "19488", value3: "19488", value4: "19488", name: "Central Illinois Bank (19488)" },
    { value: "27597", value2: "27597", value3: "27597", value4: "27597", name: "Central Missouri Community CU (27597)" },
    { value: "51906", value2: "51906", value3: "51906", value4: "27597", name: "Central Missouri Community CUQB (51906)" },
    { value: "01058", value2: "01058", value3: "01058", value4: "01058", name: "Central National Bank (01058)" },
    { value: "04131", value2: "04131", value3: "04131", value4: "04131", name: "Central National Bank of Alva (04131)" },
    { value: "06644", value2: "06644", value3: "06644", value4: "06644", name: "Central National Bank-Waco (06644)" },
    { value: "06647", value2: "06647", value3: "06647", value4: "06644", name: "Central National Bank-Waco-WC (06647)" },
    { value: "50235", value2: "50235", value3: "50235", value4: "50235", name: "Central One FCU - QB (50235)" },
    { value: "67082", value2: "67082", value3: "67082", value4: "67082", name: "Central Pacific Bank - HI (67082)" },
    { value: "68812", value2: "68812", value3: "68812", value4: "68812", name: "Central Pacific Bank DC (68812)" },
    { value: "12730", value2: "12730", value3: "12730", value4: "12730", name: "Central State Bank (12730)" },
    { value: "09672", value2: "09672", value3: "09672", value4: "09672", name: "Central State Bank - Calera, AL (09672)" },
    { value: "68688", value2: "68688", value3: "68688", value4: "68688", name: "Central State Bank BO (68688)" },
    { value: "67755", value2: "67755", value3: "67755", value4: "67754", name: "Central State Bank Bus QBDC (67755)" },
    { value: "67754", value2: "67754", value3: "67754", value4: "67754", name: "Central State Bank Business (67754)" },
    { value: "67752", value2: "67752", value3: "67752", value4: "67752", name: "Central State Bank Personal (67752)" },
    { value: "67753", value2: "67753", value3: "67753", value4: "67752", name: "Central State Bk Per QBDC (67753)" },
    { value: "68868", value2: "68868", value3: "68868", value4: "68868", name: "Central Texas Farm Credit (68868)" },
    { value: "56922", value2: "56922", value3: "56922", value4: "56922", name: "Central VA  FCU (56922)" },
    { value: "67453", value2: "67453", value3: "67453", value4: "67453", name: "Central Valley Comm Bank (67453)" },
    { value: "67456", value2: "67456", value3: "67456", value4: "67453", name: "Central Valley Comm Bank QBDC (67456)" },
    { value: "07813", value2: "07813", value3: "07813", value4: "07813", name: "Central Willamette CU (07813)" },
    { value: "69702", value2: "69702", value3: "69702", value4: "07813", name: "Central Willamette CU - QBDC (69702)" },
    { value: "67766", value2: "67766", value3: "67766", value4: "67766", name: "Centreville Bank (67766)" },
    { value: "16375", value2: "16375", value3: "16375", value4: "09749", name: "Centreville Bank - QBWC (16375)" },
    { value: "17944", value2: "17944", value3: "17944", value4: "17944", name: "Centris Federal CU - QB DC (17944)" },
    { value: "17929", value2: "17929", value3: "17929", value4: "17929", name: "Centris Federal Credit Union (17929)" },
    { value: "18663", value2: "18663", value3: "18663", value4: "18663", name: "Century Bank  MS - WC (18663)" },
    { value: "09157", value2: "09157", value3: "09157", value4: "09157", name: "Century Bank & Trust (09157)" },
    { value: "67545", value2: "67545", value3: "67545", value4: "67545", name: "Century Bank NM (67545)" },
    { value: "67546", value2: "67546", value3: "67546", value4: "67545", name: "Century Bank NM QB (67546)" },
    { value: "68223", value2: "68223", value3: "68223", value4: "68223", name: "Century Bank and Trust BO (68223)" },
    { value: "14688", value2: "14688", value3: "14688", value4: "14688", name: "Century Bank and Trust RO (14688)" },
    { value: "23328", value2: "23328", value3: "23328", value4: "23328", name: "Century Bank of Georgia (23328)" },
    { value: "12098", value2: "12098", value3: "12098", value4: "23328", name: "Century Bank of Georgia QB DC (12098)" },
    { value: "04693", value2: "04693", value3: "04693", value4: "04693", name: "Century Bank of Kentucky (04693)" },
    { value: "51783", value2: "51783", value3: "51783", value4: "51783", name: "Century Federal Credit Union (51783)" },
    { value: "13287", value2: "13287", value3: "13287", value4: "13287", name: "Century Next Bank (13287)" },
    { value: "08810", value2: "08810", value3: "08810", value4: "08810", name: "Century Next Bank-DC (08810)" },
    { value: "06283", value2: "06283", value3: "06283", value4: "06283", name: "Century Savings Bank (06283)" },
    { value: "67850", value2: "67850", value3: "67850", value4: "03695", name: "Certified FCU DC (67850)" },
    { value: "03695", value2: "03695", value3: "03695", value4: "03695", name: "Certified FCU Quickbooks WC (03695)" },
    { value: "01745", value2: "01745", value3: "01745", value4: "01745", name: "Chain Bridge Bank (01745)" },
    { value: "15153", value2: "15153", value3: "15153", value4: "15153", name: "Chain Bridge Bank, N.A. (15153)" },
    { value: "67564", value2: "67564", value3: "67564", value4: "67564", name: "Chain Bridge Bank, N.A.-TM (67564)" },
    { value: "67565", value2: "67565", value3: "67565", value4: "67564", name: "Chain Bridge Bank, N.A.-TM-DC (67565)" },
    { value: "06872", value2: "06872", value3: "06872", value4: "06872", name: "Chambers Bank - Retail (06872)" },
    { value: "06908", value2: "06908", value3: "06908", value4: "06908", name: "Chambers Bank-Business Direct (06908)" },
    { value: "06905", value2: "06905", value3: "06905", value4: "06905", name: "Chambers Bank-Business Web (06905)" },
    { value: "20250", value2: "20250", value3: "20250", value4: "20250", name: "Champion Bank, Parker, CO (20250)" },
    { value: "26058", value2: "26058", value3: "26058", value4: "26058", name: "Champion Credit Union (26058)" },
    { value: "23337", value2: "23337", value3: "23337", value4: "23337", name: "Champlain National Bank (23337)" },
    { value: "38034", value2: "38034", value3: "38034", value4: "38034", name: "Changing Seasons FCU (38034)" },
    { value: "29508", value2: "29508", value3: "29508", value4: "29508", name: "Chappell Hill Bank (29508)" },
    { value: "05153", value2: "05153", value3: "05153", value4: "05153", name: "Charles River Bank (05153)" },
    { value: "63759", value2: "63759", value3: "63759", value4: "63759", name: "Charles River Bank - BUS (63759)" },
    { value: "02782", value2: "02782", value3: "02782", value4: "02782", name: "Charlevoix State Bank (02782)" },
    { value: "69340", value2: "69340", value3: "69340", value4: "69340", name: "Charlotte State Bank FL-DC (69340)" },
    { value: "03935", value2: "03935", value3: "03935", value4: "03935", name: "Charlotte State Bank, FL (03935)" },
    { value: "12446", value2: "12446", value3: "12446", value4: "12446", name: "Charter Bank IA - Business (12446)" },
    { value: "14202", value2: "14202", value3: "14202", value4: "14202", name: "Charter Oak Federal Credit Union (14202)" },
    { value: "64398", value2: "64398", value3: "64398", value4: "64398", name: "CharterWest Bank (64398)" },
    { value: "05975", value2: "05975", value3: "05975", value4: "05975", name: "Chartway Federal Credit Union (05975)" },
    { value: "69560", value2: "69560", value3: "69560", value4: "69560", name: "Chase Bank (All) - New (69560)" },
    { value: "02430", value2: "02430", value3: "02430", value4: "10898", name: "Chase Web Download (02430)" },
    { value: "14824", value2: "14824", value3: "14824", value4: "14824", name: "Chelsea Groton Bank (14824)" },
    { value: "15040", value2: "15040", value3: "15040", value4: "14824", name: "Chelsea Groton Bank - QB (15040)" },
    { value: "19533", value2: "19533", value3: "19533", value4: "19533", name: "Chelsea State Bank (19533)" },
    { value: "68270", value2: "68270", value3: "68270", value4: "19533", name: "Chelsea State Bank - DC (68270)" },
    { value: "11966", value2: "11966", value3: "11966", value4: "11966", name: "Chemung Canal Trust CO - Business (11966)" },
    { value: "04182", value2: "04182", value3: "04182", value4: "04182", name: "Chemung Canal Trust Company (04182)" },
    { value: "49800", value2: "49800", value3: "49800", value4: "49800", name: "Cheney Federal Credit Union (49800)" },
    { value: "01892", value2: "01892", value3: "01892", value4: "01892", name: "Cherokee State Bank (01892)" },
    { value: "01325", value2: "01325", value3: "01325", value4: "01325", name: "Chesapeake Bank (01325)" },
    { value: "67272", value2: "67272", value3: "67272", value4: "67272", name: "Chesapeake Bank QBDC (67272)" },
    { value: "12980", value2: "12980", value3: "12980", value4: "12980", name: "Chesapeake Bank Trust Co (12980)" },
    { value: "12983", value2: "12983", value3: "12983", value4: "12983", name: "Chesapeake Bank Trust Co Bus (12983)" },
    { value: "68776", value2: "68776", value3: "68776", value4: "68776", name: "Chevron FCU (68776)" },
    { value: "69644", value2: "69644", value3: "69644", value4: "68776", name: "Chevron FCU - QBWC (69644)" },
    { value: "06092", value2: "06092", value3: "06092", value4: "06092", name: "Cheyenne State Bank WC QB (06092)" },
    { value: "17686", value2: "17686", value3: "17686", value4: "17686", name: "Chickasaw Cmty Bank- Bus eBanking (17686)" },
    { value: "12492", value2: "12492", value3: "12492", value4: "12492", name: "Chickasaw Community Bank (12492)" },
    { value: "00417", value2: "00417", value3: "00417", value4: "00417", name: "Chino Commercial Bank (00417)" },
    { value: "11981", value2: "11981", value3: "11981", value4: "11981", name: "Chino Commmercial Bank Credit Card (11981)" },
    { value: "16372", value2: "16372", value3: "16372", value4: "16372", name: "Chiphone Federal Credit Union (16372)" },
    { value: "02789", value2: "02789", value3: "02789", value4: "02789", name: "Chippewa County CU Credit Card (02789)" },
    { value: "17511", value2: "17511", value3: "17511", value4: "17511", name: "Chiropractic Federal CU (17511)" },
    { value: "18135", value2: "18135", value3: "18135", value4: "18135", name: "Choice Financial (18135)" },
    { value: "56709", value2: "56709", value3: "56709", value4: "56709", name: "Choice Financial-Business Adv (56709)" },
    { value: "03018", value2: "03018", value3: "03018", value4: "03018", name: "ChoiceOne Bank (03018)" },
    { value: "64047", value2: "64047", value3: "64047", value4: "03018", name: "ChoiceOne Bank - DL (64047)" },
    { value: "69749", value2: "69749", value3: "69749", value4: "69749", name: "ChoiceOne Bank-Treasury (69749)" },
    { value: "69750", value2: "69750", value3: "69750", value4: "69749", name: "ChoiceOne Bank-Treasury-DC (69750)" },
    { value: "09646", value2: "09646", value3: "09646", value4: "09646", name: "Christian Community CU - BB (09646)" },
    { value: "69429", value2: "69429", value3: "69429", value4: "69429", name: "Christian Community CU - DC (69429)" },
    { value: "56421", value2: "56421", value3: "56421", value4: "56421", name: "Christian Family Credit Union (56421)" },
    { value: "68428", value2: "68428", value3: "68428", value4: "68428", name: "Christian Financial CU - DC (68428)" },
    { value: "69117", value2: "69117", value3: "69117", value4: "69117", name: "Christian Financial CU BB DC (69117)" },
    { value: "69116", value2: "69116", value3: "69116", value4: "69116", name: "Christian Financial CU QBWC (69116)" },
    { value: "04408", value2: "04408", value3: "04408", value4: "04408", name: "Ciera Bank (04408)" },
    { value: "63087", value2: "63087", value3: "63087", value4: "63087", name: "Ciera Bank-Business (63087)" },
    { value: "05573", value2: "05573", value3: "05573", value4: "05573", name: "Cincinnatus Savings and Loan (05573)" },
    { value: "03340", value2: "03340", value3: "03340", value4: "03340", name: "Cinfed Credit Union (03340)" },
    { value: "05634", value2: "05634", value3: "05634", value4: "05634", name: "Citadel Federal Credit Union (05634)" },
    { value: "04894", value2: "04894", value3: "04894", value4: "04894", name: "Citi Cards (04894)" },
    { value: "16276", value2: "16276", value3: "16276", value4: "16276", name: "Citi Cards QuickBooks (16276)" },
    { value: "04333", value2: "04333", value3: "04333", value4: "04333", name: "Citi Chairman Card (04333)" },
    { value: "67750", value2: "67750", value3: "67750", value4: "67750", name: "Citi Private Bank (67750)" },
    { value: "11569", value2: "11569", value3: "11569", value4: "11569", name: "CitiBusiness Banking (11569)" },
    { value: "11007", value2: "11007", value3: "11007", value4: "11007", name: "CitiBusiness Credit Cards (11007)" },
    { value: "02102", value2: "02102", value3: "02102", value4: "02102", name: "Citibank (02102)" },
    { value: "00118", value2: "00118", value3: "00118", value4: "00118", name: "Citizens (00118)" },
    { value: "10937", value2: "10937", value3: "10937", value4: "10937", name: "Citizens & Northern Bank (10937)" },
    { value: "68008", value2: "68008", value3: "68008", value4: "10937", name: "Citizens & Northern Bank QBDC (68008)" },
    { value: "04172", value2: "04172", value3: "04172", value4: "04172", name: "Citizens - Cameron Commercial (04172)" },
    { value: "08510", value2: "08510", value3: "08510", value4: "08510", name: "Citizens Alliance Bank (08510)" },
    { value: "69603", value2: "69603", value3: "69603", value4: "69603", name: "Citizens Alliance Bk QBDC (69603)" },
    { value: "53949", value2: "53949", value3: "53949", value4: "53949", name: "Citizens Bank & Trust Co (53949)" },
    { value: "50961", value2: "50961", value3: "50961", value4: "50961", name: "Citizens Bank & Trust Co - AR (50961)" },
    { value: "04929", value2: "04929", value3: "04929", value4: "04929", name: "Citizens Bank & Trust Co - MN (04929)" },
    { value: "05734", value2: "05734", value3: "05734", value4: "05734", name: "Citizens Bank & Trust Co. (05734)" },
    { value: "65097", value2: "65097", value3: "65097", value4: "65097", name: "Citizens Bank & Trust Co., Vivian (65097)" },
    { value: "19269", value2: "19269", value3: "19269", value4: "19269", name: "Citizens Bank & Trust Company - LA (19269)" },
    { value: "69399", value2: "69399", value3: "69399", value4: "69399", name: "Citizens Bank & Trust DC (69399)" },
    { value: "68437", value2: "68437", value3: "68437", value4: "68437", name: "Citizens Bank & Trust FL (68437)" },
    { value: "68438", value2: "68438", value3: "68438", value4: "68437", name: "Citizens Bank & Trust FL-WC (68438)" },
    { value: "57609", value2: "57609", value3: "57609", value4: "57609", name: "Citizens Bank - AL (57609)" },
    { value: "17148", value2: "17148", value3: "17148", value4: "17148", name: "Citizens Bank - Claude, TX (17148)" },
    { value: "03762", value2: "03762", value3: "03762", value4: "03762", name: "Citizens Bank - Corvallis OR (03762)" },
    { value: "08107", value2: "08107", value3: "08107", value4: "08107", name: "Citizens Bank - Farmington, NM (08107)" },
    { value: "10809", value2: "10809", value3: "10809", value4: "10809", name: "Citizens Bank - Mid Atl Bus. Web (10809)" },
    { value: "10808", value2: "10808", value3: "10808", value4: "10808", name: "Citizens Bank - Mid Atl Cons. Web (10808)" },
    { value: "10796", value2: "10796", value3: "10796", value4: "10796", name: "Citizens Bank - New Engl Bus. Web (10796)" },
    { value: "08978", value2: "08978", value3: "08978", value4: "08978", name: "Citizens Bank - New Haven MO (08978)" },
    { value: "08090", value2: "08090", value3: "08090", value4: "08090", name: "Citizens Bank - Sac City (08090)" },
    { value: "00965", value2: "00965", value3: "00965", value4: "00965", name: "Citizens Bank - Texas (00965)" },
    { value: "67206", value2: "67206", value3: "67206", value4: "67206", name: "Citizens Bank - Texas BO (67206)" },
    { value: "06911", value2: "06911", value3: "06911", value4: "06911", name: "Citizens Bank - WI (06911)" },
    { value: "06914", value2: "06914", value3: "06914", value4: "06911", name: "Citizens Bank - WI-DC (06914)" },
    { value: "01061", value2: "01061", value3: "01061", value4: "01061", name: "Citizens Bank 24 (01061)" },
    { value: "53625", value2: "53625", value3: "53625", value4: "53625", name: "Citizens Bank Amsterdam (53625)" },
    { value: "68556", value2: "68556", value3: "68556", value4: "68556", name: "Citizens Bank Ardmore Com (68556)" },
    { value: "68557", value2: "68557", value3: "68557", value4: "68557", name: "Citizens Bank Ardmore Com QB DC (68557)" },
    { value: "68558", value2: "68558", value3: "68558", value4: "68558", name: "Citizens Bank Ardmore Pers QB DC (68558)" },
    { value: "12278", value2: "12278", value3: "12278", value4: "12278", name: "Citizens Bank Carthage (12278)" },
    { value: "12507", value2: "12507", value3: "12507", value4: "12507", name: "Citizens Bank Minnesota (12507)" },
    { value: "24696", value2: "24696", value3: "24696", value4: "24696", name: "Citizens Bank Mooresville, IN (24696)" },
    { value: "02570", value2: "02570", value3: "02570", value4: "02570", name: "Citizens Bank Plaquemine-LA (02570)" },
    { value: "18624", value2: "18624", value3: "18624", value4: "18624", name: "Citizens Bank and Trust (18624)" },
    { value: "14751", value2: "14751", value3: "14751", value4: "14751", name: "Citizens Bank of Ada (14751)" },
    { value: "02165", value2: "02165", value3: "02165", value4: "14751", name: "Citizens Bank of Ada_DC (02165)" },
    { value: "34356", value2: "34356", value3: "34356", value4: "34356", name: "Citizens Bank of Americus (34356)" },
    { value: "36543", value2: "36543", value3: "36543", value4: "36543", name: "Citizens Bank of Chatsworth (36543)" },
    { value: "67085", value2: "67085", value3: "67085", value4: "67085", name: "Citizens Bank of Edmond (67085)" },
    { value: "59664", value2: "59664", value3: "59664", value4: "59664", name: "Citizens Bank of Forsyth Co (59664)" },
    { value: "03103", value2: "03103", value3: "03103", value4: "03103", name: "Citizens Bank of Kansas (03103)" },
    { value: "04045", value2: "04045", value3: "04045", value4: "04045", name: "Citizens Bank of Kentucky (04045)" },
    { value: "33849", value2: "33849", value3: "33849", value4: "33849", name: "Citizens Bank of Lafayette TN (33849)" },
    { value: "00966", value2: "00966", value3: "00966", value4: "00966", name: "Citizens Bank of Las Cruces (00966)" },
    { value: "67133", value2: "67133", value3: "67133", value4: "67133", name: "Citizens Bank of WV - QBDC (67133)" },
    { value: "62052", value2: "62052", value3: "62052", value4: "62052", name: "Citizens Bank of West Virginia (62052)" },
    { value: "67318", value2: "67318", value3: "67318", value4: "67318", name: "Citizens Bank of Weston (67318)" },
    { value: "67319", value2: "67319", value3: "67319", value4: "67319", name: "Citizens Bank of Weston QBDC (67319)" },
    { value: "02688", value2: "02688", value3: "02688", value4: "02688", name: "Citizens Bank of the South (02688)" },
    { value: "05290", value2: "05290", value3: "05290", value4: "05290", name: "Citizens Bank, Batesville, AR (05290)" },
    { value: "16788", value2: "16788", value3: "16788", value4: "16788", name: "Citizens Bank, Inc. (16788)" },
    { value: "14592", value2: "14592", value3: "14592", value4: "14592", name: "Citizens Bank, Mount Vernon, KY (14592)" },
    { value: "67556", value2: "67556", value3: "67556", value4: "67555", name: "Citizens Bank, N.A. QBDC (67556)" },
    { value: "67555", value2: "67555", value3: "67555", value4: "67555", name: "Citizens Bank, N.A. TX (67555)" },
    { value: "10795", value2: "10795", value3: "10795", value4: "10795", name: "Citizens Bank-Not Your TypicalBank (10795)" },
    { value: "52728", value2: "52728", value3: "52728", value4: "52728", name: "Citizens Bk&Trust Co Ardmore Pers (52728)" },
    { value: "00687", value2: "00687", value3: "00687", value4: "00687", name: "Citizens Business Bank - Online Banking (00687)" },
    { value: "03302", value2: "03302", value3: "03302", value4: "03302", name: "Citizens Business Bank - QB (03302)" },
    { value: "67598", value2: "67598", value3: "67598", value4: "67598", name: "Citizens Business Credit Card (67598)" },
    { value: "18001", value2: "18001", value3: "18001", value4: "18001", name: "Citizens Commercial Banking (18001)" },
    { value: "54810", value2: "54810", value3: "54810", value4: "54810", name: "Citizens Community Bank - MO (54810)" },
    { value: "23361", value2: "23361", value3: "23361", value4: "23361", name: "Citizens Community Bank IL (23361)" },
    { value: "68267", value2: "68267", value3: "68267", value4: "68267", name: "Citizens Community CU CC (68267)" },
    { value: "31890", value2: "31890", value3: "31890", value4: "31890", name: "Citizens Community Federal - Personal (31890)" },
    { value: "65073", value2: "65073", value3: "65073", value4: "65073", name: "Citizens Community Federal Bus (65073)" },
    { value: "09683", value2: "09683", value3: "09683", value4: "09683", name: "Citizens Community Federal Bus DC (09683)" },
    { value: "67580", value2: "67580", value3: "67580", value4: "67580", name: "Citizens Credit Card (67580)" },
    { value: "15012", value2: "15012", value3: "15012", value4: "15012", name: "Citizens Deposit Bank of Arlington (15012)" },
    { value: "55989", value2: "55989", value3: "55989", value4: "55989", name: "Citizens Federal Credit Union (55989)" },
    { value: "04727", value2: "04727", value3: "04727", value4: "04727", name: "Citizens Federal Savings & Loan (04727)" },
    { value: "17527", value2: "17527", value3: "17527", value4: "17527", name: "Citizens First Bank - BeB (17527)" },
    { value: "66148", value2: "66148", value3: "66148", value4: "66148", name: "Citizens First Bank IA - Bus (66148)" },
    { value: "68009", value2: "68009", value3: "68009", value4: "68009", name: "Citizens First Bank Iowa (68009)" },
    { value: "67058", value2: "67058", value3: "67058", value4: "66148", name: "Citizens First Bank Iowa - QB (67058)" },
    { value: "50226", value2: "50226", value3: "50226", value4: "50226", name: "Citizens First Bank-TheVillages QB (50226)" },
    { value: "69563", value2: "69563", value3: "69563", value4: "69563", name: "Citizens First Bk FL BeB Direct (69563)" },
    { value: "05987", value2: "05987", value3: "05987", value4: "05987", name: "Citizens Independent Bank (05987)" },
    { value: "70445", value2: "70445", value3: "70445", value4: "70445", name: "Citizens Nat'l Bluffton Bus CC (70445)" },
    { value: "15285", value2: "15285", value3: "15285", value4: "15285", name: "Citizens National Bank - Bossier (15285)" },
    { value: "51639", value2: "51639", value3: "51639", value4: "51639", name: "Citizens National Bank - MI (51639)" },
    { value: "64455", value2: "64455", value3: "64455", value4: "64455", name: "Citizens National Bank BeB (64455)" },
    { value: "64152", value2: "64152", value3: "64152", value4: "64152", name: "Citizens National Bank Credit Card (64152)" },
    { value: "02849", value2: "02849", value3: "02849", value4: "02849", name: "Citizens National Bank Hillsboro (02849)" },
    { value: "17175", value2: "17175", value3: "17175", value4: "17175", name: "Citizens National Bank Sevierville (17175)" },
    { value: "50097", value2: "50097", value3: "50097", value4: "50097", name: "Citizens National Bank of Albion (50097)" },
    { value: "67670", value2: "67670", value3: "67670", value4: "67670", name: "Citizens National Bank of TX DC (67670)" },
    { value: "11117", value2: "11117", value3: "11117", value4: "11117", name: "Citizens National Bank of Texas (11117)" },
    { value: "50877", value2: "50877", value3: "50877", value4: "50877", name: "Citizens National Bank-Brownwood (50877)" },
    { value: "17913", value2: "17913", value3: "17913", value4: "17913", name: "Citizens National Bank-Crockett (17913)" },
    { value: "02094", value2: "02094", value3: "02094", value4: "02094", name: "Citizens National Bank-Somerset,KY (02094)" },
    { value: "67247", value2: "67247", value3: "67247", value4: "51639", name: "Citizens National Bk QBDC (67247)" },
    { value: "09043", value2: "09043", value3: "09043", value4: "09043", name: "Citizens Natl Bank Meridian WQB (09043)" },
    { value: "70446", value2: "70446", value3: "70446", value4: "50877", name: "Citizens Natl Bnk-Brownwood QBDC (70446)" },
    { value: "15846", value2: "15846", value3: "15846", value4: "15846", name: "Citizens Ntl Bank of Bluffton (15846)" },
    { value: "67581", value2: "67581", value3: "67581", value4: "67581", name: "Citizens One Credit Card (67581)" },
    { value: "54708", value2: "54708", value3: "54708", value4: "54708", name: "Citizens Progressive Bank (54708)" },
    { value: "68418", value2: "68418", value3: "68418", value4: "68417", name: "Citizens Savings B&T QBDC (68418)" },
    { value: "02900", value2: "02900", value3: "02900", value4: "02900", name: "Citizens Savings Bank (02900)" },
    { value: "03548", value2: "03548", value3: "03548", value4: "03548", name: "Citizens Savings Bank - DC (03548)" },
    { value: "68417", value2: "68417", value3: "68417", value4: "68417", name: "Citizens Savings Bank and Trust (68417)" },
    { value: "01754", value2: "01754", value3: "01754", value4: "01754", name: "Citizens State Bank (01754)" },
    { value: "67213", value2: "67213", value3: "67213", value4: "67213", name: "Citizens State Bank & Trust (67213)" },
    { value: "67214", value2: "67214", value3: "67214", value4: "67214", name: "Citizens State Bank & Trust-DC (67214)" },
    { value: "67508", value2: "67508", value3: "67508", value4: "67508", name: "Citizens State Bank - Anton TX (67508)" },
    { value: "02976", value2: "02976", value3: "02976", value4: "02976", name: "Citizens State Bank - Buffalo, TX (02976)" },
    { value: "58908", value2: "58908", value3: "58908", value4: "58908", name: "Citizens State Bank - Kingsland (58908)" },
    { value: "64983", value2: "64983", value3: "64983", value4: "64983", name: "Citizens State Bank - Kingsland_DC (64983)" },
    { value: "12629", value2: "12629", value3: "12629", value4: "12629", name: "Citizens State Bank - Miles (12629)" },
    { value: "70170", value2: "70170", value3: "70170", value4: "12629", name: "Citizens State Bank - Miles QBDC (70170)" },
    { value: "14856", value2: "14856", value3: "14856", value4: "14856", name: "Citizens State Bank - Sealy (14856)" },
    { value: "09492", value2: "09492", value3: "09492", value4: "09492", name: "Citizens State Bank -Corrigan TX (09492)" },
    { value: "70440", value2: "70440", value3: "70440", value4: "70440", name: "Citizens State Bank Bus CC (70440)" },
    { value: "70281", value2: "70281", value3: "70281", value4: "70281", name: "Citizens State Bank Cadott BO (70281)" },
    { value: "70282", value2: "70282", value3: "70282", value4: "70282", name: "Citizens State Bank Cadott BODC (70282)" },
    { value: "00167", value2: "00167", value3: "00167", value4: "00167", name: "Citizens State Bank Cadott RO (00167)" },
    { value: "70280", value2: "70280", value3: "70280", value4: "70280", name: "Citizens State Bank Cadott RODC (70280)" },
    { value: "63312", value2: "63312", value3: "63312", value4: "63312", name: "Citizens State Bank Ganado QuickBk (63312)" },
    { value: "53211", value2: "53211", value3: "53211", value4: "53211", name: "Citizens State Bank La Crosse (53211)" },
    { value: "53214", value2: "53214", value3: "53214", value4: "53211", name: "Citizens State Bank La Crosse DC (53214)" },
    { value: "67158", value2: "67158", value3: "67158", value4: "67158", name: "Citizens State Bank Lankin (67158)" },
    { value: "67159", value2: "67159", value3: "67159", value4: "67159", name: "Citizens State Bank Lankin WC (67159)" },
    { value: "68943", value2: "68943", value3: "68943", value4: "68943", name: "Citizens State Bank MI Bus DC (68943)" },
    { value: "18237", value2: "18237", value3: "18237", value4: "18237", name: "Citizens State Bank Monticello (18237)" },
    { value: "09002", value2: "09002", value3: "09002", value4: "09002", name: "Citizens State Bank NE - Bus (09002)" },
    { value: "59601", value2: "59601", value3: "59601", value4: "59601", name: "Citizens State Bank NE - Retail (59601)" },
    { value: "07358", value2: "07358", value3: "07358", value4: "07358", name: "Citizens State Bank Norwood (07358)" },
    { value: "07361", value2: "07361", value3: "07361", value4: "07358", name: "Citizens State Bank Norwood-QBDC (07361)" },
    { value: "21207", value2: "21207", value3: "21207", value4: "21207", name: "Citizens State Bank Somerville (21207)" },
    { value: "69411", value2: "69411", value3: "69411", value4: "69411", name: "Citizens State Bank TX Bus QBDC (69411)" },
    { value: "02735", value2: "02735", value3: "02735", value4: "02735", name: "Citizens State Bank TX Pers (02735)" },
    { value: "20274", value2: "20274", value3: "20274", value4: "20274", name: "Citizens State Bank at Mohall (20274)" },
    { value: "03872", value2: "03872", value3: "03872", value4: "20274", name: "Citizens State Bank at Mohall_DC (03872)" },
    { value: "69410", value2: "69410", value3: "69410", value4: "69410", name: "Citizens State Bank for Business (69410)" },
    { value: "67400", value2: "67400", value3: "67400", value4: "67400", name: "Citizens State Bank of Colorado (67400)" },
    { value: "28392", value2: "28392", value3: "28392", value4: "28392", name: "Citizens State Bank of Loyal (28392)" },
    { value: "57129", value2: "57129", value3: "57129", value4: "57129", name: "Citizens State Bank of New Castle (57129)" },
    { value: "00288", value2: "00288", value3: "00288", value4: "00288", name: "Citizens State Bank, Hugoton, KS - Personal (00288)" },
    { value: "62157", value2: "62157", value3: "62157", value4: "62157", name: "Citizens State Bank, Luling (62157)" },
    { value: "64878", value2: "64878", value3: "64878", value4: "62157", name: "Citizens State Bank, Luling-DC (64878)" },
    { value: "64011", value2: "64011", value3: "64011", value4: "64011", name: "Citizens State Bank, MN (64011)" },
    { value: "62136", value2: "62136", value3: "62136", value4: "62136", name: "Citizens State Bank, Moundridge QB (62136)" },
    { value: "62229", value2: "62229", value3: "62229", value4: "62229", name: "Citizens State Bk & Trust - KS (62229)" },
    { value: "67401", value2: "67401", value3: "67401", value4: "67400", name: "Citizens State Bk Colorado QBDC (67401)" },
    { value: "01592", value2: "01592", value3: "01592", value4: "01592", name: "Citizens Tri-County Bank (01592)" },
    { value: "02399", value2: "02399", value3: "02399", value4: "02399", name: "Citizens Tri-County Bank - DL (02399)" },
    { value: "67149", value2: "67149", value3: "67149", value4: "67149", name: "Citizens Trust Bank BEB DC (67149)" },
    { value: "67148", value2: "67148", value3: "67148", value4: "67148", name: "Citizens Trust Bank BEB WC (67148)" },
    { value: "67067", value2: "67067", value3: "67067", value4: "67067", name: "Citizens Trust Bank CEB DC (67067)" },
    { value: "17385", value2: "17385", value3: "17385", value4: "17385", name: "City (17385)" },
    { value: "17653", value2: "17653", value3: "17653", value4: "17653", name: "City Bank (17653)" },
    { value: "08846", value2: "08846", value3: "08846", value4: "08846", name: "City Bank & Trust Co. (08846)" },
    { value: "08843", value2: "08843", value3: "08843", value4: "08843", name: "City Bank & Trust Co. Biz (08843)" },
    { value: "17656", value2: "17656", value3: "17656", value4: "17653", name: "City Bank QB (17656)" },
    { value: "57963", value2: "57963", value3: "57963", value4: "57963", name: "City Credit Union Credit Card (57963)" },
    { value: "17016", value2: "17016", value3: "17016", value4: "17016", name: "City First Bank of DC (17016)" },
    { value: "12305", value2: "12305", value3: "12305", value4: "12305", name: "City First Bank of DC N.A. (12305)" },
    { value: "09470", value2: "09470", value3: "09470", value4: "09470", name: "City National BSuite Bessentials (09470)" },
    { value: "15531", value2: "15531", value3: "15531", value4: "15531", name: "City National Bank - TX (15531)" },
    { value: "57348", value2: "57348", value3: "57348", value4: "57348", name: "City National Bank Credit Card (57348)" },
    { value: "00721", value2: "00721", value3: "00721", value4: "00721", name: "City National Bank of CA-Dir Con (00721)" },
    { value: "08191", value2: "08191", value3: "08191", value4: "08191", name: "City National Bank of FL (08191)" },
    { value: "10806", value2: "10806", value3: "10806", value4: "10806", name: "City National Bank of Florida (10806)" },
    { value: "05482", value2: "05482", value3: "05482", value4: "10806", name: "City National Bank of Florida WCQB (05482)" },
    { value: "68061", value2: "68061", value3: "68061", value4: "01209", name: "City National Bank of OK (68061)" },
    { value: "01209", value2: "01209", value3: "01209", value4: "01209", name: "City National Bank of Oklahoma (01209)" },
    { value: "03550", value2: "03550", value3: "03550", value4: "03550", name: "City National Bank of Taylor (03550)" },
    { value: "69991", value2: "69991", value3: "69991", value4: "03550", name: "City National Bank of Taylor DC (69991)" },
    { value: "11564", value2: "11564", value3: "11564", value4: "11564", name: "City National Bank of WV (11564)" },
    { value: "02405", value2: "02405", value3: "02405", value4: "11564", name: "City National Bank of WV - DL (02405)" },
    { value: "02945", value2: "02945", value3: "02945", value4: "02945", name: "City National Bank, Corsicana (02945)" },
    { value: "02948", value2: "02948", value3: "02948", value4: "02945", name: "City National Bank, Corsicana-DC (02948)" },
    { value: "51438", value2: "51438", value3: "51438", value4: "51438", name: "City National Bank-Card Manager (51438)" },
    { value: "69959", value2: "69959", value3: "69959", value4: "15531", name: "City National Bank-DC (69959)" },
    { value: "12573", value2: "12573", value3: "12573", value4: "12573", name: "City State Bank (12573)" },
    { value: "09421", value2: "09421", value3: "09421", value4: "09421", name: "City and County CU (09421)" },
    { value: "12226", value2: "12226", value3: "12226", value4: "09421", name: "City and County CU - New QB Direct (12226)" },
    { value: "59103", value2: "59103", value3: "59103", value4: "59103", name: "Citywide Banks - New (59103)" },
    { value: "70380", value2: "70380", value3: "70380", value4: "70380", name: "Civic FCU Business CC (70380)" },
    { value: "08753", value2: "08753", value3: "08753", value4: "08753", name: "Civic Federal Credit Union (08753)" },
    { value: "03776", value2: "03776", value3: "03776", value4: "03776", name: "Civista Bank (03776)" },
    { value: "68323", value2: "68323", value3: "68323", value4: "68323", name: "Civista Bank - QBDC (68323)" },
    { value: "50319", value2: "50319", value3: "50319", value4: "50319", name: "Clackamas County Bank - BB (50319)" },
    { value: "03528", value2: "03528", value3: "03528", value4: "03528", name: "Claremont Savings Bank (03528)" },
    { value: "70157", value2: "70157", value3: "70157", value4: "70157", name: "Claremont Savings Bank QBDC (70157)" },
    { value: "12914", value2: "12914", value3: "12914", value4: "12914", name: "Clarion County Comm Bus QBDC (12914)" },
    { value: "12908", value2: "12908", value3: "12908", value4: "12908", name: "Clarion County Comm Bus QBWC (12908)" },
    { value: "00714", value2: "00714", value3: "00714", value4: "00714", name: "Clarion County Comm Pers (00714)" },
    { value: "12911", value2: "12911", value3: "12911", value4: "12911", name: "Clarion County Comm Pers QBDC (12911)" },
    { value: "07955", value2: "07955", value3: "07955", value4: "07955", name: "Clarity CU (07955)" },
    { value: "56556", value2: "56556", value3: "56556", value4: "56556", name: "Clark County Credit Union (56556)" },
    { value: "17004", value2: "17004", value3: "17004", value4: "17004", name: "Classic Bank,NA (17004)" },
    { value: "67777", value2: "67777", value3: "67777", value4: "67777", name: "Classic City Bank Bus Direct (67777)" },
    { value: "67776", value2: "67776", value3: "67776", value4: "67776", name: "Classic City Bank Bus Web (67776)" },
    { value: "67775", value2: "67775", value3: "67775", value4: "67775", name: "Classic City Bank Retail Direct (67775)" },
    { value: "67774", value2: "67774", value3: "67774", value4: "67774", name: "Classic City Bank Retail Web (67774)" },
    { value: "36930", value2: "36930", value3: "36930", value4: "36930", name: "Clay County Savings Bank (36930)" },
    { value: "16564", value2: "16564", value3: "16564", value4: "16564", name: "Clayton Bank and Trust - WC QB (16564)" },
    { value: "01406", value2: "01406", value3: "01406", value4: "01406", name: "Clear Lake Bank And Trust Co. (01406)" },
    { value: "04317", value2: "04317", value3: "04317", value4: "04317", name: "Clear Mountain Bank (04317)" },
    { value: "69388", value2: "69388", value3: "69388", value4: "69388", name: "Clear Mountain Bank CC (69388)" },
    { value: "67207", value2: "67207", value3: "67207", value4: "67207", name: "Clear Mountain Bank WC (67207)" },
    { value: "49818", value2: "49818", value3: "49818", value4: "49818", name: "Clearview FCU (49818)" },
    { value: "51189", value2: "51189", value3: "51189", value4: "51189", name: "Clearview FCU - BB WCQB (51189)" },
    { value: "67251", value2: "67251", value3: "67251", value4: "67251", name: "Clearwater CU (67251)" },
    { value: "67252", value2: "67252", value3: "67252", value4: "67251", name: "Clearwater Credit Union - QB (67252)" },
    { value: "20028", value2: "20028", value3: "20028", value4: "20028", name: "Cleveland State Bank (20028)" },
    { value: "68288", value2: "68288", value3: "68288", value4: "68288", name: "Climate First Bank (68288)" },
    { value: "68290", value2: "68290", value3: "68290", value4: "68290", name: "Climate First Bank Biz (68290)" },
    { value: "68291", value2: "68291", value3: "68291", value4: "68291", name: "Climate First Bank Biz QBDC (68291)" },
    { value: "68289", value2: "68289", value3: "68289", value4: "68289", name: "Climate First Bank QBDC (68289)" },
    { value: "00584", value2: "00584", value3: "00584", value4: "00584", name: "Clinton Bank KY (00584)" },
    { value: "07276", value2: "07276", value3: "07276", value4: "07276", name: "Clinton Savings WC QB BB (07276)" },
    { value: "43905", value2: "43905", value3: "43905", value4: "43905", name: "Co-op CU (43905)" },
    { value: "13068", value2: "13068", value3: "13068", value4: "13068", name: "Co-op Credit Union (13068)" },
    { value: "62760", value2: "62760", value3: "62760", value4: "62760", name: "CoBank BeB (62760)" },
    { value: "19578", value2: "19578", value3: "19578", value4: "19578", name: "CoVantage Credit Union (19578)" },
    { value: "53475", value2: "53475", value3: "53475", value4: "53475", name: "Coast Central CU (53475)" },
    { value: "68681", value2: "68681", value3: "68681", value4: "68681", name: "Coast360 FCU - DC (68681)" },
    { value: "18838", value2: "18838", value3: "18838", value4: "18838", name: "CoastHills - QuickBooks WebConnect (18838)" },
    { value: "67163", value2: "67163", value3: "67163", value4: "67163", name: "CoastHills Business Direct (67163)" },
    { value: "18835", value2: "18835", value3: "18835", value4: "18835", name: "CoastHills Web-Direct (18835)" },
    { value: "63267", value2: "63267", value3: "63267", value4: "63267", name: "CoastLife CU QB (63267)" },
    { value: "19206", value2: "19206", value3: "19206", value4: "19206", name: "Coastal Bank & Trust (19206)" },
    { value: "51864", value2: "51864", value3: "51864", value4: "51864", name: "Coastal Carolina National Bank (51864)" },
    { value: "02355", value2: "02355", value3: "02355", value4: "02355", name: "Coastal Community Bank (02355)" },
    { value: "03200", value2: "03200", value3: "03200", value4: "03200", name: "Coastal Community Bank BEB (03200)" },
    { value: "69516", value2: "69516", value3: "69516", value4: "69516", name: "Coastal Community Bank Comm DC (69516)" },
    { value: "57795", value2: "57795", value3: "57795", value4: "57795", name: "Coastal Community Bank DC (57795)" },
    { value: "09250", value2: "09250", value3: "09250", value4: "09250", name: "Coastal Federal Credit Union (09250)" },
    { value: "64116", value2: "64116", value3: "64116", value4: "64116", name: "Coastal Heritage Bank (64116)" },
    { value: "57195", value2: "57195", value3: "57195", value4: "64116", name: "Coastal Heritage Bank - BUS (57195)" },
    { value: "09387", value2: "09387", value3: "09387", value4: "09387", name: "Coastal States Bank (09387)" },
    { value: "00614", value2: "00614", value3: "00614", value4: "00614", name: "Coastal States Bank-Direct Connect (00614)" },
    { value: "01832", value2: "01832", value3: "01832", value4: "01832", name: "Coastal1 Credit Union (01832)" },
    { value: "46857", value2: "46857", value3: "46857", value4: "46857", name: "Coastway Community Bank (46857)" },
    { value: "05083", value2: "05083", value3: "05083", value4: "05083", name: "Cobalt Credit Union (05083)" },
    { value: "09128", value2: "09128", value3: "09128", value4: "09128", name: "Coffee County Bank Bus (09128)" },
    { value: "17778", value2: "17778", value3: "17778", value4: "17778", name: "Cogent Bank (17778)" },
    { value: "12614", value2: "12614", value3: "12614", value4: "12614", name: "Cogent Bank Business DC (12614)" },
    { value: "07706", value2: "07706", value3: "07706", value4: "07706", name: "Cogent Bank Business WC (07706)" },
    { value: "00956", value2: "00956", value3: "00956", value4: "00956", name: "Colchester State Bank (00956)" },
    { value: "17001", value2: "17001", value3: "17001", value4: "17001", name: "Coleman County State Bank (17001)" },
    { value: "50307", value2: "50307", value3: "50307", value4: "50307", name: "Colfax Banking Co. (50307)" },
    { value: "70024", value2: "70024", value3: "70024", value4: "70024", name: "Collegiate Peaks Bank (70024)" },
    { value: "70025", value2: "70025", value3: "70025", value4: "70024", name: "Collegiate Peaks Bank - DC (70025)" },
    { value: "04250", value2: "04250", value3: "04250", value4: "04250", name: "Collins Community C.U. (04250)" },
    { value: "17503", value2: "17503", value3: "17503", value4: "17503", name: "Collins Community CU (17503)" },
    { value: "68909", value2: "68909", value3: "68909", value4: "68909", name: "Collins Community CU DC (68909)" },
    { value: "10471", value2: "10471", value3: "10471", value4: "10471", name: "Colonial Savings, F.A. (10471)" },
    { value: "11931", value2: "11931", value3: "11931", value4: "11931", name: "Colony Bank (11931)" },
    { value: "09374", value2: "09374", value3: "09374", value4: "09374", name: "Colony Bank Business (09374)" },
    { value: "09371", value2: "09371", value3: "09371", value4: "09371", name: "Colony Bank Business Direct (09371)" },
    { value: "09368", value2: "09368", value3: "09368", value4: "09368", name: "Colony Bank QB DC (09368)" },
    { value: "67451", value2: "67451", value3: "67451", value4: "67451", name: "Colorado Bank and Trust Co. QBDC (67451)" },
    { value: "11530", value2: "11530", value3: "11530", value4: "11530", name: "Colorado Credit Union (11530)" },
    { value: "18258", value2: "18258", value3: "18258", value4: "18258", name: "Coloramo FCU (18258)" },
    { value: "06197", value2: "06197", value3: "06197", value4: "06197", name: "Columbia Bank NJ Bus DC (06197)" },
    { value: "07516", value2: "07516", value3: "07516", value4: "07516", name: "Columbia Bank NJ Bus WC (07516)" },
    { value: "19272", value2: "19272", value3: "19272", value4: "19272", name: "Columbia Bank NJ QB Personal (19272)" },
    { value: "70439", value2: "70439", value3: "70439", value4: "70439", name: "Columbia Community CU Bus CC (70439)" },
    { value: "08723", value2: "08723", value3: "08723", value4: "08723", name: "Columbia Credit Union Credit Card (08723)" },
    { value: "17332", value2: "17332", value3: "17332", value4: "17332", name: "Columbia Credit Union QB (17332)" },
    { value: "62436", value2: "62436", value3: "62436", value4: "62436", name: "Columbus Bank & Trust Co. Biz (62436)" },
    { value: "53502", value2: "53502", value3: "53502", value4: "53502", name: "Columbus State Bank (53502)" },
    { value: "05601", value2: "05601", value3: "05601", value4: "05601", name: "Comerica (05601)" },
    { value: "16783", value2: "16783", value3: "16783", value4: "16783", name: "Comerica Bank - Treasury Services (16783)" },
    { value: "11128", value2: "11128", value3: "11128", value4: "11128", name: "Comerica Bank - Web Connect (11128)" },
    { value: "15793", value2: "15793", value3: "15793", value4: "15793", name: "Comerica Bank - eTreasury (15793)" },
    { value: "03393", value2: "03393", value3: "03393", value4: "03393", name: "Comm. Banking Co. of Fitzgerald (03393)" },
    { value: "68072", value2: "68072", value3: "68072", value4: "68072", name: "Commencement Bank (68072)" },
    { value: "68074", value2: "68074", value3: "68074", value4: "68074", name: "Commencement Bank QBDC (68074)" },
    { value: "11836", value2: "11836", value3: "11836", value4: "11836", name: "Commerce Bank - Corinth (11836)" },
    { value: "04001", value2: "04001", value3: "04001", value4: "04001", name: "Commerce Bank - MO, KS, IL (04001)" },
    { value: "00430", value2: "00430", value3: "00430", value4: "04001", name: "Commerce Bank - MO, KS, IL Web (00430)" },
    { value: "69461", value2: "69461", value3: "69461", value4: "69461", name: "Commerce Bank MN (69461)" },
    { value: "69800", value2: "69800", value3: "69800", value4: "69800", name: "Commerce Bank MN Bus DC (69800)" },
    { value: "68497", value2: "68497", value3: "68497", value4: "68497", name: "Commerce Bank and Trust WP BIZ (68497)" },
    { value: "68498", value2: "68498", value3: "68498", value4: "68498", name: "Commerce Bank and Trust WP BIZ DC (68498)" },
    { value: "10957", value2: "10957", value3: "10957", value4: "10957", name: "Commerce Bank of Arizona (10957)" },
    { value: "14955", value2: "14955", value3: "14955", value4: "14955", name: "Commerce Bank of Wyoming (14955)" },
    { value: "54477", value2: "54477", value3: "54477", value4: "54477", name: "Commerce Bank of Wyoming - Biz (54477)" },
    { value: "08060", value2: "08060", value3: "08060", value4: "08060", name: "CommerceOne Bank (08060)" },
    { value: "08063", value2: "08063", value3: "08063", value4: "08060", name: "CommerceOne Bank WC (08063)" },
    { value: "67677", value2: "67677", value3: "67677", value4: "67677", name: "CommerceOne Bank-TM (67677)" },
    { value: "67678", value2: "67678", value3: "67678", value4: "67677", name: "CommerceOne Bank-TM-DC (67678)" },
    { value: "02038", value2: "02038", value3: "02038", value4: "02038", name: "CommerceWest Bank (02038)" },
    { value: "67210", value2: "67210", value3: "67210", value4: "67210", name: "CommerceWest Bank - DC (67210)" },
    { value: "01291", value2: "01291", value3: "01291", value4: "01291", name: "Commercial Bank (01291)" },
    { value: "09712", value2: "09712", value3: "09712", value4: "09712", name: "Commercial Bank & Trust of PA QB (09712)" },
    { value: "00947", value2: "00947", value3: "00947", value4: "00947", name: "Commercial Bank - MI (00947)" },
    { value: "00809", value2: "00809", value3: "00809", value4: "00809", name: "Commercial Bank - Parsons KS (00809)" },
    { value: "07394", value2: "07394", value3: "07394", value4: "07394", name: "Commercial Bank - SC WC (07394)" },
    { value: "67594", value2: "67594", value3: "67594", value4: "01291", name: "Commercial Bank DC (67594)" },
    { value: "68666", value2: "68666", value3: "68666", value4: "68666", name: "Commercial Bank Digital One (68666)" },
    { value: "09875", value2: "09875", value3: "09875", value4: "09875", name: "Commercial Bank Stl (09875)" },
    { value: "69854", value2: "69854", value3: "69854", value4: "69854", name: "Commercial Bank TN - Consumer (69854)" },
    { value: "04355", value2: "04355", value3: "04355", value4: "04355", name: "Commercial Bank of CA -Business DC (04355)" },
    { value: "00456", value2: "00456", value3: "00456", value4: "00456", name: "Commercial Bank of CA-Business (00456)" },
    { value: "15519", value2: "15519", value3: "15519", value4: "15519", name: "Commercial Bank of Mott (15519)" },
    { value: "02689", value2: "02689", value3: "02689", value4: "02689", name: "Commercial Bank of Ozark (02689)" },
    { value: "05240", value2: "05240", value3: "05240", value4: "05240", name: "Commercial Bank of Ozark-DC (05240)" },
    { value: "12025", value2: "12025", value3: "12025", value4: "12025", name: "Commercial Bank of Texas - QB (12025)" },
    { value: "06586", value2: "06586", value3: "06586", value4: "06586", name: "Commercial Bank of Texas, NA-BBQB (06586)" },
    { value: "06132", value2: "06132", value3: "06132", value4: "06132", name: "Commercial Bank, Harrogate,TN (06132)" },
    { value: "17889", value2: "17889", value3: "17889", value4: "17889", name: "Commercial Banking Company (17889)" },
    { value: "70504", value2: "70504", value3: "70504", value4: "70504", name: "Commercial Capital Bank - DC (70504)" },
    { value: "69211", value2: "69211", value3: "69211", value4: "69211", name: "Commercial Capital Bank - WC (69211)" },
    { value: "03562", value2: "03562", value3: "03562", value4: "03562", name: "Commercial National Bank-Texarkana (03562)" },
    { value: "07712", value2: "07712", value3: "07712", value4: "07712", name: "Commercial Savings Bank, Carroll (07712)" },
    { value: "14376", value2: "14376", value3: "14376", value4: "14376", name: "Commercial State Bank-Palmer (14376)" },
    { value: "68930", value2: "68930", value3: "68930", value4: "68930", name: "Commodore Perry FCU (68930)" },
    { value: "12701", value2: "12701", value3: "12701", value4: "12701", name: "Common Cents Credit Union (12701)" },
    { value: "10617", value2: "10617", value3: "10617", value4: "10617", name: "CommonWealth Central CU (10617)" },
    { value: "63291", value2: "63291", value3: "63291", value4: "63291", name: "CommonWealth One FCU (63291)" },
    { value: "12740", value2: "12740", value3: "12740", value4: "12740", name: "Commonwealth Business Bank - DC (12740)" },
    { value: "11993", value2: "11993", value3: "11993", value4: "11993", name: "Commonwealth Business Bank-BEB (11993)" },
    { value: "04541", value2: "04541", value3: "04541", value4: "04541", name: "Commonwealth Community Bank (04541)" },
    { value: "10295", value2: "10295", value3: "10295", value4: "10295", name: "Commonwealth Credit Union (10295)" },
    { value: "69579", value2: "69579", value3: "69579", value4: "69579", name: "Communication FCU - OK (69579)" },
    { value: "16354", value2: "16354", value3: "16354", value4: "16354", name: "Community 1st Bk NILA Bus (16354)" },
    { value: "17757", value2: "17757", value3: "17757", value4: "17757", name: "Community 1st CU (17757)" },
    { value: "09098", value2: "09098", value3: "09098", value4: "09098", name: "Community 1st Natl Bk - KS Bus (09098)" },
    { value: "06929", value2: "06929", value3: "06929", value4: "06929", name: "Community BT - Waterloo - QB (06929)" },
    { value: "18220", value2: "18220", value3: "18220", value4: "14599", name: "Community BT - Waterloo - QB DC (18220)" },
    { value: "14599", value2: "14599", value3: "14599", value4: "14599", name: "Community BT - Waterloo-Business (14599)" },
    { value: "12198", value2: "12198", value3: "12198", value4: "12198", name: "Community BT - Waterloo-Personal (12198)" },
    { value: "05964", value2: "05964", value3: "05964", value4: "05964", name: "Community Bank & Trust (05964)" },
    { value: "17274", value2: "17274", value3: "17274", value4: "17274", name: "Community Bank & Trust - Waco TX (17274)" },
    { value: "69667", value2: "69667", value3: "69667", value4: "69667", name: "Community Bank & Trust WGA (69667)" },
    { value: "63696", value2: "63696", value3: "63696", value4: "63696", name: "Community Bank & Trust, IA (63696)" },
    { value: "68086", value2: "68086", value3: "68086", value4: "68086", name: "Community Bank - Bristow OK (68086)" },
    { value: "08543", value2: "08543", value3: "08543", value4: "08543", name: "Community Bank - Joseph OR (08543)" },
    { value: "67806", value2: "67806", value3: "67806", value4: "67806", name: "Community Bank Alva Business (67806)" },
    { value: "68997", value2: "68997", value3: "68997", value4: "68997", name: "Community Bank Bus DC (68997)" },
    { value: "69356", value2: "69356", value3: "69356", value4: "69356", name: "Community Bank Bus WC (69356)" },
    { value: "49680", value2: "49680", value3: "49680", value4: "49680", name: "Community Bank Carmichaels RO (49680)" },
    { value: "06036", value2: "06036", value3: "06036", value4: "06036", name: "Community Bank Delaware (06036)" },
    { value: "05645", value2: "05645", value3: "05645", value4: "05645", name: "Community Bank Joseph OR-WC (05645)" },
    { value: "04415", value2: "04415", value3: "04415", value4: "04415", name: "Community Bank NA (04415)" },
    { value: "07871", value2: "07871", value3: "07871", value4: "07871", name: "Community Bank Topeka - DC (07871)" },
    { value: "04157", value2: "04157", value3: "04157", value4: "04157", name: "Community Bank of Elmhurst - BIB (04157)" },
    { value: "06860", value2: "06860", value3: "06860", value4: "06860", name: "Community Bank of Georgia - DC (06860)" },
    { value: "50106", value2: "50106", value3: "50106", value4: "50106", name: "Community Bank of Lexington TN (50106)" },
    { value: "51468", value2: "51468", value3: "51468", value4: "50106", name: "Community Bank of Lexington TN -DC (51468)" },
    { value: "04433", value2: "04433", value3: "04433", value4: "04433", name: "Community Bank of Louisiana BeB (04433)" },
    { value: "04349", value2: "04349", value3: "04349", value4: "04349", name: "Community Bank of Louisiana CeB (04349)" },
    { value: "24867", value2: "24867", value3: "24867", value4: "24867", name: "Community Bank of Mankato (24867)" },
    { value: "02834", value2: "02834", value3: "02834", value4: "02834", name: "Community Bank of Mankato-Bus (02834)" },
    { value: "19467", value2: "19467", value3: "19467", value4: "19467", name: "Community Bank of Mississippi (19467)" },
    { value: "12248", value2: "12248", value3: "12248", value4: "19467", name: "Community Bank of Mississippi DC (12248)" },
    { value: "57024", value2: "57024", value3: "57024", value4: "57024", name: "Community Bank of Oklahoma (57024)" },
    { value: "34491", value2: "34491", value3: "34491", value4: "34491", name: "Community Bank of Parkersburg (34491)" },
    { value: "69041", value2: "69041", value3: "69041", value4: "69041", name: "Community Bank of Parkersburg-DC (69041)" },
    { value: "12810", value2: "12810", value3: "12810", value4: "12810", name: "Community Bank of Pleasant Hill (12810)" },
    { value: "56475", value2: "56475", value3: "56475", value4: "56475", name: "Community Bank of Raymore MO (56475)" },
    { value: "14913", value2: "14913", value3: "14913", value4: "14913", name: "Community Bank of Santa Maria (14913)" },
    { value: "22935", value2: "22935", value3: "22935", value4: "22935", name: "Community Bank of Wichita (22935)" },
    { value: "64749", value2: "64749", value3: "64749", value4: "22935", name: "Community Bank of Wichita DC (64749)" },
    { value: "04389", value2: "04389", value3: "04389", value4: "04389", name: "Community Bank of the Bay (04389)" },
    { value: "12041", value2: "12041", value3: "12041", value4: "12041", name: "Community Bank of the Bay-Business (12041)" },
    { value: "53856", value2: "53856", value3: "53856", value4: "53856", name: "Community Bank of the Ozarks (53856)" },
    { value: "68679", value2: "68679", value3: "68679", value4: "68679", name: "Community Bank of the South FL NEW (68679)" },
    { value: "03329", value2: "03329", value3: "03329", value4: "03329", name: "Community Bank, Winslow IL (03329)" },
    { value: "12069", value2: "12069", value3: "12069", value4: "12069", name: "Community Bank,N.A.Business Online (12069)" },
    { value: "12647", value2: "12647", value3: "12647", value4: "12647", name: "Community Bank-Longview QB (12647)" },
    { value: "03409", value2: "03409", value3: "03409", value4: "03409", name: "Community Bank-Topeka (03409)" },
    { value: "64023", value2: "64023", value3: "64023", value4: "64023", name: "Community Banks of Colorado BeB (64023)" },
    { value: "68636", value2: "68636", value3: "68636", value4: "68636", name: "Community Bk of the South FL QBDC (68636)" },
    { value: "08249", value2: "08249", value3: "08249", value4: "08249", name: "Community CU (08249)" },
    { value: "07625", value2: "07625", value3: "07625", value4: "07625", name: "Community Choice CU - Consumer (07625)" },
    { value: "02883", value2: "02883", value3: "02883", value4: "02883", name: "Community Choice CU, MI (02883)" },
    { value: "68929", value2: "68929", value3: "68929", value4: "68929", name: "Community Credit Union DC (68929)" },
    { value: "70495", value2: "70495", value3: "70495", value4: "70495", name: "Community Credit Union FL WC QB (70495)" },
    { value: "01368", value2: "01368", value3: "01368", value4: "01368", name: "Community Credit Union of FL (01368)" },
    { value: "56919", value2: "56919", value3: "56919", value4: "56919", name: "Community Credit Union of Lynn (56919)" },
    { value: "63702", value2: "63702", value3: "63702", value4: "63702", name: "Community Credit Union of Lynn-BO (63702)" },
    { value: "04058", value2: "04058", value3: "04058", value4: "04058", name: "Community Development Bank - BUS (04058)" },
    { value: "06851", value2: "06851", value3: "06851", value4: "06851", name: "Community Fin. Srvcs Credit Card (06851)" },
    { value: "08882", value2: "08882", value3: "08882", value4: "08882", name: "Community Financial Bank (08882)" },
    { value: "68068", value2: "68068", value3: "68068", value4: "68068", name: "Community First Bank - Butler MO (68068)" },
    { value: "68657", value2: "68657", value3: "68657", value4: "68657", name: "Community First Bank - WA (68657)" },
    { value: "55887", value2: "55887", value3: "55887", value4: "55887", name: "Community First Bank IN (55887)" },
    { value: "68134", value2: "68134", value3: "68134", value4: "55887", name: "Community First Bank IN Kokomo (68134)" },
    { value: "12134", value2: "12134", value3: "12134", value4: "12134", name: "Community First Bank LA - DC (12134)" },
    { value: "68515", value2: "68515", value3: "68515", value4: "68515", name: "Community First Bank MN (68515)" },
    { value: "68516", value2: "68516", value3: "68516", value4: "68516", name: "Community First Bank MN QBDC (68516)" },
    { value: "67584", value2: "67584", value3: "67584", value4: "67584", name: "Community First Bank Nebraska (67584)" },
    { value: "04352", value2: "04352", value3: "04352", value4: "04352", name: "Community First Bank SC Business (04352)" },
    { value: "67773", value2: "67773", value3: "67773", value4: "67773", name: "Community First Bank, N.A. BO (67773)" },
    { value: "02939", value2: "02939", value3: "02939", value4: "02939", name: "Community First Bank, N.A. RO (02939)" },
    { value: "09836", value2: "09836", value3: "09836", value4: "09836", name: "Community First Bank-BeB DC (09836)" },
    { value: "57099", value2: "57099", value3: "57099", value4: "57099", name: "Community First Banking Co (57099)" },
    { value: "00455", value2: "00455", value3: "00455", value4: "00455", name: "Community First CU (00455)" },
    { value: "00073", value2: "00073", value3: "00073", value4: "00073", name: "Community First CU of FL (00073)" },
    { value: "10229", value2: "10229", value3: "10229", value4: "10229", name: "Community First Credit Union (10229)" },
    { value: "12738", value2: "12738", value3: "12738", value4: "12738", name: "Community First National Bk - KS (12738)" },
    { value: "68658", value2: "68658", value3: "68658", value4: "68658", name: "Community First WA QBDC (68658)" },
    { value: "01344", value2: "01344", value3: "01344", value4: "01344", name: "Community National Bank (01344)" },
    { value: "11550", value2: "11550", value3: "11550", value4: "11550", name: "Community National Bank & Trust (11550)" },
    { value: "16503", value2: "16503", value3: "16503", value4: "16503", name: "Community National Bank Seneca (16503)" },
    { value: "63285", value2: "63285", value3: "63285", value4: "63285", name: "Community National Bank TN-BQB (63285)" },
    { value: "17994", value2: "17994", value3: "17994", value4: "17994", name: "Community Natl Bank & Trust of TX (17994)" },
    { value: "67192", value2: "67192", value3: "67192", value4: "67192", name: "Community Resource BK Bus Direct (67192)" },
    { value: "67191", value2: "67191", value3: "67191", value4: "67191", name: "Community Resource BK Bus Web (67191)" },
    { value: "67165", value2: "67165", value3: "67165", value4: "67165", name: "Community Resource Bank RO DC (67165)" },
    { value: "67164", value2: "67164", value3: "67164", value4: "67164", name: "Community Resource Bank Web (67164)" },
    { value: "67998", value2: "67998", value3: "67998", value4: "67998", name: "Community Savings Bank - IA - DC (67998)" },
    { value: "06386", value2: "06386", value3: "06386", value4: "06386", name: "Community Savings Bank - OH (06386)" },
    { value: "06389", value2: "06389", value3: "06389", value4: "06386", name: "Community Savings Bank - OH WC (06389)" },
    { value: "49872", value2: "49872", value3: "49872", value4: "49872", name: "Community Savings Bank-IA (49872)" },
    { value: "68646", value2: "68646", value3: "68646", value4: "68646", name: "Community Service CU - QB (68646)" },
    { value: "69339", value2: "69339", value3: "69339", value4: "69339", name: "Community Service CU CC (69339)" },
    { value: "52140", value2: "52140", value3: "52140", value4: "52140", name: "Community Service Credit Union (52140)" },
    { value: "69465", value2: "69465", value3: "69465", value4: "69465", name: "Community Spirit Bank (69465)" },
    { value: "69473", value2: "69473", value3: "69473", value4: "69465", name: "Community Spirit Bank - QBDC (69473)" },
    { value: "56586", value2: "56586", value3: "56586", value4: "56586", name: "Community St Bank FL- QB (56586)" },
    { value: "10527", value2: "10527", value3: "10527", value4: "10527", name: "Community State Bank - IL (10527)" },
    { value: "70150", value2: "70150", value3: "70150", value4: "70150", name: "Community State Bank AR (70150)" },
    { value: "70151", value2: "70151", value3: "70151", value4: "70150", name: "Community State Bank AR - DC (70151)" },
    { value: "70248", value2: "70248", value3: "70248", value4: "70248", name: "Community State Bank Canton RO (70248)" },
    { value: "15904", value2: "15904", value3: "15904", value4: "15904", name: "Community State Bank IA (15904)" },
    { value: "07385", value2: "07385", value3: "07385", value4: "07385", name: "Community State Bank IA - retail (07385)" },
    { value: "07427", value2: "07427", value3: "07427", value4: "07421", name: "Community State Bank IA-Bus QB (07427)" },
    { value: "07421", value2: "07421", value3: "07421", value4: "07421", name: "Community State Bank IA-Business (07421)" },
    { value: "63426", value2: "63426", value3: "63426", value4: "63426", name: "Community State Bank IA-Retail (63426)" },
    { value: "09718", value2: "09718", value3: "09718", value4: "09718", name: "Community State Bank IN (09718)" },
    { value: "69637", value2: "69637", value3: "69637", value4: "69637", name: "Community State Bank IN QBDC (69637)" },
    { value: "25215", value2: "25215", value3: "25215", value4: "25215", name: "Community State Bank WI (25215)" },
    { value: "17547", value2: "17547", value3: "17547", value4: "17547", name: "Community State Bank of Lamar (17547)" },
    { value: "07265", value2: "07265", value3: "07265", value4: "07265", name: "Community State Bank-Business (07265)" },
    { value: "18576", value2: "18576", value3: "18576", value4: "18576", name: "Community State Bank-IL (18576)" },
    { value: "17898", value2: "17898", value3: "17898", value4: "17898", name: "Community State Bank-Retail (17898)" },
    { value: "10773", value2: "10773", value3: "10773", value4: "10773", name: "Community Trust Bank, Inc (10773)" },
    { value: "70020", value2: "70020", value3: "70020", value4: "70020", name: "Community Unity Bank (70020)" },
    { value: "70021", value2: "70021", value3: "70021", value4: "70020", name: "Community Unity Bank QBDC (70021)" },
    { value: "07573", value2: "07573", value3: "07573", value4: "07573", name: "Community Valley Bank (07573)" },
    { value: "67262", value2: "67262", value3: "67262", value4: "67262", name: "Community Valley Bank - QB (67262)" },
    { value: "00315", value2: "00315", value3: "00315", value4: "00315", name: "Community West Bank (00315)" },
    { value: "03089", value2: "03089", value3: "03089", value4: "03089", name: "CommunityAmerica CU Credit Card (03089)" },
    { value: "14383", value2: "14383", value3: "14383", value4: "14383", name: "CommunityAmerica CU Direct (14383)" },
    { value: "14380", value2: "14380", value3: "14380", value4: "14380", name: "CommunityAmerica CU Quickbooks (14380)" },
    { value: "67542", value2: "67542", value3: "67542", value4: "67542", name: "Compeer Financial Direct Connect (67542)" },
    { value: "67543", value2: "67543", value3: "67543", value4: "67543", name: "Compeer Financial WC (67543)" },
    { value: "00668", value2: "00668", value3: "00668", value4: "00668", name: "Complex Community FCU-QB (00668)" },
    { value: "68137", value2: "68137", value3: "68137", value4: "68137", name: "Concorde Bank Retail (68137)" },
    { value: "50928", value2: "50928", value3: "50928", value4: "50928", name: "Concordia Bank & Trust Company (50928)" },
    { value: "54510", value2: "54510", value3: "54510", value4: "54510", name: "Congressional Federal CU-Web (54510)" },
    { value: "02931", value2: "02931", value3: "02931", value4: "02931", name: "Congressional Federal Credit Union (02931)" },
    { value: "67648", value2: "67648", value3: "67648", value4: "67648", name: "Congressional Federal DC - Plus (67648)" },
    { value: "18568", value2: "18568", value3: "18568", value4: "18568", name: "Congressional Federal WC - Plus (18568)" },
    { value: "03911", value2: "03911", value3: "03911", value4: "03911", name: "ConnectOne Bank QuickBooks (03911)" },
    { value: "13158", value2: "13158", value3: "13158", value4: "13158", name: "ConnectOne Bank-BIB (13158)" },
    { value: "63852", value2: "63852", value3: "63852", value4: "63852", name: "Connected Credit Union (63852)" },
    { value: "07900", value2: "07900", value3: "07900", value4: "07900", name: "Connecticut Community Bank - BB (07900)" },
    { value: "56793", value2: "56793", value3: "56793", value4: "56793", name: "Connection Credit Union (56793)" },
    { value: "03657", value2: "03657", value3: "03657", value4: "03657", name: "Connections Credit Union (03657)" },
    { value: "53772", value2: "53772", value3: "53772", value4: "53772", name: "Connects FCU (53772)" },
    { value: "51009", value2: "51009", value3: "51009", value4: "51009", name: "Conservation Employees' CU - CC (51009)" },
    { value: "12056", value2: "12056", value3: "12056", value4: "12056", name: "Consolidated Community CU (12056)" },
    { value: "69972", value2: "69972", value3: "69972", value4: "69972", name: "Consumer Cooperative FCU (69972)" },
    { value: "68117", value2: "68117", value3: "68117", value4: "68117", name: "Consumers CU Credit Card (68117)" },
    { value: "14899", value2: "14899", value3: "14899", value4: "14899", name: "Consumers Credit Union of Michigan (14899)" },
    { value: "11448", value2: "11448", value3: "11448", value4: "11448", name: "Consumers Credit Union-Illinois (11448)" },
    { value: "06599", value2: "06599", value3: "06599", value4: "06599", name: "Consumers National Bank-Business (06599)" },
    { value: "08951", value2: "08951", value3: "08951", value4: "08951", name: "Consumers National Bank-QB DC (08951)" },
    { value: "67953", value2: "67953", value3: "67953", value4: "67953", name: "Continental Bank (67953)" },
    { value: "13968", value2: "13968", value3: "13968", value4: "13968", name: "Converse County Bank,Douglas,WY (13968)" },
    { value: "64416", value2: "64416", value3: "64416", value4: "13968", name: "Converse County Bank,Douglas-DC (64416)" },
    { value: "25689", value2: "25689", value3: "25689", value4: "25689", name: "Conway Bank (25689)" },
    { value: "25695", value2: "25695", value3: "25695", value4: "25695", name: "Coosa Pines FCU (25695)" },
    { value: "07616", value2: "07616", value3: "07616", value4: "07616", name: "Copiah Bank (07616)" },
    { value: "64809", value2: "64809", value3: "64809", value4: "64809", name: "CorTrust Bank - Business (64809)" },
    { value: "68692", value2: "68692", value3: "68692", value4: "68692", name: "CorTrust Bank CC (68692)" },
    { value: "16167", value2: "16167", value3: "16167", value4: "16167", name: "Coral Community FCU (16167)" },
    { value: "68429", value2: "68429", value3: "68429", value4: "68429", name: "Coral Community FCU CC (68429)" },
    { value: "16557", value2: "16557", value3: "16557", value4: "16557", name: "Core Bank - BIB (16557)" },
    { value: "05272", value2: "05272", value3: "05272", value4: "05272", name: "CoreFirst Bank & Trust (05272)" },
    { value: "67772", value2: "67772", value3: "67772", value4: "67772", name: "Corn Growers State Bank (67772)" },
    { value: "18486", value2: "18486", value3: "18486", value4: "18486", name: "CornerStone Bank - VA (18486)" },
    { value: "04260", value2: "04260", value3: "04260", value4: "04260", name: "Cornerstone Bank (Overland Park) - Consumer (04260)" },
    { value: "00576", value2: "00576", value3: "00576", value4: "00576", name: "Cornerstone Bank - QB WC (00576)" },
    { value: "69538", value2: "69538", value3: "69538", value4: "69538", name: "Cornerstone Bank DC (69538)" },
    { value: "68341", value2: "68341", value3: "68341", value4: "68341", name: "Cornerstone Bank IA (68341)" },
    { value: "03191", value2: "03191", value3: "03191", value4: "03191", name: "Cornerstone Bank MA (03191)" },
    { value: "07037", value2: "07037", value3: "07037", value4: "07037", name: "Cornerstone Bank ND-SD -Business (07037)" },
    { value: "07040", value2: "07040", value3: "07040", value4: "07040", name: "Cornerstone Bank ND-SD -Personal (07040)" },
    { value: "13137", value2: "13137", value3: "13137", value4: "13137", name: "Cornerstone Bank NJ (13137)" },
    { value: "14901", value2: "14901", value3: "14901", value4: "14901", name: "Cornerstone Bank Nebraska (14901)" },
    { value: "02873", value2: "02873", value3: "02873", value4: "02873", name: "Cornerstone Bank VA Business (02873)" },
    { value: "69658", value2: "69658", value3: "69658", value4: "69658", name: "Cornerstone Capital Bank (69658)" },
    { value: "69663", value2: "69663", value3: "69663", value4: "69663", name: "Cornerstone Capital Bank Biz (69663)" },
    { value: "69666", value2: "69666", value3: "69666", value4: "69663", name: "Cornerstone Capital Biz QBDC (69666)" },
    { value: "69659", value2: "69659", value3: "69659", value4: "69658", name: "Cornerstone Capital QBDC (69659)" },
    { value: "11979", value2: "11979", value3: "11979", value4: "11979", name: "Cornerstone Community Bank CA (11979)" },
    { value: "02361", value2: "02361", value3: "02361", value4: "02361", name: "Cornerstone Community FCU (02361)" },
    { value: "11604", value2: "11604", value3: "11604", value4: "11604", name: "Cornerstone Credit Union (11604)" },
    { value: "70345", value2: "70345", value3: "70345", value4: "70345", name: "Cornerstone Credit Union - DC (70345)" },
    { value: "05996", value2: "05996", value3: "05996", value4: "05996", name: "Cornerstone Financial CU (05996)" },
    { value: "51366", value2: "51366", value3: "51366", value4: "51366", name: "Cornerstone National B&T Co (51366)" },
    { value: "08871", value2: "08871", value3: "08871", value4: "08871", name: "Cornhusker Bank (08871)" },
    { value: "11261", value2: "11261", value3: "11261", value4: "11261", name: "Corning Federal Credit Union (11261)" },
    { value: "64818", value2: "64818", value3: "64818", value4: "64818", name: "Corry Federal Credit Union (64818)" },
    { value: "58830", value2: "58830", value3: "58830", value4: "58830", name: "Cottonwood Community Federal CU (58830)" },
    { value: "04782", value2: "04782", value3: "04782", value4: "04782", name: "Coulee Bank (04782)" },
    { value: "69903", value2: "69903", value3: "69903", value4: "69903", name: "Coulee Bank - TM (69903)" },
    { value: "69904", value2: "69904", value3: "69904", value4: "69903", name: "Coulee Bank-TM-DC (69904)" },
    { value: "68112", value2: "68112", value3: "68112", value4: "04782", name: "Coulee State Bank-WI (68112)" },
    { value: "08056", value2: "08056", value3: "08056", value4: "08056", name: "Country Bank for Savings (08056)" },
    { value: "70139", value2: "70139", value3: "70139", value4: "70139", name: "Country Bank for Savings Bus (70139)" },
    { value: "70140", value2: "70140", value3: "70140", value4: "70139", name: "Country Bank for Savings Bus QB (70140)" },
    { value: "08059", value2: "08059", value3: "08059", value4: "08056", name: "Country Bank for Savings_DC (08059)" },
    { value: "53037", value2: "53037", value3: "53037", value4: "53037", name: "Country Club Bank (53037)" },
    { value: "53148", value2: "53148", value3: "53148", value4: "53037", name: "Country Club Bank DC (53148)" },
    { value: "52842", value2: "52842", value3: "52842", value4: "52842", name: "Country Club Bank-Business (52842)" },
    { value: "62367", value2: "62367", value3: "62367", value4: "62367", name: "County Bank - Business Online (62367)" },
    { value: "12696", value2: "12696", value3: "12696", value4: "12696", name: "County Bank Business WC (12696)" },
    { value: "06104", value2: "06104", value3: "06104", value4: "06104", name: "County Bank, Sigourney, IA (06104)" },
    { value: "03168", value2: "03168", value3: "03168", value4: "03168", name: "Countybank (03168)" },
    { value: "70237", value2: "70237", value3: "70237", value4: "03168", name: "Countybank QBDC (70237)" },
    { value: "64755", value2: "64755", value3: "64755", value4: "64755", name: "Coventry CU Credit Card (64755)" },
    { value: "59694", value2: "59694", value3: "59694", value4: "59694", name: "Covington County Bank (59694)" },
    { value: "53097", value2: "53097", value3: "53097", value4: "53097", name: "Cowboy State Bank - WY (53097)" },
    { value: "67277", value2: "67277", value3: "67277", value4: "67277", name: "Craft Bank (67277)" },
    { value: "69514", value2: "69514", value3: "69514", value4: "69514", name: "Craft Bank-DC (69514)" },
    { value: "67522", value2: "67522", value3: "67522", value4: "67522", name: "Craft Bank-TM (67522)" },
    { value: "69515", value2: "69515", value3: "69515", value4: "69515", name: "Craft Bank-TM-DC (69515)" },
    { value: "11121", value2: "11121", value3: "11121", value4: "11121", name: "Crane Credit Union (11121)" },
    { value: "05365", value2: "05365", value3: "05365", value4: "05365", name: "Crawford County Bank (05365)" },
    { value: "13216", value2: "13216", value3: "13216", value4: "13216", name: "Credit Human (13216)" },
    { value: "02848", value2: "02848", value3: "02848", value4: "02848", name: "Credit Union 1 (02848)" },
    { value: "69984", value2: "69984", value3: "69984", value4: "02848", name: "Credit Union 1 - DC (69984)" },
    { value: "62826", value2: "62826", value3: "62826", value4: "62826", name: "Credit Union 1 Alaska (62826)" },
    { value: "12509", value2: "12509", value3: "12509", value4: "12509", name: "Credit Union ONE Michigan (12509)" },
    { value: "57069", value2: "57069", value3: "57069", value4: "57069", name: "Credit Union West (57069)" },
    { value: "01195", value2: "01195", value3: "01195", value4: "01195", name: "Credit Union of America (01195)" },
    { value: "08267", value2: "08267", value3: "08267", value4: "08267", name: "Credit Union of Colorado (08267)" },
    { value: "08387", value2: "08387", value3: "08387", value4: "08387", name: "Credit Union of Colorado - QB (08387)" },
    { value: "11705", value2: "11705", value3: "11705", value4: "11705", name: "Credit Union of Denver (11705)" },
    { value: "11470", value2: "11470", value3: "11470", value4: "11470", name: "Credit Union of Georgia (11470)" },
    { value: "70441", value2: "70441", value3: "70441", value4: "70441", name: "Credit Union of Georgia Bus CC (70441)" },
    { value: "70271", value2: "70271", value3: "70271", value4: "70271", name: "Credit Union of Georgia DC (70271)" },
    { value: "70406", value2: "70406", value3: "70406", value4: "70406", name: "Credit Union of Georgia QBWC (70406)" },
    { value: "12246", value2: "12246", value3: "12246", value4: "12246", name: "Credit Union of S. Cal.-Credit (12246)" },
    { value: "16882", value2: "16882", value3: "16882", value4: "16882", name: "Credit Union of Texas (16882)" },
    { value: "09148", value2: "09148", value3: "09148", value4: "09148", name: "Crest Savings Bank (09148)" },
    { value: "03884", value2: "03884", value3: "03884", value4: "03884", name: "Crews Bank & Trust (03884)" },
    { value: "69336", value2: "69336", value3: "69336", value4: "69336", name: "Crews Bank & Trust-DC (69336)" },
    { value: "05082", value2: "05082", value3: "05082", value4: "05082", name: "Croghan Colonial Bank (05082)" },
    { value: "70381", value2: "70381", value3: "70381", value4: "70381", name: "Croghan Colonial Bank Bus CC (70381)" },
    { value: "59073", value2: "59073", value3: "59073", value4: "59073", name: "Croghan Colonial Bank Credit Card (59073)" },
    { value: "52566", value2: "52566", value3: "52566", value4: "52566", name: "Cross Bank (52566)" },
    { value: "08534", value2: "08534", value3: "08534", value4: "08534", name: "Cross County Savings Bank (08534)" },
    { value: "11895", value2: "11895", value3: "11895", value4: "11895", name: "Cross Keys Bank (11895)" },
    { value: "51471", value2: "51471", value3: "51471", value4: "11895", name: "Cross Keys Bank - DC (51471)" },
    { value: "70152", value2: "70152", value3: "70152", value4: "70152", name: "Cross River Bank (70152)" },
    { value: "69685", value2: "69685", value3: "69685", value4: "69685", name: "CrossFirst Bank (69685)" },
    { value: "00266", value2: "00266", value3: "00266", value4: "54126", name: "CrossFirst Bank QB (00266)" },
    { value: "69686", value2: "69686", value3: "69686", value4: "69686", name: "CrossFirst Bank QBDC (69686)" },
    { value: "67895", value2: "67895", value3: "67895", value4: "67895", name: "CrossRoads Financial FCU (67895)" },
    { value: "18351", value2: "18351", value3: "18351", value4: "18351", name: "Crossbridge Community Bank (18351)" },
    { value: "50490", value2: "50490", value3: "50490", value4: "50490", name: "Crossroads Bank - IN (50490)" },
    { value: "70471", value2: "70471", value3: "70471", value4: "70470", name: "Crossroads Bank - IN Bis QBDC (70471)" },
    { value: "70470", value2: "70470", value3: "70470", value4: "70470", name: "Crossroads Bank - IN Bus (70470)" },
    { value: "70469", value2: "70469", value3: "70469", value4: "50490", name: "Crossroads Bank - IN QBDC (70469)" },
    { value: "59409", value2: "59409", value3: "59409", value4: "59409", name: "Crossroads Bank - Yoakum TX (59409)" },
    { value: "35532", value2: "35532", value3: "35532", value4: "35532", name: "Crossroads Bank Effingham, IL (35532)" },
    { value: "53925", value2: "53925", value3: "53925", value4: "53925", name: "Crowell State Bank of Crowell TX (53925)" },
    { value: "14049", value2: "14049", value3: "14049", value4: "14049", name: "Crown Bank (14049)" },
    { value: "03205", value2: "03205", value3: "03205", value4: "03205", name: "Crystal Lake Bank & Trust Bus (03205)" },
    { value: "03840", value2: "03840", value3: "03840", value4: "03840", name: "Crystal Lake Bank Trust IBB (03840)" },
    { value: "01307", value2: "01307", value3: "01307", value4: "01307", name: "Cullman Savings Bank BUS (01307)" },
    { value: "17457", value2: "17457", value3: "17457", value4: "17457", name: "Cumberland County Federal CU (17457)" },
    { value: "18105", value2: "18105", value3: "18105", value4: "18105", name: "Cumberland Federal Bank (18105)" },
    { value: "68944", value2: "68944", value3: "68944", value4: "68944", name: "Cumberland Federal Bank Bus (68944)" },
    { value: "24702", value2: "24702", value3: "24702", value4: "24702", name: "Cumberland Security Bank (24702)" },
    { value: "01462", value2: "01462", value3: "01462", value4: "01462", name: "Cumberland Valley Nat'l Bank (01462)" },
    { value: "64890", value2: "64890", value3: "64890", value4: "64890", name: "Currency Bank (64890)" },
    { value: "65163", value2: "65163", value3: "65163", value4: "65163", name: "Currie State Bank, MN (65163)" },
    { value: "65166", value2: "65166", value3: "65166", value4: "65166", name: "Currie State Bank, MN DC (65166)" },
    { value: "18889", value2: "18889", value3: "18889", value4: "18889", name: "Customers Bank - Business (18889)" },
    { value: "12638", value2: "12638", value3: "12638", value4: "12638", name: "Customers Bank - DC (12638)" },
    { value: "68525", value2: "68525", value3: "68525", value4: "68525", name: "Cypress Bank & Trust (68525)" },
    { value: "68526", value2: "68526", value3: "68526", value4: "68525", name: "Cypress Bank & Trust QBDC (68526)" },
    { value: "03074", value2: "03074", value3: "03074", value4: "03074", name: "Cyprus Credit Union-QB (03074)" },
    { value: "54228", value2: "54228", value3: "54228", value4: "54228", name: "Cyprus Federal Credit Union (54228)" },
    { value: "10874", value2: "10874", value3: "10874", value4: "10874", name: "D.L. Evans Bank (10874)" },
    { value: "69836", value2: "69836", value3: "69836", value4: "69836", name: "D.L. Evans Bank Bus CC (69836)" },
    { value: "09664", value2: "09664", value3: "09664", value4: "09664", name: "DATCU (09664)" },
    { value: "14944", value2: "14944", value3: "14944", value4: "14944", name: "DCCU - DuPont Community CU - VA (14944)" },
    { value: "15022", value2: "15022", value3: "15022", value4: "15022", name: "DCCU DuPont Community CU-WC (15022)" },
    { value: "08518", value2: "08518", value3: "08518", value4: "08518", name: "DFCU Financial (08518)" },
    { value: "69883", value2: "69883", value3: "69883", value4: "69883", name: "DFCU Financial QB DC (69883)" },
    { value: "06932", value2: "06932", value3: "06932", value4: "06932", name: "DLP Bank (06932)" },
    { value: "65253", value2: "65253", value3: "65253", value4: "65253", name: "DNB National Bank (65253)" },
    { value: "05720", value2: "05720", value3: "05720", value4: "05720", name: "DR Bank (05720)" },
    { value: "63624", value2: "63624", value3: "63624", value4: "63624", name: "DR Bank - Web (63624)" },
    { value: "12308", value2: "12308", value3: "12308", value4: "12308", name: "DSB Business QB (12308)" },
    { value: "68778", value2: "68778", value3: "68778", value4: "68778", name: "Dacotah Bank credit card (68778)" },
    { value: "11081", value2: "11081", value3: "11081", value4: "11081", name: "Dacotah Banks, Inc. (11081)" },
    { value: "00638", value2: "00638", value3: "00638", value4: "00638", name: "Dade County FCU (00638)" },
    { value: "53832", value2: "53832", value3: "53832", value4: "53832", name: "Dairy State Bank - WI (53832)" },
    { value: "02372", value2: "02372", value3: "02372", value4: "02372", name: "Dakota Community Bank & Trust BO (02372)" },
    { value: "15888", value2: "15888", value3: "15888", value4: "15888", name: "Dakota Community Bank & Trust RO (15888)" },
    { value: "67827", value2: "67827", value3: "67827", value4: "67827", name: "Dakota Community Bank BO QB DC (67827)" },
    { value: "67826", value2: "67826", value3: "67826", value4: "67826", name: "Dakota Community Bank RO QB DC (67826)" },
    { value: "12083", value2: "12083", value3: "12083", value4: "12083", name: "Dakota Heritage Bank (12083)" },
    { value: "01679", value2: "01679", value3: "01679", value4: "01679", name: "Dakota Plains CU - Edgeley ND (01679)" },
    { value: "57045", value2: "57045", value3: "57045", value4: "57045", name: "Dakota Plains FCU (57045)" },
    { value: "68121", value2: "68121", value3: "68121", value4: "68121", name: "Dakota Prairie Bank BO (68121)" },
    { value: "63843", value2: "63843", value3: "63843", value4: "63843", name: "Dakota Prairie Bank RO (63843)" },
    { value: "07466", value2: "07466", value3: "07466", value4: "07466", name: "Dakota West Credit Union (07466)" },
    { value: "70080", value2: "70080", value3: "70080", value4: "70080", name: "Dakota West Credit Union BB DC (70080)" },
    { value: "70081", value2: "70081", value3: "70081", value4: "70081", name: "Dakota West Credit Union QB (70081)" },
    { value: "00281", value2: "00281", value3: "00281", value4: "00281", name: "Dakota Western Bank-Retail (00281)" },
    { value: "65265", value2: "65265", value3: "65265", value4: "65265", name: "Dakota Western-Business (65265)" },
    { value: "16599", value2: "16599", value3: "16599", value4: "16599", name: "Dakotaland Federal Credit Union (16599)" },
    { value: "00482", value2: "00482", value3: "00482", value4: "00482", name: "Dallas Capital Bank - TX DC (00482)" },
    { value: "00479", value2: "00479", value3: "00479", value4: "00479", name: "Dallas Capital Bank - TX WC (00479)" },
    { value: "09811", value2: "09811", value3: "09811", value4: "09811", name: "Dallas Capital Bank- TX (09811)" },
    { value: "27885", value2: "27885", value3: "27885", value4: "27885", name: "Davis Trust Company (27885)" },
    { value: "69638", value2: "69638", value3: "69638", value4: "27885", name: "Davis Trust Company QBDC (69638)" },
    { value: "68922", value2: "68922", value3: "68922", value4: "68922", name: "Dawson Co-op Credit Union (68922)" },
    { value: "68923", value2: "68923", value3: "68923", value4: "68923", name: "Dawson Co-op Credit Union QBDC (68923)" },
    { value: "10945", value2: "10945", value3: "10945", value4: "10945", name: "Day Air Credit Union (10945)" },
    { value: "52281", value2: "52281", value3: "52281", value4: "52281", name: "DayMet Credit Union (52281)" },
    { value: "62529", value2: "62529", value3: "62529", value4: "62529", name: "Dayspring Bank (62529)" },
    { value: "17622", value2: "17622", value3: "17622", value4: "17622", name: "Dayspring Bank Bus (17622)" },
    { value: "36252", value2: "36252", value3: "36252", value4: "36252", name: "DeMotte State Bank (36252)" },
    { value: "02936", value2: "02936", value3: "02936", value4: "02936", name: "DeWitt Bank &Trust Co. (02936)" },
    { value: "01829", value2: "01829", value3: "01829", value4: "01829", name: "Dean Bank (01829)" },
    { value: "16186", value2: "16186", value3: "16186", value4: "16186", name: "Dearborn FSB QB WC (16186)" },
    { value: "16123", value2: "16123", value3: "16123", value4: "16123", name: "Dearborn FSB WC  DC (16123)" },
    { value: "30153", value2: "30153", value3: "30153", value4: "30153", name: "Decatur County Bank TN BO (30153)" },
    { value: "08153", value2: "08153", value3: "08153", value4: "08153", name: "Decatur County Bank TN RO (08153)" },
    { value: "03607", value2: "03607", value3: "03607", value4: "03607", name: "Decorah Bank and Trust Company (03607)" },
    { value: "50805", value2: "50805", value3: "50805", value4: "50805", name: "Dedham Savings - Business (50805)" },
    { value: "18781", value2: "18781", value3: "18781", value4: "18781", name: "Dedham Savings-Direct Connect (18781)" },
    { value: "05102", value2: "05102", value3: "05102", value4: "05102", name: "Dedicated Community Bank (05102)" },
    { value: "69335", value2: "69335", value3: "69335", value4: "69335", name: "Dedicated Community Bank QBDC (69335)" },
    { value: "68340", value2: "68340", value3: "68340", value4: "68340", name: "Deerwood Bank - Business QBDC (68340)" },
    { value: "68339", value2: "68339", value3: "68339", value4: "68339", name: "Deerwood Bank Business (68339)" },
    { value: "09941", value2: "09941", value3: "09941", value4: "09941", name: "Deerwood Bank DC (09941)" },
    { value: "09938", value2: "09938", value3: "09938", value4: "09938", name: "Deerwood Bank WC (09938)" },
    { value: "08078", value2: "08078", value3: "08078", value4: "08078", name: "Del Norte Bank (08078)" },
    { value: "67606", value2: "67606", value3: "67606", value4: "67606", name: "Del Norte Credit Union (67606)" },
    { value: "68156", value2: "68156", value3: "68156", value4: "68156", name: "Del-One Federal CU (68156)" },
    { value: "18621", value2: "18621", value3: "18621", value4: "18621", name: "Delaware National Bank of Delhi (18621)" },
    { value: "17629", value2: "17629", value3: "17629", value4: "17629", name: "Delaware State Police FCU (17629)" },
    { value: "09783", value2: "09783", value3: "09783", value4: "09783", name: "Delta Bank (09783)" },
    { value: "17347", value2: "17347", value3: "17347", value4: "17347", name: "Delta Community Credit Union (17347)" },
    { value: "17359", value2: "17359", value3: "17359", value4: "17359", name: "Delta Community QB Web Connect (17359)" },
    { value: "17011", value2: "17011", value3: "17011", value4: "17011", name: "Democracy Federal Credit Union (17011)" },
    { value: "01506", value2: "01506", value3: "01506", value4: "01506", name: "Denali State Bank (01506)" },
    { value: "09919", value2: "09919", value3: "09919", value4: "09919", name: "Denison State Bank (09919)" },
    { value: "04236", value2: "04236", value3: "04236", value4: "04236", name: "Denver Fire Dept. FCU (04236)" },
    { value: "45873", value2: "45873", value3: "45873", value4: "45873", name: "Desco FCU (45873)" },
    { value: "63150", value2: "63150", value3: "63150", value4: "45873", name: "Desco FCU - BUS (63150)" },
    { value: "68641", value2: "68641", value3: "68641", value4: "68641", name: "Desco FCU Credit Card (68641)" },
    { value: "11672", value2: "11672", value3: "11672", value4: "11672", name: "Deseret First Credit Union (11672)" },
    { value: "15727", value2: "15727", value3: "15727", value4: "15727", name: "Desert Financial Credit Union (15727)" },
    { value: "12065", value2: "12065", value3: "12065", value4: "12065", name: "Desert Rivers Credit Union (12065)" },
    { value: "53631", value2: "53631", value3: "53631", value4: "53631", name: "Desert Valleys FCU (53631)" },
    { value: "67072", value2: "67072", value3: "67072", value4: "67072", name: "Desjardins Bank BB (67072)" },
    { value: "70172", value2: "70172", value3: "70172", value4: "70172", name: "Desjardins Bank BB-Retail (70172)" },
    { value: "70173", value2: "70173", value3: "70173", value4: "70173", name: "Desjardins Bank BB-Retail WC (70173)" },
    { value: "13812", value2: "13812", value3: "13812", value4: "13812", name: "Deutsche Bank Wealth Management (13812)" },
    { value: "67892", value2: "67892", value3: "67892", value4: "67892", name: "Devon Bank Business (67892)" },
    { value: "67893", value2: "67893", value3: "67893", value4: "67893", name: "Devon Bank Business DC (67893)" },
    { value: "67891", value2: "67891", value3: "67891", value4: "67891", name: "Devon Bank DC (67891)" },
    { value: "67828", value2: "67828", value3: "67828", value4: "67828", name: "Devon Bank IL (67828)" },
    { value: "18843", value2: "18843", value3: "18843", value4: "18843", name: "Diamond Bank - AR (18843)" },
    { value: "15058", value2: "15058", value3: "15058", value4: "15058", name: "Diamond Credit Union (15058)" },
    { value: "15061", value2: "15061", value3: "15061", value4: "15058", name: "Diamond Credit Union - QB (15061)" },
    { value: "02184", value2: "02184", value3: "02184", value4: "02184", name: "Dieterich Bank (02184)" },
    { value: "69525", value2: "69525", value3: "69525", value4: "02184", name: "Dieterich Bank QBDC (69525)" },
    { value: "11506", value2: "11506", value3: "11506", value4: "11506", name: "Digital Federal Credit Union - QB (11506)" },
    { value: "03008", value2: "03008", value3: "03008", value4: "03008", name: "Dime Bank (03008)" },
    { value: "03011", value2: "03011", value3: "03011", value4: "03008", name: "Dime Bank - QB (03011)" },
    { value: "03131", value2: "03131", value3: "03131", value4: "03131", name: "Dime Community Bank - Bus Banking (03131)" },
    { value: "68818", value2: "68818", value3: "68818", value4: "68818", name: "Dime Community Bank - Bus Direct (68818)" },
    { value: "68827", value2: "68827", value3: "68827", value4: "68827", name: "Dime Community Bank Personal DC (68827)" },
    { value: "68828", value2: "68828", value3: "68828", value4: "68828", name: "Dime Community Bank Personal Web (68828)" },
    { value: "04965", value2: "04965", value3: "04965", value4: "04965", name: "Direct Federal Credit Union (04965)" },
    { value: "68974", value2: "68974", value3: "68974", value4: "68974", name: "Directions Credit Union (68974)" },
    { value: "16776", value2: "16776", value3: "16776", value4: "16776", name: "Dirigo Federal Credit Union (16776)" },
    { value: "09275", value2: "09275", value3: "09275", value4: "09275", name: "Discover Bank Express Web (09275)" },
    { value: "09056", value2: "09056", value3: "09056", value4: "09056", name: "Discover Card Express Web (09056)" },
    { value: "63894", value2: "63894", value3: "63894", value4: "63894", name: "Diversified Members Credit Union (63894)" },
    { value: "68001", value2: "68001", value3: "68001", value4: "68001", name: "Dixies FCU (68001)" },
    { value: "05792", value2: "05792", value3: "05792", value4: "05792", name: "Doches C.U. Credit Card (05792)" },
    { value: "13218", value2: "13218", value3: "13218", value4: "13218", name: "Dogwood State Bank (13218)" },
    { value: "06077", value2: "06077", value3: "06077", value4: "13218", name: "Dogwood State Bank-DC (06077)" },
    { value: "67355", value2: "67355", value3: "67355", value4: "67355", name: "Dogwood State Bank-TM (67355)" },
    { value: "67356", value2: "67356", value3: "67356", value4: "67355", name: "Dogwood State Bank-TM-DC (67356)" },
    { value: "09490", value2: "09490", value3: "09490", value4: "09490", name: "Dollar Bank (09490)" },
    { value: "17677", value2: "17677", value3: "17677", value4: "17677", name: "Dollar Bank - WC (17677)" },
    { value: "08984", value2: "08984", value3: "08984", value4: "08984", name: "Dominion Bank (08984)" },
    { value: "18592", value2: "18592", value3: "18592", value4: "18592", name: "Dominion Bank -Treasury (18592)" },
    { value: "70467", value2: "70467", value3: "70467", value4: "70467", name: "Dort Financial CU BB DC (70467)" },
    { value: "49788", value2: "49788", value3: "49788", value4: "49788", name: "Dort Financial Credit Union WC QB (49788)" },
    { value: "01919", value2: "01919", value3: "01919", value4: "01919", name: "Dover Federal Credit Union (01919)" },
    { value: "12986", value2: "12986", value3: "12986", value4: "12986", name: "Downeast Credit Union QB (12986)" },
    { value: "50316", value2: "50316", value3: "50316", value4: "50316", name: "Downey Federal Credit Union (50316)" },
    { value: "11728", value2: "11728", value3: "11728", value4: "11728", name: "Drake Bank (11728)" },
    { value: "59703", value2: "59703", value3: "59703", value4: "59703", name: "Dream First Bank QB (59703)" },
    { value: "63255", value2: "63255", value3: "63255", value4: "63255", name: "Dream First Bank RO (63255)" },
    { value: "00359", value2: "00359", value3: "00359", value4: "00359", name: "DuGood FCU (00359)" },
    { value: "69580", value2: "69580", value3: "69580", value4: "69580", name: "DuGood FCU CC (69580)" },
    { value: "16528", value2: "16528", value3: "16528", value4: "16528", name: "DuGood FCU QB WC (16528)" },
    { value: "05018", value2: "05018", value3: "05018", value4: "05018", name: "DuPage Credit Union (05018)" },
    { value: "09810", value2: "09810", value3: "09810", value4: "09810", name: "DuPage-Bus. Plus (09810)" },
    { value: "59100", value2: "59100", value3: "59100", value4: "59100", name: "Dubuque Bank & Trust IA (59100)" },
    { value: "59655", value2: "59655", value3: "59655", value4: "59655", name: "Dundee Bank - Business (59655)" },
    { value: "62355", value2: "62355", value3: "62355", value4: "62355", name: "Dundee Bank - Retail (62355)" },
    { value: "10299", value2: "10299", value3: "10299", value4: "10299", name: "Dupaco Community Credit Union (10299)" },
    { value: "69954", value2: "69954", value3: "69954", value4: "69954", name: "Durden Banking Company (69954)" },
    { value: "70019", value2: "70019", value3: "70019", value4: "70019", name: "Durden Banking Company-DC (70019)" },
    { value: "23142", value2: "23142", value3: "23142", value4: "23142", name: "E Central Credit Union (23142)" },
    { value: "68059", value2: "68059", value3: "68059", value4: "68059", name: "E-Central Credit Union (68059)" },
    { value: "51888", value2: "51888", value3: "51888", value4: "51888", name: "EECU - Fort Worth (51888)" },
    { value: "16369", value2: "16369", value3: "16369", value4: "16369", name: "ELCA Federal Credit  Union (16369)" },
    { value: "18547", value2: "18547", value3: "18547", value4: "18547", name: "ELGA Credit Union - New (18547)" },
    { value: "18550", value2: "18550", value3: "18550", value4: "18547", name: "ELGA Credit Union - QB WC (18550)" },
    { value: "69711", value2: "69711", value3: "69711", value4: "69711", name: "ESB Bank (69711)" },
    { value: "56031", value2: "56031", value3: "56031", value4: "56031", name: "ESB Financial (56031)" },
    { value: "70367", value2: "70367", value3: "70367", value4: "70367", name: "ESL Federal CU - CC (70367)" },
    { value: "16222", value2: "16222", value3: "16222", value4: "16222", name: "ESL Federal Credit Union-Bus (16222)" },
    { value: "04121", value2: "04121", value3: "04121", value4: "04121", name: "ESSA Bank & Trust - Business (04121)" },
    { value: "04283", value2: "04283", value3: "04283", value4: "04283", name: "EWEB Employees FCU (04283)" },
    { value: "02827", value2: "02827", value3: "02827", value4: "02827", name: "EZCardInfo.com (02827)" },
    { value: "69455", value2: "69455", value3: "69455", value4: "69455", name: "EZCardInfo.com QBDT EWC (69455)" },
    { value: "69220", value2: "69220", value3: "69220", value4: "69220", name: "Eagle Bank - BO (69220)" },
    { value: "68816", value2: "68816", value3: "68816", value4: "68816", name: "Eagle Bank - MN (68816)" },
    { value: "00613", value2: "00613", value3: "00613", value4: "00613", name: "Eagle Bank - Web Connect (00613)" },
    { value: "15618", value2: "15618", value3: "15618", value4: "15618", name: "Eagle Bank and Trust Company (15618)" },
    { value: "11152", value2: "11152", value3: "11152", value4: "11152", name: "Eagle Community Credit Union (11152)" },
    { value: "52149", value2: "52149", value3: "52149", value4: "52149", name: "EagleBank - Personal (52149)" },
    { value: "52482", value2: "52482", value3: "52482", value4: "52482", name: "EagleBank-Business (52482)" },
    { value: "49659", value2: "49659", value3: "49659", value4: "49659", name: "Earlham Savings Bank (49659)" },
    { value: "63153", value2: "63153", value3: "63153", value4: "49659", name: "Earlham Savings Bank QuickBooks DC (63153)" },
    { value: "50283", value2: "50283", value3: "50283", value4: "50283", name: "East Alabama Community FCU (50283)" },
    { value: "04649", value2: "04649", value3: "04649", value4: "04646", name: "East Cambridge Savings QBWC (04649)" },
    { value: "04646", value2: "04646", value3: "04646", value4: "04646", name: "East Cambridge Savings-Business (04646)" },
    { value: "07708", value2: "07708", value3: "07708", value4: "07708", name: "East County Schools FCU (07708)" },
    { value: "15124", value2: "15124", value3: "15124", value4: "15124", name: "East Idaho CU - QB (15124)" },
    { value: "11764", value2: "11764", value3: "11764", value4: "11764", name: "East Texas Professional CU (11764)" },
    { value: "11460", value2: "11460", value3: "11460", value4: "11459", name: "East West Bank - QB Web Connect (11460)" },
    { value: "67857", value2: "67857", value3: "67857", value4: "67857", name: "East West Bank Direct Connect (67857)" },
    { value: "15766", value2: "15766", value3: "15766", value4: "15766", name: "East West Bank bBPremier (15766)" },
    { value: "52293", value2: "52293", value3: "52293", value4: "52293", name: "East West businessBridgePlus (52293)" },
    { value: "06201", value2: "06201", value3: "06201", value4: "06201", name: "Eastern Bank (06201)" },
    { value: "18298", value2: "18298", value3: "18298", value4: "18298", name: "Eastern Bank Business Online (18298)" },
    { value: "15580", value2: "15580", value3: "15580", value4: "15580", name: "Eastern Bank Online (15580)" },
    { value: "15870", value2: "15870", value3: "15870", value4: "15870", name: "Eastern Bank for TreasuryConnect (15870)" },
    { value: "62640", value2: "62640", value3: "62640", value4: "62640", name: "Eastern CT Savings Bank (62640)" },
    { value: "62643", value2: "62643", value3: "62643", value4: "62640", name: "Eastern CT Savings Bank - BUS (62643)" },
    { value: "07593", value2: "07593", value3: "07593", value4: "07593", name: "Eastern Michigan Bank (07593)" },
    { value: "00999", value2: "00999", value3: "00999", value4: "00999", name: "Eastern Michigan University CU (00999)" },
    { value: "68298", value2: "68298", value3: "68298", value4: "68298", name: "EasternEase Business (68298)" },
    { value: "00740", value2: "00740", value3: "00740", value4: "00740", name: "Eastex Credit Union Credit Card (00740)" },
    { value: "02852", value2: "02852", value3: "02852", value4: "02852", name: "Eastman Credit Union QuickBooks (02852)" },
    { value: "09278", value2: "09278", value3: "09278", value4: "09278", name: "Eaton Community Bank (09278)" },
    { value: "69367", value2: "69367", value3: "69367", value4: "09278", name: "Eaton Community Bank QBDC (69367)" },
    { value: "04227", value2: "04227", value3: "04227", value4: "04227", name: "Eclipse Bank (04227)" },
    { value: "68012", value2: "68012", value3: "68012", value4: "68012", name: "Eclipse Bank QB DC (68012)" },
    { value: "67915", value2: "67915", value3: "67915", value4: "67915", name: "Ecusta Credit Union (67915)" },
    { value: "00249", value2: "00249", value3: "00249", value4: "00249", name: "Edison National Bank (00249)" },
    { value: "51027", value2: "51027", value3: "51027", value4: "51027", name: "Edmonton State Bank KY (51027)" },
    { value: "08291", value2: "08291", value3: "08291", value4: "08291", name: "Education Credit Union Credit Card (08291)" },
    { value: "52245", value2: "52245", value3: "52245", value4: "52245", name: "Education First FCU (52245)" },
    { value: "12926", value2: "12926", value3: "12926", value4: "12926", name: "Educational Systems FCU (12926)" },
    { value: "49629", value2: "49629", value3: "49629", value4: "49629", name: "Educators CU (49629)" },
    { value: "18112", value2: "18112", value3: "18112", value4: "18112", name: "Educators CU WC QB (18112)" },
    { value: "14898", value2: "14898", value3: "14898", value4: "14898", name: "Educators Credit Union (14898)" },
    { value: "06779", value2: "06779", value3: "06779", value4: "06779", name: "El Dorado Savings Bank - CIB (06779)" },
    { value: "69094", value2: "69094", value3: "69094", value4: "69094", name: "El Paso Area Teachers CC (69094)" },
    { value: "10308", value2: "10308", value3: "10308", value4: "10308", name: "Elan Financial Services (10308)" },
    { value: "69447", value2: "69447", value3: "69447", value4: "69447", name: "Elan Financial Services EWC (69447)" },
    { value: "36666", value2: "36666", value3: "36666", value4: "36666", name: "Elderton State Bank (36666)" },
    { value: "68099", value2: "68099", value3: "68099", value4: "68099", name: "Elderton State Bank Bus (68099)" },
    { value: "68100", value2: "68100", value3: "68100", value4: "68099", name: "Elderton State Bank Bus QBDC (68100)" },
    { value: "68101", value2: "68101", value3: "68101", value4: "36666", name: "Elderton State Bank QBDC (68101)" },
    { value: "20151", value2: "20151", value3: "20151", value4: "20151", name: "Electrical Federal Credit Union (20151)" },
    { value: "14217", value2: "14217", value3: "14217", value4: "14217", name: "Electro Savings Credit Union (14217)" },
    { value: "53922", value2: "53922", value3: "53922", value4: "53922", name: "Element FCU (53922)" },
    { value: "11588", value2: "11588", value3: "11588", value4: "11588", name: "Elements Financial (11588)" },
    { value: "28872", value2: "28872", value3: "28872", value4: "28872", name: "Elevate Bank (28872)" },
    { value: "65412", value2: "65412", value3: "65412", value4: "65412", name: "Elite Community Credit Union (65412)" },
    { value: "04070", value2: "04070", value3: "04070", value4: "04070", name: "Elkhorn Valley Bank - Biz DC (04070)" },
    { value: "03959", value2: "03959", value3: "03959", value4: "03959", name: "Elkhorn Valley Bank - Biz WC (03959)" },
    { value: "40953", value2: "40953", value3: "40953", value4: "40953", name: "Elkhorn Valley Bank - Consumer (40953)" },
    { value: "27678", value2: "27678", value3: "27678", value4: "27678", name: "Elko FCU (27678)" },
    { value: "68562", value2: "68562", value3: "68562", value4: "68562", name: "Elliott Community FCU (68562)" },
    { value: "01839", value2: "01839", value3: "01839", value4: "01839", name: "Embassy Bank (01839)" },
    { value: "69591", value2: "69591", value3: "69591", value4: "69591", name: "Embassy Bank LV BB DC (69591)" },
    { value: "69534", value2: "69534", value3: "69534", value4: "69534", name: "Embassy Bank QBWC (69534)" },
    { value: "70323", value2: "70323", value3: "70323", value4: "70323", name: "Embassy National Bank BO (70323)" },
    { value: "70322", value2: "70322", value3: "70322", value4: "70322", name: "Embassy National Bank BODC (70322)" },
    { value: "17601", value2: "17601", value3: "17601", value4: "17601", name: "Embassy National Bank RO (17601)" },
    { value: "70321", value2: "70321", value3: "70321", value4: "70321", name: "Embassy National Bank RODC (70321)" },
    { value: "67254", value2: "67254", value3: "67254", value4: "67254", name: "Embers CU (67254)" },
    { value: "54426", value2: "54426", value3: "54426", value4: "54426", name: "Embold CU (54426)" },
    { value: "64779", value2: "64779", value3: "64779", value4: "64779", name: "Emerald Bank, Kansas (64779)" },
    { value: "64782", value2: "64782", value3: "64782", value4: "64779", name: "Emerald Bank, Kansas QuickBooks DC (64782)" },
    { value: "13785", value2: "13785", value3: "13785", value4: "13785", name: "Emery Federal Credit Union (13785)" },
    { value: "11056", value2: "11056", value3: "11056", value4: "11056", name: "Emory Alliance Credit Union (11056)" },
    { value: "00187", value2: "00187", value3: "00187", value4: "00187", name: "Empeople Credit Union (00187)" },
    { value: "13684", value2: "13684", value3: "13684", value4: "13684", name: "Empire NB IB QBO (13684)" },
    { value: "64032", value2: "64032", value3: "64032", value4: "64032", name: "Empire State Bank BO (64032)" },
    { value: "20160", value2: "20160", value3: "20160", value4: "20160", name: "Empire State Bank BO DC (20160)" },
    { value: "70196", value2: "70196", value3: "70196", value4: "70196", name: "Employees FCU (70196)" },
    { value: "07309", value2: "07309", value3: "07309", value4: "07309", name: "Empower FCU (07309)" },
    { value: "68680", value2: "68680", value3: "68680", value4: "68680", name: "Emprise Bank Direct Connect (68680)" },
    { value: "65511", value2: "65511", value3: "65511", value4: "65511", name: "Emprise Bank eBusiness Access (65511)" },
    { value: "09332", value2: "09332", value3: "09332", value4: "09332", name: "Encore Bank (09332)" },
    { value: "09335", value2: "09335", value3: "09335", value4: "09332", name: "Encore Bank - QB DC (09335)" },
    { value: "05930", value2: "05930", value3: "05930", value4: "05930", name: "Endeavor Bank (05930)" },
    { value: "70175", value2: "70175", value3: "70175", value4: "70175", name: "Endeavor Bank-TM (70175)" },
    { value: "70176", value2: "70176", value3: "70176", value4: "70176", name: "Endeavor Bank-TM-DC (70176)" },
    { value: "05933", value2: "05933", value3: "05933", value4: "05930", name: "Endeavor Bank-WC (05933)" },
    { value: "24480", value2: "24480", value3: "24480", value4: "24480", name: "EnerGComm FCU (24480)" },
    { value: "56808", value2: "56808", value3: "56808", value4: "24480", name: "EnerGComm FCU_DC (56808)" },
    { value: "16020", value2: "16020", value3: "16020", value4: "16020", name: "Energy Capital CU (16020)" },
    { value: "04589", value2: "04589", value3: "04589", value4: "04589", name: "Energy One Federal Credit Union (04589)" },
    { value: "69846", value2: "69846", value3: "69846", value4: "69846", name: "Engage Federal Credit Union (69846)" },
    { value: "69343", value2: "69343", value3: "69343", value4: "69343", name: "Englewood Bank & Trust FL-DC (69343)" },
    { value: "03830", value2: "03830", value3: "03830", value4: "03830", name: "Englewood Bank & Trust, FL (03830)" },
    { value: "19278", value2: "19278", value3: "19278", value4: "19278", name: "Ennis State Bank WC (19278)" },
    { value: "08918", value2: "08918", value3: "08918", value4: "08918", name: "Enrichment - FCU (08918)" },
    { value: "10598", value2: "10598", value3: "10598", value4: "10598", name: "Ent Credit Union (10598)" },
    { value: "11401", value2: "11401", value3: "11401", value4: "11401", name: "Enterprise Bank & Trust (11401)" },
    { value: "68376", value2: "68376", value3: "68376", value4: "11401", name: "Enterprise Bank & Trust - QBDC (68376)" },
    { value: "02994", value2: "02994", value3: "02994", value4: "02994", name: "Enterprise Bank - MA - Personal (02994)" },
    { value: "56655", value2: "56655", value3: "56655", value4: "56655", name: "Enterprise Bank Omaha - Direct (56655)" },
    { value: "10977", value2: "10977", value3: "10977", value4: "10977", name: "Enterprise Bank Omaha - Web (10977)" },
    { value: "34692", value2: "34692", value3: "34692", value4: "34692", name: "Enterprise Bank PGH (34692)" },
    { value: "07598", value2: "07598", value3: "07598", value4: "34692", name: "Enterprise Bank PGH-DL (07598)" },
    { value: "69329", value2: "69329", value3: "69329", value4: "69329", name: "Enterprise Bank of SC BO (69329)" },
    { value: "70168", value2: "70168", value3: "70168", value4: "69329", name: "Enterprise Bank of SC BO DC (70168)" },
    { value: "70159", value2: "70159", value3: "70159", value4: "70159", name: "Enterprise Bank of SC RO (70159)" },
    { value: "70160", value2: "70160", value3: "70160", value4: "70159", name: "Enterprise Bank of SC RO DC (70160)" },
    { value: "01981", value2: "01981", value3: "01981", value4: "01981", name: "Enterprise CU (01981)" },
    { value: "69127", value2: "69127", value3: "69127", value4: "69127", name: "EntreBank (69127)" },
    { value: "69128", value2: "69128", value3: "69128", value4: "69128", name: "EntreBank QBDC (69128)" },
    { value: "69405", value2: "69405", value3: "69405", value4: "69405", name: "Envision CU CC (69405)" },
    { value: "10016", value2: "10016", value3: "10016", value4: "10016", name: "Envision Credit Union (10016)" },
    { value: "07349", value2: "07349", value3: "07349", value4: "07349", name: "Envista Credit Union - QB (07349)" },
    { value: "01175", value2: "01175", value3: "01175", value4: "01175", name: "Ephrata National Bank (01175)" },
    { value: "69710", value2: "69710", value3: "69710", value4: "69710", name: "Ephrata National Bank QBDC (69710)" },
    { value: "08102", value2: "08102", value3: "08102", value4: "08102", name: "Equitable Bank, NE (08102)" },
    { value: "08105", value2: "08105", value3: "08105", value4: "08102", name: "Equitable Bank, NE - WC (08105)" },
    { value: "14628", value2: "14628", value3: "14628", value4: "14628", name: "Equity Bank (14628)" },
    { value: "07829", value2: "07829", value3: "07829", value4: "07829", name: "Equity Bank USA (07829)" },
    { value: "07823", value2: "07823", value3: "07823", value4: "07829", name: "Equity Bank USA_WC (07823)" },
    { value: "67596", value2: "67596", value3: "67596", value4: "67596", name: "Ergo Bank (67596)" },
    { value: "11786", value2: "11786", value3: "11786", value4: "11786", name: "Erie Federal Credit Union (11786)" },
    { value: "17361", value2: "17361", value3: "17361", value4: "17361", name: "Esquire Bank (17361)" },
    { value: "67999", value2: "67999", value3: "67999", value4: "17361", name: "Esquire Bank NY QB (67999)" },
    { value: "15394", value2: "15394", value3: "15394", value4: "15394", name: "Essential CU - QB (15394)" },
    { value: "11677", value2: "11677", value3: "11677", value4: "11677", name: "Essential Credit Union (11677)" },
    { value: "03443", value2: "03443", value3: "03443", value4: "03443", name: "Essex Savings Bank (03443)" },
    { value: "59292", value2: "59292", value3: "59292", value4: "59292", name: "Essex Savings Bank - WC (59292)" },
    { value: "00977", value2: "00977", value3: "00977", value4: "00977", name: "Eureka Savings Bank - WC (00977)" },
    { value: "07639", value2: "07639", value3: "07639", value4: "07639", name: "Eva Bank (07639)" },
    { value: "07960", value2: "07960", value3: "07960", value4: "07960", name: "Evangeline Bank & Trust Co (07960)" },
    { value: "00244", value2: "00244", value3: "00244", value4: "00244", name: "Evans Bank, N.A (00244)" },
    { value: "06185", value2: "06185", value3: "06185", value4: "06185", name: "Evansville Firefighter's FCU (06185)" },
    { value: "13482", value2: "13482", value3: "13482", value4: "13482", name: "EverBank - Business Online (13482)" },
    { value: "19197", value2: "19197", value3: "19197", value4: "19197", name: "EverTrust Bank (19197)" },
    { value: "18060", value2: "18060", value3: "18060", value4: "18060", name: "Everence FCU (18060)" },
    { value: "62757", value2: "62757", value3: "62757", value4: "10861", name: "Everest QuickBooks DC (62757)" },
    { value: "06638", value2: "06638", value3: "06638", value4: "06638", name: "Everett Co-operative Bank (06638)" },
    { value: "62457", value2: "62457", value3: "62457", value4: "06638", name: "Everett Co-operative Bank - New QB (62457)" },
    { value: "69141", value2: "69141", value3: "69141", value4: "69141", name: "Evergreen Bank Group - Retail (69141)" },
    { value: "68037", value2: "68037", value3: "68037", value4: "68037", name: "Evergreen Bank Group-TM (68037)" },
    { value: "68038", value2: "68038", value3: "68038", value4: "68037", name: "Evergreen Bank Group-TM-DC (68038)" },
    { value: "55986", value2: "55986", value3: "55986", value4: "55986", name: "Evergreen CU (55986)" },
    { value: "04955", value2: "04955", value3: "04955", value4: "04955", name: "Evergreen Credit Union (04955)" },
    { value: "65948", value2: "65948", value3: "65948", value4: "65948", name: "Evergreen Federal Bank OR Business (65948)" },
    { value: "17853", value2: "17853", value3: "17853", value4: "17853", name: "Evergreen Federal Bank OR Retail (17853)" },
    { value: "01220", value2: "01220", value3: "01220", value4: "01220", name: "Evergreen National Bank (01220)" },
    { value: "68022", value2: "68022", value3: "68022", value4: "01220", name: "Evergreen National Bank QBDC (68022)" },
    { value: "69654", value2: "69654", value3: "69654", value4: "69654", name: "Evermore Bank BO (69654)" },
    { value: "70320", value2: "70320", value3: "70320", value4: "70320", name: "Evermore Bank BODC (70320)" },
    { value: "69655", value2: "69655", value3: "69655", value4: "69655", name: "Evermore Bank RO (69655)" },
    { value: "70319", value2: "70319", value3: "70319", value4: "69655", name: "Evermore Bank RODC (70319)" },
    { value: "69872", value2: "69872", value3: "69872", value4: "10298", name: "Everwise CU Business QB DC (69872)" },
    { value: "10298", value2: "10298", value3: "10298", value4: "10298", name: "Everwise CU Business South Bend (10298)" },
    { value: "68796", value2: "68796", value3: "68796", value4: "68796", name: "Everwise Credit Union (68796)" },
    { value: "28575", value2: "28575", value3: "28575", value4: "28575", name: "Evolve Bank & Trust (28575)" },
    { value: "21699", value2: "21699", value3: "21699", value4: "21699", name: "Evolve FCU (21699)" },
    { value: "56049", value2: "56049", value3: "56049", value4: "56049", name: "Excel Bank (56049)" },
    { value: "14892", value2: "14892", value3: "14892", value4: "14892", name: "Excel Federal Credit Union (14892)" },
    { value: "50142", value2: "50142", value3: "50142", value4: "50142", name: "Exchange Bank (50142)" },
    { value: "56079", value2: "56079", value3: "56079", value4: "56079", name: "Exchange Bank & Trust (56079)" },
    { value: "49548", value2: "49548", value3: "49548", value4: "49548", name: "Exchange Bank & Trust-Perry-OK (49548)" },
    { value: "07118", value2: "07118", value3: "07118", value4: "07118", name: "Exchange Bank - Business (07118)" },
    { value: "65574", value2: "65574", value3: "65574", value4: "65574", name: "Exchange Bank - GA (65574)" },
    { value: "08987", value2: "08987", value3: "08987", value4: "08987", name: "Exchange Bank - Santa Rosa (08987)" },
    { value: "08990", value2: "08990", value3: "08990", value4: "08987", name: "Exchange Bank - Santa Rosa - DC (08990)" },
    { value: "34998", value2: "34998", value3: "34998", value4: "34998", name: "Exchange Bank of Missouri (34998)" },
    { value: "69110", value2: "69110", value3: "69110", value4: "69110", name: "Exchange Bank of NE MO (69110)" },
    { value: "68506", value2: "68506", value3: "68506", value4: "68506", name: "Exchange State Bank KS - DC (68506)" },
    { value: "68505", value2: "68505", value3: "68505", value4: "68505", name: "Exchange State Bank, KS (68505)" },
    { value: "68717", value2: "68717", value3: "68717", value4: "68717", name: "Excite CU - Bus BB DC (68717)" },
    { value: "10218", value2: "10218", value3: "10218", value4: "10218", name: "Excite Credit Union (10218)" },
    { value: "06454", value2: "06454", value3: "06454", value4: "06454", name: "Excite Credit Union - QB (06454)" },
    { value: "40812", value2: "40812", value3: "40812", value4: "40812", name: "Explorers CU (40812)" },
    { value: "12158", value2: "12158", value3: "12158", value4: "12158", name: "Extraco Banks - New (12158)" },
    { value: "12161", value2: "12161", value3: "12161", value4: "12161", name: "Extraco Banks - QBDC (12161)" },
    { value: "18508", value2: "18508", value3: "18508", value4: "18508", name: "F & A Federal Credit Union (18508)" },
    { value: "30324", value2: "30324", value3: "30324", value4: "30324", name: "F & C Bank Holden, MO (30324)" },
    { value: "12737", value2: "12737", value3: "12737", value4: "30324", name: "F & C Bank Holden, MO QB DC (12737)" },
    { value: "69018", value2: "69018", value3: "69018", value4: "69018", name: "F & M Bank & Trust Company (69018)" },
    { value: "12689", value2: "12689", value3: "12689", value4: "12689", name: "F & M Bank of ND (12689)" },
    { value: "57234", value2: "57234", value3: "57234", value4: "57234", name: "F & M State Bank of Pierz (57234)" },
    { value: "17940", value2: "17940", value3: "17940", value4: "17940", name: "F&M Bank - Falls City (17940)" },
    { value: "15144", value2: "15144", value3: "15144", value4: "15144", name: "F&M Bank - NC (15144)" },
    { value: "12342", value2: "12342", value3: "12342", value4: "12342", name: "F&M Bank - Nebraska (12342)" },
    { value: "49851", value2: "49851", value3: "49851", value4: "49851", name: "F&M Bank - OK (49851)" },
    { value: "55707", value2: "55707", value3: "55707", value4: "55707", name: "F&M Bank Business (55707)" },
    { value: "04286", value2: "04286", value3: "04286", value4: "04286", name: "F&M Bank ClarksvilleTN QBDC (04286)" },
    { value: "05186", value2: "05186", value3: "05186", value4: "05186", name: "F&M Bank Colby (05186)" },
    { value: "08579", value2: "08579", value3: "08579", value4: "08579", name: "F&M Bank IA Business Direct (08579)" },
    { value: "57240", value2: "57240", value3: "57240", value4: "57240", name: "F&M Bank Washington, GA (57240)" },
    { value: "15927", value2: "15927", value3: "15927", value4: "15927", name: "F&M Bank and Trust - Hannibal (15927)" },
    { value: "05183", value2: "05183", value3: "05183", value4: "05183", name: "F&M Bank of Colby Bus. (05183)" },
    { value: "03111", value2: "03111", value3: "03111", value4: "03111", name: "F&M Bank, Clarksville, TN (03111)" },
    { value: "68244", value2: "68244", value3: "68244", value4: "68244", name: "F&M State Bank Retail (68244)" },
    { value: "69712", value2: "69712", value3: "69712", value4: "69712", name: "F&M Trust - DC (69712)" },
    { value: "02810", value2: "02810", value3: "02810", value4: "02810", name: "FAA FCU Credit Card (02810)" },
    { value: "69771", value2: "69771", value3: "69771", value4: "69771", name: "FAA Federal Credit Union (69771)" },
    { value: "16830", value2: "16830", value3: "16830", value4: "16830", name: "FAST Federal Credit Union (16830)" },
    { value: "69584", value2: "69584", value3: "69584", value4: "16830", name: "FAST Federal Credit Union-QB (69584)" },
    { value: "08231", value2: "08231", value3: "08231", value4: "08231", name: "FB Direct Connect (08231)" },
    { value: "63933", value2: "63933", value3: "63933", value4: "63933", name: "FBT Bank & Mortgage (63933)" },
    { value: "13953", value2: "13953", value3: "13953", value4: "13953", name: "FCB Banks (13953)" },
    { value: "67612", value2: "67612", value3: "67612", value4: "28677", name: "FCB Heartland QBDC (67612)" },
    { value: "68704", value2: "68704", value3: "68704", value4: "68704", name: "FCB Treasury-Direct (68704)" },
    { value: "68703", value2: "68703", value3: "68703", value4: "68703", name: "FCB Treasury-Web (68703)" },
    { value: "09226", value2: "09226", value3: "09226", value4: "09226", name: "FCCU - QB North Dakota (09226)" },
    { value: "12981", value2: "12981", value3: "12981", value4: "12981", name: "FCNB (12981)" },
    { value: "68820", value2: "68820", value3: "68820", value4: "12981", name: "FCNB QBDC (68820)" },
    { value: "67518", value2: "67518", value3: "67518", value4: "67518", name: "FCS Financial (67518)" },
    { value: "56010", value2: "56010", value3: "56010", value4: "56010", name: "FF&M - Brownsdale & Austin, MN (56010)" },
    { value: "56019", value2: "56019", value3: "56019", value4: "56019", name: "FF&M - CannonFalls,MN & Branches (56019)" },
    { value: "56007", value2: "56007", value3: "56007", value4: "56007", name: "FF&M - Fairmont & St. Paul, MN (56007)" },
    { value: "56013", value2: "56013", value3: "56013", value4: "56013", name: "FF&M - Grand Meadow & Stewartville (56013)" },
    { value: "01788", value2: "01788", value3: "01788", value4: "01788", name: "FF&M - Luverne & Pipestone, MN (01788)" },
    { value: "08148", value2: "08148", value3: "08148", value4: "08148", name: "FFB Bank - Fresno (08148)" },
    { value: "69808", value2: "69808", value3: "69808", value4: "08148", name: "FFB Bank - Fresno QBDC (69808)" },
    { value: "62739", value2: "62739", value3: "62739", value4: "62739", name: "FFB of Louisiana CC (62739)" },
    { value: "68241", value2: "68241", value3: "68241", value4: "68241", name: "FFNWB Business DC (68241)" },
    { value: "36912", value2: "36912", value3: "36912", value4: "36912", name: "FFNWB Business WC (36912)" },
    { value: "08072", value2: "08072", value3: "08072", value4: "08072", name: "FFSB Rochester (08072)" },
    { value: "06706", value2: "06706", value3: "06706", value4: "06706", name: "FIA Card Services, Business Card (06706)" },
    { value: "56085", value2: "56085", value3: "56085", value4: "56085", name: "FIA CardServices (56085)" },
    { value: "68502", value2: "68502", value3: "68502", value4: "68502", name: "FM Bank Business DC (68502)" },
    { value: "68501", value2: "68501", value3: "68501", value4: "68501", name: "FM Bank Business WC (68501)" },
    { value: "68518", value2: "68518", value3: "68518", value4: "68518", name: "FM Bank Personal (68518)" },
    { value: "68519", value2: "68519", value3: "68519", value4: "68519", name: "FM Bank Personal QBDC (68519)" },
    { value: "69759", value2: "69759", value3: "69759", value4: "69759", name: "FM Community Bank Bus (69759)" },
    { value: "06947", value2: "06947", value3: "06947", value4: "06947", name: "FMB BanK (06947)" },
    { value: "33903", value2: "33903", value3: "33903", value4: "33903", name: "FMB Nashville - IL (33903)" },
    { value: "67589", value2: "67589", value3: "67589", value4: "67589", name: "FMS Bank (67589)" },
    { value: "67591", value2: "67591", value3: "67591", value4: "67591", name: "FMS Bank QBDC (67591)" },
    { value: "68350", value2: "68350", value3: "68350", value4: "68350", name: "FMUB Business (68350)" },
    { value: "69400", value2: "69400", value3: "69400", value4: "66263", name: "FNB & Trust Co. Okmulgee QBDC (69400)" },
    { value: "51672", value2: "51672", value3: "51672", value4: "51672", name: "FNB Ames (51672)" },
    { value: "70348", value2: "70348", value3: "70348", value4: "70348", name: "FNB Aspermont TX (70348)" },
    { value: "56547", value2: "56547", value3: "56547", value4: "56547", name: "FNB Bank Fontanelle IA (56547)" },
    { value: "06191", value2: "06191", value3: "06191", value4: "06191", name: "FNB Bank QB (06191)" },
    { value: "58074", value2: "58074", value3: "58074", value4: "58074", name: "FNB Bank, Inc. (58074)" },
    { value: "00971", value2: "00971", value3: "00971", value4: "00971", name: "FNB Clarksdale (00971)" },
    { value: "07069", value2: "07069", value3: "07069", value4: "07069", name: "FNB Clarksdale IB WC QB (07069)" },
    { value: "65421", value2: "65421", value3: "65421", value4: "65421", name: "FNB Community Bank (65421)" },
    { value: "53895", value2: "53895", value3: "53895", value4: "53895", name: "FNB Community Bank Vandalia (53895)" },
    { value: "67320", value2: "67320", value3: "67320", value4: "53895", name: "FNB Community Bank Vandalia QBDC (67320)" },
    { value: "68216", value2: "68216", value3: "68216", value4: "68215", name: "FNB Dighton QBDC (68216)" },
    { value: "69982", value2: "69982", value3: "69982", value4: "69982", name: "FNB Griggsville (69982)" },
    { value: "69983", value2: "69983", value3: "69983", value4: "69982", name: "FNB Griggsville QBDC (69983)" },
    { value: "08051", value2: "08051", value3: "08051", value4: "08051", name: "FNB Groton (08051)" },
    { value: "70300", value2: "70300", value3: "70300", value4: "70300", name: "FNB Hartford RO DC (70300)" },
    { value: "08639", value2: "08639", value3: "08639", value4: "08639", name: "FNB Hartford Retail Online (08639)" },
    { value: "09929", value2: "09929", value3: "09929", value4: "09929", name: "FNB Jasper (09929)" },
    { value: "08066", value2: "08066", value3: "08066", value4: "08066", name: "FNB Le Center WC (08066)" },
    { value: "07388", value2: "07388", value3: "07388", value4: "07388", name: "FNB Livingston BO (07388)" },
    { value: "04535", value2: "04535", value3: "04535", value4: "04535", name: "FNB Oxford Business (04535)" },
    { value: "04088", value2: "04088", value3: "04088", value4: "04088", name: "FNB Pandora Business Direct (04088)" },
    { value: "04085", value2: "04085", value3: "04085", value4: "04085", name: "FNB Pandora Direct Connect (04085)" },
    { value: "04082", value2: "04082", value3: "04082", value4: "04082", name: "FNB Pandora Web Connect (04082)" },
    { value: "04268", value2: "04268", value3: "04268", value4: "04268", name: "FNB Russell Springs (04268)" },
    { value: "70402", value2: "70402", value3: "70402", value4: "70402", name: "FNB South DC (70402)" },
    { value: "54210", value2: "54210", value3: "54210", value4: "54210", name: "FNB South GA (54210)" },
    { value: "63555", value2: "63555", value3: "63555", value4: "63555", name: "FNB Tremont (63555)" },
    { value: "07694", value2: "07694", value3: "07694", value4: "07694", name: "FNB USA Business Direct Connect (07694)" },
    { value: "07691", value2: "07691", value3: "07691", value4: "07691", name: "FNB USA Retail Direct Connect (07691)" },
    { value: "11180", value2: "11180", value3: "11180", value4: "11180", name: "FNB Waterloo (11180)" },
    { value: "07133", value2: "07133", value3: "07133", value4: "07133", name: "FNB Waterloo - Business (07133)" },
    { value: "08837", value2: "08837", value3: "08837", value4: "08837", name: "FNB Weatherford Busines Direct (08837)" },
    { value: "08840", value2: "08840", value3: "08840", value4: "08840", name: "FNB Weatherford Direct (08840)" },
    { value: "19209", value2: "19209", value3: "19209", value4: "19209", name: "FNB of Bellville (19209)" },
    { value: "68928", value2: "68928", value3: "68928", value4: "68928", name: "FNB of Bosque County (68928)" },
    { value: "08111", value2: "08111", value3: "08111", value4: "08108", name: "FNB of Elk City-Biz DC (08111)" },
    { value: "08108", value2: "08108", value3: "08108", value4: "08108", name: "FNB of Elk City-biz (08108)" },
    { value: "15684", value2: "15684", value3: "15684", value4: "15684", name: "FNB of Louisiana (15684)" },
    { value: "70088", value2: "70088", value3: "70088", value4: "70088", name: "FNB of McIntosh - DC (70088)" },
    { value: "27303", value2: "27303", value3: "27303", value4: "27303", name: "FNB of Middle Tennessee (27303)" },
    { value: "67129", value2: "67129", value3: "67129", value4: "67129", name: "FNB of St. Ignace (67129)" },
    { value: "62637", value2: "62637", value3: "62637", value4: "62637", name: "FNB of Talladega Credit Card (62637)" },
    { value: "03122", value2: "03122", value3: "03122", value4: "03122", name: "FNB&T Clinton - Cash Mgmt (03122)" },
    { value: "15861", value2: "15861", value3: "15861", value4: "15861", name: "FNB, Weatherford, OK (15861)" },
    { value: "20055", value2: "20055", value3: "20055", value4: "20055", name: "FNB-Tennessee Business (20055)" },
    { value: "03577", value2: "03577", value3: "03577", value4: "03577", name: "FNBC Bank (03577)" },
    { value: "11928", value2: "11928", value3: "11928", value4: "11928", name: "FNBC Bank & Trust - BIB (11928)" },
    { value: "07811", value2: "07811", value3: "07811", value4: "07811", name: "FNBC Bank Bus Direct Connect (07811)" },
    { value: "02669", value2: "02669", value3: "02669", value4: "02669", name: "FNBCC (02669)" },
    { value: "03404", value2: "03404", value3: "03404", value4: "03404", name: "FNBCC - DL (03404)" },
    { value: "69108", value2: "69108", value3: "69108", value4: "69108", name: "FNBCC-TM (69108)" },
    { value: "69109", value2: "69109", value3: "69109", value4: "69108", name: "FNBCC-TM-DC (69109)" },
    { value: "11323", value2: "11323", value3: "11323", value4: "11323", name: "FNBO Direct (11323)" },
    { value: "00833", value2: "00833", value3: "00833", value4: "00833", name: "FNBT Chickasha, OK - BeB DC (00833)" },
    { value: "16498", value2: "16498", value3: "16498", value4: "16498", name: "FNBT Chickasha, OK - BeB WC (16498)" },
    { value: "08602", value2: "08602", value3: "08602", value4: "08602", name: "FNBT Chickasha, Ok - IB QBO (08602)" },
    { value: "06289", value2: "06289", value3: "06289", value4: "06289", name: "FNBT.COM (06289)" },
    { value: "70519", value2: "70519", value3: "70519", value4: "70519", name: "FSB Bozeman (70519)" },
    { value: "70492", value2: "70492", value3: "70492", value4: "70492", name: "FSB Bozeman-DC (70492)" },
    { value: "67770", value2: "67770", value3: "67770", value4: "67770", name: "FSB Lynnville BIZ QBDC (67770)" },
    { value: "07475", value2: "07475", value3: "07475", value4: "07475", name: "FSB Malta - Retail (07475)" },
    { value: "56034", value2: "56034", value3: "56034", value4: "56034", name: "FSB Northwood - IA (56034)" },
    { value: "06542", value2: "06542", value3: "06542", value4: "06542", name: "FSB Odem QBDC (06542)" },
    { value: "70521", value2: "70521", value3: "70521", value4: "70521", name: "FSB of Missoula (70521)" },
    { value: "70487", value2: "70487", value3: "70487", value4: "70487", name: "FSB of Missoula-DC (70487)" },
    { value: "70286", value2: "70286", value3: "70286", value4: "70286", name: "FSB of Sauk Centre BO (70286)" },
    { value: "70288", value2: "70288", value3: "70288", value4: "70286", name: "FSB of Sauk Centre BODC (70288)" },
    { value: "70285", value2: "70285", value3: "70285", value4: "70285", name: "FSB of Sauk Centre RO (70285)" },
    { value: "70287", value2: "70287", value3: "70287", value4: "70285", name: "FSB of Sauk Centre RODC (70287)" },
    { value: "08774", value2: "08774", value3: "08774", value4: "08774", name: "FSB of Uvalde - Business (08774)" },
    { value: "13296", value2: "13296", value3: "13296", value4: "13296", name: "FSBT Carthage, TX (13296)" },
    { value: "62958", value2: "62958", value3: "62958", value4: "62958", name: "FSBTND (62958)" },
    { value: "68078", value2: "68078", value3: "68078", value4: "62958", name: "FSBTND QBDC (68078)" },
    { value: "01212", value2: "01212", value3: "01212", value4: "01212", name: "FSNB (01212)" },
    { value: "53817", value2: "53817", value3: "53817", value4: "53817", name: "FVCbank - Business Banking (53817)" },
    { value: "56061", value2: "56061", value3: "56061", value4: "56061", name: "FVCbank OLB - DC (56061)" },
    { value: "13222", value2: "13222", value3: "13222", value4: "13222", name: "FVCbank OLB - WC (13222)" },
    { value: "08252", value2: "08252", value3: "08252", value4: "08252", name: "FVCbank-CM DC QB (08252)" },
    { value: "68331", value2: "68331", value3: "68331", value4: "68331", name: "FWBank-First Women's Bank BO DC (68331)" },
    { value: "68333", value2: "68333", value3: "68333", value4: "68333", name: "FWBank-First Women's Bank BO WC (68333)" },
    { value: "68332", value2: "68332", value3: "68332", value4: "68332", name: "FWBank-First Women's Bank RO DC (68332)" },
    { value: "68334", value2: "68334", value3: "68334", value4: "68334", name: "FWBank-First Women's Bank RO WC (68334)" },
    { value: "06869", value2: "06869", value3: "06869", value4: "06869", name: "Fairfield County Bank (06869)" },
    { value: "16743", value2: "16743", value3: "16743", value4: "16743", name: "Fairfield County Bank QB WC (16743)" },
    { value: "68007", value2: "68007", value3: "68007", value4: "68007", name: "Fairfield Federal S&L Business (68007)" },
    { value: "68006", value2: "68006", value3: "68006", value4: "68006", name: "Fairfield Federal Savings & Loan (68006)" },
    { value: "70016", value2: "70016", value3: "70016", value4: "70016", name: "Fairview State Banking Co (70016)" },
    { value: "70017", value2: "70017", value3: "70017", value4: "70016", name: "Fairview State Banking Co - DC (70017)" },
    { value: "17566", value2: "17566", value3: "17566", value4: "17566", name: "Fairwinds Credit Union - Business (17566)" },
    { value: "17563", value2: "17563", value3: "17563", value4: "17566", name: "Fairwinds Credit Union QB (17563)" },
    { value: "14254", value2: "14254", value3: "14254", value4: "14254", name: "Fairwinds Credit Union QuickBooks (14254)" },
    { value: "14365", value2: "14365", value3: "14365", value4: "14365", name: "Fairwinds Credit Union Quicken&QB (14365)" },
    { value: "00458", value2: "00458", value3: "00458", value4: "00458", name: "Faith Comm United CU Credit Card (00458)" },
    { value: "20364", value2: "20364", value3: "20364", value4: "20364", name: "Falcon International Bank (20364)" },
    { value: "27162", value2: "27162", value3: "27162", value4: "27162", name: "Falcon National Bank (27162)" },
    { value: "68299", value2: "68299", value3: "68299", value4: "68299", name: "Falls City National Bank (68299)" },
    { value: "21933", value2: "21933", value3: "21933", value4: "21933", name: "Family Trust FCU - SC (21933)" },
    { value: "58797", value2: "58797", value3: "58797", value4: "58797", name: "Fannin Bank (58797)" },
    { value: "16336", value2: "16336", value3: "16336", value4: "58797", name: "Fannin Bk-DC (16336)" },
    { value: "40557", value2: "40557", value3: "40557", value4: "40557", name: "Fargo Public Schools FCU (40557)" },
    { value: "56895", value2: "56895", value3: "56895", value4: "40557", name: "Fargo Public Schools_DC (56895)" },
    { value: "11650", value2: "11650", value3: "11650", value4: "11650", name: "Farm Bureau Bank (11650)" },
    { value: "67097", value2: "67097", value3: "67097", value4: "67097", name: "Farm Credit Mid America (67097)" },
    { value: "50160", value2: "50160", value3: "50160", value4: "50160", name: "Farm Credit Services of America (50160)" },
    { value: "12002", value2: "12002", value3: "12002", value4: "12002", name: "Farm Credit by AgVantis (12002)" },
    { value: "68496", value2: "68496", value3: "68496", value4: "68496", name: "Farmers & Merch St. Clair QBDC (68496)" },
    { value: "14203", value2: "14203", value3: "14203", value4: "14203", name: "Farmers & Merchants - Berlin (14203)" },
    { value: "00995", value2: "00995", value3: "00995", value4: "00995", name: "Farmers & Merchants - Business DC (00995)" },
    { value: "00998", value2: "00998", value3: "00998", value4: "00998", name: "Farmers & Merchants - Business WC (00998)" },
    { value: "01208", value2: "01208", value3: "01208", value4: "01208", name: "Farmers & Merchants - Personal DC (01208)" },
    { value: "01211", value2: "01211", value3: "01211", value4: "01211", name: "Farmers & Merchants - Personal WC (01211)" },
    { value: "14197", value2: "14197", value3: "14197", value4: "14197", name: "Farmers & Merchants Bank - Direct (14197)" },
    { value: "02052", value2: "02052", value3: "02052", value4: "02052", name: "Farmers & Merchants Bank - Lodi,CA (02052)" },
    { value: "14313", value2: "14313", value3: "14313", value4: "14313", name: "Farmers & Merchants Bank - VA (14313)" },
    { value: "68416", value2: "68416", value3: "68416", value4: "15144", name: "Farmers & Merchants Bank DC (68416)" },
    { value: "42693", value2: "42693", value3: "42693", value4: "42693", name: "Farmers & Merchants Bank GA (42693)" },
    { value: "04379", value2: "04379", value3: "04379", value4: "04379", name: "Farmers & Merchants Bank IN (04379)" },
    { value: "12245", value2: "12245", value3: "12245", value4: "12245", name: "Farmers & Merchants Bank IN - QBDC (12245)" },
    { value: "18190", value2: "18190", value3: "18190", value4: "18187", name: "Farmers & Merchants Bank LB QBWC (18190)" },
    { value: "18187", value2: "18187", value3: "18187", value4: "18187", name: "Farmers & Merchants Bank Long Bch (18187)" },
    { value: "05371", value2: "05371", value3: "05371", value4: "05371", name: "Farmers & Merchants Bank NE (05371)" },
    { value: "19080", value2: "19080", value3: "19080", value4: "19080", name: "Farmers & Merchants Bank Waterloo (19080)" },
    { value: "05393", value2: "05393", value3: "05393", value4: "05393", name: "Farmers & Merchants Bank of SC (05393)" },
    { value: "12710", value2: "12710", value3: "12710", value4: "12710", name: "Farmers & Merchants Bank, De Leon (12710)" },
    { value: "20211", value2: "20211", value3: "20211", value4: "20211", name: "Farmers & Merchants Bank-AR (20211)" },
    { value: "01904", value2: "01904", value3: "01904", value4: "01904", name: "Farmers & Merchants Bank-Arnett (01904)" },
    { value: "68217", value2: "68217", value3: "68217", value4: "68217", name: "Farmers & Merchants Bank-Dir Biz (68217)" },
    { value: "20208", value2: "20208", value3: "20208", value4: "20208", name: "Farmers & Merchants Bank-Piedmont (20208)" },
    { value: "09452", value2: "09452", value3: "09452", value4: "09452", name: "Farmers & Merchants Bank-Spfld DC (09452)" },
    { value: "11217", value2: "11217", value3: "11217", value4: "11217", name: "Farmers & Merchants Bank-Spfld MN (11217)" },
    { value: "08768", value2: "08768", value3: "08768", value4: "14313", name: "Farmers & Merchants Bank-VA-DC (08768)" },
    { value: "02562", value2: "02562", value3: "02562", value4: "02562", name: "Farmers & Merchants Bk Ashland (02562)" },
    { value: "49806", value2: "49806", value3: "49806", value4: "49806", name: "Farmers & Merchants Burlington (49806)" },
    { value: "04487", value2: "04487", value3: "04487", value4: "04487", name: "Farmers & Merchants Credit Card (04487)" },
    { value: "27804", value2: "27804", value3: "27804", value4: "27804", name: "Farmers & Merchants State BK Bush (27804)" },
    { value: "30321", value2: "30321", value3: "30321", value4: "30321", name: "Farmers & Merchants State Bank-NY (30321)" },
    { value: "02972", value2: "02972", value3: "02972", value4: "02972", name: "Farmers & Merchants Union Bank (02972)" },
    { value: "03764", value2: "03764", value3: "03764", value4: "03764", name: "Farmers & Merchants, Alpha, MN (03764)" },
    { value: "03767", value2: "03767", value3: "03767", value4: "03767", name: "Farmers & Merchants, Alpha, MNDC (03767)" },
    { value: "13965", value2: "13965", value3: "13965", value4: "13965", name: "Farmers & Merchants,Caldwell,OH (13965)" },
    { value: "05441", value2: "05441", value3: "05441", value4: "05441", name: "Farmers & Merchants-Baldwyn,MS (05441)" },
    { value: "36624", value2: "36624", value3: "36624", value4: "36624", name: "Farmers & Traders Bank (36624)" },
    { value: "09653", value2: "09653", value3: "09653", value4: "09653", name: "Farmers Bank & Savings Company (09653)" },
    { value: "11879", value2: "11879", value3: "11879", value4: "11879", name: "Farmers Bank & Savings Company-DC (11879)" },
    { value: "63921", value2: "63921", value3: "63921", value4: "63921", name: "Farmers Bank & Trust (63921)" },
    { value: "70134", value2: "70134", value3: "70134", value4: "70134", name: "Farmers Bank & Trust Co QBDC (70134)" },
    { value: "53961", value2: "53961", value3: "53961", value4: "53961", name: "Farmers Bank & Trust Company (53961)" },
    { value: "13362", value2: "13362", value3: "13362", value4: "13362", name: "Farmers Bank & Trust WC (13362)" },
    { value: "04489", value2: "04489", value3: "04489", value4: "04489", name: "Farmers Bank & Trust, Great Bend (04489)" },
    { value: "01422", value2: "01422", value3: "01422", value4: "01422", name: "Farmers Bank & Trust-Magnolia-AR (01422)" },
    { value: "06896", value2: "06896", value3: "06896", value4: "06896", name: "Farmers Bank &Trust  - YourNXTbank (06896)" },
    { value: "02267", value2: "02267", value3: "02267", value4: "02267", name: "Farmers Bank - Greenwood, AR (02267)" },
    { value: "69990", value2: "69990", value3: "69990", value4: "69990", name: "Farmers Bank - KY (69990)" },
    { value: "34779", value2: "34779", value3: "34779", value4: "34779", name: "Farmers Bank Ault, CO (34779)" },
    { value: "70423", value2: "70423", value3: "70423", value4: "70423", name: "Farmers Bank Bus CC (70423)" },
    { value: "68933", value2: "68933", value3: "68933", value4: "68933", name: "Farmers Bank Idaho Personal DC (68933)" },
    { value: "06962", value2: "06962", value3: "06962", value4: "06962", name: "Farmers Bank Idaho Personal WC (06962)" },
    { value: "68934", value2: "68934", value3: "68934", value4: "68934", name: "Farmers Bank Idaho eCORP DC (68934)" },
    { value: "70220", value2: "70220", value3: "70220", value4: "06896", name: "Farmers Bank and Trust Co QBDC (70220)" },
    { value: "07928", value2: "07928", value3: "07928", value4: "07928", name: "Farmers Bank of NorthernMissouri (07928)" },
    { value: "09626", value2: "09626", value3: "09626", value4: "09626", name: "Farmers Bank of Willards (09626)" },
    { value: "09737", value2: "09737", value3: "09737", value4: "09737", name: "Farmers Bank of Willards - Biz (09737)" },
    { value: "09629", value2: "09629", value3: "09629", value4: "09626", name: "Farmers Bank of Willards-QBDC (09629)" },
    { value: "09740", value2: "09740", value3: "09740", value4: "09740", name: "Farmers Bank of Willards-QBDCBiz (09740)" },
    { value: "64713", value2: "64713", value3: "64713", value4: "64713", name: "Farmers Insurance FCU (64713)" },
    { value: "68013", value2: "68013", value3: "68013", value4: "68013", name: "Farmers National Bank (68013)" },
    { value: "05045", value2: "05045", value3: "05045", value4: "05045", name: "Farmers National Bank BO QB (05045)" },
    { value: "20007", value2: "20007", value3: "20007", value4: "20007", name: "Farmers National Bank Canfield (20007)" },
    { value: "08891", value2: "08891", value3: "08891", value4: "08891", name: "Farmers National Bank IL (08891)" },
    { value: "12485", value2: "12485", value3: "12485", value4: "12485", name: "Farmers National Bank Lebanon (12485)" },
    { value: "03655", value2: "03655", value3: "03655", value4: "03655", name: "Farmers National Bank RO (03655)" },
    { value: "05042", value2: "05042", value3: "05042", value4: "05042", name: "Farmers National Bank RO QB (05042)" },
    { value: "12488", value2: "12488", value3: "12488", value4: "12485", name: "Farmers National Bk Lebanon QBDC (12488)" },
    { value: "64542", value2: "64542", value3: "64542", value4: "52629", name: "Farmers Savings Bank - DC (64542)" },
    { value: "16225", value2: "16225", value3: "16225", value4: "16225", name: "Farmers Savings Bank - Wever (16225)" },
    { value: "67068", value2: "67068", value3: "67068", value4: "67068", name: "Farmers Savings Bank - Wever DC (67068)" },
    { value: "51276", value2: "51276", value3: "51276", value4: "51276", name: "Farmers Savings Bank Marshalltown (51276)" },
    { value: "52629", value2: "52629", value3: "52629", value4: "52629", name: "Farmers Savings Bank, Fostoria, IA (52629)" },
    { value: "04419", value2: "04419", value3: "04419", value4: "04419", name: "Farmers Savings Bank-Mineral Pt (04419)" },
    { value: "03171", value2: "03171", value3: "03171", value4: "03171", name: "Farmers State Bank - Calhan (03171)" },
    { value: "69683", value2: "69683", value3: "69683", value4: "03171", name: "Farmers State Bank - Calhan QBDC (69683)" },
    { value: "02713", value2: "02713", value3: "02713", value4: "02713", name: "Farmers State Bank - Cameron, MO (02713)" },
    { value: "07387", value2: "07387", value3: "07387", value4: "07387", name: "Farmers State Bank - IL (07387)" },
    { value: "18024", value2: "18024", value3: "18024", value4: "18024", name: "Farmers State Bank - IN (18024)" },
    { value: "00564", value2: "00564", value3: "00564", value4: "00564", name: "Farmers State Bank - Marion, IA (00564)" },
    { value: "18021", value2: "18021", value3: "18021", value4: "18021", name: "Farmers State Bank - TX (18021)" },
    { value: "16615", value2: "16615", value3: "16615", value4: "16615", name: "Farmers State Bank - Waterloo (16615)" },
    { value: "03032", value2: "03032", value3: "03032", value4: "03032", name: "Farmers State Bank Hillsboro (03032)" },
    { value: "36642", value2: "36642", value3: "36642", value4: "36642", name: "Farmers State Bank KY (36642)" },
    { value: "09044", value2: "09044", value3: "09044", value4: "09044", name: "Farmers State Bank OH - DC (09044)" },
    { value: "09050", value2: "09050", value3: "09050", value4: "09050", name: "Farmers State Bank OH-BO QBWC (09050)" },
    { value: "12887", value2: "12887", value3: "12887", value4: "12887", name: "Farmers State Bank Victor MT (12887)" },
    { value: "04714", value2: "04714", value3: "04714", value4: "04714", name: "Farmers State Bank Victor-Bus (04714)" },
    { value: "70064", value2: "70064", value3: "70064", value4: "70064", name: "Farmers State Bank of Canton (70064)" },
    { value: "70433", value2: "70433", value3: "70433", value4: "70433", name: "Farmers State Bank of Canton-DC (70433)" },
    { value: "14370", value2: "14370", value3: "14370", value4: "14370", name: "Farmers State Bank of Lincolnton (14370)" },
    { value: "32445", value2: "32445", value3: "32445", value4: "32445", name: "Farmers State Bank of Newcastle (32445)" },
    { value: "40158", value2: "40158", value3: "40158", value4: "40158", name: "Farmers State Bank of Westmoreland (40158)" },
    { value: "57264", value2: "57264", value3: "57264", value4: "57264", name: "Farmers State Bank, West Salem (57264)" },
    { value: "52380", value2: "52380", value3: "52380", value4: "52380", name: "Farmers State Bank, Yale, IA (52380)" },
    { value: "63456", value2: "63456", value3: "63456", value4: "63456", name: "Farmers State Bank-Business (63456)" },
    { value: "03644", value2: "03644", value3: "03644", value4: "03644", name: "Farmers State Bank-Pittsfield (03644)" },
    { value: "69911", value2: "69911", value3: "69911", value4: "18021", name: "Farmers State Bank-TX QBDC (69911)" },
    { value: "17971", value2: "17971", value3: "17971", value4: "16615", name: "Farmers State New -Waterloo-QB (17971)" },
    { value: "52851", value2: "52851", value3: "52851", value4: "52851", name: "Farmers Trust & Savings Bank (52851)" },
    { value: "29562", value2: "29562", value3: "29562", value4: "29562", name: "Farmers Trust and Savings Bank (29562)" },
    { value: "59403", value2: "59403", value3: "59403", value4: "59403", name: "Farmers Trust&Savings Bank-Earling (59403)" },
    { value: "19032", value2: "19032", value3: "19032", value4: "19032", name: "Farmers and Drovers Bank (19032)" },
    { value: "06206", value2: "06206", value3: "06206", value4: "06206", name: "Farmers and Merchants BIZ (06206)" },
    { value: "17118", value2: "17118", value3: "17118", value4: "17118", name: "Farmers and Merchants Bank - GA (17118)" },
    { value: "02077", value2: "02077", value3: "02077", value4: "02077", name: "Farmers and Merchants Bank - IN (02077)" },
    { value: "24072", value2: "24072", value3: "24072", value4: "24072", name: "Farmers and Merchants Bank NE (24072)" },
    { value: "67147", value2: "67147", value3: "67147", value4: "67147", name: "Farmers and Merchants Bank QB (67147)" },
    { value: "16389", value2: "16389", value3: "16389", value4: "16389", name: "Farmers and Merchants St.Clair (16389)" },
    { value: "16906", value2: "16906", value3: "16906", value4: "16906", name: "Farmers and Miners Bank (16906)" },
    { value: "16912", value2: "16912", value3: "16912", value4: "16912", name: "Farmers and Miners Bank QBWC (16912)" },
    { value: "53163", value2: "53163", value3: "53163", value4: "53163", name: "Farmers-Merchants Bank & Trust Co. (53163)" },
    { value: "04395", value2: "04395", value3: "04395", value4: "04395", name: "Farmington Bank (04395)" },
    { value: "50400", value2: "50400", value3: "50400", value4: "50400", name: "Fayette County National Bank (50400)" },
    { value: "19680", value2: "19680", value3: "19680", value4: "19680", name: "Fayetteville Bank (19680)" },
    { value: "18643", value2: "18643", value3: "18643", value4: "18814", name: "FedChoice Federal CU WC (18643)" },
    { value: "18814", value2: "18814", value3: "18814", value4: "18814", name: "FedChoice Federal Credit Union (18814)" },
    { value: "68139", value2: "68139", value3: "68139", value4: "68139", name: "FedTrust FCU (68139)" },
    { value: "08351", value2: "08351", value3: "08351", value4: "08351", name: "Federal Employees Credit Union (08351)" },
    { value: "03092", value2: "03092", value3: "03092", value4: "03092", name: "Federal Employees Newark FCU CC (03092)" },
    { value: "39339", value2: "39339", value3: "39339", value4: "39339", name: "Federal Employees of Chippewa Cn (39339)" },
    { value: "65133", value2: "65133", value3: "65133", value4: "39339", name: "Federal Employees of Chippewa_DC (65133)" },
    { value: "02795", value2: "02795", value3: "02795", value4: "02795", name: "Federated Bank Credit Card (02795)" },
    { value: "09612", value2: "09612", value3: "09612", value4: "09612", name: "Federated Bank of Onarga (09612)" },
    { value: "51699", value2: "51699", value3: "51699", value4: "51699", name: "Federation Bank (51699)" },
    { value: "07997", value2: "07997", value3: "07997", value4: "51699", name: "Federation Bank - QB DC (07997)" },
    { value: "70346", value2: "70346", value3: "70346", value4: "70346", name: "Fellowship Credit Union (70346)" },
    { value: "03299", value2: "03299", value3: "03299", value4: "03299", name: "Fergus Federal CU (03299)" },
    { value: "07487", value2: "07487", value3: "07487", value4: "07487", name: "Fibre Federal Credit Union and TLC (07487)" },
    { value: "67146", value2: "67146", value3: "67146", value4: "67146", name: "Fidelis Catholic FCU (67146)" },
    { value: "05143", value2: "05143", value3: "05143", value4: "05143", name: "Fidelity & Oklahoma Fidelity Bank (05143)" },
    { value: "67914", value2: "67914", value3: "67914", value4: "67914", name: "Fidelity & Oklahoma Fidelity DC (67914)" },
    { value: "11308", value2: "11308", value3: "11308", value4: "11308", name: "Fidelity Bank (11308)" },
    { value: "58035", value2: "58035", value3: "58035", value4: "58035", name: "Fidelity Bank & Trust-Dubuque (58035)" },
    { value: "68011", value2: "68011", value3: "68011", value4: "68011", name: "Fidelity Bank - MA BB DC (68011)" },
    { value: "09787", value2: "09787", value3: "09787", value4: "09787", name: "Fidelity Bank - MA BB QB (09787)" },
    { value: "08234", value2: "08234", value3: "08234", value4: "08234", name: "Fidelity Bank Business Advantage (08234)" },
    { value: "56076", value2: "56076", value3: "56076", value4: "56076", name: "Fidelity Bank Credit Card (56076)" },
    { value: "11963", value2: "11963", value3: "11963", value4: "11963", name: "Fidelity Bank PA - Business (11963)" },
    { value: "12923", value2: "12923", value3: "12923", value4: "12923", name: "Fidelity Bank PA - Business WC (12923)" },
    { value: "06320", value2: "06320", value3: "06320", value4: "06320", name: "Fidelity Bank, New Orleans (06320)" },
    { value: "62514", value2: "62514", value3: "62514", value4: "62514", name: "Fidelity Banking (62514)" },
    { value: "13176", value2: "13176", value3: "13176", value4: "13176", name: "Fidelity Deposit & Discount Bank (13176)" },
    { value: "09446", value2: "09446", value3: "09446", value4: "09446", name: "Fidelity Express Web (09446)" },
    { value: "31356", value2: "31356", value3: "31356", value4: "31356", name: "Fidelity Federal Savings (31356)" },
    { value: "20445", value2: "20445", value3: "20445", value4: "20445", name: "Fidelity National Bank (20445)" },
    { value: "19791", value2: "19791", value3: "19791", value4: "19791", name: "Fidelity State Bank &Trust Company (19791)" },
    { value: "34257", value2: "34257", value3: "34257", value4: "34257", name: "Field and Main Bank (34257)" },
    { value: "08444", value2: "08444", value3: "08444", value4: "08444", name: "Fieldpoint Private (08444)" },
    { value: "08456", value2: "08456", value3: "08456", value4: "08456", name: "Fieldpoint Private-DC (08456)" },
    { value: "69393", value2: "69393", value3: "69393", value4: "69393", name: "Fieldpoint Private-TM (69393)" },
    { value: "69394", value2: "69394", value3: "69394", value4: "69393", name: "Fieldpoint Private-TM-DC (69394)" },
    { value: "53292", value2: "53292", value3: "53292", value4: "53292", name: "Fifth Third Bank (53292)" },
    { value: "18523", value2: "18523", value3: "18523", value4: "18523", name: "Fifth Third Bank Commercial (18523)" },
    { value: "70360", value2: "70360", value3: "70360", value4: "70360", name: "Filer Credit Union (70360)" },
    { value: "69795", value2: "69795", value3: "69795", value4: "69795", name: "FinWise Bank IB QB (69795)" },
    { value: "13422", value2: "13422", value3: "13422", value4: "13422", name: "Financial Center First CU (13422)" },
    { value: "69145", value2: "69145", value3: "69145", value4: "69145", name: "Financial Ctr First CU CC (69145)" },
    { value: "08084", value2: "08084", value3: "08084", value4: "08084", name: "Financial Federal Business DC (08084)" },
    { value: "51093", value2: "51093", value3: "51093", value4: "51093", name: "Financial Federal CU (51093)" },
    { value: "16044", value2: "16044", value3: "16044", value4: "16044", name: "Financial Horizons Credit Union (16044)" },
    { value: "01284", value2: "01284", value3: "01284", value4: "01284", name: "Financial Partners Credit Union (01284)" },
    { value: "09230", value2: "09230", value3: "09230", value4: "09230", name: "Financial Partners FCU Credit Card (09230)" },
    { value: "17235", value2: "17235", value3: "17235", value4: "17235", name: "Financial Plus Credit Union MI (17235)" },
    { value: "01379", value2: "01379", value3: "01379", value4: "01379", name: "Financial Plus Credit Union, IL-QB (01379)" },
    { value: "04288", value2: "04288", value3: "04288", value4: "04288", name: "Financial Resources FCU (04288)" },
    { value: "69985", value2: "69985", value3: "69985", value4: "69985", name: "Financial Resources FCU - QBWC (69985)" },
    { value: "68286", value2: "68286", value3: "68286", value4: "68286", name: "Financial Security Bank Biz (68286)" },
    { value: "68287", value2: "68287", value3: "68287", value4: "68287", name: "Financial Security Bank Biz QBDC (68287)" },
    { value: "68284", value2: "68284", value3: "68284", value4: "68284", name: "Financial Security Bank Pers (68284)" },
    { value: "68285", value2: "68285", value3: "68285", value4: "68285", name: "Financial Security Bank Pers QBD (68285)" },
    { value: "06130", value2: "06130", value3: "06130", value4: "06130", name: "FineMark National Bank & Trust (06130)" },
    { value: "56763", value2: "56763", value3: "56763", value4: "56763", name: "FineMark National Bank&Trust-BEB (56763)" },
    { value: "49794", value2: "49794", value3: "49794", value4: "49794", name: "Finger Lakes Federal Credit Union (49794)" },
    { value: "61365", value2: "61365", value3: "61365", value4: "61365", name: "Fire Police City County FCU (61365)" },
    { value: "10055", value2: "10055", value3: "10055", value4: "10055", name: "Firefighters First CU (10055)" },
    { value: "69107", value2: "69107", value3: "69107", value4: "69107", name: "Firefighters First CU BB DC (69107)" },
    { value: "65484", value2: "65484", value3: "65484", value4: "65484", name: "Firefighters First CU QB (65484)" },
    { value: "00645", value2: "00645", value3: "00645", value4: "00645", name: "Firelands FCU (00645)" },
    { value: "18816", value2: "18816", value3: "18816", value4: "18816", name: "FirsTier Bank (18816)" },
    { value: "09553", value2: "09553", value3: "09553", value4: "09553", name: "First & Farmers National Bank (09553)" },
    { value: "09541", value2: "09541", value3: "09541", value4: "09541", name: "First & Farmers National Bank DC (09541)" },
    { value: "63333", value2: "63333", value3: "63333", value4: "63333", name: "First American Bank & Trust (63333)" },
    { value: "04463", value2: "04463", value3: "04463", value4: "04463", name: "First American Bank & Trust-DC (04463)" },
    { value: "67617", value2: "67617", value3: "67617", value4: "67617", name: "First American Bank & Trust-TM (67617)" },
    { value: "67618", value2: "67618", value3: "67618", value4: "67617", name: "First American Bank & Trust-TMDC (67618)" },
    { value: "51084", value2: "51084", value3: "51084", value4: "51084", name: "First American Bank - CashTrac (51084)" },
    { value: "69614", value2: "69614", value3: "69614", value4: "69614", name: "First American Bank - IL (69614)" },
    { value: "18427", value2: "18427", value3: "18427", value4: "51084", name: "First American Bank CashTrac QB (18427)" },
    { value: "69615", value2: "69615", value3: "69615", value4: "69614", name: "First American Bank IL-DC (69615)" },
    { value: "69120", value2: "69120", value3: "69120", value4: "69120", name: "First American Bank NM BB - DC (69120)" },
    { value: "01591", value2: "01591", value3: "01591", value4: "01591", name: "First American Bank and Trust (01591)" },
    { value: "03790", value2: "03790", value3: "03790", value4: "03790", name: "First American Bank, NM - BB QB (03790)" },
    { value: "54069", value2: "54069", value3: "54069", value4: "54069", name: "First American Bank, NM - IB WC QB (54069)" },
    { value: "04883", value2: "04883", value3: "04883", value4: "04883", name: "First American Credit Union (04883)" },
    { value: "08204", value2: "08204", value3: "08204", value4: "08204", name: "First American State Bank (08204)" },
    { value: "08207", value2: "08207", value3: "08207", value4: "08204", name: "First American State Bank-WC (08207)" },
    { value: "03630", value2: "03630", value3: "03630", value4: "03630", name: "First Arkansas Bank & Trust (03630)" },
    { value: "67276", value2: "67276", value3: "67276", value4: "67276", name: "First Arkansas Bank & Trust - DC (67276)" },
    { value: "00710", value2: "00710", value3: "00710", value4: "00710", name: "First Atlantic FCU (00710)" },
    { value: "11041", value2: "11041", value3: "11041", value4: "11041", name: "First Bancorp (11041)" },
    { value: "69125", value2: "69125", value3: "69125", value4: "69125", name: "First Bancorp QB DC (69125)" },
    { value: "01717", value2: "01717", value3: "01717", value4: "01717", name: "First Bank & Trust  Brookings (01717)" },
    { value: "04496", value2: "04496", value3: "04496", value4: "04496", name: "First Bank & Trust Co - OK (04496)" },
    { value: "52836", value2: "52836", value3: "52836", value4: "52836", name: "First Bank & Trust Co Duncan OK (52836)" },
    { value: "70249", value2: "70249", value3: "70249", value4: "70249", name: "First Bank & Trust SD - BUS CC (70249)" },
    { value: "07682", value2: "07682", value3: "07682", value4: "07682", name: "First Bank & Trust, Fullerton (07682)" },
    { value: "07688", value2: "07688", value3: "07688", value4: "07682", name: "First Bank & Trust, Fullerton DC (07688)" },
    { value: "07409", value2: "07409", value3: "07409", value4: "04427", name: "First Bank - Direct (07409)" },
    { value: "04427", value2: "04427", value3: "04427", value4: "04427", name: "First Bank - MS (04427)" },
    { value: "09107", value2: "09107", value3: "09107", value4: "09107", name: "First Bank - OK  DC (09107)" },
    { value: "08417", value2: "08417", value3: "08417", value4: "08417", name: "First Bank - Sterling, KS QB (08417)" },
    { value: "04494", value2: "04494", value3: "04494", value4: "04494", name: "First Bank - TX (04494)" },
    { value: "02527", value2: "02527", value3: "02527", value4: "02527", name: "First Bank -VA (02527)" },
    { value: "01667", value2: "01667", value3: "01667", value4: "01667", name: "First Bank Alaska (01667)" },
    { value: "11914", value2: "11914", value3: "11914", value4: "11914", name: "First Bank Business eBanking (11914)" },
    { value: "24111", value2: "24111", value3: "24111", value4: "24111", name: "First Bank Chicago (24111)" },
    { value: "57879", value2: "57879", value3: "57879", value4: "57879", name: "First Bank Chicago Business (57879)" },
    { value: "69386", value2: "69386", value3: "69386", value4: "69386", name: "First Bank Chicago Comm DC (69386)" },
    { value: "01067", value2: "01067", value3: "01067", value4: "01067", name: "First Bank Elk River (01067)" },
    { value: "12635", value2: "12635", value3: "12635", value4: "12635", name: "First Bank Hampton (12635)" },
    { value: "09577", value2: "09577", value3: "09577", value4: "09577", name: "First Bank Kansas (09577)" },
    { value: "69021", value2: "69021", value3: "69021", value4: "69021", name: "First Bank Of Utica (69021)" },
    { value: "69022", value2: "69022", value3: "69022", value4: "69022", name: "First Bank Of Utica - DC (69022)" },
    { value: "64827", value2: "64827", value3: "64827", value4: "64827", name: "First Bank Richmond IN (64827)" },
    { value: "68727", value2: "68727", value3: "68727", value4: "64827", name: "First Bank Richmond IN QBDC (68727)" },
    { value: "59076", value2: "59076", value3: "59076", value4: "59076", name: "First Bank Texas (59076)" },
    { value: "67116", value2: "67116", value3: "67116", value4: "59076", name: "First Bank Texas QBDC (67116)" },
    { value: "08336", value2: "08336", value3: "08336", value4: "08336", name: "First Bank Upper MI - Consumer (08336)" },
    { value: "09776", value2: "09776", value3: "09776", value4: "09776", name: "First Bank Upper MI e-Business (09776)" },
    { value: "68019", value2: "68019", value3: "68019", value4: "68019", name: "First Bank and Trust Company (68019)" },
    { value: "17214", value2: "17214", value3: "17214", value4: "17214", name: "First Bank of Alabama (17214)" },
    { value: "01889", value2: "01889", value3: "01889", value4: "01889", name: "First Bank of Beloit (01889)" },
    { value: "00656", value2: "00656", value3: "00656", value4: "00656", name: "First Bank of Berne - Business (00656)" },
    { value: "32238", value2: "32238", value3: "32238", value4: "32238", name: "First Bank of Boaz Boaz, Alabama (32238)" },
    { value: "52857", value2: "52857", value3: "52857", value4: "52857", name: "First Bank of Carmi, IL (52857)" },
    { value: "68080", value2: "68080", value3: "68080", value4: "68080", name: "First Bank of Central Ohio Bus (68080)" },
    { value: "32466", value2: "32466", value3: "32466", value4: "32466", name: "First Bank of Coastal Georgia (32466)" },
    { value: "04490", value2: "04490", value3: "04490", value4: "04490", name: "First Bank of Coastal Georgia CC (04490)" },
    { value: "62463", value2: "62463", value3: "62463", value4: "62463", name: "First Bank of Greenwich Bus New (62463)" },
    { value: "70518", value2: "70518", value3: "70518", value4: "70518", name: "First Bank of Montana (70518)" },
    { value: "70507", value2: "70507", value3: "70507", value4: "70507", name: "First Bank of Montana-DC (70507)" },
    { value: "13759", value2: "13759", value3: "13759", value4: "13759", name: "First Bank of Owasso DC & WC QFX (13759)" },
    { value: "13756", value2: "13756", value3: "13756", value4: "13756", name: "First Bank of Owasso WC for QB (13756)" },
    { value: "09104", value2: "09104", value3: "09104", value4: "09104", name: "First Bank, OK (09104)" },
    { value: "08300", value2: "08300", value3: "08300", value4: "08300", name: "First Bank- NJ (08300)" },
    { value: "08306", value2: "08306", value3: "08306", value4: "08306", name: "First Bank- NJ BO DC (08306)" },
    { value: "08303", value2: "08303", value3: "08303", value4: "08303", name: "First Bank- NJ BO WC (08303)" },
    { value: "20145", value2: "20145", value3: "20145", value4: "20145", name: "First Bank-Sterling - KS (20145)" },
    { value: "64512", value2: "64512", value3: "64512", value4: "64512", name: "First Bank-Wavery IA (64512)" },
    { value: "05368", value2: "05368", value3: "05368", value4: "05368", name: "First Bankcard (05368)" },
    { value: "69453", value2: "69453", value3: "69453", value4: "69453", name: "First Bankcard EWC (69453)" },
    { value: "09281", value2: "09281", value3: "09281", value4: "09281", name: "First Bankers Trust (09281)" },
    { value: "09287", value2: "09287", value3: "09287", value4: "09287", name: "First Bankers Trust QBDC (09287)" },
    { value: "15865", value2: "15865", value3: "15865", value4: "15865", name: "First Basin Credit Union (15865)" },
    { value: "27870", value2: "27870", value3: "27870", value4: "27870", name: "First Bethany Bank & Trust (27870)" },
    { value: "68107", value2: "68107", value3: "68107", value4: "27870", name: "First Bethany Bank & Trust QBDC (68107)" },
    { value: "69121", value2: "69121", value3: "69121", value4: "69121", name: "First Cahawba Bank - DC (69121)" },
    { value: "08212", value2: "08212", value3: "08212", value4: "08212", name: "First Cahawba Bank - New (08212)" },
    { value: "67141", value2: "67141", value3: "67141", value4: "67141", name: "First Capital Bank Bus Direct (67141)" },
    { value: "12317", value2: "12317", value3: "12317", value4: "12317", name: "First Capital Bank Business (12317)" },
    { value: "12756", value2: "12756", value3: "12756", value4: "12756", name: "First Capital Bank-Quanah (12756)" },
    { value: "68118", value2: "68118", value3: "68118", value4: "68118", name: "First Capital Federal CU QB (68118)" },
    { value: "20295", value2: "20295", value3: "20295", value4: "20295", name: "First Carolina Bank (20295)" },
    { value: "08330", value2: "08330", value3: "08330", value4: "20295", name: "First Carolina Bank-Direct (08330)" },
    { value: "69747", value2: "69747", value3: "69747", value4: "69747", name: "First Carolina Bank-TM (69747)" },
    { value: "69748", value2: "69748", value3: "69748", value4: "69747", name: "First Carolina Bank-TM-DC (69748)" },
    { value: "58803", value2: "58803", value3: "58803", value4: "58803", name: "First Central Bank  McCook (58803)" },
    { value: "58617", value2: "58617", value3: "58617", value4: "58617", name: "First Central Bank - NE (58617)" },
    { value: "64647", value2: "64647", value3: "64647", value4: "64647", name: "First Central Savings Bank BO DC (64647)" },
    { value: "06494", value2: "06494", value3: "06494", value4: "06494", name: "First Central Savings Bank BO WC (06494)" },
    { value: "06286", value2: "06286", value3: "06286", value4: "06286", name: "First Central State Bank (06286)" },
    { value: "12239", value2: "12239", value3: "12239", value4: "12239", name: "First Century Bank (12239)" },
    { value: "12242", value2: "12242", value3: "12242", value4: "12239", name: "First Century Bank DC (12242)" },
    { value: "04287", value2: "04287", value3: "04287", value4: "04287", name: "First Chatham Bank (04287)" },
    { value: "05351", value2: "05351", value3: "05351", value4: "05351", name: "First Chatham Bank Business QB (05351)" },
    { value: "05348", value2: "05348", value3: "05348", value4: "05348", name: "First Chatham Bank Personal QB (05348)" },
    { value: "67291", value2: "67291", value3: "67291", value4: "67291", name: "First Choice Financial (67291)" },
    { value: "15013", value2: "15013", value3: "15013", value4: "15013", name: "First Citizens Bank - DC (15013)" },
    { value: "67103", value2: "67103", value3: "67103", value4: "67103", name: "First Citizens Bank HOA (67103)" },
    { value: "67104", value2: "67104", value3: "67104", value4: "67103", name: "First Citizens Bank HOA QBDC (67104)" },
    { value: "42579", value2: "42579", value3: "42579", value4: "42579", name: "First Citizens Bank IA (42579)" },
    { value: "07727", value2: "07727", value3: "07727", value4: "07727", name: "First Citizens Bank IA WC QB (07727)" },
    { value: "23445", value2: "23445", value3: "23445", value4: "23445", name: "First Citizens Bank of Luverne (23445)" },
    { value: "03257", value2: "03257", value3: "03257", value4: "03257", name: "First Citizens Bank, Credit Card (03257)" },
    { value: "57534", value2: "57534", value3: "57534", value4: "57534", name: "First Citizens Bk of Butte WC QB (57534)" },
    { value: "67650", value2: "67650", value3: "67650", value4: "67650", name: "First Citizens Comm Advantage (67650)" },
    { value: "01073", value2: "01073", value3: "01073", value4: "01073", name: "First Citizens Community Bank (01073)" },
    { value: "10516", value2: "10516", value3: "10516", value4: "15013", name: "First Citizens Digital Bkng Web (10516)" },
    { value: "00689", value2: "00689", value3: "00689", value4: "00689", name: "First Citizens Federal CU (00689)" },
    { value: "68946", value2: "68946", value3: "68946", value4: "68946", name: "First Citizens NB - Bus DC (68946)" },
    { value: "03002", value2: "03002", value3: "03002", value4: "03002", name: "First Citizens NB Bus Banking (03002)" },
    { value: "18651", value2: "18651", value3: "18651", value4: "18651", name: "First Citizens State Bank (18651)" },
    { value: "09380", value2: "09380", value3: "09380", value4: "09380", name: "First City Bank OH - QBDC (09380)" },
    { value: "15375", value2: "15375", value3: "15375", value4: "15375", name: "First Colony Bank of Florida-BIB (15375)" },
    { value: "16836", value2: "16836", value3: "16836", value4: "16836", name: "First Colorado National Bank (16836)" },
    { value: "67278", value2: "67278", value3: "67278", value4: "67278", name: "First Comm Bank RGV Bus (67278)" },
    { value: "67526", value2: "67526", value3: "67526", value4: "67526", name: "First Comm Bank RGV Con DC (67526)" },
    { value: "67279", value2: "67279", value3: "67279", value4: "67279", name: "First Comm Bank RGV DC Bus (67279)" },
    { value: "69760", value2: "69760", value3: "69760", value4: "69760", name: "First Comm Bank TM CFO DC Bus (69760)" },
    { value: "69763", value2: "69763", value3: "69763", value4: "69763", name: "First Comm Bk TM CFO WC Bus (69763)" },
    { value: "17044", value2: "17044", value3: "17044", value4: "17044", name: "First Command Bank WC (17044)" },
    { value: "11497", value2: "11497", value3: "11497", value4: "11497", name: "First Commerce Bank (11497)" },
    { value: "13542", value2: "13542", value3: "13542", value4: "13542", name: "First Commerce Bank Lewisburg (13542)" },
    { value: "63219", value2: "63219", value3: "63219", value4: "63219", name: "First Commerce Bank WC (63219)" },
    { value: "57666", value2: "57666", value3: "57666", value4: "57666", name: "First Commerce Bank-Business (57666)" },
    { value: "58758", value2: "58758", value3: "58758", value4: "58758", name: "First Commerce Bank-Consumer (58758)" },
    { value: "17047", value2: "17047", value3: "17047", value4: "17047", name: "First Commerce Credit Union (17047)" },
    { value: "05192", value2: "05192", value3: "05192", value4: "05192", name: "First Commerce Credit Union - QB (05192)" },
    { value: "08623", value2: "08623", value3: "08623", value4: "08623", name: "First Commercial Bank - Seguin, TX (08623)" },
    { value: "03818", value2: "03818", value3: "03818", value4: "03818", name: "First Commonwealth Bank (03818)" },
    { value: "03824", value2: "03824", value3: "03824", value4: "03818", name: "First Commonwealth Bank-DL (03824)" },
    { value: "67142", value2: "67142", value3: "67142", value4: "67142", name: "First Commonwealth Bank-TM (67142)" },
    { value: "67143", value2: "67143", value3: "67143", value4: "67142", name: "First Commonwealth Bankâ€“TM-DC (67143)" },
    { value: "04743", value2: "04743", value3: "04743", value4: "04743", name: "First Commonwealth FCU Web (04743)" },
    { value: "68586", value2: "68586", value3: "68586", value4: "68586", name: "First Community Bank & Trust Biz (68586)" },
    { value: "68570", value2: "68570", value3: "68570", value4: "68570", name: "First Community Bank & Trust DC (68570)" },
    { value: "18807", value2: "18807", value3: "18807", value4: "18807", name: "First Community Bank & Trust Per (18807)" },
    { value: "69382", value2: "69382", value3: "69382", value4: "69382", name: "First Community Bank - AR (69382)" },
    { value: "10391", value2: "10391", value3: "10391", value4: "10391", name: "First Community Bank - MI (10391)" },
    { value: "28677", value2: "28677", value3: "28677", value4: "28677", name: "First Community Bank Heartland (28677)" },
    { value: "50199", value2: "50199", value3: "50199", value4: "50199", name: "First Community Bank Milton-BeB (50199)" },
    { value: "51264", value2: "51264", value3: "51264", value4: "51264", name: "First Community Bank Nebraska (51264)" },
    { value: "69550", value2: "69550", value3: "69550", value4: "69550", name: "First Community Bank Ozarks (69550)" },
    { value: "00450", value2: "00450", value3: "00450", value4: "00450", name: "First Community Bank SC Business (00450)" },
    { value: "50223", value2: "50223", value3: "50223", value4: "50223", name: "First Community Bank of Central AL (50223)" },
    { value: "67859", value2: "67859", value3: "67859", value4: "67859", name: "First Community Bank of East TN (67859)" },
    { value: "27462", value2: "27462", value3: "27462", value4: "27462", name: "First Community Bank of TN (27462)" },
    { value: "13203", value2: "13203", value3: "13203", value4: "13203", name: "First Community Bank, Batesville (13203)" },
    { value: "00165", value2: "00165", value3: "00165", value4: "00165", name: "First Community Bank, Bluefield VA (00165)" },
    { value: "02239", value2: "02239", value3: "02239", value4: "02239", name: "First Community CU of Houston (02239)" },
    { value: "18058", value2: "18058", value3: "18058", value4: "18058", name: "First Community CU of Houston -QB (18058)" },
    { value: "11132", value2: "11132", value3: "11132", value4: "11132", name: "First Community CU of Oregon (11132)" },
    { value: "11352", value2: "11352", value3: "11352", value4: "11352", name: "First Community Credit Union (11352)" },
    { value: "03482", value2: "03482", value3: "03482", value4: "03482", name: "First County Bank - CT (03482)" },
    { value: "03485", value2: "03485", value3: "03485", value4: "03482", name: "First County Bank - WC (03485)" },
    { value: "04272", value2: "04272", value3: "04272", value4: "04272", name: "First Credit Union (04272)" },
    { value: "01319", value2: "01319", value3: "01319", value4: "01319", name: "First Dakota National Bank (01319)" },
    { value: "64137", value2: "64137", value3: "64137", value4: "64137", name: "First Eagle Bank (64137)" },
    { value: "67616", value2: "67616", value3: "67616", value4: "67616", name: "First Eagle Bank Business Direct (67616)" },
    { value: "16314", value2: "16314", value3: "16314", value4: "16314", name: "First Enterprise Bank (16314)" },
    { value: "57738", value2: "57738", value3: "57738", value4: "57738", name: "First Entertainment CU (57738)" },
    { value: "08801", value2: "08801", value3: "08801", value4: "08801", name: "First Exchange Bank DCQB (08801)" },
    { value: "58398", value2: "58398", value3: "58398", value4: "58398", name: "First Exchange Bank Quickbooks (58398)" },
    { value: "04538", value2: "04538", value3: "04538", value4: "04538", name: "First FarmBank QB (04538)" },
    { value: "10331", value2: "10331", value3: "10331", value4: "10331", name: "First Farmers & Merchants Bank DC (10331)" },
    { value: "11268", value2: "11268", value3: "11268", value4: "11268", name: "First Farmers Bank & Trust (11268)" },
    { value: "12455", value2: "12455", value3: "12455", value4: "11268", name: "First Farmers Bank & Trust - DC (12455)" },
    { value: "69044", value2: "69044", value3: "69044", value4: "69044", name: "First Farmers Bank - Business DC (69044)" },
    { value: "04017", value2: "04017", value3: "04017", value4: "04017", name: "First Farmers and Merchants BIB (04017)" },
    { value: "67669", value2: "67669", value3: "67669", value4: "67669", name: "First Fed Delta (67669)" },
    { value: "57432", value2: "57432", value3: "57432", value4: "52716", name: "First Fed Savings of Lorain (57432)" },
    { value: "03345", value2: "03345", value3: "03345", value4: "03345", name: "First Federal Bank (03345)" },
    { value: "18747", value2: "18747", value3: "18747", value4: "18747", name: "First Federal Bank & Trust (18747)" },
    { value: "63006", value2: "63006", value3: "63006", value4: "63006", name: "First Federal Bank - Dunn, NC (63006)" },
    { value: "00443", value2: "00443", value3: "00443", value4: "00443", name: "First Federal Bank Credit Card (00443)" },
    { value: "64125", value2: "64125", value3: "64125", value4: "64125", name: "First Federal Bank DC (64125)" },
    { value: "04367", value2: "04367", value3: "04367", value4: "04367", name: "First Federal Bank Littlefield (04367)" },
    { value: "64128", value2: "64128", value3: "64128", value4: "64128", name: "First Federal Bank WC (64128)" },
    { value: "53145", value2: "53145", value3: "53145", value4: "53145", name: "First Federal Bank-Harrison,AR-BB (53145)" },
    { value: "69627", value2: "69627", value3: "69627", value4: "69627", name: "First Federal Champaign-Urbana (69627)" },
    { value: "68694", value2: "68694", value3: "68694", value4: "68694", name: "First Federal Community Bank BO (68694)" },
    { value: "68693", value2: "68693", value3: "68693", value4: "68693", name: "First Federal Community Bank RO (68693)" },
    { value: "06110", value2: "06110", value3: "06110", value4: "06110", name: "First Federal Dover (06110)" },
    { value: "06113", value2: "06113", value3: "06113", value4: "06110", name: "First Federal Dover - QB WC (06113)" },
    { value: "07115", value2: "07115", value3: "07115", value4: "07115", name: "First Federal Lakewood-Bus QBDC (07115)" },
    { value: "64014", value2: "64014", value3: "64014", value4: "64014", name: "First Federal Lakewood-Business (64014)" },
    { value: "63144", value2: "63144", value3: "63144", value4: "63144", name: "First Federal McMinnville - Bus (63144)" },
    { value: "01259", value2: "01259", value3: "01259", value4: "01259", name: "First Federal S&L of Bath WC QB (01259)" },
    { value: "52716", value2: "52716", value3: "52716", value4: "52716", name: "First Federal S&L of Lorain (52716)" },
    { value: "04528", value2: "04528", value3: "04528", value4: "04528", name: "First Federal S&L-Port Angeles-QB (04528)" },
    { value: "53748", value2: "53748", value3: "53748", value4: "53748", name: "First Federal SB, Evansville (53748)" },
    { value: "02408", value2: "02408", value3: "02408", value4: "02408", name: "First Federal Savings -Lincolnton (02408)" },
    { value: "12497", value2: "12497", value3: "12497", value4: "12497", name: "First Federal Savings Bank (Rochester, IN) - Business (12497)" },
    { value: "64281", value2: "64281", value3: "64281", value4: "64281", name: "First Federal Savings Bank Hunting (64281)" },
    { value: "08210", value2: "08210", value3: "08210", value4: "08210", name: "First Federal Savings Newark (08210)" },
    { value: "08219", value2: "08219", value3: "08219", value4: "08210", name: "First Federal Savings Newark-WC (08219)" },
    { value: "52773", value2: "52773", value3: "52773", value4: "52773", name: "First Federal Savings of Lorain (52773)" },
    { value: "02643", value2: "02643", value3: "02643", value4: "02643", name: "First Federal Twin Falls ID - WC (02643)" },
    { value: "06260", value2: "06260", value3: "06260", value4: "06260", name: "First Federal Twin Falls ID-DC (06260)" },
    { value: "68549", value2: "68549", value3: "68549", value4: "68549", name: "First Federal WI Commercial (68549)" },
    { value: "68548", value2: "68548", value3: "68548", value4: "68548", name: "First Federal WI Commercial QBDC (68548)" },
    { value: "08246", value2: "08246", value3: "08246", value4: "08246", name: "First Federal Yamhill BO DC (08246)" },
    { value: "04911", value2: "04911", value3: "04911", value4: "04911", name: "First Fidelity Bank (04911)" },
    { value: "70234", value2: "70234", value3: "70234", value4: "70234", name: "First Fidelity Bank - Bus CC (70234)" },
    { value: "57357", value2: "57357", value3: "57357", value4: "57357", name: "First Fidelity Bank - SD (57357)" },
    { value: "09821", value2: "09821", value3: "09821", value4: "09821", name: "First Fidelity Bank Fort Payne (09821)" },
    { value: "04176", value2: "04176", value3: "04176", value4: "04176", name: "First Financial Bank (04176)" },
    { value: "00941", value2: "00941", value3: "00941", value4: "00941", name: "First Financial Bank - BO (00941)" },
    { value: "64194", value2: "64194", value3: "64194", value4: "64194", name: "First Financial Bank - QB (64194)" },
    { value: "10500", value2: "10500", value3: "10500", value4: "10500", name: "First Financial Bank - Texas (10500)" },
    { value: "12707", value2: "12707", value3: "12707", value4: "12707", name: "First Financial Bank - Texas QBDC (12707)" },
    { value: "12443", value2: "12443", value3: "12443", value4: "12443", name: "First Financial Bank AR-Bus DC (12443)" },
    { value: "12440", value2: "12440", value3: "12440", value4: "12440", name: "First Financial Bank AR-Business (12440)" },
    { value: "15676", value2: "15676", value3: "15676", value4: "15676", name: "First Financial Bank IN QFX & QB (15676)" },
    { value: "07820", value2: "07820", value3: "07820", value4: "55104", name: "First Financial Bank OH - QB (07820)" },
    { value: "53058", value2: "53058", value3: "53058", value4: "53058", name: "First Financial Bank in Winnebago (53058)" },
    { value: "19158", value2: "19158", value3: "19158", value4: "19158", name: "First Financial Bank of Bessemer (19158)" },
    { value: "52488", value2: "52488", value3: "52488", value4: "52488", name: "First Financial Bank-Business (52488)" },
    { value: "12716", value2: "12716", value3: "12716", value4: "12716", name: "First Financial Bank-Business QBDC (12716)" },
    { value: "70022", value2: "70022", value3: "70022", value4: "69848", name: "First Financial Bank-DC (70022)" },
    { value: "69848", value2: "69848", value3: "69848", value4: "69848", name: "First Financial Bank-OH (69848)" },
    { value: "68356", value2: "68356", value3: "68356", value4: "68356", name: "First Financial CU of NM (68356)" },
    { value: "16888", value2: "16888", value3: "16888", value4: "16888", name: "First Financial Corporation (16888)" },
    { value: "68104", value2: "68104", value3: "68104", value4: "68104", name: "First Financial FCU Credit Card (68104)" },
    { value: "18529", value2: "18529", value3: "18529", value4: "18529", name: "First Financial Northwest Bank DC (18529)" },
    { value: "18526", value2: "18526", value3: "18526", value4: "18526", name: "First Financial Northwest Bank WC (18526)" },
    { value: "15444", value2: "15444", value3: "15444", value4: "15444", name: "First Flight FCU (15444)" },
    { value: "13981", value2: "13981", value3: "13981", value4: "13981", name: "First Flight FCU - QB (13981)" },
    { value: "51903", value2: "51903", value3: "51903", value4: "51903", name: "First Florida Credit Union (51903)" },
    { value: "15075", value2: "15075", value3: "15075", value4: "15075", name: "First Foundation Bank (15075)" },
    { value: "56319", value2: "56319", value3: "56319", value4: "56319", name: "First Foundation Bank Business (56319)" },
    { value: "14034", value2: "14034", value3: "14034", value4: "14034", name: "First Freedom Bank (14034)" },
    { value: "00840", value2: "00840", value3: "00840", value4: "00840", name: "First Guaranty Bank (00840)" },
    { value: "02501", value2: "02501", value3: "02501", value4: "02501", name: "First Harrison Bank (02501)" },
    { value: "25260", value2: "25260", value3: "25260", value4: "25260", name: "First Harvest Credit Union (25260)" },
    { value: "70128", value2: "70128", value3: "70128", value4: "70128", name: "First Hawaiian Bank (70128)" },
    { value: "70129", value2: "70129", value3: "70129", value4: "70128", name: "First Hawaiian Bank QBDC (70129)" },
    { value: "68091", value2: "68091", value3: "68091", value4: "68091", name: "First Heritage Bank - KS DC (68091)" },
    { value: "68090", value2: "68090", value3: "68090", value4: "68090", name: "First Heritage Bank, KS (68090)" },
    { value: "57429", value2: "57429", value3: "57429", value4: "57429", name: "First Heritage Federal CU (57429)" },
    { value: "09939", value2: "09939", value3: "09939", value4: "09939", name: "First Heroes National Bank QB (09939)" },
    { value: "01853", value2: "01853", value3: "01853", value4: "01853", name: "First Hope Bank (01853)" },
    { value: "01856", value2: "01856", value3: "01856", value4: "01856", name: "First Hope Bank - DL (01856)" },
    { value: "02250", value2: "02250", value3: "02250", value4: "02250", name: "First Horizon Bank - TN (02250)" },
    { value: "68441", value2: "68441", value3: "68441", value4: "68441", name: "First Horizon Bank Business (68441)" },
    { value: "68839", value2: "68839", value3: "68839", value4: "68839", name: "First Horizon Credit Card (68839)" },
    { value: "17473", value2: "17473", value3: "17473", value4: "17473", name: "First Horizon-legacy Capital Bnk (17473)" },
    { value: "69221", value2: "69221", value3: "69221", value4: "69221", name: "First IB Business (69221)" },
    { value: "12806", value2: "12806", value3: "12806", value4: "12806", name: "First IC Bank-Retail DC (12806)" },
    { value: "68145", value2: "68145", value3: "68145", value4: "68145", name: "First Independence Bank Biz (68145)" },
    { value: "68146", value2: "68146", value3: "68146", value4: "68145", name: "First Independence Bank Biz QBDC (68146)" },
    { value: "12074", value2: "12074", value3: "12074", value4: "12074", name: "First Independence Bank NEW (12074)" },
    { value: "68214", value2: "68214", value3: "68214", value4: "12074", name: "First Independence Bank QBDC (68214)" },
    { value: "19641", value2: "19641", value3: "19641", value4: "19641", name: "First Independent Bank MN (19641)" },
    { value: "63303", value2: "63303", value3: "63303", value4: "63303", name: "First Independent Bank MN-Bus (63303)" },
    { value: "20253", value2: "20253", value3: "20253", value4: "20253", name: "First International Bank & Trust (20253)" },
    { value: "01570", value2: "01570", value3: "01570", value4: "01570", name: "First Internet Bank of Indiana (01570)" },
    { value: "55902", value2: "55902", value3: "55902", value4: "55902", name: "First Internet Bank of Indiana QB (55902)" },
    { value: "68094", value2: "68094", value3: "68094", value4: "68094", name: "First Internet Bank-DC Business (68094)" },
    { value: "00692", value2: "00692", value3: "00692", value4: "00692", name: "First Interstate Bank I-Bank (00692)" },
    { value: "11505", value2: "11505", value3: "11505", value4: "11505", name: "First Interstate Bank I-Corp (11505)" },
    { value: "07145", value2: "07145", value3: "07145", value4: "07145", name: "First Interstate Bank TBS (07145)" },
    { value: "68798", value2: "68798", value3: "68798", value4: "68798", name: "First Intl Bank & Trust (68798)" },
    { value: "65049", value2: "65049", value3: "65049", value4: "65049", name: "First Intnat'l B&T Credit Card (65049)" },
    { value: "09896", value2: "09896", value3: "09896", value4: "09896", name: "First Iowa State Bank Albia (09896)" },
    { value: "09389", value2: "09389", value3: "09389", value4: "09389", name: "First Iowa State Bank of Keo WC (09389)" },
    { value: "03799", value2: "03799", value3: "03799", value4: "03799", name: "First Kentucky Bank (03799)" },
    { value: "70142", value2: "70142", value3: "70142", value4: "70142", name: "First Keystone Comm Bank BB DC (70142)" },
    { value: "70174", value2: "70174", value3: "70174", value4: "70174", name: "First Keystone Comm Bank QK QB (70174)" },
    { value: "50391", value2: "50391", value3: "50391", value4: "50391", name: "First Keystone Community Bank (50391)" },
    { value: "56700", value2: "56700", value3: "56700", value4: "56700", name: "First Liberty Bank - OK (56700)" },
    { value: "68212", value2: "68212", value3: "68212", value4: "68212", name: "First Liberty Bank - TX (68212)" },
    { value: "68131", value2: "68131", value3: "68131", value4: "68131", name: "First Liberty Bank WCQB (68131)" },
    { value: "69438", value2: "69438", value3: "69438", value4: "69438", name: "First Lockhart Bank Bus WC (69438)" },
    { value: "63411", value2: "63411", value3: "63411", value4: "63411", name: "First Merchants Bus Web (63411)" },
    { value: "63465", value2: "63465", value3: "63465", value4: "63465", name: "First Merchants Business (63465)" },
    { value: "02703", value2: "02703", value3: "02703", value4: "02703", name: "First Metro Bank (02703)" },
    { value: "07973", value2: "07973", value3: "07973", value4: "07973", name: "First Mid Bank & Trust Business (07973)" },
    { value: "53982", value2: "53982", value3: "53982", value4: "53982", name: "First Mid Bank & Trust Retail (53982)" },
    { value: "70163", value2: "70163", value3: "70163", value4: "07973", name: "First Mid Business DC (70163)" },
    { value: "11521", value2: "11521", value3: "11521", value4: "11521", name: "First Midwest Bank of Dexter (11521)" },
    { value: "02593", value2: "02593", value3: "02593", value4: "02593", name: "First Midwest Bank of Poplar Bluff (02593)" },
    { value: "06135", value2: "06135", value3: "06135", value4: "06135", name: "First Minnetonka City Bank (06135)" },
    { value: "09453", value2: "09453", value3: "09453", value4: "09453", name: "First Missouri State Bank - Personal Banking (09453)" },
    { value: "67109", value2: "67109", value3: "67109", value4: "67109", name: "First Missouri State Bank BO (67109)" },
    { value: "67108", value2: "67108", value3: "67108", value4: "67108", name: "First Missouri State Bank BO DC (67108)" },
    { value: "67107", value2: "67107", value3: "67107", value4: "67107", name: "First Missouri State Bank RO DC (67107)" },
    { value: "09858", value2: "09858", value3: "09858", value4: "09858", name: "First Montana Bank (09858)" },
    { value: "19929", value2: "19929", value3: "19929", value4: "19929", name: "First Nat'l Bank of Broken Arrow (19929)" },
    { value: "13209", value2: "13209", value3: "13209", value4: "13209", name: "First National Bank & Trust OK (13209)" },
    { value: "16140", value2: "16140", value3: "16140", value4: "16140", name: "First National Bank & Trust-Atmore (16140)" },
    { value: "07464", value2: "07464", value3: "07464", value4: "07464", name: "First National Bank - Cloverdale (07464)" },
    { value: "14221", value2: "14221", value3: "14221", value4: "14221", name: "First National Bank - El Paso QB (14221)" },
    { value: "01068", value2: "01068", value3: "01068", value4: "01068", name: "First National Bank - Goodland (01068)" },
    { value: "16080", value2: "16080", value3: "16080", value4: "16080", name: "First National Bank - Granbury TX (16080)" },
    { value: "67560", value2: "67560", value3: "67560", value4: "67560", name: "First National Bank - Huntsville (67560)" },
    { value: "03699", value2: "03699", value3: "03699", value4: "03699", name: "First National Bank Alaska (03699)" },
    { value: "04500", value2: "04500", value3: "04500", value4: "04500", name: "First National Bank Albany (04500)" },
    { value: "15942", value2: "15942", value3: "15942", value4: "15942", name: "First National Bank Altavista (15942)" },
    { value: "09536", value2: "09536", value3: "09536", value4: "09536", name: "First National Bank Ames Business (09536)" },
    { value: "67392", value2: "67392", value3: "67392", value4: "67392", name: "First National Bank Beloit BizDC (67392)" },
    { value: "67391", value2: "67391", value3: "67391", value4: "67391", name: "First National Bank Beloit BizWC (67391)" },
    { value: "67390", value2: "67390", value3: "67390", value4: "67390", name: "First National Bank Beloit DC (67390)" },
    { value: "67389", value2: "67389", value3: "67389", value4: "67389", name: "First National Bank Beloit WC (67389)" },
    { value: "70390", value2: "70390", value3: "70390", value4: "70390", name: "First National Bank Bus CC (70390)" },
    { value: "53451", value2: "53451", value3: "53451", value4: "53451", name: "First National Bank Chisholm (53451)" },
    { value: "53454", value2: "53454", value3: "53454", value4: "53451", name: "First National Bank Chisholm DC (53454)" },
    { value: "13191", value2: "13191", value3: "13191", value4: "13191", name: "First National Bank Colorado (13191)" },
    { value: "57495", value2: "57495", value3: "57495", value4: "57495", name: "First National Bank Cooper (57495)" },
    { value: "65307", value2: "65307", value3: "65307", value4: "65307", name: "First National Bank DeRidder (65307)" },
    { value: "02139", value2: "02139", value3: "02139", value4: "02139", name: "First National Bank Decatur Co. (02139)" },
    { value: "69218", value2: "69218", value3: "69218", value4: "69218", name: "First National Bank East TX - BO (69218)" },
    { value: "14088", value2: "14088", value3: "14088", value4: "14088", name: "First National Bank Fulda MN (14088)" },
    { value: "67178", value2: "67178", value3: "67178", value4: "67178", name: "First National Bank Germantown (67178)" },
    { value: "70515", value2: "70515", value3: "70515", value4: "03166", name: "First National Bank Griffin QBDC (70515)" },
    { value: "12338", value2: "12338", value3: "12338", value4: "29775", name: "First National Bank Hereford -QBDC (12338)" },
    { value: "05383", value2: "05383", value3: "05383", value4: "05383", name: "First National Bank Kansas (05383)" },
    { value: "69673", value2: "69673", value3: "69673", value4: "69673", name: "First National Bank MI BO (69673)" },
    { value: "69675", value2: "69675", value3: "69675", value4: "69675", name: "First National Bank MI BO QBDC (69675)" },
    { value: "11892", value2: "11892", value3: "11892", value4: "11892", name: "First National Bank MI RO (11892)" },
    { value: "69674", value2: "69674", value3: "69674", value4: "69674", name: "First National Bank MI RO QBDC (69674)" },
    { value: "06877", value2: "06877", value3: "06877", value4: "06877", name: "First National Bank Minnesota - Personal Banking (06877)" },
    { value: "02154", value2: "02154", value3: "02154", value4: "02154", name: "First National Bank Mt Dora-BIB (02154)" },
    { value: "67900", value2: "67900", value3: "67900", value4: "67900", name: "First National Bank Mt Dora-DC (67900)" },
    { value: "42942", value2: "42942", value3: "42942", value4: "42942", name: "First National Bank North (42942)" },
    { value: "54165", value2: "54165", value3: "54165", value4: "54165", name: "First National Bank Northwest FL (54165)" },
    { value: "69555", value2: "69555", value3: "69555", value4: "69554", name: "First National Bank Oldham SD DC (69555)" },
    { value: "05392", value2: "05392", value3: "05392", value4: "05392", name: "First National Bank Omaha (05392)" },
    { value: "05624", value2: "05624", value3: "05624", value4: "05624", name: "First National Bank Omaha DC (05624)" },
    { value: "67239", value2: "67239", value3: "67239", value4: "67239", name: "First National Bank Pasco (67239)" },
    { value: "67237", value2: "67237", value3: "67237", value4: "67237", name: "First National Bank Pasco Bus (67237)" },
    { value: "67238", value2: "67238", value3: "67238", value4: "67238", name: "First National Bank Pasco Bus QBDC (67238)" },
    { value: "67240", value2: "67240", value3: "67240", value4: "67240", name: "First National Bank Pasco QBDC (67240)" },
    { value: "05576", value2: "05576", value3: "05576", value4: "05576", name: "First National Bank Peterstown (05576)" },
    { value: "14091", value2: "14091", value3: "14091", value4: "14091", name: "First National Bank Pierre SD (14091)" },
    { value: "69552", value2: "69552", value3: "69552", value4: "14091", name: "First National Bank Pierre SD DC (69552)" },
    { value: "02575", value2: "02575", value3: "02575", value4: "02575", name: "First National Bank Scott City (02575)" },
    { value: "19830", value2: "19830", value3: "19830", value4: "19830", name: "First National Bank Spearman (19830)" },
    { value: "55107", value2: "55107", value3: "55107", value4: "17214", name: "First National Bank Talladega - DC (55107)" },
    { value: "18973", value2: "18973", value3: "18973", value4: "18973", name: "First National Bank Texas QB (18973)" },
    { value: "09089", value2: "09089", value3: "09089", value4: "09089", name: "First National Bank Waynesboro (09089)" },
    { value: "19422", value2: "19422", value3: "19422", value4: "19422", name: "First National Bank at Paris (19422)" },
    { value: "28791", value2: "28791", value3: "28791", value4: "28791", name: "First National Bank in Cimarron (28791)" },
    { value: "50094", value2: "50094", value3: "50094", value4: "50094", name: "First National Bank in Creston (50094)" },
    { value: "62202", value2: "62202", value3: "62202", value4: "62202", name: "First National Bank in Hominy (62202)" },
    { value: "62211", value2: "62211", value3: "62211", value4: "62202", name: "First National Bank in Hominy - DC (62211)" },
    { value: "69793", value2: "69793", value3: "69793", value4: "69793", name: "First National Bank in Olney (69793)" },
    { value: "69794", value2: "69794", value3: "69794", value4: "69793", name: "First National Bank in Olney-QB (69794)" },
    { value: "32898", value2: "32898", value3: "32898", value4: "32898", name: "First National Bank in Philip (32898)" },
    { value: "19116", value2: "19116", value3: "19116", value4: "19116", name: "First National Bank of Absecon (19116)" },
    { value: "04190", value2: "04190", value3: "04190", value4: "04190", name: "First National Bank of Anson (04190)" },
    { value: "35025", value2: "35025", value3: "35025", value4: "35025", name: "First National Bank of Ballinger (35025)" },
    { value: "67405", value2: "67405", value3: "67405", value4: "67405", name: "First National Bank of Bangor (67405)" },
    { value: "01848", value2: "01848", value3: "01848", value4: "01848", name: "First National Bank of Bastrop (01848)" },
    { value: "69358", value2: "69358", value3: "69358", value4: "69358", name: "First National Bank of Bellevue (69358)" },
    { value: "67170", value2: "67170", value3: "67170", value4: "67170", name: "First National Bank of Cokato (67170)" },
    { value: "69577", value2: "69577", value3: "69577", value4: "69577", name: "First National Bank of Dennison (69577)" },
    { value: "68215", value2: "68215", value3: "68215", value4: "68215", name: "First National Bank of Dighton (68215)" },
    { value: "35580", value2: "35580", value3: "35580", value4: "35580", name: "First National Bank of Dublin (35580)" },
    { value: "07025", value2: "07025", value3: "07025", value4: "35580", name: "First National Bank of Dublin-DC (07025)" },
    { value: "00072", value2: "00072", value3: "00072", value4: "00072", name: "First National Bank of Eagle Lake (00072)" },
    { value: "55959", value2: "55959", value3: "55959", value4: "55959", name: "First National Bank of Eldorado (55959)" },
    { value: "16734", value2: "16734", value3: "16734", value4: "16734", name: "First National Bank of Elk City (16734)" },
    { value: "08117", value2: "08117", value3: "08117", value4: "16734", name: "First National Bank of Elk City DC (08117)" },
    { value: "00389", value2: "00389", value3: "00389", value4: "00389", name: "First National Bank of Elmer (00389)" },
    { value: "50964", value2: "50964", value3: "50964", value4: "50964", name: "First National Bank of Fort Smith (50964)" },
    { value: "08513", value2: "08513", value3: "08513", value4: "08513", name: "First National Bank of Gilbert, MN (08513)" },
    { value: "08516", value2: "08516", value3: "08516", value4: "08516", name: "First National Bank of Gilbert-DC (08516)" },
    { value: "09882", value2: "09882", value3: "09882", value4: "09882", name: "First National Bank of Gillette (09882)" },
    { value: "40911", value2: "40911", value3: "40911", value4: "40911", name: "First National Bank of Grayson (40911)" },
    { value: "03166", value2: "03166", value3: "03166", value4: "03166", name: "First National Bank of Griffin (03166)" },
    { value: "12537", value2: "12537", value3: "12537", value4: "12537", name: "First National Bank of Hamilton (12537)" },
    { value: "40887", value2: "40887", value3: "40887", value4: "40887", name: "First National Bank of Henning (40887)" },
    { value: "29775", value2: "29775", value3: "29775", value4: "29775", name: "First National Bank of Hereford (29775)" },
    { value: "11129", value2: "11129", value3: "11129", value4: "11129", name: "First National Bank of Huntsville (11129)" },
    { value: "07469", value2: "07469", value3: "07469", value4: "07469", name: "First National Bank of KY (07469)" },
    { value: "67102", value2: "67102", value3: "67102", value4: "07469", name: "First National Bank of KY QBDC (67102)" },
    { value: "52719", value2: "52719", value3: "52719", value4: "52719", name: "First National Bank of Litchfield (52719)" },
    { value: "15225", value2: "15225", value3: "15225", value4: "15225", name: "First National Bank of Livingston (15225)" },
    { value: "70087", value2: "70087", value3: "70087", value4: "70087", name: "First National Bank of McIntosh (70087)" },
    { value: "07128", value2: "07128", value3: "07128", value4: "07128", name: "First National Bank of Mertzon (07128)" },
    { value: "28104", value2: "28104", value3: "28104", value4: "28104", name: "First National Bank of Milaca (28104)" },
    { value: "68790", value2: "68790", value3: "68790", value4: "68790", name: "First National Bank of Moose Lake - Business (68790)" },
    { value: "06772", value2: "06772", value3: "06772", value4: "06772", name: "First National Bank of Mt Dora - CIB (06772)" },
    { value: "08023", value2: "08023", value3: "08023", value4: "08023", name: "First National Bank of Newtown (08023)" },
    { value: "19482", value2: "19482", value3: "19482", value4: "19482", name: "First National Bank of Oklahoma (19482)" },
    { value: "67673", value2: "67673", value3: "67673", value4: "67673", name: "First National Bank of Oneida (67673)" },
    { value: "03188", value2: "03188", value3: "03188", value4: "03188", name: "First National Bank of Osakis (03188)" },
    { value: "13086", value2: "13086", value3: "13086", value4: "13086", name: "First National Bank of PA (13086)" },
    { value: "69456", value2: "69456", value3: "69456", value4: "69456", name: "First National Bank of PA EWC (69456)" },
    { value: "19710", value2: "19710", value3: "19710", value4: "19710", name: "First National Bank of Pana (19710)" },
    { value: "64812", value2: "64812", value3: "64812", value4: "64812", name: "First National Bank of Paragould (64812)" },
    { value: "49995", value2: "49995", value3: "49995", value4: "49995", name: "First National Bank of Pontotoc (49995)" },
    { value: "18294", value2: "18294", value3: "18294", value4: "18294", name: "First National Bank of Port Lavaca (18294)" },
    { value: "10611", value2: "10611", value3: "10611", value4: "10611", name: "First National Bank of Pulaski (10611)" },
    { value: "19425", value2: "19425", value3: "19425", value4: "19425", name: "First National Bank of River Falls (19425)" },
    { value: "15579", value2: "15579", value3: "15579", value4: "15579", name: "First National Bank of SPI (15579)" },
    { value: "52725", value2: "52725", value3: "52725", value4: "52725", name: "First National Bank of Shiner (52725)" },
    { value: "00426", value2: "00426", value3: "00426", value4: "00426", name: "First National Bank of Sonora (00426)" },
    { value: "53841", value2: "53841", value3: "53841", value4: "53841", name: "First National Bank of Wauchula (53841)" },
    { value: "50250", value2: "50250", value3: "50250", value4: "50250", name: "First National Bank of Winnsboro (50250)" },
    { value: "19857", value2: "19857", value3: "19857", value4: "19857", name: "First National Bank, Bagley, MN (19857)" },
    { value: "17094", value2: "17094", value3: "17094", value4: "17094", name: "First National Bank, Cortez (17094)" },
    { value: "11095", value2: "11095", value3: "11095", value4: "11095", name: "First National Bank, ME (11095)" },
    { value: "06899", value2: "06899", value3: "06899", value4: "06899", name: "First National Bank, MN (06899)" },
    { value: "06902", value2: "06902", value3: "06902", value4: "06902", name: "First National Bank, MN DC (06902)" },
    { value: "69554", value2: "69554", value3: "69554", value4: "69554", name: "First National Bank, Oldham, SD (69554)" },
    { value: "13740", value2: "13740", value3: "13740", value4: "13740", name: "First National Bank, Trinity (13740)" },
    { value: "67523", value2: "67523", value3: "67523", value4: "67523", name: "First National Bank-OK (67523)" },
    { value: "67205", value2: "67205", value3: "67205", value4: "67205", name: "First National Bank-Steeleville (67205)" },
    { value: "00499", value2: "00499", value3: "00499", value4: "00499", name: "First National Bk Wichita Falls (00499)" },
    { value: "61824", value2: "61824", value3: "61824", value4: "61824", name: "First National Bk and Trust (61824)" },
    { value: "01167", value2: "01167", value3: "01167", value4: "01167", name: "First National Bk in Alamogordo (01167)" },
    { value: "07354", value2: "07354", value3: "07354", value4: "07354", name: "First National Bk of Hutchinson (07354)" },
    { value: "50622", value2: "50622", value3: "50622", value4: "50622", name: "First National Bk of Jeanerette (50622)" },
    { value: "57423", value2: "57423", value3: "57423", value4: "57423", name: "First National Community Bank - GA (57423)" },
    { value: "01428", value2: "01428", value3: "01428", value4: "01428", name: "First National Community Bank-WI (01428)" },
    { value: "68445", value2: "68445", value3: "68445", value4: "57423", name: "First National Community Bk-DC (68445)" },
    { value: "69359", value2: "69359", value3: "69359", value4: "69359", name: "First Natl Bank Bellevue QBDC (69359)" },
    { value: "68788", value2: "68788", value3: "68788", value4: "68788", name: "First Natl Bank Moose Lake (68788)" },
    { value: "68791", value2: "68791", value3: "68791", value4: "68791", name: "First Natl Bank Moose Lake BusDC (68791)" },
    { value: "68789", value2: "68789", value3: "68789", value4: "68789", name: "First Natl Bank Moose Lake DC (68789)" },
    { value: "03215", value2: "03215", value3: "03215", value4: "03215", name: "First Natl Bank of Burleson-QB (03215)" },
    { value: "56163", value2: "56163", value3: "56163", value4: "56163", name: "First Natl Bank of Central TX-DC (56163)" },
    { value: "12708", value2: "12708", value3: "12708", value4: "12708", name: "First Natl Bank of Central Tx (12708)" },
    { value: "00366", value2: "00366", value3: "00366", value4: "00366", name: "First Natl Bank of Lawrence Co. (00366)" },
    { value: "08032", value2: "08032", value3: "08032", value4: "08032", name: "First Natl Bank of Monterey QB (08032)" },
    { value: "05909", value2: "05909", value3: "05909", value4: "05909", name: "First NaturalState Bank (05909)" },
    { value: "50217", value2: "50217", value3: "50217", value4: "50217", name: "First Nebraska Bank (50217)" },
    { value: "57561", value2: "57561", value3: "57561", value4: "57561", name: "First Neighbor Bank, NA - IL (57561)" },
    { value: "51402", value2: "51402", value3: "51402", value4: "51402", name: "First New Mexico Bank Las Cruces (51402)" },
    { value: "67712", value2: "67712", value3: "67712", value4: "67712", name: "First New Mexico Bank Silver -DC (67712)" },
    { value: "51273", value2: "51273", value3: "51273", value4: "51273", name: "First New Mexico Bank, Silver City (51273)" },
    { value: "05288", value2: "05288", value3: "05288", value4: "05288", name: "First Northern Bank (05288)" },
    { value: "05660", value2: "05660", value3: "05660", value4: "05660", name: "First Northern Bank PA-WC (05660)" },
    { value: "03980", value2: "03980", value3: "03980", value4: "03980", name: "First Northern Bank of Wyoming (03980)" },
    { value: "06917", value2: "06917", value3: "06917", value4: "06917", name: "First Northern Bank-PA (06917)" },
    { value: "16485", value2: "16485", value3: "16485", value4: "16485", name: "First Northern CU (16485)" },
    { value: "18088", value2: "18088", value3: "18088", value4: "18088", name: "First Northern CU - DC (18088)" },
    { value: "11451", value2: "11451", value3: "11451", value4: "11451", name: "First Ntl Bank in Sioux Falls (11451)" },
    { value: "70493", value2: "70493", value3: "70493", value4: "70493", name: "First Ntnl Bank Long Island DC (70493)" },
    { value: "68521", value2: "68521", value3: "68521", value4: "68521", name: "First Ntnl Long Island (68521)" },
    { value: "13311", value2: "13311", value3: "13311", value4: "13311", name: "First Ntnl Long Island Com (13311)" },
    { value: "70530", value2: "70530", value3: "70530", value4: "17749", name: "First Ntnl Long Island QBDC (70530)" },
    { value: "20457", value2: "20457", value3: "20457", value4: "20457", name: "First Oklahoma Bank (20457)" },
    { value: "70094", value2: "70094", value3: "70094", value4: "70094", name: "First Oklahoma Bank-TM (70094)" },
    { value: "05173", value2: "05173", value3: "05173", value4: "05173", name: "First Pacific Bank (05173)" },
    { value: "63888", value2: "63888", value3: "63888", value4: "63888", name: "First Palmetto Bank - Business (63888)" },
    { value: "67204", value2: "67204", value3: "67204", value4: "67204", name: "First Peoples Bank GA - D.C. (67204)" },
    { value: "50697", value2: "50697", value3: "50697", value4: "50697", name: "First Peoples Bank Georgia (50697)" },
    { value: "69095", value2: "69095", value3: "69095", value4: "69095", name: "First Pioneers FCU credit card (69095)" },
    { value: "53997", value2: "53997", value3: "53997", value4: "53997", name: "First Port City Bank (53997)" },
    { value: "20322", value2: "20322", value3: "20322", value4: "20322", name: "First Premier Bank (20322)" },
    { value: "70365", value2: "70365", value3: "70365", value4: "70365", name: "First Priority Credit Union DC (70365)" },
    { value: "20442", value2: "20442", value3: "20442", value4: "20442", name: "First Priority Credit Union WC (20442)" },
    { value: "35076", value2: "35076", value3: "35076", value4: "35076", name: "First Pryority Bank (35076)" },
    { value: "70028", value2: "70028", value3: "70028", value4: "70028", name: "First Pryority Bank - DC (70028)" },
    { value: "67962", value2: "67962", value3: "67962", value4: "67962", name: "First Reliance Bank (67962)" },
    { value: "67963", value2: "67963", value3: "67963", value4: "67963", name: "First Reliance Bank Direct Connect (67963)" },
    { value: "16462", value2: "16462", value3: "16462", value4: "16462", name: "First Republic - Digital Banking (16462)" },
    { value: "17902", value2: "17902", value3: "17902", value4: "17902", name: "First Republic Bank (17902)" },
    { value: "16423", value2: "16423", value3: "16423", value4: "16423", name: "First Republic Bank - Corporate DC (16423)" },
    { value: "51237", value2: "51237", value3: "51237", value4: "51237", name: "First Republic Bank - Corporate WC (51237)" },
    { value: "17050", value2: "17050", value3: "17050", value4: "16462", name: "First Republic Bank - QB DC (17050)" },
    { value: "07004", value2: "07004", value3: "07004", value4: "17902", name: "First Republic Bank DCv2 (07004)" },
    { value: "00579", value2: "00579", value3: "00579", value4: "00579", name: "First Resource Bank (00579)" },
    { value: "69844", value2: "69844", value3: "69844", value4: "69844", name: "First Resource Bank Bus QBDC (69844)" },
    { value: "12416", value2: "12416", value3: "12416", value4: "12416", name: "First Resource Bank Business (12416)" },
    { value: "14094", value2: "14094", value3: "14094", value4: "14094", name: "First Savings Bank Beresford SD (14094)" },
    { value: "67403", value2: "67403", value3: "67403", value4: "67403", name: "First Savings Bank Bus (67403)" },
    { value: "67404", value2: "67404", value3: "67404", value4: "67404", name: "First Savings Bank Bus DC (67404)" },
    { value: "11361", value2: "11361", value3: "11361", value4: "11361", name: "First Savings Bank IN (11361)" },
    { value: "67402", value2: "67402", value3: "67402", value4: "67402", name: "First Savings Bank IN QBDC (67402)" },
    { value: "69553", value2: "69553", value3: "69553", value4: "14094", name: "First Savings BankBeresford SDDC (69553)" },
    { value: "64941", value2: "64941", value3: "64941", value4: "64941", name: "First Seacoast Bank - Bus (64941)" },
    { value: "08678", value2: "08678", value3: "08678", value4: "08678", name: "First Seacoast Bank Bus Direct (08678)" },
    { value: "07364", value2: "07364", value3: "07364", value4: "07364", name: "First Secure Bank & Trust (07364)" },
    { value: "67127", value2: "67127", value3: "67127", value4: "67127", name: "First Secure Community Bank (67127)" },
    { value: "67462", value2: "67462", value3: "67462", value4: "67127", name: "First Secure Community Bank DC (67462)" },
    { value: "13230", value2: "13230", value3: "13230", value4: "13230", name: "First Security Bank & Trust (13230)" },
    { value: "03239", value2: "03239", value3: "03239", value4: "03239", name: "First Security Bank & Trust-Bus (03239)" },
    { value: "62610", value2: "62610", value3: "62610", value4: "62610", name: "First Security Bank - Beaver (62610)" },
    { value: "30075", value2: "30075", value3: "30075", value4: "30075", name: "First Security Bank - Canby. (30075)" },
    { value: "67952", value2: "67952", value3: "67952", value4: "67952", name: "First Security Bank - Hendricks (67952)" },
    { value: "28083", value2: "28083", value3: "28083", value4: "28083", name: "First Security Bank - MO (28083)" },
    { value: "67954", value2: "67954", value3: "67954", value4: "67954", name: "First Security Bank - Mapleton (67954)" },
    { value: "67939", value2: "67939", value3: "67939", value4: "67939", name: "First Security Bank - West (67939)" },
    { value: "67115", value2: "67115", value3: "67115", value4: "62610", name: "First Security Bank Beaver QBDC (67115)" },
    { value: "67856", value2: "67856", value3: "67856", value4: "67856", name: "First Security Bank Canby QB (67856)" },
    { value: "67951", value2: "67951", value3: "67951", value4: "67951", name: "First Security Bank Hendricks QB (67951)" },
    { value: "67955", value2: "67955", value3: "67955", value4: "67955", name: "First Security Bank Mapleton QB (67955)" },
    { value: "32274", value2: "32274", value3: "32274", value4: "32274", name: "First Security Bank Overbroook (32274)" },
    { value: "67855", value2: "67855", value3: "67855", value4: "67855", name: "First Security Bank Sleepy Eye (67855)" },
    { value: "33987", value2: "33987", value3: "33987", value4: "33987", name: "First Security Bank Sleepy Eye QB (33987)" },
    { value: "67938", value2: "67938", value3: "67938", value4: "67938", name: "First Security Bank West MN (67938)" },
    { value: "32496", value2: "32496", value3: "32496", value4: "32496", name: "First Security Bank and Trust Co (32496)" },
    { value: "12765", value2: "12765", value3: "12765", value4: "12765", name: "First Security Bank of Nevada (12765)" },
    { value: "03574", value2: "03574", value3: "03574", value4: "03574", name: "First Security Bank, AR (03574)" },
    { value: "07458", value2: "07458", value3: "07458", value4: "07458", name: "First Security Bank, Batesville (07458)" },
    { value: "51252", value2: "51252", value3: "51252", value4: "51252", name: "First Security Bank, Mackinaw (51252)" },
    { value: "51495", value2: "51495", value3: "51495", value4: "03574", name: "First Security Bank, Searcy AR-DC (51495)" },
    { value: "68546", value2: "68546", value3: "68546", value4: "68546", name: "First Security Sleepy Eye DC Bus (68546)" },
    { value: "14871", value2: "14871", value3: "14871", value4: "14871", name: "First Security State Bank (14871)" },
    { value: "09731", value2: "09731", value3: "09731", value4: "09731", name: "First Security Trust & Savings DC (09731)" },
    { value: "10535", value2: "10535", value3: "10535", value4: "10535", name: "First Service Bank (10535)" },
    { value: "68561", value2: "68561", value3: "68561", value4: "68561", name: "First Service Bank - DC (68561)" },
    { value: "10225", value2: "10225", value3: "10225", value4: "10225", name: "First Service Credit Union (10225)" },
    { value: "11694", value2: "11694", value3: "11694", value4: "11694", name: "First Sound Bank (11694)" },
    { value: "12778", value2: "12778", value3: "12778", value4: "12778", name: "First Source FCU (12778)" },
    { value: "58833", value2: "58833", value3: "58833", value4: "58833", name: "First South Financial Credit Union (58833)" },
    { value: "57486", value2: "57486", value3: "57486", value4: "57486", name: "First Southeast Bank (57486)" },
    { value: "20115", value2: "20115", value3: "20115", value4: "20115", name: "First Southern Bank - Florence (20115)" },
    { value: "50100", value2: "50100", value3: "50100", value4: "50100", name: "First Southern Bank - IL (50100)" },
    { value: "70366", value2: "70366", value3: "70366", value4: "70366", name: "First Southern Bank AL QBDC (70366)" },
    { value: "59319", value2: "59319", value3: "59319", value4: "59319", name: "First Southern Bank BO (59319)" },
    { value: "67970", value2: "67970", value3: "67970", value4: "67970", name: "First Southern Bank BO - DC (67970)" },
    { value: "59316", value2: "59316", value3: "59316", value4: "59316", name: "First Southern Bank Consumer (59316)" },
    { value: "67969", value2: "67969", value3: "67969", value4: "67969", name: "First Southern Bank RO DC (67969)" },
    { value: "62652", value2: "62652", value3: "62652", value4: "62652", name: "First Southern National Bank KY (62652)" },
    { value: "67101", value2: "67101", value3: "67101", value4: "62652", name: "First Southern National BankQBDC (67101)" },
    { value: "27708", value2: "27708", value3: "27708", value4: "27708", name: "First Southern State Bank (27708)" },
    { value: "03197", value2: "03197", value3: "03197", value4: "27708", name: "First Southern State Bank-DC (03197)" },
    { value: "16740", value2: "16740", value3: "16740", value4: "16740", name: "First Southwest Bank (16740)" },
    { value: "57288", value2: "57288", value3: "57288", value4: "57288", name: "First State Bank (57288)" },
    { value: "04902", value2: "04902", value3: "04902", value4: "04902", name: "First State Bank & Trust Co. (04902)" },
    { value: "12725", value2: "12725", value3: "12725", value4: "04902", name: "First State Bank & Trust Co. DC (12725)" },
    { value: "13824", value2: "13824", value3: "13824", value4: "13824", name: "First State Bank & Trust, KS (13824)" },
    { value: "67295", value2: "67295", value3: "67295", value4: "67295", name: "First State Bank - Farnam (67295)" },
    { value: "57960", value2: "57960", value3: "57960", value4: "57960", name: "First State Bank - IA (57960)" },
    { value: "03398", value2: "03398", value3: "03398", value4: "03398", name: "First State Bank - KY (03398)" },
    { value: "02937", value2: "02937", value3: "02937", value4: "02937", name: "First State Bank - Michigan Personal (02937)" },
    { value: "02138", value2: "02138", value3: "02138", value4: "02138", name: "First State Bank - Middlebury (02138)" },
    { value: "03245", value2: "03245", value3: "03245", value4: "03245", name: "First State Bank - Rosemount (03245)" },
    { value: "12005", value2: "12005", value3: "12005", value4: "12005", name: "First State Bank - Southwest (12005)" },
    { value: "63246", value2: "63246", value3: "63246", value4: "63246", name: "First State Bank - Sumner (63246)" },
    { value: "01883", value2: "01883", value3: "01883", value4: "01883", name: "First State Bank - TX (01883)" },
    { value: "12410", value2: "12410", value3: "12410", value4: "12410", name: "First State Bank - Tahlequah (12410)" },
    { value: "70124", value2: "70124", value3: "70124", value4: "70124", name: "First State Bank Ben Wheele QBDC (70124)" },
    { value: "09272", value2: "09272", value3: "09272", value4: "09272", name: "First State Bank Buxton (09272)" },
    { value: "16959", value2: "16959", value3: "16959", value4: "16959", name: "First State Bank Cando (16959)" },
    { value: "68904", value2: "68904", value3: "68904", value4: "68904", name: "First State Bank Clute Cash Mgmt (68904)" },
    { value: "68906", value2: "68906", value3: "68906", value4: "68906", name: "First State Bank Clute Cash QBDC (68906)" },
    { value: "52479", value2: "52479", value3: "52479", value4: "52479", name: "First State Bank Clute Online (52479)" },
    { value: "02495", value2: "02495", value3: "02495", value4: "02495", name: "First State Bank Florida Keys (02495)" },
    { value: "56643", value2: "56643", value3: "56643", value4: "56643", name: "First State Bank Graham (56643)" },
    { value: "69912", value2: "69912", value3: "69912", value4: "56643", name: "First State Bank Graham QBDC (69912)" },
    { value: "40971", value2: "40971", value3: "40971", value4: "40971", name: "First State Bank Junction,TX (40971)" },
    { value: "06419", value2: "06419", value3: "06419", value4: "06419", name: "First State Bank Livingston (06419)" },
    { value: "12787", value2: "12787", value3: "12787", value4: "12787", name: "First State Bank Lonoke (12787)" },
    { value: "05404", value2: "05404", value3: "05404", value4: "05404", name: "First State Bank Loomis (05404)" },
    { value: "67767", value2: "67767", value3: "67767", value4: "67767", name: "First State Bank Lynnville (67767)" },
    { value: "67769", value2: "67769", value3: "67769", value4: "67769", name: "First State Bank Lynnville BIZ (67769)" },
    { value: "67768", value2: "67768", value3: "67768", value4: "67768", name: "First State Bank Lynnville QBDC (67768)" },
    { value: "56115", value2: "56115", value3: "56115", value4: "56115", name: "First State Bank Nebraska (56115)" },
    { value: "56118", value2: "56118", value3: "56118", value4: "56118", name: "First State Bank Nebraska Business (56118)" },
    { value: "42132", value2: "42132", value3: "42132", value4: "42132", name: "First State Bank Of Forrest (42132)" },
    { value: "06155", value2: "06155", value3: "06155", value4: "06155", name: "First State Bank Shallowater (06155)" },
    { value: "06203", value2: "06203", value3: "06203", value4: "06203", name: "First State Bank Shannon-Polo (06203)" },
    { value: "15126", value2: "15126", value3: "15126", value4: "15126", name: "First State Bank Socorro (15126)" },
    { value: "69602", value2: "69602", value3: "69602", value4: "69602", name: "First State Bank St. Charles DC (69602)" },
    { value: "30888", value2: "30888", value3: "30888", value4: "30888", name: "First State Bank Wrens, GA (30888)" },
    { value: "08815", value2: "08815", value3: "08815", value4: "08815", name: "First State Bank and Trust - IL (08815)" },
    { value: "01403", value2: "01403", value3: "01403", value4: "01403", name: "First State Bank and Trust MN (01403)" },
    { value: "50586", value2: "50586", value3: "50586", value4: "50586", name: "First State Bank of Abernathy (50586)" },
    { value: "18417", value2: "18417", value3: "18417", value4: "18417", name: "First State Bank of Bedias (18417)" },
    { value: "08348", value2: "08348", value3: "08348", value4: "08348", name: "First State Bank of Beecher City (08348)" },
    { value: "70123", value2: "70123", value3: "70123", value4: "70123", name: "First State Bank of Ben Wheeler (70123)" },
    { value: "08447", value2: "08447", value3: "08447", value4: "08447", name: "First State Bank of Bigfork (08447)" },
    { value: "07589", value2: "07589", value3: "07589", value4: "07589", name: "First State Bank of Blakely (07589)" },
    { value: "07592", value2: "07592", value3: "07592", value4: "07589", name: "First State Bank of Blakely WC (07592)" },
    { value: "13194", value2: "13194", value3: "13194", value4: "13194", name: "First State Bank of Burnet (13194)" },
    { value: "50055", value2: "50055", value3: "50055", value4: "50055", name: "First State Bank of De Kalb County (50055)" },
    { value: "69238", value2: "69238", value3: "69238", value4: "42132", name: "First State Bank of Forrest QBDC (69238)" },
    { value: "56403", value2: "56403", value3: "56403", value4: "56403", name: "First State Bank of Golva (56403)" },
    { value: "68490", value2: "68490", value3: "68490", value4: "68490", name: "First State Bank of Le Center (68490)" },
    { value: "70045", value2: "70045", value3: "70045", value4: "68490", name: "First State Bank of Le Center-DC (70045)" },
    { value: "08578", value2: "08578", value3: "08578", value4: "08578", name: "First State Bank of Porter (08578)" },
    { value: "49809", value2: "49809", value3: "49809", value4: "49809", name: "First State Bank of St. Charles (49809)" },
    { value: "70026", value2: "70026", value3: "70026", value4: "70026", name: "First State Bank of WY (70026)" },
    { value: "70027", value2: "70027", value3: "70027", value4: "70026", name: "First State Bank of WY-DC (70027)" },
    { value: "18736", value2: "18736", value3: "18736", value4: "18736", name: "First State Bank of Wyoming (18736)" },
    { value: "68810", value2: "68810", value3: "68810", value4: "68810", name: "First State Bank of Wyoming QBDC (68810)" },
    { value: "67779", value2: "67779", value3: "67779", value4: "67779", name: "First State Bank of the South (67779)" },
    { value: "16323", value2: "16323", value3: "16323", value4: "16323", name: "First State Bank, Athens (16323)" },
    { value: "64803", value2: "64803", value3: "64803", value4: "64803", name: "First State Bank, IA (64803)" },
    { value: "64806", value2: "64806", value3: "64806", value4: "64803", name: "First State Bank, IA DC (64806)" },
    { value: "15000", value2: "15000", value3: "15000", value4: "15000", name: "First State Bank, Louise (15000)" },
    { value: "04874", value2: "04874", value3: "04874", value4: "04874", name: "First State Bank, Louise -QB (04874)" },
    { value: "13867", value2: "13867", value3: "13867", value4: "13867", name: "First State Bank, Russellville QB (13867)" },
    { value: "68023", value2: "68023", value3: "68023", value4: "68023", name: "First State Bank-Buxton QBDC (68023)" },
    { value: "11645", value2: "11645", value3: "11645", value4: "11645", name: "First State Bank-GainesvilleTX (11645)" },
    { value: "67627", value2: "67627", value3: "67627", value4: "67627", name: "First State Bank-Mendota IL BO (67627)" },
    { value: "53964", value2: "53964", value3: "53964", value4: "53964", name: "First State Bank-Mendota IL RO (53964)" },
    { value: "06641", value2: "06641", value3: "06641", value4: "06641", name: "First State Bank-Michigan Bus DC (06641)" },
    { value: "14787", value2: "14787", value3: "14787", value4: "14787", name: "First State Bank-Stratford (14787)" },
    { value: "01898", value2: "01898", value3: "01898", value4: "01898", name: "First State Bank-Valliant (01898)" },
    { value: "55626", value2: "55626", value3: "55626", value4: "55626", name: "First State Bank-Winchester, OH (55626)" },
    { value: "67296", value2: "67296", value3: "67296", value4: "67295", name: "First State Bk - Farnam (67296)" },
    { value: "68905", value2: "68905", value3: "68905", value4: "68905", name: "First State Bk Clute Bus QBDC (68905)" },
    { value: "68973", value2: "68973", value3: "68973", value4: "68973", name: "First State Commercial DC (68973)" },
    { value: "68972", value2: "68972", value3: "68972", value4: "68972", name: "First State Commercial WC (68972)" },
    { value: "23496", value2: "23496", value3: "23496", value4: "23496", name: "First State Community Bank (23496)" },
    { value: "06098", value2: "06098", value3: "06098", value4: "23496", name: "First State Community Bank-DC (06098)" },
    { value: "12312", value2: "12312", value3: "12312", value4: "12312", name: "First Texas Bank (12312)" },
    { value: "12774", value2: "12774", value3: "12774", value4: "12774", name: "First Texas Bank-Killeen (12774)" },
    { value: "70132", value2: "70132", value3: "70132", value4: "70132", name: "First Texas National Bank (70132)" },
    { value: "70133", value2: "70133", value3: "70133", value4: "70132", name: "First Texas National Bank QBDC (70133)" },
    { value: "12794", value2: "12794", value3: "12794", value4: "12794", name: "First Texoma National Bank - BO (12794)" },
    { value: "09507", value2: "09507", value3: "09507", value4: "09507", name: "First Texoma National Bank - RO (09507)" },
    { value: "69301", value2: "69301", value3: "69301", value4: "69301", name: "First Trust & Savings Bk IA QBDC (69301)" },
    { value: "08717", value2: "08717", value3: "08717", value4: "08717", name: "First Trust Bank of Illinos (08717)" },
    { value: "50646", value2: "50646", value3: "50646", value4: "50646", name: "First Trust Credit Union (50646)" },
    { value: "18612", value2: "18612", value3: "18612", value4: "18612", name: "First Trust and Savings Bank (18612)" },
    { value: "26196", value2: "26196", value3: "26196", value4: "26196", name: "First Trust and Savings Bank IA (26196)" },
    { value: "00374", value2: "00374", value3: "00374", value4: "00374", name: "First US Bank (00374)" },
    { value: "00377", value2: "00377", value3: "00377", value4: "00377", name: "First US Bank - DL - NEW (00377)" },
    { value: "68113", value2: "68113", value3: "68113", value4: "68113", name: "First US Bank-CBA (68113)" },
    { value: "68114", value2: "68114", value3: "68114", value4: "68113", name: "First US Bank-CBA-DC (68114)" },
    { value: "11100", value2: "11100", value3: "11100", value4: "11100", name: "First US Community Credit Union (11100)" },
    { value: "17665", value2: "17665", value3: "17665", value4: "17665", name: "First United Bank & Trust (17665)" },
    { value: "17671", value2: "17671", value3: "17671", value4: "17665", name: "First United Bank & Trust QB (17671)" },
    { value: "15795", value2: "15795", value3: "15795", value4: "15795", name: "First United Bank & Trust-MD WV (15795)" },
    { value: "67299", value2: "67299", value3: "67299", value4: "67299", name: "First United Bank And Trust KY (67299)" },
    { value: "69816", value2: "69816", value3: "69816", value4: "69816", name: "First United Bank ND - Business (69816)" },
    { value: "16032", value2: "16032", value3: "16032", value4: "16032", name: "First United Bank North Dakota (16032)" },
    { value: "05723", value2: "05723", value3: "05723", value4: "05723", name: "First United Bank TX (05723)" },
    { value: "68635", value2: "68635", value3: "68635", value4: "05723", name: "First United Bank TX QBDC (68635)" },
    { value: "09488", value2: "09488", value3: "09488", value4: "09488", name: "First United Credit Union (09488)" },
    { value: "63912", value2: "63912", value3: "63912", value4: "63912", name: "First United National Bank (63912)" },
    { value: "21609", value2: "21609", value3: "21609", value4: "21609", name: "First Utah Bank (21609)" },
    { value: "05492", value2: "05492", value3: "05492", value4: "05492", name: "First Utah Bank Business (05492)" },
    { value: "49686", value2: "49686", value3: "49686", value4: "49686", name: "First Vision Bank of Tennessee (49686)" },
    { value: "68851", value2: "68851", value3: "68851", value4: "68851", name: "First Vision Bank of TennesseeDC (68851)" },
    { value: "05732", value2: "05732", value3: "05732", value4: "05732", name: "First Volunteer Bank-DL (05732)" },
    { value: "51057", value2: "51057", value3: "51057", value4: "51057", name: "First Western Bank & Trust (51057)" },
    { value: "00876", value2: "00876", value3: "00876", value4: "00876", name: "First Western Bank - AR (00876)" },
    { value: "67821", value2: "67821", value3: "67821", value4: "00876", name: "First Western Bank-AR (67821)" },
    { value: "13485", value2: "13485", value3: "13485", value4: "13485", name: "First Western Trust Bank - BIB (13485)" },
    { value: "12260", value2: "12260", value3: "12260", value4: "12260", name: "First Western Trust Bank-DC (12260)" },
    { value: "03048", value2: "03048", value3: "03048", value4: "03048", name: "First Westroads Bank (03048)" },
    { value: "11232", value2: "11232", value3: "11232", value4: "11232", name: "First-Lockhart National Bank (11232)" },
    { value: "02429", value2: "02429", value3: "02429", value4: "02429", name: "First1Bank (02429)" },
    { value: "20424", value2: "20424", value3: "20424", value4: "20424", name: "FirstBank - OK (20424)" },
    { value: "09008", value2: "09008", value3: "09008", value4: "09008", name: "FirstBank Business Banking (09008)" },
    { value: "09011", value2: "09011", value3: "09011", value4: "09008", name: "FirstBank Business Banking - DC (09011)" },
    { value: "63582", value2: "63582", value3: "63582", value4: "63582", name: "FirstBank Florida - QB (63582)" },
    { value: "67057", value2: "67057", value3: "67057", value4: "67057", name: "FirstBank Personal Banking (67057)" },
    { value: "12684", value2: "12684", value3: "12684", value4: "12684", name: "FirstBank Southwest (12684)" },
    { value: "69814", value2: "69814", value3: "69814", value4: "69814", name: "FirstBank Southwest Bus (69814)" },
    { value: "13885", value2: "13885", value3: "13885", value4: "13885", name: "FirstBank Texas (13885)" },
    { value: "10781", value2: "10781", value3: "10781", value4: "10781", name: "FirstBank of Colorado (10781)" },
    { value: "14554", value2: "14554", value3: "14554", value4: "14554", name: "FirstBank of Colorado - ICM (14554)" },
    { value: "07667", value2: "07667", value3: "07667", value4: "07667", name: "FirstBank of Nebraska (07667)" },
    { value: "07715", value2: "07715", value3: "07715", value4: "13885", name: "FirstBank,Texas-QB (07715)" },
    { value: "69372", value2: "69372", value3: "69372", value4: "69372", name: "FirstCNB Consumer DC (69372)" },
    { value: "65223", value2: "65223", value3: "65223", value4: "65223", name: "FirstLight FCU (65223)" },
    { value: "54405", value2: "54405", value3: "54405", value4: "54405", name: "FirstLight FCU - QB (54405)" },
    { value: "07724", value2: "07724", value3: "07724", value4: "07724", name: "FirstOak Bank (07724)" },
    { value: "13833", value2: "13833", value3: "13833", value4: "13833", name: "Firstar Bank (13833)" },
    { value: "67162", value2: "67162", value3: "67162", value4: "67162", name: "Firstbank PR - BeB Web Connect (67162)" },
    { value: "11673", value2: "11673", value3: "11673", value4: "11673", name: "Firstmark CU (11673)" },
    { value: "18325", value2: "18325", value3: "18325", value4: "18325", name: "Firstrust Bank Online Banking (18325)" },
    { value: "18328", value2: "18328", value3: "18328", value4: "18325", name: "Firstrust Bank WC (18328)" },
    { value: "06328", value2: "06328", value3: "06328", value4: "06328", name: "Fiscal Credit Union - QB for IB (06328)" },
    { value: "19620", value2: "19620", value3: "19620", value4: "19620", name: "Fitzsimons (19620)" },
    { value: "62835", value2: "62835", value3: "62835", value4: "19620", name: "Fitzsimons Credit Union - QB (62835)" },
    { value: "68638", value2: "68638", value3: "68638", value4: "68638", name: "Five County Credit Union (68638)" },
    { value: "24846", value2: "24846", value3: "24846", value4: "24846", name: "Five Points Bank (24846)" },
    { value: "24852", value2: "24852", value3: "24852", value4: "24852", name: "Five Points Bank  Bus. - Hastings (24852)" },
    { value: "24849", value2: "24849", value3: "24849", value4: "24849", name: "Five Points Bank - Hastings (24849)" },
    { value: "23772", value2: "23772", value3: "23772", value4: "23772", name: "Five Points Bank Business (23772)" },
    { value: "01537", value2: "01537", value3: "01537", value4: "01537", name: "Five Star Bank (01537)" },
    { value: "67228", value2: "67228", value3: "67228", value4: "01537", name: "Five Star Bank CA - QB DC (67228)" },
    { value: "55884", value2: "55884", value3: "55884", value4: "55884", name: "Five Star Credit Union (55884)" },
    { value: "01096", value2: "01096", value3: "01096", value4: "01096", name: "Five-Star Bank-NY (01096)" },
    { value: "12281", value2: "12281", value3: "12281", value4: "12281", name: "Flagler Bank Business (12281)" },
    { value: "09935", value2: "09935", value3: "09935", value4: "09935", name: "Flagler Business DC (09935)" },
    { value: "09789", value2: "09789", value3: "09789", value4: "09789", name: "Flagship Bank (09789)" },
    { value: "19026", value2: "19026", value3: "19026", value4: "19026", name: "Flagship Bank Minnesota (19026)" },
    { value: "68626", value2: "68626", value3: "68626", value4: "68626", name: "Flagship Bank QBDC (68626)" },
    { value: "17890", value2: "17890", value3: "17890", value4: "17890", name: "Flagstar (17890)" },
    { value: "63507", value2: "63507", value3: "63507", value4: "63507", name: "Flagstar Bank (63507)" },
    { value: "63510", value2: "63510", value3: "63510", value4: "63507", name: "Flagstar Bank - QB (63510)" },
    { value: "52059", value2: "52059", value3: "52059", value4: "52059", name: "Flagstar Business Online Banking (52059)" },
    { value: "54615", value2: "54615", value3: "54615", value4: "54615", name: "Flagstar Commercial & Private Bank (54615)" },
    { value: "17893", value2: "17893", value3: "17893", value4: "17893", name: "Flagstar WebConnect (17893)" },
    { value: "51546", value2: "51546", value3: "51546", value4: "51546", name: "Flatirons Bank CO (51546)" },
    { value: "19506", value2: "19506", value3: "19506", value4: "19506", name: "Flatwater Bank (19506)" },
    { value: "03164", value2: "03164", value3: "03164", value4: "03164", name: "Fleetwood Bank (03164)" },
    { value: "03158", value2: "03158", value3: "03158", value4: "03164", name: "Fleetwood Bank - QB WC (03158)" },
    { value: "02345", value2: "02345", value3: "02345", value4: "02345", name: "Flint Community Bank (02345)" },
    { value: "02348", value2: "02348", value3: "02348", value4: "02348", name: "Flint Community Bank - DL (02348)" },
    { value: "56427", value2: "56427", value3: "56427", value4: "56427", name: "Flora Bank & Trust (56427)" },
    { value: "05156", value2: "05156", value3: "05156", value4: "05156", name: "Florence Bank (05156)" },
    { value: "63018", value2: "63018", value3: "63018", value4: "63018", name: "Florence Bank - BUS (63018)" },
    { value: "00852", value2: "00852", value3: "00852", value4: "00852", name: "Florida Capital Bank NA (00852)" },
    { value: "15637", value2: "15637", value3: "15637", value4: "15637", name: "Florida Credit Union (15637)" },
    { value: "11984", value2: "11984", value3: "11984", value4: "11984", name: "Florida State University CU (11984)" },
    { value: "67303", value2: "67303", value3: "67303", value4: "67303", name: "Florida West Coast CU (67303)" },
    { value: "67304", value2: "67304", value3: "67304", value4: "67304", name: "Florida West Coast CU QBWC (67304)" },
    { value: "12550", value2: "12550", value3: "12550", value4: "12550", name: "FloridaCentral CU (12550)" },
    { value: "15027", value2: "15027", value3: "15027", value4: "15027", name: "Flushing Financial (15027)" },
    { value: "07847", value2: "07847", value3: "07847", value4: "07847", name: "Fncb Bank (07847)" },
    { value: "51261", value2: "51261", value3: "51261", value4: "51261", name: "Focus Bank (51261)" },
    { value: "02131", value2: "02131", value3: "02131", value4: "02131", name: "Foothill Credit Union (02131)" },
    { value: "53478", value2: "53478", value3: "53478", value4: "53478", name: "Forbright Bank - Bus. Web (53478)" },
    { value: "67595", value2: "67595", value3: "67595", value4: "67595", name: "Forbright Bank - PFM (67595)" },
    { value: "09817", value2: "09817", value3: "09817", value4: "09817", name: "Forcht Bank - Business WebConnect (09817)" },
    { value: "63486", value2: "63486", value3: "63486", value4: "63486", name: "Forcht Bank - RO (63486)" },
    { value: "63705", value2: "63705", value3: "63705", value4: "63705", name: "Forcht Bank - RO - Direct Connect (63705)" },
    { value: "69769", value2: "69769", value3: "69769", value4: "69769", name: "Forcht Bank NA CC (69769)" },
    { value: "70398", value2: "70398", value3: "70398", value4: "70398", name: "Foresight Bank (70398)" },
    { value: "70399", value2: "70399", value3: "70399", value4: "70398", name: "Foresight Bank - DC (70399)" },
    { value: "17694", value2: "17694", value3: "17694", value4: "17694", name: "Forest Park Natl Bank&Trust BIB (17694)" },
    { value: "09611", value2: "09611", value3: "09611", value4: "09611", name: "Fort Community Credit Union (09611)" },
    { value: "15036", value2: "15036", value3: "15036", value4: "15036", name: "Fort Davis State Bank (15036)" },
    { value: "62685", value2: "62685", value3: "62685", value4: "62685", name: "Fort Sill FCU Quickbooks - New (62685)" },
    { value: "12876", value2: "12876", value3: "12876", value4: "12876", name: "Forte Bank (12876)" },
    { value: "17059", value2: "17059", value3: "17059", value4: "17059", name: "Fortera Credit Union - QB (17059)" },
    { value: "56334", value2: "56334", value3: "56334", value4: "56334", name: "Fortifi Bank (56334)" },
    { value: "69146", value2: "69146", value3: "69146", value4: "69146", name: "Fortifi Bank QBDC (69146)" },
    { value: "07903", value2: "07903", value3: "07903", value4: "07903", name: "Fortis Private Bank (07903)" },
    { value: "63591", value2: "63591", value3: "63591", value4: "63591", name: "Fortis Private Bank - DL (63591)" },
    { value: "69505", value2: "69505", value3: "69505", value4: "69505", name: "Fortis Private Bank-TM (69505)" },
    { value: "69506", value2: "69506", value3: "69506", value4: "69505", name: "Fortis Private Bank-TM-DC (69506)" },
    { value: "35211", value2: "35211", value3: "35211", value4: "35211", name: "Fortress Bank (35211)" },
    { value: "69723", value2: "69723", value3: "69723", value4: "69723", name: "Fortress Business (69723)" },
    { value: "69724", value2: "69724", value3: "69724", value4: "69723", name: "Fortress Business QBDC (69724)" },
    { value: "57297", value2: "57297", value3: "57297", value4: "35211", name: "Fortress_DC (57297)" },
    { value: "11300", value2: "11300", value3: "11300", value4: "11300", name: "Forum Credit Union - Business (11300)" },
    { value: "67156", value2: "67156", value3: "67156", value4: "67156", name: "Forward Bank (67156)" },
    { value: "67218", value2: "67218", value3: "67218", value4: "67219", name: "Forward Bank Bus (67218)" },
    { value: "67219", value2: "67219", value3: "67219", value4: "67219", name: "Forward Bank Bus QBDC (67219)" },
    { value: "67157", value2: "67157", value3: "67157", value4: "67156", name: "Forward Bank QBDC (67157)" },
    { value: "50595", value2: "50595", value3: "50595", value4: "50595", name: "Forward Financial Credit Union (50595)" },
    { value: "37206", value2: "37206", value3: "37206", value4: "37206", name: "Foundation Bank OH (37206)" },
    { value: "64083", value2: "64083", value3: "64083", value4: "37206", name: "Foundation Bank OH_DC (64083)" },
    { value: "52257", value2: "52257", value3: "52257", value4: "52257", name: "Foundation One Bank (52257)" },
    { value: "07274", value2: "07274", value3: "07274", value4: "07274", name: "Foundation One Bank - BUS (07274)" },
    { value: "12668", value2: "12668", value3: "12668", value4: "12668", name: "Founders Bank (12668)" },
    { value: "12764", value2: "12764", value3: "12764", value4: "12764", name: "Founders Bank-TM (12764)" },
    { value: "09998", value2: "09998", value3: "09998", value4: "09998", name: "Four Corners Community Bank (09998)" },
    { value: "00839", value2: "00839", value3: "00839", value4: "00839", name: "Four Seasons FCU Credit Card (00839)" },
    { value: "68724", value2: "68724", value3: "68724", value4: "68724", name: "Fourth Capital Bank (68724)" },
    { value: "68725", value2: "68725", value3: "68725", value4: "68724", name: "Fourth Capital QBDC (68725)" },
    { value: "62232", value2: "62232", value3: "62232", value4: "62232", name: "Fowler St Bank (62232)" },
    { value: "56376", value2: "56376", value3: "56376", value4: "56376", name: "Fowler State Bank Colorado (56376)" },
    { value: "64995", value2: "64995", value3: "64995", value4: "56376", name: "Fowler State Bank Colorado_DC (64995)" },
    { value: "17436", value2: "17436", value3: "17436", value4: "17436", name: "Fox Communities Credit Union (17436)" },
    { value: "68295", value2: "68295", value3: "68295", value4: "68295", name: "Fox Valley Savings Bank (68295)" },
    { value: "70416", value2: "70416", value3: "70416", value4: "68295", name: "Fox Valley Savings Bank QBDC (70416)" },
    { value: "63036", value2: "63036", value3: "63036", value4: "63036", name: "Frandsen Bank & Trust - Business (63036)" },
    { value: "17439", value2: "17439", value3: "17439", value4: "17439", name: "Frankenmuth Credit Union (17439)" },
    { value: "10477", value2: "10477", value3: "10477", value4: "10477", name: "Franklin Bank & Trust Company (10477)" },
    { value: "15910", value2: "15910", value3: "15910", value4: "15910", name: "Franklin Bank- BUS (15910)" },
    { value: "04218", value2: "04218", value3: "04218", value4: "04218", name: "Franklin Mint Federal Credit Union (04218)" },
    { value: "13533", value2: "13533", value3: "13533", value4: "13533", name: "Franklin Savings Bank (13533)" },
    { value: "53061", value2: "53061", value3: "53061", value4: "53061", name: "Franklin Savings Bank - Maine (53061)" },
    { value: "46314", value2: "46314", value3: "46314", value4: "46314", name: "Franklin Somerset FCU (46314)" },
    { value: "69812", value2: "69812", value3: "69812", value4: "69812", name: "Franklin State Bank - MN (69812)" },
    { value: "69567", value2: "69567", value3: "69567", value4: "69567", name: "Franklin State Bank and Trust (69567)" },
    { value: "69813", value2: "69813", value3: "69813", value4: "69812", name: "Franklin State Bank-MN - DC (69813)" },
    { value: "65142", value2: "65142", value3: "65142", value4: "65142", name: "Frazer Bank Business (65142)" },
    { value: "28128", value2: "28128", value3: "28128", value4: "28128", name: "Freedom Bank IN (28128)" },
    { value: "09917", value2: "09917", value3: "09917", value4: "09917", name: "Freedom Bank IN - QB DC (09917)" },
    { value: "69071", value2: "69071", value3: "69071", value4: "69071", name: "Freedom Bank Texas - DC (69071)" },
    { value: "28470", value2: "28470", value3: "28470", value4: "28470", name: "Freedom Bank Texas WC (28470)" },
    { value: "14541", value2: "14541", value3: "14541", value4: "14541", name: "Freedom Bank of Montana (14541)" },
    { value: "09338", value2: "09338", value3: "09338", value4: "09338", name: "Freedom Bank of Virginia - New (09338)" },
    { value: "09341", value2: "09341", value3: "09341", value4: "09341", name: "Freedom Bank of Virginia-QBDC (09341)" },
    { value: "08975", value2: "08975", value3: "08975", value4: "08975", name: "Freedom Bank, IA (08975)" },
    { value: "46449", value2: "46449", value3: "46449", value4: "46449", name: "Freedom Community Credit Union (46449)" },
    { value: "55713", value2: "55713", value3: "55713", value4: "46449", name: "Freedom Community Credit Union_DC (55713)" },
    { value: "05417", value2: "05417", value3: "05417", value4: "05417", name: "Freedom Credit Union - MA (05417)" },
    { value: "63093", value2: "63093", value3: "63093", value4: "63093", name: "Freedom Credit Union - MassBiz (63093)" },
    { value: "09215", value2: "09215", value3: "09215", value4: "09215", name: "Freedom Credit Union PA (09215)" },
    { value: "67968", value2: "67968", value3: "67968", value4: "67968", name: "Freedom FCU Business (67968)" },
    { value: "04098", value2: "04098", value3: "04098", value4: "04098", name: "Freedom Federal Credit Union (04098)" },
    { value: "65037", value2: "65037", value3: "65037", value4: "65037", name: "Freedom First Credit Card (65037)" },
    { value: "00213", value2: "00213", value3: "00213", value4: "00213", name: "Freedom First Federal Credit Union (00213)" },
    { value: "12930", value2: "12930", value3: "12930", value4: "12930", name: "Freedom National Bank (12930)" },
    { value: "06047", value2: "06047", value3: "06047", value4: "06047", name: "Freedom National Bank QBDC (06047)" },
    { value: "69632", value2: "69632", value3: "69632", value4: "69632", name: "FreedomRoad Financial (69632)" },
    { value: "08372", value2: "08372", value3: "08372", value4: "08372", name: "Freestar Financial Credit Union (08372)" },
    { value: "15643", value2: "15643", value3: "15643", value4: "15643", name: "Fremont Bank Cash Management (15643)" },
    { value: "53541", value2: "53541", value3: "53541", value4: "53541", name: "Fremont Bank-Personal Banking (53541)" },
    { value: "67654", value2: "67654", value3: "67654", value4: "53541", name: "Fremont Bank-Personal Banking CA (67654)" },
    { value: "00310", value2: "00310", value3: "00310", value4: "00310", name: "Fremont Federal Credit Union (00310)" },
    { value: "09298", value2: "09298", value3: "09298", value4: "09298", name: "Frontier Airlines BusinessCard (09298)" },
    { value: "02156", value2: "02156", value3: "02156", value4: "02156", name: "Frontier Bank - IA (02156)" },
    { value: "68154", value2: "68154", value3: "68154", value4: "68154", name: "Frontier Bank Business Direct (68154)" },
    { value: "55110", value2: "55110", value3: "55110", value4: "55110", name: "Frontier Bank Business Web (55110)" },
    { value: "13977", value2: "13977", value3: "13977", value4: "13977", name: "Frontier Bank of Texas (13977)" },
    { value: "08618", value2: "08618", value3: "08618", value4: "08618", name: "Frontier Bank of Texas - DC (08618)" },
    { value: "52050", value2: "52050", value3: "52050", value4: "52050", name: "Frontier Bank-NE (52050)" },
    { value: "00554", value2: "00554", value3: "00554", value4: "00554", name: "Frontier Community Bank (00554)" },
    { value: "10322", value2: "10322", value3: "10322", value4: "10322", name: "Frontier Community Credit Union (10322)" },
    { value: "19299", value2: "19299", value3: "19299", value4: "19299", name: "Frontier Credit Union (19299)" },
    { value: "68362", value2: "68362", value3: "68362", value4: "68362", name: "Frontier Credit Union Business (68362)" },
    { value: "05405", value2: "05405", value3: "05405", value4: "05405", name: "Frontier State Bank (05405)" },
    { value: "07586", value2: "07586", value3: "07586", value4: "07586", name: "Frontier State Bank - DC (07586)" },
    { value: "52086", value2: "52086", value3: "52086", value4: "52086", name: "Frontwave Credit Union (52086)" },
    { value: "12106", value2: "12106", value3: "12106", value4: "12106", name: "Frost - WC (12106)" },
    { value: "05825", value2: "05825", value3: "05825", value4: "05825", name: "Frost Bank (05825)" },
    { value: "67309", value2: "67309", value3: "67309", value4: "67309", name: "Frost Connect - DC (67309)" },
    { value: "46758", value2: "46758", value3: "46758", value4: "46758", name: "Fulda Area CUFulda, MN (46758)" },
    { value: "57765", value2: "57765", value3: "57765", value4: "46758", name: "Fulda Area CU_DC (57765)" },
    { value: "14071", value2: "14071", value3: "14071", value4: "14071", name: "Fulton Bank DirectConnect (14071)" },
    { value: "67173", value2: "67173", value3: "67173", value4: "67173", name: "Fulton Bank Small Business - DC (67173)" },
    { value: "14077", value2: "14077", value3: "14077", value4: "14077", name: "Fulton Bank WebConnect QBO (14077)" },
    { value: "16501", value2: "16501", value3: "16501", value4: "16501", name: "Fulton Financial Bank BOSS (16501)" },
    { value: "02783", value2: "02783", value3: "02783", value4: "02783", name: "Fulton Financial Bank BOSS-DC (02783)" },
    { value: "52347", value2: "52347", value3: "52347", value4: "52347", name: "Fusion Bank (52347)" },
    { value: "69754", value2: "69754", value3: "69754", value4: "69754", name: "GBB Business (69754)" },
    { value: "69756", value2: "69756", value3: "69756", value4: "69754", name: "GBB Business QB DC (69756)" },
    { value: "69753", value2: "69753", value3: "69753", value4: "69753", name: "GBB Personal (69753)" },
    { value: "69755", value2: "69755", value3: "69755", value4: "69753", name: "GBB Personal QB DC (69755)" },
    { value: "07865", value2: "07865", value3: "07865", value4: "07865", name: "GBC International Bank - Business (07865)" },
    { value: "68451", value2: "68451", value3: "68451", value4: "68451", name: "GBC QuickBooks Direct Connect (68451)" },
    { value: "68446", value2: "68446", value3: "68446", value4: "68446", name: "GBC QuickBooks Web Connect (68446)" },
    { value: "15102", value2: "15102", value3: "15102", value4: "15102", name: "GBank (15102)" },
    { value: "69743", value2: "69743", value3: "69743", value4: "69743", name: "GBank QBDC (69743)" },
    { value: "14535", value2: "14535", value3: "14535", value4: "14535", name: "GCB A Division of LGE Community CU (14535)" },
    { value: "14593", value2: "14593", value3: "14593", value4: "14593", name: "GE Credit Union QB (14593)" },
    { value: "11577", value2: "11577", value3: "11577", value4: "11577", name: "GECU QB (11577)" },
    { value: "06302", value2: "06302", value3: "06302", value4: "06302", name: "GFA Federal Credit Union (06302)" },
    { value: "12055", value2: "12055", value3: "12055", value4: "12055", name: "GFA Federal Credit Union-QB (12055)" },
    { value: "69331", value2: "69331", value3: "69331", value4: "69331", name: "GNB Bank CC (69331)" },
    { value: "16993", value2: "16993", value3: "16993", value4: "16993", name: "GNBank (16993)" },
    { value: "05359", value2: "05359", value3: "05359", value4: "05359", name: "GTE Financial (05359)" },
    { value: "12272", value2: "12272", value3: "12272", value4: "12272", name: "Gabriels Community Credit Union (12272)" },
    { value: "05965", value2: "05965", value3: "05965", value4: "05965", name: "Gain FCU (05965)" },
    { value: "16374", value2: "16374", value3: "16374", value4: "16374", name: "Garfield County Bank (16374)" },
    { value: "11435", value2: "11435", value3: "11435", value4: "11435", name: "Gate City Bank (11435)" },
    { value: "11154", value2: "11154", value3: "11154", value4: "11154", name: "Gateway Bank (11154)" },
    { value: "70126", value2: "70126", value3: "70126", value4: "70126", name: "Gateway Bank AR (70126)" },
    { value: "15708", value2: "15708", value3: "15708", value4: "15708", name: "Gateway Commercial Bank (15708)" },
    { value: "63651", value2: "63651", value3: "63651", value4: "63651", name: "Gateway Commercial Bank - DL (63651)" },
    { value: "34335", value2: "34335", value3: "34335", value4: "34335", name: "Gateway First Bank (34335)" },
    { value: "69679", value2: "69679", value3: "69679", value4: "69679", name: "Gateway First Bank QBDC (69679)" },
    { value: "12666", value2: "12666", value3: "12666", value4: "12666", name: "Gather Federal Credit Union (12666)" },
    { value: "07451", value2: "07451", value3: "07451", value4: "07451", name: "Geauga Savings Bank (07451)" },
    { value: "50724", value2: "50724", value3: "50724", value4: "50724", name: "Geauga Savings Bank - Business (50724)" },
    { value: "70037", value2: "70037", value3: "70037", value4: "70037", name: "Geddes Federal SL WC QB (70037)" },
    { value: "22110", value2: "22110", value3: "22110", value4: "22110", name: "GenFed Financial CU (22110)" },
    { value: "16858", value2: "16858", value3: "16858", value4: "16858", name: "General Electric Credit Union WQB (16858)" },
    { value: "16855", value2: "16855", value3: "16855", value4: "16855", name: "General Electric Credit Union-WCDC (16855)" },
    { value: "08135", value2: "08135", value3: "08135", value4: "08135", name: "Generations Bank (08135)" },
    { value: "16767", value2: "16767", value3: "16767", value4: "16767", name: "Generations Bank - AR (16767)" },
    { value: "68110", value2: "68110", value3: "68110", value4: "68110", name: "Generations Bank - NY (68110)" },
    { value: "02978", value2: "02978", value3: "02978", value4: "02978", name: "Generations Bank BeB-WC (02978)" },
    { value: "07739", value2: "07739", value3: "07739", value4: "07739", name: "Generations Bank, NE (07739)" },
    { value: "07742", value2: "07742", value3: "07742", value4: "07739", name: "Generations Bank, NE - DC (07742)" },
    { value: "06818", value2: "06818", value3: "06818", value4: "06818", name: "Generations FCU (06818)" },
    { value: "06971", value2: "06971", value3: "06971", value4: "06818", name: "Generations FCU - QB (06971)" },
    { value: "10557", value2: "10557", value3: "10557", value4: "10557", name: "Genesee Regional Bank (10557)" },
    { value: "05924", value2: "05924", value3: "05924", value4: "05924", name: "Genesee Regional Bank-DC (05924)" },
    { value: "67940", value2: "67940", value3: "67940", value4: "67940", name: "Genesee Regional Bank-TM (67940)" },
    { value: "67941", value2: "67941", value3: "67941", value4: "67940", name: "Genesee Regional Bank-TM-DC (67941)" },
    { value: "68230", value2: "68230", value3: "68230", value4: "68230", name: "Genesis Bank - Personal (68230)" },
    { value: "68615", value2: "68615", value3: "68615", value4: "68615", name: "Genesis Bank Bus (68615)" },
    { value: "68614", value2: "68614", value3: "68614", value4: "68614", name: "Genesis Bank Bus QBDC (68614)" },
    { value: "68231", value2: "68231", value3: "68231", value4: "68231", name: "Genesis Bank Personal Web (68231)" },
    { value: "10593", value2: "10593", value3: "10593", value4: "10593", name: "Genisys Credit Union (10593)" },
    { value: "67185", value2: "67185", value3: "67185", value4: "67185", name: "Genoa Community Bank (67185)" },
    { value: "63363", value2: "63363", value3: "63363", value4: "63363", name: "GenoaBank-Business (63363)" },
    { value: "00636", value2: "00636", value3: "00636", value4: "00636", name: "Georgia Banking Company (00636)" },
    { value: "68536", value2: "68536", value3: "68536", value4: "68536", name: "Georgia Banking Company - DC (68536)" },
    { value: "35907", value2: "35907", value3: "35907", value4: "35907", name: "Georgia Community Bank - BO (35907)" },
    { value: "57645", value2: "57645", value3: "57645", value4: "57645", name: "Georgia Community Bank - RO (57645)" },
    { value: "16380", value2: "16380", value3: "16380", value4: "16380", name: "Georgia First Bank (16380)" },
    { value: "67858", value2: "67858", value3: "67858", value4: "67858", name: "Georgia Power Valdosta Fed CU (67858)" },
    { value: "18588", value2: "18588", value3: "18588", value4: "18588", name: "Georgia Primary Bank (18588)" },
    { value: "18693", value2: "18693", value3: "18693", value4: "18693", name: "Georgia Primary Direct (18693)" },
    { value: "64071", value2: "64071", value3: "64071", value4: "64071", name: "Georgia Primary Direct Business (64071)" },
    { value: "17287", value2: "17287", value3: "17287", value4: "17287", name: "Georgia United Credit Union (17287)" },
    { value: "06577", value2: "06577", value3: "06577", value4: "06577", name: "Georgia's Own Credit Union (06577)" },
    { value: "51516", value2: "51516", value3: "51516", value4: "51516", name: "Gerber Federal Credit Union (51516)" },
    { value: "14641", value2: "14641", value3: "14641", value4: "14641", name: "German American Bank - Direct (14641)" },
    { value: "14638", value2: "14638", value3: "14638", value4: "14638", name: "German American Bank QuickBooks (14638)" },
    { value: "56757", value2: "56757", value3: "56757", value4: "56757", name: "German American Business Online (56757)" },
    { value: "15163", value2: "15163", value3: "15163", value4: "15163", name: "German American Business Online QB (15163)" },
    { value: "54021", value2: "54021", value3: "54021", value4: "54021", name: "German American State Bank (54021)" },
    { value: "19008", value2: "19008", value3: "19008", value4: "19008", name: "Gesa Credit Union (19008)" },
    { value: "54663", value2: "54663", value3: "54663", value4: "54663", name: "Gibsland Bank (54663)" },
    { value: "51636", value2: "51636", value3: "51636", value4: "51636", name: "Gifford State Bank (51636)" },
    { value: "12407", value2: "12407", value3: "12407", value4: "12407", name: "Glacier Bancorp Inc (12407)" },
    { value: "70484", value2: "70484", value3: "70484", value4: "70484", name: "Glacier Bank (70484)" },
    { value: "70485", value2: "70485", value3: "70485", value4: "70484", name: "Glacier Bank-DC (70485)" },
    { value: "01485", value2: "01485", value3: "01485", value4: "01485", name: "Glacier Family of Banks (01485)" },
    { value: "06983", value2: "06983", value3: "06983", value4: "06983", name: "Glacier Family of Banks - DC (06983)" },
    { value: "69240", value2: "69240", value3: "69240", value4: "69240", name: "Glacier Family of Banks-TM (69240)" },
    { value: "69241", value2: "69241", value3: "69241", value4: "69241", name: "Glacier Family of Banks-TM-DC (69241)" },
    { value: "50898", value2: "50898", value3: "50898", value4: "50898", name: "Glacier Hills Credit Union - WI (50898)" },
    { value: "00651", value2: "00651", value3: "00651", value4: "00651", name: "Glass City Federal Credit Union (00651)" },
    { value: "08396", value2: "08396", value3: "08396", value4: "08396", name: "Glens Falls National Bank - WC (08396)" },
    { value: "69545", value2: "69545", value3: "69545", value4: "69545", name: "Glens Falls National Bank DC (69545)" },
    { value: "27351", value2: "27351", value3: "27351", value4: "27351", name: "Glenwood State Bank (27351)" },
    { value: "08093", value2: "08093", value3: "08093", value4: "08093", name: "Glenwood State Bank - Frontier (08093)" },
    { value: "11419", value2: "11419", value3: "11419", value4: "11419", name: "Global Credit Union (11419)" },
    { value: "69566", value2: "69566", value3: "69566", value4: "69566", name: "Global Innovations Bank (69566)" },
    { value: "70465", value2: "70465", value3: "70465", value4: "70465", name: "Go Energy Financial CU Bus CC (70465)" },
    { value: "19440", value2: "19440", value3: "19440", value4: "19440", name: "GoUnionBank (19440)" },
    { value: "09185", value2: "09185", value3: "09185", value4: "09185", name: "Gogebic Range Bank BO (09185)" },
    { value: "07399", value2: "07399", value3: "07399", value4: "07399", name: "Gold Coast Bank WC (07399)" },
    { value: "12896", value2: "12896", value3: "12896", value4: "12896", name: "Golden Bank (12896)" },
    { value: "12899", value2: "12899", value3: "12899", value4: "12896", name: "Golden Bank WC (12899)" },
    { value: "15117", value2: "15117", value3: "15117", value4: "15117", name: "Golden Belt Bank, FSA WC QB (15117)" },
    { value: "12772", value2: "12772", value3: "12772", value4: "12772", name: "Golden Pacific Bank (12772)" },
    { value: "51555", value2: "51555", value3: "51555", value4: "51555", name: "Golden Pacific Bank - BB-QBO (51555)" },
    { value: "12775", value2: "12775", value3: "12775", value4: "12775", name: "Golden Pacific Bank QBO (12775)" },
    { value: "14401", value2: "14401", value3: "14401", value4: "14401", name: "Golden Plains CU - Direct (14401)" },
    { value: "14485", value2: "14485", value3: "14485", value4: "14485", name: "Golden Plains Credit Union QB (14485)" },
    { value: "70245", value2: "70245", value3: "70245", value4: "70245", name: "Golden State Bank of CA-DC (70245)" },
    { value: "18813", value2: "18813", value3: "18813", value4: "18813", name: "Golden State Bank of California (18813)" },
    { value: "15972", value2: "15972", value3: "15972", value4: "15972", name: "Golden Triangle FCU (15972)" },
    { value: "64050", value2: "64050", value3: "64050", value4: "64050", name: "Golden Valley Bank Bus WC (64050)" },
    { value: "65298", value2: "65298", value3: "65298", value4: "65298", name: "Golden Valley Bank Business DC (65298)" },
    { value: "64101", value2: "64101", value3: "64101", value4: "64101", name: "Golden Valley Bank Retail (64101)" },
    { value: "64104", value2: "64104", value3: "64104", value4: "64101", name: "Golden Valley Bank Retail QB DC (64104)" },
    { value: "69707", value2: "69707", value3: "69707", value4: "69707", name: "Golden1 CU Commercial (69707)" },
    { value: "02119", value2: "02119", value3: "02119", value4: "02119", name: "Goldenwest Credit Union (02119)" },
    { value: "65172", value2: "65172", value3: "65172", value4: "65172", name: "Goldwater Bank- QB (65172)" },
    { value: "28152", value2: "28152", value3: "28152", value4: "28152", name: "Goodfield State Bank (28152)" },
    { value: "04520", value2: "04520", value3: "04520", value4: "04520", name: "Goose River Bank (04520)" },
    { value: "04523", value2: "04523", value3: "04523", value4: "04520", name: "Goose River Bank - DC (04523)" },
    { value: "68302", value2: "68302", value3: "68302", value4: "68302", name: "Goppert Financial Bank (68302)" },
    { value: "68530", value2: "68530", value3: "68530", value4: "68530", name: "Goppert State Service Bank (68530)" },
    { value: "68531", value2: "68531", value3: "68531", value4: "68531", name: "Goppert State Service Bank QBDC (68531)" },
    { value: "09708", value2: "09708", value3: "09708", value4: "09708", name: "Gorham Savings Bank (09708)" },
    { value: "69398", value2: "69398", value3: "69398", value4: "69398", name: "Gorham Savings Bank-DC (69398)" },
    { value: "65022", value2: "65022", value3: "65022", value4: "65022", name: "Graham Savings and Loan, SSB (65022)" },
    { value: "17106", value2: "17106", value3: "17106", value4: "17106", name: "Grand Bank of Texas (17106)" },
    { value: "08621", value2: "08621", value3: "08621", value4: "08621", name: "Grand Bank-Tulsa OK (08621)" },
    { value: "08624", value2: "08624", value3: "08624", value4: "08621", name: "Grand Bank-Tulsa OK WC (08624)" },
    { value: "34098", value2: "34098", value3: "34098", value4: "34098", name: "Grand Marais State Bank (34098)" },
    { value: "20235", value2: "20235", value3: "20235", value4: "20235", name: "Grand Rapids State Bank (20235)" },
    { value: "19290", value2: "19290", value3: "19290", value4: "19290", name: "Grand Ridge National Bank (19290)" },
    { value: "19608", value2: "19608", value3: "19608", value4: "19608", name: "Grand River Bank (19608)" },
    { value: "69698", value2: "69698", value3: "69698", value4: "69698", name: "Grand River Bank Business DC (69698)" },
    { value: "16266", value2: "16266", value3: "16266", value4: "16266", name: "Grand Savings Bank (16266)" },
    { value: "12176", value2: "12176", value3: "12176", value4: "12176", name: "Grand Savings Bank DC (12176)" },
    { value: "70294", value2: "70294", value3: "70294", value4: "70294", name: "Grand Timber Bank BO (70294)" },
    { value: "70295", value2: "70295", value3: "70295", value4: "70294", name: "Grand Timber Bank BODC (70295)" },
    { value: "70293", value2: "70293", value3: "70293", value4: "70293", name: "Grand Timber Bank RO (70293)" },
    { value: "70296", value2: "70296", value3: "70296", value4: "70293", name: "Grand Timber Bank RO DC (70296)" },
    { value: "17661", value2: "17661", value3: "17661", value4: "17661", name: "Grand Trunk BC Emp. FCU (17661)" },
    { value: "23502", value2: "23502", value3: "23502", value4: "23502", name: "Grand Valley Bank (23502)" },
    { value: "51231", value2: "51231", value3: "51231", value4: "51231", name: "Grand Valley Coop Credit Union (51231)" },
    { value: "50838", value2: "50838", value3: "50838", value4: "50838", name: "Grandview Bank Grandview TX (50838)" },
    { value: "69484", value2: "69484", value3: "69484", value4: "50838", name: "Grandview Bank Grandview TX-DC (69484)" },
    { value: "08864", value2: "08864", value3: "08864", value4: "08864", name: "Granite Bank (08864)" },
    { value: "08867", value2: "08867", value3: "08867", value4: "08864", name: "Granite Bank-QB (08867)" },
    { value: "03928", value2: "03928", value3: "03928", value4: "03928", name: "Granite State Credit Union (03928)" },
    { value: "06250", value2: "06250", value3: "06250", value4: "06250", name: "Grant County Bank, Ulysses, KS (06250)" },
    { value: "69122", value2: "69122", value3: "69122", value4: "69122", name: "Grasshopper Bank Business (69122)" },
    { value: "68829", value2: "68829", value3: "68829", value4: "68829", name: "Grasshopper Bank DC (68829)" },
    { value: "68855", value2: "68855", value3: "68855", value4: "68855", name: "Grasshopper Bank WC Bus (68855)" },
    { value: "64569", value2: "64569", value3: "64569", value4: "64569", name: "Grasslands Federal Credit Union (64569)" },
    { value: "54549", value2: "54549", value3: "54549", value4: "54549", name: "Great American Bank - KS (54549)" },
    { value: "17452", value2: "17452", value3: "17452", value4: "17452", name: "Great Basin FCU (17452)" },
    { value: "06038", value2: "06038", value3: "06038", value4: "06038", name: "Great Basin FCU-WC QB (06038)" },
    { value: "11471", value2: "11471", value3: "11471", value4: "11471", name: "Great Lakes CU (11471)" },
    { value: "40152", value2: "40152", value3: "40152", value4: "40152", name: "Great Lakes Federal CU (40152)" },
    { value: "06053", value2: "06053", value3: "06053", value4: "06053", name: "Great North Bank (06053)" },
    { value: "09644", value2: "09644", value3: "09644", value4: "09644", name: "Great NorthWest FCU QB (09644)" },
    { value: "17028", value2: "17028", value3: "17028", value4: "17028", name: "Great Oaks Bank (17028)" },
    { value: "16017", value2: "16017", value3: "16017", value4: "16017", name: "Great Plains Bank (16017)" },
    { value: "67657", value2: "67657", value3: "67657", value4: "67657", name: "Great Plains Bank - DC (67657)" },
    { value: "67656", value2: "67656", value3: "67656", value4: "67656", name: "Great Plains Bank - WC (67656)" },
    { value: "55728", value2: "55728", value3: "55728", value4: "55728", name: "Great Plains National Bank-Hollis (55728)" },
    { value: "00968", value2: "00968", value3: "00968", value4: "00968", name: "Great Plains State Bk (00968)" },
    { value: "02393", value2: "02393", value3: "02393", value4: "02393", name: "Great River FCU - QuickBooks (02393)" },
    { value: "50841", value2: "50841", value3: "50841", value4: "50841", name: "Great Rivers Bank (50841)" },
    { value: "01446", value2: "01446", value3: "01446", value4: "01446", name: "Great Southern Bank (01446)" },
    { value: "50505", value2: "50505", value3: "50505", value4: "50505", name: "Great Southern National Bank - MS (50505)" },
    { value: "16597", value2: "16597", value3: "16597", value4: "16597", name: "Greater Alliance FCU-QB (16597)" },
    { value: "64089", value2: "64089", value3: "64089", value4: "64089", name: "Greater Cincinnati Credit Union-DC (64089)" },
    { value: "05168", value2: "05168", value3: "05168", value4: "05168", name: "Greater Cleveland Comm. C.U. (05168)" },
    { value: "09406", value2: "09406", value3: "09406", value4: "09406", name: "Greater Iowa CU (09406)" },
    { value: "17580", value2: "17580", value3: "17580", value4: "17580", name: "Greater Metro FCU (17580)" },
    { value: "67235", value2: "67235", value3: "67235", value4: "67235", name: "Greater Nevada CU (67235)" },
    { value: "67236", value2: "67236", value3: "67236", value4: "67235", name: "Greater Nevada CU DC (67236)" },
    { value: "24432", value2: "24432", value3: "24432", value4: "24432", name: "Greater New Orleans FCU (24432)" },
    { value: "70122", value2: "70122", value3: "70122", value4: "70122", name: "Greater Springfield (70122)" },
    { value: "65385", value2: "65385", value3: "65385", value4: "65385", name: "Greater State Bank (65385)" },
    { value: "18634", value2: "18634", value3: "18634", value4: "18634", name: "Greater Texas Credit Union (18634)" },
    { value: "18637", value2: "18637", value3: "18637", value4: "18634", name: "Greater Texas Credit Union-QBDC (18637)" },
    { value: "67901", value2: "67901", value3: "67901", value4: "67901", name: "Greater Wayne Community FCU (67901)" },
    { value: "07718", value2: "07718", value3: "07718", value4: "07718", name: "Green Country FCU (07718)" },
    { value: "07721", value2: "07721", value3: "07721", value4: "07718", name: "Green Country FCU - DC (07721)" },
    { value: "09876", value2: "09876", value3: "09876", value4: "09876", name: "GreenState Credit Union (09876)" },
    { value: "68550", value2: "68550", value3: "68550", value4: "68550", name: "Greeneville Federal Bank (68550)" },
    { value: "68552", value2: "68552", value3: "68552", value4: "68552", name: "Greeneville Federal Bank Biz (68552)" },
    { value: "68553", value2: "68553", value3: "68553", value4: "68553", name: "Greeneville Federal Bank Bz QBDC (68553)" },
    { value: "68551", value2: "68551", value3: "68551", value4: "68551", name: "Greeneville Federal Bank QBDC (68551)" },
    { value: "02274", value2: "02274", value3: "02274", value4: "02274", name: "Greenfield Banking Co. (02274)" },
    { value: "09254", value2: "09254", value3: "09254", value4: "09254", name: "Greenfield Banking Company (09254)" },
    { value: "09257", value2: "09257", value3: "09257", value4: "09254", name: "Greenfield Banking Company-DC (09257)" },
    { value: "05159", value2: "05159", value3: "05159", value4: "05159", name: "Greenfield Cooperative Bank (05159)" },
    { value: "05162", value2: "05162", value3: "05162", value4: "05159", name: "Greenfield Cooperative Bank QBWC (05162)" },
    { value: "50970", value2: "50970", value3: "50970", value4: "50970", name: "Greenfield Savings Bank (50970)" },
    { value: "64488", value2: "64488", value3: "64488", value4: "64488", name: "Greenfield Savings Bank-BUS (64488)" },
    { value: "57528", value2: "57528", value3: "57528", value4: "57528", name: "Greensboro Municipal Federal CU (57528)" },
    { value: "12406", value2: "12406", value3: "12406", value4: "12406", name: "Greenstone FCS, ACA (12406)" },
    { value: "03396", value2: "03396", value3: "03396", value4: "03396", name: "Greenville Federal Credit Union (03396)" },
    { value: "19761", value2: "19761", value3: "19761", value4: "19761", name: "Greenville Heritage FCU (19761)" },
    { value: "00560", value2: "00560", value3: "00560", value4: "00560", name: "Greenville National Bank (00560)" },
    { value: "03061", value2: "03061", value3: "03061", value4: "03061", name: "Greenwood Credit Union (03061)" },
    { value: "53805", value2: "53805", value3: "53805", value4: "53805", name: "Greenwood's State Bank (53805)" },
    { value: "08468", value2: "08468", value3: "08468", value4: "08468", name: "Greenwoods State Bank Direct (08468)" },
    { value: "04253", value2: "04253", value3: "04253", value4: "04253", name: "Greylock FCU (04253)" },
    { value: "00782", value2: "00782", value3: "00782", value4: "00782", name: "Grinnell State Bank IA (00782)" },
    { value: "00827", value2: "00827", value3: "00827", value4: "00827", name: "Grinnell State Bank IA QBDC (00827)" },
    { value: "64761", value2: "64761", value3: "64761", value4: "64761", name: "Grove Bank & Trust - Business Banking (64761)" },
    { value: "64764", value2: "64764", value3: "64764", value4: "64761", name: "Grove Bank & Trust - DL (64764)" },
    { value: "68133", value2: "68133", value3: "68133", value4: "68133", name: "Grove Bank & Trust-TM (68133)" },
    { value: "68135", value2: "68135", value3: "68135", value4: "68135", name: "Grove Bank & Trust-TM-DC (68135)" },
    { value: "67582", value2: "67582", value3: "67582", value4: "67582", name: "Grove Bank and Trust (67582)" },
    { value: "00960", value2: "00960", value3: "00960", value4: "00960", name: "Grow Financial FCU (00960)" },
    { value: "26103", value2: "26103", value3: "26103", value4: "26103", name: "Grundy Bank (26103)" },
    { value: "16491", value2: "16491", value3: "16491", value4: "16491", name: "Guadalupe Bank (16491)" },
    { value: "67509", value2: "67509", value3: "67509", value4: "67509", name: "Guadalupe Bank DC (67509)" },
    { value: "00305", value2: "00305", value3: "00305", value4: "00305", name: "Guaranty Bank & Trust - LA (00305)" },
    { value: "51312", value2: "51312", value3: "51312", value4: "51312", name: "Guaranty Bank & Trust, N.A. (51312)" },
    { value: "53304", value2: "53304", value3: "53304", value4: "53304", name: "Guaranty Bank & Trust, N.A. - DC (53304)" },
    { value: "05847", value2: "05847", value3: "05847", value4: "05847", name: "Guaranty Bank - Springfield, MO (05847)" },
    { value: "69600", value2: "69600", value3: "69600", value4: "69600", name: "Guaranty Bank MO Bus (69600)" },
    { value: "09143", value2: "09143", value3: "09143", value4: "09140", name: "Guaranty Bank MO Bus QBDC (09143)" },
    { value: "15273", value2: "15273", value3: "15273", value4: "15273", name: "Guaranty Bank MS (15273)" },
    { value: "69502", value2: "69502", value3: "69502", value4: "15273", name: "Guaranty Bank MS QBDC (69502)" },
    { value: "70229", value2: "70229", value3: "70229", value4: "70229", name: "Guaranty Sate Bank & Trust QBDC (70229)" },
    { value: "29484", value2: "29484", value3: "29484", value4: "29484", name: "Guaranty State Bank and Trust (29484)" },
    { value: "53973", value2: "53973", value3: "53973", value4: "53973", name: "Guardian Bank (53973)" },
    { value: "03026", value2: "03026", value3: "03026", value4: "53973", name: "Guardian Bank - DL (03026)" },
    { value: "02958", value2: "02958", value3: "02958", value4: "02958", name: "Guardian Credit Union (02958)" },
    { value: "61656", value2: "61656", value3: "61656", value4: "61656", name: "Guardian Credit Union - AL (61656)" },
    { value: "40395", value2: "40395", value3: "40395", value4: "40395", name: "Guardians Credit Union (40395)" },
    { value: "09806", value2: "09806", value3: "09806", value4: "09806", name: "Guilford Savings Bank (09806)" },
    { value: "06643", value2: "06643", value3: "06643", value4: "09806", name: "Guilford Savings Bank - QB (06643)" },
    { value: "12767", value2: "12767", value3: "12767", value4: "12767", name: "Gulf Atlantic Bank (12767)" },
    { value: "67110", value2: "67110", value3: "67110", value4: "67110", name: "Gulf Capital Bank (67110)" },
    { value: "67113", value2: "67113", value3: "67113", value4: "67113", name: "Gulf Capital Bank BO DC (67113)" },
    { value: "67111", value2: "67111", value3: "67111", value4: "67111", name: "Gulf Capital Bank Business (67111)" },
    { value: "67112", value2: "67112", value3: "67112", value4: "67112", name: "Gulf Capital Bank RO DC (67112)" },
    { value: "03044", value2: "03044", value3: "03044", value4: "03044", name: "Gulf Coast B&T Credit Card (03044)" },
    { value: "50772", value2: "50772", value3: "50772", value4: "50772", name: "Gulf Coast Bank (50772)" },
    { value: "14610", value2: "14610", value3: "14610", value4: "14610", name: "Gulf Coast Bank & Trust Co. (14610)" },
    { value: "70255", value2: "70255", value3: "70255", value4: "70255", name: "Gulf Coast Bank - LA - DC (70255)" },
    { value: "69349", value2: "69349", value3: "69349", value4: "69348", name: "Gulf Coast Business Bank-DC (69349)" },
    { value: "69350", value2: "69350", value3: "69350", value4: "69350", name: "Gulf Coast Business Bank-TM (69350)" },
    { value: "69351", value2: "69351", value3: "69351", value4: "69350", name: "Gulf Coast Business Bank-TM-DC (69351)" },
    { value: "42081", value2: "42081", value3: "42081", value4: "42081", name: "Gulf Coast FCU TX (42081)" },
    { value: "69841", value2: "69841", value3: "69841", value4: "69841", name: "Gulf Coast FCU TX Bus (69841)" },
    { value: "69842", value2: "69842", value3: "69842", value4: "69842", name: "Gulf Coast FCU TX Bus QBDC (69842)" },
    { value: "69840", value2: "69840", value3: "69840", value4: "69840", name: "Gulf Coast FCU TX QBDC (69840)" },
    { value: "11937", value2: "11937", value3: "11937", value4: "11937", name: "Gulf Winds Credit Union (11937)" },
    { value: "07547", value2: "07547", value3: "07547", value4: "07547", name: "Gulfside Bank (07547)" },
    { value: "07646", value2: "07646", value3: "07646", value4: "07547", name: "Gulfside Bank Direct (07646)" },
    { value: "67510", value2: "67510", value3: "67510", value4: "67510", name: "Gulfside Bank-TM (67510)" },
    { value: "67511", value2: "67511", value3: "67511", value4: "67510", name: "Gulfside Bank-TM-DC (67511)" },
    { value: "65079", value2: "65079", value3: "65079", value4: "65079", name: "Guthrie County State Bank - IA (65079)" },
    { value: "67231", value2: "67231", value3: "67231", value4: "67231", name: "Guthrie County State Bank-IA QB (67231)" },
    { value: "07280", value2: "07280", value3: "07280", value4: "07280", name: "H&H Federal Credit Union (07280)" },
    { value: "04134", value2: "04134", value3: "04134", value4: "04134", name: "H.B.I Employees Credit Union (04134)" },
    { value: "17215", value2: "17215", value3: "17215", value4: "17215", name: "HAPO Community CU - QB (17215)" },
    { value: "68322", value2: "68322", value3: "68322", value4: "68322", name: "HAPO Community CU Business QB (68322)" },
    { value: "20706", value2: "20706", value3: "20706", value4: "20706", name: "HAR_CO Maryland FCU (20706)" },
    { value: "07415", value2: "07415", value3: "07415", value4: "20706", name: "HAR_CO Maryland FCU - QB (07415)" },
    { value: "01599", value2: "01599", value3: "01599", value4: "01599", name: "HCN Bank (01599)" },
    { value: "63480", value2: "63480", value3: "63480", value4: "63480", name: "HFS FCU Credit Card (63480)" },
    { value: "09677", value2: "09677", value3: "09677", value4: "09677", name: "HFS Federal Credit Union (09677)" },
    { value: "65229", value2: "65229", value3: "65229", value4: "65229", name: "HNB FIRST Bank (65229)" },
    { value: "05256", value2: "05256", value3: "05256", value4: "05256", name: "HNB National Bank (05256)" },
    { value: "70473", value2: "70473", value3: "70473", value4: "70473", name: "HNB National Bank-DC (70473)" },
    { value: "62865", value2: "62865", value3: "62865", value4: "62865", name: "HOMEBANK (62865)" },
    { value: "09752", value2: "09752", value3: "09752", value4: "62865", name: "HOMEBANK QB DC (09752)" },
    { value: "70400", value2: "70400", value3: "70400", value4: "70400", name: "HPC Credit Union (70400)" },
    { value: "01270", value2: "01270", value3: "01270", value4: "01270", name: "HSBC Bank USA (01270)" },
    { value: "67314", value2: "67314", value3: "67314", value4: "67314", name: "HTLF Card Services (67314)" },
    { value: "06802", value2: "06802", value3: "06802", value4: "06802", name: "Halstead Bank IB WC QB (06802)" },
    { value: "00323", value2: "00323", value3: "00323", value4: "00323", name: "Hamilton Horizons FCU credit card (00323)" },
    { value: "42102", value2: "42102", value3: "42102", value4: "42102", name: "Hancock FCU (42102)" },
    { value: "70438", value2: "70438", value3: "70438", value4: "70438", name: "Hancock FCU Bus CC (70438)" },
    { value: "17098", value2: "17098", value3: "17098", value4: "17098", name: "Hancock Whitney Bank Direct (17098)" },
    { value: "67823", value2: "67823", value3: "67823", value4: "67823", name: "Hancock Whitney Bank Treasury (67823)" },
    { value: "17101", value2: "17101", value3: "17101", value4: "17101", name: "Hancock Whitney Bank Treasury - DC (17101)" },
    { value: "16861", value2: "16861", value3: "16861", value4: "16861", name: "Hancock Whitney Bank Web (16861)" },
    { value: "59709", value2: "59709", value3: "59709", value4: "59709", name: "Hanmi Bank (59709)" },
    { value: "18907", value2: "18907", value3: "18907", value4: "18907", name: "Hanmi Bank - QB DC (18907)" },
    { value: "70355", value2: "70355", value3: "70355", value4: "70355", name: "Hanover Bank BO DC (70355)" },
    { value: "70354", value2: "70354", value3: "70354", value4: "70354", name: "Hanover Bank BO WC (70354)" },
    { value: "70353", value2: "70353", value3: "70353", value4: "70353", name: "Hanover Bank RO DC (70353)" },
    { value: "70352", value2: "70352", value3: "70352", value4: "70352", name: "Hanover Bank RO WC (70352)" },
    { value: "06090", value2: "06090", value3: "06090", value4: "06090", name: "Hanscom Federal Credit Union (06090)" },
    { value: "58809", value2: "58809", value3: "58809", value4: "58809", name: "Harbor Pointe Credit Union (58809)" },
    { value: "59358", value2: "59358", value3: "59358", value4: "59358", name: "HarborOne Bank (59358)" },
    { value: "59355", value2: "59355", value3: "59355", value4: "59358", name: "HarborOne Bank - WC (59355)" },
    { value: "11849", value2: "11849", value3: "11849", value4: "11849", name: "Harborstone Credit Union - Web (11849)" },
    { value: "05416", value2: "05416", value3: "05416", value4: "05416", name: "Hardin County Savings Bank (05416)" },
    { value: "16365", value2: "16365", value3: "16365", value4: "16365", name: "Harford Bank (16365)" },
    { value: "69688", value2: "69688", value3: "69688", value4: "69688", name: "Harford Bank Business WC (69688)" },
    { value: "01163", value2: "01163", value3: "01163", value4: "01163", name: "Harleysville Bank (01163)" },
    { value: "00822", value2: "00822", value3: "00822", value4: "00822", name: "Harmony Bank (00822)" },
    { value: "67317", value2: "67317", value3: "67317", value4: "67317", name: "Harmony Bank QBDC (67317)" },
    { value: "67250", value2: "67250", value3: "67250", value4: "67250", name: "Harmony Federal Credit Union (67250)" },
    { value: "17481", value2: "17481", value3: "17481", value4: "17481", name: "Harris County Federal CU (17481)" },
    { value: "09269", value2: "09269", value3: "09269", value4: "09269", name: "Harrison District No 2 FCU (09269)" },
    { value: "69918", value2: "69918", value3: "69918", value4: "69918", name: "Harvest Bank Kimball - MN (69918)" },
    { value: "69919", value2: "69919", value3: "69919", value4: "69918", name: "Harvest Bank Kimball MN-DC (69919)" },
    { value: "20241", value2: "20241", value3: "20241", value4: "20241", name: "Harvesters Credit Union (20241)" },
    { value: "16107", value2: "16107", value3: "16107", value4: "16107", name: "Hasler TotalFunds (16107)" },
    { value: "02771", value2: "02771", value3: "02771", value4: "02771", name: "Haven Savings Bank (02771)" },
    { value: "03794", value2: "03794", value3: "03794", value4: "03794", name: "Haverhill Bank (03794)" },
    { value: "03791", value2: "03791", value3: "03791", value4: "03794", name: "Haverhill Bank QB WC (03791)" },
    { value: "09545", value2: "09545", value3: "09545", value4: "09545", name: "Hawaii Community FCU (09545)" },
    { value: "09551", value2: "09551", value3: "09551", value4: "09545", name: "Hawaii Community FCU-DC (09551)" },
    { value: "20451", value2: "20451", value3: "20451", value4: "20451", name: "Hawaii National Bank (20451)" },
    { value: "57141", value2: "57141", value3: "57141", value4: "20451", name: "Hawaii National Bank-DC (57141)" },
    { value: "13279", value2: "13279", value3: "13279", value4: "13279", name: "Hawaii State Federal Credit Union (13279)" },
    { value: "02135", value2: "02135", value3: "02135", value4: "02135", name: "HawaiiUSA Federal Credit Union (02135)" },
    { value: "64821", value2: "64821", value3: "64821", value4: "64821", name: "Hawaiian Airlines Businesscard (64821)" },
    { value: "68097", value2: "68097", value3: "68097", value4: "68097", name: "Hawaiian Financial FCU (68097)" },
    { value: "68098", value2: "68098", value3: "68098", value4: "68098", name: "Hawaiian Financial FCU QBDC (68098)" },
    { value: "15069", value2: "15069", value3: "15069", value4: "15069", name: "Hawthorn Bank (15069)" },
    { value: "68484", value2: "68484", value3: "68484", value4: "68484", name: "Haxtun Community FCU (68484)" },
    { value: "64149", value2: "64149", value3: "64149", value4: "64149", name: "Hayward Community CU Credit Card (64149)" },
    { value: "22065", value2: "22065", value3: "22065", value4: "22065", name: "HealthNet FCU (22065)" },
    { value: "58815", value2: "58815", value3: "58815", value4: "22065", name: "HealthNet FCU_DC (58815)" },
    { value: "68937", value2: "68937", value3: "68937", value4: "68937", name: "Healthcare Plus Federal CU CC (68937)" },
    { value: "04213", value2: "04213", value3: "04213", value4: "04213", name: "Hearthside Bank (04213)" },
    { value: "51369", value2: "51369", value3: "51369", value4: "51369", name: "Heartland Bank - Nebraska (51369)" },
    { value: "11483", value2: "11483", value3: "11483", value4: "11483", name: "Heartland Bank - Ohio (11483)" },
    { value: "65184", value2: "65184", value3: "65184", value4: "65184", name: "Heartland Bank Credit Card (65184)" },
    { value: "11125", value2: "11125", value3: "11125", value4: "11125", name: "Heartland Bank and Trust Company (11125)" },
    { value: "50997", value2: "50997", value3: "50997", value4: "50997", name: "Heartland CU - Springfield IL (50997)" },
    { value: "04482", value2: "04482", value3: "04482", value4: "04482", name: "Heartland Credit Union (04482)" },
    { value: "11618", value2: "11618", value3: "11618", value4: "11618", name: "Heartland Credit Union - WI (11618)" },
    { value: "00419", value2: "00419", value3: "00419", value4: "00419", name: "Heartland National Bank (00419)" },
    { value: "00422", value2: "00422", value3: "00422", value4: "00422", name: "Heartland National Bank - DL - NEW (00422)" },
    { value: "32889", value2: "32889", value3: "32889", value4: "32889", name: "Heartland State Bank, ND (32889)" },
    { value: "24342", value2: "24342", value3: "24342", value4: "24342", name: "Hebron Savings Bank (24342)" },
    { value: "70368", value2: "70368", value3: "70368", value4: "70368", name: "Hello Credit Union CC Bus (70368)" },
    { value: "62604", value2: "62604", value3: "62604", value4: "62604", name: "Hello Credit Union Credit Card (62604)" },
    { value: "58206", value2: "58206", value3: "58206", value4: "58206", name: "Henderson State Bank Quickbooks (58206)" },
    { value: "02192", value2: "02192", value3: "02192", value4: "02192", name: "Hendricks County Bank Business (02192)" },
    { value: "06311", value2: "06311", value3: "06311", value4: "06311", name: "Hereford Texas FCU (06311)" },
    { value: "09456", value2: "09456", value3: "09456", value4: "09456", name: "Heritage Bank & Trust (09456)" },
    { value: "13560", value2: "13560", value3: "13560", value4: "13560", name: "Heritage Bank - KS (13560)" },
    { value: "11791", value2: "11791", value3: "11791", value4: "11791", name: "Heritage Bank - Olympia, WA (11791)" },
    { value: "11792", value2: "11792", value3: "11792", value4: "11792", name: "Heritage Bank - Olympia, WA - Web (11792)" },
    { value: "00830", value2: "00830", value3: "00830", value4: "00830", name: "Heritage Bank Inc. Business DC (00830)" },
    { value: "11934", value2: "11934", value3: "11934", value4: "11934", name: "Heritage Bank NA (11934)" },
    { value: "18517", value2: "18517", value3: "18517", value4: "18517", name: "Heritage Bank NW DC (18517)" },
    { value: "18610", value2: "18610", value3: "18610", value4: "18610", name: "Heritage Bank NW WC (18610)" },
    { value: "62295", value2: "62295", value3: "62295", value4: "62295", name: "Heritage Bank Nebraska (62295)" },
    { value: "62298", value2: "62298", value3: "62298", value4: "62295", name: "Heritage Bank Nebraska_DC (62298)" },
    { value: "70432", value2: "70432", value3: "70432", value4: "70432", name: "Heritage Bank of Commerce Bus DC (70432)" },
    { value: "69573", value2: "69573", value3: "69573", value4: "69573", name: "Heritage Bank of Commerce CC (69573)" },
    { value: "08486", value2: "08486", value3: "08486", value4: "08486", name: "Heritage Bank of Commerce Comm (08486)" },
    { value: "07445", value2: "07445", value3: "07445", value4: "07445", name: "Heritage Bank of Schaumburg (07445)" },
    { value: "65732", value2: "65732", value3: "65732", value4: "65732", name: "Heritage Bank of St. Tammany (65732)" },
    { value: "13227", value2: "13227", value3: "13227", value4: "13227", name: "Heritage Bank, Inc. (13227)" },
    { value: "67829", value2: "67829", value3: "67829", value4: "67829", name: "Heritage Bank, NA - MN QBDC (67829)" },
    { value: "69892", value2: "69892", value3: "69892", value4: "69892", name: "Heritage Community Bank (69892)" },
    { value: "69927", value2: "69927", value3: "69927", value4: "69927", name: "Heritage Community Bank - DC (69927)" },
    { value: "14220", value2: "14220", value3: "14220", value4: "14220", name: "Heritage Family Credit Union (14220)" },
    { value: "01624", value2: "01624", value3: "01624", value4: "01624", name: "Heritage Federal Credit Union (01624)" },
    { value: "68865", value2: "68865", value3: "68865", value4: "68865", name: "Heritage Land Bank (68865)" },
    { value: "67643", value2: "67643", value3: "67643", value4: "67643", name: "Heritage South CCU (67643)" },
    { value: "67782", value2: "67782", value3: "67782", value4: "67782", name: "Heritage Southeast Bank GA (67782)" },
    { value: "68374", value2: "68374", value3: "68374", value4: "68374", name: "Heritage USA FCU (68374)" },
    { value: "15025", value2: "15025", value3: "15025", value4: "15025", name: "Heritage Valley FCU - PA (15025)" },
    { value: "20367", value2: "20367", value3: "20367", value4: "20367", name: "Herring Bank (20367)" },
    { value: "65310", value2: "65310", value3: "65310", value4: "65310", name: "Hershey State Bank (65310)" },
    { value: "56106", value2: "56106", value3: "56106", value4: "56106", name: "Hiawatha National Bank (56106)" },
    { value: "20049", value2: "20049", value3: "20049", value4: "20049", name: "Hibernia Bank (20049)" },
    { value: "09234", value2: "09234", value3: "09234", value4: "09234", name: "High Country Bank (09234)" },
    { value: "69765", value2: "69765", value3: "69765", value4: "69765", name: "High Plains Bank (69765)" },
    { value: "20427", value2: "20427", value3: "20427", value4: "20427", name: "High Plains Bank - CO (20427)" },
    { value: "69766", value2: "69766", value3: "69766", value4: "69765", name: "High Plains Bank QBDC (69766)" },
    { value: "00420", value2: "00420", value3: "00420", value4: "00420", name: "Highland Bank (00420)" },
    { value: "02820", value2: "02820", value3: "02820", value4: "02820", name: "Highmark Federal Credit Union (02820)" },
    { value: "03913", value2: "03913", value3: "03913", value4: "03913", name: "Highpoint Community Bank-WCforQB (03913)" },
    { value: "06344", value2: "06344", value3: "06344", value4: "06344", name: "Highpoint Federal Credit Union (06344)" },
    { value: "16335", value2: "16335", value3: "16335", value4: "16335", name: "Hill Bank and Trust Co. (16335)" },
    { value: "53277", value2: "53277", value3: "53277", value4: "53277", name: "Hill Dodge Banking Com (53277)" },
    { value: "70474", value2: "70474", value3: "70474", value4: "70474", name: "Hill Dodge Banking Com-DC (70474)" },
    { value: "64029", value2: "64029", value3: "64029", value4: "64029", name: "Hillcrest Bank BeB (64029)" },
    { value: "59523", value2: "59523", value3: "59523", value4: "59523", name: "Hills Bank and Trust (59523)" },
    { value: "06836", value2: "06836", value3: "06836", value4: "59523", name: "Hills Bank and Trust-QB (06836)" },
    { value: "02442", value2: "02442", value3: "02442", value4: "02442", name: "Hillsdale County National Bank (02442)" },
    { value: "03491", value2: "03491", value3: "03491", value4: "03491", name: "Hilltop National Bank - Business (03491)" },
    { value: "05161", value2: "05161", value3: "05161", value4: "05161", name: "Hingham Inst. for Sav. WCQB (05161)" },
    { value: "03202", value2: "03202", value3: "03202", value4: "03202", name: "Hinsdale Bank & Trust Bus (03202)" },
    { value: "03843", value2: "03843", value3: "03843", value4: "03843", name: "Hinsdale Bank & Trust IBB (03843)" },
    { value: "03777", value2: "03777", value3: "03777", value4: "03777", name: "Hocking Valley Bank (03777)" },
    { value: "33093", value2: "33093", value3: "33093", value4: "33093", name: "Holcomb State Bank (33093)" },
    { value: "67995", value2: "67995", value3: "67995", value4: "67995", name: "Holladay Bank & Trust (67995)" },
    { value: "18666", value2: "18666", value3: "18666", value4: "18666", name: "Holmes County Bank (18666)" },
    { value: "57171", value2: "57171", value3: "57171", value4: "57171", name: "Holy Rosary CU (57171)" },
    { value: "06134", value2: "06134", value3: "06134", value4: "06134", name: "Holyoke CU (06134)" },
    { value: "62913", value2: "62913", value3: "62913", value4: "62913", name: "Holyoke Credit Union - WC (62913)" },
    { value: "00802", value2: "00802", value3: "00802", value4: "00802", name: "Home Bank & Trust Co (00802)" },
    { value: "07016", value2: "07016", value3: "07016", value4: "07016", name: "Home Bank Credit Card (07016)" },
    { value: "04252", value2: "04252", value3: "04252", value4: "04252", name: "Home Bank SB (04252)" },
    { value: "69385", value2: "69385", value3: "69385", value4: "04252", name: "Home Bank SB QBDC (69385)" },
    { value: "52974", value2: "52974", value3: "52974", value4: "52974", name: "Home Bank of CA (52974)" },
    { value: "03366", value2: "03366", value3: "03366", value4: "03366", name: "Home Bank, Lafayette, LA (03366)" },
    { value: "54042", value2: "54042", value3: "54042", value4: "54042", name: "Home Banking Company (54042)" },
    { value: "69496", value2: "69496", value3: "69496", value4: "69496", name: "Home Depot Comm Revolving CC EWC (69496)" },
    { value: "69495", value2: "69495", value3: "69495", value4: "69495", name: "Home Depot Consumer CC EWC (69495)" },
    { value: "49512", value2: "49512", value3: "49512", value4: "49512", name: "Home Federal Bank LA (49512)" },
    { value: "67125", value2: "67125", value3: "67125", value4: "67125", name: "Home Federal Bank LA Bus (67125)" },
    { value: "67105", value2: "67105", value3: "67105", value4: "67105", name: "Home Federal Bank LA Bus QBDC (67105)" },
    { value: "67124", value2: "67124", value3: "67124", value4: "49512", name: "Home Federal Bank LA Per QBDC (67124)" },
    { value: "11636", value2: "11636", value3: "11636", value4: "11636", name: "Home Federal Bank of Tennessee (11636)" },
    { value: "16600", value2: "16600", value3: "16600", value4: "16600", name: "Home Federal Savings Bank , MN (16600)" },
    { value: "18030", value2: "18030", value3: "18030", value4: "18030", name: "Home Loan State Bank (18030)" },
    { value: "05207", value2: "05207", value3: "05207", value4: "05207", name: "Home Loan State Bank-QB (05207)" },
    { value: "53040", value2: "53040", value3: "53040", value4: "53040", name: "Home National Bank (53040)" },
    { value: "12320", value2: "12320", value3: "12320", value4: "12320", name: "Home Savings Bank KS (12320)" },
    { value: "56196", value2: "56196", value3: "56196", value4: "56196", name: "Home Savings Norborne (56196)" },
    { value: "69305", value2: "69305", value3: "69305", value4: "69305", name: "Home Savings and Loan CO - BO (69305)" },
    { value: "13314", value2: "13314", value3: "13314", value4: "13314", name: "Home State Bank - MN (13314)" },
    { value: "57327", value2: "57327", value3: "57327", value4: "57327", name: "Home State Bank Iowa (57327)" },
    { value: "04827", value2: "04827", value3: "04827", value4: "04827", name: "Home State Bank N.A.-Crystal Lake (04827)" },
    { value: "09830", value2: "09830", value3: "09830", value4: "09830", name: "Home State Bank WC BO (09830)" },
    { value: "04175", value2: "04175", value3: "04175", value4: "04175", name: "Home Town CU Credit Card (04175)" },
    { value: "18669", value2: "18669", value3: "18669", value4: "18669", name: "HomeBank Texas (18669)" },
    { value: "57354", value2: "57354", value3: "57354", value4: "57354", name: "HomeStreet Bank - Direct Connect (57354)" },
    { value: "57105", value2: "57105", value3: "57105", value4: "57105", name: "HomeTown Bank - IB Quickbooks (57105)" },
    { value: "50139", value2: "50139", value3: "50139", value4: "50139", name: "HomeTown Bank MN (50139)" },
    { value: "03173", value2: "03173", value3: "03173", value4: "03173", name: "HomeTown Bank MN Business (03173)" },
    { value: "00674", value2: "00674", value3: "00674", value4: "00674", name: "HomeTown Bank of Alabama (00674)" },
    { value: "10222", value2: "10222", value3: "10222", value4: "10222", name: "HomeTrust Bank (10222)" },
    { value: "08318", value2: "08318", value3: "08318", value4: "08318", name: "HomeTrust Bank Business (08318)" },
    { value: "12479", value2: "12479", value3: "12479", value4: "12479", name: "Homeland FSB -  DC (12479)" },
    { value: "05756", value2: "05756", value3: "05756", value4: "05756", name: "Homeland Federal Savings Bank (05756)" },
    { value: "57117", value2: "57117", value3: "57117", value4: "57117", name: "Homestead Bank (57117)" },
    { value: "53988", value2: "53988", value3: "53988", value4: "53988", name: "Homestreet Bank- Business (53988)" },
    { value: "69314", value2: "69314", value3: "69314", value4: "06970", name: "Hometown BK of Corbin QBDC (69314)" },
    { value: "70392", value2: "70392", value3: "70392", value4: "70392", name: "Hometown Bank Bus CC (70392)" },
    { value: "09410", value2: "09410", value3: "09410", value4: "09410", name: "Hometown Bank of AL - QBDC (09410)" },
    { value: "17103", value2: "17103", value3: "17103", value4: "17103", name: "Hometown Bank of Pennsylvania (17103)" },
    { value: "06970", value2: "06970", value3: "06970", value4: "06970", name: "Hometown Bk of Corbin-WC QWKN-IB (06970)" },
    { value: "46473", value2: "46473", value3: "46473", value4: "46473", name: "Hometown CUKulm, ND (46473)" },
    { value: "57330", value2: "57330", value3: "57330", value4: "46473", name: "Hometown CUKulm,ND_DC (57330)" },
    { value: "03658", value2: "03658", value3: "03658", value4: "03658", name: "Hometown Community Banks (Devision of Morton Community Bank) (03658)" },
    { value: "57438", value2: "57438", value3: "57438", value4: "57438", name: "Hometown National Bank - La Salle (57438)" },
    { value: "01370", value2: "01370", value3: "01370", value4: "01370", name: "Honda Federal Credit Union (01370)" },
    { value: "03024", value2: "03024", value3: "03024", value4: "03024", name: "Honor Bank (03024)" },
    { value: "70023", value2: "70023", value3: "70023", value4: "70023", name: "Honor Bank-DC (70023)" },
    { value: "19770", value2: "19770", value3: "19770", value4: "19770", name: "Honor Credit Union (19770)" },
    { value: "68254", value2: "68254", value3: "68254", value4: "68254", name: "Hoosier Heartland State Bank (68254)" },
    { value: "70370", value2: "70370", value3: "70370", value4: "70370", name: "Hoosier Heartland State Bank-DC (70370)" },
    { value: "07366", value2: "07366", value3: "07366", value4: "07366", name: "Hoosier Hills CU (07366)" },
    { value: "68435", value2: "68435", value3: "68435", value4: "68435", name: "Hope Credit Union Biz (68435)" },
    { value: "68436", value2: "68436", value3: "68436", value4: "68435", name: "Hope Credit Union Biz QBDC (68436)" },
    { value: "05228", value2: "05228", value3: "05228", value4: "05228", name: "Horicon Bank (05228)" },
    { value: "07490", value2: "07490", value3: "07490", value4: "07490", name: "Horicon Bank Business (07490)" },
    { value: "11271", value2: "11271", value3: "11271", value4: "11271", name: "Horizon Bank (11271)" },
    { value: "29565", value2: "29565", value3: "29565", value4: "29565", name: "Horizon Bank NE (29565)" },
    { value: "04325", value2: "04325", value3: "04325", value4: "29565", name: "Horizon Bank NE-DC (04325)" },
    { value: "16927", value2: "16927", value3: "16927", value4: "16924", name: "Horizon Bank QB (16927)" },
    { value: "16924", value2: "16924", value3: "16924", value4: "16924", name: "Horizon Bank SSB (16924)" },
    { value: "11625", value2: "11625", value3: "11625", value4: "11625", name: "Horizon Credit Union (11625)" },
    { value: "69745", value2: "69745", value3: "69745", value4: "69745", name: "Horizon Credit Union - WC (69745)" },
    { value: "67562", value2: "67562", value3: "67562", value4: "67562", name: "Horizon Financial Bank (67562)" },
    { value: "67563", value2: "67563", value3: "67563", value4: "67562", name: "Horizon Financial Bank-DC (67563)" },
    { value: "05410", value2: "05410", value3: "05410", value4: "05410", name: "Houghton State Bank (05410)" },
    { value: "02112", value2: "02112", value3: "02112", value4: "02112", name: "Houston Federal Credit Union (02112)" },
    { value: "54534", value2: "54534", value3: "54534", value4: "54534", name: "Houston Highway CU Credit Card (54534)" },
    { value: "08198", value2: "08198", value3: "08198", value4: "08198", name: "Houston Metropolitan FCU CC (08198)" },
    { value: "01796", value2: "01796", value3: "01796", value4: "01796", name: "Houston Police FCU Credit Card (01796)" },
    { value: "01925", value2: "01925", value3: "01925", value4: "01925", name: "Houston Texas Fire Fighters FCU (01925)" },
    { value: "64368", value2: "64368", value3: "64368", value4: "64368", name: "Howard Bank Business Direct (64368)" },
    { value: "27285", value2: "27285", value3: "27285", value4: "27285", name: "Hudson River Community CU (27285)" },
    { value: "69539", value2: "69539", value3: "69539", value4: "27285", name: "Hudson River Community CU QBDC (69539)" },
    { value: "14374", value2: "14374", value3: "14374", value4: "14374", name: "Hudson Valley Credit Union (14374)" },
    { value: "18169", value2: "18169", value3: "18169", value4: "18169", name: "Hughes FCU (18169)" },
    { value: "18493", value2: "18493", value3: "18493", value4: "18493", name: "Hughes FCU DC (18493)" },
    { value: "16735", value2: "16735", value3: "16735", value4: "16735", name: "Huntington Bank Business Card (16735)" },
    { value: "16738", value2: "16738", value3: "16738", value4: "16738", name: "Huntington Bank Commercial Card (16738)" },
    { value: "16696", value2: "16696", value3: "16696", value4: "16696", name: "Huntington Bank Credit Card (16696)" },
    { value: "11025", value2: "11025", value3: "11025", value4: "11025", name: "Huntington National Bank (11025)" },
    { value: "11965", value2: "11965", value3: "11965", value4: "11965", name: "Huntington National Bank-Business (11965)" },
    { value: "11050", value2: "11050", value3: "11050", value4: "11025", name: "Huntington Web Connect (11050)" },
    { value: "13722", value2: "13722", value3: "13722", value4: "13722", name: "Huron Area Education Federal CU (13722)" },
    { value: "26502", value2: "26502", value3: "26502", value4: "26502", name: "Huron Community Bank (26502)" },
    { value: "34848", value2: "34848", value3: "34848", value4: "34848", name: "Huron Valley State Bank (34848)" },
    { value: "68152", value2: "68152", value3: "68152", value4: "68152", name: "Hustisford State Bank (68152)" },
    { value: "68153", value2: "68153", value3: "68153", value4: "68153", name: "Hustisford State Bk (68153)" },
    { value: "36621", value2: "36621", value3: "36621", value4: "36621", name: "Hyden Citizens Bank (36621)" },
    { value: "56778", value2: "56778", value3: "56778", value4: "56778", name: "Hyperion Bank (56778)" },
    { value: "69049", value2: "69049", value3: "69049", value4: "69049", name: "IAA CU QB (69049)" },
    { value: "15478", value2: "15478", value3: "15478", value4: "15478", name: "IBC Bank (15478)" },
    { value: "16683", value2: "16683", value3: "16683", value4: "16683", name: "IBEW & United Workers FCU (16683)" },
    { value: "09524", value2: "09524", value3: "09524", value4: "09524", name: "IBMSECU - biz (09524)" },
    { value: "69974", value2: "69974", value3: "69974", value4: "69974", name: "IBMSECU - biz - DC (69974)" },
    { value: "09527", value2: "09527", value3: "09527", value4: "09527", name: "IBMSECU_biz_WC (09527)" },
    { value: "16870", value2: "16870", value3: "16870", value4: "16870", name: "IC Federal Credit Union - Business (16870)" },
    { value: "69780", value2: "69780", value3: "69780", value4: "69780", name: "IDB Bank - Direct (69780)" },
    { value: "69781", value2: "69781", value3: "69781", value4: "69780", name: "IDB Bank - Direct-DC (69781)" },
    { value: "17518", value2: "17518", value3: "17518", value4: "17518", name: "IDB Bank NY (17518)" },
    { value: "56103", value2: "56103", value3: "56103", value4: "56103", name: "IH Credit Union (56103)" },
    { value: "01973", value2: "01973", value3: "01973", value4: "01973", name: "IH Mississippi Valley CU (01973)" },
    { value: "62187", value2: "62187", value3: "62187", value4: "62187", name: "IH Mississippi Valley CU - QB (62187)" },
    { value: "42258", value2: "42258", value3: "42258", value4: "42258", name: "ILWU CU (42258)" },
    { value: "62532", value2: "62532", value3: "62532", value4: "62532", name: "INB, NA - Business Banking (62532)" },
    { value: "09167", value2: "09167", value3: "09167", value4: "09167", name: "INB, NA - Direct (09167)" },
    { value: "11702", value2: "11702", value3: "11702", value4: "11702", name: "INB, NA. (11702)" },
    { value: "60639", value2: "60639", value3: "60639", value4: "60639", name: "INOVA Federal Credit Union (60639)" },
    { value: "04140", value2: "04140", value3: "04140", value4: "04140", name: "INSOUTH Bank (04140)" },
    { value: "68327", value2: "68327", value3: "68327", value4: "68327", name: "INTRUST Bank Business (68327)" },
    { value: "68328", value2: "68328", value3: "68328", value4: "68328", name: "INTRUST Bank Business Direct (68328)" },
    { value: "12285", value2: "12285", value3: "12285", value4: "12285", name: "INTRUST Bank Personal (12285)" },
    { value: "68329", value2: "68329", value3: "68329", value4: "68329", name: "INTRUST Bank Personal Direct (68329)" },
    { value: "13294", value2: "13294", value3: "13294", value4: "13294", name: "IU Credit Union (13294)" },
    { value: "13378", value2: "13378", value3: "13378", value4: "13378", name: "IU Credit Union QB (13378)" },
    { value: "70213", value2: "70213", value3: "70213", value4: "70213", name: "Icon Business Bank (70213)" },
    { value: "04994", value2: "04994", value3: "04994", value4: "04994", name: "Idaho Central Credit Union (04994)" },
    { value: "69497", value2: "69497", value3: "69497", value4: "69497", name: "Idaho Central Credit Union EWC (69497)" },
    { value: "03435", value2: "03435", value3: "03435", value4: "03435", name: "Idaho First Bank (03435)" },
    { value: "16992", value2: "16992", value3: "16992", value4: "16992", name: "Idaho Trust Bank (16992)" },
    { value: "08462", value2: "08462", value3: "08462", value4: "08462", name: "Idaho Trust Bank Business (08462)" },
    { value: "08561", value2: "08561", value3: "08561", value4: "08561", name: "Idaho Trust Bank Business Direct (08561)" },
    { value: "53589", value2: "53589", value3: "53589", value4: "53589", name: "Ideal Credit Union (53589)" },
    { value: "19314", value2: "19314", value3: "19314", value4: "19314", name: "Illiana Financial (19314)" },
    { value: "59181", value2: "59181", value3: "59181", value4: "59181", name: "Illinois Bank & Trust (59181)" },
    { value: "68902", value2: "68902", value3: "68902", value4: "68902", name: "Illinois Community Credit Union (68902)" },
    { value: "05063", value2: "05063", value3: "05063", value4: "05063", name: "Illinois Educators CU Credit Card (05063)" },
    { value: "04644", value2: "04644", value3: "04644", value4: "04644", name: "Illinois State Credit Union Online Banking (ItsMyCreditunion) (04644)" },
    { value: "01651", value2: "01651", value3: "01651", value4: "01651", name: "Illinois State Police FCU (01651)" },
    { value: "69482", value2: "69482", value3: "69482", value4: "69482", name: "Illinois State Police FCU QBDC (69482)" },
    { value: "18297", value2: "18297", value3: "18297", value4: "18297", name: "Impact Bank (18297)" },
    { value: "08333", value2: "08333", value3: "08333", value4: "08333", name: "InBank Business QBDC (08333)" },
    { value: "06397", value2: "06397", value3: "06397", value4: "06397", name: "InFirst Bank (06397)" },
    { value: "68794", value2: "68794", value3: "68794", value4: "68794", name: "InFirst Bank - QBDC (68794)" },
    { value: "18076", value2: "18076", value3: "18076", value4: "18076", name: "InRoads Credit Union - QB (18076)" },
    { value: "12584", value2: "12584", value3: "12584", value4: "12584", name: "InTouch Credit Union (12584)" },
    { value: "69609", value2: "69609", value3: "69609", value4: "69609", name: "InTouch Credit Union CC (69609)" },
    { value: "20304", value2: "20304", value3: "20304", value4: "20304", name: "Incommons Bank (20304)" },
    { value: "67561", value2: "67561", value3: "67561", value4: "67561", name: "IncredibleBank (67561)" },
    { value: "51774", value2: "51774", value3: "51774", value4: "51774", name: "Independence Bank (51774)" },
    { value: "56484", value2: "56484", value3: "56484", value4: "56484", name: "Independence Bank RI - DC (56484)" },
    { value: "00162", value2: "00162", value3: "00162", value4: "00162", name: "Independence Bank RI - WC (00162)" },
    { value: "04488", value2: "04488", value3: "04488", value4: "04488", name: "Independence Bank of Kentucky (04488)" },
    { value: "09659", value2: "09659", value3: "09659", value4: "09659", name: "Independence Bank of Kentucky DC (09659)" },
    { value: "11757", value2: "11757", value3: "11757", value4: "11757", name: "Independent Bank (11757)" },
    { value: "17626", value2: "17626", value3: "17626", value4: "17626", name: "Independent Bank - DC (17626)" },
    { value: "68324", value2: "68324", value3: "68324", value4: "68324", name: "Independent Bank - TN (68324)" },
    { value: "06765", value2: "06765", value3: "06765", value4: "06765", name: "Independent Bank ONE Wallet WC (06765)" },
    { value: "68265", value2: "68265", value3: "68265", value4: "68265", name: "Independent Bank One Wallet DC (68265)" },
    { value: "68243", value2: "68243", value3: "68243", value4: "68243", name: "Independent Bank TreasuryOne DC (68243)" },
    { value: "19212", value2: "19212", value3: "19212", value4: "19212", name: "Independent Bank TreasuryOne WC (19212)" },
    { value: "05560", value2: "05560", value3: "05560", value4: "05560", name: "Independent Bank, TN-Business QB (05560)" },
    { value: "52053", value2: "52053", value3: "52053", value4: "52053", name: "Independent Bank, TN-CashMgmt QB (52053)" },
    { value: "67289", value2: "67289", value3: "67289", value4: "67289", name: "Indiana Members CU - Web Connect (67289)" },
    { value: "67290", value2: "67290", value3: "67290", value4: "67289", name: "Indiana Members CU -Direct Connect (67290)" },
    { value: "69993", value2: "69993", value3: "69993", value4: "69993", name: "Industrial Bank Business QBDC (69993)" },
    { value: "62661", value2: "62661", value3: "62661", value4: "62661", name: "Industrial Bank-Business (62661)" },
    { value: "64527", value2: "64527", value3: "64527", value4: "64527", name: "Industrial Credit Union QB WC (64527)" },
    { value: "19683", value2: "19683", value3: "19683", value4: "19683", name: "Industry State Bank (19683)" },
    { value: "04712", value2: "04712", value3: "04712", value4: "04712", name: "Infinity Bank (04712)" },
    { value: "08690", value2: "08690", value3: "08690", value4: "08690", name: "Infinity Bank Business DC (08690)" },
    { value: "51042", value2: "51042", value3: "51042", value4: "51042", name: "Infinity Credit Union (51042)" },
    { value: "57750", value2: "57750", value3: "57750", value4: "57750", name: "Infinity FCU Credit Card (57750)" },
    { value: "53124", value2: "53124", value3: "53124", value4: "53124", name: "Inland Valley FCU (53124)" },
    { value: "64593", value2: "64593", value3: "64593", value4: "64593", name: "Innovations FCU (64593)" },
    { value: "34755", value2: "34755", value3: "34755", value4: "34755", name: "InsBank (34755)" },
    { value: "69676", value2: "69676", value3: "69676", value4: "69676", name: "InsBank QBDC (69676)" },
    { value: "09815", value2: "09815", value3: "09815", value4: "09815", name: "Insight Credit Union (09815)" },
    { value: "59121", value2: "59121", value3: "59121", value4: "59121", name: "Insight Credit Union Credit Card (59121)" },
    { value: "01571", value2: "01571", value3: "01571", value4: "01571", name: "Inspire FCU (01571)" },
    { value: "59466", value2: "59466", value3: "59466", value4: "59466", name: "Institution for Savings (59466)" },
    { value: "59469", value2: "59469", value3: "59469", value4: "59466", name: "Institution for Savings-DL (59469)" },
    { value: "04845", value2: "04845", value3: "04845", value4: "04845", name: "Integrity Bank & Trust (04845)" },
    { value: "68935", value2: "68935", value3: "68935", value4: "68935", name: "Integrity Bank for Bus BO DC (68935)" },
    { value: "68875", value2: "68875", value3: "68875", value4: "68875", name: "Integrity Bank for Business BO (68875)" },
    { value: "28968", value2: "28968", value3: "28968", value4: "28968", name: "IntegrityBank (28968)" },
    { value: "69547", value2: "69547", value3: "69547", value4: "69547", name: "Integro Bank (69547)" },
    { value: "69548", value2: "69548", value3: "69548", value4: "69548", name: "Integro Bank - DC (69548)" },
    { value: "69543", value2: "69543", value3: "69543", value4: "69543", name: "Integro Bank Business (69543)" },
    { value: "69544", value2: "69544", value3: "69544", value4: "69544", name: "Integro Bank DC Bus (69544)" },
    { value: "16144", value2: "16144", value3: "16144", value4: "16144", name: "InterBank Cash Management (16144)" },
    { value: "62301", value2: "62301", value3: "62301", value4: "62301", name: "InterBank Retail (62301)" },
    { value: "18277", value2: "18277", value3: "18277", value4: "18277", name: "Interaudi Bank (18277)" },
    { value: "30810", value2: "30810", value3: "30810", value4: "30810", name: "Intercity State Bank (30810)" },
    { value: "07391", value2: "07391", value3: "07391", value4: "07391", name: "Intercredit Bank, N.A. (07391)" },
    { value: "69880", value2: "69880", value3: "69880", value4: "69880", name: "Intercredit Bank, N.A. QB DC (69880)" },
    { value: "49692", value2: "49692", value3: "49692", value4: "49692", name: "Interior Federal Credit Union - QB (49692)" },
    { value: "07979", value2: "07979", value3: "07979", value4: "07979", name: "International Bank Business Web (07979)" },
    { value: "00218", value2: "00218", value3: "00218", value4: "00218", name: "International Bank of Amherst (00218)" },
    { value: "00233", value2: "00233", value3: "00233", value4: "00233", name: "International Bank of Amherst DC (00233)" },
    { value: "70362", value2: "70362", value3: "70362", value4: "70362", name: "International Finance Bank FL (70362)" },
    { value: "70361", value2: "70361", value3: "70361", value4: "70361", name: "International Finance Bank FL WC (70361)" },
    { value: "53118", value2: "53118", value3: "53118", value4: "53118", name: "Interra CU (53118)" },
    { value: "09173", value2: "09173", value3: "09173", value4: "09173", name: "Interstate Bank, ssb (09173)" },
    { value: "63837", value2: "63837", value3: "63837", value4: "63837", name: "Interstate C.U. Credit Card (63837)" },
    { value: "08521", value2: "08521", value3: "08521", value4: "08521", name: "Intl Bank of Commerce - Business (08521)" },
    { value: "12587", value2: "12587", value3: "12587", value4: "12587", name: "Intouch Credit Union QBDC (12587)" },
    { value: "17382", value2: "17382", value3: "17382", value4: "17382", name: "Intracoastal Bank-Per Banking (17382)" },
    { value: "65514", value2: "65514", value3: "65514", value4: "65514", name: "Intrepid Credit Union (65514)" },
    { value: "56682", value2: "56682", value3: "56682", value4: "56682", name: "Intuit Bank (56682)" },
    { value: "08744", value2: "08744", value3: "08744", value4: "08744", name: "Intuit Bank Quicken and QB Testing (08744)" },
    { value: "12174", value2: "12174", value3: "12174", value4: "12174", name: "Investar Bank Personal-SM Bus (12174)" },
    { value: "70042", value2: "70042", value3: "70042", value4: "70042", name: "Investar Commercial DC (70042)" },
    { value: "12269", value2: "12269", value3: "12269", value4: "12269", name: "Investar Commercial WC (12269)" },
    { value: "10846", value2: "10846", value3: "10846", value4: "10846", name: "Inwood National Bank (10846)" },
    { value: "64953", value2: "64953", value3: "64953", value4: "10846", name: "Inwood National Bank QBDC (64953)" },
    { value: "67520", value2: "67520", value3: "67520", value4: "67520", name: "Ion Bank (67520)" },
    { value: "63489", value2: "63489", value3: "63489", value4: "67520", name: "Ion Bank Web Connect (63489)" },
    { value: "27375", value2: "27375", value3: "27375", value4: "27375", name: "Iowa Falls State Bank (27375)" },
    { value: "08469", value2: "08469", value3: "08469", value4: "08469", name: "Iowa Savings Bank (08469)" },
    { value: "67898", value2: "67898", value3: "67898", value4: "67898", name: "Iowa Savings Bank BIZ (67898)" },
    { value: "67899", value2: "67899", value3: "67899", value4: "67898", name: "Iowa Savings Bank BIZ QBDC (67899)" },
    { value: "67897", value2: "67897", value3: "67897", value4: "08469", name: "Iowa Savings Bank- IA (67897)" },
    { value: "57021", value2: "57021", value3: "57021", value4: "57021", name: "Iowa State Bank & Trust Company (57021)" },
    { value: "12431", value2: "12431", value3: "12431", value4: "12431", name: "Iowa State Bank - Business (12431)" },
    { value: "08660", value2: "08660", value3: "08660", value4: "08663", name: "Iowa State Bank - Consumer (08660)" },
    { value: "51120", value2: "51120", value3: "51120", value4: "51120", name: "Iowa State Bank - Des Moines (51120)" },
    { value: "15915", value2: "15915", value3: "15915", value4: "15915", name: "Iowa State Bank - IA (15915)" },
    { value: "06176", value2: "06176", value3: "06176", value4: "06176", name: "Iowa State Bank - IA Business (06176)" },
    { value: "12494", value2: "12494", value3: "12494", value4: "12494", name: "Iowa State Bank Business DC (12494)" },
    { value: "12434", value2: "12434", value3: "12434", value4: "12434", name: "Iowa State Bank Business QBDC (12434)" },
    { value: "12500", value2: "12500", value3: "12500", value4: "12500", name: "Iowa State Bank Business WC (12500)" },
    { value: "08663", value2: "08663", value3: "08663", value4: "08663", name: "Iowa State Bank Consumer QBDC (08663)" },
    { value: "12491", value2: "12491", value3: "12491", value4: "12491", name: "Iowa State Bank RO DC (12491)" },
    { value: "30270", value2: "30270", value3: "30270", value4: "30270", name: "Iowa State Savings Bank Creston (30270)" },
    { value: "68420", value2: "68420", value3: "68420", value4: "68420", name: "Iowa Trust Savings Bank IA (68420)" },
    { value: "68421", value2: "68421", value3: "68421", value4: "68421", name: "Iowa Trust Savings Bank IA Bus (68421)" },
    { value: "28299", value2: "28299", value3: "28299", value4: "28299", name: "Ipava State Bank (28299)" },
    { value: "68225", value2: "68225", value3: "68225", value4: "68225", name: "Ireland Bank Business (68225)" },
    { value: "26622", value2: "26622", value3: "26622", value4: "26622", name: "Iron County Community CU (26622)" },
    { value: "67548", value2: "67548", value3: "67548", value4: "67548", name: "Irving City Employees FCU (67548)" },
    { value: "04829", value2: "04829", value3: "04829", value4: "04829", name: "Isabella Bank & Trust (04829)" },
    { value: "17574", value2: "17574", value3: "17574", value4: "17574", name: "Isabella Community Credit Union (17574)" },
    { value: "63228", value2: "63228", value3: "63228", value4: "63228", name: "Island Federal Credit Union (63228)" },
    { value: "63231", value2: "63231", value3: "63231", value4: "63231", name: "Island Federal Credit Union - QB (63231)" },
    { value: "10956", value2: "10956", value3: "10956", value4: "10956", name: "Itasca Bank & Trust Co. (10956)" },
    { value: "52167", value2: "52167", value3: "52167", value4: "52167", name: "Iuka State Bank (52167)" },
    { value: "69806", value2: "69806", value3: "69806", value4: "69806", name: "Ixonia Bank Business DC (69806)" },
    { value: "69731", value2: "69731", value3: "69731", value4: "69731", name: "Ixonia Bank Business WC (69731)" },
    { value: "69949", value2: "69949", value3: "69949", value4: "69949", name: "Ixonia Bank Retail DC (69949)" },
    { value: "68975", value2: "68975", value3: "68975", value4: "68975", name: "J.P. Morgan Commercial Card (68975)" },
    { value: "17163", value2: "17163", value3: "17163", value4: "17163", name: "JD Bank - Personal Banking (17163)" },
    { value: "04391", value2: "04391", value3: "04391", value4: "04391", name: "JD Bank Business (04391)" },
    { value: "67671", value2: "67671", value3: "67671", value4: "19236", name: "JP Stone Community Bank - QBDC (67671)" },
    { value: "16497", value2: "16497", value3: "16497", value4: "16497", name: "Jacksboro National Bank (16497)" },
    { value: "08675", value2: "08675", value3: "08675", value4: "08675", name: "Jackson County Bank- Business (08675)" },
    { value: "08852", value2: "08852", value3: "08852", value4: "08852", name: "Jackson County Bank-Bus QB DC (08852)" },
    { value: "70091", value2: "70091", value3: "70091", value4: "70091", name: "Jackson Parish Bank (70091)" },
    { value: "07958", value2: "07958", value3: "07958", value4: "07958", name: "Jacksonville Firemen's CU (07958)" },
    { value: "19236", value2: "19236", value3: "19236", value4: "19236", name: "James Polk Stone Community Bank (19236)" },
    { value: "51336", value2: "51336", value3: "51336", value4: "51336", name: "Janesville St. Bank (51336)" },
    { value: "08069", value2: "08069", value3: "08069", value4: "08069", name: "Jarrettsville Federal (08069)" },
    { value: "01721", value2: "01721", value3: "01721", value4: "01721", name: "Jeanne D' Arc Credit Union (01721)" },
    { value: "68689", value2: "68689", value3: "68689", value4: "68689", name: "Jeanne D'Arc Credit Union CC (68689)" },
    { value: "70302", value2: "70302", value3: "70302", value4: "70302", name: "Jeff Bank BO (70302)" },
    { value: "70303", value2: "70303", value3: "70303", value4: "70302", name: "Jeff Bank BODC (70303)" },
    { value: "70301", value2: "70301", value3: "70301", value4: "70301", name: "Jeff Bank RO (70301)" },
    { value: "70304", value2: "70304", value3: "70304", value4: "70301", name: "Jeff Bank RODC (70304)" },
    { value: "10343", value2: "10343", value3: "10343", value4: "10343", name: "Jefferson Bank (10343)" },
    { value: "70147", value2: "70147", value3: "70147", value4: "70147", name: "Jefferson Bank - Bus (70147)" },
    { value: "13677", value2: "13677", value3: "13677", value4: "13677", name: "Jefferson Bank - Personal (13677)" },
    { value: "70385", value2: "70385", value3: "70385", value4: "70385", name: "Jefferson Community Fed CU (70385)" },
    { value: "16387", value2: "16387", value3: "16387", value4: "16387", name: "Jefferson Financial Credit Union (16387)" },
    { value: "05759", value2: "05759", value3: "05759", value4: "05759", name: "Jefferson Security Bank (05759)" },
    { value: "05771", value2: "05771", value3: "05771", value4: "05759", name: "Jefferson Security Bank-WC (05771)" },
    { value: "01454", value2: "01454", value3: "01454", value4: "01454", name: "Jersey Shore State Bank (01454)" },
    { value: "68269", value2: "68269", value3: "68269", value4: "68269", name: "Jersey State Bank Retail (68269)" },
    { value: "64848", value2: "64848", value3: "64848", value4: "64848", name: "JetBlue Business Card (64848)" },
    { value: "05129", value2: "05129", value3: "05129", value4: "05129", name: "Jewett City Savings Bank (05129)" },
    { value: "63495", value2: "63495", value3: "63495", value4: "63495", name: "Jewett City Savings Bank - Bus (63495)" },
    { value: "12044", value2: "12044", value3: "12044", value4: "12044", name: "Jim Thorpe Neighborhood Bank (12044)" },
    { value: "12047", value2: "12047", value3: "12047", value4: "12047", name: "Jim Thorpe Neighborhood Bank_QBWC (12047)" },
    { value: "64971", value2: "64971", value3: "64971", value4: "64971", name: "John Marshall BK BUS (64971)" },
    { value: "22506", value2: "22506", value3: "22506", value4: "22506", name: "Johns Hopkins FCU (22506)" },
    { value: "09477", value2: "09477", value3: "09477", value4: "09477", name: "Johnson City Bank (09477)" },
    { value: "67647", value2: "67647", value3: "67647", value4: "67647", name: "Johnson Financial Group (67647)" },
    { value: "70190", value2: "70190", value3: "70190", value4: "70190", name: "Johnson State Bank WC QB (70190)" },
    { value: "05675", value2: "05675", value3: "05675", value4: "05675", name: "Jonah Bank Credit Card (05675)" },
    { value: "13566", value2: "13566", value3: "13566", value4: "13566", name: "Jonah Bank of Wyoming (13566)" },
    { value: "62700", value2: "62700", value3: "62700", value4: "62700", name: "Jonesboro State Bank (62700)" },
    { value: "62901", value2: "62901", value3: "62901", value4: "62700", name: "Jonesboro State Bank-DC (62901)" },
    { value: "35184", value2: "35184", value3: "35184", value4: "35184", name: "Jonesburg State Bank (35184)" },
    { value: "70069", value2: "70069", value3: "70069", value4: "70069", name: "Jonesburg State Bank-Commercial (70069)" },
    { value: "15327", value2: "15327", value3: "15327", value4: "15327", name: "Jonestown Bank & Trust Co. (15327)" },
    { value: "18052", value2: "18052", value3: "18052", value4: "18052", name: "Jordan FCU (18052)" },
    { value: "05754", value2: "05754", value3: "05754", value4: "05754", name: "Journey Bank (05754)" },
    { value: "40596", value2: "40596", value3: "40596", value4: "40596", name: "Journey Federal Credit Union (40596)" },
    { value: "06961", value2: "06961", value3: "06961", value4: "06961", name: "Jovia BB WCQB (06961)" },
    { value: "59082", value2: "59082", value3: "59082", value4: "59082", name: "Junction National Bank (59082)" },
    { value: "09301", value2: "09301", value3: "09301", value4: "09301", name: "Juniper BusinessCard (09301)" },
    { value: "08150", value2: "08150", value3: "08150", value4: "08150", name: "KBR Heritage FCU Credit Card (08150)" },
    { value: "70526", value2: "70526", value3: "70526", value4: "70526", name: "KEB Hana Bank USA (70526)" },
    { value: "70527", value2: "70527", value3: "70527", value4: "70527", name: "KEB Hana Bank USA-DC (70527)" },
    { value: "68838", value2: "68838", value3: "68838", value4: "68838", name: "KEMBA Financial CU (68838)" },
    { value: "67260", value2: "67260", value3: "67260", value4: "67260", name: "KO'OLAU FCU - NEW (67260)" },
    { value: "14310", value2: "14310", value3: "14310", value4: "14310", name: "KS Bank (14310)" },
    { value: "00428", value2: "00428", value3: "00428", value4: "00428", name: "KS StateBank (00428)" },
    { value: "00425", value2: "00425", value3: "00425", value4: "00425", name: "KS StateBank - DL - NEW (00425)" },
    { value: "67991", value2: "67991", value3: "67991", value4: "67991", name: "KVSB Business Banking (67991)" },
    { value: "67992", value2: "67992", value3: "67992", value4: "67991", name: "KVSB Business Banking QB (67992)" },
    { value: "67989", value2: "67989", value3: "67989", value4: "67989", name: "KVSB Digital Banking (67989)" },
    { value: "67990", value2: "67990", value3: "67990", value4: "67989", name: "KVSB Digital Banking QB (67990)" },
    { value: "57717", value2: "57717", value3: "57717", value4: "57717", name: "Kalsee Credit Union (57717)" },
    { value: "07601", value2: "07601", value3: "07601", value4: "07601", name: "Kansas State Bank (07601)" },
    { value: "18213", value2: "18213", value3: "18213", value4: "18213", name: "KansasLand Bank (18213)" },
    { value: "56070", value2: "56070", value3: "56070", value4: "56070", name: "Kanza Bank (56070)" },
    { value: "69309", value2: "69309", value3: "69309", value4: "69309", name: "Kanza Bank WC Bus (69309)" },
    { value: "68642", value2: "68642", value3: "68642", value4: "68642", name: "Karnes County National Bank RO (68642)" },
    { value: "03955", value2: "03955", value3: "03955", value4: "03955", name: "Katahdin Trust Company (03955)" },
    { value: "70073", value2: "70073", value3: "70073", value4: "70073", name: "Kauai FCU (70073)" },
    { value: "70453", value2: "70453", value3: "70453", value4: "70453", name: "Kauai FCU Bus CC (70453)" },
    { value: "01621", value2: "01621", value3: "01621", value4: "01621", name: "Kearny Bank (01621)" },
    { value: "59178", value2: "59178", value3: "59178", value4: "59178", name: "Kearny Bank-DC (59178)" },
    { value: "05216", value2: "05216", value3: "05216", value4: "05216", name: "Keen Bank, National Association (05216)" },
    { value: "00063", value2: "00063", value3: "00063", value4: "00063", name: "Keesler Federal Credit Union (00063)" },
    { value: "07411", value2: "07411", value3: "07411", value4: "07411", name: "Kekaha Federal Credit Union (07411)" },
    { value: "57756", value2: "57756", value3: "57756", value4: "57756", name: "Kellogg Community Federal CU (57756)" },
    { value: "63009", value2: "63009", value3: "63009", value4: "63009", name: "Kemba Credit Union (63009)" },
    { value: "67824", value2: "67824", value3: "67824", value4: "67824", name: "Kendall Bank (67824)" },
    { value: "00342", value2: "00342", value3: "00342", value4: "00342", name: "Kennebec Savings Bank (00342)" },
    { value: "00158", value2: "00158", value3: "00158", value4: "00158", name: "Kennebec Savings Credit Card (00158)" },
    { value: "00899", value2: "00899", value3: "00899", value4: "00899", name: "Kennebunk Savings Cash Mgmt (00899)" },
    { value: "00893", value2: "00893", value3: "00893", value4: "00893", name: "Kennebunk Savings Direct Connect (00893)" },
    { value: "10938", value2: "10938", value3: "10938", value4: "10938", name: "Kennebunk Savings Web Connect (10938)" },
    { value: "70014", value2: "70014", value3: "70014", value4: "70014", name: "Kennett Trust Bank (70014)" },
    { value: "70386", value2: "70386", value3: "70386", value4: "70386", name: "Kennett Trust Bank - DC (70386)" },
    { value: "50526", value2: "50526", value3: "50526", value4: "50526", name: "Kenowa Community FCU (50526)" },
    { value: "06395", value2: "06395", value3: "06395", value4: "06395", name: "Kensington Bank (06395)" },
    { value: "62505", value2: "62505", value3: "62505", value4: "62505", name: "Kentucky Bank Credit Card (62505)" },
    { value: "23538", value2: "23538", value3: "23538", value4: "23538", name: "Kentucky Farmers Bank (23538)" },
    { value: "67234", value2: "67234", value3: "67234", value4: "23538", name: "Kentucky Farmers Bank DC (67234)" },
    { value: "57699", value2: "57699", value3: "57699", value4: "57699", name: "Kerr County Federal Credit Union (57699)" },
    { value: "05901", value2: "05901", value3: "05901", value4: "05901", name: "Key (05901)" },
    { value: "09083", value2: "09083", value3: "09083", value4: "09083", name: "Key Community Bank - MN (09083)" },
    { value: "09265", value2: "09265", value3: "09265", value4: "09265", name: "KeyBank - KTT (09265)" },
    { value: "12913", value2: "12913", value3: "12913", value4: "12913", name: "KeyBank-Key Business Online (12913)" },
    { value: "15739", value2: "15739", value3: "15739", value4: "15739", name: "KeyPoint Credit Union - WC (15739)" },
    { value: "67086", value2: "67086", value3: "67086", value4: "67086", name: "Keys Federal Credit Union (67086)" },
    { value: "67087", value2: "67087", value3: "67087", value4: "67087", name: "Keys Federal Credit Union - QBDC (67087)" },
    { value: "08594", value2: "08594", value3: "08594", value4: "08594", name: "Keystone Bank (08594)" },
    { value: "08636", value2: "08636", value3: "08636", value4: "08594", name: "Keystone Bank DC (08636)" },
    { value: "01866", value2: "01866", value3: "01866", value4: "01866", name: "Keystone Savings Bank (01866)" },
    { value: "64551", value2: "64551", value3: "64551", value4: "64551", name: "Keystone Savings Bank - BO (64551)" },
    { value: "02792", value2: "02792", value3: "02792", value4: "02792", name: "Keystone United Methodist FCU CC (02792)" },
    { value: "68565", value2: "68565", value3: "68565", value4: "68565", name: "Killbuck Savings Bank-KillbuckOH (68565)" },
    { value: "13210", value2: "13210", value3: "13210", value4: "13210", name: "Kinecta FCU - Bus (13210)" },
    { value: "09037", value2: "09037", value3: "09037", value4: "09037", name: "Kinecta Federal Credit Union (09037)" },
    { value: "00191", value2: "00191", value3: "00191", value4: "00191", name: "Kinetic Credit Union- QuickBooks (00191)" },
    { value: "53913", value2: "53913", value3: "53913", value4: "53913", name: "Kingston National Bank - OH (53913)" },
    { value: "15393", value2: "15393", value3: "15393", value4: "15393", name: "Kirkpatrick Bank (15393)" },
    { value: "63846", value2: "63846", value3: "63846", value4: "63846", name: "Kirkpatrick Bank Business (63846)" },
    { value: "70166", value2: "70166", value3: "70166", value4: "70166", name: "Kirkwood Bank & Trust BO (70166)" },
    { value: "69187", value2: "69187", value3: "69187", value4: "69187", name: "Kirkwood Bank & Trust BO DC (69187)" },
    { value: "70162", value2: "70162", value3: "70162", value4: "70162", name: "Kirkwood Bank & Trust RO DC (70162)" },
    { value: "64797", value2: "64797", value3: "64797", value4: "64797", name: "Kirkwood Bank and Trust RO (64797)" },
    { value: "53886", value2: "53886", value3: "53886", value4: "53886", name: "Kirkwood Bank of Nevada (53886)" },
    { value: "06681", value2: "06681", value3: "06681", value4: "06681", name: "Kish Bank (06681)" },
    { value: "12944", value2: "12944", value3: "12944", value4: "06681", name: "Kish Bank - QBDC (12944)" },
    { value: "10418", value2: "10418", value3: "10418", value4: "10418", name: "Kitsap Bank (10418)" },
    { value: "01905", value2: "01905", value3: "01905", value4: "01905", name: "Kitsap Credit Union (01905)" },
    { value: "69905", value2: "69905", value3: "69905", value4: "69905", name: "Kitsap Credit Union Business DC (69905)" },
    { value: "65109", value2: "65109", value3: "65109", value4: "65109", name: "Kitsap Credit Union- QB (65109)" },
    { value: "14325", value2: "14325", value3: "14325", value4: "14325", name: "Kleberg First National Bank (14325)" },
    { value: "57864", value2: "57864", value3: "57864", value4: "57864", name: "Knoxville TVA Emp CU (57864)" },
    { value: "57867", value2: "57867", value3: "57867", value4: "57864", name: "Knoxville TVA Emp CU QB (57867)" },
    { value: "00760", value2: "00760", value3: "00760", value4: "00760", name: "Ko'olau FCU WC (00760)" },
    { value: "18423", value2: "18423", value3: "18423", value4: "18423", name: "Kohler Credit Union (18423)" },
    { value: "65370", value2: "65370", value3: "65370", value4: "65370", name: "Kyang FCU Credit Card (65370)" },
    { value: "02443", value2: "02443", value3: "02443", value4: "02443", name: "L&N Federal Credit Union (02443)" },
    { value: "19884", value2: "19884", value3: "19884", value4: "19884", name: "L&N Federal Credit Union - WC QB (19884)" },
    { value: "18885", value2: "18885", value3: "18885", value4: "18885", name: "LAFIRE CM (18885)" },
    { value: "65325", value2: "65325", value3: "65325", value4: "65325", name: "LCNB (65325)" },
    { value: "65328", value2: "65328", value3: "65328", value4: "65325", name: "LCNB - DL  -NEW (65328)" },
    { value: "00309", value2: "00309", value3: "00309", value4: "00309", name: "LCRA Credit Union (00309)" },
    { value: "02123", value2: "02123", value3: "02123", value4: "02123", name: "LGE Community Credit Union (02123)" },
    { value: "08054", value2: "08054", value3: "08054", value4: "08054", name: "LINKBANK (08054)" },
    { value: "69699", value2: "69699", value3: "69699", value4: "08054", name: "LINKBANK-DC (69699)" },
    { value: "69700", value2: "69700", value3: "69700", value4: "69700", name: "LINKBANK-Treasury Management (69700)" },
    { value: "69701", value2: "69701", value3: "69701", value4: "69700", name: "LINKBANK-Treasury Management-DC (69701)" },
    { value: "32904", value2: "32904", value3: "32904", value4: "32904", name: "LNB Community Bank (32904)" },
    { value: "04557", value2: "04557", value3: "04557", value4: "04557", name: "LOC Federal Credit Union (04557)" },
    { value: "00516", value2: "00516", value3: "00516", value4: "00516", name: "LOCAL 1360 Federal Credit Union (00516)" },
    { value: "68108", value2: "68108", value3: "68108", value4: "68108", name: "LUSO American Credit Union (68108)" },
    { value: "03763", value2: "03763", value3: "03763", value4: "03763", name: "La Cap FCU (03763)" },
    { value: "44646", value2: "44646", value3: "44646", value4: "44646", name: "La Joya Area FCU (44646)" },
    { value: "62199", value2: "62199", value3: "62199", value4: "62199", name: "LaSalle State Bank (62199)" },
    { value: "23547", value2: "23547", value3: "23547", value4: "23547", name: "Labette Bank (23547)" },
    { value: "16710", value2: "16710", value3: "16710", value4: "16710", name: "Lafayette Federal Credit Union (16710)" },
    { value: "14694", value2: "14694", value3: "14694", value4: "14694", name: "Lafayette State Bank (14694)" },
    { value: "67783", value2: "67783", value3: "67783", value4: "67783", name: "Lake Central Bank (67783)" },
    { value: "00669", value2: "00669", value3: "00669", value4: "00669", name: "Lake City Bank (00669)" },
    { value: "16575", value2: "16575", value3: "16575", value4: "16575", name: "Lake City Federal Bank (16575)" },
    { value: "56565", value2: "56565", value3: "56565", value4: "56565", name: "Lake Community Bank - MN (56565)" },
    { value: "00311", value2: "00311", value3: "00311", value4: "00311", name: "Lake Community Bank MN QBWC (00311)" },
    { value: "01474", value2: "01474", value3: "01474", value4: "01474", name: "Lake Elmo Bank (01474)" },
    { value: "03217", value2: "03217", value3: "03217", value4: "03217", name: "Lake Forest Bank & Trust Bus (03217)" },
    { value: "03855", value2: "03855", value3: "03855", value4: "03855", name: "Lake Forest Bank & Trust, N.A. IBB (03855)" },
    { value: "10635", value2: "10635", value3: "10635", value4: "10635", name: "Lake Michigan Credit Union (10635)" },
    { value: "07430", value2: "07430", value3: "07430", value4: "07430", name: "Lake Michigan Credit Union-MyBiz (07430)" },
    { value: "12249", value2: "12249", value3: "12249", value4: "12249", name: "Lake Region Bank (12249)" },
    { value: "12690", value2: "12690", value3: "12690", value4: "12690", name: "Lake Ridge Bank (12690)" },
    { value: "70247", value2: "70247", value3: "70247", value4: "70247", name: "Lake Ridge Bank CC (70247)" },
    { value: "52467", value2: "52467", value3: "52467", value4: "52467", name: "Lake Shore Savings Bank-NY (52467)" },
    { value: "68499", value2: "68499", value3: "68499", value4: "68499", name: "Lake Shore Savings NY QBDC (68499)" },
    { value: "08834", value2: "08834", value3: "08834", value4: "08834", name: "Lake Trust C.U. Credit Card (08834)" },
    { value: "09837", value2: "09837", value3: "09837", value4: "09837", name: "Lake Trust CU (09837)" },
    { value: "17188", value2: "17188", value3: "17188", value4: "17188", name: "Lake Trust CU Business (17188)" },
    { value: "70397", value2: "70397", value3: "70397", value4: "70397", name: "Lake Trust CU Business CC (70397)" },
    { value: "15355", value2: "15355", value3: "15355", value4: "15355", name: "Lake Trust Credit Union- W.C. QBO (15355)" },
    { value: "07481", value2: "07481", value3: "07481", value4: "07481", name: "Lake-Osceola State Bank (07481)" },
    { value: "04335", value2: "04335", value3: "04335", value4: "04335", name: "Lakeland Bank - Consumer (04335)" },
    { value: "18499", value2: "18499", value3: "18499", value4: "18499", name: "Lakeland Bank - Treasury (18499)" },
    { value: "69650", value2: "69650", value3: "69650", value4: "69650", name: "Lakes Federal CU (69650)" },
    { value: "10395", value2: "10395", value3: "10395", value4: "10395", name: "Lakeside Bank (10395)" },
    { value: "50262", value2: "50262", value3: "50262", value4: "50262", name: "Lakeside Bank LA (50262)" },
    { value: "69775", value2: "69775", value3: "69775", value4: "10395", name: "Lakeside Bank QB DC (69775)" },
    { value: "04505", value2: "04505", value3: "04505", value4: "04505", name: "Lakeside National Bank - Texas (04505)" },
    { value: "11079", value2: "11079", value3: "11079", value4: "11079", name: "Lakeview Bank (11079)" },
    { value: "10514", value2: "10514", value3: "10514", value4: "10514", name: "Lamar Bank and Trust Company (10514)" },
    { value: "02237", value2: "02237", value3: "02237", value4: "02237", name: "Lamar National Bank - Retail (02237)" },
    { value: "67075", value2: "67075", value3: "67075", value4: "67075", name: "Lamar National Bank BO (67075)" },
    { value: "03445", value2: "03445", value3: "03445", value4: "03445", name: "Lampco Federal Credit Union (03445)" },
    { value: "64830", value2: "64830", value3: "64830", value4: "64830", name: "Lancaster Red Rose Credit Card (64830)" },
    { value: "04388", value2: "04388", value3: "04388", value4: "04388", name: "Land of Lincoln CU Credit Card (04388)" },
    { value: "49578", value2: "49578", value3: "49578", value4: "49578", name: "Land of Lincoln Credit Union (49578)" },
    { value: "68489", value2: "68489", value3: "68489", value4: "68489", name: "Land of Lincoln QB (68489)" },
    { value: "02114", value2: "02114", value3: "02114", value4: "02114", name: "Landings Credit Union (02114)" },
    { value: "05413", value2: "05413", value3: "05413", value4: "05413", name: "Landmands Bank (05413)" },
    { value: "05483", value2: "05483", value3: "05483", value4: "05483", name: "Landmark Credit Union (05483)" },
    { value: "18774", value2: "18774", value3: "18774", value4: "18774", name: "Landmark Credit Union - WI (18774)" },
    { value: "70246", value2: "70246", value3: "70246", value4: "70246", name: "Landmark Credit Union CC (70246)" },
    { value: "00434", value2: "00434", value3: "00434", value4: "00434", name: "Landmark National Bank Business (00434)" },
    { value: "12417", value2: "12417", value3: "12417", value4: "12417", name: "Landmark National Bank Personal (12417)" },
    { value: "15382", value2: "15382", value3: "15382", value4: "15382", name: "Langley Federal Credit Union (15382)" },
    { value: "17299", value2: "17299", value3: "17299", value4: "17299", name: "Launch CU (17299)" },
    { value: "01498", value2: "01498", value3: "01498", value4: "01498", name: "Lea County State Bank (01498)" },
    { value: "65445", value2: "65445", value3: "65445", value4: "65445", name: "Lead Bank Credit Card (65445)" },
    { value: "54120", value2: "54120", value3: "54120", value4: "54120", name: "LeadBank Bus (54120)" },
    { value: "64494", value2: "64494", value3: "64494", value4: "64494", name: "LeadBank Bus Direct Connect (64494)" },
    { value: "15810", value2: "15810", value3: "15810", value4: "15810", name: "Leader Bank (15810)" },
    { value: "70456", value2: "70456", value3: "70456", value4: "70456", name: "Leader Bank - Personal (70456)" },
    { value: "65481", value2: "65481", value3: "65481", value4: "65481", name: "Leaders Credit Union -TN WC (65481)" },
    { value: "08519", value2: "08519", value3: "08519", value4: "08519", name: "Ledyard National Bank (08519)" },
    { value: "08522", value2: "08522", value3: "08522", value4: "08519", name: "Ledyard National Bank - QBWC (08522)" },
    { value: "12927", value2: "12927", value3: "12927", value4: "12927", name: "Lee Bank - Personal Banking (12927)" },
    { value: "68859", value2: "68859", value3: "68859", value4: "68859", name: "Legacy Ag Credit (68859)" },
    { value: "69383", value2: "69383", value3: "69383", value4: "69383", name: "Legacy Bank (69383)" },
    { value: "12719", value2: "12719", value3: "12719", value4: "12719", name: "Legacy Bank - Iowa (12719)" },
    { value: "14055", value2: "14055", value3: "14055", value4: "14055", name: "Legacy Bank - KS (14055)" },
    { value: "03926", value2: "03926", value3: "03926", value4: "03926", name: "Legacy Bank and Trust Company (03926)" },
    { value: "04124", value2: "04124", value3: "04124", value4: "03926", name: "Legacy Bank and Trust Company-DC (04124)" },
    { value: "01655", value2: "01655", value3: "01655", value4: "01655", name: "Legacy Bank, OK (01655)" },
    { value: "67274", value2: "67274", value3: "67274", value4: "67274", name: "Legacy CFCU - Digital Banking (67274)" },
    { value: "03057", value2: "03057", value3: "03057", value4: "03057", name: "Legacy National Bank (03057)" },
    { value: "43083", value2: "43083", value3: "43083", value4: "43083", name: "Legacy National Bank - Business (43083)" },
    { value: "64095", value2: "64095", value3: "64095", value4: "64095", name: "Legacy State Bank - DC (64095)" },
    { value: "08563", value2: "08563", value3: "08563", value4: "08563", name: "Legence Bank (08563)" },
    { value: "67315", value2: "67315", value3: "67315", value4: "67315", name: "Legence Bank - IL QBDC (67315)" },
    { value: "67264", value2: "67264", value3: "67264", value4: "67264", name: "Legend Bank (67264)" },
    { value: "67265", value2: "67265", value3: "67265", value4: "67264", name: "Legend Bank QBDC (67265)" },
    { value: "14046", value2: "14046", value3: "14046", value4: "14046", name: "Legends Bank (14046)" },
    { value: "53712", value2: "53712", value3: "53712", value4: "53712", name: "Leighton State Bank (53712)" },
    { value: "49887", value2: "49887", value3: "49887", value4: "49887", name: "Lenco Credit Union (49887)" },
    { value: "07523", value2: "07523", value3: "07523", value4: "07523", name: "LendingClub -Personal and Bus (07523)" },
    { value: "68354", value2: "68354", value3: "68354", value4: "68354", name: "LendingClub Bank (68354)" },
    { value: "68355", value2: "68355", value3: "68355", value4: "68355", name: "LendingClub Bank- Commercial (68355)" },
    { value: "14685", value2: "14685", value3: "14685", value4: "14685", name: "Leominster Credit Union (14685)" },
    { value: "65433", value2: "65433", value3: "65433", value4: "65433", name: "Levo Credit Union (65433)" },
    { value: "12453", value2: "12453", value3: "12453", value4: "12453", name: "Lewis & Clark Bank (12453)" },
    { value: "22680", value2: "22680", value3: "22680", value4: "22680", name: "Lewis Clark CU (22680)" },
    { value: "00857", value2: "00857", value3: "00857", value4: "00857", name: "Lewisburg Banking Company (00857)" },
    { value: "09407", value2: "09407", value3: "09407", value4: "09407", name: "Lexicon Bank Bus Online (09407)" },
    { value: "67679", value2: "67679", value3: "67679", value4: "67679", name: "Lexicon Bank Direct Connect (67679)" },
    { value: "21720", value2: "21720", value3: "21720", value4: "21720", name: "Lexington Ave FCU (21720)" },
    { value: "67942", value2: "67942", value3: "67942", value4: "67942", name: "LiFE Federal Credit Union (67942)" },
    { value: "69889", value2: "69889", value3: "69889", value4: "69889", name: "Liberty Bank - CT (69889)" },
    { value: "07574", value2: "07574", value3: "07574", value4: "07574", name: "Liberty Bank - So. San Francisco (07574)" },
    { value: "05126", value2: "05126", value3: "05126", value4: "05126", name: "Liberty Bank CT - WC TM (05126)" },
    { value: "51843", value2: "51843", value3: "51843", value4: "51843", name: "Liberty Bank Minnesota (51843)" },
    { value: "67069", value2: "67069", value3: "67069", value4: "67069", name: "Liberty Bank NW (67069)" },
    { value: "67070", value2: "67070", value3: "67070", value4: "67070", name: "Liberty Bank NW - DC (67070)" },
    { value: "05123", value2: "05123", value3: "05123", value4: "05123", name: "Liberty Bank Treasury Management (05123)" },
    { value: "56094", value2: "56094", value3: "56094", value4: "56094", name: "Liberty Bank and Trust Co. (56094)" },
    { value: "63453", value2: "63453", value3: "63453", value4: "63453", name: "Liberty Bay Bank WC QBO (63453)" },
    { value: "11923", value2: "11923", value3: "11923", value4: "11923", name: "Liberty Bay Credit Union (11923)" },
    { value: "19881", value2: "19881", value3: "19881", value4: "19881", name: "Liberty Bay Credit Union - BB-QB (19881)" },
    { value: "12750", value2: "12750", value3: "12750", value4: "12750", name: "Liberty Bell Bank (12750)" },
    { value: "53073", value2: "53073", value3: "53073", value4: "12750", name: "Liberty Bell Bank_DC (53073)" },
    { value: "17877", value2: "17877", value3: "17877", value4: "17877", name: "Liberty Capital Bank (17877)" },
    { value: "67128", value2: "67128", value3: "67128", value4: "67128", name: "Liberty Capital Bank - DC (67128)" },
    { value: "67126", value2: "67126", value3: "67126", value4: "67126", name: "Liberty Capital Bank - WC (67126)" },
    { value: "69572", value2: "69572", value3: "69572", value4: "69572", name: "Liberty County Teachers FCU (69572)" },
    { value: "13221", value2: "13221", value3: "13221", value4: "13221", name: "Liberty Federal Credit Union (13221)" },
    { value: "14464", value2: "14464", value3: "14464", value4: "14464", name: "Liberty Federal Credit Union QB (14464)" },
    { value: "00334", value2: "00334", value3: "00334", value4: "00334", name: "Liberty First Credit Union (00334)" },
    { value: "07259", value2: "07259", value3: "07259", value4: "07259", name: "Liberty National Bank - Business (07259)" },
    { value: "52623", value2: "52623", value3: "52623", value4: "52623", name: "Liberty National Bank - IA (52623)" },
    { value: "18066", value2: "18066", value3: "18066", value4: "18066", name: "Liberty Savings Bank FSB (18066)" },
    { value: "68677", value2: "68677", value3: "68677", value4: "68677", name: "Liberty Savings Bank FSB DC (68677)" },
    { value: "67902", value2: "67902", value3: "67902", value4: "67902", name: "Liberty Savings Federal CU (67902)" },
    { value: "08189", value2: "08189", value3: "08189", value4: "08189", name: "Liberty Savings MN - BeB (08189)" },
    { value: "56883", value2: "56883", value3: "56883", value4: "56883", name: "Liberty State Bank Powers Lake (56883)" },
    { value: "03223", value2: "03223", value3: "03223", value4: "03223", name: "Libertyville Bank & Trust Bus (03223)" },
    { value: "03864", value2: "03864", value3: "03864", value4: "03864", name: "Libertyville Bank & Trust IBB (03864)" },
    { value: "23844", value2: "23844", value3: "23844", value4: "23844", name: "Libertyville Savings Bank (23844)" },
    { value: "12471", value2: "12471", value3: "12471", value4: "12471", name: "LifeStore Bank (12471)" },
    { value: "69599", value2: "69599", value3: "69599", value4: "69599", name: "Light Commerce Credit Union (69599)" },
    { value: "08606", value2: "08606", value3: "08606", value4: "08606", name: "LimeBank (08606)" },
    { value: "08609", value2: "08609", value3: "08609", value4: "08606", name: "LimeBank - MO WC (08609)" },
    { value: "68503", value2: "68503", value3: "68503", value4: "68503", name: "Limestone Federal Credit Union (68503)" },
    { value: "28800", value2: "28800", value3: "28800", value4: "28800", name: "Lincoln National Bank MC (28800)" },
    { value: "69952", value2: "69952", value3: "69952", value4: "07310", name: "Lincoln Savings Bank - DC (69952)" },
    { value: "07310", value2: "07310", value3: "07310", value4: "07310", name: "Lincoln Savings Bank - IA (07310)" },
    { value: "23550", value2: "23550", value3: "23550", value4: "23550", name: "Lindell Bank and Trust Company (23550)" },
    { value: "68210", value2: "68210", value3: "68210", value4: "68210", name: "Lineage Bank (68210)" },
    { value: "68268", value2: "68268", value3: "68268", value4: "68268", name: "Lineage Bank - Business DC (68268)" },
    { value: "68541", value2: "68541", value3: "68541", value4: "68541", name: "Lineage Bank - DC (68541)" },
    { value: "68256", value2: "68256", value3: "68256", value4: "68256", name: "Lineage Bank Business - WC (68256)" },
    { value: "49575", value2: "49575", value3: "49575", value4: "49575", name: "Linn Area Credit Union (49575)" },
    { value: "55089", value2: "55089", value3: "55089", value4: "55089", name: "Linn-Co Federal Credit Union (55089)" },
    { value: "09025", value2: "09025", value3: "09025", value4: "09025", name: "Lisbon Community FCU (09025)" },
    { value: "67890", value2: "67890", value3: "67890", value4: "67890", name: "Lisle Saving Bank (67890)" },
    { value: "16638", value2: "16638", value3: "16638", value4: "16638", name: "Listerhill Credit Union (16638)" },
    { value: "16410", value2: "16410", value3: "16410", value4: "16410", name: "Litchfield National Bank (16410)" },
    { value: "15930", value2: "15930", value3: "15930", value4: "15930", name: "Little Horn State Bank (15930)" },
    { value: "08411", value2: "08411", value3: "08411", value4: "08411", name: "Live Life FCU (08411)" },
    { value: "16471", value2: "16471", value3: "16471", value4: "16471", name: "Live Oak Bank (16471)" },
    { value: "69845", value2: "69845", value3: "69845", value4: "69845", name: "Live Oak Business Plus (69845)" },
    { value: "69874", value2: "69874", value3: "69874", value4: "69845", name: "Live Oak Business Plus-DC (69874)" },
    { value: "18648", value2: "18648", value3: "18648", value4: "18648", name: "Llano National Bank (18648)" },
    { value: "69713", value2: "69713", value3: "69713", value4: "69713", name: "Llano National Bank-DC (69713)" },
    { value: "69920", value2: "69920", value3: "69920", value4: "69920", name: "Local Bank (69920)" },
    { value: "69921", value2: "69921", value3: "69921", value4: "69920", name: "Local Bank-DC (69921)" },
    { value: "69728", value2: "69728", value3: "69728", value4: "69728", name: "Locality Bank (69728)" },
    { value: "69070", value2: "69070", value3: "69070", value4: "69070", name: "Loco Credit Union (69070)" },
    { value: "64206", value2: "64206", value3: "64206", value4: "64206", name: "Logan Bank & Trust Credit Card (64206)" },
    { value: "68039", value2: "68039", value3: "68039", value4: "68039", name: "Logan County Bank (68039)" },
    { value: "68040", value2: "68040", value3: "68040", value4: "68039", name: "Logan County Bank QBDC (68040)" },
    { value: "69898", value2: "69898", value3: "69898", value4: "69898", name: "Logan State Bank (69898)" },
    { value: "08290", value2: "08290", value3: "08290", value4: "08290", name: "Logansport Savings Bank, FSB (08290)" },
    { value: "10626", value2: "10626", value3: "10626", value4: "10626", name: "Logix Federal Credit Union (CA) (10626)" },
    { value: "68860", value2: "68860", value3: "68860", value4: "68860", name: "Lone Star Ag Credit (68860)" },
    { value: "11880", value2: "11880", value3: "11880", value4: "11880", name: "Lone Star Bank (11880)" },
    { value: "09302", value2: "09302", value3: "09302", value4: "09302", name: "Lone Star Bank Direct (09302)" },
    { value: "64905", value2: "64905", value3: "64905", value4: "64905", name: "Lone Star Capital Bank (64905)" },
    { value: "12941", value2: "12941", value3: "12941", value4: "64905", name: "Lone Star Capital Bank  QBDC (12941)" },
    { value: "03579", value2: "03579", value3: "03579", value4: "03579", name: "Lone Star National Bank (03579)" },
    { value: "15555", value2: "15555", value3: "15555", value4: "15555", name: "Lone Star State Bank -TX (15555)" },
    { value: "70219", value2: "70219", value3: "70219", value4: "70219", name: "Longview Bank BO (70219)" },
    { value: "70218", value2: "70218", value3: "70218", value4: "70218", name: "Longview Bank RO (70218)" },
    { value: "56466", value2: "56466", value3: "56466", value4: "56466", name: "Lookout Credit Union (56466)" },
    { value: "68300", value2: "68300", value3: "68300", value4: "68300", name: "Los Angeles FCU (68300)" },
    { value: "68863", value2: "68863", value3: "68863", value4: "68863", name: "Louisiana Land Bank (68863)" },
    { value: "17532", value2: "17532", value3: "17532", value4: "17532", name: "Louisiana National Bank (17532)" },
    { value: "68797", value2: "68797", value3: "68797", value4: "68797", name: "Louisiana USA FCU QBDC (68797)" },
    { value: "68720", value2: "68720", value3: "68720", value4: "68720", name: "Louisiana USA FCU WC (68720)" },
    { value: "44262", value2: "44262", value3: "44262", value4: "44262", name: "Lowell Firefighters Credit Union (44262)" },
    { value: "00836", value2: "00836", value3: "00836", value4: "00836", name: "Lower Valley C.U. Credit Card (00836)" },
    { value: "64185", value2: "64185", value3: "64185", value4: "64185", name: "Lowry State Bank (64185)" },
    { value: "68858", value2: "68858", value3: "68858", value4: "68858", name: "Luana Savings Bank (68858)" },
    { value: "01722", value2: "01722", value3: "01722", value4: "01722", name: "Lubbock National Bank (01722)" },
    { value: "13713", value2: "13713", value3: "13713", value4: "13713", name: "Lumbee Guaranty Bank (13713)" },
    { value: "70349", value2: "70349", value3: "70349", value4: "70349", name: "Lumbee Guaranty Bank QBDC (70349)" },
    { value: "68908", value2: "68908", value3: "68908", value4: "68908", name: "Luminate Bank (68908)" },
    { value: "69803", value2: "69803", value3: "69803", value4: "69803", name: "Luminate Bank-TM (69803)" },
    { value: "00284", value2: "00284", value3: "00284", value4: "00284", name: "Luso-American CU Credit Card (00284)" },
    { value: "54147", value2: "54147", value3: "54147", value4: "54147", name: "Luzerne Bank (54147)" },
    { value: "14388", value2: "14388", value3: "14388", value4: "14388", name: "Lyons National Bank (14388)" },
    { value: "65235", value2: "65235", value3: "65235", value4: "65235", name: "Lyons National Bank CC (65235)" },
    { value: "70384", value2: "70384", value3: "70384", value4: "70384", name: "Lyons National Bank CC - Bus (70384)" },
    { value: "57336", value2: "57336", value3: "57336", value4: "57336", name: "Lytle State Bank (57336)" },
    { value: "06460", value2: "06460", value3: "06460", value4: "06460", name: "M&F Bank (06460)" },
    { value: "64377", value2: "64377", value3: "64377", value4: "64377", name: "M&F Bank - Business (64377)" },
    { value: "50103", value2: "50103", value3: "50103", value4: "50103", name: "M&M Bank Business (50103)" },
    { value: "14770", value2: "14770", value3: "14770", value4: "14770", name: "M&T Bank - Direct Connect (14770)" },
    { value: "69852", value2: "69852", value3: "69852", value4: "69852", name: "M&T Bank - Treasury Center (69852)" },
    { value: "09449", value2: "09449", value3: "09449", value4: "09449", name: "M&T Bank Credit Card (09449)" },
    { value: "57657", value2: "57657", value3: "57657", value4: "57657", name: "M.Y. Safra Bank - Business (57657)" },
    { value: "06359", value2: "06359", value3: "06359", value4: "06359", name: "M1 Bank (06359)" },
    { value: "06362", value2: "06362", value3: "06362", value4: "06362", name: "M1 Bank Business (06362)" },
    { value: "54498", value2: "54498", value3: "54498", value4: "54498", name: "MAX Credit Union (54498)" },
    { value: "03187", value2: "03187", value3: "03187", value4: "03187", name: "MBNA Business Card (03187)" },
    { value: "08159", value2: "08159", value3: "08159", value4: "08159", name: "MC Bank and Trust Co Bus (08159)" },
    { value: "65607", value2: "65607", value3: "65607", value4: "65607", name: "MC Federal Credit Union (65607)" },
    { value: "13053", value2: "13053", value3: "13053", value4: "13053", name: "MCBank (13053)" },
    { value: "02952", value2: "02952", value3: "02952", value4: "02952", name: "MCNB Banks (02952)" },
    { value: "11449", value2: "11449", value3: "11449", value4: "11449", name: "MCT Credit Union (11449)" },
    { value: "15172", value2: "15172", value3: "15172", value4: "15172", name: "MECU Credit Union QB (15172)" },
    { value: "67466", value2: "67466", value3: "67466", value4: "67466", name: "MID PENN BanK - Business (67466)" },
    { value: "09763", value2: "09763", value3: "09763", value4: "09763", name: "MIDFLORIDA Credit Union (09763)" },
    { value: "12755", value2: "12755", value3: "12755", value4: "12755", name: "MIDFLORIDA Premier OB (12755)" },
    { value: "12758", value2: "12758", value3: "12758", value4: "12758", name: "MIDFLORIDA Premier OB - QBDC (12758)" },
    { value: "05265", value2: "05265", value3: "05265", value4: "05265", name: "MNB Bank (05265)" },
    { value: "69594", value2: "69594", value3: "69594", value4: "69594", name: "MNT Bank- Commercial (69594)" },
    { value: "03741", value2: "03741", value3: "03741", value4: "03741", name: "MOCSE Federal Credit Union (03741)" },
    { value: "51654", value2: "51654", value3: "51654", value4: "51654", name: "MPD Community Credit Union (51654)" },
    { value: "09893", value2: "09893", value3: "09893", value4: "09893", name: "MRV Banks (09893)" },
    { value: "09922", value2: "09922", value3: "09922", value4: "09922", name: "MSU Federal Credit Union (09922)" },
    { value: "16780", value2: "16780", value3: "16780", value4: "16780", name: "MSU Federal Credit Union-WC (16780)" },
    { value: "16414", value2: "16414", value3: "16414", value4: "16414", name: "MUFG Union Bank, N.A. (16414)" },
    { value: "57846", value2: "57846", value3: "57846", value4: "16383", name: "MV Savings Bank for QB (57846)" },
    { value: "70463", value2: "70463", value3: "70463", value4: "70463", name: "MVB Bank (70463)" },
    { value: "70464", value2: "70464", value3: "70464", value4: "70463", name: "MVB Bank QBDC (70464)" },
    { value: "08558", value2: "08558", value3: "08558", value4: "08558", name: "MVBofC Commercial (08558)" },
    { value: "08564", value2: "08564", value3: "08564", value4: "08564", name: "MVBofC Consumer (08564)" },
    { value: "01473", value2: "01473", value3: "01473", value4: "01473", name: "Mabrey Bank (01473)" },
    { value: "05968", value2: "05968", value3: "05968", value4: "05968", name: "Macatawa Bank (05968)" },
    { value: "12818", value2: "12818", value3: "12818", value4: "12818", name: "Machias Savings Bank (12818)" },
    { value: "12854", value2: "12854", value3: "12854", value4: "12818", name: "Machias Savings Bank - DC (12854)" },
    { value: "12287", value2: "12287", value3: "12287", value4: "12287", name: "Macon Bank and Trust Company (12287)" },
    { value: "12797", value2: "12797", value3: "12797", value4: "12797", name: "Macon Bank and Trust Company-DC (12797)" },
    { value: "09689", value2: "09689", value3: "09689", value4: "09689", name: "Madison County Bank Bus Online (09689)" },
    { value: "17943", value2: "17943", value3: "17943", value4: "17943", name: "Madison Valley Bank (17943)" },
    { value: "12726", value2: "12726", value3: "12726", value4: "12726", name: "Magnifi Financial (12726)" },
    { value: "57318", value2: "57318", value3: "57318", value4: "57318", name: "Magnolia Bank KY (57318)" },
    { value: "57321", value2: "57321", value3: "57321", value4: "57318", name: "Magnolia Bank KY - DC (57321)" },
    { value: "13071", value2: "13071", value3: "13071", value4: "13071", name: "Magnolia State Bank GA (13071)" },
    { value: "15765", value2: "15765", value3: "15765", value4: "15765", name: "Magnolia State Bank MI (15765)" },
    { value: "51486", value2: "51486", value3: "51486", value4: "15765", name: "Magnolia State Bank-DC (51486)" },
    { value: "04900", value2: "04900", value3: "04900", value4: "04900", name: "Magyar Bank BB WC QB (04900)" },
    { value: "08849", value2: "08849", value3: "08849", value4: "08849", name: "Magyar Bank Business Direct (08849)" },
    { value: "09888", value2: "09888", value3: "09888", value4: "09888", name: "Main Bank (09888)" },
    { value: "06368", value2: "06368", value3: "06368", value4: "09888", name: "Main Bank - QBDC (06368)" },
    { value: "06737", value2: "06737", value3: "06737", value4: "06737", name: "Main Street Bank (06737)" },
    { value: "04418", value2: "04418", value3: "04418", value4: "04418", name: "Main Street Bank Corp Business (04418)" },
    { value: "13842", value2: "13842", value3: "13842", value4: "13842", name: "Main Street Bank Corp Consumer (13842)" },
    { value: "16287", value2: "16287", value3: "16287", value4: "16287", name: "Main Street Bank QBWC (16287)" },
    { value: "59673", value2: "59673", value3: "59673", value4: "59673", name: "MainStreet Bank (59673)" },
    { value: "59676", value2: "59676", value3: "59676", value4: "59676", name: "MainStreet Bank - DL (59676)" },
    { value: "67327", value2: "67327", value3: "67327", value4: "67327", name: "Maine Community Bank BO DC (67327)" },
    { value: "67313", value2: "67313", value3: "67313", value4: "67313", name: "Maine Community Bank BO WC (67313)" },
    { value: "67326", value2: "67326", value3: "67326", value4: "67326", name: "Maine Community Bank RO DC (67326)" },
    { value: "67312", value2: "67312", value3: "67312", value4: "67312", name: "Maine Community Bank RO WC (67312)" },
    { value: "09097", value2: "09097", value3: "09097", value4: "09097", name: "Maine Family Federal Credit Union (09097)" },
    { value: "38031", value2: "38031", value3: "38031", value4: "38031", name: "Maine Highlands FCU (38031)" },
    { value: "68627", value2: "68627", value3: "68627", value4: "68627", name: "Maine Savings (68627)" },
    { value: "11663", value2: "11663", value3: "11663", value4: "11663", name: "Maine State Credit Union (11663)" },
    { value: "17592", value2: "17592", value3: "17592", value4: "17592", name: "Mainstreet Community Bank (17592)" },
    { value: "00324", value2: "00324", value3: "00324", value4: "00324", name: "Malaga Bank, FSB (00324)" },
    { value: "13461", value2: "13461", value3: "13461", value4: "13461", name: "Malvern National Bank (13461)" },
    { value: "11509", value2: "11509", value3: "11509", value4: "11509", name: "Manasquan Bank (11509)" },
    { value: "07709", value2: "07709", value3: "07709", value4: "07709", name: "Manasquan Bank Business Direct (07709)" },
    { value: "10658", value2: "10658", value3: "10658", value4: "10658", name: "Manufacturers Bank (10658)" },
    { value: "08285", value2: "08285", value3: "08285", value4: "08285", name: "Manufacturers Bank-IA (08285)" },
    { value: "08288", value2: "08288", value3: "08288", value4: "08285", name: "Manufacturers Bank-IA WC (08288)" },
    { value: "02417", value2: "02417", value3: "02417", value4: "02417", name: "Maple City Savings Bank, FSB (02417)" },
    { value: "02420", value2: "02420", value3: "02420", value4: "02417", name: "Maple City Savings Bank, FSB - WC (02420)" },
    { value: "05738", value2: "05738", value3: "05738", value4: "05738", name: "MapleMark Bank (05738)" },
    { value: "06875", value2: "06875", value3: "06875", value4: "06875", name: "MapleMark Bank - Treasury Accts (06875)" },
    { value: "06956", value2: "06956", value3: "06956", value4: "06875", name: "MapleMark Bank - Treasury QB (06956)" },
    { value: "05034", value2: "05034", value3: "05034", value4: "05034", name: "Maps Credit Union (05034)" },
    { value: "13500", value2: "13500", value3: "13500", value4: "13500", name: "Maquoketa State Bank (13500)" },
    { value: "55914", value2: "55914", value3: "55914", value4: "55914", name: "Marblehead Bank - Business (55914)" },
    { value: "67212", value2: "67212", value3: "67212", value4: "67212", name: "Marblehead Bank - PFM (67212)" },
    { value: "69931", value2: "69931", value3: "69931", value4: "69931", name: "Marcus By Goldman Sachs (69931)" },
    { value: "65124", value2: "65124", value3: "65124", value4: "05124", name: "Marine Bank & Trust - DL (65124)" },
    { value: "68617", value2: "68617", value3: "68617", value4: "68617", name: "Marine Bank & Trust - Treasury Management (68617)" },
    { value: "68293", value2: "68293", value3: "68293", value4: "68293", name: "Marine Bank & Trust Company - FL (68293)" },
    { value: "68618", value2: "68618", value3: "68618", value4: "68618", name: "Marine Bank & Trust-CM-DC (68618)" },
    { value: "05124", value2: "05124", value3: "05124", value4: "05124", name: "Marine Bank & Trust-Cash Mngt (05124)" },
    { value: "65523", value2: "65523", value3: "65523", value4: "65523", name: "Marion Center Bank - Business (65523)" },
    { value: "69381", value2: "69381", value3: "69381", value4: "69381", name: "Marion Community Bank (69381)" },
    { value: "08894", value2: "08894", value3: "08894", value4: "08894", name: "Marion County Bank (08894)" },
    { value: "64020", value2: "64020", value3: "64020", value4: "08894", name: "Marion County Bank QB DC (64020)" },
    { value: "54081", value2: "54081", value3: "54081", value4: "54081", name: "Marion State Bank (54081)" },
    { value: "56448", value2: "56448", value3: "56448", value4: "56448", name: "Marion State Bank LA (56448)" },
    { value: "22050", value2: "22050", value3: "22050", value4: "22050", name: "Market USA FCU (22050)" },
    { value: "02956", value2: "02956", value3: "02956", value4: "02956", name: "Marquette Bank (02956)" },
    { value: "04043", value2: "04043", value3: "04043", value4: "04043", name: "Marquette Community FCU C Card (04043)" },
    { value: "19332", value2: "19332", value3: "19332", value4: "19332", name: "Marquette Savings Bank (19332)" },
    { value: "03386", value2: "03386", value3: "03386", value4: "03386", name: "Marquette Savings Business BeB (03386)" },
    { value: "10420", value2: "10420", value3: "10420", value4: "10420", name: "Mars National Bank (10420)" },
    { value: "28629", value2: "28629", value3: "28629", value4: "28629", name: "Marseilles Bank (28629)" },
    { value: "43362", value2: "43362", value3: "43362", value4: "43362", name: "Marshall Community CU (43362)" },
    { value: "12099", value2: "12099", value3: "12099", value4: "12099", name: "Marshland Credit Union (12099)" },
    { value: "16383", value2: "16383", value3: "16383", value4: "16383", name: "Martha's Vineyard Savings Bank - Personal (16383)" },
    { value: "64413", value2: "64413", value3: "64413", value4: "64413", name: "Martha's Vineyard Savings Bank-Bus (64413)" },
    { value: "04752", value2: "04752", value3: "04752", value4: "04752", name: "Mascoma Bank (04752)" },
    { value: "54300", value2: "54300", value3: "54300", value4: "54300", name: "Mason Bank (54300)" },
    { value: "67716", value2: "67716", value3: "67716", value4: "54300", name: "Mason Bank - DC (67716)" },
    { value: "05122", value2: "05122", value3: "05122", value4: "05122", name: "Maspeth Federal Savings (05122)" },
    { value: "14656", value2: "14656", value3: "14656", value4: "14656", name: "Matanuska Valley Federal CU (14656)" },
    { value: "02432", value2: "02432", value3: "02432", value4: "02432", name: "Materion Federal Credit Union (02432)" },
    { value: "09762", value2: "09762", value3: "09762", value4: "09762", name: "Mattel Federal Credit Union (09762)" },
    { value: "09878", value2: "09878", value3: "09878", value4: "09878", name: "Mauch Chunk Trust Co (09878)" },
    { value: "67450", value2: "67450", value3: "67450", value4: "67450", name: "Mauch Chunk Trust Co Business (67450)" },
    { value: "69379", value2: "69379", value3: "69379", value4: "69379", name: "Mayville Savings Bank (69379)" },
    { value: "09239", value2: "09239", value3: "09239", value4: "09239", name: "Mazuma Credit Union - Quickbooks (09239)" },
    { value: "67651", value2: "67651", value3: "67651", value4: "67651", name: "McClave State Bank (67651)" },
    { value: "02926", value2: "02926", value3: "02926", value4: "02926", name: "McCoy Federal Credit Union (02926)" },
    { value: "07988", value2: "07988", value3: "07988", value4: "07988", name: "McCurtain County National Bank (07988)" },
    { value: "64863", value2: "64863", value3: "64863", value4: "64863", name: "McHenry Savings - Direct Connect (64863)" },
    { value: "63138", value2: "63138", value3: "63138", value4: "63138", name: "McHenry Savings Bank - BeB (63138)" },
    { value: "63156", value2: "63156", value3: "63156", value4: "63156", name: "McHenry Savings Bank - BeB DC (63156)" },
    { value: "63444", value2: "63444", value3: "63444", value4: "63444", name: "McHenry Savings Bank - CeB (63444)" },
    { value: "33405", value2: "33405", value3: "33405", value4: "33405", name: "McKenzie Banking Company TE (33405)" },
    { value: "05609", value2: "05609", value3: "05609", value4: "05609", name: "McKesson & Healthcare Providers (05609)" },
    { value: "03899", value2: "03899", value3: "03899", value4: "03899", name: "Meade County Bank - KY (03899)" },
    { value: "45747", value2: "45747", value3: "45747", value4: "45747", name: "Meadowland CUWI (45747)" },
    { value: "63855", value2: "63855", value3: "63855", value4: "63855", name: "Meadows Bank (63855)" },
    { value: "63858", value2: "63858", value3: "63858", value4: "63858", name: "Meadows Bank-DL-NEW (63858)" },
    { value: "70070", value2: "70070", value3: "70070", value4: "70070", name: "Meadows Bank-TM (70070)" },
    { value: "70071", value2: "70071", value3: "70071", value4: "70071", name: "Meadows Bank-TM-DC (70071)" },
    { value: "08405", value2: "08405", value3: "08405", value4: "08405", name: "Mechanics Bank - OH (08405)" },
    { value: "08420", value2: "08420", value3: "08420", value4: "08405", name: "Mechanics Bank - QBWC (08420)" },
    { value: "16978", value2: "16978", value3: "16978", value4: "16978", name: "Mechanics Bank Business Direct (16978)" },
    { value: "52113", value2: "52113", value3: "52113", value4: "52113", name: "Mechanics Bank CA (52113)" },
    { value: "52116", value2: "52116", value3: "52116", value4: "52116", name: "Mechanics Bank CA - Web Connect (52116)" },
    { value: "17988", value2: "17988", value3: "17988", value4: "17988", name: "Mechanics Cooperative Bank (17988)" },
    { value: "64629", value2: "64629", value3: "64629", value4: "64629", name: "Mechanics Cooperative Bank-BUS (64629)" },
    { value: "18235", value2: "18235", value3: "18235", value4: "18235", name: "Mechanics' Cooperative Bank-WC (18235)" },
    { value: "18238", value2: "18238", value3: "18238", value4: "18238", name: "Mechanics' Cooperative Business (18238)" },
    { value: "03842", value2: "03842", value3: "03842", value4: "03842", name: "Mediapolis Savings Bank - Business (03842)" },
    { value: "03839", value2: "03839", value3: "03839", value4: "03839", name: "Mediapolis Savings Bank - Retail (03839)" },
    { value: "06272", value2: "06272", value3: "06272", value4: "06272", name: "Mega Bank - Business (06272)" },
    { value: "18729", value2: "18729", value3: "18729", value4: "18729", name: "Meijer Credit Union (18729)" },
    { value: "51378", value2: "51378", value3: "51378", value4: "51378", name: "Member One FCU (51378)" },
    { value: "14566", value2: "14566", value3: "14566", value4: "14566", name: "Members 1st FCU - New (14566)" },
    { value: "67136", value2: "67136", value3: "67136", value4: "17572", name: "Members 1st FCU Bus Svcs QBDC (67136)" },
    { value: "17572", value2: "17572", value3: "17572", value4: "17572", name: "Members 1st FCU Business Svcs (17572)" },
    { value: "13089", value2: "13089", value3: "13089", value4: "13089", name: "Members Choice CU - Houston (13089)" },
    { value: "26952", value2: "26952", value3: "26952", value4: "26952", name: "Members Choice of Central Texas (26952)" },
    { value: "17920", value2: "17920", value3: "17920", value4: "17920", name: "Members Cooperative Credit Union (17920)" },
    { value: "05750", value2: "05750", value3: "05750", value4: "11601", name: "Members First - QB (05750)" },
    { value: "11601", value2: "11601", value3: "11601", value4: "11601", name: "Members First CU (11601)" },
    { value: "46851", value2: "46851", value3: "46851", value4: "46851", name: "Members First CU - IL (46851)" },
    { value: "09879", value2: "09879", value3: "09879", value4: "09879", name: "Members Heritage CU (09879)" },
    { value: "17002", value2: "17002", value3: "17002", value4: "17002", name: "Members Plus CU (17002)" },
    { value: "17005", value2: "17005", value3: "17005", value4: "17005", name: "Members Plus CU-QB (17005)" },
    { value: "69628", value2: "69628", value3: "69628", value4: "69628", name: "Members Preferred Credit Union (69628)" },
    { value: "67223", value2: "67223", value3: "67223", value4: "67223", name: "Members Source Credit Union (67223)" },
    { value: "17509", value2: "17509", value3: "17509", value4: "17509", name: "Members Trust of the SW FCU (17509)" },
    { value: "64005", value2: "64005", value3: "64005", value4: "64005", name: "Members1st Community Credit Union (64005)" },
    { value: "04835", value2: "04835", value3: "04835", value4: "04835", name: "Memorial Employees Federal CU (04835)" },
    { value: "16621", value2: "16621", value3: "16621", value4: "16621", name: "MemoryBank (16621)" },
    { value: "16624", value2: "16624", value3: "16624", value4: "16624", name: "MemoryBank - WC (16624)" },
    { value: "67957", value2: "67957", value3: "67957", value4: "67957", name: "Memphis City Employees CU (67957)" },
    { value: "17139", value2: "17139", value3: "17139", value4: "17139", name: "Menlo Survey Federal Credit Union (17139)" },
    { value: "57216", value2: "57216", value3: "57216", value4: "57216", name: "Mercantile Bank (57216)" },
    { value: "56082", value2: "56082", value3: "56082", value4: "56082", name: "Merchant & Marine Bank (56082)" },
    { value: "12155", value2: "12155", value3: "12155", value4: "12155", name: "Merchants & Citizens Bank-Mcrae (12155)" },
    { value: "29022", value2: "29022", value3: "29022", value4: "29022", name: "Merchants & Farmers Bank & Trust (29022)" },
    { value: "08897", value2: "08897", value3: "08897", value4: "08897", name: "Merchants & Planters Bank (08897)" },
    { value: "09903", value2: "09903", value3: "09903", value4: "09903", name: "Merchants Bank of Al (09903)" },
    { value: "12933", value2: "12933", value3: "12933", value4: "12933", name: "Merchants Bank of Indiana (12933)" },
    { value: "67524", value2: "67524", value3: "67524", value4: "67524", name: "Merchants Bank, N.A. (67524)" },
    { value: "16560", value2: "16560", value3: "16560", value4: "16560", name: "Merchants Bank, NA (16560)" },
    { value: "18892", value2: "18892", value3: "18892", value4: "18892", name: "Merchants Bank,N.A.- Commercial (18892)" },
    { value: "12171", value2: "12171", value3: "12171", value4: "12171", name: "Merchants Commercial Bank (12171)" },
    { value: "70206", value2: "70206", value3: "70206", value4: "70206", name: "Merchants National Bank BO (70206)" },
    { value: "70205", value2: "70205", value3: "70205", value4: "70205", name: "Merchants National Bank RO (70205)" },
    { value: "35706", value2: "35706", value3: "35706", value4: "35706", name: "Merchants State Bank SD (35706)" },
    { value: "20094", value2: "20094", value3: "20094", value4: "20094", name: "Merchants and Farmers Bank (20094)" },
    { value: "09728", value2: "09728", value3: "09728", value4: "09728", name: "Merck Employees Federal CU (09728)" },
    { value: "69797", value2: "69797", value3: "69797", value4: "69797", name: "Merco CU CC (69797)" },
    { value: "54822", value2: "54822", value3: "54822", value4: "46380", name: "Mercy-City-Lehigh Family Mason_DC (54822)" },
    { value: "46380", value2: "46380", value3: "46380", value4: "46380", name: "Mercy-City-Lehigh FamilyMason C (46380)" },
    { value: "67587", value2: "67587", value3: "67587", value4: "67586", name: "Meredith Village SV Bk WC-QB-BS (67587)" },
    { value: "67586", value2: "67586", value3: "67586", value4: "67586", name: "Meredith Village Savings (67586)" },
    { value: "70048", value2: "70048", value3: "70048", value4: "70048", name: "MeriWest Credit Union BB DC (70048)" },
    { value: "00861", value2: "00861", value3: "00861", value4: "00861", name: "Meridian Bank (00861)" },
    { value: "69068", value2: "69068", value3: "69068", value4: "69068", name: "Meridian Bank-Retail (69068)" },
    { value: "69378", value2: "69378", value3: "69378", value4: "69378", name: "Meridian Bank-TM (69378)" },
    { value: "03390", value2: "03390", value3: "03390", value4: "03390", name: "Meridian Trust FCU (03390)" },
    { value: "09395", value2: "09395", value3: "09395", value4: "09395", name: "Merit Bank (09395)" },
    { value: "09206", value2: "09206", value3: "09206", value4: "09206", name: "Merit Bank Business (09206)" },
    { value: "09398", value2: "09398", value3: "09398", value4: "09398", name: "Merit Bank Business QB DC (09398)" },
    { value: "11978", value2: "11978", value3: "11978", value4: "11978", name: "Merit Bank Credit Card (11978)" },
    { value: "09203", value2: "09203", value3: "09203", value4: "09203", name: "Merit Bank QB DC (09203)" },
    { value: "70240", value2: "70240", value3: "70240", value4: "70240", name: "Meritrust (70240)" },
    { value: "03167", value2: "03167", value3: "03167", value4: "03167", name: "Meritrust Business QB (03167)" },
    { value: "06308", value2: "06308", value3: "06308", value4: "06308", name: "Meritus Credit Union (06308)" },
    { value: "08995", value2: "08995", value3: "08995", value4: "08995", name: "Meriwest Credit Union (08995)" },
    { value: "69827", value2: "69827", value3: "69827", value4: "69827", name: "Meriwest Credit Union QBWC IB BB (69827)" },
    { value: "67454", value2: "67454", value3: "67454", value4: "67454", name: "Merrimack County Savings Bk (67454)" },
    { value: "67455", value2: "67455", value3: "67455", value4: "67454", name: "Merrimack MCSB DC-QB (67455)" },
    { value: "09990", value2: "09990", value3: "09990", value4: "09990", name: "Merrimack Valley CU (09990)" },
    { value: "02603", value2: "02603", value3: "02603", value4: "02603", name: "Metairie Bank & Trust CO. (02603)" },
    { value: "03710", value2: "03710", value3: "03710", value4: "03710", name: "Metamora State Bank (03710)" },
    { value: "70051", value2: "70051", value3: "70051", value4: "70051", name: "Method Bank BO (70051)" },
    { value: "70052", value2: "70052", value3: "70052", value4: "70051", name: "Method Bank BO QBDC (70052)" },
    { value: "70053", value2: "70053", value3: "70053", value4: "70053", name: "Method Bank RO (70053)" },
    { value: "70054", value2: "70054", value3: "70054", value4: "70053", name: "Method Bank RO QBDC (70054)" },
    { value: "63342", value2: "63342", value3: "63342", value4: "63342", name: "Methuen Cooperative Bank (63342)" },
    { value: "63345", value2: "63345", value3: "63345", value4: "63342", name: "Methuen Cooperative Bank - Biz (63345)" },
    { value: "68814", value2: "68814", value3: "68814", value4: "68814", name: "Metro Bank AL (68814)" },
    { value: "68815", value2: "68815", value3: "68815", value4: "68814", name: "Metro Bank AL QBDC (68815)" },
    { value: "54249", value2: "54249", value3: "54249", value4: "54249", name: "Metro City Bank (54249)" },
    { value: "68714", value2: "68714", value3: "68714", value4: "68714", name: "Metro City Bank - Business DC (68714)" },
    { value: "68545", value2: "68545", value3: "68545", value4: "68545", name: "Metro City Bank - Business WC (68545)" },
    { value: "52617", value2: "52617", value3: "52617", value4: "52617", name: "Metro Credit Union - Omaha, NE (52617)" },
    { value: "69192", value2: "69192", value3: "69192", value4: "69192", name: "Metro Credit Union CC (69192)" },
    { value: "05969", value2: "05969", value3: "05969", value4: "05969", name: "Metro Credit Union MA (05969)" },
    { value: "67554", value2: "67554", value3: "67554", value4: "05969", name: "Metro Credit Union MA QBWC (67554)" },
    { value: "01899", value2: "01899", value3: "01899", value4: "01899", name: "Metro Medical Credit Union (01899)" },
    { value: "08830", value2: "08830", value3: "08830", value4: "08830", name: "Metro United Bank-BB_QuickBooks (08830)" },
    { value: "08443", value2: "08443", value3: "08443", value4: "08443", name: "MetroBank, NA CM QB (08443)" },
    { value: "51168", value2: "51168", value3: "51168", value4: "51168", name: "Metropolitan Bank (51168)" },
    { value: "07939", value2: "07939", value3: "07939", value4: "07939", name: "Metropolitan Capital Bank (07939)" },
    { value: "00540", value2: "00540", value3: "00540", value4: "00540", name: "Metropolitan Commercial Bank, NY (00540)" },
    { value: "08633", value2: "08633", value3: "08633", value4: "08633", name: "Mi BANK Business (08633)" },
    { value: "08630", value2: "08630", value3: "08630", value4: "08630", name: "Mi BANK Personal (08630)" },
    { value: "58599", value2: "58599", value3: "58599", value4: "58599", name: "Miami Firefighters FCU CCard (58599)" },
    { value: "19989", value2: "19989", value3: "19989", value4: "19989", name: "Miami Firefighters Federal CU (19989)" },
    { value: "68151", value2: "68151", value3: "68151", value4: "68151", name: "Michigan Columbus FCU (68151)" },
    { value: "00368", value2: "00368", value3: "00368", value4: "00368", name: "Michigan Columbus FCU CC (00368)" },
    { value: "09606", value2: "09606", value3: "09606", value4: "09606", name: "Michigan First Credit Union (09606)" },
    { value: "70191", value2: "70191", value3: "70191", value4: "70191", name: "Michigan One CCU (70191)" },
    { value: "70192", value2: "70192", value3: "70192", value4: "70192", name: "Michigan One CCU - DC (70192)" },
    { value: "11554", value2: "11554", value3: "11554", value4: "11554", name: "Michigan Schools & Government CU (11554)" },
    { value: "54369", value2: "54369", value3: "54369", value4: "54369", name: "Michigan United CU (54369)" },
    { value: "56835", value2: "56835", value3: "56835", value4: "56835", name: "Mid America Bank - Business (56835)" },
    { value: "70335", value2: "70335", value3: "70335", value4: "70335", name: "Mid America Bank BO (70335)" },
    { value: "70334", value2: "70334", value3: "70334", value4: "70335", name: "Mid America Bank BODC (70334)" },
    { value: "70333", value2: "70333", value3: "70333", value4: "70333", name: "Mid America Bank RODC (70333)" },
    { value: "18339", value2: "18339", value3: "18339", value4: "18339", name: "Mid American Credit Union (18339)" },
    { value: "03047", value2: "03047", value3: "03047", value4: "03047", name: "Mid Carolina CU Credit Card (03047)" },
    { value: "13165", value2: "13165", value3: "13165", value4: "13165", name: "Mid Hudson Valley FCU (13165)" },
    { value: "54513", value2: "54513", value3: "54513", value4: "54513", name: "Mid Hudson Valley FCU - WC QB (54513)" },
    { value: "01995", value2: "01995", value3: "01995", value4: "01995", name: "Mid Minnesota Federal Credit Union (01995)" },
    { value: "12770", value2: "12770", value3: "12770", value4: "12770", name: "Mid Oregon Fed Credit Union (12770)" },
    { value: "51939", value2: "51939", value3: "51939", value4: "51939", name: "Mid Penn Bank - Retail Online (51939)" },
    { value: "70509", value2: "70509", value3: "70509", value4: "70509", name: "Mid Penn Bank DC (70509)" },
    { value: "59376", value2: "59376", value3: "59376", value4: "59376", name: "Mid-America Bank RO (59376)" },
    { value: "02981", value2: "02981", value3: "02981", value4: "02981", name: "Mid-Atlantic FCU Credit Card (02981)" },
    { value: "08335", value2: "08335", value3: "08335", value4: "08335", name: "Mid-Atlantic Federal Credit Un (08335)" },
    { value: "16368", value2: "16368", value3: "16368", value4: "16368", name: "Mid-Missouri Bank (16368)" },
    { value: "28461", value2: "28461", value3: "28461", value4: "28461", name: "Mid-Southern Savings Bank, FSB (28461)" },
    { value: "67672", value2: "67672", value3: "67672", value4: "28461", name: "Mid-Southern Savings Bk (67672)" },
    { value: "02354", value2: "02354", value3: "02354", value4: "02354", name: "Mid-Tex FCU Credit Card (02354)" },
    { value: "08803", value2: "08803", value3: "08803", value4: "08803", name: "MidAmerica National Bank (08803)" },
    { value: "70412", value2: "70412", value3: "70412", value4: "70412", name: "MidCountry Bank BO (70412)" },
    { value: "70413", value2: "70413", value3: "70413", value4: "70412", name: "MidCountry Bank BODC (70413)" },
    { value: "70415", value2: "70415", value3: "70415", value4: "70415", name: "MidCountry Bank RO (70415)" },
    { value: "70414", value2: "70414", value3: "70414", value4: "70415", name: "MidCountry Bank RODC (70414)" },
    { value: "17949", value2: "17949", value3: "17949", value4: "17949", name: "MidFirst Bank - iManage (17949)" },
    { value: "12929", value2: "12929", value3: "12929", value4: "12929", name: "MidFirst Bank-1st Century Bank (12929)" },
    { value: "10256", value2: "10256", value3: "10256", value4: "10256", name: "MidSouth Community FCU (GA) (10256)" },
    { value: "16300", value2: "16300", value3: "16300", value4: "16300", name: "MidWest America FCU (16300)" },
    { value: "70167", value2: "70167", value3: "70167", value4: "16300", name: "MidWest America FCU - QB (70167)" },
    { value: "03261", value2: "03261", value3: "03261", value4: "03261", name: "MidWest Bank of Western Illinois (03261)" },
    { value: "62703", value2: "62703", value3: "62703", value4: "62703", name: "Midcoast Federal CU (62703)" },
    { value: "08033", value2: "08033", value3: "08033", value4: "08033", name: "Middlefield Banking Co-Business (08033)" },
    { value: "16918", value2: "16918", value3: "16918", value4: "16918", name: "Middlesex Savings Bank Online Bank (16918)" },
    { value: "34266", value2: "34266", value3: "34266", value4: "34266", name: "Middletown Valley Bank (34266)" },
    { value: "02177", value2: "02177", value3: "02177", value4: "34266", name: "Middletown Valley Bank - DL (02177)" },
    { value: "09797", value2: "09797", value3: "09797", value4: "09797", name: "Midland Community Bank (09797)" },
    { value: "05248", value2: "05248", value3: "05248", value4: "05248", name: "Midland States Bank BB QB (05248)" },
    { value: "59223", value2: "59223", value3: "59223", value4: "59223", name: "Midland States Bank DC QB (59223)" },
    { value: "69357", value2: "69357", value3: "69357", value4: "69357", name: "Midland States Bank Premier - DC (69357)" },
    { value: "05157", value2: "05157", value3: "05157", value4: "05157", name: "Midsouth Bank N.A. (05157)" },
    { value: "01450", value2: "01450", value3: "01450", value4: "01450", name: "Midstates Bank, N.A. (01450)" },
    { value: "12050", value2: "12050", value3: "12050", value4: "12050", name: "Midstates Bank, N.A. DC (12050)" },
    { value: "14625", value2: "14625", value3: "14625", value4: "14625", name: "Midwest Bank - MN (14625)" },
    { value: "00518", value2: "00518", value3: "00518", value4: "00518", name: "Midwest Bank - MN Direct Connect (00518)" },
    { value: "01862", value2: "01862", value3: "01862", value4: "01862", name: "Midwest Bank - MN QBDC Bus (01862)" },
    { value: "01874", value2: "01874", value3: "01874", value4: "01874", name: "Midwest Bank - MN WC Bus (01874)" },
    { value: "01072", value2: "01072", value3: "01072", value4: "01072", name: "Midwest Bank Centre (01072)" },
    { value: "06578", value2: "06578", value3: "06578", value4: "06578", name: "Midwest Bank Centre - DC (06578)" },
    { value: "13572", value2: "13572", value3: "13572", value4: "13572", name: "Midwest Bank N.A. (13572)" },
    { value: "53814", value2: "53814", value3: "53814", value4: "53814", name: "Midwest Community FCU (53814)" },
    { value: "24096", value2: "24096", value3: "24096", value4: "24096", name: "Midwest Heritage Bank (24096)" },
    { value: "69051", value2: "69051", value3: "69051", value4: "69051", name: "Midwest Heritage Bank QBDC (69051)" },
    { value: "56715", value2: "56715", value3: "56715", value4: "56715", name: "Midwest Heritage-Business eBanking (56715)" },
    { value: "68611", value2: "68611", value3: "68611", value4: "68611", name: "Midwest Liberty Federal Credit Union (68611)" },
    { value: "69761", value2: "69761", value3: "69761", value4: "69761", name: "Midwest National Bank (69761)" },
    { value: "69762", value2: "69762", value3: "69762", value4: "69761", name: "Midwest National Bank QBDC (69762)" },
    { value: "57516", value2: "57516", value3: "57516", value4: "57516", name: "Midwest Regional Bank (57516)" },
    { value: "05229", value2: "05229", value3: "05229", value4: "05229", name: "MidwestOne Bank (05229)" },
    { value: "14743", value2: "14743", value3: "14743", value4: "14743", name: "Mifflinburg Bank & Trust DC (14743)" },
    { value: "57714", value2: "57714", value3: "57714", value4: "57714", name: "Mifflinburg Bank & Trust QB (57714)" },
    { value: "46383", value2: "46383", value3: "46383", value4: "46383", name: "Mile High FCUButte, MT (46383)" },
    { value: "57072", value2: "57072", value3: "57072", value4: "46383", name: "Mile High FCU_DC (57072)" },
    { value: "00264", value2: "00264", value3: "00264", value4: "00264", name: "Milford Federal Bank (00264)" },
    { value: "02168", value2: "02168", value3: "02168", value4: "02168", name: "Milford Federal Bank - QB WC (02168)" },
    { value: "19719", value2: "19719", value3: "19719", value4: "19719", name: "Milford National Bank - BBQB (19719)" },
    { value: "09233", value2: "09233", value3: "09233", value4: "09233", name: "Millbury National Bank (09233)" },
    { value: "09236", value2: "09236", value3: "09236", value4: "09233", name: "Millbury National Bank - QBWC (09236)" },
    { value: "67171", value2: "67171", value3: "67171", value4: "67171", name: "Milledgeville State Bank (67171)" },
    { value: "12482", value2: "12482", value3: "12482", value4: "12482", name: "Millennial Bank - DirectConnect (12482)" },
    { value: "08915", value2: "08915", value3: "08915", value4: "08915", name: "Millennial Bank - Leeds (08915)" },
    { value: "62379", value2: "62379", value3: "62379", value4: "62379", name: "Millennium Bank  - business (62379)" },
    { value: "04781", value2: "04781", value3: "04781", value4: "04781", name: "Millennium Bank, TN (04781)" },
    { value: "00350", value2: "00350", value3: "00350", value4: "00350", name: "Millstream Area CU credit card (00350)" },
    { value: "69575", value2: "69575", value3: "69575", value4: "69575", name: "Millstream Area Credit Union (69575)" },
    { value: "67550", value2: "67550", value3: "67550", value4: "67550", name: "Millyard BO DC (67550)" },
    { value: "67549", value2: "67549", value3: "67549", value4: "67549", name: "Millyard Bank (67549)" },
    { value: "32280", value2: "32280", value3: "32280", value4: "32280", name: "Miners National Bank of Eveleth (32280)" },
    { value: "13938", value2: "13938", value3: "13938", value4: "13938", name: "Minnco CU (13938)" },
    { value: "08297", value2: "08297", value3: "08297", value4: "08297", name: "Minnco CU - DC (08297)" },
    { value: "59184", value2: "59184", value3: "59184", value4: "59184", name: "Minnesota Bank & Trust of MN (59184)" },
    { value: "00932", value2: "00932", value3: "00932", value4: "00932", name: "Minnesota Lakes BeB (00932)" },
    { value: "70155", value2: "70155", value3: "70155", value4: "70155", name: "Minnesota National Bank (70155)" },
    { value: "70156", value2: "70156", value3: "70156", value4: "70155", name: "Minnesota National Bank QBDC (70156)" },
    { value: "17446", value2: "17446", value3: "17446", value4: "17446", name: "Minnesota Valley Federal CU (17446)" },
    { value: "17449", value2: "17449", value3: "17449", value4: "17449", name: "Minnesota Valley Federal CU QB (17449)" },
    { value: "18888", value2: "18888", value3: "18888", value4: "18888", name: "Minnwest Bank (18888)" },
    { value: "08981", value2: "08981", value3: "08981", value4: "08981", name: "Minnwest Bank Business (08981)" },
    { value: "70274", value2: "70274", value3: "70274", value4: "70274", name: "Minster Bank BO (70274)" },
    { value: "70273", value2: "70273", value3: "70273", value4: "70274", name: "Minster Bank BODC (70273)" },
    { value: "17625", value2: "17625", value3: "17625", value4: "17625", name: "Minster Bank RO (17625)" },
    { value: "70272", value2: "70272", value3: "70272", value4: "17625", name: "Minster Bank RODC (70272)" },
    { value: "56955", value2: "56955", value3: "56955", value4: "56955", name: "Mission Bank - AZ (56955)" },
    { value: "23565", value2: "23565", value3: "23565", value4: "23565", name: "Mission Bank CA (23565)" },
    { value: "12611", value2: "12611", value3: "12611", value4: "12611", name: "Mission Bank CA-TM (12611)" },
    { value: "68027", value2: "68027", value3: "68027", value4: "68027", name: "Mission Bank CA-TM-DC (68027)" },
    { value: "55773", value2: "55773", value3: "55773", value4: "55773", name: "Mission City Federal Credit Union (CA) (55773)" },
    { value: "15403", value2: "15403", value3: "15403", value4: "15403", name: "Mission Federal Credit Union (15403)" },
    { value: "31050", value2: "31050", value3: "31050", value4: "31050", name: "Mission National Bank (31050)" },
    { value: "08154", value2: "08154", value3: "08154", value4: "08154", name: "Mission Valley Bank (08154)" },
    { value: "67507", value2: "67507", value3: "67507", value4: "67507", name: "Mission Valley Bank BO DC (67507)" },
    { value: "00785", value2: "00785", value3: "00785", value4: "00785", name: "Mississippi Federal Credit Union (00785)" },
    { value: "68864", value2: "68864", value3: "68864", value4: "68864", name: "Mississippi Land Bank (68864)" },
    { value: "12228", value2: "12228", value3: "12228", value4: "12228", name: "Missouri Credit Union (12228)" },
    { value: "52098", value2: "52098", value3: "52098", value4: "52098", name: "Mobility CU Credit Card (52098)" },
    { value: "12395", value2: "12395", value3: "12395", value4: "12395", name: "Modern Bank (12395)" },
    { value: "69681", value2: "69681", value3: "69681", value4: "69681", name: "Monesty (69681)" },
    { value: "69682", value2: "69682", value3: "69682", value4: "69681", name: "Monesty-DC (69682)" },
    { value: "00515", value2: "00515", value3: "00515", value4: "00515", name: "Monroe Bank & Trust Credit Card (00515)" },
    { value: "09873", value2: "09873", value3: "09873", value4: "09873", name: "Monroe Community CU (09873)" },
    { value: "06413", value2: "06413", value3: "06413", value4: "06413", name: "Monroe Federal S&L (06413)" },
    { value: "62922", value2: "62922", value3: "62922", value4: "62922", name: "Monroe Federal S&L - Bus (62922)" },
    { value: "64908", value2: "64908", value3: "64908", value4: "64908", name: "Monson Savings Bank - BUS (64908)" },
    { value: "22146", value2: "22146", value3: "22146", value4: "22146", name: "Montana Credit Union (22146)" },
    { value: "03965", value2: "03965", value3: "03965", value4: "03965", name: "Montecito Bank & Trust Credit Card (03965)" },
    { value: "67186", value2: "67186", value3: "67186", value4: "67186", name: "Montecito Bank & Trust DC (67186)" },
    { value: "67187", value2: "67187", value3: "67187", value4: "67187", name: "Montecito Bank & Trust WC (67187)" },
    { value: "16864", value2: "16864", value3: "16864", value4: "16864", name: "Monterey CU (16864)" },
    { value: "16867", value2: "16867", value3: "16867", value4: "16864", name: "Monterey CU -QB (16867)" },
    { value: "04089", value2: "04089", value3: "04089", value4: "04089", name: "Monterey County Bank (04089)" },
    { value: "13554", value2: "13554", value3: "13554", value4: "13554", name: "Montgomery Bank (13554)" },
    { value: "53307", value2: "53307", value3: "53307", value4: "53307", name: "Montgomery Bank - Dir Con (53307)" },
    { value: "17637", value2: "17637", value3: "17637", value4: "17637", name: "Monticello Banking Company (17637)" },
    { value: "15693", value2: "15693", value3: "15693", value4: "15693", name: "Moody National Bank (15693)" },
    { value: "57201", value2: "57201", value3: "57201", value4: "57201", name: "Morgan Stanley - Cash Management (57201)" },
    { value: "63147", value2: "63147", value3: "63147", value4: "63147", name: "Morganton Savings Bank (63147)" },
    { value: "29808", value2: "29808", value3: "29808", value4: "29808", name: "Morgantown Bank & Trust Company (29808)" },
    { value: "68365", value2: "68365", value3: "68365", value4: "68365", name: "Morris Bank - OLB DC (68365)" },
    { value: "62658", value2: "62658", value3: "62658", value4: "62658", name: "Morris Bank mBiz (62658)" },
    { value: "62655", value2: "62655", value3: "62655", value4: "62658", name: "Morris Bank mBiz WC (62655)" },
    { value: "01277", value2: "01277", value3: "01277", value4: "01277", name: "Morris State Bank (01277)" },
    { value: "69504", value2: "69504", value3: "69504", value4: "69504", name: "Moultrie Bank & Trust (69504)" },
    { value: "68338", value2: "68338", value3: "68338", value4: "68338", name: "Mound City Bank BO (68338)" },
    { value: "50145", value2: "50145", value3: "50145", value4: "50145", name: "Mound City Bank RO (50145)" },
    { value: "68279", value2: "68279", value3: "68279", value4: "68279", name: "Mount Vernon Bank & Trust Co (68279)" },
    { value: "58911", value2: "58911", value3: "58911", value4: "58911", name: "Mount Vernon Bank in Vidalia, GA (58911)" },
    { value: "09886", value2: "09886", value3: "09886", value4: "09886", name: "Mountain America CU - Direct (09886)" },
    { value: "53034", value2: "53034", value3: "53034", value4: "53034", name: "Mountain America CU - WC (53034)" },
    { value: "05678", value2: "05678", value3: "05678", value4: "05678", name: "Mountain Commerce Bank (05678)" },
    { value: "08509", value2: "08509", value3: "08509", value4: "08509", name: "Mountain Pacific Bank (08509)" },
    { value: "69884", value2: "69884", value3: "69884", value4: "08509", name: "Mountain Pacific Bank QB DC (69884)" },
    { value: "55620", value2: "55620", value3: "55620", value4: "55620", name: "Mountain River Credit Union (55620)" },
    { value: "63369", value2: "63369", value3: "63369", value4: "63369", name: "Mountain Valley Bank - CO (63369)" },
    { value: "01793", value2: "01793", value3: "01793", value4: "01793", name: "Mountain Valley Bank Credit Card (01793)" },
    { value: "50184", value2: "50184", value3: "50184", value4: "50184", name: "Mountain Valley Bank, N.A. (50184)" },
    { value: "53556", value2: "53556", value3: "53556", value4: "53556", name: "MountainOne Bank (53556)" },
    { value: "18013", value2: "18013", value3: "18013", value4: "18013", name: "Movement Bank (18013)" },
    { value: "11166", value2: "11166", value3: "11166", value4: "11166", name: "Mt. McKinley Bank (11166)" },
    { value: "69704", value2: "69704", value3: "69704", value4: "11166", name: "Mt. McKinley Bank QBDC (69704)" },
    { value: "68542", value2: "68542", value3: "68542", value4: "68542", name: "Mtn Valley Bank (68542)" },
    { value: "29601", value2: "29601", value3: "29601", value4: "29601", name: "Muenster State Bank (29601)" },
    { value: "68691", value2: "68691", value3: "68691", value4: "29601", name: "Muenster State Bank QBDC (68691)" },
    { value: "70327", value2: "70327", value3: "70327", value4: "70327", name: "Municipal Bank BO (70327)" },
    { value: "70324", value2: "70324", value3: "70324", value4: "70324", name: "Municipal Bank RO (70324)" },
    { value: "04115", value2: "04115", value3: "04115", value4: "04115", name: "Municipal CU of OKC Credit Card (04115)" },
    { value: "09352", value2: "09352", value3: "09352", value4: "09352", name: "Municipal Credit Union (09352)" },
    { value: "53511", value2: "53511", value3: "53511", value4: "53511", name: "Municipal Employees CU of OKC (53511)" },
    { value: "70326", value2: "70326", value3: "70326", value4: "70327", name: "Municipal Trust & Savings Bk BODC (70326)" },
    { value: "70325", value2: "70325", value3: "70325", value4: "70324", name: "Municipal Trust & Savings Bk RODC (70325)" },
    { value: "58812", value2: "58812", value3: "58812", value4: "58812", name: "Muskegon Co-Op Federal CU (58812)" },
    { value: "09299", value2: "09299", value3: "09299", value4: "09299", name: "Mutual 1st Federal Credit Union (09299)" },
    { value: "07376", value2: "07376", value3: "07376", value4: "07376", name: "Mutual Credit Union credit card (07376)" },
    { value: "68728", value2: "68728", value3: "68728", value4: "68728", name: "Mutual Federal Bank OH (68728)" },
    { value: "68729", value2: "68729", value3: "68729", value4: "68728", name: "Mutual Federal Bank OH QBDC (68729)" },
    { value: "09119", value2: "09119", value3: "09119", value4: "09119", name: "Mutual Savings Bank (09119)" },
    { value: "09125", value2: "09125", value3: "09125", value4: "09125", name: "Mutual Savings Bank QB (09125)" },
    { value: "17313", value2: "17313", value3: "17313", value4: "17313", name: "Mutual Savings CU - AL (17313)" },
    { value: "43368", value2: "43368", value3: "43368", value4: "43368", name: "Mutual Security Credit Union (43368)" },
    { value: "03809", value2: "03809", value3: "03809", value4: "03809", name: "MutualOne Bank (03809)" },
    { value: "64230", value2: "64230", value3: "64230", value4: "03809", name: "MutualOne Bank - BUS (64230)" },
    { value: "09209", value2: "09209", value3: "09209", value4: "09209", name: "My Community Credit Union (09209)" },
    { value: "08357", value2: "08357", value3: "08357", value4: "08357", name: "My Credit Union (08357)" },
    { value: "06062", value2: "06062", value3: "06062", value4: "06062", name: "My Credit Union MN (06062)" },
    { value: "00232", value2: "00232", value3: "00232", value4: "00232", name: "My Synovus (00232)" },
    { value: "02833", value2: "02833", value3: "02833", value4: "02833", name: "MyCardStatement (02833)" },
    { value: "69486", value2: "69486", value3: "69486", value4: "69486", name: "MyCardStatement EWC (69486)" },
    { value: "02884", value2: "02884", value3: "02884", value4: "02884", name: "MyLoanAccount (02884)" },
    { value: "08612", value2: "08612", value3: "08612", value4: "08612", name: "MyOCCU (08612)" },
    { value: "10485", value2: "10485", value3: "10485", value4: "10485", name: "MyPoint Credit Union (10485)" },
    { value: "09040", value2: "09040", value3: "09040", value4: "09040", name: "MyUSA Credit Union (09040)" },
    { value: "00837", value2: "00837", value3: "00837", value4: "00837", name: "N.Western Bank-ChippewaFalls,WI (00837)" },
    { value: "64545", value2: "64545", value3: "64545", value4: "64545", name: "NAFT FCU Credit Card (64545)" },
    { value: "69706", value2: "69706", value3: "69706", value4: "68246", name: "NAHEOLA CREDIT UNION QB (69706)" },
    { value: "10643", value2: "10643", value3: "10643", value4: "10643", name: "NASA Federal Credit Union (10643)" },
    { value: "13114", value2: "13114", value3: "13114", value4: "13114", name: "NBC Oklahoma (13114)" },
    { value: "63750", value2: "63750", value3: "63750", value4: "63750", name: "NBH Bank Bank Midwest WC (63750)" },
    { value: "04091", value2: "04091", value3: "04091", value4: "04091", name: "NBH Bank Direct Connect (04091)" },
    { value: "63768", value2: "63768", value3: "63768", value4: "63768", name: "NBH Bank Hillcrest Bank WC (63768)" },
    { value: "63771", value2: "63771", value3: "63771", value4: "63771", name: "NBH BankCom Banks Colorado WC (63771)" },
    { value: "59643", value2: "59643", value3: "59643", value4: "59643", name: "NBKC Bank (59643)" },
    { value: "06824", value2: "06824", value3: "06824", value4: "59643", name: "NBKC Bank-QB (06824)" },
    { value: "67810", value2: "67810", value3: "67810", value4: "67810", name: "NBOFI (67810)" },
    { value: "16770", value2: "16770", value3: "16770", value4: "16770", name: "NBT Bank (16770)" },
    { value: "04282", value2: "04282", value3: "04282", value4: "04282", name: "NCB FSB (04282)" },
    { value: "04285", value2: "04285", value3: "04285", value4: "04285", name: "NCB FSB QBDC (04285)" },
    { value: "08936", value2: "08936", value3: "08936", value4: "08936", name: "NIH Federal Credit Union (08936)" },
    { value: "00312", value2: "00312", value3: "00312", value4: "00312", name: "NJ Suburban Federal Credit Union (00312)" },
    { value: "40179", value2: "40179", value3: "40179", value4: "40179", name: "NOA Bank (40179)" },
    { value: "69092", value2: "69092", value3: "69092", value4: "69092", name: "NRB Business (69092)" },
    { value: "69093", value2: "69093", value3: "69093", value4: "69092", name: "NRB Business QBDC (69093)" },
    { value: "69090", value2: "69090", value3: "69090", value4: "69090", name: "NRB Consumer (69090)" },
    { value: "69091", value2: "69091", value3: "69091", value4: "69090", name: "NRB Consumer QBDC (69091)" },
    { value: "40131", value2: "40131", value3: "40131", value4: "40131", name: "NSB Bank (40131)" },
    { value: "08282", value2: "08282", value3: "08282", value4: "08282", name: "NSB Bank Business (08282)" },
    { value: "06433", value2: "06433", value3: "06433", value4: "06433", name: "NVE Bank (06433)" },
    { value: "68971", value2: "68971", value3: "68971", value4: "68971", name: "NVE Bank QBDC (68971)" },
    { value: "00494", value2: "00494", value3: "00494", value4: "00494", name: "NW Preferred FCU (00494)" },
    { value: "58752", value2: "58752", value3: "58752", value4: "58752", name: "NWave, Inc. (58752)" },
    { value: "62622", value2: "62622", value3: "62622", value4: "62622", name: "NYMEO FCU (62622)" },
    { value: "68246", value2: "68246", value3: "68246", value4: "68246", name: "Naheola Credit Union (68246)" },
    { value: "09065", value2: "09065", value3: "09065", value4: "09065", name: "Nano Banc (09065)" },
    { value: "07136", value2: "07136", value3: "07136", value4: "07136", name: "Nano Banc -Business eBanking (07136)" },
    { value: "14379", value2: "14379", value3: "14379", value4: "14379", name: "Nantahala Bank & Trust RO (14379)" },
    { value: "68483", value2: "68483", value3: "68483", value4: "68483", name: "Nantahala Bank & Trust RO QBDC (68483)" },
    { value: "68377", value2: "68377", value3: "68377", value4: "68377", name: "Nantahala Bank-Business (68377)" },
    { value: "68391", value2: "68391", value3: "68391", value4: "68391", name: "Nantahala Bank-Business QBDC (68391)" },
    { value: "17812", value2: "17812", value3: "17812", value4: "17812", name: "Nat Bank Blacksburg Credit Cards (17812)" },
    { value: "69188", value2: "69188", value3: "69188", value4: "69188", name: "Natco Credit Union (69188)" },
    { value: "03589", value2: "03589", value3: "03589", value4: "03589", name: "National Bank &Trust - LaGrange (03589)" },
    { value: "12626", value2: "12626", value3: "12626", value4: "12626", name: "National Bank Middlebury QBDC (12626)" },
    { value: "17250", value2: "17250", value3: "17250", value4: "17250", name: "National Bank of Andrews (17250)" },
    { value: "16381", value2: "16381", value3: "16381", value4: "16381", name: "National Bank of Andrews - DC (16381)" },
    { value: "13501", value2: "13501", value3: "13501", value4: "13501", name: "National Bank of Arizona - Direct (13501)" },
    { value: "10849", value2: "10849", value3: "10849", value4: "10849", name: "National Bank of Arizona - WEB (10849)" },
    { value: "65034", value2: "65034", value3: "65034", value4: "65034", name: "National Bank of Blacksburg (65034)" },
    { value: "11548", value2: "11548", value3: "11548", value4: "11548", name: "National Bank of Coxsackie (11548)" },
    { value: "69377", value2: "69377", value3: "69377", value4: "69377", name: "National Bank of Coxsackie DC (69377)" },
    { value: "04610", value2: "04610", value3: "04610", value4: "04607", name: "National Bank of Indianapoli-DL (04610)" },
    { value: "04607", value2: "04607", value3: "04607", value4: "04607", name: "National Bank of Indianapolis (04607)" },
    { value: "11145", value2: "11145", value3: "11145", value4: "11145", name: "National Bank of Middlebury (11145)" },
    { value: "06635", value2: "06635", value3: "06635", value4: "06635", name: "National Bank of Texas Ft Worth (06635)" },
    { value: "12872", value2: "12872", value3: "12872", value4: "12872", name: "National Capital Bank - BeB (12872)" },
    { value: "08870", value2: "08870", value3: "08870", value4: "08870", name: "National Exchange Bank- BEB (08870)" },
    { value: "03133", value2: "03133", value3: "03133", value4: "03133", name: "National Grand Bank (03133)" },
    { value: "64008", value2: "64008", value3: "64008", value4: "03133", name: "National Grand Bank - Business (64008)" },
    { value: "03389", value2: "03389", value3: "03389", value4: "03389", name: "National Iron Bank (03389)" },
    { value: "62451", value2: "62451", value3: "62451", value4: "62451", name: "National Iron Bank - QB WC (62451)" },
    { value: "05603", value2: "05603", value3: "05603", value4: "05603", name: "National JACL Credit Union (05603)" },
    { value: "62706", value2: "62706", value3: "62706", value4: "62706", name: "Naveo Credit Union (62706)" },
    { value: "19512", value2: "19512", value3: "19512", value4: "19512", name: "Naveo Credit Union - BUS (19512)" },
    { value: "10856", value2: "10856", value3: "10856", value4: "10856", name: "Navigant Credit Union (10856)" },
    { value: "20319", value2: "20319", value3: "20319", value4: "20319", name: "Navigant Credit Union - WC QB Bus (20319)" },
    { value: "67071", value2: "67071", value3: "67071", value4: "67071", name: "Navigant Credit Union- DC QB Bus (67071)" },
    { value: "14226", value2: "14226", value3: "14226", value4: "14226", name: "Navigator Credit Union (MS) (14226)" },
    { value: "14545", value2: "14545", value3: "14545", value4: "14545", name: "Navy Federal Credit Union (14545)" },
    { value: "69503", value2: "69503", value3: "69503", value4: "69503", name: "Navy Federal Credit Union EWC (69503)" },
    { value: "58011", value2: "58011", value3: "58011", value4: "58011", name: "Nebraska Bank (58011)" },
    { value: "53079", value2: "53079", value3: "53079", value4: "53079", name: "Nebraska Bank of Commerce (53079)" },
    { value: "64866", value2: "64866", value3: "64866", value4: "64866", name: "Nebraska Energy FCU - WebConnect (64866)" },
    { value: "04745", value2: "04745", value3: "04745", value4: "04745", name: "Nebraska State Bank (04745)" },
    { value: "25872", value2: "25872", value3: "25872", value4: "25872", name: "Nebraska State Bank and Trust Co (25872)" },
    { value: "54480", value2: "54480", value3: "54480", value4: "54480", name: "NebraskaLand Bank - Biz (54480)" },
    { value: "14952", value2: "14952", value3: "14952", value4: "14952", name: "Nebraskaland Bank (14952)" },
    { value: "20382", value2: "20382", value3: "20382", value4: "20382", name: "Neches Federal Credit Union - QB (20382)" },
    { value: "19392", value2: "19392", value3: "19392", value4: "19392", name: "Needham Bank - BB QB (19392)" },
    { value: "70391", value2: "70391", value3: "70391", value4: "70391", name: "Needham Bank Bus CC (70391)" },
    { value: "04832", value2: "04832", value3: "04832", value4: "04832", name: "Needham Bank Business DC (04832)" },
    { value: "53286", value2: "53286", value3: "53286", value4: "53286", name: "Neighborhood Credit Union - WC QB (53286)" },
    { value: "62178", value2: "62178", value3: "62178", value4: "62178", name: "Neighborhood National Bank - CA (62178)" },
    { value: "68004", value2: "68004", value3: "68004", value4: "68004", name: "Neighborhood National Bank BO (68004)" },
    { value: "04202", value2: "04202", value3: "04202", value4: "04202", name: "Neighborhood Natl Bank-Personal (04202)" },
    { value: "69585", value2: "69585", value3: "69585", value4: "69585", name: "Neighbors FCU CC (69585)" },
    { value: "67230", value2: "67230", value3: "67230", value4: "67230", name: "Neighbors FCU DC (67230)" },
    { value: "11797", value2: "11797", value3: "11797", value4: "11797", name: "Neighbors FCU WC (11797)" },
    { value: "22404", value2: "22404", value3: "22404", value4: "22404", name: "Net FCU (22404)" },
    { value: "16278", value2: "16278", value3: "16278", value4: "16278", name: "Nevada Bank & Trust (16278)" },
    { value: "07448", value2: "07448", value3: "07448", value4: "07448", name: "Nevada Bank & Trust - Bus (07448)" },
    { value: "13504", value2: "13504", value3: "13504", value4: "13504", name: "Nevada State Bank - Direct (13504)" },
    { value: "10847", value2: "10847", value3: "10847", value4: "10847", name: "Nevada State Bank - WEB (10847)" },
    { value: "18738", value2: "18738", value3: "18738", value4: "18738", name: "New Carlisle FSB - Business (18738)" },
    { value: "03098", value2: "03098", value3: "03098", value4: "03098", name: "New Century Bank (03098)" },
    { value: "43392", value2: "43392", value3: "43392", value4: "43392", name: "New Cumberland FCU (43392)" },
    { value: "52011", value2: "52011", value3: "52011", value4: "52011", name: "New Dimensions FCU (52011)" },
    { value: "13180", value2: "13180", value3: "13180", value4: "13180", name: "New England FCU (13180)" },
    { value: "00129", value2: "00129", value3: "00129", value4: "00129", name: "New Frontier Bank - MO (00129)" },
    { value: "08024", value2: "08024", value3: "08024", value4: "08024", name: "New Haven Bank (08024)" },
    { value: "08027", value2: "08027", value3: "08027", value4: "08024", name: "New Haven Bank - QBWC (08027)" },
    { value: "36795", value2: "36795", value3: "36795", value4: "36795", name: "New Horizon Bank (36795)" },
    { value: "68939", value2: "68939", value3: "68939", value4: "68939", name: "New Horizon Federal Credit Union (68939)" },
    { value: "67283", value2: "67283", value3: "67283", value4: "67283", name: "New Market Bank (67283)" },
    { value: "59133", value2: "59133", value3: "59133", value4: "59133", name: "New Mexico Bank & Trust (59133)" },
    { value: "68685", value2: "68685", value3: "68685", value4: "68685", name: "New Millennium Bank -  Business (68685)" },
    { value: "53808", value2: "53808", value3: "53808", value4: "53808", name: "New Orleans Firemens FCU (53808)" },
    { value: "00326", value2: "00326", value3: "00326", value4: "00326", name: "New Peoples Bank credit card (00326)" },
    { value: "03947", value2: "03947", value3: "03947", value4: "03947", name: "New Tripoli Bank (03947)" },
    { value: "65334", value2: "65334", value3: "65334", value4: "65334", name: "New Tripoli Bank - BUS (65334)" },
    { value: "08213", value2: "08213", value3: "08213", value4: "08213", name: "New Valley Bank & Trust (08213)" },
    { value: "08222", value2: "08222", value3: "08222", value4: "08213", name: "New Valley Bank & Trust - DC (08222)" },
    { value: "03938", value2: "03938", value3: "03938", value4: "03938", name: "New Washington State Bank (03938)" },
    { value: "68092", value2: "68092", value3: "68092", value4: "68092", name: "New Washington State Bk QBDC (68092)" },
    { value: "18022", value2: "18022", value3: "18022", value4: "18022", name: "New York Community Bank (18022)" },
    { value: "68352", value2: "68352", value3: "68352", value4: "68352", name: "New York Community Bank DC QB (68352)" },
    { value: "15246", value2: "15246", value3: "15246", value4: "15246", name: "NewFirst National Bank (15246)" },
    { value: "06566", value2: "06566", value3: "06566", value4: "06566", name: "NewFirst National Bank BO QB DC (06566)" },
    { value: "03442", value2: "03442", value3: "03442", value4: "03442", name: "Newburyport 5Cents Sv Bank (03442)" },
    { value: "12695", value2: "12695", value3: "12695", value4: "12695", name: "Newburyport Bank - Business Online (12695)" },
    { value: "67275", value2: "67275", value3: "67275", value4: "67275", name: "Newburyport Bank BeB DC (67275)" },
    { value: "19251", value2: "19251", value3: "19251", value4: "19251", name: "Newburyport Five Cents WC for QB (19251)" },
    { value: "00303", value2: "00303", value3: "00303", value4: "00303", name: "Newfield National Bank (00303)" },
    { value: "68347", value2: "68347", value3: "68347", value4: "68347", name: "Newport News Municipal ECU CC (68347)" },
    { value: "00962", value2: "00962", value3: "00962", value4: "00962", name: "Newton Federal Bank (00962)" },
    { value: "67100", value2: "67100", value3: "67100", value4: "00962", name: "Newton Federal Bank QBDC (67100)" },
    { value: "70031", value2: "70031", value3: "70031", value4: "70031", name: "Newtown Savings Bank (70031)" },
    { value: "70032", value2: "70032", value3: "70032", value4: "70031", name: "Newtown Savings Bank DC (70032)" },
    { value: "03678", value2: "03678", value3: "03678", value4: "03678", name: "NexBank (03678)" },
    { value: "52143", value2: "52143", value3: "52143", value4: "03678", name: "NexBank-DC (52143)" },
    { value: "00729", value2: "00729", value3: "00729", value4: "00729", name: "NexTier Bank (00729)" },
    { value: "05116", value2: "05116", value3: "05116", value4: "05116", name: "NexTier Bank Cash Manager (05116)" },
    { value: "68927", value2: "68927", value3: "68927", value4: "68927", name: "Niagara Regional FCU CC (68927)" },
    { value: "06200", value2: "06200", value3: "06200", value4: "06200", name: "Nicolet Bank Credit Card (06200)" },
    { value: "17868", value2: "17868", value3: "17868", value4: "17868", name: "Nicolet National Bank (17868)" },
    { value: "70035", value2: "70035", value3: "70035", value4: "70035", name: "Ninnescah Valley Bank (70035)" },
    { value: "09918", value2: "09918", value3: "09918", value4: "09918", name: "NobleBank & Trust - Business (09918)" },
    { value: "69973", value2: "69973", value3: "69973", value4: "69973", name: "Noda FCU (69973)" },
    { value: "00402", value2: "00402", value3: "00402", value4: "00402", name: "Nodaway Valley Bank - MO (00402)" },
    { value: "59943", value2: "59943", value3: "59943", value4: "59943", name: "NorState FCU (59943)" },
    { value: "70340", value2: "70340", value3: "70340", value4: "70340", name: "Normangee State Bank BO (70340)" },
    { value: "70339", value2: "70339", value3: "70339", value4: "70340", name: "Normangee State Bank BODC (70339)" },
    { value: "69046", value2: "69046", value3: "69046", value4: "69046", name: "Normangee State Bank RO (69046)" },
    { value: "70338", value2: "70338", value3: "70338", value4: "69046", name: "Normangee State Bank RODC (70338)" },
    { value: "70447", value2: "70447", value3: "70447", value4: "70447", name: "Norstar FCU (70447)" },
    { value: "70452", value2: "70452", value3: "70452", value4: "70447", name: "Norstar FCU - DC (70452)" },
    { value: "22983", value2: "22983", value3: "22983", value4: "22983", name: "North Adams State Bank (22983)" },
    { value: "04046", value2: "04046", value3: "04046", value4: "04046", name: "North Alabama Bank New (04046)" },
    { value: "18477", value2: "18477", value3: "18477", value4: "18477", name: "North Alabama Educators CU (18477)" },
    { value: "00732", value2: "00732", value3: "00732", value4: "00732", name: "North American Banking Co (00732)" },
    { value: "68813", value2: "68813", value3: "68813", value4: "68813", name: "North Carolina Comm FCU (68813)" },
    { value: "50118", value2: "50118", value3: "50118", value4: "50118", name: "North Central Area Credit Union (50118)" },
    { value: "57858", value2: "57858", value3: "57858", value4: "57858", name: "North Central Bank (57858)" },
    { value: "68932", value2: "68932", value3: "68932", value4: "68932", name: "North Central Bank QBDC (68932)" },
    { value: "08831", value2: "08831", value3: "08831", value4: "08831", name: "North Coast Credit Union - New (08831)" },
    { value: "19317", value2: "19317", value3: "19317", value4: "19317", name: "North Country Federal CU WC QB (19317)" },
    { value: "03500", value2: "03500", value3: "03500", value4: "03500", name: "North Country Savings Bank (03500)" },
    { value: "03509", value2: "03509", value3: "03509", value4: "03500", name: "North Country Savings Bank WC (03509)" },
    { value: "08323", value2: "08323", value3: "08323", value4: "08323", name: "North County CU WC QB (08323)" },
    { value: "01245", value2: "01245", value3: "01245", value4: "01245", name: "North Dallas Bank & Trust Co. (01245)" },
    { value: "59412", value2: "59412", value3: "59412", value4: "59412", name: "North Dallas Bank & Trust Co.-DC (59412)" },
    { value: "04238", value2: "04238", value3: "04238", value4: "04238", name: "North Easton Savings Bank (04238)" },
    { value: "63177", value2: "63177", value3: "63177", value4: "63177", name: "North Easton Savings Bank - Bus (63177)" },
    { value: "70225", value2: "70225", value3: "70225", value4: "70225", name: "North Georgia CU (70225)" },
    { value: "00177", value2: "00177", value3: "00177", value4: "00177", name: "North Hawaii Community FCU (00177)" },
    { value: "07007", value2: "07007", value3: "07007", value4: "07007", name: "North Iowa Community Credit Union (07007)" },
    { value: "27165", value2: "27165", value3: "27165", value4: "27165", name: "North Salem State Bank (27165)" },
    { value: "05753", value2: "05753", value3: "05753", value4: "05753", name: "North Salem State Bank-DL-New (05753)" },
    { value: "62547", value2: "62547", value3: "62547", value4: "62547", name: "North Shore Bank (62547)" },
    { value: "62550", value2: "62550", value3: "62550", value4: "62547", name: "North Shore Bank - BUS (62550)" },
    { value: "63264", value2: "63264", value3: "63264", value4: "63264", name: "North Shore Bank FSB - QB (63264)" },
    { value: "04199", value2: "04199", value3: "04199", value4: "04199", name: "North Shore Bank FSB-QB DC (04199)" },
    { value: "50793", value2: "50793", value3: "50793", value4: "50793", name: "North Shore Bank of Commerce-MN (50793)" },
    { value: "03199", value2: "03199", value3: "03199", value4: "03199", name: "North Shore Comm Bank & Trust (03199)" },
    { value: "67894", value2: "67894", value3: "67894", value4: "67894", name: "North Shore FCU (Silver Bay, MN) (67894)" },
    { value: "01826", value2: "01826", value3: "01826", value4: "01826", name: "North Side Bank and Trust Co. (01826)" },
    { value: "03180", value2: "03180", value3: "03180", value4: "03180", name: "North Star Bank, MN (03180)" },
    { value: "56391", value2: "56391", value3: "56391", value4: "56385", name: "North Star Comm CU Cherokee QBDC (56391)" },
    { value: "56385", value2: "56385", value3: "56385", value4: "56385", name: "North Star Community CU Cherokee (56385)" },
    { value: "18418", value2: "18418", value3: "18418", value4: "18418", name: "North State Bank - New (18418)" },
    { value: "08489", value2: "08489", value3: "08489", value4: "18418", name: "North State Bank - QBWC (08489)" },
    { value: "01330", value2: "01330", value3: "01330", value4: "01330", name: "North Valley Bank (01330)" },
    { value: "04055", value2: "04055", value3: "04055", value4: "04055", name: "North Valley Bank OH (04055)" },
    { value: "00797", value2: "00797", value3: "00797", value4: "00797", name: "North Valley Bank OH - WC (00797)" },
    { value: "53472", value2: "53472", value3: "53472", value4: "53472", name: "NorthEast Community BK QB (53472)" },
    { value: "64869", value2: "64869", value3: "64869", value4: "64869", name: "NorthEast Community Bank QB (64869)" },
    { value: "04954", value2: "04954", value3: "04954", value4: "04954", name: "NorthPark Community  CU (04954)" },
    { value: "06287", value2: "06287", value3: "06287", value4: "06287", name: "NorthStar Bank Iowa (06287)" },
    { value: "04479", value2: "04479", value3: "04479", value4: "04479", name: "Northbrook Bank & Trust Bus (04479)" },
    { value: "03885", value2: "03885", value3: "03885", value4: "03885", name: "Northbrook Bank & Trust IBB (03885)" },
    { value: "01278", value2: "01278", value3: "01278", value4: "01278", name: "Northeast Bank - Bus (01278)" },
    { value: "68095", value2: "68095", value3: "68095", value4: "68095", name: "Northeast Bank MN Retail (68095)" },
    { value: "10753", value2: "10753", value3: "10753", value4: "10753", name: "Northeast Bank, Maine (10753)" },
    { value: "65448", value2: "65448", value3: "65448", value4: "10753", name: "Northeast Bank, Maine - DL (65448)" },
    { value: "11809", value2: "11809", value3: "11809", value4: "11809", name: "Northeast Credit Union (11809)" },
    { value: "01202", value2: "01202", value3: "01202", value4: "01202", name: "Northeast Security Bank (01202)" },
    { value: "18772", value2: "18772", value3: "18772", value4: "18772", name: "Northern Bank & Trust Company PFM (18772)" },
    { value: "16692", value2: "16692", value3: "16692", value4: "16692", name: "Northern Bank and Trust - BIB (16692)" },
    { value: "50865", value2: "50865", value3: "50865", value4: "50865", name: "Northern CU - NY (50865)" },
    { value: "51351", value2: "51351", value3: "51351", value4: "51351", name: "Northern California National Bank (51351)" },
    { value: "00200", value2: "00200", value3: "00200", value4: "00200", name: "Northern Colorado Credit Union (00200)" },
    { value: "17544", value2: "17544", value3: "17544", value4: "17544", name: "Northern Hills FCU (17544)" },
    { value: "55881", value2: "55881", value3: "55881", value4: "55881", name: "Northern Lights Credit Union (55881)" },
    { value: "12354", value2: "12354", value3: "12354", value4: "12354", name: "Northern Skies FCU (12354)" },
    { value: "06422", value2: "06422", value3: "06422", value4: "06422", name: "Northern State Bank (06422)" },
    { value: "70102", value2: "70102", value3: "70102", value4: "70102", name: "Northern State Bank of TRF (70102)" },
    { value: "70104", value2: "70104", value3: "70104", value4: "70102", name: "Northern State Bank of TRF-DC (70104)" },
    { value: "05642", value2: "05642", value3: "05642", value4: "05642", name: "Northern Trust (05642)" },
    { value: "56634", value2: "56634", value3: "56634", value4: "50397", name: "Northfield Bank Direct (56634)" },
    { value: "50397", value2: "50397", value3: "50397", value4: "50397", name: "Northfield Bank, Staten Island (50397)" },
    { value: "62253", value2: "62253", value3: "62253", value4: "62253", name: "Northfield Savings Bank (62253)" },
    { value: "67151", value2: "67151", value3: "67151", value4: "62253", name: "Northfield Savings Bank - DC QB (67151)" },
    { value: "01190", value2: "01190", value3: "01190", value4: "01190", name: "Northland Area FCU (01190)" },
    { value: "05338", value2: "05338", value3: "05338", value4: "05338", name: "Northrim Bank BeB QB (05338)" },
    { value: "05166", value2: "05166", value3: "05166", value4: "05166", name: "Northrop Grumman FCU (05166)" },
    { value: "16578", value2: "16578", value3: "16578", value4: "16578", name: "Northside Bank (16578)" },
    { value: "56442", value2: "56442", value3: "56442", value4: "16578", name: "Northside Bank  - DC (56442)" },
    { value: "18114", value2: "18114", value3: "18114", value4: "18114", name: "Northside Community Bank (18114)" },
    { value: "11960", value2: "11960", value3: "11960", value4: "11960", name: "Northside Community Bank Bus (11960)" },
    { value: "05222", value2: "05222", value3: "05222", value4: "05222", name: "Northstar Bank of Michigan (05222)" },
    { value: "05225", value2: "05225", value3: "05225", value4: "05222", name: "Northstar Bank of Michigan-QB (05225)" },
    { value: "18987", value2: "18987", value3: "18987", value4: "18987", name: "Northumberland National Bank (18987)" },
    { value: "70095", value2: "70095", value3: "70095", value4: "70095", name: "Northumberland National Bank DC (70095)" },
    { value: "51885", value2: "51885", value3: "51885", value4: "51885", name: "Northview Bank (51885)" },
    { value: "01790", value2: "01790", value3: "01790", value4: "01790", name: "Northview Bank Credit Card (01790)" },
    { value: "00989", value2: "00989", value3: "00989", value4: "00989", name: "Northview Bank-Business (00989)" },
    { value: "10467", value2: "10467", value3: "10467", value4: "10467", name: "Northway Bank (10467)" },
    { value: "16138", value2: "16138", value3: "16138", value4: "16138", name: "Northwest (16138)" },
    { value: "37743", value2: "37743", value3: "37743", value4: "37743", name: "Northwest Arkansas FCU (37743)" },
    { value: "50154", value2: "50154", value3: "50154", value4: "50154", name: "Northwest Bank (50154)" },
    { value: "11975", value2: "11975", value3: "11975", value4: "11975", name: "Northwest Bank & Trust Co Business (11975)" },
    { value: "06941", value2: "06941", value3: "06941", value4: "06941", name: "Northwest Bank - Business (06941)" },
    { value: "70468", value2: "70468", value3: "70468", value4: "70468", name: "Northwest Bank Bus CC (70468)" },
    { value: "68690", value2: "68690", value3: "68690", value4: "68690", name: "Northwest Bank Credit Card (68690)" },
    { value: "68544", value2: "68544", value3: "68544", value4: "68544", name: "Northwest Bank DC (68544)" },
    { value: "67324", value2: "67324", value3: "67324", value4: "67324", name: "Northwest Bank IA-NE Business DC (67324)" },
    { value: "69695", value2: "69695", value3: "69695", value4: "06941", name: "Northwest Bank QB DC (69695)" },
    { value: "69801", value2: "69801", value3: "69801", value4: "69801", name: "Northwest Bank Treasury Pro (69801)" },
    { value: "69802", value2: "69802", value3: "69802", value4: "69802", name: "Northwest Bank Treasury Pro DC (69802)" },
    { value: "68543", value2: "68543", value3: "68543", value4: "68543", name: "Northwest Bank WC (68543)" },
    { value: "50862", value2: "50862", value3: "50862", value4: "50862", name: "Northwest Bank of Rockford (50862)" },
    { value: "02811", value2: "02811", value3: "02811", value4: "02811", name: "Northwest Bank, Personal Banking (02811)" },
    { value: "05612", value2: "05612", value3: "05612", value4: "05612", name: "Northwest Christian CU (05612)" },
    { value: "03434", value2: "03434", value3: "03434", value4: "03434", name: "Northwest Community Bank (03434)" },
    { value: "59724", value2: "59724", value3: "59724", value4: "59724", name: "Northwest Community Bank - QB (59724)" },
    { value: "56733", value2: "56733", value3: "56733", value4: "56733", name: "Northwest Community CU (56733)" },
    { value: "68245", value2: "68245", value3: "68245", value4: "68245", name: "Northwest Community CU - QB DC (68245)" },
    { value: "70434", value2: "70434", value3: "70434", value4: "70434", name: "Northwest Community CU Bus CC (70434)" },
    { value: "00254", value2: "00254", value3: "00254", value4: "00254", name: "Northwest Community CU CR Card (00254)" },
    { value: "09077", value2: "09077", value3: "09077", value4: "09077", name: "Northwest Consumers Fed CU (09077)" },
    { value: "10919", value2: "10919", value3: "10919", value4: "10919", name: "Northwest Federal Credit Union (10919)" },
    { value: "16141", value2: "16141", value3: "16141", value4: "16138", name: "Northwest QBDC (16141)" },
    { value: "63585", value2: "63585", value3: "63585", value4: "63585", name: "Northwestern Bank BOB (63585)" },
    { value: "09455", value2: "09455", value3: "09455", value4: "09455", name: "Northwestern Bank BOB Direct (09455)" },
    { value: "02349", value2: "02349", value3: "02349", value4: "02349", name: "Norway Savings Bank (02349)" },
    { value: "04679", value2: "04679", value3: "04679", value4: "04679", name: "Norwood Bank Business (04679)" },
    { value: "68485", value2: "68485", value3: "68485", value4: "68485", name: "Noteworthy FCU (68485)" },
    { value: "51645", value2: "51645", value3: "51645", value4: "51645", name: "Notre Dame FCU (51645)" },
    { value: "70513", value2: "70513", value3: "70513", value4: "70513", name: "Nova Bank (70513)" },
    { value: "70514", value2: "70514", value3: "70514", value4: "70513", name: "Nova Bank-DC (70514)" },
    { value: "10645", value2: "10645", value3: "10645", value4: "10645", name: "Nova Credit Union (10645)" },
    { value: "70187", value2: "70187", value3: "70187", value4: "70187", name: "Nova UA FCU (70187)" },
    { value: "70188", value2: "70188", value3: "70188", value4: "70187", name: "Nova UA FCU - DC (70188)" },
    { value: "50757", value2: "50757", value3: "50757", value4: "50757", name: "Novation CU Credit Card OA (50757)" },
    { value: "11994", value2: "11994", value3: "11994", value4: "11994", name: "Novation Credit Union (11994)" },
    { value: "15601", value2: "15601", value3: "15601", value4: "15601", name: "Novation-Quickbooks (15601)" },
    { value: "69454", value2: "69454", value3: "69454", value4: "69454", name: "Novo EWC (69454)" },
    { value: "15712", value2: "15712", value3: "15712", value4: "15712", name: "NuMark CU Joliet (15712)" },
    { value: "16990", value2: "16990", value3: "16990", value4: "16990", name: "NuVista Federal Credit Union (16990)" },
    { value: "52839", value2: "52839", value3: "52839", value4: "52839", name: "Numerica Credit Union (52839)" },
    { value: "68443", value2: "68443", value3: "68443", value4: "52839", name: "Numerica Credit Union QBDC (68443)" },
    { value: "64284", value2: "64284", value3: "64284", value4: "64284", name: "Nusenda CU (64284)" },
    { value: "55722", value2: "55722", value3: "55722", value4: "55722", name: "Nutmeg State FCU - New (55722)" },
    { value: "09974", value2: "09974", value3: "09974", value4: "09974", name: "Nuvision Credit Union (09974)" },
    { value: "07027", value2: "07027", value3: "07027", value4: "07027", name: "O Bee Credit Union (07027)" },
    { value: "02017", value2: "02017", value3: "02017", value4: "02017", name: "O'Bannon Bank (02017)" },
    { value: "14235", value2: "14235", value3: "14235", value4: "14235", name: "OE Federal Credit Union (14235)" },
    { value: "52644", value2: "52644", value3: "52644", value4: "52644", name: "OMB Bank (52644)" },
    { value: "09296", value2: "09296", value3: "09296", value4: "09296", name: "OMB Bank-DC (09296)" },
    { value: "68833", value2: "68833", value3: "68833", value4: "68833", name: "ONB Business (68833)" },
    { value: "68834", value2: "68834", value3: "68834", value4: "68834", name: "ONB Business DC (68834)" },
    { value: "68831", value2: "68831", value3: "68831", value4: "68831", name: "ONB Consumer (68831)" },
    { value: "68832", value2: "68832", value3: "68832", value4: "68832", name: "ONB Consumer DC (68832)" },
    { value: "70083", value2: "70083", value3: "70083", value4: "20133", name: "ONE COMMUNITY FCU - DC (70083)" },
    { value: "08228", value2: "08228", value3: "08228", value4: "08228", name: "ONE Federal Credit Union (08228)" },
    { value: "12462", value2: "12462", value3: "12462", value4: "12462", name: "ORNL Federal Credit Union (12462)" },
    { value: "28233", value2: "28233", value3: "28233", value4: "28233", name: "OSB Community Bank (28233)" },
    { value: "69532", value2: "69532", value3: "69532", value4: "69532", name: "OSB Community Bank - DC (69532)" },
    { value: "53835", value2: "53835", value3: "53835", value4: "53835", name: "Oak Bank - WI (53835)" },
    { value: "55530", value2: "55530", value3: "55530", value4: "53835", name: "Oak Bank - WI Direct (55530)" },
    { value: "14142", value2: "14142", value3: "14142", value4: "14142", name: "Oak Valley Community Bank (14142)" },
    { value: "69106", value2: "69106", value3: "69106", value4: "69106", name: "Oak Valley Community Bank - DC (69106)" },
    { value: "69956", value2: "69956", value3: "69956", value4: "69956", name: "Oak Valley Community Bank Bus DC (69956)" },
    { value: "69955", value2: "69955", value3: "69955", value4: "69955", name: "Oak Valley Community Bank Bus WC (69955)" },
    { value: "68239", value2: "68239", value3: "68239", value4: "68239", name: "Oak View National Bank (68239)" },
    { value: "68240", value2: "68240", value3: "68240", value4: "68240", name: "Oak View National Bank DC (68240)" },
    { value: "13545", value2: "13545", value3: "13545", value4: "13545", name: "Oakstar Bancshares (13545)" },
    { value: "03230", value2: "03230", value3: "03230", value4: "03230", name: "Oakwood Bank (03230)" },
    { value: "09071", value2: "09071", value3: "09071", value4: "09071", name: "Oakwood Bank - Commercial (09071)" },
    { value: "67853", value2: "67853", value3: "67853", value4: "09071", name: "Oakwood Bank - Commercial - TX (67853)" },
    { value: "16494", value2: "16494", value3: "16494", value4: "16494", name: "Oakworth Capital Bank (16494)" },
    { value: "57315", value2: "57315", value3: "57315", value4: "16494", name: "Oakworth Capital Bank-DC (57315)" },
    { value: "04291", value2: "04291", value3: "04291", value4: "04291", name: "Ocean Bank Florida (04291)" },
    { value: "09511", value2: "09511", value3: "09511", value4: "09511", name: "Ocean State CU-DC WC (09511)" },
    { value: "08983", value2: "08983", value3: "08983", value4: "08983", name: "Ocean State Credit Union (08983)" },
    { value: "68708", value2: "68708", value3: "68708", value4: "68708", name: "OceanFirst Bank NA BB (68708)" },
    { value: "68426", value2: "68426", value3: "68426", value4: "68426", name: "OceanFirst Bank NJ (68426)" },
    { value: "06268", value2: "06268", value3: "06268", value4: "06268", name: "OceanFirst Bank WC QB BB (06268)" },
    { value: "69099", value2: "69099", value3: "69099", value4: "69099", name: "OceanFirst OFBCONNECT DC (69099)" },
    { value: "69624", value2: "69624", value3: "69624", value4: "69624", name: "OceanFirst OFBCONNECT WC (69624)" },
    { value: "62688", value2: "62688", value3: "62688", value4: "62688", name: "Oconee Federal (62688)" },
    { value: "62724", value2: "62724", value3: "62724", value4: "62724", name: "Oconee Federal Business (62724)" },
    { value: "04944", value2: "04944", value3: "04944", value4: "04944", name: "Oconee State Bank (04944)" },
    { value: "69477", value2: "69477", value3: "69477", value4: "69477", name: "Oconee State Bank-DC (69477)" },
    { value: "64839", value2: "64839", value3: "64839", value4: "64839", name: "Ohana Pacific Bank (64839)" },
    { value: "64842", value2: "64842", value3: "64842", value4: "64839", name: "Ohana Pacific Bnk_DC (64842)" },
    { value: "07838", value2: "07838", value3: "07838", value4: "07838", name: "Ohio Healthcare FCU - new (07838)" },
    { value: "07835", value2: "07835", value3: "07835", value4: "07838", name: "Ohio Healthcare FCU_WC (07835)" },
    { value: "08414", value2: "08414", value3: "08414", value4: "08414", name: "Ohio State Bank BO (08414)" },
    { value: "12185", value2: "12185", value3: "12185", value4: "12185", name: "Ohio State Bank BO DC (12185)" },
    { value: "58977", value2: "58977", value3: "58977", value4: "58977", name: "Ohio University CU (58977)" },
    { value: "58980", value2: "58980", value3: "58980", value4: "58977", name: "Ohio University CU -QB (58980)" },
    { value: "04766", value2: "04766", value3: "04766", value4: "04766", name: "Ohio Valley Bank (04766)" },
    { value: "69646", value2: "69646", value3: "69646", value4: "04766", name: "Ohio Valley Bank-DC (69646)" },
    { value: "67134", value2: "67134", value3: "67134", value4: "67134", name: "Ohio Valley Community FCU (67134)" },
    { value: "67135", value2: "67135", value3: "67135", value4: "67135", name: "Ohio Valley Community FCU QB (67135)" },
    { value: "59691", value2: "59691", value3: "59691", value4: "59691", name: "Ohio Valley FCU Credit Card (59691)" },
    { value: "13503", value2: "13503", value3: "13503", value4: "13503", name: "Ohnward Bank & Trust (13503)" },
    { value: "06044", value2: "06044", value3: "06044", value4: "06044", name: "Oklahoma Bank & Trust Company (06044)" },
    { value: "09764", value2: "09764", value3: "09764", value4: "09764", name: "Oklahoma Capital Bank BO DC (09764)" },
    { value: "09758", value2: "09758", value3: "09758", value4: "09758", name: "Oklahoma Capital Bank BO WC (09758)" },
    { value: "09761", value2: "09761", value3: "09761", value4: "09761", name: "Oklahoma Capital Bank RO DC (09761)" },
    { value: "09755", value2: "09755", value3: "09755", value4: "09755", name: "Oklahoma Capital Bank RO WC (09755)" },
    { value: "54198", value2: "54198", value3: "54198", value4: "54198", name: "Oklahoma Central Credit Union QB (54198)" },
    { value: "68780", value2: "68780", value3: "68780", value4: "68780", name: "Oklahoma Heritage Bank BO (68780)" },
    { value: "67292", value2: "67292", value3: "67292", value4: "01968", name: "Oklahoma's CU (67292)" },
    { value: "01968", value2: "01968", value3: "01968", value4: "01968", name: "Oklahoma's Credit Union (01968)" },
    { value: "06383", value2: "06383", value3: "06383", value4: "06383", name: "Old Dominion National Bank BeB DC (06383)" },
    { value: "62766", value2: "62766", value3: "62766", value4: "62766", name: "Old Dominion National Bank-Bus (62766)" },
    { value: "16803", value2: "16803", value3: "16803", value4: "16803", name: "Old Fort Banking Company (16803)" },
    { value: "05072", value2: "05072", value3: "05072", value4: "05072", name: "Old Fort Banking Company-DC (05072)" },
    { value: "70084", value2: "70084", value3: "70084", value4: "70084", name: "Old Glory Bank (70084)" },
    { value: "70085", value2: "70085", value3: "70085", value4: "70084", name: "Old Glory Bank QBDC (70085)" },
    { value: "00762", value2: "00762", value3: "00762", value4: "00762", name: "Old Hickory Credit Union (00762)" },
    { value: "69716", value2: "69716", value3: "69716", value4: "69716", name: "Old Missouri Bank-TS (69716)" },
    { value: "69717", value2: "69717", value3: "69717", value4: "69716", name: "Old Missouri Bank-TS-DC (69717)" },
    { value: "15583", value2: "15583", value3: "15583", value4: "15583", name: "Old National (15583)" },
    { value: "15586", value2: "15586", value3: "15586", value4: "15586", name: "Old National - WCQB (15586)" },
    { value: "62811", value2: "62811", value3: "62811", value4: "62811", name: "Old National BankConnect Plus (62811)" },
    { value: "69334", value2: "69334", value3: "69334", value4: "69334", name: "Old National ONPointe DC (69334)" },
    { value: "07943", value2: "07943", value3: "07943", value4: "07943", name: "Old National ONPointe WC (07943)" },
    { value: "04434", value2: "04434", value3: "04434", value4: "04434", name: "Old Plank Trail Bank Bus (04434)" },
    { value: "03912", value2: "03912", value3: "03912", value4: "03912", name: "Old Plank Trail Bank IBB (03912)" },
    { value: "11010", value2: "11010", value3: "11010", value4: "11010", name: "Old Point National Bank (11010)" },
    { value: "68000", value2: "68000", value3: "68000", value4: "11010", name: "Old Point National Bank - QBDC (68000)" },
    { value: "04791", value2: "04791", value3: "04791", value4: "04791", name: "Old Second National Bank - Retail (04791)" },
    { value: "07814", value2: "07814", value3: "07814", value4: "04791", name: "Old Second National Bank DC (07814)" },
    { value: "67246", value2: "67246", value3: "67246", value4: "67246", name: "Old Second National Bank Ret DC (67246)" },
    { value: "67245", value2: "67245", value3: "67245", value4: "67245", name: "Old Second National Bank Retail (67245)" },
    { value: "68439", value2: "68439", value3: "68439", value4: "68439", name: "Old Second National Bank-TM (68439)" },
    { value: "68440", value2: "68440", value3: "68440", value4: "68439", name: "Old Second National Bank-TM-DC (68440)" },
    { value: "17418", value2: "17418", value3: "17418", value4: "17418", name: "Old West Federal Credit Union (17418)" },
    { value: "69930", value2: "69930", value3: "69930", value4: "69930", name: "Olympia Credit Union (69930)" },
    { value: "03984", value2: "03984", value3: "03984", value4: "03984", name: "Olympia Federal Savings and Loan (03984)" },
    { value: "68533", value2: "68533", value3: "68533", value4: "68533", name: "Omaha FCU CC (68533)" },
    { value: "20190", value2: "20190", value3: "20190", value4: "20190", name: "Omaha Federal Credit Union (20190)" },
    { value: "14184", value2: "14184", value3: "14184", value4: "14184", name: "On Tap Credit Union (14184)" },
    { value: "68184", value2: "68184", value3: "68184", value4: "68184", name: "OnPath CU - WC (68184)" },
    { value: "68183", value2: "68183", value3: "68183", value4: "68183", name: "OnPath Credit Union (68183)" },
    { value: "16936", value2: "16936", value3: "16936", value4: "16936", name: "OnPoint Community CU- New (16936)" },
    { value: "16939", value2: "16939", value3: "16939", value4: "16939", name: "OnPoint Community CU- QB (16939)" },
    { value: "03989", value2: "03989", value3: "03989", value4: "03989", name: "One American Bank (03989)" },
    { value: "68799", value2: "68799", value3: "68799", value4: "68799", name: "One Bank of Tennessee (68799)" },
    { value: "67267", value2: "67267", value3: "67267", value4: "67267", name: "One Community Bank (67267)" },
    { value: "67268", value2: "67268", value3: "67268", value4: "67268", name: "One Community Bank QBDC (67268)" },
    { value: "20133", value2: "20133", value3: "20133", value4: "20133", name: "One Community FCU (20133)" },
    { value: "18739", value2: "18739", value3: "18739", value4: "18739", name: "One Credit Union - DC (18739)" },
    { value: "18261", value2: "18261", value3: "18261", value4: "18261", name: "One Florida Bank (18261)" },
    { value: "67232", value2: "67232", value3: "67232", value4: "67232", name: "One Florida Bank-TM (67232)" },
    { value: "67233", value2: "67233", value3: "67233", value4: "67232", name: "One Florida Bank-TM-DC (67233)" },
    { value: "11541", value2: "11541", value3: "11541", value4: "10888", name: "One Nevada CU - DC (11541)" },
    { value: "10888", value2: "10888", value3: "10888", value4: "11541", name: "One Nevada Credit Union (10888)" },
    { value: "03144", value2: "03144", value3: "03144", value4: "03144", name: "One World Bank (03144)" },
    { value: "68304", value2: "68304", value3: "68304", value4: "68304", name: "OneAZ CU (68304)" },
    { value: "62403", value2: "62403", value3: "62403", value4: "62403", name: "OneSouth Bank (62403)" },
    { value: "62583", value2: "62583", value3: "62583", value4: "62583", name: "Ontario Shores FCU - BUS (62583)" },
    { value: "45870", value2: "45870", value3: "45870", value4: "45870", name: "Ontario Shores FCU - New (45870)" },
    { value: "09062", value2: "09062", value3: "09062", value4: "09062", name: "Ontario-Montclair School FCU (09062)" },
    { value: "13044", value2: "13044", value3: "13044", value4: "13044", name: "Oostburg State Bank (13044)" },
    { value: "06953", value2: "06953", value3: "06953", value4: "06953", name: "Open Bank (06953)" },
    { value: "07088", value2: "07088", value3: "07088", value4: "07088", name: "Open Bank-Business QBWC (07088)" },
    { value: "09433", value2: "09433", value3: "09433", value4: "09433", name: "Opportunity Bank of MT-Business (09433)" },
    { value: "07873", value2: "07873", value3: "07873", value4: "07873", name: "Opportunity Bank of Montana (07873)" },
    { value: "03395", value2: "03395", value3: "03395", value4: "03395", name: "Optimum Bank (03395)" },
    { value: "01633", value2: "01633", value3: "01633", value4: "01633", name: "Orange Bank & Trust (01633)" },
    { value: "68996", value2: "68996", value3: "68996", value4: "68996", name: "Orange Bank and Trust (68996)" },
    { value: "18454", value2: "18454", value3: "18454", value4: "18454", name: "Orange County's Credit Union (18454)" },
    { value: "51012", value2: "51012", value3: "51012", value4: "51012", name: "Oregon Coast Bank (51012)" },
    { value: "13371", value2: "13371", value3: "13371", value4: "13371", name: "Oregon Pacific Banking Company (13371)" },
    { value: "04130", value2: "04130", value3: "04130", value4: "04130", name: "Oregon State Credit Union (04130)" },
    { value: "70251", value2: "70251", value3: "70251", value4: "70251", name: "Oregon State Credit Union BB DC (70251)" },
    { value: "70252", value2: "70252", value3: "70252", value4: "70252", name: "Oregon State Credit Union BB WC (70252)" },
    { value: "54132", value2: "54132", value3: "54132", value4: "54132", name: "Oregon State Credit Union QBO (54132)" },
    { value: "69996", value2: "69996", value3: "69996", value4: "69996", name: "Oregonians Credit Union (69996)" },
    { value: "69997", value2: "69997", value3: "69997", value4: "69996", name: "Oregonians Credit Union - QBWC (69997)" },
    { value: "52260", value2: "52260", value3: "52260", value4: "52260", name: "Organized Labor CU Credit Card (52260)" },
    { value: "19800", value2: "19800", value3: "19800", value4: "19800", name: "Origin Bank - WC (19800)" },
    { value: "63501", value2: "63501", value3: "63501", value4: "63501", name: "Origin Bank-PFM (63501)" },
    { value: "63690", value2: "63690", value3: "63690", value4: "63690", name: "Origin Bank-WC (63690)" },
    { value: "69384", value2: "69384", value3: "69384", value4: "02726", name: "Orion Federal CU QBDC (69384)" },
    { value: "02726", value2: "02726", value3: "02726", value4: "02726", name: "Orion Federal Credit Union (02726)" },
    { value: "70238", value2: "70238", value3: "70238", value4: "70238", name: "Orlando Credit Union BB QB (70238)" },
    { value: "01349", value2: "01349", value3: "01349", value4: "01349", name: "Orrstown Bank (01349)" },
    { value: "05120", value2: "05120", value3: "05120", value4: "05120", name: "Orrstown Bank-DC (05120)" },
    { value: "69612", value2: "69612", value3: "69612", value4: "69612", name: "Orrstown Bank-TM (69612)" },
    { value: "69613", value2: "69613", value3: "69613", value4: "69613", name: "Orrstown Bank-TM-DC (69613)" },
    { value: "02174", value2: "02174", value3: "02174", value4: "02174", name: "Osgood State Bank (02174)" },
    { value: "53007", value2: "53007", value3: "53007", value4: "53007", name: "Oswego County FCU (53007)" },
    { value: "57459", value2: "57459", value3: "57459", value4: "57459", name: "Otter Tail CU (57459)" },
    { value: "57462", value2: "57462", value3: "57462", value4: "57459", name: "Otter Tail CU_DC (57462)" },
    { value: "25731", value2: "25731", value3: "25731", value4: "25731", name: "Ouachita Valley FCU (25731)" },
    { value: "69570", value2: "69570", value3: "69570", value4: "25731", name: "Ouachita Valley FCU - QB (69570)" },
    { value: "06078", value2: "06078", value3: "06078", value4: "06078", name: "Our Community Credit Union (06078)" },
    { value: "12251", value2: "12251", value3: "12251", value4: "12251", name: "Our Community Credit Union QBWC (12251)" },
    { value: "04597", value2: "04597", value3: "04597", value4: "04597", name: "Our Credit Union (04597)" },
    { value: "54135", value2: "54135", value3: "54135", value4: "54135", name: "Outdoor Bank - KS (54135)" },
    { value: "69819", value2: "69819", value3: "69819", value4: "54135", name: "Outdoor Bank - KS-DC (69819)" },
    { value: "69934", value2: "69934", value3: "69934", value4: "69934", name: "Outdoor Bank-TM (69934)" },
    { value: "69935", value2: "69935", value3: "69935", value4: "69935", name: "Outdoor Bank-TM-DC (69935)" },
    { value: "53310", value2: "53310", value3: "53310", value4: "53310", name: "Owen County State Bank (53310)" },
    { value: "69875", value2: "69875", value3: "69875", value4: "69875", name: "Owners's Bank (69875)" },
    { value: "01332", value2: "01332", value3: "01332", value4: "01332", name: "Oxford Bank (01332)" },
    { value: "04847", value2: "04847", value3: "04847", value4: "04847", name: "Oxford Bank-Direct (04847)" },
    { value: "20262", value2: "20262", value3: "20262", value4: "20262", name: "Oxford University Bank (20262)" },
    { value: "16785", value2: "16785", value3: "16785", value4: "16785", name: "Ozona Bank (16785)" },
    { value: "70063", value2: "70063", value3: "70063", value4: "70063", name: "Ozona Bank-DC (70063)" },
    { value: "02984", value2: "02984", value3: "02984", value4: "02984", name: "PALCO FCU Credit Card (02984)" },
    { value: "67832", value2: "67832", value3: "67832", value4: "67832", name: "PB Direct - BeB (67832)" },
    { value: "65256", value2: "65256", value3: "65256", value4: "65256", name: "PB&T Bank Biz (65256)" },
    { value: "67724", value2: "67724", value3: "67724", value4: "67724", name: "PB&T Bank Biz QBDC (67724)" },
    { value: "25785", value2: "25785", value3: "25785", value4: "25785", name: "PB&T Bank Personal (25785)" },
    { value: "68115", value2: "68115", value3: "68115", value4: "25785", name: "PB&T Bank Personal QBDC (68115)" },
    { value: "69768", value2: "69768", value3: "69768", value4: "69767", name: "PBK BANK, INC. QBDC (69768)" },
    { value: "69767", value2: "69767", value3: "69767", value4: "69767", name: "PBK Bank Inc. (69767)" },
    { value: "64197", value2: "64197", value3: "64197", value4: "64197", name: "PCB Bank (64197)" },
    { value: "63807", value2: "63807", value3: "63807", value4: "63807", name: "PCB Bank WC QB (63807)" },
    { value: "18601", value2: "18601", value3: "18601", value4: "18601", name: "PCSB (18601)" },
    { value: "16303", value2: "16303", value3: "16303", value4: "16303", name: "PCSB Bank (16303)" },
    { value: "58278", value2: "58278", value3: "58278", value4: "58278", name: "PCSB Bank - NY (58278)" },
    { value: "08390", value2: "08390", value3: "08390", value4: "08390", name: "PCSB Bank DC (08390)" },
    { value: "69885", value2: "69885", value3: "69885", value4: "69885", name: "PCSB Bank WC (69885)" },
    { value: "09650", value2: "09650", value3: "09650", value4: "09650", name: "PERU (09650)" },
    { value: "14059", value2: "14059", value3: "14059", value4: "14059", name: "PEX Card Employee Expense Mgmt (14059)" },
    { value: "11991", value2: "11991", value3: "11991", value4: "11991", name: "PFCU (11991)" },
    { value: "13996", value2: "13996", value3: "13996", value4: "13996", name: "PFCU WebConnect QuickBooks (13996)" },
    { value: "18877", value2: "18877", value3: "18877", value4: "18877", name: "PNB Community Bank (18877)" },
    { value: "12562", value2: "12562", value3: "12562", value4: "12562", name: "PNC Account View (12562)" },
    { value: "14740", value2: "14740", value3: "14740", value4: "14740", name: "PNC Advanced Reporting Tool (14740)" },
    { value: "04501", value2: "04501", value3: "04501", value4: "04501", name: "PNC Bank - Direct Connect (04501)" },
    { value: "07138", value2: "07138", value3: "07138", value4: "07138", name: "PNC Bank - Web Connect (07138)" },
    { value: "01870", value2: "01870", value3: "01870", value4: "01870", name: "PNC Card Services Online (01870)" },
    { value: "18208", value2: "18208", value3: "18208", value4: "18208", name: "PNC-PINACLE (18208)" },
    { value: "14637", value2: "14637", value3: "14637", value4: "14637", name: "PSCCU (14637)" },
    { value: "69692", value2: "69692", value3: "69692", value4: "14637", name: "PSCCU - QBWC (69692)" },
    { value: "19581", value2: "19581", value3: "19581", value4: "19581", name: "PSE Credit Union (19581)" },
    { value: "57936", value2: "57936", value3: "57936", value4: "57936", name: "PVNB - Business (57936)" },
    { value: "57933", value2: "57933", value3: "57933", value4: "57933", name: "PVNB - Personal (57933)" },
    { value: "10421", value2: "10421", value3: "10421", value4: "10421", name: "Pacific Cascade FCU (10421)" },
    { value: "68938", value2: "68938", value3: "68938", value4: "68938", name: "Pacific Cascade FCU CC (68938)" },
    { value: "08540", value2: "08540", value3: "08540", value4: "64197", name: "Pacific City Bank DC - Bus (08540)" },
    { value: "08567", value2: "08567", value3: "08567", value4: "08567", name: "Pacific Crest Banking (08567)" },
    { value: "08573", value2: "08573", value3: "08573", value4: "08573", name: "Pacific Crest Banking_DC (08573)" },
    { value: "08570", value2: "08570", value3: "08570", value4: "08570", name: "Pacific Crest Business Banking (08570)" },
    { value: "08576", value2: "08576", value3: "08576", value4: "08576", name: "Pacific Crest Business Banking _DC (08576)" },
    { value: "51807", value2: "51807", value3: "51807", value4: "51807", name: "Pacific Crest FCU (51807)" },
    { value: "16747", value2: "16747", value3: "16747", value4: "16747", name: "Pacific Postal Credit Union (16747)" },
    { value: "69652", value2: "69652", value3: "69652", value4: "16747", name: "Pacific Postal Credit Union QBDC (69652)" },
    { value: "52665", value2: "52665", value3: "52665", value4: "52665", name: "Pacific Premier Bus Online WEB (52665)" },
    { value: "63492", value2: "63492", value3: "63492", value4: "63492", name: "Pacific Premier Business Online DC (63492)" },
    { value: "62970", value2: "62970", value3: "62970", value4: "62970", name: "Pacific Premier Personal Online-DC (62970)" },
    { value: "11161", value2: "11161", value3: "11161", value4: "11161", name: "Pacific Premier Personal Online-WC (11161)" },
    { value: "11078", value2: "11078", value3: "11078", value4: "11078", name: "Pacific Service Credit Union (11078)" },
    { value: "14844", value2: "14844", value3: "14844", value4: "14844", name: "Pacific Valley Bank (14844)" },
    { value: "12704", value2: "12704", value3: "12704", value4: "12704", name: "Pacific Valley Bank Business DC (12704)" },
    { value: "31974", value2: "31974", value3: "31974", value4: "31974", name: "Pacific West Bank (31974)" },
    { value: "15502", value2: "15502", value3: "15502", value4: "15502", name: "Pacific Western Bank (15502)" },
    { value: "65283", value2: "65283", value3: "65283", value4: "65283", name: "Pacific Western Bank BEB (65283)" },
    { value: "05561", value2: "05561", value3: "05561", value4: "05561", name: "Pacific Western Bank- PFM DC (05561)" },
    { value: "56148", value2: "56148", value3: "56148", value4: "56148", name: "Paducah Bank and Trust Co (56148)" },
    { value: "70062", value2: "70062", value3: "70062", value4: "56148", name: "Paducah Bank and Trust Co QBDC (70062)" },
    { value: "12089", value2: "12089", value3: "12089", value4: "12089", name: "Pagoda  FCU (12089)" },
    { value: "42300", value2: "42300", value3: "42300", value4: "42300", name: "Pahranagat Valley FCU (42300)" },
    { value: "65502", value2: "65502", value3: "65502", value4: "65502", name: "Palisades Credit Union (65502)" },
    { value: "53373", value2: "53373", value3: "53373", value4: "53373", name: "Palmetto Citizens FCU (53373)" },
    { value: "18442", value2: "18442", value3: "18442", value4: "18442", name: "Palmetto Citizens FCU QB (18442)" },
    { value: "02597", value2: "02597", value3: "02597", value4: "02597", name: "Palmetto State Bank (02597)" },
    { value: "04421", value2: "04421", value3: "04421", value4: "04421", name: "Palmetto State Bank Business (04421)" },
    { value: "53580", value2: "53580", value3: "53580", value4: "53580", name: "Pan American Bank (53580)" },
    { value: "04331", value2: "04331", value3: "04331", value4: "04331", name: "Pan American Bank-Direct (04331)" },
    { value: "13932", value2: "13932", value3: "13932", value4: "13932", name: "Panhandle Educators FCU (13932)" },
    { value: "07850", value2: "07850", value3: "07850", value4: "07850", name: "Pantex Federal Credit Union (07850)" },
    { value: "54825", value2: "54825", value3: "54825", value4: "54825", name: "Pantex Federal Credit Union - QB (54825)" },
    { value: "10490", value2: "10490", value3: "10490", value4: "10490", name: "Paper City Savings (10490)" },
    { value: "67354", value2: "67354", value3: "67354", value4: "67354", name: "Paper City Savings Assoc (67354)" },
    { value: "68779", value2: "68779", value3: "68779", value4: "68779", name: "Paper City Savings Assoc Business (68779)" },
    { value: "68659", value2: "68659", value3: "68659", value4: "68659", name: "Paper City Savings Business (68659)" },
    { value: "03684", value2: "03684", value3: "03684", value4: "03684", name: "Paradise Bank (03684)" },
    { value: "17679", value2: "17679", value3: "17679", value4: "17679", name: "Paradise Valley FCU (17679)" },
    { value: "00186", value2: "00186", value3: "00186", value4: "00186", name: "Paragon Bank (00186)" },
    { value: "09794", value2: "09794", value3: "09794", value4: "09794", name: "Paramount Bank (09794)" },
    { value: "09788", value2: "09788", value3: "09788", value4: "09788", name: "Paramount Bank BUS (09788)" },
    { value: "69143", value2: "69143", value3: "69143", value4: "55980", name: "Parishioners Federal CU DC (69143)" },
    { value: "55980", value2: "55980", value3: "55980", value4: "55980", name: "Parishioners Federal Credit Union (55980)" },
    { value: "18285", value2: "18285", value3: "18285", value4: "18285", name: "Park Bank - WI (18285)" },
    { value: "68645", value2: "68645", value3: "68645", value4: "68645", name: "Park Bank - WI - DC (68645)" },
    { value: "70243", value2: "70243", value3: "70243", value4: "70243", name: "Park Bank CC (70243)" },
    { value: "02879", value2: "02879", value3: "02879", value4: "02879", name: "Park Bank Credit Card (02879)" },
    { value: "00412", value2: "00412", value3: "00412", value4: "00412", name: "Park Bank Madison (00412)" },
    { value: "62931", value2: "62931", value3: "62931", value4: "62931", name: "Park City CU (62931)" },
    { value: "49536", value2: "49536", value3: "49536", value4: "49536", name: "Park National Bank (49536)" },
    { value: "69535", value2: "69535", value3: "69535", value4: "69536", name: "Park National Bank Commercial DC (69535)" },
    { value: "69536", value2: "69536", value3: "69536", value4: "69536", name: "Park National Bank Commercial OLB (69536)" },
    { value: "08615", value2: "08615", value3: "08615", value4: "08615", name: "Park Side Credit Union-QB (08615)" },
    { value: "12068", value2: "12068", value3: "12068", value4: "12068", name: "Park State Bank MN (12068)" },
    { value: "02166", value2: "02166", value3: "02166", value4: "02166", name: "Park State Bank and Trust (02166)" },
    { value: "69965", value2: "69965", value3: "69965", value4: "69965", name: "Park View FCU (69965)" },
    { value: "16732", value2: "16732", value3: "16732", value4: "69965", name: "Park View FCU QBDC (16732)" },
    { value: "06938", value2: "06938", value3: "06938", value4: "06938", name: "Parke Bank (06938)" },
    { value: "55665", value2: "55665", value3: "55665", value4: "55665", name: "Parkside Credit Union (55665)" },
    { value: "67463", value2: "67463", value3: "67463", value4: "17079", name: "Parkside Financial B & T Direct (67463)" },
    { value: "70231", value2: "70231", value3: "70231", value4: "70231", name: "Parkside Financial B&T-TM (70231)" },
    { value: "70232", value2: "70232", value3: "70232", value4: "70232", name: "Parkside Financial B&T-TM-DC (70232)" },
    { value: "17079", value2: "17079", value3: "17079", value4: "17079", name: "Parkside Financial Bank & Trust (17079)" },
    { value: "11054", value2: "11054", value3: "11054", value4: "11054", name: "Parkway Bank & Trust Company (11054)" },
    { value: "46644", value2: "46644", value3: "46644", value4: "46644", name: "Parlin Dupont Employees (46644)" },
    { value: "68357", value2: "68357", value3: "68357", value4: "68357", name: "Partner Colorado CU - QB WC (68357)" },
    { value: "16456", value2: "16456", value3: "16456", value4: "16456", name: "Partner Colorado Credit Union (16456)" },
    { value: "16465", value2: "16465", value3: "16465", value4: "16465", name: "Partners 1st FCU (16465)" },
    { value: "16468", value2: "16468", value3: "16468", value4: "16465", name: "Partners 1st FCU QB (16468)" },
    { value: "09314", value2: "09314", value3: "09314", value4: "09314", name: "Partners Bank - New (09314)" },
    { value: "09317", value2: "09317", value3: "09317", value4: "09314", name: "Partners Bank - WC (09317)" },
    { value: "12341", value2: "12341", value3: "12341", value4: "12341", name: "Partners Bank Helena - AR (12341)" },
    { value: "70351", value2: "70351", value3: "70351", value4: "70351", name: "Partners Bank Helena - AR DC (70351)" },
    { value: "12353", value2: "12353", value3: "12353", value4: "12353", name: "Partners Bank of CA-Bus Direct (12353)" },
    { value: "12350", value2: "12350", value3: "12350", value4: "12350", name: "Partners Bank of CA-Direct (12350)" },
    { value: "63783", value2: "63783", value3: "63783", value4: "63783", name: "Partners Bank of California (63783)" },
    { value: "63786", value2: "63786", value3: "63786", value4: "63786", name: "Partners Bank of California-BO (63786)" },
    { value: "12800", value2: "12800", value3: "12800", value4: "12800", name: "Partners Bank of Wisconsin (12800)" },
    { value: "13951", value2: "13951", value3: "13951", value4: "13951", name: "Partners FCU (13951)" },
    { value: "65844", value2: "65844", value3: "65844", value4: "65844", name: "Partnership Financial Credit Union (65844)" },
    { value: "52914", value2: "52914", value3: "52914", value4: "52914", name: "Pasadena Service FCU (52914)" },
    { value: "11788", value2: "11788", value3: "11788", value4: "11788", name: "Passumpsic Savings Bank (11788)" },
    { value: "09868", value2: "09868", value3: "09868", value4: "09868", name: "Patelco Credit Union-WC (09868)" },
    { value: "01824", value2: "01824", value3: "01824", value4: "01824", name: "Pathfinder Bank BeB (01824)" },
    { value: "19449", value2: "19449", value3: "19449", value4: "19449", name: "Pathward Business (19449)" },
    { value: "01851", value2: "01851", value3: "01851", value4: "01851", name: "Pathway Bank (01851)" },
    { value: "00723", value2: "00723", value3: "00723", value4: "00723", name: "Patriot Bank of Millington (00723)" },
    { value: "05515", value2: "05515", value3: "05515", value4: "05515", name: "Patriot National Bank - WC for QB (05515)" },
    { value: "67996", value2: "67996", value3: "67996", value4: "67996", name: "Patriots Bank KS BO (67996)" },
    { value: "03593", value2: "03593", value3: "03593", value4: "03593", name: "Patterson State Bank (03593)" },
    { value: "04863", value2: "04863", value3: "04863", value4: "04863", name: "Pavillion Bank (04863)" },
    { value: "69511", value2: "69511", value3: "69511", value4: "69511", name: "PayPal EWC (69511)" },
    { value: "43239", value2: "43239", value3: "43239", value4: "43239", name: "Payne County Bank (43239)" },
    { value: "57039", value2: "57039", value3: "57039", value4: "43239", name: "Payne County Bank-DC (57039)" },
    { value: "14136", value2: "14136", value3: "14136", value4: "14136", name: "Peach State Bank (14136)" },
    { value: "02990", value2: "02990", value3: "02990", value4: "14136", name: "Peach State Bank - DL (02990)" },
    { value: "67722", value2: "67722", value3: "67722", value4: "67722", name: "Peach State Bank-TM (67722)" },
    { value: "67723", value2: "67723", value3: "67723", value4: "67722", name: "Peach State Bank-TM-DC (67723)" },
    { value: "16209", value2: "16209", value3: "16209", value4: "16209", name: "PeachState FCU (16209)" },
    { value: "01147", value2: "01147", value3: "01147", value4: "01147", name: "Peapack Gladstone Bank (01147)" },
    { value: "68479", value2: "68479", value3: "68479", value4: "68479", name: "Peapack-Gladstone Bank Personal (68479)" },
    { value: "05606", value2: "05606", value3: "05606", value4: "05606", name: "Pegasus Bank- Consumer (05606)" },
    { value: "67512", value2: "67512", value3: "67512", value4: "67512", name: "Pegasus Bank-TMS (67512)" },
    { value: "67513", value2: "67513", value3: "67513", value4: "67513", name: "Pegasus Bank-TMS-DC (67513)" },
    { value: "13116", value2: "13116", value3: "13116", value4: "13116", name: "Pelican State Credit Union (13116)" },
    { value: "00346", value2: "00346", value3: "00346", value4: "00346", name: "Pen Air Credit Union (00346)" },
    { value: "07661", value2: "07661", value3: "07661", value4: "07661", name: "Pendleton Community Bank (07661)" },
    { value: "07664", value2: "07664", value3: "07664", value4: "07661", name: "Pendleton Community Bank WC (07664)" },
    { value: "62730", value2: "62730", value3: "62730", value4: "62730", name: "Peninsula Federal Credit Union (62730)" },
    { value: "67076", value2: "67076", value3: "67076", value4: "67076", name: "Penn Community Bank BB DC QB (67076)" },
    { value: "09656", value2: "09656", value3: "09656", value4: "09656", name: "Penn Community Bank DC QB (09656)" },
    { value: "63498", value2: "63498", value3: "63498", value4: "63498", name: "Penn Community Bank QB (63498)" },
    { value: "02364", value2: "02364", value3: "02364", value4: "02364", name: "Penn State Federal Credit Union (02364)" },
    { value: "56433", value2: "56433", value3: "56433", value4: "56433", name: "Pennian Bank (56433)" },
    { value: "69242", value2: "69242", value3: "69242", value4: "56433", name: "Pennian Bank QBDC (69242)" },
    { value: "03961", value2: "03961", value3: "03961", value4: "03961", name: "Pentucket Bank QB (03961)" },
    { value: "68218", value2: "68218", value3: "68218", value4: "68218", name: "Pentucket Business QB DC (68218)" },
    { value: "04386", value2: "04386", value3: "04386", value4: "04386", name: "People's Bank of Commerce (04386)" },
    { value: "16318", value2: "16318", value3: "16318", value4: "16318", name: "People's Bank of Seneca (16318)" },
    { value: "17823", value2: "17823", value3: "17823", value4: "17823", name: "People's Community Federal CU (17823)" },
    { value: "06704", value2: "06704", value3: "06704", value4: "06704", name: "People's Intermountain Credit Card (06704)" },
    { value: "69458", value2: "69458", value3: "69458", value4: "69458", name: "People's United Bk eTreasury EWC (69458)" },
    { value: "68423", value2: "68423", value3: "68423", value4: "68423", name: "PeopleFirst Bank BUS (68423)" },
    { value: "68424", value2: "68424", value3: "68424", value4: "68424", name: "PeopleFirst Bank Retail (68424)" },
    { value: "52044", value2: "52044", value3: "52044", value4: "52044", name: "Peoples  Bank of East Tennessee (52044)" },
    { value: "02374", value2: "02374", value3: "02374", value4: "02374", name: "Peoples Bank (02374)" },
    { value: "11967", value2: "11967", value3: "11967", value4: "11967", name: "Peoples Bank & Trust (11967)" },
    { value: "29883", value2: "29883", value3: "29883", value4: "29883", name: "Peoples Bank & Trust Co - TN (29883)" },
    { value: "13200", value2: "13200", value3: "13200", value4: "13200", name: "Peoples Bank & Trust Company (13200)" },
    { value: "04334", value2: "04334", value3: "04334", value4: "04334", name: "Peoples Bank & Trust of Pickett (04334)" },
    { value: "13911", value2: "13911", value3: "13911", value4: "13911", name: "Peoples Bank - Arkansas (13911)" },
    { value: "70109", value2: "70109", value3: "70109", value4: "02374", name: "Peoples Bank - DC (70109)" },
    { value: "50826", value2: "50826", value3: "50826", value4: "50826", name: "Peoples Bank - IA (50826)" },
    { value: "58914", value2: "58914", value3: "58914", value4: "58914", name: "Peoples Bank - Lyons (58914)" },
    { value: "50790", value2: "50790", value3: "50790", value4: "50790", name: "Peoples Bank - Missouri (50790)" },
    { value: "50421", value2: "50421", value3: "50421", value4: "50421", name: "Peoples Bank - TX (50421)" },
    { value: "12773", value2: "12773", value3: "12773", value4: "12773", name: "Peoples Bank Business (12773)" },
    { value: "05794", value2: "05794", value3: "05794", value4: "05794", name: "Peoples Bank Kankakee Retail (05794)" },
    { value: "60546", value2: "60546", value3: "60546", value4: "60546", name: "Peoples Bank MS (60546)" },
    { value: "09542", value2: "09542", value3: "09542", value4: "60546", name: "Peoples Bank MS - QBDC (09542)" },
    { value: "02357", value2: "02357", value3: "02357", value4: "02357", name: "Peoples Bank Magnolia AR (02357)" },
    { value: "03078", value2: "03078", value3: "03078", value4: "03078", name: "Peoples Bank NC (03078)" },
    { value: "56649", value2: "56649", value3: "56649", value4: "03078", name: "Peoples Bank NC DirectConnect (56649)" },
    { value: "11408", value2: "11408", value3: "11408", value4: "11408", name: "Peoples Bank NWI - Business (11408)" },
    { value: "68003", value2: "68003", value3: "68003", value4: "68003", name: "Peoples Bank Texas Bus (68003)" },
    { value: "68136", value2: "68136", value3: "68136", value4: "68136", name: "Peoples Bank Texas DC (68136)" },
    { value: "68010", value2: "68010", value3: "68010", value4: "68010", name: "Peoples Bank Texas PFM (68010)" },
    { value: "11725", value2: "11725", value3: "11725", value4: "11725", name: "Peoples Bank WA - Peoples Online (11725)" },
    { value: "53421", value2: "53421", value3: "53421", value4: "53421", name: "Peoples Bank WAâ€“Business Manager (53421)" },
    { value: "51054", value2: "51054", value3: "51054", value4: "51054", name: "Peoples Bank and Trust - IL (51054)" },
    { value: "19869", value2: "19869", value3: "19869", value4: "19869", name: "Peoples Bank and Trust - KS (19869)" },
    { value: "55605", value2: "55605", value3: "55605", value4: "55605", name: "Peoples Bank of Alabama (55605)" },
    { value: "69298", value2: "69298", value3: "69298", value4: "69298", name: "Peoples Bank of Altenburg-Retail (69298)" },
    { value: "08126", value2: "08126", value3: "08126", value4: "04386", name: "Peoples Bank of Commerce_QB (08126)" },
    { value: "19665", value2: "19665", value3: "19665", value4: "19665", name: "Peoples Bank of Deer Lodge (19665)" },
    { value: "53031", value2: "53031", value3: "53031", value4: "52044", name: "Peoples Bank of East TN - DC (53031)" },
    { value: "14058", value2: "14058", value3: "14058", value4: "14058", name: "Peoples Bank of Kankakee County (14058)" },
    { value: "03277", value2: "03277", value3: "03277", value4: "03277", name: "Peoples Bank of Mount Washington (03277)" },
    { value: "08264", value2: "08264", value3: "08264", value4: "08264", name: "Peoples Bank of Wyaconda (08264)" },
    { value: "30060", value2: "30060", value3: "30060", value4: "30060", name: "Peoples Bank of the South TN (30060)" },
    { value: "04844", value2: "04844", value3: "04844", value4: "58872", name: "Peoples CU (04844)" },
    { value: "02183", value2: "02183", value3: "02183", value4: "02183", name: "Peoples Commercial Credit Card (02183)" },
    { value: "08804", value2: "08804", value3: "08804", value4: "08804", name: "Peoples Community Bank-MO (08804)" },
    { value: "08807", value2: "08807", value3: "08807", value4: "08804", name: "Peoples Community Bank-MO WC (08807)" },
    { value: "57450", value2: "57450", value3: "57450", value4: "57450", name: "Peoples Credit Union (57450)" },
    { value: "68721", value2: "68721", value3: "68721", value4: "68721", name: "Peoples Credit Union Colorado (68721)" },
    { value: "57453", value2: "57453", value3: "57453", value4: "57450", name: "Peoples Credit Union_DC (57453)" },
    { value: "05998", value2: "05998", value3: "05998", value4: "05998", name: "Peoples Driven Credit Union (05998)" },
    { value: "50775", value2: "50775", value3: "50775", value4: "50775", name: "Peoples Exchange Bank (50775)" },
    { value: "06512", value2: "06512", value3: "06512", value4: "06512", name: "Peoples Exchange Bank - Cash Mgt (06512)" },
    { value: "12578", value2: "12578", value3: "12578", value4: "12578", name: "Peoples First Savings Bank - New (12578)" },
    { value: "12581", value2: "12581", value3: "12581", value4: "12578", name: "Peoples First Savings Bank - QBDC (12581)" },
    { value: "12218", value2: "12218", value3: "12218", value4: "12218", name: "Peoples First Savings Bank QBDCBiz (12218)" },
    { value: "06159", value2: "06159", value3: "06159", value4: "06159", name: "Peoples Independent Bank (06159)" },
    { value: "04012", value2: "04012", value3: "04012", value4: "04012", name: "Peoples National Bank - Business (04012)" },
    { value: "70209", value2: "70209", value3: "70209", value4: "70209", name: "Peoples National Bank of Kewanee (70209)" },
    { value: "70210", value2: "70210", value3: "70210", value4: "70209", name: "Peoples Natl Bank of Kewanee QB (70210)" },
    { value: "08954", value2: "08954", value3: "08954", value4: "08954", name: "Peoples Savings Bank (08954)" },
    { value: "08957", value2: "08957", value3: "08957", value4: "08957", name: "Peoples Savings Bank - Business (08957)" },
    { value: "12713", value2: "12713", value3: "12713", value4: "16950", name: "Peoples Savings Bank Rhineland QB (12713)" },
    { value: "16950", value2: "16950", value3: "16950", value4: "16950", name: "Peoples Savings Bank of Rhineland (16950)" },
    { value: "07754", value2: "07754", value3: "07754", value4: "07754", name: "Peoples Savings Bank-Iowa (07754)" },
    { value: "07757", value2: "07757", value3: "07757", value4: "07757", name: "Peoples Savings Bank-Iowa-Bus (07757)" },
    { value: "68639", value2: "68639", value3: "68639", value4: "68639", name: "Peoples Security Bank & Trust (68639)" },
    { value: "68640", value2: "68640", value3: "68640", value4: "68639", name: "Peoples Security Bank & Trust QBDC (68640)" },
    { value: "69408", value2: "69408", value3: "69408", value4: "69408", name: "Peoples St Bnk of Munising-BeB (69408)" },
    { value: "64632", value2: "64632", value3: "64632", value4: "64632", name: "Peoples State Bank & Trust (64632)" },
    { value: "64635", value2: "64635", value3: "64635", value4: "64632", name: "Peoples State Bank & Trust _DC (64635)" },
    { value: "17583", value2: "17583", value3: "17583", value4: "17583", name: "Peoples State Bank - Ellettsville (17583)" },
    { value: "68271", value2: "68271", value3: "68271", value4: "68271", name: "Peoples State Bank ND (68271)" },
    { value: "01809", value2: "01809", value3: "01809", value4: "01809", name: "Peoples State Bank Wyalusing,PA (01809)" },
    { value: "03251", value2: "03251", value3: "03251", value4: "03251", name: "Peoples State Bank of Newton (03251)" },
    { value: "09717", value2: "09717", value3: "09717", value4: "09717", name: "Peoples State Bank of Shepherd (09717)" },
    { value: "34152", value2: "34152", value3: "34152", value4: "34152", name: "Peoples State Bank of Velva (34152)" },
    { value: "04472", value2: "04472", value3: "04472", value4: "04469", name: "Peoples State Bank-DC (04472)" },
    { value: "04469", value2: "04469", value3: "04469", value4: "04469", name: "Peoples State Bank-NEW (04469)" },
    { value: "11286", value2: "11286", value3: "11286", value4: "11286", name: "Peoples State Bank-PDC, WI (11286)" },
    { value: "04217", value2: "04217", value3: "04217", value4: "04217", name: "Peoples State Bank-TX (04217)" },
    { value: "54645", value2: "54645", value3: "54645", value4: "54645", name: "Peoples State Bk,New Lexington (54645)" },
    { value: "59328", value2: "59328", value3: "59328", value4: "59328", name: "Peoples Trust & Svgs BK CCard (59328)" },
    { value: "68628", value2: "68628", value3: "68628", value4: "68628", name: "Peoples Trust Co of St Albans (68628)" },
    { value: "68629", value2: "68629", value3: "68629", value4: "68629", name: "Peoples Trust Company (68629)" },
    { value: "68630", value2: "68630", value3: "68630", value4: "68630", name: "Peoples Trust Company - Bus (68630)" },
    { value: "05315", value2: "05315", value3: "05315", value4: "05315", name: "Peoples Trust Company Business (05315)" },
    { value: "67610", value2: "67610", value3: "67610", value4: "67610", name: "Peoples Trust Savings Bank QBDC (67610)" },
    { value: "67609", value2: "67609", value3: "67609", value4: "67609", name: "Peoples Trust and Savings Bank (67609)" },
    { value: "03293", value2: "03293", value3: "03293", value4: "03293", name: "PeoplesBank - Holyoke MA (03293)" },
    { value: "63186", value2: "63186", value3: "63186", value4: "63186", name: "PeoplesBank - Holyoke MA - Bus (63186)" },
    { value: "07877", value2: "07877", value3: "07877", value4: "07877", name: "PeoplesBank, A Codorus Valley Co (07877)" },
    { value: "08480", value2: "08480", value3: "08480", value4: "08480", name: "PeoplesBankClifton Business (08480)" },
    { value: "53172", value2: "53172", value3: "53172", value4: "53172", name: "PeoplesChoice Credit Union (53172)" },
    { value: "69522", value2: "69522", value3: "69522", value4: "69522", name: "Performance Finance (69522)" },
    { value: "09533", value2: "09533", value3: "09533", value4: "09533", name: "Peru Federal Saving Bank Bus (09533)" },
    { value: "02888", value2: "02888", value3: "02888", value4: "02888", name: "Petefish, Skiles & Co. Credit Card (02888)" },
    { value: "33231", value2: "33231", value3: "33231", value4: "33231", name: "Petefish, Skiles & Co. IL (33231)" },
    { value: "03598", value2: "03598", value3: "03598", value4: "03598", name: "Phelps County Bank (03598)" },
    { value: "49764", value2: "49764", value3: "49764", value4: "49764", name: "Phenix-Girard Bank-AL (49764)" },
    { value: "69353", value2: "69353", value3: "69353", value4: "69353", name: "Philadelphia FCU - Business (69353)" },
    { value: "03147", value2: "03147", value3: "03147", value4: "03147", name: "Philadelphia Federal Credit Union (03147)" },
    { value: "67096", value2: "67096", value3: "67096", value4: "67096", name: "Phoenixville Fed BankTrust Bus (67096)" },
    { value: "18636", value2: "18636", value3: "18636", value4: "18636", name: "Phoenixville Federal Bank & Trust (18636)" },
    { value: "57219", value2: "57219", value3: "57219", value4: "57219", name: "Picatinny FCU Credit Card (57219)" },
    { value: "67706", value2: "67706", value3: "67706", value4: "67706", name: "Picatinny Federal Credit Union DC (67706)" },
    { value: "67683", value2: "67683", value3: "67683", value4: "67683", name: "Picatinny Federal Credit Union W (67683)" },
    { value: "09942", value2: "09942", value3: "09942", value4: "09942", name: "Piedmont Advantage Credit Union (09942)" },
    { value: "05141", value2: "05141", value3: "05141", value4: "05141", name: "Piedmont Advantage QB (05141)" },
    { value: "50484", value2: "50484", value3: "50484", value4: "50484", name: "Piedmont Bank (50484)" },
    { value: "03357", value2: "03357", value3: "03357", value4: "03357", name: "Piedmont Federal Savings Bank (03357)" },
    { value: "69576", value2: "69576", value3: "69576", value4: "69576", name: "Piedmont Federal Savings DC (69576)" },
    { value: "09962", value2: "09962", value3: "09962", value4: "09962", name: "Piermont Bank - NY - Business DC (09962)" },
    { value: "09956", value2: "09956", value3: "09956", value4: "09956", name: "Piermont Bank - NY - Business WC (09956)" },
    { value: "09959", value2: "09959", value3: "09959", value4: "09959", name: "Piermont Bank - NY - Personal WC (09959)" },
    { value: "59460", value2: "59460", value3: "59460", value4: "59460", name: "Pigeon Falls State Bank (59460)" },
    { value: "59463", value2: "59463", value3: "59463", value4: "59460", name: "Pigeon Falls State Bank_DC (59463)" },
    { value: "09110", value2: "09110", value3: "09110", value4: "09110", name: "Piggott State Bank - Retail (09110)" },
    { value: "00677", value2: "00677", value3: "00677", value4: "00677", name: "Pike National Bank (00677)" },
    { value: "52770", value2: "52770", value3: "52770", value4: "07369", name: "Pilgrim Bank - DC (52770)" },
    { value: "07369", value2: "07369", value3: "07369", value4: "07369", name: "Pilgrim Bank - TX (07369)" },
    { value: "03890", value2: "03890", value3: "03890", value4: "03890", name: "Pillar Bank (03890)" },
    { value: "65454", value2: "65454", value3: "65454", value4: "65454", name: "Pilot Grove Savings Bank (65454)" },
    { value: "65457", value2: "65457", value3: "65457", value4: "65457", name: "Pilot Grove Savings Bk - DL (65457)" },
    { value: "26949", value2: "26949", value3: "26949", value4: "26949", name: "Pima FCU (26949)" },
    { value: "69069", value2: "69069", value3: "69069", value4: "69069", name: "Pima Federal Credit Union QBWC (69069)" },
    { value: "06209", value2: "06209", value3: "06209", value4: "06209", name: "Pinal County FCU (06209)" },
    { value: "18579", value2: "18579", value3: "18579", value4: "18579", name: "Pinal County Federal Credit Union (18579)" },
    { value: "70297", value2: "70297", value3: "70297", value4: "70297", name: "Pine Country Bank Bus (70297)" },
    { value: "68358", value2: "68358", value3: "68358", value4: "68358", name: "Pine Country Bank Bus Direct (68358)" },
    { value: "70299", value2: "70299", value3: "70299", value4: "70299", name: "Pine Country Bank Pers DC (70299)" },
    { value: "70298", value2: "70298", value3: "70298", value4: "70298", name: "Pine Country Pers Bus (70298)" },
    { value: "42870", value2: "42870", value3: "42870", value4: "42870", name: "Pine River State Bank (42870)" },
    { value: "00854", value2: "00854", value3: "00854", value4: "42870", name: "Pine River State Bank, MN DC (00854)" },
    { value: "64584", value2: "64584", value3: "64584", value4: "64584", name: "Pineland Bank (64584)" },
    { value: "15687", value2: "15687", value3: "15687", value4: "15687", name: "Pinnacle Bank - Alabama (15687)" },
    { value: "16999", value2: "16999", value3: "16999", value4: "16999", name: "Pinnacle Bank - Credit Card (16999)" },
    { value: "65112", value2: "65112", value3: "65112", value4: "65112", name: "Pinnacle Bank - Morgan Hill BEB (65112)" },
    { value: "70435", value2: "70435", value3: "70435", value4: "70435", name: "Pinnacle Bank Bus CC (70435)" },
    { value: "01871", value2: "01871", value3: "01871", value4: "01871", name: "Pinnacle Bank Credit Card (01871)" },
    { value: "68125", value2: "68125", value3: "68125", value4: "68125", name: "Pinnacle Bank NE BusPremium (68125)" },
    { value: "68128", value2: "68128", value3: "68128", value4: "68128", name: "Pinnacle Bank NE BusPremium QBDC (68128)" },
    { value: "15970", value2: "15970", value3: "15970", value4: "15970", name: "Pinnacle Bank Nebraska BO (15970)" },
    { value: "15973", value2: "15973", value3: "15973", value4: "15970", name: "Pinnacle Bank Nebraska BODC (15973)" },
    { value: "11661", value2: "11661", value3: "11661", value4: "11661", name: "Pinnacle Bank Nebraska RO (11661)" },
    { value: "15967", value2: "15967", value3: "15967", value4: "11661", name: "Pinnacle Bank Nebraska RODC (15967)" },
    { value: "68126", value2: "68126", value3: "68126", value4: "68126", name: "Pinnacle Bank TX BusPremium (68126)" },
    { value: "68129", value2: "68129", value3: "68129", value4: "68129", name: "Pinnacle Bank TX BusPremium QBDC (68129)" },
    { value: "15979", value2: "15979", value3: "15979", value4: "15979", name: "Pinnacle Bank Texas BO (15979)" },
    { value: "15982", value2: "15982", value3: "15982", value4: "15979", name: "Pinnacle Bank Texas BODC (15982)" },
    { value: "13092", value2: "13092", value3: "13092", value4: "13092", name: "Pinnacle Bank Texas RO (13092)" },
    { value: "15976", value2: "15976", value3: "15976", value4: "13092", name: "Pinnacle Bank Texas RODC (15976)" },
    { value: "68127", value2: "68127", value3: "68127", value4: "68127", name: "Pinnacle Bank WY BusPremium (68127)" },
    { value: "68130", value2: "68130", value3: "68130", value4: "68130", name: "Pinnacle Bank WY BusPremium QBDC (68130)" },
    { value: "15988", value2: "15988", value3: "15988", value4: "15988", name: "Pinnacle Bank Wyoming BO (15988)" },
    { value: "15991", value2: "15991", value3: "15991", value4: "15988", name: "Pinnacle Bank Wyoming BODC (15991)" },
    { value: "05544", value2: "05544", value3: "05544", value4: "05544", name: "Pinnacle Bank Wyoming RO (05544)" },
    { value: "15985", value2: "15985", value3: "15985", value4: "05544", name: "Pinnacle Bank Wyoming RODC (15985)" },
    { value: "01427", value2: "01427", value3: "01427", value4: "01427", name: "Pinnacle Bank, GA (01427)" },
    { value: "57705", value2: "57705", value3: "57705", value4: "57705", name: "Pinnacle Bank, GA Business (57705)" },
    { value: "57600", value2: "57600", value3: "57600", value4: "57600", name: "Pinnacle Bank, GA QB-DC (57600)" },
    { value: "11099", value2: "11099", value3: "11099", value4: "11099", name: "Pinnacle Bank-Marshalltown (11099)" },
    { value: "03019", value2: "03019", value3: "03019", value4: "03019", name: "Pinnacle CU (03019)" },
    { value: "01810", value2: "01810", value3: "01810", value4: "01810", name: "Pinnacle Financial Partners (01810)" },
    { value: "09589", value2: "09589", value3: "09589", value4: "09589", name: "Pinnacle Financial Partners QBDC (09589)" },
    { value: "04148", value2: "04148", value3: "04148", value4: "04148", name: "Pinpoint FCU Credit Card (04148)" },
    { value: "04257", value2: "04257", value3: "04257", value4: "04257", name: "Pioneer Bank (04257)" },
    { value: "01105", value2: "01105", value3: "01105", value4: "01105", name: "Pioneer Bank & Trust (01105)" },
    { value: "63777", value2: "63777", value3: "63777", value4: "63777", name: "Pioneer Bank - BUS (63777)" },
    { value: "13113", value2: "13113", value3: "13113", value4: "13113", name: "Pioneer Bank - Stanley, VA (13113)" },
    { value: "03083", value2: "03083", value3: "03083", value4: "03083", name: "Pioneer Bank Credit Card (03083)" },
    { value: "32958", value2: "32958", value3: "32958", value4: "32958", name: "Pioneer Bank MN (32958)" },
    { value: "64515", value2: "64515", value3: "64515", value4: "64515", name: "Pioneer Bank MN - Business (64515)" },
    { value: "68821", value2: "68821", value3: "68821", value4: "68821", name: "Pioneer Bank MN - Business QBDC (68821)" },
    { value: "68819", value2: "68819", value3: "68819", value4: "68819", name: "Pioneer Bank MN QBDC (68819)" },
    { value: "51999", value2: "51999", value3: "51999", value4: "51999", name: "Pioneer Bank, IA (51999)" },
    { value: "54174", value2: "54174", value3: "54174", value4: "54174", name: "Pioneer Commercial BK - WC (54174)" },
    { value: "56121", value2: "56121", value3: "56121", value4: "56121", name: "Pioneer Commercial Bank - DC (56121)" },
    { value: "68669", value2: "68669", value3: "68669", value4: "68669", name: "Pioneer Federal CU (68669)" },
    { value: "68670", value2: "68670", value3: "68670", value4: "68670", name: "Pioneer Federal CU - QBWC (68670)" },
    { value: "56124", value2: "56124", value3: "56124", value4: "56124", name: "Pioneer Savings Bank - DC (56124)" },
    { value: "54171", value2: "54171", value3: "54171", value4: "54171", name: "Pioneer Savings Bk - WC (54171)" },
    { value: "68055", value2: "68055", value3: "68055", value4: "68055", name: "Pioneer Trust Bank BO WC (68055)" },
    { value: "68034", value2: "68034", value3: "68034", value4: "68034", name: "Pioneer Trust Bank Business DC (68034)" },
    { value: "68035", value2: "68035", value3: "68035", value4: "68035", name: "Pioneer Trust Bank Personal (68035)" },
    { value: "68033", value2: "68033", value3: "68033", value4: "68033", name: "Pioneer Trust Bank Personal QBDC (68033)" },
    { value: "70478", value2: "70478", value3: "70478", value4: "70478", name: "Pioneer Valley Credit Union (70478)" },
    { value: "68876", value2: "68876", value3: "68876", value4: "68876", name: "Pipefitters Steamfitters Credit (68876)" },
    { value: "68529", value2: "68529", value3: "68529", value4: "68529", name: "Pittsfield Co-op Bank Bus DC (68529)" },
    { value: "64926", value2: "64926", value3: "64926", value4: "64926", name: "Pittsfield Cooperative Bank Bus (64926)" },
    { value: "64944", value2: "64944", value3: "64944", value4: "64944", name: "Plains Commerce Bank  DC Bus (64944)" },
    { value: "62601", value2: "62601", value3: "62601", value4: "62598", name: "Plains Commerce Bank QB DC (62601)" },
    { value: "62598", value2: "62598", value3: "62598", value4: "62598", name: "Plains Commerce Bank Retail (62598)" },
    { value: "62595", value2: "62595", value3: "62595", value4: "62595", name: "Plains Commerce Bank WC Business (62595)" },
    { value: "68871", value2: "68871", value3: "68871", value4: "68871", name: "Plains Land Bank (68871)" },
    { value: "17961", value2: "17961", value3: "17961", value4: "17961", name: "Plains State Bank (17961)" },
    { value: "69669", value2: "69669", value3: "69669", value4: "69669", name: "Plains State Bank - DC (69669)" },
    { value: "69597", value2: "69597", value3: "69597", value4: "69597", name: "Plains State Bank BOB DC (69597)" },
    { value: "69596", value2: "69596", value3: "69596", value4: "69596", name: "Plains State Bank BOB WC (69596)" },
    { value: "10390", value2: "10390", value3: "10390", value4: "10390", name: "PlainsCapital Bank (10390)" },
    { value: "00248", value2: "00248", value3: "00248", value4: "00248", name: "PlainsCapital Bank  Business (00248)" },
    { value: "11202", value2: "11202", value3: "11202", value4: "11202", name: "Planters & Citizens Bank (11202)" },
    { value: "05223", value2: "05223", value3: "05223", value4: "05223", name: "Planters Bank (05223)" },
    { value: "17670", value2: "17670", value3: "17670", value4: "17670", name: "Planters Bank and Trust (17670)" },
    { value: "11253", value2: "11253", value3: "11253", value4: "11253", name: "Planters First Bank (11253)" },
    { value: "69537", value2: "69537", value3: "69537", value4: "69537", name: "Plaquemine Bank and Trust (69537)" },
    { value: "34896", value2: "34896", value3: "34896", value4: "34896", name: "Platinum Bank - MN (34896)" },
    { value: "69494", value2: "69494", value3: "69494", value4: "69494", name: "Platinum Federal CU EWC (69494)" },
    { value: "51897", value2: "51897", value3: "51897", value4: "51897", name: "Platinum Federal Credit Union (51897)" },
    { value: "63396", value2: "63396", value3: "63396", value4: "63396", name: "Platte Valley Bank - NE (63396)" },
    { value: "33993", value2: "33993", value3: "33993", value4: "33993", name: "Platte Valley Bank WY (33993)" },
    { value: "02647", value2: "02647", value3: "02647", value4: "02647", name: "Plaza Park Bank (02647)" },
    { value: "53883", value2: "53883", value3: "53883", value4: "02647", name: "Plaza Park Bank_DC (53883)" },
    { value: "11098", value2: "11098", value3: "11098", value4: "11098", name: "Plumas Bank (11098)" },
    { value: "67242", value2: "67242", value3: "67242", value4: "67242", name: "Plus International Bank (67242)" },
    { value: "03050", value2: "03050", value3: "03050", value4: "03050", name: "Plymouth County Teachers FCU (03050)" },
    { value: "70236", value2: "70236", value3: "70236", value4: "70236", name: "Point Breeze CU  BB - DC (70236)" },
    { value: "14698", value2: "14698", value3: "14698", value4: "14698", name: "Point Breeze Credit Union Direct (14698)" },
    { value: "14701", value2: "14701", value3: "14701", value4: "14701", name: "Point Breeze Credit Union QBWC (14701)" },
    { value: "01191", value2: "01191", value3: "01191", value4: "01191", name: "Point West CU (01191)" },
    { value: "68925", value2: "68925", value3: "68925", value4: "68925", name: "PointBank - Direct Connect (68925)" },
    { value: "68924", value2: "68924", value3: "68924", value4: "68924", name: "PointBank - TX (68924)" },
    { value: "67169", value2: "67169", value3: "67169", value4: "67169", name: "Points West - Consumer DC (67169)" },
    { value: "05139", value2: "05139", value3: "05139", value4: "05139", name: "Points West Community Bank (05139)" },
    { value: "67822", value2: "67822", value3: "67822", value4: "67822", name: "Points West Community Bank Bus (67822)" },
    { value: "67814", value2: "67814", value3: "67814", value4: "67814", name: "Points West Community Bank QK BO (67814)" },
    { value: "16408", value2: "16408", value3: "16408", value4: "16159", name: "Polish & Slavic FCU - QB WC (16408)" },
    { value: "67535", value2: "67535", value3: "67535", value4: "67535", name: "Polish National CU QBWC (67535)" },
    { value: "67534", value2: "67534", value3: "67534", value4: "67534", name: "Polish National Credit Union (67534)" },
    { value: "05213", value2: "05213", value3: "05213", value4: "05213", name: "Ponce Bank (05213)" },
    { value: "65250", value2: "65250", value3: "65250", value4: "65250", name: "Ponce de Leon Federal Bank - Bus (65250)" },
    { value: "50517", value2: "50517", value3: "50517", value4: "50517", name: "Pony Express Bank (50517)" },
    { value: "67956", value2: "67956", value3: "67956", value4: "67956", name: "Pony Express Bank Business (67956)" },
    { value: "00318", value2: "00318", value3: "00318", value4: "00318", name: "Poppy Bank (00318)" },
    { value: "70112", value2: "70112", value3: "70112", value4: "00318", name: "Poppy Bank QBDC (70112)" },
    { value: "10865", value2: "10865", value3: "10865", value4: "10865", name: "Popular Bank (10865)" },
    { value: "17631", value2: "17631", value3: "17631", value4: "17631", name: "Port City Federal Credit Union (17631)" },
    { value: "53574", value2: "53574", value3: "53574", value4: "53574", name: "Port Conneaut FCU (53574)" },
    { value: "36612", value2: "36612", value3: "36612", value4: "36612", name: "Port Richmond Savings (36612)" },
    { value: "64308", value2: "64308", value3: "64308", value4: "36612", name: "Port Richmond Savings - DC (64308)" },
    { value: "00631", value2: "00631", value3: "00631", value4: "00631", name: "Port Washington State Bank (00631)" },
    { value: "69123", value2: "69123", value3: "69123", value4: "69123", name: "Portage Bank (69123)" },
    { value: "09887", value2: "09887", value3: "09887", value4: "09887", name: "Portage Community Bank DC QB (09887)" },
    { value: "08747", value2: "08747", value3: "08747", value4: "08747", name: "Portage Community Bank Ohio (08747)" },
    { value: "69653", value2: "69653", value3: "69653", value4: "69653", name: "Portage County Bank (69653)" },
    { value: "55653", value2: "55653", value3: "55653", value4: "55653", name: "Potlatch P1FCU (55653)" },
    { value: "68122", value2: "68122", value3: "68122", value4: "55653", name: "Potlatch P1FCU QB (68122)" },
    { value: "07436", value2: "07436", value3: "07436", value4: "07436", name: "Potter State Bank (07436)" },
    { value: "12311", value2: "12311", value3: "12311", value4: "12311", name: "Powell State Bank (12311)" },
    { value: "70525", value2: "70525", value3: "70525", value4: "12311", name: "Powell State Bank QBDC (70525)" },
    { value: "70331", value2: "70331", value3: "70331", value4: "70331", name: "Powell Valley National Bank BO (70331)" },
    { value: "70330", value2: "70330", value3: "70330", value4: "70331", name: "Powell Valley National Bank BODC (70330)" },
    { value: "70328", value2: "70328", value3: "70328", value4: "70328", name: "Powell Valley National Bank RO (70328)" },
    { value: "70329", value2: "70329", value3: "70329", value4: "70328", name: "Powell Valley National Bank RODC (70329)" },
    { value: "12521", value2: "12521", value3: "12521", value4: "12521", name: "Power Credit Union (12521)" },
    { value: "68682", value2: "68682", value3: "68682", value4: "68682", name: "Power Financial CU WC (68682)" },
    { value: "19407", value2: "19407", value3: "19407", value4: "19407", name: "Power Financial Credit Union (19407)" },
    { value: "67172", value2: "67172", value3: "67172", value4: "67172", name: "Prairie Community Bank Bus (67172)" },
    { value: "51447", value2: "51447", value3: "51447", value4: "51447", name: "Prairie State Bank & Trust - BB WC (51447)" },
    { value: "06488", value2: "06488", value3: "06488", value4: "06488", name: "Prefered Bank (06488)" },
    { value: "35253", value2: "35253", value3: "35253", value4: "35253", name: "Preferred Bank (35253)" },
    { value: "68179", value2: "68179", value3: "68179", value4: "68179", name: "Premier America CU (68179)" },
    { value: "68209", value2: "68209", value3: "68209", value4: "68209", name: "Premier America CU DC (68209)" },
    { value: "53184", value2: "53184", value3: "53184", value4: "53184", name: "Premier Bank - IA (53184)" },
    { value: "68251", value2: "68251", value3: "68251", value4: "68251", name: "Premier Bank - IA QBDC (68251)" },
    { value: "67666", value2: "67666", value3: "67666", value4: "07804", name: "Premier Bank - NE QB DC (67666)" },
    { value: "11665", value2: "11665", value3: "11665", value4: "11665", name: "Premier Bank - OH (11665)" },
    { value: "11752", value2: "11752", value3: "11752", value4: "11752", name: "Premier Bank - Powered by People (11752)" },
    { value: "01261", value2: "01261", value3: "01261", value4: "01261", name: "Premier Bank Maplewood - WC (01261)" },
    { value: "56175", value2: "56175", value3: "56175", value4: "56175", name: "Premier Bank Maplewood- DC (56175)" },
    { value: "56178", value2: "56178", value3: "56178", value4: "56178", name: "Premier Bank Minnesota - DC (56178)" },
    { value: "01262", value2: "01262", value3: "01262", value4: "01262", name: "Premier Bank Minnesota - WC (01262)" },
    { value: "07804", value2: "07804", value3: "07804", value4: "07804", name: "Premier Bank Nebraska (07804)" },
    { value: "15501", value2: "15501", value3: "15501", value4: "15501", name: "Premier Bank of the South (15501)" },
    { value: "19803", value2: "19803", value3: "19803", value4: "19803", name: "Premier Community Bank (19803)" },
    { value: "68534", value2: "68534", value3: "68534", value4: "68534", name: "Premier Financial FCU CC (68534)" },
    { value: "01913", value2: "01913", value3: "01913", value4: "01913", name: "Premier Members Credit Union (01913)" },
    { value: "02060", value2: "02060", value3: "02060", value4: "02060", name: "Premier Valley Bank (02060)" },
    { value: "67820", value2: "67820", value3: "67820", value4: "67820", name: "PremierBank Business (67820)" },
    { value: "67958", value2: "67958", value3: "67958", value4: "67958", name: "PremierBank Business DC (67958)" },
    { value: "01611", value2: "01611", value3: "01611", value4: "01611", name: "PremierOne Credit Union (01611)" },
    { value: "59364", value2: "59364", value3: "59364", value4: "59364", name: "Presence Bank (59364)" },
    { value: "68281", value2: "68281", value3: "68281", value4: "68281", name: "Presidential - Business Direct (68281)" },
    { value: "04481", value2: "04481", value3: "04481", value4: "04481", name: "Presidential Bank, FSB-Quickbooks (04481)" },
    { value: "06350", value2: "06350", value3: "06350", value4: "06350", name: "Presidents FCU Credit Card (06350)" },
    { value: "09782", value2: "09782", value3: "09782", value4: "09782", name: "Prestige Community - CU (09782)" },
    { value: "64362", value2: "64362", value3: "64362", value4: "64362", name: "Prestige Community CU QB WC (64362)" },
    { value: "18087", value2: "18087", value3: "18087", value4: "18087", name: "Prevail Bank (18087)" },
    { value: "63375", value2: "63375", value3: "63375", value4: "63375", name: "Primary Bank (63375)" },
    { value: "19050", value2: "19050", value3: "19050", value4: "19050", name: "Prime Bank, Edmond, OK (19050)" },
    { value: "08905", value2: "08905", value3: "08905", value4: "08905", name: "Prime Meridian Bank (08905)" },
    { value: "09464", value2: "09464", value3: "09464", value4: "09464", name: "Prime Meridian Bank Direct (09464)" },
    { value: "70312", value2: "70312", value3: "70312", value4: "70312", name: "Prime Security Bank BO (70312)" },
    { value: "70313", value2: "70313", value3: "70313", value4: "70312", name: "Prime Security Bank BODC (70313)" },
    { value: "70311", value2: "70311", value3: "70311", value4: "70311", name: "Prime Security Bank RO (70311)" },
    { value: "70314", value2: "70314", value3: "70314", value4: "70311", name: "Prime Security Bank RODC (70314)" },
    { value: "69500", value2: "69500", value3: "69500", value4: "69500", name: "PrimeBank of Texas (69500)" },
    { value: "67888", value2: "67888", value3: "67888", value4: "67888", name: "PrimeSouth Bank Direct Connect (67888)" },
    { value: "68069", value2: "68069", value3: "68069", value4: "68069", name: "PrimeSouth Bank PFM (68069)" },
    { value: "57303", value2: "57303", value3: "57303", value4: "57303", name: "PrimeSouth-Business Online Bking (57303)" },
    { value: "68830", value2: "68830", value3: "68830", value4: "68830", name: "PrimeTrust CU (68830)" },
    { value: "49926", value2: "49926", value3: "49926", value4: "49926", name: "PrimeTrust Financial (49926)" },
    { value: "52578", value2: "52578", value3: "52578", value4: "52578", name: "PrimeWay Federal Credit Union - QB (52578)" },
    { value: "68353", value2: "68353", value3: "68353", value4: "68353", name: "Primebank Business (68353)" },
    { value: "64371", value2: "64371", value3: "64371", value4: "64371", name: "Primebank Credit Card (64371)" },
    { value: "14595", value2: "14595", value3: "14595", value4: "14595", name: "Primis (14595)" },
    { value: "67521", value2: "67521", value3: "67521", value4: "67521", name: "Princeville State Bank (67521)" },
    { value: "65289", value2: "65289", value3: "65289", value4: "65289", name: "PriorityOne Bank - Business (65289)" },
    { value: "62946", value2: "62946", value3: "62946", value4: "62946", name: "Prism Bank (62946)" },
    { value: "01274", value2: "01274", value3: "01274", value4: "01274", name: "ProFed Credit Union (01274)" },
    { value: "07815", value2: "07815", value3: "07815", value4: "07815", name: "ProGrowth Bank (07815)" },
    { value: "65226", value2: "65226", value3: "65226", value4: "65226", name: "Profile Bank (65226)" },
    { value: "70103", value2: "70103", value3: "70103", value4: "70103", name: "Profile Bank - QBDC (70103)" },
    { value: "68825", value2: "68825", value3: "68825", value4: "68825", name: "Profinium Inc (68825)" },
    { value: "68826", value2: "68826", value3: "68826", value4: "68825", name: "Profinium, Inc.-DC (68826)" },
    { value: "11711", value2: "11711", value3: "11711", value4: "11711", name: "Progressive Bank LA (11711)" },
    { value: "68301", value2: "68301", value3: "68301", value4: "11711", name: "Progressive Bank QB (68301)" },
    { value: "35562", value2: "35562", value3: "35562", value4: "35562", name: "Progressive National Bank (35562)" },
    { value: "68807", value2: "68807", value3: "68807", value4: "68807", name: "Progressive Ozark Bank Business (68807)" },
    { value: "68808", value2: "68808", value3: "68808", value4: "68808", name: "Progressive Ozark Bank Retail (68808)" },
    { value: "04177", value2: "04177", value3: "04177", value4: "04177", name: "Proponent Federal Credit Union (04177)" },
    { value: "67725", value2: "67725", value3: "67725", value4: "67725", name: "Prospect Bank Business (67725)" },
    { value: "17424", value2: "17424", value3: "17424", value4: "17424", name: "Prospera Credit Union (17424)" },
    { value: "06356", value2: "06356", value3: "06356", value4: "06356", name: "Prosperity Bank Credit Card (06356)" },
    { value: "18295", value2: "18295", value3: "18295", value4: "18295", name: "Prosperity Bank Direct (18295)" },
    { value: "09512", value2: "09512", value3: "09512", value4: "09512", name: "Prosperity Bank Treasury (09512)" },
    { value: "11954", value2: "11954", value3: "11954", value4: "11954", name: "Prosperity Bank Treasury New_DC (11954)" },
    { value: "18286", value2: "18286", value3: "18286", value4: "18286", name: "Prosperity Bank Web (18286)" },
    { value: "11273", value2: "11273", value3: "11273", value4: "11273", name: "Providence Bank - IL (11273)" },
    { value: "04647", value2: "04647", value3: "04647", value4: "04647", name: "Providence Bank, NC (04647)" },
    { value: "68144", value2: "68144", value3: "68144", value4: "68144", name: "Providence Bank, NC CeB Direct (68144)" },
    { value: "67854", value2: "67854", value3: "67854", value4: "67854", name: "Providence Bank, NC Direct Connect (67854)" },
    { value: "09170", value2: "09170", value3: "09170", value4: "09170", name: "Providence Bk - QBDC (09170)" },
    { value: "06472", value2: "06472", value3: "06472", value4: "06472", name: "Provident Bank - NJ (06472)" },
    { value: "01658", value2: "01658", value3: "01658", value4: "01658", name: "Provident Bank - Riverside (01658)" },
    { value: "02180", value2: "02180", value3: "02180", value4: "02180", name: "Provident Bank NJ-Business DC (02180)" },
    { value: "53985", value2: "53985", value3: "53985", value4: "53985", name: "Provident Bank NJ-Business WC (53985)" },
    { value: "18706", value2: "18706", value3: "18706", value4: "18706", name: "Provident Bank-NJ WC (18706)" },
    { value: "16558", value2: "16558", value3: "16558", value4: "16558", name: "Provident Credit Union-Web Connect (16558)" },
    { value: "00260", value2: "00260", value3: "00260", value4: "00260", name: "Public Service Credit Union - MI (00260)" },
    { value: "68057", value2: "68057", value3: "68057", value4: "68057", name: "Puget Sound Coop CU CC (68057)" },
    { value: "67615", value2: "67615", value3: "67615", value4: "67615", name: "Purdue Federal CU - Direct Connect (67615)" },
    { value: "70241", value2: "70241", value3: "70241", value4: "70241", name: "Putnam 1st Mercantile Bank (70241)" },
    { value: "70242", value2: "70242", value3: "70242", value4: "70242", name: "Putnam 1st Mercantile Bank QBDC (70242)" },
    { value: "70072", value2: "70072", value3: "70072", value4: "70072", name: "Putnam County National Bank (70072)" },
    { value: "49545", value2: "49545", value3: "49545", value4: "49545", name: "Pyramax Bank - Bus (49545)" },
    { value: "00146", value2: "00146", value3: "00146", value4: "00146", name: "QB's FIDIR TEST 2 (00146)" },
    { value: "02340", value2: "02340", value3: "02340", value4: "02340", name: "QNB Bank (02340)" },
    { value: "52635", value2: "52635", value3: "52635", value4: "52635", name: "Quad City Bank & Trust Business (52635)" },
    { value: "18016", value2: "18016", value3: "18016", value4: "52635", name: "Quad City Bank & Trust QB DC (18016)" },
    { value: "09619", value2: "09619", value3: "09619", value4: "09619", name: "Quad City Bank & Trust Retail (09619)" },
    { value: "06656", value2: "06656", value3: "06656", value4: "06656", name: "Quad City Bank & Trust Retail QB (06656)" },
    { value: "16104", value2: "16104", value3: "16104", value4: "16104", name: "Quadient Postage Funding (16104)" },
    { value: "06081", value2: "06081", value3: "06081", value4: "06081", name: "Quail Creek Bank, N.A. (06081)" },
    { value: "09614", value2: "09614", value3: "09614", value4: "09614", name: "Quaint Oak Bank Business (09614)" },
    { value: "12263", value2: "12263", value3: "12263", value4: "12263", name: "Qualstar Credit Union -New (12263)" },
    { value: "18198", value2: "18198", value3: "18198", value4: "18198", name: "Queensborough - WC (18198)" },
    { value: "67637", value2: "67637", value3: "67637", value4: "67637", name: "Queensborough Bus Banking-DC (67637)" },
    { value: "11853", value2: "11853", value3: "11853", value4: "11853", name: "Queensborough Online Banking-DC (11853)" },
    { value: "00933", value2: "00933", value3: "00933", value4: "00933", name: "Queenstown Bank of Maryland (00933)" },
    { value: "51390", value2: "51390", value3: "51390", value4: "51390", name: "Quest Federal Credit Union (51390)" },
    { value: "67194", value2: "67194", value3: "67194", value4: "67194", name: "Questa Credit Union (67194)" },
    { value: "10456", value2: "10456", value3: "10456", value4: "10456", name: "QuickBooks Platinum Plus (10456)" },
    { value: "09521", value2: "09521", value3: "09521", value4: "09521", name: "Quoin Bank (09521)" },
    { value: "04155", value2: "04155", value3: "04155", value4: "04155", name: "Quontic Bank (04155)" },
    { value: "11104", value2: "11104", value3: "11104", value4: "11104", name: "Quorum Federal Credit Union (11104)" },
    { value: "08015", value2: "08015", value3: "08015", value4: "08015", name: "Qup Systems (08015)" },
    { value: "53223", value2: "53223", value3: "53223", value4: "53223", name: "R Bank Texas (53223)" },
    { value: "07643", value2: "07643", value3: "07643", value4: "53223", name: "R Bank Texas - QBDC (07643)" },
    { value: "04753", value2: "04753", value3: "04753", value4: "04753", name: "RCB Bank (04753)" },
    { value: "69764", value2: "69764", value3: "69764", value4: "04753", name: "RCB Bank QBDC (69764)" },
    { value: "04971", value2: "04971", value3: "04971", value4: "04971", name: "REV FCU - SC (04971)" },
    { value: "09179", value2: "09179", value3: "09179", value4: "09179", name: "RGFCU (09179)" },
    { value: "68699", value2: "68699", value3: "68699", value4: "68699", name: "RNB Front Range SB Business DC (68699)" },
    { value: "14895", value2: "14895", value3: "14895", value4: "14895", name: "RSNB Bank (14895)" },
    { value: "13980", value2: "13980", value3: "13980", value4: "13980", name: "RTN Federal Credit Union (13980)" },
    { value: "06025", value2: "06025", value3: "06025", value4: "06025", name: "RVR Bank (06025)" },
    { value: "12290", value2: "12290", value3: "12290", value4: "06025", name: "RVR Bank-QBDC (12290)" },
    { value: "53802", value2: "53802", value3: "53802", value4: "53802", name: "Rabun County Bank - Business (53802)" },
    { value: "69360", value2: "69360", value3: "69360", value4: "69360", name: "Raccoon Valley Bank Bus DC (69360)" },
    { value: "10277", value2: "10277", value3: "10277", value4: "10277", name: "RadiFi Credit Union (10277)" },
    { value: "02120", value2: "02120", value3: "02120", value4: "02120", name: "Radiant Credit Union (02120)" },
    { value: "52662", value2: "52662", value3: "52662", value4: "52662", name: "Raiz Federal Credit Union (52662)" },
    { value: "08342", value2: "08342", value3: "08342", value4: "08342", name: "Rally Credit Union (08342)" },
    { value: "08345", value2: "08345", value3: "08345", value4: "08342", name: "Rally Credit Union QBDC (08345)" },
    { value: "70250", value2: "70250", value3: "70250", value4: "70250", name: "Randolph Brooks CU â€“ BUS CC (70250)" },
    { value: "07072", value2: "07072", value3: "07072", value4: "07072", name: "Randolph-Brooks FCU (07072)" },
    { value: "69489", value2: "69489", value3: "69489", value4: "69489", name: "Randolph-Brooks FCU EWC (69489)" },
    { value: "17091", value2: "17091", value3: "17091", value4: "17091", name: "Range Bank N.A. (17091)" },
    { value: "06214", value2: "06214", value3: "06214", value4: "06214", name: "Ravalli County FCU (06214)" },
    { value: "68002", value2: "68002", value3: "68002", value4: "68002", name: "Rave Financial QB (68002)" },
    { value: "00726", value2: "00726", value3: "00726", value4: "00726", name: "Rayne State Bank (00726)" },
    { value: "06980", value2: "06980", value3: "06980", value4: "06980", name: "Rayne State Bank Business (06980)" },
    { value: "07286", value2: "07286", value3: "07286", value4: "07286", name: "Rayne State Bank Business QB (07286)" },
    { value: "13599", value2: "13599", value3: "13599", value4: "13599", name: "Reading Cooperative Bank (13599)" },
    { value: "63672", value2: "63672", value3: "63672", value4: "13599", name: "Reading Cooperative Bank Bus (63672)" },
    { value: "16831", value2: "16831", value3: "16831", value4: "16831", name: "Readlyn Savings Bank (16831)" },
    { value: "10425", value2: "10425", value3: "10425", value4: "10425", name: "Red Canoe Credit Union (10425)" },
    { value: "15610", value2: "15610", value3: "15610", value4: "10425", name: "Red Canoe Credit Union QB (15610)" },
    { value: "12195", value2: "12195", value3: "12195", value4: "12195", name: "Red River Bank (12195)" },
    { value: "16854", value2: "16854", value3: "16854", value4: "16854", name: "Red River FCU (16854)" },
    { value: "56283", value2: "56283", value3: "56283", value4: "56283", name: "Red River FCU - OK (56283)" },
    { value: "33540", value2: "33540", value3: "33540", value4: "33540", name: "Red River State Bank (33540)" },
    { value: "70290", value2: "70290", value3: "70290", value4: "70290", name: "Red Rock Bank BO (70290)" },
    { value: "70292", value2: "70292", value3: "70292", value4: "70290", name: "Red Rock Bank BODC (70292)" },
    { value: "70289", value2: "70289", value3: "70289", value4: "70289", name: "Red Rock Bank RO (70289)" },
    { value: "70291", value2: "70291", value3: "70291", value4: "70291", name: "Red Rock Bank RODC (70291)" },
    { value: "01181", value2: "01181", value3: "01181", value4: "01181", name: "Red Rocks CU (01181)" },
    { value: "65043", value2: "65043", value3: "65043", value4: "65043", name: "Red Wing Credit Union Credit Card (65043)" },
    { value: "17352", value2: "17352", value3: "17352", value4: "17352", name: "Redstone Bank (17352)" },
    { value: "67751", value2: "67751", value3: "67751", value4: "67751", name: "Redstone Business Direct Connect (67751)" },
    { value: "08737", value2: "08737", value3: "08737", value4: "08737", name: "Redstone Federal Credit Union-QB (08737)" },
    { value: "14157", value2: "14157", value3: "14157", value4: "14157", name: "Redwood Capital Bank (14157)" },
    { value: "69528", value2: "69528", value3: "69528", value4: "69528", name: "Redwood Capital Bank DC (69528)" },
    { value: "70082", value2: "70082", value3: "70082", value4: "70082", name: "Redwood Credit Union Digital (70082)" },
    { value: "69493", value2: "69493", value3: "69493", value4: "69493", name: "Redwood Credit Union EWC (69493)" },
    { value: "52356", value2: "52356", value3: "52356", value4: "52356", name: "Regal Bank (52356)" },
    { value: "17556", value2: "17556", value3: "17556", value4: "17556", name: "Regent Bank - Oklahoma (17556)" },
    { value: "33636", value2: "33636", value3: "33636", value4: "33636", name: "Regional Missouri Bank (33636)" },
    { value: "16552", value2: "16552", value3: "16552", value4: "16552", name: "Regions Bank (16552)" },
    { value: "12533", value2: "12533", value3: "12533", value4: "12533", name: "Regions Bank - TSYS CentreSuite (12533)" },
    { value: "05702", value2: "05702", value3: "05702", value4: "05702", name: "Regions Bank - Web Connect (05702)" },
    { value: "70479", value2: "70479", value3: "70479", value4: "70479", name: "Regions Bank iTreasury DC (70479)" },
    { value: "16741", value2: "16741", value3: "16741", value4: "16741", name: "Regions Financial iTreasury (16741)" },
    { value: "57693", value2: "57693", value3: "57693", value4: "57693", name: "Reliabank Business (57693)" },
    { value: "00522", value2: "00522", value3: "00522", value4: "00522", name: "Reliabank Dakota (00522)" },
    { value: "62892", value2: "62892", value3: "62892", value4: "62892", name: "Reliance Bank - BusOnline (62892)" },
    { value: "59268", value2: "59268", value3: "59268", value4: "59268", name: "Reliance Bank - Business (59268)" },
    { value: "08138", value2: "08138", value3: "08138", value4: "08138", name: "Reliance Bank Business Direct (08138)" },
    { value: "09688", value2: "09688", value3: "09688", value4: "09688", name: "Reliance State Bank (09688)" },
    { value: "69897", value2: "69897", value3: "69897", value4: "69897", name: "Reliance State Bank WC QB (69897)" },
    { value: "05729", value2: "05729", value3: "05729", value4: "05729", name: "RelyOn CU (05729)" },
    { value: "18832", value2: "18832", value3: "18832", value4: "18832", name: "Relyance Bank Commercial Direct (18832)" },
    { value: "18826", value2: "18826", value3: "18826", value4: "18826", name: "Relyance Bank Commercial Web (18826)" },
    { value: "12152", value2: "12152", value3: "12152", value4: "12152", name: "Relyance Bank Direct Connect (12152)" },
    { value: "04277", value2: "04277", value3: "04277", value4: "04277", name: "Renasant - QB (04277)" },
    { value: "13590", value2: "13590", value3: "13590", value4: "13590", name: "Renasant Bank - BIB (13590)" },
    { value: "18133", value2: "18133", value3: "18133", value4: "18133", name: "Republic Bank & Trust - Bus (18133)" },
    { value: "18136", value2: "18136", value3: "18136", value4: "18133", name: "Republic Bank & Trust - Bus WC (18136)" },
    { value: "19767", value2: "19767", value3: "19767", value4: "19767", name: "Republic Bank - Business (19767)" },
    { value: "69310", value2: "69310", value3: "69310", value4: "69310", name: "Republic Bank CC (69310)" },
    { value: "05948", value2: "05948", value3: "05948", value4: "05948", name: "Republic Bank FRBK DC (05948)" },
    { value: "05942", value2: "05942", value3: "05942", value4: "05942", name: "Republic Bank FRBK WC (05942)" },
    { value: "17620", value2: "17620", value3: "17620", value4: "17620", name: "Republic Bank TM (17620)" },
    { value: "69469", value2: "69469", value3: "69469", value4: "17620", name: "Republic Bank TM QBDC (69469)" },
    { value: "16731", value2: "16731", value3: "16731", value4: "16731", name: "Republic Bank of Arizona (16731)" },
    { value: "04229", value2: "04229", value3: "04229", value4: "04229", name: "Republic Bank of Arizona QBDC (04229)" },
    { value: "03600", value2: "03600", value3: "03600", value4: "03600", name: "Resource Bank, Louisiana (03600)" },
    { value: "69571", value2: "69571", value3: "69571", value4: "69571", name: "Resource Bank, Louisiana - DC (69571)" },
    { value: "11626", value2: "11626", value3: "11626", value4: "11626", name: "Resource Bank, N.A. - Business (11626)" },
    { value: "01627", value2: "01627", value3: "01627", value4: "01627", name: "Resource Bank, N.A. - Personal (01627)" },
    { value: "11527", value2: "11527", value3: "11527", value4: "11527", name: "Resource One Credit Union (11527)" },
    { value: "15753", value2: "15753", value3: "15753", value4: "15753", name: "Revere Bank (15753)" },
    { value: "05545", value2: "05545", value3: "05545", value4: "05545", name: "Rhinebeck Bank (05545)" },
    { value: "11529", value2: "11529", value3: "11529", value4: "11529", name: "Rhode Island Credit Union (11529)" },
    { value: "24042", value2: "24042", value3: "24042", value4: "24042", name: "Richland FCU (24042)" },
    { value: "57393", value2: "57393", value3: "57393", value4: "24042", name: "Richland FCU_QB (57393)" },
    { value: "21564", value2: "21564", value3: "21564", value4: "21564", name: "Richwood Bank - Online (21564)" },
    { value: "62370", value2: "62370", value3: "62370", value4: "62370", name: "Riddell National Bank (62370)" },
    { value: "08098", value2: "08098", value3: "08098", value4: "08098", name: "Ridgewood Savings Bank - WC-QB (08098)" },
    { value: "56373", value2: "56373", value3: "56373", value4: "56373", name: "Riley State Bank (56373)" },
    { value: "70270", value2: "70270", value3: "70270", value4: "70270", name: "Rio Bank Business eCorp (70270)" },
    { value: "70269", value2: "70269", value3: "70269", value4: "70269", name: "Rio Bank Business eCorp DC (70269)" },
    { value: "59265", value2: "59265", value3: "59265", value4: "59265", name: "Rio Bank Personal (59265)" },
    { value: "70268", value2: "70268", value3: "70268", value4: "70268", name: "Rio Bank Personal DC (70268)" },
    { value: "50976", value2: "50976", value3: "50976", value4: "50976", name: "Rio Grande Credit Union (50976)" },
    { value: "02786", value2: "02786", value3: "02786", value4: "02786", name: "Rio Grande Valley CU CC (02786)" },
    { value: "63117", value2: "63117", value3: "63117", value4: "63117", name: "Ripco Credit Union (63117)" },
    { value: "52161", value2: "52161", value3: "52161", value4: "52161", name: "River Bank & Trust (52161)" },
    { value: "12008", value2: "12008", value3: "12008", value4: "12008", name: "River Bank & Trust DC (12008)" },
    { value: "03887", value2: "03887", value3: "03887", value4: "03887", name: "River Bank Stoddard - QB (03887)" },
    { value: "03896", value2: "03896", value3: "03896", value4: "03896", name: "River City Bank (03896)" },
    { value: "11955", value2: "11955", value3: "11955", value4: "11955", name: "River City Bank - Georgia (11955)" },
    { value: "69246", value2: "69246", value3: "69246", value4: "69246", name: "River City Bank Business (69246)" },
    { value: "65028", value2: "65028", value3: "65028", value4: "65028", name: "River City Bank CA - Commercial WC (65028)" },
    { value: "69332", value2: "69332", value3: "69332", value4: "69332", name: "River City Bank DC (69332)" },
    { value: "68373", value2: "68373", value3: "68373", value4: "68373", name: "River City FCU (68373)" },
    { value: "09074", value2: "09074", value3: "09074", value4: "09074", name: "River Valley - OH (09074)" },
    { value: "67840", value2: "67840", value3: "67840", value4: "67840", name: "River Valley Community Bank OFX (67840)" },
    { value: "67841", value2: "67841", value3: "67841", value4: "67841", name: "River Valley Community Bank QB (67841)" },
    { value: "50502", value2: "50502", value3: "50502", value4: "50502", name: "River Valley Credit Union (IA) (50502)" },
    { value: "17448", value2: "17448", value3: "17448", value4: "17448", name: "River Valley Credit Union - Ada (17448)" },
    { value: "04483", value2: "04483", value3: "04483", value4: "04483", name: "RiverBank (04483)" },
    { value: "63804", value2: "63804", value3: "63804", value4: "63804", name: "RiverHills Bank (63804)" },
    { value: "16554", value2: "16554", value3: "16554", value4: "16554", name: "RiverHills Bank, OH 042206574 (16554)" },
    { value: "01676", value2: "01676", value3: "01676", value4: "01676", name: "RiverWood Bank (01676)" },
    { value: "62544", value2: "62544", value3: "62544", value4: "62541", name: "RiverWorks Credit Union - BUS (62544)" },
    { value: "62541", value2: "62541", value3: "62541", value4: "62541", name: "RiverWorks Credit Union - NEW (62541)" },
    { value: "16213", value2: "16213", value3: "16213", value4: "16213", name: "Riverfront Federal CU - WC (16213)" },
    { value: "04698", value2: "04698", value3: "04698", value4: "04698", name: "Riverland Bank (04698)" },
    { value: "67311", value2: "67311", value3: "67311", value4: "04698", name: "Riverland Bank QBDC (67311)" },
    { value: "15922", value2: "15922", value3: "15922", value4: "15922", name: "Rivermark Community CU QuickBooks (15922)" },
    { value: "69551", value2: "69551", value3: "69551", value4: "69551", name: "Rivers Edge Bank (69551)" },
    { value: "00157", value2: "00157", value3: "00157", value4: "00157", name: "Rivers Falls State Bank - Web (00157)" },
    { value: "69512", value2: "69512", value3: "69512", value4: "69512", name: "Riverside Bank of Dublin (69512)" },
    { value: "49746", value2: "49746", value3: "49746", value4: "49746", name: "Riverside Savings Bank (49746)" },
    { value: "69052", value2: "69052", value3: "69052", value4: "69052", name: "Riverstone Bank Retail (69052)" },
    { value: "07658", value2: "07658", value3: "07658", value4: "07658", name: "Rivertrust FCU (07658)" },
    { value: "51582", value2: "51582", value3: "51582", value4: "51582", name: "Riverview Community Bank WA (51582)" },
    { value: "04079", value2: "04079", value3: "04079", value4: "51582", name: "Riverview Community Bank WA -QB (04079)" },
    { value: "03467", value2: "03467", value3: "03467", value4: "03467", name: "Riverways FCU (03467)" },
    { value: "65019", value2: "65019", value3: "65019", value4: "65019", name: "Riverwind Bank (65019)" },
    { value: "14067", value2: "14067", value3: "14067", value4: "14067", name: "Robert Lee State Bank (14067)" },
    { value: "02046", value2: "02046", value3: "02046", value4: "02046", name: "Robertson Banking Company (02046)" },
    { value: "70318", value2: "70318", value3: "70318", value4: "70318", name: "Rochelle State Bank BO (70318)" },
    { value: "70317", value2: "70317", value3: "70317", value4: "70318", name: "Rochelle State Bank BODC (70317)" },
    { value: "70315", value2: "70315", value3: "70315", value4: "70315", name: "Rochelle State Bank RO (70315)" },
    { value: "70316", value2: "70316", value3: "70316", value4: "70315", name: "Rochelle State Bank RODC (70316)" },
    { value: "50472", value2: "50472", value3: "50472", value4: "50472", name: "Rochester State Bank (50472)" },
    { value: "40341", value2: "40341", value3: "40341", value4: "40341", name: "Rock Valley Credit Union (40341)" },
    { value: "68706", value2: "68706", value3: "68706", value4: "68706", name: "RockPoint Bank (68706)" },
    { value: "68707", value2: "68707", value3: "68707", value4: "68707", name: "RockPoint Bank-DC (68707)" },
    { value: "68667", value2: "68667", value3: "68667", value4: "68667", name: "RockPoint Bank-TM (68667)" },
    { value: "68668", value2: "68668", value3: "68668", value4: "68668", name: "RockPoint Bank-TM-DC (68668)" },
    { value: "69578", value2: "69578", value3: "69578", value4: "69578", name: "Rocket Federal Credit Union (69578)" },
    { value: "12782", value2: "12782", value3: "12782", value4: "12782", name: "Rockhold Bank-Buisness (12782)" },
    { value: "01782", value2: "01782", value3: "01782", value4: "01782", name: "Rockland FCU - Web Connect (01782)" },
    { value: "10881", value2: "10881", value3: "10881", value4: "10881", name: "Rockland Trust (10881)" },
    { value: "04223", value2: "04223", value3: "04223", value4: "10881", name: "Rockland Trust - QB (04223)" },
    { value: "16953", value2: "16953", value3: "16953", value4: "16953", name: "Rocky Mountain Bank and Trust (16953)" },
    { value: "59193", value2: "59193", value3: "59193", value4: "59193", name: "Rocky Mountain Bank of MT (59193)" },
    { value: "15321", value2: "15321", value3: "15321", value4: "15321", name: "Rocky Mountain Credit Union (15321)" },
    { value: "18103", value2: "18103", value3: "18103", value4: "18103", name: "Rogue CU (18103)" },
    { value: "18202", value2: "18202", value3: "18202", value4: "18202", name: "Rogue CU QB (18202)" },
    { value: "54552", value2: "54552", value3: "54552", value4: "54552", name: "Rolling Hills Bank & Trust (54552)" },
    { value: "70143", value2: "70143", value3: "70143", value4: "70143", name: "Rolling Hills Bank & Trust BB DC (70143)" },
    { value: "70383", value2: "70383", value3: "70383", value4: "70383", name: "Rolling Hills Bank & Trust QBWC (70383)" },
    { value: "62916", value2: "62916", value3: "62916", value4: "62916", name: "Rollstone Bank & Trust - BeB (62916)" },
    { value: "09471", value2: "09471", value3: "09471", value4: "09471", name: "Rondout Savings Bank (09471)" },
    { value: "07134", value2: "07134", value3: "07134", value4: "07134", name: "Roscoe Bank (07134)" },
    { value: "04139", value2: "04139", value3: "04139", value4: "04139", name: "Rosedale Federal S & L (04139)" },
    { value: "62979", value2: "62979", value3: "62979", value4: "62979", name: "Round Top State Bank - Business (62979)" },
    { value: "14604", value2: "14604", value3: "14604", value4: "14604", name: "Roundbank (14604)" },
    { value: "62424", value2: "62424", value3: "62424", value4: "62424", name: "Roundbank - Business (62424)" },
    { value: "67530", value2: "67530", value3: "67530", value4: "67530", name: "Roxboro Savings Bank - Bus - NC (67530)" },
    { value: "67528", value2: "67528", value3: "67528", value4: "67528", name: "Roxboro Savings Bank - Bus - wc (67528)" },
    { value: "67527", value2: "67527", value3: "67527", value4: "67527", name: "Roxboro Savings Bank - Cons (67527)" },
    { value: "67529", value2: "67529", value3: "67529", value4: "67529", name: "Roxboro Savings Bank - Cons - dc (67529)" },
    { value: "08327", value2: "08327", value3: "08327", value4: "08327", name: "Royal Bank (08327)" },
    { value: "69746", value2: "69746", value3: "69746", value4: "69746", name: "Royal Banks of MO QBDC (69746)" },
    { value: "05659", value2: "05659", value3: "05659", value4: "05659", name: "Royal Banks of Missouri (05659)" },
    { value: "64212", value2: "64212", value3: "64212", value4: "64212", name: "Royal Business Bank (64212)" },
    { value: "68676", value2: "68676", value3: "68676", value4: "68676", name: "Royal Business Bank BO QBDC (68676)" },
    { value: "68419", value2: "68419", value3: "68419", value4: "68419", name: "Royal CU Business DC (68419)" },
    { value: "14737", value2: "14737", value3: "14737", value4: "14737", name: "Royal Credit Union - Consumer (14737)" },
    { value: "14734", value2: "14734", value3: "14734", value4: "14734", name: "Royal Credit Union - QB (14734)" },
    { value: "07283", value2: "07283", value3: "07283", value4: "07283", name: "Royal Credit Union Credit Card (07283)" },
    { value: "02400", value2: "02400", value3: "02400", value4: "02400", name: "Rumson-Fair Haven Bank & Trust (02400)" },
    { value: "51921", value2: "51921", value3: "51921", value4: "02400", name: "Rumson-Fair Haven Bank & Trust_DC (51921)" },
    { value: "69019", value2: "69019", value3: "69019", value4: "69019", name: "Rushford State Bank (69019)" },
    { value: "69020", value2: "69020", value3: "69020", value4: "69020", name: "Rushford State Bank - DC (69020)" },
    { value: "52746", value2: "52746", value3: "52746", value4: "52746", name: "Rutgers FCU Credit Card (52746)" },
    { value: "00730", value2: "00730", value3: "00730", value4: "00730", name: "S&T Bank - Direct Connect (00730)" },
    { value: "01265", value2: "01265", value3: "01265", value4: "01265", name: "S&T Bank - Web Connect (01265)" },
    { value: "67830", value2: "67830", value3: "67830", value4: "67830", name: "S.P.C. Credit Union (67830)" },
    { value: "01252", value2: "01252", value3: "01252", value4: "01252", name: "SAFE Credit Union (01252)" },
    { value: "02421", value2: "02421", value3: "02421", value4: "02421", name: "SAG-AFTRA FCU (02421)" },
    { value: "64608", value2: "64608", value3: "64608", value4: "64608", name: "SB of Waterloo Business Online (64608)" },
    { value: "14908", value2: "14908", value3: "14908", value4: "14908", name: "SC State Credit Union - WC QB (14908)" },
    { value: "58773", value2: "58773", value3: "58773", value4: "58773", name: "SC State Credit Union Credit Card (58773)" },
    { value: "52935", value2: "52935", value3: "52935", value4: "52935", name: "SC TELCO Federal Credit Union-DC (52935)" },
    { value: "16492", value2: "16492", value3: "16492", value4: "16492", name: "SCE FCU (16492)" },
    { value: "16495", value2: "16495", value3: "16495", value4: "16495", name: "SCE FCU - Quickbooks (16495)" },
    { value: "03652", value2: "03652", value3: "03652", value4: "03652", name: "SCU Credit Union (03652)" },
    { value: "50190", value2: "50190", value3: "50190", value4: "50190", name: "SECNY Federal Credit Union (50190)" },
    { value: "17386", value2: "17386", value3: "17386", value4: "17386", name: "SECU of Maryland (17386)" },
    { value: "19257", value2: "19257", value3: "19257", value4: "19257", name: "SEFCU-BB QB (19257)" },
    { value: "03365", value2: "03365", value3: "03365", value4: "03365", name: "SEFCU-BFS (03365)" },
    { value: "10921", value2: "10921", value3: "10921", value4: "10921", name: "SELCO CCU - Web Connect (10921)" },
    { value: "09914", value2: "09914", value3: "09914", value4: "09914", name: "SELCO CCU BusinessPro (09914)" },
    { value: "67150", value2: "67150", value3: "67150", value4: "67150", name: "SELCO CCU BusinessPro - Direct (67150)" },
    { value: "07610", value2: "07610", value3: "07610", value4: "07610", name: "SENB Business (07610)" },
    { value: "07613", value2: "07613", value3: "07613", value4: "07610", name: "SENB Business_DC (07613)" },
    { value: "07604", value2: "07604", value3: "07604", value4: "07604", name: "SENB Consumer (07604)" },
    { value: "07607", value2: "07607", value3: "07607", value4: "07604", name: "SENB Consumer_DC (07607)" },
    { value: "00444", value2: "00444", value3: "00444", value4: "00444", name: "SESLOC Federal Credit Union (00444)" },
    { value: "69312", value2: "69312", value3: "69312", value4: "69312", name: "SIU CU (69312)" },
    { value: "13332", value2: "13332", value3: "13332", value4: "13332", name: "SIU Credit Union (13332)" },
    { value: "67658", value2: "67658", value3: "67658", value4: "67658", name: "SIUE Credit Union (67658)" },
    { value: "09908", value2: "09908", value3: "09908", value4: "09908", name: "SLO Credit Union (09908)" },
    { value: "45822", value2: "45822", value3: "45822", value4: "45822", name: "SMW 104  FCU San Leandro, CA (45822)" },
    { value: "55782", value2: "55782", value3: "55782", value4: "45822", name: "SMW 104 FCU_DC (55782)" },
    { value: "17935", value2: "17935", value3: "17935", value4: "17935", name: "SMW Financial Credit Union (17935)" },
    { value: "17938", value2: "17938", value3: "17938", value4: "17938", name: "SMW Financial Credit Union_DC (17938)" },
    { value: "63432", value2: "63432", value3: "63432", value4: "63432", name: "SNB Credit Card (63432)" },
    { value: "12897", value2: "12897", value3: "12897", value4: "12897", name: "SPELC Federal Credit Union (12897)" },
    { value: "06898", value2: "06898", value3: "06898", value4: "06898", name: "SRI FCU (06898)" },
    { value: "16951", value2: "16951", value3: "16951", value4: "16951", name: "SRP Federal Credit Union (16951)" },
    { value: "09329", value2: "09329", value3: "09329", value4: "09329", name: "SSB Bank Business (09329)" },
    { value: "09440", value2: "09440", value3: "09440", value4: "09329", name: "SSB Bank Business DC (09440)" },
    { value: "08939", value2: "08939", value3: "08939", value4: "08939", name: "SSB Community Bank (08939)" },
    { value: "08942", value2: "08942", value3: "08942", value4: "08939", name: "SSB Community Bank - QBWC (08942)" },
    { value: "07352", value2: "07352", value3: "07352", value4: "07352", name: "SSB Hibbing BO (07352)" },
    { value: "11946", value2: "11946", value3: "11946", value4: "11946", name: "SSB Hibbing RO (11946)" },
    { value: "09005", value2: "09005", value3: "09005", value4: "09005", name: "SSB&T BeB WC (09005)" },
    { value: "09977", value2: "09977", value3: "09977", value4: "09977", name: "SSB&T CeB Direct Connect (09977)" },
    { value: "11695", value2: "11695", value3: "11695", value4: "11695", name: "STAR Financial Bank (11695)" },
    { value: "68647", value2: "68647", value3: "68647", value4: "68647", name: "STCU Business (68647)" },
    { value: "68648", value2: "68648", value3: "68648", value4: "68647", name: "STCU Business - QBDirect (68648)" },
    { value: "69530", value2: "69530", value3: "69530", value4: "69530", name: "STJ Federal Credit Union (69530)" },
    { value: "52014", value2: "52014", value3: "52014", value4: "52014", name: "SVB - Private Bank QB (52014)" },
    { value: "09535", value2: "09535", value3: "09535", value4: "09535", name: "SVB Direct Connect - Preferred (09535)" },
    { value: "08729", value2: "08729", value3: "08729", value4: "08729", name: "SVB Private - Legacy BP Client (08729)" },
    { value: "52947", value2: "52947", value3: "52947", value4: "52947", name: "SVB Private Bank (52947)" },
    { value: "18451", value2: "18451", value3: "18451", value4: "08729", name: "SVB Private QBDC - Legacy BP (18451)" },
    { value: "46329", value2: "46329", value3: "46329", value4: "46329", name: "Sabattus Regional CU (46329)" },
    { value: "41889", value2: "41889", value3: "41889", value4: "41889", name: "Sabine FCU (41889)" },
    { value: "12972", value2: "12972", value3: "12972", value4: "12972", name: "Sabine State Bank & Trust Co. (12972)" },
    { value: "17187", value2: "17187", value3: "17187", value4: "17187", name: "Saco Biddeford Savings-Business (17187)" },
    { value: "18070", value2: "18070", value3: "18070", value4: "18070", name: "Saco Valley Credit Union (18070)" },
    { value: "18094", value2: "18094", value3: "18094", value4: "18094", name: "Sacramento Credit Union QB (18094)" },
    { value: "53106", value2: "53106", value3: "53106", value4: "53106", name: "Safe Harbor Credit Union MI (53106)" },
    { value: "21432", value2: "21432", value3: "21432", value4: "21432", name: "SafeAmerica Credit Union (21432)" },
    { value: "62787", value2: "62787", value3: "62787", value4: "62787", name: "Safeway FCU (62787)" },
    { value: "62790", value2: "62790", value3: "62790", value4: "62787", name: "Safeway FCU_DC (62790)" },
    { value: "64224", value2: "64224", value3: "64224", value4: "64224", name: "Sage Capital Bank, N.A. (64224)" },
    { value: "03176", value2: "03176", value3: "03176", value4: "03176", name: "Sagelink Credit Union Credit Card (03176)" },
    { value: "05728", value2: "05728", value3: "05728", value4: "05728", name: "Salal Credit Union (05728)" },
    { value: "11206", value2: "11206", value3: "11206", value4: "11206", name: "Salem Co-Operative Bank (11206)" },
    { value: "02235", value2: "02235", value3: "02235", value4: "02235", name: "Salem Five Bank - Business Web (02235)" },
    { value: "04067", value2: "04067", value3: "04067", value4: "04067", name: "Salem Five Bank - Direct QB (04067)" },
    { value: "06746", value2: "06746", value3: "06746", value4: "06746", name: "Salem Five Bank - Online Banking (06746)" },
    { value: "02996", value2: "02996", value3: "02996", value4: "02996", name: "Salem VA Credit Union QB (02996)" },
    { value: "07339", value2: "07339", value3: "07339", value4: "07339", name: "Salyersville National Bank IB QB (07339)" },
    { value: "53103", value2: "53103", value3: "53103", value4: "53103", name: "San Antonio Citizens FCU (53103)" },
    { value: "01104", value2: "01104", value3: "01104", value4: "01104", name: "San Diego County Credit Union (01104)" },
    { value: "12401", value2: "12401", value3: "12401", value4: "12401", name: "San Diego Firefighters FCU CC (12401)" },
    { value: "18805", value2: "18805", value3: "18805", value4: "18805", name: "San Francisco FCU - New (18805)" },
    { value: "18808", value2: "18808", value3: "18808", value4: "18805", name: "San Francisco FCU - QBWC (18808)" },
    { value: "10827", value2: "10827", value3: "10827", value4: "10827", name: "San Francisco Fire Credit Union (10827)" },
    { value: "69407", value2: "69407", value3: "69407", value4: "69407", name: "San Juan Mountains Credit Union (69407)" },
    { value: "19785", value2: "19785", value3: "19785", value4: "19785", name: "San Mateo Credit Union (19785)" },
    { value: "55734", value2: "55734", value3: "55734", value4: "55734", name: "Sandhills State Bank (55734)" },
    { value: "69825", value2: "69825", value3: "69825", value4: "69825", name: "Sandhills State Bank -  Bus (69825)" },
    { value: "16666", value2: "16666", value3: "16666", value4: "16666", name: "Sandia Area (16666)" },
    { value: "20196", value2: "20196", value3: "20196", value4: "20196", name: "Sandia Laboratory Federal CU (20196)" },
    { value: "10422", value2: "10422", value3: "10422", value4: "10422", name: "Sandy Spring Bank (10422)" },
    { value: "67209", value2: "67209", value3: "67209", value4: "67209", name: "Sandy Spring Bank DC (67209)" },
    { value: "01250", value2: "01250", value3: "01250", value4: "01250", name: "Sandy Spring Bank eBIZ (01250)" },
    { value: "35592", value2: "35592", value3: "35592", value4: "35592", name: "Sanger Bank (35592)" },
    { value: "31908", value2: "31908", value3: "31908", value4: "31908", name: "Sanibel Captiva - Web Connect (31908)" },
    { value: "06815", value2: "06815", value3: "06815", value4: "06815", name: "Sanibel Captiva Bank - Business (06815)" },
    { value: "06812", value2: "06812", value3: "06812", value4: "06812", name: "Sanibel Captiva Bank - Personal (06812)" },
    { value: "07334", value2: "07334", value3: "07334", value4: "07334", name: "Santa Anna National Bank (07334)" },
    { value: "12741", value2: "12741", value3: "12741", value4: "12741", name: "Santa Cruz Community Credit Union (12741)" },
    { value: "57828", value2: "57828", value3: "57828", value4: "57828", name: "Santa Cruz County Bank (57828)" },
    { value: "57831", value2: "57831", value3: "57831", value4: "57828", name: "Santa Cruz County Bank QBDC (57831)" },
    { value: "13159", value2: "13159", value3: "13159", value4: "13159", name: "Santander Bank (13159)" },
    { value: "10381", value2: "10381", value3: "10381", value4: "10381", name: "Santander Bank - Corporate (10381)" },
    { value: "69451", value2: "69451", value3: "69451", value4: "69451", name: "Santander Bank QBDT EWC (69451)" },
    { value: "00644", value2: "00644", value3: "00644", value4: "00644", name: "Santee Cooper CU Credit Card (00644)" },
    { value: "08399", value2: "08399", value3: "08399", value4: "08399", name: "Saratoga National Bank - WC (08399)" },
    { value: "69541", value2: "69541", value3: "69541", value4: "69541", name: "Saratoga National Bank DC (69541)" },
    { value: "01337", value2: "01337", value3: "01337", value4: "01337", name: "Sauk Valley Bank & Trust (01337)" },
    { value: "12950", value2: "12950", value3: "12950", value4: "12950", name: "Savannah Bank Business (12950)" },
    { value: "12947", value2: "12947", value3: "12947", value4: "12947", name: "Savannah Bank Retail (12947)" },
    { value: "04293", value2: "04293", value3: "04293", value4: "04293", name: "Savers Bank (04293)" },
    { value: "55590", value2: "55590", value3: "55590", value4: "55590", name: "Savers Bank Bus Online - DC (55590)" },
    { value: "14166", value2: "14166", value3: "14166", value4: "14166", name: "SaviBank (14166)" },
    { value: "06590", value2: "06590", value3: "06590", value4: "06590", name: "Savings Bank of Danbury - New (06590)" },
    { value: "14128", value2: "14128", value3: "14128", value4: "14128", name: "Savings Bank of Danbury WC for QB (14128)" },
    { value: "01587", value2: "01587", value3: "01587", value4: "01587", name: "Savings Bank of Mendocino County (01587)" },
    { value: "05266", value2: "05266", value3: "05266", value4: "05266", name: "Savings Bank of Walpole (05266)" },
    { value: "69815", value2: "69815", value3: "69815", value4: "05266", name: "Savings Bank of Walpole DC (69815)" },
    { value: "07853", value2: "07853", value3: "07853", value4: "07853", name: "Sawyer Savings Bank (07853)" },
    { value: "58866", value2: "58866", value3: "58866", value4: "58866", name: "Scale Bank (58866)" },
    { value: "03822", value2: "03822", value3: "03822", value4: "03822", name: "Schaumburg Bank & Trust - IBB (03822)" },
    { value: "04449", value2: "04449", value3: "04449", value4: "04449", name: "Schaumburg Bank & Trust Company (04449)" },
    { value: "11952", value2: "11952", value3: "11952", value4: "11952", name: "Schertz Bank & Trust (11952)" },
    { value: "11999", value2: "11999", value3: "11999", value4: "11999", name: "Schertz Bank & Trust Bus QB DC (11999)" },
    { value: "11996", value2: "11996", value3: "11996", value4: "11996", name: "Schertz Bank & Trust Business (11996)" },
    { value: "67263", value2: "67263", value3: "67263", value4: "67263", name: "Schools Federal Credit Union (67263)" },
    { value: "02759", value2: "02759", value3: "02759", value4: "02759", name: "Scient Federal Credit Union (02759)" },
    { value: "09029", value2: "09029", value3: "09029", value4: "09029", name: "Scott & White Employees CU (09029)" },
    { value: "13236", value2: "13236", value3: "13236", value4: "13236", name: "Scott Credit Union (13236)" },
    { value: "06923", value2: "06923", value3: "06923", value4: "06923", name: "Scott State Bank (06923)" },
    { value: "11976", value2: "11976", value3: "11976", value4: "11976", name: "Scott Valley Bank (11976)" },
    { value: "69417", value2: "69417", value3: "69417", value4: "69417", name: "Scottsdale Community Bank (69417)" },
    { value: "69418", value2: "69418", value3: "69418", value4: "69417", name: "Scottsdale Community Bank-DC (69418)" },
    { value: "69419", value2: "69419", value3: "69419", value4: "69419", name: "Scottsdale Community Bank-TM (69419)" },
    { value: "69420", value2: "69420", value3: "69420", value4: "69419", name: "Scottsdale Community Bank-TM-DC (69420)" },
    { value: "09386", value2: "09386", value3: "09386", value4: "09386", name: "Sea Air Federal Credit Union (09386)" },
    { value: "53616", value2: "53616", value3: "53616", value4: "53616", name: "Seaboard Federal Credit Union (53616)" },
    { value: "06752", value2: "06752", value3: "06752", value4: "06752", name: "Seacoast National Bank (06752)" },
    { value: "69072", value2: "69072", value3: "69072", value4: "69072", name: "Seacoast National Bank QBDC (69072)" },
    { value: "15228", value2: "15228", value3: "15228", value4: "15228", name: "Seamen's Bank - Business (15228)" },
    { value: "12918", value2: "12918", value3: "12918", value4: "12918", name: "Seaside Bank & Trust (12918)" },
    { value: "12422", value2: "12422", value3: "12422", value4: "12422", name: "Seattle Bank DC (12422)" },
    { value: "12419", value2: "12419", value3: "12419", value4: "12419", name: "Seattle Bank New (12419)" },
    { value: "12428", value2: "12428", value3: "12428", value4: "12428", name: "Seattle Bank-Biz DC (12428)" },
    { value: "12425", value2: "12425", value3: "12425", value4: "12425", name: "Seattle Bank-Biz New (12425)" },
    { value: "18321", value2: "18321", value3: "18321", value4: "18321", name: "Seattle CU (18321)" },
    { value: "64722", value2: "64722", value3: "64722", value4: "64722", name: "Seattle Metropolitan Credit Card (64722)" },
    { value: "02867", value2: "02867", value3: "02867", value4: "02867", name: "Sebasticook Valley FCU (02867)" },
    { value: "13962", value2: "13962", value3: "13962", value4: "13962", name: "Security Bank (13962)" },
    { value: "16281", value2: "16281", value3: "16281", value4: "16281", name: "Security Bank & Trust Co. (16281)" },
    { value: "65364", value2: "65364", value3: "65364", value4: "65364", name: "Security Bank & Trust Company (65364)" },
    { value: "12893", value2: "12893", value3: "12893", value4: "12893", name: "Security Bank & Trust Company - DC (12893)" },
    { value: "10542", value2: "10542", value3: "10542", value4: "10542", name: "Security Bank - Laurel, NE (10542)" },
    { value: "06974", value2: "06974", value3: "06974", value4: "06974", name: "Security Bank - WI (06974)" },
    { value: "64335", value2: "64335", value3: "64335", value4: "64335", name: "Security Bank Business Online (64335)" },
    { value: "68660", value2: "68660", value3: "68660", value4: "68660", name: "Security Bank IL QBDC (68660)" },
    { value: "64392", value2: "64392", value3: "64392", value4: "64392", name: "Security Bank USA (64392)" },
    { value: "64332", value2: "64332", value3: "64332", value4: "64332", name: "Security Bank and Trust (64332)" },
    { value: "00699", value2: "00699", value3: "00699", value4: "00699", name: "Security Bank of Dyersburg (00699)" },
    { value: "23091", value2: "23091", value3: "23091", value4: "23091", name: "Security Bank of Kansas City (23091)" },
    { value: "55566", value2: "55566", value3: "55566", value4: "55566", name: "Security Bank of Pulaski Cty (55566)" },
    { value: "07325", value2: "07325", value3: "07325", value4: "07325", name: "Security Bank of Texas (07325)" },
    { value: "09920", value2: "09920", value3: "09920", value4: "07325", name: "Security Bank of Texas - QB DC (09920)" },
    { value: "00752", value2: "00752", value3: "00752", value4: "00752", name: "Security Bank of the Ozarks (00752)" },
    { value: "40335", value2: "40335", value3: "40335", value4: "40335", name: "Security Bank, s.b.-IL (40335)" },
    { value: "03778", value2: "03778", value3: "03778", value4: "03778", name: "Security Federal Bank (03778)" },
    { value: "67960", value2: "67960", value3: "67960", value4: "67960", name: "Security Federal Bank-TM (67960)" },
    { value: "67961", value2: "67961", value3: "67961", value4: "67960", name: "Security Federal Bank-TM-DC (67961)" },
    { value: "12450", value2: "12450", value3: "12450", value4: "12450", name: "Security Federal Savings Bank (12450)" },
    { value: "20433", value2: "20433", value3: "20433", value4: "20433", name: "Security Federal Savings Bank TN (20433)" },
    { value: "68900", value2: "68900", value3: "68900", value4: "68900", name: "Security Financial Bank (68900)" },
    { value: "64002", value2: "64002", value3: "64002", value4: "64002", name: "Security First - CorpLink Business (64002)" },
    { value: "55791", value2: "55791", value3: "55791", value4: "55791", name: "Security First Bank of ND (55791)" },
    { value: "02375", value2: "02375", value3: "02375", value4: "02375", name: "Security First National Bank (02375)" },
    { value: "28569", value2: "28569", value3: "28569", value4: "28569", name: "Security National Bank - IL (28569)" },
    { value: "69042", value2: "69042", value3: "69042", value4: "69042", name: "Security National Bank IA - BeB (69042)" },
    { value: "11442", value2: "11442", value3: "11442", value4: "11442", name: "Security National Bank of Omaha (11442)" },
    { value: "64824", value2: "64824", value3: "64824", value4: "64824", name: "Security National Bank of SD -BEB (64824)" },
    { value: "19995", value2: "19995", value3: "19995", value4: "19995", name: "Security Savings Bank - IL (19995)" },
    { value: "14107", value2: "14107", value3: "14107", value4: "14107", name: "Security Service FCU (14107)" },
    { value: "54186", value2: "54186", value3: "54186", value4: "54186", name: "Security State Bank - Marine (54186)" },
    { value: "09497", value2: "09497", value3: "09497", value4: "09497", name: "Security State Bank - WI (09497)" },
    { value: "69216", value2: "69216", value3: "69216", value4: "69216", name: "Security State Bank DC (69216)" },
    { value: "52071", value2: "52071", value3: "52071", value4: "52071", name: "Security State Bank Farwell (52071)" },
    { value: "69354", value2: "69354", value3: "69354", value4: "69354", name: "Security State Bank Farwell-DC (69354)" },
    { value: "54189", value2: "54189", value3: "54189", value4: "54186", name: "Security State Bank Marine DC (54189)" },
    { value: "69404", value2: "69404", value3: "69404", value4: "69404", name: "Security State Bank South TX DC (69404)" },
    { value: "69219", value2: "69219", value3: "69219", value4: "69219", name: "Security State Bank WC (69219)" },
    { value: "00578", value2: "00578", value3: "00578", value4: "00578", name: "Security State Bank WY Bus (00578)" },
    { value: "16674", value2: "16674", value3: "16674", value4: "16674", name: "Security State Bank WY Retail (16674)" },
    { value: "29433", value2: "29433", value3: "29433", value4: "29433", name: "Security State Bank of Oklahoma (29433)" },
    { value: "17256", value2: "17256", value3: "17256", value4: "17256", name: "Security State Bank- WA (17256)" },
    { value: "56583", value2: "56583", value3: "56583", value4: "56583", name: "Security State Bank-KS (56583)" },
    { value: "09890", value2: "09890", value3: "09890", value4: "09890", name: "Security State Bank-SD (09890)" },
    { value: "05435", value2: "05435", value3: "05435", value4: "05435", name: "Security State Bank-Scott City (05435)" },
    { value: "67249", value2: "67249", value3: "67249", value4: "05435", name: "Security State Bank-Scott QBDC (67249)" },
    { value: "12503", value2: "12503", value3: "12503", value4: "12503", name: "Security State Bank-WA BO (12503)" },
    { value: "56559", value2: "56559", value3: "56559", value4: "56559", name: "Security State Business eBanking (56559)" },
    { value: "70428", value2: "70428", value3: "70428", value4: "70428", name: "SecurityPlus FCU Bus CC (70428)" },
    { value: "50280", value2: "50280", value3: "50280", value4: "50280", name: "Select Bank-VA (50280)" },
    { value: "69595", value2: "69595", value3: "69595", value4: "69595", name: "Select Bank-VA-QBDC (69595)" },
    { value: "70401", value2: "70401", value3: "70401", value4: "70401", name: "Selfreliance Federal CU - QBWC (70401)" },
    { value: "15966", value2: "15966", value3: "15966", value4: "15966", name: "Selfreliance Federal Credit Union (15966)" },
    { value: "35796", value2: "35796", value3: "35796", value4: "35796", name: "Senath State Bank Senath, MO (35796)" },
    { value: "56541", value2: "56541", value3: "56541", value4: "35796", name: "Senath State Bank Senath, MO_DC (56541)" },
    { value: "09080", value2: "09080", value3: "09080", value4: "09080", name: "Sentinel Federal CU (09080)" },
    { value: "67668", value2: "67668", value3: "67668", value4: "67668", name: "Sentry Bank - MN (67668)" },
    { value: "67667", value2: "67667", value3: "67667", value4: "67668", name: "Sentry Bank QBDC (67667)" },
    { value: "16258", value2: "16258", value3: "16258", value4: "16258", name: "ServU CU (16258)" },
    { value: "06968", value2: "06968", value3: "06968", value4: "06968", name: "Serve Credit Union (06968)" },
    { value: "07914", value2: "07914", value3: "07914", value4: "07914", name: "Service 1st Federal Credit Union (07914)" },
    { value: "69588", value2: "69588", value3: "69588", value4: "69588", name: "Service CU Business DC (69588)" },
    { value: "11112", value2: "11112", value3: "11112", value4: "11112", name: "Service Credit Union (11112)" },
    { value: "55614", value2: "55614", value3: "55614", value4: "55614", name: "Service Credit Union - WC QB (55614)" },
    { value: "68282", value2: "68282", value3: "68282", value4: "68282", name: "Service First FCU (68282)" },
    { value: "68283", value2: "68283", value3: "68283", value4: "68283", name: "Service First FCU - QB (68283)" },
    { value: "67726", value2: "67726", value3: "67726", value4: "67726", name: "Servis1st Bank (67726)" },
    { value: "06587", value2: "06587", value3: "06587", value4: "06587", name: "Servis1st Bank, AL (06587)" },
    { value: "06326", value2: "06326", value3: "06326", value4: "06326", name: "ServisFirst Bank-New (06326)" },
    { value: "06329", value2: "06329", value3: "06329", value4: "06326", name: "ServisFirst Bank-WC (06329)" },
    { value: "05384", value2: "05384", value3: "05384", value4: "05384", name: "ServisFirst eServis Business (05384)" },
    { value: "15645", value2: "15645", value3: "15645", value4: "15645", name: "Settlers best Credit Union (15645)" },
    { value: "18594", value2: "18594", value3: "18594", value4: "18594", name: "Shamrock Bank, N.A. (18594)" },
    { value: "46464", value2: "46464", value3: "46464", value4: "46464", name: "Share Advantage CU Duluth, MN (46464)" },
    { value: "59157", value2: "59157", value3: "59157", value4: "46464", name: "Share Advantage CU_DC (59157)" },
    { value: "10886", value2: "10886", value3: "10886", value4: "10886", name: "Sharepoint Credit Union (10886)" },
    { value: "56463", value2: "56463", value3: "56463", value4: "56463", name: "Sharon Bank BO (56463)" },
    { value: "05428", value2: "05428", value3: "05428", value4: "05428", name: "Shelby County State Bank (05428)" },
    { value: "03869", value2: "03869", value3: "03869", value4: "03869", name: "Shelby County State Bank - IL (03869)" },
    { value: "20076", value2: "20076", value3: "20076", value4: "20076", name: "Shelby Savings Bank, SSB (20076)" },
    { value: "16821", value2: "16821", value3: "16821", value4: "16821", name: "Shelby State Bank - Personal (16821)" },
    { value: "11088", value2: "11088", value3: "11088", value4: "11088", name: "Shell Federal Credit Union (11088)" },
    { value: "67886", value2: "67886", value3: "67886", value4: "67886", name: "Sherburne State Bank (67886)" },
    { value: "67887", value2: "67887", value3: "67887", value4: "67887", name: "Sherburne State Bank - QBDC (67887)" },
    { value: "57324", value2: "57324", value3: "57324", value4: "57324", name: "Sherwood Community Bank (57324)" },
    { value: "12814", value2: "12814", value3: "12814", value4: "12814", name: "Shinhan Bank America (12814)" },
    { value: "09828", value2: "09828", value3: "09828", value4: "09828", name: "Shore United Bank (09828)" },
    { value: "68371", value2: "68371", value3: "68371", value4: "68371", name: "Shore United Bank - DC (68371)" },
    { value: "65352", value2: "65352", value3: "65352", value4: "65352", name: "Shoreline Credit Union (65352)" },
    { value: "00171", value2: "00171", value3: "00171", value4: "00171", name: "Shoreline Federal Credit Union (00171)" },
    { value: "05431", value2: "05431", value3: "05431", value4: "05431", name: "Sibley State Bank (05431)" },
    { value: "25023", value2: "25023", value3: "25023", value4: "25023", name: "Sidney FCU (25023)" },
    { value: "69118", value2: "69118", value3: "69118", value4: "69118", name: "Sierra Central CU Credit Card (69118)" },
    { value: "18091", value2: "18091", value3: "18091", value4: "18091", name: "Sierra Central Credit Union-DC (18091)" },
    { value: "17743", value2: "17743", value3: "17743", value4: "17743", name: "Sierra Central Credit Union-WC (17743)" },
    { value: "02929", value2: "02929", value3: "02929", value4: "02929", name: "Signal Financial FCU (02929)" },
    { value: "70394", value2: "70394", value3: "70394", value4: "70394", name: "Signal Financial FCU Bus CC (70394)" },
    { value: "12279", value2: "12279", value3: "12279", value4: "12279", name: "Signature Bank - Chicago (12279)" },
    { value: "04313", value2: "04313", value3: "04313", value4: "04313", name: "Signature Bank - New York (04313)" },
    { value: "16038", value2: "16038", value3: "16038", value4: "16038", name: "Signature Bank - Toledo, OH (16038)" },
    { value: "69452", value2: "69452", value3: "69452", value4: "69452", name: "Signature Bank NY- Bus EWC (69452)" },
    { value: "08368", value2: "08368", value3: "08368", value4: "08368", name: "Signature Bank of Arkansas (08368)" },
    { value: "03237", value2: "03237", value3: "03237", value4: "03237", name: "Signature Bank of Georgia (03237)" },
    { value: "68823", value2: "68823", value3: "68823", value4: "68823", name: "Signature Bank of Georgia BUS (68823)" },
    { value: "08822", value2: "08822", value3: "08822", value4: "08822", name: "Signature Bank-Chicago Direct (08822)" },
    { value: "13525", value2: "13525", value3: "13525", value4: "13525", name: "Sikorsky CU WC QuickBooks (13525)" },
    { value: "68342", value2: "68342", value3: "68342", value4: "68342", name: "Sikorsky Credit Union (68342)" },
    { value: "70044", value2: "70044", value3: "70044", value4: "70044", name: "Sikorsky Credit Union BB DC (70044)" },
    { value: "58173", value2: "58173", value3: "58173", value4: "09535", name: "Silicon Valley Banking SVB (58173)" },
    { value: "23094", value2: "23094", value3: "23094", value4: "23094", name: "Silver Lake Bank (23094)" },
    { value: "00716", value2: "00716", value3: "00716", value4: "00716", name: "Silver State Schools CU WebC QB (00716)" },
    { value: "64686", value2: "64686", value3: "64686", value4: "64686", name: "Silver State Schools Credit Union (64686)" },
    { value: "00371", value2: "00371", value3: "00371", value4: "00371", name: "Silvergate Bank - BeB (00371)" },
    { value: "51126", value2: "51126", value3: "51126", value4: "51126", name: "Simmesport State Bank (51126)" },
    { value: "52302", value2: "52302", value3: "52302", value4: "52302", name: "Simmons Bank - CentreSuite (52302)" },
    { value: "65130", value2: "65130", value3: "65130", value4: "65130", name: "Simmons Bank - Personal (65130)" },
    { value: "67122", value2: "67122", value3: "67122", value4: "67122", name: "Simmons Bank - TM (67122)" },
    { value: "67123", value2: "67123", value3: "67123", value4: "67122", name: "Simmons Bank - TM-DC (67123)" },
    { value: "65127", value2: "65127", value3: "65127", value4: "65130", name: "Simmons Bank- DL - NEW (65127)" },
    { value: "05789", value2: "05789", value3: "05789", value4: "05789", name: "Simplicity C.U. Credit Card (05789)" },
    { value: "70429", value2: "70429", value3: "70429", value4: "70429", name: "Simplicity CU Bus CC (70429)" },
    { value: "04392", value2: "04392", value3: "04392", value4: "04392", name: "Simplicity Credit Union (04392)" },
    { value: "63276", value2: "63276", value3: "63276", value4: "63276", name: "SimplyBank - Bus (63276)" },
    { value: "55002", value2: "55002", value3: "55002", value4: "55002", name: "Singing River FCU (55002)" },
    { value: "27990", value2: "27990", value3: "27990", value4: "27990", name: "Siouxland Federal Credit Union (27990)" },
    { value: "08566", value2: "08566", value3: "08566", value4: "08566", name: "Sjn Bank Of Kansas (08566)" },
    { value: "28542", value2: "28542", value3: "28542", value4: "28542", name: "Skowhegan Savings Bank (28542)" },
    { value: "24507", value2: "24507", value3: "24507", value4: "24507", name: "Sky FCU (24507)" },
    { value: "12928", value2: "12928", value3: "12928", value4: "12928", name: "Skyla Credit Union (12928)" },
    { value: "70283", value2: "70283", value3: "70283", value4: "70283", name: "Skyline Financial FCU - DC (70283)" },
    { value: "57186", value2: "57186", value3: "57186", value4: "57186", name: "Skyline National Bank - Business (57186)" },
    { value: "08858", value2: "08858", value3: "08858", value4: "08858", name: "Skyline National Bank-Bus Direct (08858)" },
    { value: "14766", value2: "14766", value3: "14766", value4: "14766", name: "Smackover State Bank (14766)" },
    { value: "02915", value2: "02915", value3: "02915", value4: "02915", name: "Smart Financial Credit Union (02915)" },
    { value: "00263", value2: "00263", value3: "00263", value4: "00263", name: "SmartBank - DirectConnect (00263)" },
    { value: "69592", value2: "69592", value3: "69592", value4: "69592", name: "SmartBank DC (69592)" },
    { value: "69593", value2: "69593", value3: "69593", value4: "69593", name: "SmartBank WC (69593)" },
    { value: "12687", value2: "12687", value3: "12687", value4: "12687", name: "Smartbank (12687)" },
    { value: "16750", value2: "16750", value3: "16750", value4: "16750", name: "Sno Falls Credit Union (16750)" },
    { value: "51963", value2: "51963", value3: "51963", value4: "51963", name: "SnoCope Credit Union (51963)" },
    { value: "16966", value2: "16966", value3: "16966", value4: "16966", name: "Solarity CU (16966)" },
    { value: "70145", value2: "70145", value3: "70145", value4: "70145", name: "Solera National Bank (70145)" },
    { value: "70138", value2: "70138", value3: "70138", value4: "70138", name: "Solera National Bank - DC (70138)" },
    { value: "01305", value2: "01305", value3: "01305", value4: "01305", name: "Solidarity FCU (01305)" },
    { value: "12122", value2: "12122", value3: "12122", value4: "12122", name: "Solutions Bank Business (12122)" },
    { value: "67655", value2: "67655", value3: "67655", value4: "67655", name: "Solutions Bank Business Direct (67655)" },
    { value: "55545", value2: "55545", value3: "55545", value4: "55545", name: "Solutions Bank Retail (55545)" },
    { value: "59217", value2: "59217", value3: "59217", value4: "59217", name: "Solutions North Bank (59217)" },
    { value: "64404", value2: "64404", value3: "64404", value4: "64404", name: "Somerset Trust Company (64404)" },
    { value: "69670", value2: "69670", value3: "69670", value4: "69670", name: "Sonata Bank (69670)" },
    { value: "70067", value2: "70067", value3: "70067", value4: "70067", name: "Sonata Bank - Treasury (70067)" },
    { value: "70068", value2: "70068", value3: "70068", value4: "70068", name: "Sonata Bank - Treasury Direct (70068)" },
    { value: "70092", value2: "70092", value3: "70092", value4: "70092", name: "Sonata Bank-DC (70092)" },
    { value: "08572", value2: "08572", value3: "08572", value4: "08572", name: "Soo Co-op Credit Union (08572)" },
    { value: "22161", value2: "22161", value3: "22161", value4: "22161", name: "Sooper CU (22161)" },
    { value: "17434", value2: "17434", value3: "17434", value4: "17434", name: "Sound CU - New (17434)" },
    { value: "19017", value2: "19017", value3: "19017", value4: "19017", name: "Sound Community Bank - Business (19017)" },
    { value: "17431", value2: "17431", value3: "17431", value4: "17431", name: "Sound Credit Union (17431)" },
    { value: "15729", value2: "15729", value3: "15729", value4: "15729", name: "South Atlantic Bank (15729)" },
    { value: "07655", value2: "07655", value3: "07655", value4: "15729", name: "South Atlantic Bank - DC (07655)" },
    { value: "59037", value2: "59037", value3: "59037", value4: "59037", name: "South Carolina Credit Card (59037)" },
    { value: "11706", value2: "11706", value3: "11706", value4: "11706", name: "South Carolina Federal CU (11706)" },
    { value: "04467", value2: "04467", value3: "04467", value4: "04467", name: "South Central Bank, Inc (04467)" },
    { value: "62556", value2: "62556", value3: "62556", value4: "62556", name: "South Coast Bank and Trust (62556)" },
    { value: "03941", value2: "03941", value3: "03941", value4: "62556", name: "South Coast Bank and Trust-DL (03941)" },
    { value: "70075", value2: "70075", value3: "70075", value4: "70074", name: "South GA Bank QBDC (70075)" },
    { value: "70074", value2: "70074", value3: "70074", value4: "70074", name: "South Georgia Bank (70074)" },
    { value: "69680", value2: "69680", value3: "69680", value4: "69680", name: "South Georgia Banking Comp QBDC (69680)" },
    { value: "04599", value2: "04599", value3: "04599", value4: "04599", name: "South Georgia Banking Company (04599)" },
    { value: "12825", value2: "12825", value3: "12825", value4: "12825", name: "South Louisiana Bank (12825)" },
    { value: "30294", value2: "30294", value3: "30294", value4: "30294", name: "South Ottumwa Savings Bank (30294)" },
    { value: "00890", value2: "00890", value3: "00890", value4: "00890", name: "South Shore Bank QBWC (00890)" },
    { value: "04769", value2: "04769", value3: "04769", value4: "04769", name: "South Shore Bank-Business DC (04769)" },
    { value: "67641", value2: "67641", value3: "67641", value4: "67640", name: "South State Bank QBDC (67641)" },
    { value: "53709", value2: "53709", value3: "53709", value4: "53709", name: "South Story Bank and Trust (53709)" },
    { value: "69421", value2: "69421", value3: "69421", value4: "69421", name: "South Story Bank and Trust BO DC (69421)" },
    { value: "69422", value2: "69422", value3: "69422", value4: "69422", name: "South Story Bank and Trust BO WC (69422)" },
    { value: "64410", value2: "64410", value3: "64410", value4: "64410", name: "SouthBay Credit Union (64410)" },
    { value: "34782", value2: "34782", value3: "34782", value4: "34782", name: "SouthEast Bank (34782)" },
    { value: "12803", value2: "12803", value3: "12803", value4: "12803", name: "SouthPoint Bank - DC (12803)" },
    { value: "42582", value2: "42582", value3: "42582", value4: "42582", name: "SouthPoint FCU (42582)" },
    { value: "08684", value2: "08684", value3: "08684", value4: "08684", name: "SouthPoint FCU Direct Connect (08684)" },
    { value: "57090", value2: "57090", value3: "57090", value4: "57090", name: "SouthStar Bank, S.S.B.-Business (57090)" },
    { value: "51780", value2: "51780", value3: "51780", value4: "51780", name: "SouthStar Bank, SSB-Personal (51780)" },
    { value: "67640", value2: "67640", value3: "67640", value4: "67640", name: "SouthState (67640)" },
    { value: "67771", value2: "67771", value3: "67771", value4: "05999", name: "SouthState Bank, N.A. DC QB (67771)" },
    { value: "05999", value2: "05999", value3: "05999", value4: "05999", name: "SouthState- Treasury Navigator (05999)" },
    { value: "00488", value2: "00488", value3: "00488", value4: "00488", name: "SouthTrust Bank (00488)" },
    { value: "13431", value2: "13431", value3: "13431", value4: "13431", name: "SouthWest Bank - TX (13431)" },
    { value: "00173", value2: "00173", value3: "00173", value4: "00173", name: "Southbridge Credit Union (00173)" },
    { value: "55788", value2: "55788", value3: "55788", value4: "55788", name: "Southeastern Bank (55788)" },
    { value: "68783", value2: "68783", value3: "68783", value4: "68783", name: "Southeastern Credit Union (68783)" },
    { value: "68866", value2: "68866", value3: "68866", value4: "68866", name: "Southern Ag Credit (68866)" },
    { value: "09320", value2: "09320", value3: "09320", value4: "09320", name: "Southern Bancorp (09320)" },
    { value: "09323", value2: "09323", value3: "09323", value4: "09320", name: "Southern Bancorp - DC (09323)" },
    { value: "57729", value2: "57729", value3: "57729", value4: "57729", name: "Southern Bank - BusinessPro (57729)" },
    { value: "03116", value2: "03116", value3: "03116", value4: "03116", name: "Southern Bank - BusinessPro-DC (03116)" },
    { value: "02746", value2: "02746", value3: "02746", value4: "02746", name: "Southern Bank - MO (02746)" },
    { value: "11310", value2: "11310", value3: "11310", value4: "11310", name: "Southern Bank - Web Connect (11310)" },
    { value: "00293", value2: "00293", value3: "00293", value4: "00293", name: "Southern Bank of Tennessee (00293)" },
    { value: "70472", value2: "70472", value3: "70472", value4: "70472", name: "Southern Bank of Tennessee-DC (70472)" },
    { value: "69837", value2: "69837", value3: "69837", value4: "69837", name: "Southern Bank, MO - DC (69837)" },
    { value: "02870", value2: "02870", value3: "02870", value4: "02870", name: "Southern Chautauqua FCU (02870)" },
    { value: "63990", value2: "63990", value3: "63990", value4: "63990", name: "Southern First Bank -Business DC (63990)" },
    { value: "55515", value2: "55515", value3: "55515", value4: "55515", name: "Southern First Bank -Business WC (55515)" },
    { value: "09809", value2: "09809", value3: "09809", value4: "09809", name: "Southern Heritage Bank WC (09809)" },
    { value: "68054", value2: "68054", value3: "68054", value4: "68054", name: "Southern Illinois Bank (68054)" },
    { value: "68070", value2: "68070", value3: "68070", value4: "68070", name: "Southern Illinois Bank, IL (68070)" },
    { value: "12432", value2: "12432", value3: "12432", value4: "12432", name: "Southern Independent Bank (12432)" },
    { value: "04448", value2: "04448", value3: "04448", value4: "04448", name: "Southern Michigan Bank & Trst-DC (04448)" },
    { value: "50016", value2: "50016", value3: "50016", value4: "50016", name: "Southern Michigan Bank and Trust (50016)" },
    { value: "15981", value2: "15981", value3: "15981", value4: "15981", name: "Southern States Bank (15981)" },
    { value: "08237", value2: "08237", value3: "08237", value4: "08237", name: "Southern States Bank DC (08237)" },
    { value: "67216", value2: "67216", value3: "67216", value4: "67216", name: "SouthernTrust Bank - WC (67216)" },
    { value: "67183", value2: "67183", value3: "67183", value4: "67183", name: "SouthernTrust Bank-QB (67183)" },
    { value: "11038", value2: "11038", value3: "11038", value4: "11038", name: "Southland Credit Union (11038)" },
    { value: "11201", value2: "11201", value3: "11201", value4: "11201", name: "Southside Bank (11201)" },
    { value: "67780", value2: "67780", value3: "67780", value4: "11201", name: "Southside Bank - TX QB (67780)" },
    { value: "46185", value2: "46185", value3: "46185", value4: "46185", name: "Southwest 66 CU (46185)" },
    { value: "69970", value2: "69970", value3: "69970", value4: "46185", name: "Southwest 66 CU - QBWC (69970)" },
    { value: "06575", value2: "06575", value3: "06575", value4: "06575", name: "Southwest 66 CU CC (06575)" },
    { value: "00275", value2: "00275", value3: "00275", value4: "00275", name: "Southwest Capital - Bus (00275)" },
    { value: "50547", value2: "50547", value3: "50547", value4: "50547", name: "Southwest Financial (50547)" },
    { value: "63315", value2: "63315", value3: "63315", value4: "63315", name: "Southwest Heritage Credit Union (63315)" },
    { value: "01144", value2: "01144", value3: "01144", value4: "01144", name: "Southwest Missouri Bank (01144)" },
    { value: "05779", value2: "05779", value3: "05779", value4: "05779", name: "Southwest National Bank (05779)" },
    { value: "50988", value2: "50988", value3: "50988", value4: "50988", name: "Southwest Oklahoma Federal CU (50988)" },
    { value: "35640", value2: "35640", value3: "35640", value4: "35640", name: "Southwestern National Bank (35640)" },
    { value: "68486", value2: "68486", value3: "68486", value4: "68486", name: "Sovereign Bank (68486)" },
    { value: "69015", value2: "69015", value3: "69015", value4: "69015", name: "Sovereign Bank Bus DC (69015)" },
    { value: "69380", value2: "69380", value3: "69380", value4: "69380", name: "Sovereign Bank Bus WC (69380)" },
    { value: "11842", value2: "11842", value3: "11842", value4: "11842", name: "Sovita Credit Union (11842)" },
    { value: "11189", value2: "11189", value3: "11189", value4: "11189", name: "Space City Credit Union (11189)" },
    { value: "15412", value2: "15412", value3: "15412", value4: "15412", name: "Space Coast CU (15412)" },
    { value: "27642", value2: "27642", value3: "27642", value4: "27642", name: "Spectra Bank (27642)" },
    { value: "67323", value2: "67323", value3: "67323", value4: "27642", name: "Spectra Bank QBDC (67323)" },
    { value: "68777", value2: "68777", value3: "68777", value4: "68777", name: "Spectrum CU (68777)" },
    { value: "08120", value2: "08120", value3: "08120", value4: "08120", name: "Spencer Savings Bank - Cash Mgmt (08120)" },
    { value: "54420", value2: "54420", value3: "54420", value4: "54420", name: "Spencer Savings Bank QBWC (54420)" },
    { value: "01645", value2: "01645", value3: "01645", value4: "01645", name: "Spero Financial (01645)" },
    { value: "04278", value2: "04278", value3: "04278", value4: "04278", name: "Spirit of Alaska FCU (04278)" },
    { value: "11313", value2: "11313", value3: "11313", value4: "11313", name: "SpiritBank (11313)" },
    { value: "11951", value2: "11951", value3: "11951", value4: "11951", name: "SpiritBank - Business (11951)" },
    { value: "11942", value2: "11942", value3: "11942", value4: "11942", name: "SpiritBank - Business DC (11942)" },
    { value: "54375", value2: "54375", value3: "54375", value4: "11313", name: "SpiritBank - QB (54375)" },
    { value: "18039", value2: "18039", value3: "18039", value4: "18039", name: "Spokane Firefighters (18039)" },
    { value: "17514", value2: "17514", value3: "17514", value4: "17514", name: "Spokane Media FCU (17514)" },
    { value: "50643", value2: "50643", value3: "50643", value4: "50643", name: "Spokane Teachers Credit Union-new (50643)" },
    { value: "69189", value2: "69189", value3: "69189", value4: "69189", name: "Spring Bank Business DC (69189)" },
    { value: "08048", value2: "08048", value3: "08048", value4: "08048", name: "Spring Bank Business WC (08048)" },
    { value: "58062", value2: "58062", value3: "58062", value4: "58062", name: "Spring Bank Direct (58062)" },
    { value: "69424", value2: "69424", value3: "69424", value4: "69424", name: "Spring Bank NY Online Banking DC (69424)" },
    { value: "69222", value2: "69222", value3: "69222", value4: "69222", name: "Spring Bank NY Online Banking WC (69222)" },
    { value: "05672", value2: "05672", value3: "05672", value4: "05672", name: "Spring Bank-Business (05672)" },
    { value: "13608", value2: "13608", value3: "13608", value4: "13608", name: "Spring Hill State Bank, Longview (13608)" },
    { value: "02216", value2: "02216", value3: "02216", value4: "13608", name: "Spring Hill State Bank,Longview-DC (02216)" },
    { value: "70442", value2: "70442", value3: "70442", value4: "70442", name: "Spring Valley Bank Trust Bus CC (70442)" },
    { value: "49491", value2: "49491", value3: "49491", value4: "49491", name: "Spring Valley City Bank (49491)" },
    { value: "09680", value2: "09680", value3: "09680", value4: "09680", name: "Springfield State Bank-BO (09680)" },
    { value: "14505", value2: "14505", value3: "14505", value4: "14505", name: "Springfield State Bank-Retail (14505)" },
    { value: "03962", value2: "03962", value3: "03962", value4: "03962", name: "Springs Valley B&T Credit Card (03962)" },
    { value: "13611", value2: "13611", value3: "13611", value4: "13611", name: "Springs Valley Bank & Trust (13611)" },
    { value: "62994", value2: "62994", value3: "62994", value4: "62994", name: "Springs Valley Bank - Business (62994)" },
    { value: "70516", value2: "70516", value3: "70516", value4: "70516", name: "Spur Security Bank (70516)" },
    { value: "70517", value2: "70517", value3: "70517", value4: "70517", name: "Spur Security Bank - DC (70517)" },
    { value: "12921", value2: "12921", value3: "12921", value4: "12879", name: "Square 1 Bank - QB Web-OLD (12921)" },
    { value: "70393", value2: "70393", value3: "70393", value4: "70393", name: "St Anne's Credit Union Bus CC (70393)" },
    { value: "69776", value2: "69776", value3: "69776", value4: "69776", name: "St Anne's Credit Union CC (69776)" },
    { value: "11916", value2: "11916", value3: "11916", value4: "11916", name: "St Charles Bank & Trust Bus (11916)" },
    { value: "12090", value2: "12090", value3: "12090", value4: "12090", name: "St Charles Bank & Trust IBB (12090)" },
    { value: "70344", value2: "70344", value3: "70344", value4: "70344", name: "St Henry Bank BO (70344)" },
    { value: "70343", value2: "70343", value3: "70343", value4: "70344", name: "St Henry Bank BODC (70343)" },
    { value: "70341", value2: "70341", value3: "70341", value4: "70341", name: "St Henry Bank RO (70341)" },
    { value: "70342", value2: "70342", value3: "70342", value4: "70341", name: "St Henry Bank RODC (70342)" },
    { value: "69608", value2: "69608", value3: "69608", value4: "69608", name: "St Louis Community CU (69608)" },
    { value: "15091", value2: "15091", value3: "15091", value4: "15091", name: "St Paul Federal Credit Union (15091)" },
    { value: "15094", value2: "15094", value3: "15094", value4: "15091", name: "St Paul Federal Credit Union QB (15094)" },
    { value: "69097", value2: "69097", value3: "69097", value4: "69097", name: "St Pius X Church FCU CC (69097)" },
    { value: "64407", value2: "64407", value3: "64407", value4: "64407", name: "St. Anne's Credit Union - BO (64407)" },
    { value: "16473", value2: "16473", value3: "16473", value4: "16473", name: "St. Cloud Financial Credit Union (16473)" },
    { value: "17320", value2: "17320", value3: "17320", value4: "17320", name: "St. Francis X Federal CU (17320)" },
    { value: "16260", value2: "16260", value3: "16260", value4: "16260", name: "St. John's Bank & Trust Co. (16260)" },
    { value: "09473", value2: "09473", value3: "09473", value4: "09473", name: "St. Lawrence Federal Credit Union (09473)" },
    { value: "06069", value2: "06069", value3: "06069", value4: "06069", name: "St. Louis Bank (06069)" },
    { value: "16570", value2: "16570", value3: "16570", value4: "06069", name: "St. Louis Bank QBDC (16570)" },
    { value: "08261", value2: "08261", value3: "08261", value4: "08261", name: "St. Mary's Bank - New (08261)" },
    { value: "04232", value2: "04232", value3: "04232", value4: "04232", name: "St. Mary's Credit Union (04232)" },
    { value: "04235", value2: "04235", value3: "04235", value4: "04232", name: "St. Mary's Credit Union - WC (04235)" },
    { value: "50481", value2: "50481", value3: "50481", value4: "50481", name: "StagePoint FCU (50481)" },
    { value: "05005", value2: "05005", value3: "05005", value4: "05005", name: "Staley Credit Union (05005)" },
    { value: "01967", value2: "01967", value3: "01967", value4: "01967", name: "Standard Register FCU (01967)" },
    { value: "52374", value2: "52374", value3: "52374", value4: "52374", name: "Standard Register FCU - QB (52374)" },
    { value: "13036", value2: "13036", value3: "13036", value4: "13036", name: "Stanford Federal Credit Union (13036)" },
    { value: "02384", value2: "02384", value3: "02384", value4: "02384", name: "Star Bank (02384)" },
    { value: "65187", value2: "65187", value3: "65187", value4: "65187", name: "Star Choice Credit Union (65187)" },
    { value: "04385", value2: "04385", value3: "04385", value4: "04385", name: "Star One Credit Union - New (04385)" },
    { value: "55698", value2: "55698", value3: "55698", value4: "55698", name: "Star One Credit Union - QB (55698)" },
    { value: "18964", value2: "18964", value3: "18964", value4: "18964", name: "Starion Bank - Business (18964)" },
    { value: "50739", value2: "50739", value3: "50739", value4: "50739", name: "Starion Bank - Personal (50739)" },
    { value: "32211", value2: "32211", value3: "32211", value4: "32211", name: "State Bank & Trust AL (32211)" },
    { value: "03797", value2: "03797", value3: "03797", value4: "20118", name: "State Bank & Trust Kenmar-DC (03797)" },
    { value: "20118", value2: "20118", value3: "20118", value4: "20118", name: "State Bank & Trust Kenmare (20118)" },
    { value: "19437", value2: "19437", value3: "19437", value4: "19437", name: "State Bank - IN (19437)" },
    { value: "67132", value2: "67132", value3: "67132", value4: "67132", name: "State Bank Davis (67132)" },
    { value: "69909", value2: "69909", value3: "69909", value4: "02438", name: "State Bank De Kalb QBDC (69909)" },
    { value: "68058", value2: "68058", value3: "68058", value4: "68058", name: "State Bank Financial-Business (68058)" },
    { value: "13215", value2: "13215", value3: "13215", value4: "13215", name: "State Bank Northwest (13215)" },
    { value: "18873", value2: "18873", value3: "18873", value4: "18873", name: "State Bank Of Downs (18873)" },
    { value: "68625", value2: "68625", value3: "68625", value4: "68625", name: "State Bank WY Retail (68625)" },
    { value: "05633", value2: "05633", value3: "05633", value4: "05633", name: "State Bank of Bottineau -DC (05633)" },
    { value: "05636", value2: "05636", value3: "05636", value4: "05633", name: "State Bank of Bottineau-WC (05636)" },
    { value: "67604", value2: "67604", value3: "67604", value4: "67604", name: "State Bank of Chandler, MN (67604)" },
    { value: "67605", value2: "67605", value3: "67605", value4: "67604", name: "State Bank of Chandler, MN-DC (67605)" },
    { value: "35274", value2: "35274", value3: "35274", value4: "35274", name: "State Bank of Cherry (35274)" },
    { value: "64698", value2: "64698", value3: "64698", value4: "64698", name: "State Bank of Chilton (64698)" },
    { value: "63882", value2: "63882", value3: "63882", value4: "63882", name: "State Bank of Cross Plains CC (63882)" },
    { value: "02438", value2: "02438", value3: "02438", value4: "02438", name: "State Bank of De Kalb (02438)" },
    { value: "09812", value2: "09812", value3: "09812", value4: "09812", name: "State Bank of Downs - DC (09812)" },
    { value: "50685", value2: "50685", value3: "50685", value4: "50685", name: "State Bank of Eagle Butte (50685)" },
    { value: "05342", value2: "05342", value3: "05342", value4: "05342", name: "State Bank of India Calif - BEB (05342)" },
    { value: "06305", value2: "06305", value3: "06305", value4: "06305", name: "State Bank of India Direct Connect (06305)" },
    { value: "03335", value2: "03335", value3: "03335", value4: "03335", name: "State Bank of New Richland (03335)" },
    { value: "03338", value2: "03338", value3: "03338", value4: "03335", name: "State Bank of New Richland-DC (03338)" },
    { value: "03845", value2: "03845", value3: "03845", value4: "03845", name: "State Bank of Reeseville (03845)" },
    { value: "18910", value2: "18910", value3: "18910", value4: "18910", name: "State Bank of Southern Utah (18910)" },
    { value: "18913", value2: "18913", value3: "18913", value4: "18913", name: "State Bank of Southern Utah DC (18913)" },
    { value: "17430", value2: "17430", value3: "17430", value4: "17430", name: "State Bank of Southwest Missouri (17430)" },
    { value: "36531", value2: "36531", value3: "36531", value4: "36531", name: "State Bank of Table Rock (36531)" },
    { value: "64251", value2: "64251", value3: "64251", value4: "36531", name: "State Bank of Table Rock DC (64251)" },
    { value: "68616", value2: "68616", value3: "68616", value4: "68616", name: "State Bank of Taunton (68616)" },
    { value: "01640", value2: "01640", value3: "01640", value4: "01640", name: "State Bank of Toledo (01640)" },
    { value: "70029", value2: "70029", value3: "70029", value4: "70029", name: "State Bank of Toledo QBWC (70029)" },
    { value: "55572", value2: "55572", value3: "55572", value4: "55572", name: "State Bank of Waterloo-IL (55572)" },
    { value: "04446", value2: "04446", value3: "04446", value4: "04446", name: "State Bank of the Lakes Bus (04446)" },
    { value: "03909", value2: "03909", value3: "03909", value4: "03909", name: "State Bank of the Lakes IBB (03909)" },
    { value: "50817", value2: "50817", value3: "50817", value4: "50817", name: "State Bank-Freeport, IL (50817)" },
    { value: "67909", value2: "67909", value3: "67909", value4: "67909", name: "State Bank-IN (67909)" },
    { value: "70233", value2: "70233", value3: "70233", value4: "70233", name: "State Employees CU MD â€“ Bus CC (70233)" },
    { value: "02837", value2: "02837", value3: "02837", value4: "02837", name: "State Employees Credit Card (02837)" },
    { value: "00907", value2: "00907", value3: "00907", value4: "00907", name: "State Employees Credit Union of MD (00907)" },
    { value: "63834", value2: "63834", value3: "63834", value4: "63834", name: "State Police Credit Card (63834)" },
    { value: "12727", value2: "12727", value3: "12727", value4: "12727", name: "State Savings Bank - Michigan (12727)" },
    { value: "69631", value2: "69631", value3: "69631", value4: "69631", name: "State Savings Bank BB-Retail (69631)" },
    { value: "69630", value2: "69630", value3: "69630", value4: "69630", name: "State Savings Bank BB-Retail WC (69630)" },
    { value: "53862", value2: "53862", value3: "53862", value4: "53862", name: "State Savings Bank-Creston (53862)" },
    { value: "67181", value2: "67181", value3: "67181", value4: "67181", name: "State Street Bank Cash Mgmt (67181)" },
    { value: "68624", value2: "68624", value3: "68624", value4: "68624", name: "State Street Bank Digital Bkg (68624)" },
    { value: "50196", value2: "50196", value3: "50196", value4: "50196", name: "Stearns Bank - Holdingford (50196)" },
    { value: "50193", value2: "50193", value3: "50193", value4: "50193", name: "Stearns Bank -St Cloud (50193)" },
    { value: "49863", value2: "49863", value3: "49863", value4: "49863", name: "Stearns Bank-Upsala (49863)" },
    { value: "67949", value2: "67949", value3: "67949", value4: "67949", name: "Stellar Bank (67949)" },
    { value: "67950", value2: "67950", value3: "67950", value4: "67950", name: "Stellar Bank QBDC (67950)" },
    { value: "00530", value2: "00530", value3: "00530", value4: "00530", name: "Stephenson National Bank Business (00530)" },
    { value: "16194", value2: "16194", value3: "16194", value4: "16194", name: "Stephenson National Bank and Trust (16194)" },
    { value: "00543", value2: "00543", value3: "00543", value4: "00543", name: "Sterling Bank & Trust, FSB (00543)" },
    { value: "05684", value2: "05684", value3: "05684", value4: "05684", name: "Sterling Bank & Trust, FSB - QB (05684)" },
    { value: "31956", value2: "31956", value3: "31956", value4: "31956", name: "Sterling Bank MO (31956)" },
    { value: "17871", value2: "17871", value3: "17871", value4: "17871", name: "Sterling Bank Wisconsin (17871)" },
    { value: "49791", value2: "49791", value3: "49791", value4: "49791", name: "Sterling Federal Bank (49791)" },
    { value: "09563", value2: "09563", value3: "09563", value4: "68088", name: "Sterling Federal Bank Bus (09563)" },
    { value: "68088", value2: "68088", value3: "68088", value4: "68088", name: "Sterling Federal Bank Bus DC (68088)" },
    { value: "68087", value2: "68087", value3: "68087", value4: "49791", name: "Sterling Federal Bank QBDC (68087)" },
    { value: "02656", value2: "02656", value3: "02656", value4: "02656", name: "Sterling State Bank (02656)" },
    { value: "58002", value2: "58002", value3: "58002", value4: "58002", name: "Stifel Bank - Commercial (58002)" },
    { value: "58017", value2: "58017", value3: "58017", value4: "58017", name: "Stifel Bank DC-QN-QB (58017)" },
    { value: "57999", value2: "57999", value3: "57999", value4: "57999", name: "Stifel Bank WC-QN-QB (57999)" },
    { value: "68303", value2: "68303", value3: "68303", value4: "68303", name: "Stifel Bank-Treasury Central (68303)" },
    { value: "17262", value2: "17262", value3: "17262", value4: "17262", name: "Stillman Bank (17262)" },
    { value: "57696", value2: "57696", value3: "57696", value4: "57696", name: "Stock Yards Bank & Trust New (57696)" },
    { value: "12722", value2: "12722", value3: "12722", value4: "12722", name: "Stockman Bank of Montana - BO (12722)" },
    { value: "18775", value2: "18775", value3: "18775", value4: "18775", name: "Stockman Bank of Montana WC QB (18775)" },
    { value: "51429", value2: "51429", value3: "51429", value4: "51429", name: "Stockmens Bank (51429)" },
    { value: "06398", value2: "06398", value3: "06398", value4: "06398", name: "Stockmens National Bank in Cotulla (06398)" },
    { value: "08426", value2: "08426", value3: "08426", value4: "08426", name: "Stone Bank - Direct (08426)" },
    { value: "08423", value2: "08423", value3: "08423", value4: "08423", name: "Stone Bank- Web Connect (08423)" },
    { value: "18855", value2: "18855", value3: "18855", value4: "18855", name: "Stoneham Bank - QBWC (18855)" },
    { value: "06782", value2: "06782", value3: "06782", value4: "06782", name: "StonehamBank (06782)" },
    { value: "01877", value2: "01877", value3: "01877", value4: "01877", name: "Strait View Credit Union (01877)" },
    { value: "52089", value2: "52089", value3: "52089", value4: "52089", name: "Straits Area Federal CU (52089)" },
    { value: "01141", value2: "01141", value3: "01141", value4: "01141", name: "Stride Bank, N.A. (01141)" },
    { value: "19242", value2: "19242", value3: "19242", value4: "19242", name: "Stryv Bank (19242)" },
    { value: "05189", value2: "05189", value3: "05189", value4: "05189", name: "Studio Bank (05189)" },
    { value: "09377", value2: "09377", value3: "09377", value4: "09377", name: "Studio Bank QB (09377)" },
    { value: "08770", value2: "08770", value3: "08770", value4: "08770", name: "Sturdy Savings Bank (08770)" },
    { value: "08039", value2: "08039", value3: "08039", value4: "08039", name: "Sturgis Bank & Trust Business DC (08039)" },
    { value: "08036", value2: "08036", value3: "08036", value4: "08036", name: "Sturgis Bank & Trust Business WC (08036)" },
    { value: "08075", value2: "08075", value3: "08075", value4: "08075", name: "Suffolk Credit Union (08075)" },
    { value: "08081", value2: "08081", value3: "08081", value4: "08075", name: "Suffolk Credit Union QBDC (08081)" },
    { value: "12027", value2: "12027", value3: "12027", value4: "12027", name: "Sugar River Savings Bank (12027)" },
    { value: "04495", value2: "04495", value3: "04495", value4: "04495", name: "Summit Bank - Oakland CA (04495)" },
    { value: "06437", value2: "06437", value3: "06437", value4: "04495", name: "Summit Bank - Oakland CA DC (06437)" },
    { value: "02214", value2: "02214", value3: "02214", value4: "02214", name: "Summit Bank - Oregon (02214)" },
    { value: "03221", value2: "03221", value3: "03221", value4: "03221", name: "Summit Bank - Oregon QB DC (03221)" },
    { value: "18145", value2: "18145", value3: "18145", value4: "18145", name: "Summit Community Bank WV (18145)" },
    { value: "18148", value2: "18148", value3: "18148", value4: "18145", name: "Summit Community Bank WV-QBWC (18148)" },
    { value: "19104", value2: "19104", value3: "19104", value4: "19104", name: "Summit Credit Union-Business (19104)" },
    { value: "18507", value2: "18507", value3: "18507", value4: "18507", name: "Summit Credit Union-WISC (18507)" },
    { value: "69375", value2: "69375", value3: "69375", value4: "69375", name: "Summit National Bank MT Bus (69375)" },
    { value: "69376", value2: "69376", value3: "69376", value4: "69375", name: "Summit National Bank MT Bus QBDC (69376)" },
    { value: "57093", value2: "57093", value3: "57093", value4: "57093", name: "Summit National Bank MT Per (57093)" },
    { value: "69374", value2: "69374", value3: "69374", value4: "57093", name: "Summit National Bank MT Per QBDC (69374)" },
    { value: "13056", value2: "13056", value3: "13056", value4: "13056", name: "Summit State Bank (13056)" },
    { value: "56706", value2: "56706", value3: "56706", value4: "56706", name: "Summit State Bank - DC (56706)" },
    { value: "64269", value2: "64269", value3: "64269", value4: "64269", name: "Summit State Bank BEB - DC (64269)" },
    { value: "64266", value2: "64266", value3: "64266", value4: "64266", name: "Summit State Bank BeB (64266)" },
    { value: "00846", value2: "00846", value3: "00846", value4: "00846", name: "Sun Community FCU (00846)" },
    { value: "06418", value2: "06418", value3: "06418", value4: "06418", name: "Sun Federal Credit Union (06418)" },
    { value: "07640", value2: "07640", value3: "07640", value4: "07640", name: "SunSouth bank - OLB (07640)" },
    { value: "12184", value2: "12184", value3: "12184", value4: "12184", name: "SunTrust Bank Online Treasury Mgr (12184)" },
    { value: "69498", value2: "69498", value3: "69498", value4: "69498", name: "SunTrust Bank Small Bus EWC (69498)" },
    { value: "06845", value2: "06845", value3: "06845", value4: "06845", name: "SunTrust Online Courier (06845)" },
    { value: "11660", value2: "11660", value3: "11660", value4: "11660", name: "SunWest Federal Credit Union (11660)" },
    { value: "15469", value2: "15469", value3: "15469", value4: "15469", name: "Suncoast Credit Union (15469)" },
    { value: "42063", value2: "42063", value3: "42063", value4: "42063", name: "Sundown State Bank (42063)" },
    { value: "67305", value2: "67305", value3: "67305", value4: "67305", name: "Sunflower Bank - Personal (67305)" },
    { value: "18466", value2: "18466", value3: "18466", value4: "18466", name: "Sunflower Bank - Treasury (18466)" },
    { value: "67649", value2: "67649", value3: "67649", value4: "67649", name: "Sunflower Community FCU (67649)" },
    { value: "46173", value2: "46173", value3: "46173", value4: "46173", name: "Sunlight FCU (46173)" },
    { value: "09260", value2: "09260", value3: "09260", value4: "09260", name: "Sunlight FCU Credit Card (09260)" },
    { value: "70496", value2: "70496", value3: "70496", value4: "70496", name: "Sunmark CU Bus CC (70496)" },
    { value: "32490", value2: "32490", value3: "32490", value4: "32490", name: "Sunmark Community Bank (32490)" },
    { value: "55488", value2: "55488", value3: "55488", value4: "55488", name: "Sunmark Credit Union - QB (55488)" },
    { value: "03681", value2: "03681", value3: "03681", value4: "03681", name: "Sunrise Bank (03681)" },
    { value: "12671", value2: "12671", value3: "12671", value4: "03681", name: "Sunrise Bank-DC (12671)" },
    { value: "65061", value2: "65061", value3: "65061", value4: "65061", name: "Sunrise Banks (65061)" },
    { value: "22809", value2: "22809", value3: "22809", value4: "22809", name: "Sunset Science Park  FCU (22809)" },
    { value: "00845", value2: "00845", value3: "00845", value4: "00845", name: "Sunshine Community Bank Card (00845)" },
    { value: "20340", value2: "20340", value3: "20340", value4: "20340", name: "Sunstate Bank (20340)" },
    { value: "18183", value2: "18183", value3: "18183", value4: "18183", name: "Sunwest Bank (18183)" },
    { value: "07841", value2: "07841", value3: "07841", value4: "07841", name: "Sunwest Bank - Commercial (07841)" },
    { value: "67675", value2: "67675", value3: "67675", value4: "67675", name: "Sunwest Bank DC (67675)" },
    { value: "55638", value2: "55638", value3: "55638", value4: "55638", name: "Superior Choice Credit Union (55638)" },
    { value: "62832", value2: "62832", value3: "62832", value4: "62832", name: "Superior Credit Union (62832)" },
    { value: "68294", value2: "68294", value3: "68294", value4: "68294", name: "Superior Credit Union - PA (68294)" },
    { value: "69390", value2: "69390", value3: "69390", value4: "69390", name: "Superior Credit Union CC (69390)" },
    { value: "68907", value2: "68907", value3: "68907", value4: "68907", name: "Superior Credit Union PA -DC (68907)" },
    { value: "11149", value2: "11149", value3: "11149", value4: "11149", name: "Superior National Bank (11149)" },
    { value: "18193", value2: "18193", value3: "18193", value4: "18193", name: "Surety Bank (18193)" },
    { value: "57258", value2: "57258", value3: "57258", value4: "57258", name: "Susquehanna Community Bank (57258)" },
    { value: "12659", value2: "12659", value3: "12659", value4: "12659", name: "Susquehanna Valley FCU CC (12659)" },
    { value: "68232", value2: "68232", value3: "68232", value4: "68232", name: "Susser Bank (68232)" },
    { value: "68233", value2: "68233", value3: "68233", value4: "68232", name: "Susser Bank QBDC (68233)" },
    { value: "05350", value2: "05350", value3: "05350", value4: "05350", name: "Sutton Bank - Business (05350)" },
    { value: "67781", value2: "67781", value3: "67781", value4: "67781", name: "Swedish-American State Bank (67781)" },
    { value: "04537", value2: "04537", value3: "04537", value4: "04537", name: "Synchrony Bank Credit Cards (04537)" },
    { value: "69450", value2: "69450", value3: "69450", value4: "69450", name: "Synchrony Bank Credit Cards EWC (69450)" },
    { value: "12077", value2: "12077", value3: "12077", value4: "12077", name: "Synergy Bank Houma, LA DC (12077)" },
    { value: "05220", value2: "05220", value3: "05220", value4: "05220", name: "Synergy Bank, Houma, LA (05220)" },
    { value: "70279", value2: "70279", value3: "70279", value4: "70279", name: "Synergy FCU (70279)" },
    { value: "70278", value2: "70278", value3: "70278", value4: "70278", name: "Synergy FCU - DC (70278)" },
    { value: "64602", value2: "64602", value3: "64602", value4: "64602", name: "Synergy FCU Credit Card (64602)" },
    { value: "12363", value2: "12363", value3: "12363", value4: "12363", name: "Synovus Card Services (12363)" },
    { value: "67681", value2: "67681", value3: "67681", value4: "67681", name: "Synovus Gateway (67681)" },
    { value: "67682", value2: "67682", value3: "67682", value4: "67681", name: "Synovus Gateway Direct Connect (67682)" },
    { value: "69694", value2: "69694", value3: "69694", value4: "69694", name: "Systematic Savings Bank Bus (69694)" },
    { value: "69693", value2: "69693", value3: "69693", value4: "69693", name: "Systematic Savings Bank Personal (69693)" },
    { value: "68025", value2: "68025", value3: "68025", value4: "68025", name: "T Bank (68025)" },
    { value: "68026", value2: "68026", value3: "68026", value4: "68026", name: "T Bank QBDC (68026)" },
    { value: "54285", value2: "54285", value3: "54285", value4: "54285", name: "T&I Credit Union (54285)" },
    { value: "53979", value2: "53979", value3: "53979", value4: "53979", name: "TAB Bank (53979)" },
    { value: "11200", value2: "11200", value3: "11200", value4: "11200", name: "TAPCO Credit Union (11200)" },
    { value: "69882", value2: "69882", value3: "69882", value4: "69882", name: "TASI Bank div Bank of Guam QB DC (69882)" },
    { value: "17577", value2: "17577", value3: "17577", value4: "17577", name: "TBA Credit Union (17577)" },
    { value: "69987", value2: "69987", value3: "69987", value4: "69987", name: "TBA Credit Union Bus (69987)" },
    { value: "58788", value2: "58788", value3: "58788", value4: "58788", name: "TBK Bank, SSB (58788)" },
    { value: "05135", value2: "05135", value3: "05135", value4: "05135", name: "TBK Bank, SSB - Personal Direct (05135)" },
    { value: "16333", value2: "16333", value3: "16333", value4: "16333", name: "TC Federal Bank BUS Online Banking (16333)" },
    { value: "68926", value2: "68926", value3: "68926", value4: "68926", name: "TCT Federal Credit Union (68926)" },
    { value: "55584", value2: "55584", value3: "55584", value4: "55584", name: "TD Bank - TD eTreasury (55584)" },
    { value: "56610", value2: "56610", value3: "56610", value4: "56610", name: "TD Bank Online Banking (56610)" },
    { value: "18742", value2: "18742", value3: "18742", value4: "18742", name: "TD Bank US Credit Card (18742)" },
    { value: "12103", value2: "12103", value3: "12103", value4: "56610", name: "TD Bank Web Connect (12103)" },
    { value: "63549", value2: "63549", value3: "63549", value4: "63549", name: "TDECU (63549)" },
    { value: "09088", value2: "09088", value3: "09088", value4: "09088", name: "TEG FCU (09088)" },
    { value: "06926", value2: "06926", value3: "06926", value4: "06926", name: "TEST BID 8_8_2018 (06926)" },
    { value: "01647", value2: "01647", value3: "01647", value4: "01647", name: "TFNB Your Bank for Life (01647)" },
    { value: "69391", value2: "69391", value3: "69391", value4: "69391", name: "TIB Bank CC (69391)" },
    { value: "12217", value2: "12217", value3: "12217", value4: "12217", name: "TLC Community Credit Union (12217)" },
    { value: "70178", value2: "70178", value3: "70178", value4: "70178", name: "TLCU Financial (70178)" },
    { value: "02673", value2: "02673", value3: "02673", value4: "02673", name: "TN Bank (02673)" },
    { value: "11957", value2: "11957", value3: "11957", value4: "11957", name: "TN Bank Business (11957)" },
    { value: "08741", value2: "08741", value3: "08741", value4: "08741", name: "TN Bank Business Direct (08741)" },
    { value: "68367", value2: "68367", value3: "68367", value4: "68367", name: "TNB Direct Connect (68367)" },
    { value: "68366", value2: "68366", value3: "68366", value4: "68366", name: "TNB Web Connect (68366)" },
    { value: "70223", value2: "70223", value3: "70223", value4: "70223", name: "TPB Taylorsville (70223)" },
    { value: "70224", value2: "70224", value3: "70224", value4: "70224", name: "TPB Taylorsville QBDC (70224)" },
    { value: "70490", value2: "70490", value3: "70490", value4: "70490", name: "TRCBank (70490)" },
    { value: "52905", value2: "52905", value3: "52905", value4: "52905", name: "TRUE Community Credit Union (52905)" },
    { value: "02903", value2: "02903", value3: "02903", value4: "02903", name: "TSB Bank (02903)" },
    { value: "18724", value2: "18724", value3: "18724", value4: "18724", name: "TVFCU Batavia QB (18724)" },
    { value: "67709", value2: "67709", value3: "67709", value4: "67709", name: "TXN BanK-TX-Bus (67709)" },
    { value: "09609", value2: "09609", value3: "09609", value4: "09609", name: "TXN Bank NA-Retail (09609)" },
    { value: "57267", value2: "57267", value3: "57267", value4: "57267", name: "Tampa Bay Federal Credit Union (57267)" },
    { value: "09356", value2: "09356", value3: "09356", value4: "09356", name: "Tandem Bank (09356)" },
    { value: "09947", value2: "09947", value3: "09947", value4: "09947", name: "Tandem Bank DC (09947)" },
    { value: "67808", value2: "67808", value3: "67808", value4: "67808", name: "Tandem Bank-TM (67808)" },
    { value: "67809", value2: "67809", value3: "67809", value4: "67808", name: "Tandem Bank-TM-DC (67809)" },
    { value: "02894", value2: "02894", value3: "02894", value4: "02894", name: "Tandem FCU Credit Card (02894)" },
    { value: "53190", value2: "53190", value3: "53190", value4: "53190", name: "Taunton Federal CU (53190)" },
    { value: "02036", value2: "02036", value3: "02036", value4: "02036", name: "Taylor Bank (02036)" },
    { value: "59457", value2: "59457", value3: "59457", value4: "59457", name: "Taylor County Bank (59457)" },
    { value: "01868", value2: "01868", value3: "01868", value4: "01868", name: "Teachers FCU Credit Card (01868)" },
    { value: "16948", value2: "16948", value3: "16948", value4: "16948", name: "Teachers FCU Long Island NY QB (16948)" },
    { value: "59187", value2: "59187", value3: "59187", value4: "59187", name: "Team One Credit Union (59187)" },
    { value: "00242", value2: "00242", value3: "00242", value4: "00242", name: "Teamsters C.U. Credit Card (00242)" },
    { value: "12515", value2: "12515", value3: "12515", value4: "12515", name: "Technicolor Federal CU (12515)" },
    { value: "12506", value2: "12506", value3: "12506", value4: "12506", name: "Technicolor Federal CU WC QB (12506)" },
    { value: "06059", value2: "06059", value3: "06059", value4: "06059", name: "Technology CU Commercial (06059)" },
    { value: "15079", value2: "15079", value3: "15079", value4: "15079", name: "Technology Credit Union - DC (15079)" },
    { value: "11362", value2: "11362", value3: "11362", value4: "11362", name: "Technology Credit Union - WC (11362)" },
    { value: "51240", value2: "51240", value3: "51240", value4: "51240", name: "Tejas Bank (51240)" },
    { value: "53868", value2: "53868", value3: "53868", value4: "53868", name: "Telco Community Credit Union (53868)" },
    { value: "58218", value2: "58218", value3: "58218", value4: "58218", name: "Telcomm CU (58218)" },
    { value: "19350", value2: "19350", value3: "19350", value4: "19350", name: "Telesis Community CU (19350)" },
    { value: "65496", value2: "65496", value3: "65496", value4: "65496", name: "Telhio CU (65496)" },
    { value: "13239", value2: "13239", value3: "13239", value4: "13239", name: "Tennessee State Bank (13239)" },
    { value: "10051", value2: "10051", value3: "10051", value4: "10051", name: "Tennessee Valley FCU (10051)" },
    { value: "11854", value2: "11854", value3: "11854", value4: "11854", name: "Tensas State Bank (11854)" },
    { value: "68701", value2: "68701", value3: "68701", value4: "68701", name: "Terrabank Biz (68701)" },
    { value: "68702", value2: "68702", value3: "68702", value4: "68702", name: "Terrabank Biz QBDC (68702)" },
    { value: "36285", value2: "36285", value3: "36285", value4: "36285", name: "Terrabank Per (36285)" },
    { value: "68700", value2: "68700", value3: "68700", value4: "68700", name: "Terrabank Per QBDC (68700)" },
    { value: "18057", value2: "18057", value3: "18057", value4: "18057", name: "Teutopolis State Bank (18057)" },
    { value: "51579", value2: "51579", value3: "51579", value4: "51579", name: "Texana Bank (51579)" },
    { value: "08784", value2: "08784", value3: "08784", value4: "08784", name: "Texans Credit Union (08784)" },
    { value: "00335", value2: "00335", value3: "00335", value4: "00335", name: "Texas Advantage Comm Bank BOB (00335)" },
    { value: "67817", value2: "67817", value3: "67817", value4: "67817", name: "Texas Assoc of Professionals FUC (67817)" },
    { value: "14190", value2: "14190", value3: "14190", value4: "14190", name: "Texas Bank (14190)" },
    { value: "18178", value2: "18178", value3: "18178", value4: "14190", name: "Texas Bank - QB DC (18178)" },
    { value: "10306", value2: "10306", value3: "10306", value4: "10306", name: "Texas Bank and Trust (10306)" },
    { value: "67812", value2: "67812", value3: "67812", value4: "67506", name: "Texas Bank and Trust -QB (67812)" },
    { value: "67506", value2: "67506", value3: "67506", value4: "67506", name: "Texas Bank and Trust Treasury (67506)" },
    { value: "18846", value2: "18846", value3: "18846", value4: "18846", name: "Texas Bank-Brownwood (18846)" },
    { value: "58083", value2: "58083", value3: "58083", value4: "58083", name: "Texas Bay Area CU Credit Card (58083)" },
    { value: "63327", value2: "63327", value3: "63327", value4: "63327", name: "Texas Capital Bank (63327)" },
    { value: "67720", value2: "67720", value3: "67720", value4: "63327", name: "Texas Capital Bank QBDC (67720)" },
    { value: "62238", value2: "62238", value3: "62238", value4: "62238", name: "Texas Champion Bank Business (62238)" },
    { value: "00969", value2: "00969", value3: "00969", value4: "00969", name: "Texas Community Bank Laredo, TX (00969)" },
    { value: "00806", value2: "00806", value3: "00806", value4: "00806", name: "Texas Community FCU (00806)" },
    { value: "69549", value2: "69549", value3: "69549", value4: "69549", name: "Texas DPS CU CC (69549)" },
    { value: "04221", value2: "04221", value3: "04221", value4: "04221", name: "Texas Exchange Bank (04221)" },
    { value: "68867", value2: "68867", value3: "68867", value4: "68867", name: "Texas Farm Credit Services (68867)" },
    { value: "03143", value2: "03143", value3: "03143", value4: "03143", name: "Texas Financial Bank (03143)" },
    { value: "13149", value2: "13149", value3: "13149", value4: "13149", name: "Texas First Bank (13149)" },
    { value: "14301", value2: "14301", value3: "14301", value4: "14301", name: "Texas Gulf Bank (14301)" },
    { value: "68555", value2: "68555", value3: "68555", value4: "14301", name: "Texas Gulf Bank - DC (68555)" },
    { value: "15909", value2: "15909", value3: "15909", value4: "15909", name: "Texas Health Credit Union (15909)" },
    { value: "15489", value2: "15489", value3: "15489", value4: "15489", name: "Texas Heritage Bank (15489)" },
    { value: "67316", value2: "67316", value3: "67316", value4: "67316", name: "Texas Heritage Bank- TX (67316)" },
    { value: "67452", value2: "67452", value3: "67452", value4: "67452", name: "Texas Heritage National Bank-QBDC (67452)" },
    { value: "03020", value2: "03020", value3: "03020", value4: "03020", name: "Texas National Bank - East TX (03020)" },
    { value: "06491", value2: "06491", value3: "06491", value4: "06491", name: "Texas National Bank- New (06491)" },
    { value: "12692", value2: "12692", value3: "12692", value4: "12692", name: "Texas National Bank-East TX Bus (12692)" },
    { value: "04631", value2: "04631", value3: "04631", value4: "04631", name: "Texas Partners Bank (04631)" },
    { value: "04634", value2: "04634", value3: "04634", value4: "04631", name: "Texas Partners Bank-Direct (04634)" },
    { value: "67911", value2: "67911", value3: "67911", value4: "67911", name: "Texas Regional Bank Bus Direct (67911)" },
    { value: "57636", value2: "57636", value3: "57636", value4: "57636", name: "Texas Regional Bank Harlingen TX (57636)" },
    { value: "12347", value2: "12347", value3: "12347", value4: "29313", name: "Texas Republic Bank - QBDC (12347)" },
    { value: "29313", value2: "29313", value3: "29313", value4: "29313", name: "Texas Republic Bank, N.A. (29313)" },
    { value: "67514", value2: "67514", value3: "67514", value4: "67514", name: "Texas Security Bank (67514)" },
    { value: "67515", value2: "67515", value3: "67515", value4: "67514", name: "Texas Security Bank - DC (67515)" },
    { value: "09623", value2: "09623", value3: "09623", value4: "09623", name: "Texas State Bank San Angelo WC (09623)" },
    { value: "04919", value2: "04919", value3: "04919", value4: "04919", name: "Texas State Bank of San Angelo (04919)" },
    { value: "02378", value2: "02378", value3: "02378", value4: "02378", name: "Texas Tech C.U. Credit Card (02378)" },
    { value: "50544", value2: "50544", value3: "50544", value4: "50544", name: "Texas Tech Federal Credit Union (50544)" },
    { value: "69556", value2: "69556", value3: "69556", value4: "69556", name: "Texas Traditions Bank (69556)" },
    { value: "69633", value2: "69633", value3: "69633", value4: "69633", name: "Texas Traditions Bank - DC (69633)" },
    { value: "68995", value2: "68995", value3: "68995", value4: "68995", name: "TexasBank Brownwood QBDC (68995)" },
    { value: "17710", value2: "17710", value3: "17710", value4: "17710", name: "Texell Credit Union (17710)" },
    { value: "04740", value2: "04740", value3: "04740", value4: "04740", name: "The Andover Bank (04740)" },
    { value: "02969", value2: "02969", value3: "02969", value4: "02969", name: "The Apple Creek Bank -Business (02969)" },
    { value: "07985", value2: "07985", value3: "07985", value4: "07985", name: "The Bank (07985)" },
    { value: "69725", value2: "69725", value3: "69725", value4: "69725", name: "The Bank - DC (69725)" },
    { value: "01065", value2: "01065", value3: "01065", value4: "01065", name: "The Bank and Trust (01065)" },
    { value: "14307", value2: "14307", value3: "14307", value4: "14307", name: "The Bank of Bennington (14307)" },
    { value: "00655", value2: "00655", value3: "00655", value4: "00655", name: "The Bank of Commerce- QB (00655)" },
    { value: "10934", value2: "10934", value3: "10934", value4: "10934", name: "The Bank of Delmarva-DC (10934)" },
    { value: "10240", value2: "10240", value3: "10240", value4: "10240", name: "The Bank of Elk River (10240)" },
    { value: "12464", value2: "12464", value3: "12464", value4: "12464", name: "The Bank of Elk River DC (12464)" },
    { value: "12633", value2: "12633", value3: "12633", value4: "12633", name: "The Bank of Fayette County (12633)" },
    { value: "09827", value2: "09827", value3: "09827", value4: "09827", name: "The Bank of Fayette County BO WC (09827)" },
    { value: "15718", value2: "15718", value3: "15718", value4: "15718", name: "The Bank of Greene County QB-New (15718)" },
    { value: "15715", value2: "15715", value3: "15715", value4: "15715", name: "The Bank of Greene County-New (15715)" },
    { value: "00711", value2: "00711", value3: "00711", value4: "00711", name: "The Bank of Jackson (00711)" },
    { value: "30696", value2: "30696", value3: "30696", value4: "30696", name: "The Bank of Kaukauna (30696)" },
    { value: "29505", value2: "29505", value3: "29505", value4: "29505", name: "The Bank of Kremlin (29505)" },
    { value: "04936", value2: "04936", value3: "04936", value4: "04936", name: "The Bank of LaFayette (04936)" },
    { value: "69829", value2: "69829", value3: "69829", value4: "04936", name: "The Bank of Lafayette - DC (69829)" },
    { value: "03992", value2: "03992", value3: "03992", value4: "03992", name: "The Bank of Landisburg-PA (03992)" },
    { value: "11544", value2: "11544", value3: "11544", value4: "11544", name: "The Bank of Marion (11544)" },
    { value: "31644", value2: "31644", value3: "31644", value4: "31644", name: "The Bank of Milan (31644)" },
    { value: "01053", value2: "01053", value3: "01053", value4: "01053", name: "The Bank of Missouri (01053)" },
    { value: "67747", value2: "67747", value3: "67747", value4: "67747", name: "The Bank of Monroe WV DC (67747)" },
    { value: "07931", value2: "07931", value3: "07931", value4: "07931", name: "The Bank of New Glarus (07931)" },
    { value: "56574", value2: "56574", value3: "56574", value4: "56574", name: "The Bank of Northwest Kansas (56574)" },
    { value: "68507", value2: "68507", value3: "68507", value4: "68507", name: "The Bank of Northwest Kansas-DC (68507)" },
    { value: "14106", value2: "14106", value3: "14106", value4: "14106", name: "The Bank of Princeton (14106)" },
    { value: "18438", value2: "18438", value3: "18438", value4: "18438", name: "The Bank of Princeton QB DC BO (18438)" },
    { value: "05048", value2: "05048", value3: "05048", value4: "05048", name: "The Bank of Princeton QB DC RO (05048)" },
    { value: "69758", value2: "69758", value3: "69758", value4: "69758", name: "The Bank of SC Business DC (69758)" },
    { value: "69785", value2: "69785", value3: "69785", value4: "69785", name: "The Bank of SC Personal DC (69785)" },
    { value: "08431", value2: "08431", value3: "08431", value4: "08431", name: "The Bank of San Francisco (08431)" },
    { value: "07097", value2: "07097", value3: "07097", value4: "07097", name: "The Bank of San Francisco DC (07097)" },
    { value: "02946", value2: "02946", value3: "02946", value4: "02946", name: "The Bank of South Carolina (02946)" },
    { value: "69784", value2: "69784", value3: "69784", value4: "69784", name: "The Bank of South Carolina Bus (69784)" },
    { value: "01056", value2: "01056", value3: "01056", value4: "01056", name: "The Bank of Southside Virginia (01056)" },
    { value: "04119", value2: "04119", value3: "04119", value4: "04119", name: "The Bank of Tampa - Direct (04119)" },
    { value: "63000", value2: "63000", value3: "63000", value4: "63000", name: "The Bank of Tescott Business (63000)" },
    { value: "30816", value2: "30816", value3: "30816", value4: "30816", name: "The Bank of Tioga (30816)" },
    { value: "05214", value2: "05214", value3: "05214", value4: "05214", name: "The Bank of Walker County (05214)" },
    { value: "34071", value2: "34071", value3: "34071", value4: "34071", name: "The Bath State Bank (34071)" },
    { value: "05774", value2: "05774", value3: "05774", value4: "05774", name: "The Berkshire Bank (05774)" },
    { value: "69330", value2: "69330", value3: "69330", value4: "69330", name: "The Blue Grass Valley Bank (69330)" },
    { value: "13569", value2: "13569", value3: "13569", value4: "13569", name: "The Callaway Bank (13569)" },
    { value: "01562", value2: "01562", value3: "01562", value4: "01562", name: "The Callaway Bank Credit Card (01562)" },
    { value: "69587", value2: "69587", value3: "69587", value4: "13569", name: "The Callaway Bank QBDC (69587)" },
    { value: "34416", value2: "34416", value3: "34416", value4: "34416", name: "The Campbell & Fetter Bank (34416)" },
    { value: "16174", value2: "16174", value3: "16174", value4: "16174", name: "The Card Services Center (16174)" },
    { value: "12071", value2: "12071", value3: "12071", value4: "12071", name: "The Card Services Center P-Card (12071)" },
    { value: "17508", value2: "17508", value3: "17508", value4: "17508", name: "The Cecilian Bank 2014 (17508)" },
    { value: "67130", value2: "67130", value3: "67130", value4: "67130", name: "The Citizens 1st National Bank (67130)" },
    { value: "67217", value2: "67217", value3: "67217", value4: "67217", name: "The Citizens Bank Enterprise (67217)" },
    { value: "67719", value2: "67719", value3: "67719", value4: "67719", name: "The Citizens Bank Enterprise Con (67719)" },
    { value: "27384", value2: "27384", value3: "27384", value4: "27384", name: "The Citizens Bank Of Cochran (27384)" },
    { value: "26478", value2: "26478", value3: "26478", value4: "26478", name: "The Citizens Bank SC (26478)" },
    { value: "19992", value2: "19992", value3: "19992", value4: "19992", name: "The Citizens Bank of Clovis, NM (19992)" },
    { value: "53274", value2: "53274", value3: "53274", value4: "53274", name: "The Citizens Bank of Edina (53274)" },
    { value: "70475", value2: "70475", value3: "70475", value4: "70475", name: "The Citizens Bank of Edina-DC (70475)" },
    { value: "67785", value2: "67785", value3: "67785", value4: "03325", name: "The Citizens Bank of Phil, MS QB (67785)" },
    { value: "03325", value2: "03325", value3: "03325", value4: "03325", name: "The Citizens Bank of Philadelphia (03325)" },
    { value: "50088", value2: "50088", value3: "50088", value4: "50088", name: "The Citizens Bank-Hickman, KY (50088)" },
    { value: "70444", value2: "70444", value3: "70444", value4: "70444", name: "The Citizens Nat'l Bank Bus CC (70444)" },
    { value: "00921", value2: "00921", value3: "00921", value4: "00921", name: "The Citizens National Bank - KS (00921)" },
    { value: "09932", value2: "09932", value3: "09932", value4: "09932", name: "The Citizens National Bank-KS Bus (09932)" },
    { value: "62619", value2: "62619", value3: "62619", value4: "62619", name: "The Citizens State Bank WCQB (62619)" },
    { value: "36165", value2: "36165", value3: "36165", value4: "36165", name: "The City National Bank of Colora (36165)" },
    { value: "70079", value2: "70079", value3: "70079", value4: "70079", name: "The City Natl Bank of Co QBDC (70079)" },
    { value: "30747", value2: "30747", value3: "30747", value4: "30747", name: "The Claxton Bank (30747)" },
    { value: "70041", value2: "70041", value3: "70041", value4: "30747", name: "The Claxton Bank QBDC (70041)" },
    { value: "32217", value2: "32217", value3: "32217", value4: "32217", name: "The Colorado Bank and Trust Comp (32217)" },
    { value: "13876", value2: "13876", value3: "13876", value4: "13876", name: "The Columbia Bank WebConnect QBO (13876)" },
    { value: "03177", value2: "03177", value3: "03177", value4: "03177", name: "The Commerce Bank of Oregon (03177)" },
    { value: "02483", value2: "02483", value3: "02483", value4: "02483", name: "The Commerce Bank of Washington (02483)" },
    { value: "23700", value2: "23700", value3: "23700", value4: "23700", name: "The Commercial & Savings Bank OH (23700)" },
    { value: "06884", value2: "06884", value3: "06884", value4: "06884", name: "The Commercial Bank (06884)" },
    { value: "06887", value2: "06887", value3: "06887", value4: "06887", name: "The Commercial Bank - DC (06887)" },
    { value: "69423", value2: "69423", value3: "69423", value4: "69423", name: "The Commercial Bank SC DC (69423)" },
    { value: "00277", value2: "00277", value3: "00277", value4: "00277", name: "The Commercial Bank of Grayson (00277)" },
    { value: "57138", value2: "57138", value3: "57138", value4: "57138", name: "The Commercial Bank, Crawford, GA (57138)" },
    { value: "13004", value2: "13004", value3: "13004", value4: "13004", name: "The Commercial Bank-Mason (13004)" },
    { value: "50634", value2: "50634", value3: "50634", value4: "50634", name: "The Community Bank - KS (50634)" },
    { value: "54471", value2: "54471", value3: "54471", value4: "54471", name: "The Community Bank of Bridgeport (54471)" },
    { value: "19545", value2: "19545", value3: "19545", value4: "19545", name: "The Community Bank, Zanesville (19545)" },
    { value: "02292", value2: "02292", value3: "02292", value4: "02292", name: "The Conway National Bank (02292)" },
    { value: "08471", value2: "08471", value3: "08471", value4: "08471", name: "The Cooperative Bank - New (08471)" },
    { value: "08474", value2: "08474", value3: "08474", value4: "08471", name: "The Cooperative Bank - QBWC (08474)" },
    { value: "11789", value2: "11789", value3: "11789", value4: "11789", name: "The Cooperative Bank of Cape Cod (11789)" },
    { value: "00536", value2: "00536", value3: "00536", value4: "00536", name: "The Cornerstone Bank (00536)" },
    { value: "17565", value2: "17565", value3: "17565", value4: "17565", name: "The Cottonport Bank (17565)" },
    { value: "53646", value2: "53646", value3: "53646", value4: "53646", name: "The Cowboy Bank (53646)" },
    { value: "20271", value2: "20271", value3: "20271", value4: "20271", name: "The Credit Union For All (20271)" },
    { value: "33972", value2: "33972", value3: "33972", value4: "33972", name: "The Dart Bank (33972)" },
    { value: "65220", value2: "65220", value3: "65220", value4: "65220", name: "The Dart Bank CC (65220)" },
    { value: "07520", value2: "07520", value3: "07520", value4: "33972", name: "The Dart Bank DC (07520)" },
    { value: "68976", value2: "68976", value3: "68976", value4: "68976", name: "The Dart Bank-TM (68976)" },
    { value: "68977", value2: "68977", value3: "68977", value4: "68976", name: "The Dart Bank-TM-DC (68977)" },
    { value: "01502", value2: "01502", value3: "01502", value4: "01502", name: "The Dime Bank (01502)" },
    { value: "09743", value2: "09743", value3: "09743", value4: "09743", name: "The Dolores State Bank (09743)" },
    { value: "09746", value2: "09746", value3: "09746", value4: "09743", name: "The Dolores State Bank-DC (09746)" },
    { value: "00384", value2: "00384", value3: "00384", value4: "00384", name: "The Eastern Colorado Bank (00384)" },
    { value: "57639", value2: "57639", value3: "57639", value4: "57639", name: "The Eastern Colorado Bank Biz (57639)" },
    { value: "19074", value2: "19074", value3: "19074", value4: "19074", name: "The Edgartown National Bank (19074)" },
    { value: "08927", value2: "08927", value3: "08927", value4: "08927", name: "The Equitable Bank SSB Bus (08927)" },
    { value: "68105", value2: "68105", value3: "68105", value4: "68105", name: "The Exchange Bank (68105)" },
    { value: "68106", value2: "68106", value3: "68106", value4: "68105", name: "The Exchange Bank QBDC (68106)" },
    { value: "16614", value2: "16614", value3: "16614", value4: "16614", name: "The Exchange Bank of Alabama (16614)" },
    { value: "00383", value2: "00383", value3: "00383", value4: "00383", name: "The Fahey Banking Company (00383)" },
    { value: "07679", value2: "07679", value3: "07679", value4: "07679", name: "The Fahey Banking Company -DC (07679)" },
    { value: "68612", value2: "68612", value3: "68612", value4: "68612", name: "The Fairfield National Bank (68612)" },
    { value: "68613", value2: "68613", value3: "68613", value4: "68613", name: "The Fairfield National Bank QBDC (68613)" },
    { value: "46854", value2: "46854", value3: "46854", value4: "46854", name: "The Fairmount State Bank (46854)" },
    { value: "69406", value2: "69406", value3: "69406", value4: "69406", name: "The Family Credit Union (69406)" },
    { value: "06254", value2: "06254", value3: "06254", value4: "06254", name: "The Farmers Bank (06254)" },
    { value: "00722", value2: "00722", value3: "00722", value4: "00722", name: "The Farmers Bank - Business (00722)" },
    { value: "56571", value2: "56571", value3: "56571", value4: "56571", name: "The Farmers Bank - Frankfort IN (56571)" },
    { value: "15897", value2: "15897", value3: "15897", value4: "15897", name: "The Farmers Bank - Portland (15897)" },
    { value: "04493", value2: "04493", value3: "04493", value4: "04493", name: "The Farmers Bank Credit Card (04493)" },
    { value: "32289", value2: "32289", value3: "32289", value4: "32289", name: "The Farmers Bank Greensboro (32289)" },
    { value: "06257", value2: "06257", value3: "06257", value4: "06254", name: "The Farmers Bank-DC (06257)" },
    { value: "11600", value2: "11600", value3: "11600", value4: "11600", name: "The Farmers State B&T Co (11600)" },
    { value: "69365", value2: "69365", value3: "69365", value4: "11600", name: "The Farmers State B&T Co QBDC (69365)" },
    { value: "20124", value2: "20124", value3: "20124", value4: "20124", name: "The Farmers State Bank (20124)" },
    { value: "53013", value2: "53013", value3: "53013", value4: "53013", name: "The Farmers State Bank - KS (53013)" },
    { value: "69442", value2: "69442", value3: "69442", value4: "69442", name: "The Farmers State Bank Oklahoma (69442)" },
    { value: "69024", value2: "69024", value3: "69024", value4: "69024", name: "The Finest FCU (69024)" },
    { value: "01542", value2: "01542", value3: "01542", value4: "01542", name: "The First Bank and Trust Co VA (01542)" },
    { value: "04733", value2: "04733", value3: "04733", value4: "04733", name: "The First Bank of Greenwich (04733)" },
    { value: "42861", value2: "42861", value3: "42861", value4: "42861", name: "The First Bank of Okarche (42861)" },
    { value: "64170", value2: "64170", value3: "64170", value4: "35727", name: "The First National Bank Odon_DC (64170)" },
    { value: "66263", value2: "66263", value3: "66263", value4: "66263", name: "The First National Bank and Trust (66263)" },
    { value: "33450", value2: "33450", value3: "33450", value4: "33450", name: "The First National Bank at St. J (33450)" },
    { value: "30165", value2: "30165", value3: "30165", value4: "30165", name: "The First National Bank of Blanc (30165)" },
    { value: "42432", value2: "42432", value3: "42432", value4: "42432", name: "The First National Bank of Evant (42432)" },
    { value: "09292", value2: "09292", value3: "09292", value4: "09292", name: "The First National Bank of Hugo QB (09292)" },
    { value: "12731", value2: "12731", value3: "12731", value4: "12731", name: "The First National Bank of Lindsay (12731)" },
    { value: "35727", value2: "35727", value3: "35727", value4: "35727", name: "The First National Bank of Odon (35727)" },
    { value: "35973", value2: "35973", value3: "35973", value4: "35973", name: "The First State Bank of Red Wing (35973)" },
    { value: "57789", value2: "57789", value3: "57789", value4: "57789", name: "The First State Bank of Shelby (57789)" },
    { value: "04289", value2: "04289", value3: "04289", value4: "04289", name: "The First State BankColumbusTX (04289)" },
    { value: "69729", value2: "69729", value3: "69729", value4: "69729", name: "The First eBank Solution (69729)" },
    { value: "69730", value2: "69730", value3: "69730", value4: "69729", name: "The First eBank Solution QBDC (69730)" },
    { value: "69488", value2: "69488", value3: "69488", value4: "69488", name: "The First, A National Assoc EWC (69488)" },
    { value: "04836", value2: "04836", value3: "04836", value4: "04836", name: "The First, A National Association (04836)" },
    { value: "29652", value2: "29652", value3: "29652", value4: "29652", name: "The Fisher National Bank (29652)" },
    { value: "69986", value2: "69986", value3: "69986", value4: "29652", name: "The Fisher National Bank QBDC (69986)" },
    { value: "69352", value2: "69352", value3: "69352", value4: "69352", name: "The Florist FCU (69352)" },
    { value: "51330", value2: "51330", value3: "51330", value4: "51330", name: "The Four County Bank (51330)" },
    { value: "09785", value2: "09785", value3: "09785", value4: "09785", name: "The Frederick Community Bank (09785)" },
    { value: "03732", value2: "03732", value3: "03732", value4: "03732", name: "The Friendship State Bank (03732)" },
    { value: "04203", value2: "04203", value3: "04203", value4: "04203", name: "The Geo. D. Warthen Bank (04203)" },
    { value: "03893", value2: "03893", value3: "03893", value4: "03893", name: "The Geo. D. Warthen Bank - DC (03893)" },
    { value: "27000", value2: "27000", value3: "27000", value4: "27000", name: "The Gerber State Bank (27000)" },
    { value: "67176", value2: "67176", value3: "67176", value4: "67176", name: "The Grant County Bank (67176)" },
    { value: "67177", value2: "67177", value3: "67177", value4: "67177", name: "The Grant County Bank Bus (67177)" },
    { value: "15792", value2: "15792", value3: "15792", value4: "15792", name: "The Gunnison Bank & Trust Comp (15792)" },
    { value: "67665", value2: "67665", value3: "67665", value4: "67665", name: "The Harbor Bk of MD - Biz Net (67665)" },
    { value: "56772", value2: "56772", value3: "56772", value4: "56772", name: "The Harvard State Bank (56772)" },
    { value: "31380", value2: "31380", value3: "31380", value4: "31380", name: "The Home Loan Savings Bank (31380)" },
    { value: "70239", value2: "70239", value3: "70239", value4: "31380", name: "The Home Loan Savings Bank-DC (70239)" },
    { value: "23109", value2: "23109", value3: "23109", value4: "23109", name: "The Honesdale National Bank (23109)" },
    { value: "01282", value2: "01282", value3: "01282", value4: "01282", name: "The Jefferson Bank - Personal Banking (01282)" },
    { value: "67761", value2: "67761", value3: "67761", value4: "67761", name: "The Jefferson Bank Business (67761)" },
    { value: "11641", value2: "11641", value3: "11641", value4: "11641", name: "The Juniata Valley Bank (11641)" },
    { value: "08909", value2: "08909", value3: "08909", value4: "08909", name: "The Juniata Valley Bk WCQB (08909)" },
    { value: "09734", value2: "09734", value3: "09734", value4: "09734", name: "The Kearny County Bank (09734)" },
    { value: "18720", value2: "18720", value3: "18720", value4: "18720", name: "The MINT National Bank (18720)" },
    { value: "69432", value2: "69432", value3: "69432", value4: "69432", name: "The MINT National Bank - Bus WC (69432)" },
    { value: "69446", value2: "69446", value3: "69446", value4: "69446", name: "The MINT National Bank DC (69446)" },
    { value: "04722", value2: "04722", value3: "04722", value4: "04722", name: "The Maries County Bank (04722)" },
    { value: "02397", value2: "02397", value3: "02397", value4: "02397", name: "The Mechanics Bank - Bus.Banking (02397)" },
    { value: "08732", value2: "08732", value3: "08732", value4: "08732", name: "The Milford Bank - Business (08732)" },
    { value: "35352", value2: "35352", value3: "35352", value4: "35352", name: "The Missouri Bank (35352)" },
    { value: "07412", value2: "07412", value3: "07412", value4: "07412", name: "The Missouri Bank Bus (07412)" },
    { value: "08821", value2: "08821", value3: "08821", value4: "08821", name: "The Murray Bank (08821)" },
    { value: "18678", value2: "18678", value3: "18678", value4: "18678", name: "The Napoleon State Bank (18678)" },
    { value: "36645", value2: "36645", value3: "36645", value4: "36645", name: "The National Bank of Malvern (36645)" },
    { value: "02207", value2: "02207", value3: "02207", value4: "36645", name: "The National Bank of Malvern-DC (02207)" },
    { value: "00603", value2: "00603", value3: "00603", value4: "00603", name: "The Neffs National Bank (00603)" },
    { value: "55470", value2: "55470", value3: "55470", value4: "55470", name: "The People's Savings Bank (55470)" },
    { value: "05099", value2: "05099", value3: "05099", value4: "05099", name: "The Peoples Bank - Brownstown (05099)" },
    { value: "10270", value2: "10270", value3: "10270", value4: "10270", name: "The Peoples Bank - Eatonton GA (10270)" },
    { value: "51456", value2: "51456", value3: "51456", value4: "51456", name: "The Peoples Bank - Iva (51456)" },
    { value: "03053", value2: "03053", value3: "03053", value4: "03053", name: "The Peoples Bank Gambier (03053)" },
    { value: "09106", value2: "09106", value3: "09106", value4: "09106", name: "The Peoples Bank KS (09106)" },
    { value: "69879", value2: "69879", value3: "69879", value4: "69879", name: "The Peoples Bank KS QBDC (69879)" },
    { value: "35421", value2: "35421", value3: "35421", value4: "35421", name: "The Peoples Bank Marion, KY (35421)" },
    { value: "68887", value2: "68887", value3: "68887", value4: "68887", name: "The Peoples Bank of Georgia - DC (68887)" },
    { value: "68795", value2: "68795", value3: "68795", value4: "68795", name: "The Peoples Bank of Georgia - WC (68795)" },
    { value: "16366", value2: "16366", value3: "16366", value4: "16366", name: "The Peoples Bank of Ripley MS (16366)" },
    { value: "12785", value2: "12785", value3: "12785", value4: "12785", name: "The Peoples Bank of TN (12785)" },
    { value: "12788", value2: "12788", value3: "12788", value4: "12785", name: "The Peoples Bank of TN WC (12788)" },
    { value: "19569", value2: "19569", value3: "19569", value4: "19569", name: "The Peoples Bank, Biloxi, MS (19569)" },
    { value: "09432", value2: "09432", value3: "09432", value4: "09432", name: "The Peoples Bank, Chestertown, MD (09432)" },
    { value: "68433", value2: "68433", value3: "68433", value4: "68433", name: "The Peoples Comm Bank WI Biz (68433)" },
    { value: "68434", value2: "68434", value3: "68434", value4: "68434", name: "The Peoples Comm Bank WI Biz DC (68434)" },
    { value: "68431", value2: "68431", value3: "68431", value4: "68431", name: "The Peoples Comm Bank WI Per (68431)" },
    { value: "68432", value2: "68432", value3: "68432", value4: "68432", name: "The Peoples Comm Bank WI Per DC (68432)" },
    { value: "52275", value2: "52275", value3: "52275", value4: "46860", name: "The Peoples FCU QB (52275)" },
    { value: "46860", value2: "46860", value3: "46860", value4: "46860", name: "The Peoples Federal Credit Union (46860)" },
    { value: "04358", value2: "04358", value3: "04358", value4: "04259", name: "The Peoples Savings and Loan - DC (04358)" },
    { value: "04259", value2: "04259", value3: "04259", value4: "04259", name: "The Peoples Savings and Loan-OH (04259)" },
    { value: "08507", value2: "08507", value3: "08507", value4: "08507", name: "The Peoples State Bank Business (08507)" },
    { value: "29310", value2: "29310", value3: "29310", value4: "29310", name: "The Perryton National Bank (29310)" },
    { value: "62316", value2: "62316", value3: "62316", value4: "62316", name: "The Pineries Bank (62316)" },
    { value: "69714", value2: "69714", value3: "69714", value4: "69714", name: "The Pineries Bank-DC (69714)" },
    { value: "68539", value2: "68539", value3: "68539", value4: "68539", name: "The Police CU (68539)" },
    { value: "68540", value2: "68540", value3: "68540", value4: "68540", name: "The Police CU - QB (68540)" },
    { value: "03035", value2: "03035", value3: "03035", value4: "03035", name: "The Rawlins National Bank (03035)" },
    { value: "00259", value2: "00259", value3: "00259", value4: "00259", name: "The Savings Bank (00259)" },
    { value: "63180", value2: "63180", value3: "63180", value4: "63180", name: "The Savings Bank - Business (63180)" },
    { value: "58014", value2: "58014", value3: "58014", value4: "58014", name: "The Savings Bank OH (58014)" },
    { value: "70066", value2: "70066", value3: "70066", value4: "70066", name: "The Savings Bank OH Bus (70066)" },
    { value: "63675", value2: "63675", value3: "63675", value4: "63675", name: "The Security State Bank (63675)" },
    { value: "07511", value2: "07511", value3: "07511", value4: "07511", name: "The Seymour Bank (07511)" },
    { value: "56673", value2: "56673", value3: "56673", value4: "56673", name: "The Seymour Bank - Quickbooks (56673)" },
    { value: "58746", value2: "58746", value3: "58746", value4: "16821", name: "The Shelby State Bank-Qkbks Direct (58746)" },
    { value: "69392", value2: "69392", value3: "69392", value4: "69392", name: "The Southern Bank (69392)" },
    { value: "69395", value2: "69395", value3: "69395", value4: "69395", name: "The Southern Bank - DC (69395)" },
    { value: "12907", value2: "12907", value3: "12907", value4: "12907", name: "The State Bank (12907)" },
    { value: "11446", value2: "11446", value3: "11446", value4: "11446", name: "The State Bank & Trust Company (11446)" },
    { value: "13854", value2: "13854", value3: "13854", value4: "13854", name: "The State Bank - MI (13854)" },
    { value: "06935", value2: "06935", value3: "06935", value4: "06935", name: "The State Bank Group (06935)" },
    { value: "69023", value2: "69023", value3: "69023", value4: "69023", name: "The State Bank MI BB (69023)" },
    { value: "03104", value2: "03104", value3: "03104", value4: "03104", name: "The State Bank MI QBWC (03104)" },
    { value: "00357", value2: "00357", value3: "00357", value4: "00357", name: "The State Bank of Faribault (00357)" },
    { value: "28875", value2: "28875", value3: "28875", value4: "28875", name: "The State Bank of Geneva (28875)" },
    { value: "50010", value2: "50010", value3: "50010", value4: "50010", name: "The Stock Exchange Bank - IB (50010)" },
    { value: "07363", value2: "07363", value3: "07363", value4: "07363", name: "The Tennessee Credit Union (07363)" },
    { value: "67188", value2: "67188", value3: "67188", value4: "67188", name: "The Tipton Latham Bank, NA (67188)" },
    { value: "58929", value2: "58929", value3: "58929", value4: "58929", name: "The Tri-County Bank (58929)" },
    { value: "02366", value2: "02366", value3: "02366", value4: "02366", name: "The Tri-County Bank Business (02366)" },
    { value: "17814", value2: "17814", value3: "17814", value4: "17814", name: "The Union Bank Company (17814)" },
    { value: "14907", value2: "14907", value3: "14907", value4: "14907", name: "The Upstate National Bank (14907)" },
    { value: "03833", value2: "03833", value3: "03833", value4: "03833", name: "The Victory Bank (03833)" },
    { value: "03836", value2: "03836", value3: "03836", value4: "03833", name: "The Victory Bank - DL (03836)" },
    { value: "07289", value2: "07289", value3: "07289", value4: "07289", name: "The Village Bank (MA) (07289)" },
    { value: "06088", value2: "06088", value3: "06088", value4: "06088", name: "The Village Bank - IL (06088)" },
    { value: "19092", value2: "19092", value3: "19092", value4: "19092", name: "The Village Bank - QBWC (19092)" },
    { value: "04639", value2: "04639", value3: "04639", value4: "04639", name: "The Washington Trust Co - BB WC QB (04639)" },
    { value: "07486", value2: "07486", value3: "07486", value4: "07486", name: "The Washington Trust Company (07486)" },
    { value: "15054", value2: "15054", value3: "15054", value4: "15054", name: "Think Bank Online Download (15054)" },
    { value: "04340", value2: "04340", value3: "04340", value4: "04340", name: "Think Bank-Direct Connect (04340)" },
    { value: "68978", value2: "68978", value3: "68978", value4: "68978", name: "Thinkwise Federal Credit Union (68978)" },
    { value: "67638", value2: "67638", value3: "67638", value4: "67638", name: "Third Coast Bank - Consumer (67638)" },
    { value: "67639", value2: "67639", value3: "67639", value4: "67638", name: "Third Coast Bank - Consumer DC (67639)" },
    { value: "16542", value2: "16542", value3: "16542", value4: "16542", name: "Third Coast Bank ssb (16542)" },
    { value: "09671", value2: "09671", value3: "09671", value4: "09671", name: "Third Coast Bank ssb DC (09671)" },
    { value: "03119", value2: "03119", value3: "03119", value4: "03119", name: "Thomaston Savings Bank - DC (03119)" },
    { value: "03422", value2: "03422", value3: "03422", value4: "03422", name: "Thomaston Savings Bank - QBWC (03422)" },
    { value: "01719", value2: "01719", value3: "01719", value4: "01719", name: "Thomasville National Bank (01719)" },
    { value: "68442", value2: "68442", value3: "68442", value4: "01719", name: "Thomasville National Bank-DC (68442)" },
    { value: "69668", value2: "69668", value3: "69668", value4: "69668", name: "Thornapple Credit Union (69668)" },
    { value: "13095", value2: "13095", value3: "13095", value4: "13095", name: "Three Rivers Bank of Montana (13095)" },
    { value: "10466", value2: "10466", value3: "10466", value4: "10466", name: "Three Rivers Federal CU (10466)" },
    { value: "51432", value2: "51432", value3: "51432", value4: "51432", name: "Thrive FCU (51432)" },
    { value: "15817", value2: "15817", value3: "15817", value4: "15817", name: "Thrivent Federal Credit Union (15817)" },
    { value: "15811", value2: "15811", value3: "15811", value4: "15811", name: "Thrivent Federal Credit Union WCQB (15811)" },
    { value: "10793", value2: "10793", value3: "10793", value4: "10793", name: "Thrivent Federal Credit Union-old (10793)" },
    { value: "24012", value2: "24012", value3: "24012", value4: "24012", name: "Thumb Bank & Trust (24012)" },
    { value: "16182", value2: "16182", value3: "16182", value4: "16182", name: "Ticonderoga Federal Credit Union (16182)" },
    { value: "18487", value2: "18487", value3: "18487", value4: "18487", name: "Tidemark FCU (18487)" },
    { value: "18793", value2: "18793", value3: "18793", value4: "18793", name: "Tidemark FCU - WC (18793)" },
    { value: "00476", value2: "00476", value3: "00476", value4: "00476", name: "Tigers Community Credit Union (00476)" },
    { value: "08096", value2: "08096", value3: "08096", value4: "08096", name: "Timberland Bank (08096)" },
    { value: "08099", value2: "08099", value3: "08099", value4: "08096", name: "Timberland Bank WC (08099)" },
    { value: "68861", value2: "68861", value3: "68861", value4: "68861", name: "Timberland Bank-TM (68861)" },
    { value: "68862", value2: "68862", value3: "68862", value4: "68862", name: "Timberland Bank-TM-DC (68862)" },
    { value: "02388", value2: "02388", value3: "02388", value4: "02388", name: "Timberline Bank (02388)" },
    { value: "07106", value2: "07106", value3: "07106", value4: "07106", name: "Timberline Bank - Business (07106)" },
    { value: "07112", value2: "07112", value3: "07112", value4: "07112", name: "Timberline Bank - Retail QBDC (07112)" },
    { value: "70244", value2: "70244", value3: "70244", value4: "70244", name: "Timberline Bank CC (70244)" },
    { value: "07439", value2: "07439", value3: "07439", value4: "07439", name: "Timberline Bank Credit Card (07439)" },
    { value: "36309", value2: "36309", value3: "36309", value4: "36309", name: "Time Bank - Bank Online (36309)" },
    { value: "64293", value2: "64293", value3: "64293", value4: "64293", name: "Time Bank - QB (64293)" },
    { value: "02856", value2: "02856", value3: "02856", value4: "02856", name: "Tioga State Bank (02856)" },
    { value: "69901", value2: "69901", value3: "69901", value4: "69901", name: "Titan Bank Business DC (69901)" },
    { value: "09152", value2: "09152", value3: "09152", value4: "09152", name: "Titan Bank Business WC (09152)" },
    { value: "01712", value2: "01712", value3: "01712", value4: "01712", name: "Today's Bank (01712)" },
    { value: "06601", value2: "06601", value3: "06601", value4: "06601", name: "Together Credit Union (06601)" },
    { value: "10369", value2: "10369", value3: "10369", value4: "10369", name: "Tolleson Private Bank (10369)" },
    { value: "15975", value2: "15975", value3: "15975", value4: "15975", name: "Tomah Area CU (15975)" },
    { value: "08705", value2: "08705", value3: "08705", value4: "08705", name: "Tompkins Community Bank - NY (08705)" },
    { value: "12191", value2: "12191", value3: "12191", value4: "12191", name: "Tompkins Community Bank TM (12191)" },
    { value: "12194", value2: "12194", value3: "12194", value4: "12191", name: "Tompkins Community Bank TM-DL (12194)" },
    { value: "08708", value2: "08708", value3: "08708", value4: "08705", name: "Tompkins Community Bank WC (08708)" },
    { value: "07319", value2: "07319", value3: "07319", value4: "07319", name: "Tompkins State Bank-Retail (07319)" },
    { value: "07242", value2: "07242", value3: "07242", value4: "07242", name: "Tongass CU (07242)" },
    { value: "07343", value2: "07343", value3: "07343", value4: "07343", name: "TopLine (07343)" },
    { value: "01272", value2: "01272", value3: "01272", value4: "01272", name: "Topside Federal Credit Union (01272)" },
    { value: "58401", value2: "58401", value3: "58401", value4: "58401", name: "Toro Employees Federal CU (58401)" },
    { value: "00437", value2: "00437", value3: "00437", value4: "00437", name: "Torrance Community Credit Union (00437)" },
    { value: "63579", value2: "63579", value3: "63579", value4: "63579", name: "Torrington Savings Bank - Bus (63579)" },
    { value: "05456", value2: "05456", value3: "05456", value4: "05456", name: "Torrington Savings Bank - New (05456)" },
    { value: "28929", value2: "28929", value3: "28929", value4: "28929", name: "Touchmark National Bank (28929)" },
    { value: "19542", value2: "19542", value3: "19542", value4: "19542", name: "Touchstone Bank (19542)" },
    { value: "07130", value2: "07130", value3: "07130", value4: "07130", name: "Touchstone Bank - BOB (07130)" },
    { value: "69925", value2: "69925", value3: "69925", value4: "69925", name: "Touchstone Bank DC (69925)" },
    { value: "69926", value2: "69926", value3: "69926", value4: "69926", name: "Touchstone Business - DC (69926)" },
    { value: "02435", value2: "02435", value3: "02435", value4: "02435", name: "Tower Community Bank (02435)" },
    { value: "12321", value2: "12321", value3: "12321", value4: "12321", name: "Town & Country Bank - Las Vegas (12321)" },
    { value: "00811", value2: "00811", value3: "00811", value4: "00811", name: "Town & Country Bank and Trust Co. (00811)" },
    { value: "67884", value2: "67884", value3: "67884", value4: "67884", name: "Town & Country CU QBWC (67884)" },
    { value: "13987", value2: "13987", value3: "13987", value4: "13987", name: "Town & Country FCU-QBO (13987)" },
    { value: "04063", value2: "04063", value3: "04063", value4: "04063", name: "Town Bank Bus (04063)" },
    { value: "09561", value2: "09561", value3: "09561", value4: "09561", name: "Town Bank IBB (09561)" },
    { value: "11901", value2: "11901", value3: "11901", value4: "11901", name: "Town Center Bank IL (11901)" },
    { value: "69625", value2: "69625", value3: "69625", value4: "69625", name: "Town Center Bank IL QBDC (69625)" },
    { value: "05078", value2: "05078", value3: "05078", value4: "05078", name: "Town and Country (05078)" },
    { value: "70476", value2: "70476", value3: "70476", value4: "70476", name: "Town and Country Bank Quincy-DC (70476)" },
    { value: "53271", value2: "53271", value3: "53271", value4: "53271", name: "Town and Country Bank of Quincy (53271)" },
    { value: "56988", value2: "56988", value3: "56988", value4: "56988", name: "Town of Hempstead Empl FCU QB (56988)" },
    { value: "06139", value2: "06139", value3: "06139", value4: "06139", name: "Town&Country Bk MO (06139)" },
    { value: "70158", value2: "70158", value3: "70158", value4: "70158", name: "Town&Country Bk MO QBDC (70158)" },
    { value: "18000", value2: "18000", value3: "18000", value4: "18000", name: "TowneBank VA BizMax (18000)" },
    { value: "08263", value2: "08263", value3: "08263", value4: "08245", name: "TowneBank VA Pers. & Biz WebCon (08263)" },
    { value: "08245", value2: "08245", value3: "08245", value4: "08245", name: "TowneBank VA Personal & Biz (08245)" },
    { value: "17926", value2: "17926", value3: "17926", value4: "17926", name: "TowneBnk Business Online Banking (17926)" },
    { value: "09064", value2: "09064", value3: "09064", value4: "09064", name: "Toyota Financial Savings Bank (09064)" },
    { value: "46434", value2: "46434", value3: "46434", value4: "46434", name: "Tradesmen Community CU Des Moin (46434)" },
    { value: "56703", value2: "56703", value3: "56703", value4: "46434", name: "Tradesmen Community CU_DC (56703)" },
    { value: "04100", value2: "04100", value3: "04100", value4: "04100", name: "Tradition Capital Bank - Pers QB (04100)" },
    { value: "01860", value2: "01860", value3: "01860", value4: "01860", name: "Tradition Capital Bank - Personal Banking (01860)" },
    { value: "04103", value2: "04103", value3: "04103", value4: "04103", name: "Tradition Capital Bank - QKN QB (04103)" },
    { value: "08042", value2: "08042", value3: "08042", value4: "08042", name: "Tradition Capital Bank Bus (08042)" },
    { value: "09597", value2: "09597", value3: "09597", value4: "09597", name: "Traditional Bank (09597)" },
    { value: "04725", value2: "04725", value3: "04725", value4: "04725", name: "Traditions Bank (04725)" },
    { value: "64923", value2: "64923", value3: "64923", value4: "64923", name: "Traditions First Bank (64923)" },
    { value: "69101", value2: "69101", value3: "69101", value4: "69101", name: "TrailNorth FCU credit card (69101)" },
    { value: "04143", value2: "04143", value3: "04143", value4: "04143", name: "TrailWest Bank (04143)" },
    { value: "15435", value2: "15435", value3: "15435", value4: "15435", name: "Trailhead Credit Union (15435)" },
    { value: "69798", value2: "69798", value3: "69798", value4: "69798", name: "TransPecos Bank (69798)" },
    { value: "67062", value2: "67062", value3: "67062", value4: "67062", name: "Transact Bank NA - Business (67062)" },
    { value: "63378", value2: "63378", value3: "63378", value4: "63378", name: "Traverse Catholic CU (63378)" },
    { value: "10933", value2: "10933", value3: "10933", value4: "10933", name: "Travis Credit Union (10933)" },
    { value: "67198", value2: "67198", value3: "67198", value4: "67198", name: "Tremont Credit Union (67198)" },
    { value: "67200", value2: "67200", value3: "67200", value4: "67200", name: "Tremont Credit Union - QBWC (67200)" },
    { value: "11532", value2: "11532", value3: "11532", value4: "11532", name: "Treynor State Bank (11532)" },
    { value: "04023", value2: "04023", value3: "04023", value4: "04023", name: "Tri City National Bank (04023)" },
    { value: "18463", value2: "18463", value3: "18463", value4: "18463", name: "Tri Counties - Bus (18463)" },
    { value: "68651", value2: "68651", value3: "68651", value4: "68651", name: "Tri Counties Bank CC (68651)" },
    { value: "15306", value2: "15306", value3: "15306", value4: "15306", name: "Tri Counties Bank Personal (15306)" },
    { value: "68220", value2: "68220", value3: "68220", value4: "68220", name: "Tri Counties Bank-DC (68220)" },
    { value: "11437", value2: "11437", value3: "11437", value4: "11437", name: "Tri County Area FCU (11437)" },
    { value: "55752", value2: "55752", value3: "55752", value4: "11437", name: "Tri County Area FCU - DC (55752)" },
    { value: "57381", value2: "57381", value3: "57381", value4: "57381", name: "Tri Star Bank (57381)" },
    { value: "63108", value2: "63108", value3: "63108", value4: "63108", name: "Tri State Area Federal CU (63108)" },
    { value: "33798", value2: "33798", value3: "33798", value4: "33798", name: "Tri-County Bank & Trust (33798)" },
    { value: "69620", value2: "69620", value3: "69620", value4: "69620", name: "Tri-County Bank QBDC (69620)" },
    { value: "68528", value2: "68528", value3: "68528", value4: "68528", name: "Tri-County Credit Union (68528)" },
    { value: "69933", value2: "69933", value3: "69933", value4: "69933", name: "Tri-Valley Bank, IA - DC (69933)" },
    { value: "68726", value2: "68726", value3: "68726", value4: "68726", name: "TriStar Business Banking (68726)" },
    { value: "68722", value2: "68722", value3: "68722", value4: "68722", name: "TriStar Business Banking DC (68722)" },
    { value: "17383", value2: "17383", value3: "17383", value4: "17383", name: "TriState Capital Bank Corporate (17383)" },
    { value: "16671", value2: "16671", value3: "16671", value4: "16671", name: "Triad Bank (16671)" },
    { value: "08851", value2: "08851", value3: "08851", value4: "08851", name: "Triad Banking (08851)" },
    { value: "09818", value2: "09818", value3: "09818", value4: "09818", name: "Triad Banking Business (09818)" },
    { value: "12467", value2: "12467", value3: "12467", value4: "12467", name: "Triad Business Bank (12467)" },
    { value: "12791", value2: "12791", value3: "12791", value4: "12791", name: "Triad Business Bank-TM (12791)" },
    { value: "55494", value2: "55494", value3: "55494", value4: "55494", name: "Triangle Credit Union - WC QB (55494)" },
    { value: "11155", value2: "11155", value3: "11155", value4: "11155", name: "Trinity Bank (11155)" },
    { value: "67199", value2: "67199", value3: "67199", value4: "67199", name: "Tristate Capital Bank Corporate_DC (67199)" },
    { value: "20475", value2: "20475", value3: "20475", value4: "20475", name: "Trona Valley Community FCU (20475)" },
    { value: "02416", value2: "02416", value3: "02416", value4: "02416", name: "Tropical Financial CU QB (02416)" },
    { value: "00386", value2: "00386", value3: "00386", value4: "00386", name: "Tropical Financial credit card (00386)" },
    { value: "00435", value2: "00435", value3: "00435", value4: "00435", name: "Troy Bank and Trust (00435)" },
    { value: "63021", value2: "63021", value3: "63021", value4: "63021", name: "Troy Bank and Trust Company (63021)" },
    { value: "04859", value2: "04859", value3: "04859", value4: "04859", name: "Tru Fi Credit Union (04859)" },
    { value: "65040", value2: "65040", value3: "65040", value4: "65040", name: "TruGrocer FCU Credit Card (65040)" },
    { value: "04363", value2: "04363", value3: "04363", value4: "04363", name: "TruMark Financial BB-Retail (04363)" },
    { value: "69439", value2: "69439", value3: "69439", value4: "69439", name: "TruMark Financial BB-Retail WC (69439)" },
    { value: "08201", value2: "08201", value3: "08201", value4: "08201", name: "TruNorth FCU Credit Card (08201)" },
    { value: "57399", value2: "57399", value3: "57399", value4: "57399", name: "TruPartner Credit Union, Inc. (57399)" },
    { value: "12332", value2: "12332", value3: "12332", value4: "12332", name: "TruPoint Bank Biz (12332)" },
    { value: "12335", value2: "12335", value3: "12335", value4: "12335", name: "TruPoint Bank Biz DC (12335)" },
    { value: "12329", value2: "12329", value3: "12329", value4: "12329", name: "TruPoint Bank DC (12329)" },
    { value: "12326", value2: "12326", value3: "12326", value4: "12326", name: "TruPoint Bank New (12326)" },
    { value: "68062", value2: "68062", value3: "68062", value4: "68062", name: "TruStar Federal CU TM (68062)" },
    { value: "14214", value2: "14214", value3: "14214", value4: "14214", name: "TruStar Federal Credit Union (14214)" },
    { value: "11047", value2: "11047", value3: "11047", value4: "11047", name: "TruStone Financial FCU (11047)" },
    { value: "03303", value2: "03303", value3: "03303", value4: "03303", name: "True North Federal Credit Union (03303)" },
    { value: "58956", value2: "58956", value3: "58956", value4: "58956", name: "True Sky Credit Union - DC (58956)" },
    { value: "67636", value2: "67636", value3: "67636", value4: "67636", name: "True Sky Credit Union - OK (67636)" },
    { value: "00147", value2: "00147", value3: "00147", value4: "00147", name: "Truist Bank (00147)" },
    { value: "69507", value2: "69507", value3: "69507", value4: "69507", name: "Truist Bank EWC (69507)" },
    { value: "17437", value2: "17437", value3: "17437", value4: "17437", name: "Truist Bank, Commercial Card (17437)" },
    { value: "67182", value2: "67182", value3: "67182", value4: "67182", name: "Truist Digital Treasury (67182)" },
    { value: "09547", value2: "09547", value3: "09547", value4: "09547", name: "Truist Online Banking (09547)" },
    { value: "15562", value2: "15562", value3: "15562", value4: "09547", name: "Truist Online Banking QBWC (15562)" },
    { value: "06164", value2: "06164", value3: "06164", value4: "06164", name: "Truity Credit Union (06164)" },
    { value: "06167", value2: "06167", value3: "06167", value4: "06164", name: "Truity Credit Union - Web (06167)" },
    { value: "01288", value2: "01288", value3: "01288", value4: "01288", name: "Truliant Federal Credit Union (01288)" },
    { value: "04097", value2: "04097", value3: "04097", value4: "04097", name: "Trust FCU Credit Card (04097)" },
    { value: "16221", value2: "16221", value3: "16221", value4: "16221", name: "TrustBank (16221)" },
    { value: "05057", value2: "05057", value3: "05057", value4: "05057", name: "TrustBank-DC (05057)" },
    { value: "07673", value2: "07673", value3: "07673", value4: "07673", name: "TrustTexas Bank Business (07673)" },
    { value: "08876", value2: "08876", value3: "08876", value4: "08876", name: "Trustar Bank (08876)" },
    { value: "67197", value2: "67197", value3: "67197", value4: "67197", name: "Trustar Bank-TM (67197)" },
    { value: "67201", value2: "67201", value3: "67201", value4: "67197", name: "Trustar Bank-TM-DC (67201)" },
    { value: "37194", value2: "37194", value3: "37194", value4: "37194", name: "Trustco Bank (37194)" },
    { value: "62904", value2: "62904", value3: "62904", value4: "62904", name: "Trustmark Bank - myTrustmark (62904)" },
    { value: "11251", value2: "11251", value3: "11251", value4: "11251", name: "Truxton Trust Company (11251)" },
    { value: "67664", value2: "67664", value3: "67664", value4: "67664", name: "Truxton Trust QB (67664)" },
    { value: "00168", value2: "00168", value3: "00168", value4: "00168", name: "Tucoemas Credit Union (00168)" },
    { value: "00641", value2: "00641", value3: "00641", value4: "00641", name: "Tucoemas FCU Credit Card (00641)" },
    { value: "08287", value2: "08287", value3: "08287", value4: "08287", name: "Tucson Federal Credit Union QBO (08287)" },
    { value: "61287", value2: "61287", value3: "61287", value4: "61287", name: "Tucson Old Pueblo Credit Union (61287)" },
    { value: "52461", value2: "52461", value3: "52461", value4: "52461", name: "Turbotville NB (52461)" },
    { value: "70421", value2: "70421", value3: "70421", value4: "70421", name: "Twin Cedars Bank BO (70421)" },
    { value: "70457", value2: "70457", value3: "70457", value4: "70421", name: "Twin Cedars Bank BO DC (70457)" },
    { value: "70422", value2: "70422", value3: "70422", value4: "70422", name: "Twin Cedars Bank RO (70422)" },
    { value: "70458", value2: "70458", value3: "70458", value4: "70422", name: "Twin Cedars Bank RO DC (70458)" },
    { value: "17712", value2: "17712", value3: "17712", value4: "17712", name: "Twin City Bank (17712)" },
    { value: "03438", value2: "03438", value3: "03438", value4: "03438", name: "TwinStar Credit Union (03438)" },
    { value: "00992", value2: "00992", value3: "00992", value4: "00992", name: "Two Harbors Federal CU (00992)" },
    { value: "05718", value2: "05718", value3: "05718", value4: "05718", name: "Two Rivers Bank & Trust (05718)" },
    { value: "70050", value2: "70050", value3: "70050", value4: "70050", name: "Two Rivers Bank & Trust-TM (70050)" },
    { value: "63513", value2: "63513", value3: "63513", value4: "63513", name: "U$X FCU Credit Card (63513)" },
    { value: "51837", value2: "51837", value3: "51837", value4: "51837", name: "U.R.W. Community FCU (51837)" },
    { value: "01401", value2: "01401", value3: "01401", value4: "01401", name: "U.S. Bank - PFM Direct Connect (01401)" },
    { value: "07483", value2: "07483", value3: "07483", value4: "07483", name: "U.S. Bank SinglePoint Essentials (07483)" },
    { value: "02153", value2: "02153", value3: "02153", value4: "02153", name: "U.S. Bank bus.E banking (02153)" },
    { value: "69564", value2: "69564", value3: "69564", value4: "69564", name: "UARK Federal Credit Union (69564)" },
    { value: "09638", value2: "09638", value3: "09638", value4: "09638", name: "UB&T- Marshalltown Bus QB WC (09638)" },
    { value: "12097", value2: "12097", value3: "12097", value4: "12097", name: "UBS VISA Credit Card (12097)" },
    { value: "16873", value2: "16873", value3: "16873", value4: "16873", name: "UBTgo Union Bank and Trust - NE (16873)" },
    { value: "12903", value2: "12903", value3: "12903", value4: "12903", name: "UBank Business (12903)" },
    { value: "16444", value2: "16444", value3: "16444", value4: "16444", name: "UCBI - United Community (16444)" },
    { value: "67261", value2: "67261", value3: "67261", value4: "16444", name: "UCBI - United Community QB (67261)" },
    { value: "55602", value2: "55602", value3: "55602", value4: "55602", name: "UCCU (55602)" },
    { value: "45792", value2: "45792", value3: "45792", value4: "45792", name: "UCF FCU (45792)" },
    { value: "52713", value2: "52713", value3: "52713", value4: "45792", name: "UCF FCU - QB (52713)" },
    { value: "70542", value2: "70542", value3: "70542", value4: "70542", name: "UCFN yitnas (70542)" },
    { value: "19851", value2: "19851", value3: "19851", value4: "19851", name: "UECU (19851)" },
    { value: "55209", value2: "55209", value3: "55209", value4: "55209", name: "UFirst CU (55209)" },
    { value: "62139", value2: "62139", value3: "62139", value4: "62139", name: "UKFCU (62139)" },
    { value: "09343", value2: "09343", value3: "09343", value4: "09343", name: "UMB - Web Connect (09343)" },
    { value: "03781", value2: "03781", value3: "03781", value4: "03781", name: "UMB Bank (03781)" },
    { value: "12389", value2: "12389", value3: "12389", value4: "12389", name: "UMB Bank - New Online Banking (12389)" },
    { value: "15454", value2: "15454", value3: "15454", value4: "15454", name: "UMB Bank n.a. (15454)" },
    { value: "68850", value2: "68850", value3: "68850", value4: "68850", name: "UMTB USA - DC (68850)" },
    { value: "68836", value2: "68836", value3: "68836", value4: "68836", name: "UMTB USA - WC (68836)" },
    { value: "12808", value2: "12808", value3: "12808", value4: "12808", name: "UMe Federal Credit Union (12808)" },
    { value: "10677", value2: "10677", value3: "10677", value4: "10677", name: "UNCLE Credit Union (10677)" },
    { value: "46428", value2: "46428", value3: "46428", value4: "46428", name: "UNI CU  Cedar Falls, IA (46428)" },
    { value: "55755", value2: "55755", value3: "55755", value4: "46428", name: "UNI CU_DC Cedar Falls, IA (55755)" },
    { value: "11404", value2: "11404", value3: "11404", value4: "11404", name: "UNIFY Financial Credit Union (11404)" },
    { value: "00478", value2: "00478", value3: "00478", value4: "00478", name: "UNIWYO Federal Credit Union (00478)" },
    { value: "69744", value2: "69744", value3: "69744", value4: "69744", name: "UNO FCU (69744)" },
    { value: "68359", value2: "68359", value3: "68359", value4: "68359", name: "US Bank - Visa (68359)" },
    { value: "68520", value2: "68520", value3: "68520", value4: "68520", name: "US Century Bank Business (68520)" },
    { value: "68538", value2: "68538", value3: "68538", value4: "68538", name: "US Century Bank Connect (68538)" },
    { value: "68537", value2: "68537", value3: "68537", value4: "68537", name: "US Century Bank WC QB (68537)" },
    { value: "22215", value2: "22215", value3: "22215", value4: "22215", name: "US Community Credit Union (22215)" },
    { value: "11182", value2: "11182", value3: "11182", value4: "11182", name: "US Eagle Federal Credit Union (11182)" },
    { value: "69697", value2: "69697", value3: "69697", value4: "69697", name: "US Metro Bank Bus DC (69697)" },
    { value: "08771", value2: "08771", value3: "08771", value4: "08771", name: "US Metro Bank CA (08771)" },
    { value: "70055", value2: "70055", value3: "70055", value4: "70055", name: "USAA Federal Savings Bank - New (70055)" },
    { value: "00235", value2: "00235", value3: "00235", value4: "00235", name: "USAlliance FCU (00235)" },
    { value: "02427", value2: "02427", value3: "02427", value4: "02427", name: "USF Federal Credit Union (02427)" },
    { value: "68272", value2: "68272", value3: "68272", value4: "68272", name: "USLA Direct Connect (68272)" },
    { value: "01986", value2: "01986", value3: "01986", value4: "01986", name: "UT FCU (01986)" },
    { value: "00061", value2: "00061", value3: "00061", value4: "00061", name: "UVA Community Credit Union (00061)" },
    { value: "67248", value2: "67248", value3: "67248", value4: "67248", name: "Uinta Bank (67248)" },
    { value: "62259", value2: "62259", value3: "62259", value4: "62259", name: "Ukrainian FCU (62259)" },
    { value: "62262", value2: "62262", value3: "62262", value4: "62259", name: "Ukrainian FCU - QB (62262)" },
    { value: "16834", value2: "16834", value3: "16834", value4: "16834", name: "Ukrainian Selfreliance FCU PHILA (16834)" },
    { value: "16720", value2: "16720", value3: "16720", value4: "16720", name: "Ukrainian Selfreliance FCU QB (16720)" },
    { value: "08501", value2: "08501", value3: "08501", value4: "08501", name: "Ulster Savings Bank (08501)" },
    { value: "08504", value2: "08504", value3: "08504", value4: "08504", name: "Ulster Savings Bank DC (08504)" },
    { value: "12795", value2: "12795", value3: "12795", value4: "12795", name: "Ultima Bank Minnesota (12795)" },
    { value: "70427", value2: "70427", value3: "70427", value4: "70427", name: "UmassFive College FCU Bus CC (70427)" },
    { value: "69715", value2: "69715", value3: "69715", value4: "69715", name: "UmassFive College FCU CC (69715)" },
    { value: "58893", value2: "58893", value3: "58893", value4: "58893", name: "Umpqua Bank - New (58893)" },
    { value: "18745", value2: "18745", value3: "18745", value4: "18745", name: "Umpqua-Business Direct Connect (18745)" },
    { value: "54861", value2: "54861", value3: "54861", value4: "54861", name: "Umpqua-Business Web Connect (54861)" },
    { value: "12449", value2: "12449", value3: "12449", value4: "12449", name: "UniBank (12449)" },
    { value: "12452", value2: "12452", value3: "12452", value4: "12449", name: "UniBank - QBWC (12452)" },
    { value: "32721", value2: "32721", value3: "32721", value4: "32721", name: "UniBank WA (32721)" },
    { value: "12968", value2: "12968", value3: "12968", value4: "12968", name: "UniBank WA - DC (12968)" },
    { value: "04194", value2: "04194", value3: "04194", value4: "04194", name: "Unico Bank (04194)" },
    { value: "12299", value2: "12299", value3: "12299", value4: "12299", name: "Unified Bank Business (12299)" },
    { value: "12302", value2: "12302", value3: "12302", value4: "12302", name: "Unified Bank Business - DC (12302)" },
    { value: "16761", value2: "16761", value3: "16761", value4: "16761", name: "Union Bank (16761)" },
    { value: "03138", value2: "03138", value3: "03138", value4: "03138", name: "Union Bank & Trust Company - NE (03138)" },
    { value: "03758", value2: "03758", value3: "03758", value4: "03758", name: "Union Bank & Trust, Monticello, AR (03758)" },
    { value: "69104", value2: "69104", value3: "69104", value4: "69104", name: "Union Bank MI (69104)" },
    { value: "69105", value2: "69105", value3: "69105", value4: "69105", name: "Union Bank MI-DC (69105)" },
    { value: "01091", value2: "01091", value3: "01091", value4: "01091", name: "Union Bank Morrisville (01091)" },
    { value: "70118", value2: "70118", value3: "70118", value4: "70118", name: "Union Bank-MI-TM (70118)" },
    { value: "70119", value2: "70119", value3: "70119", value4: "70118", name: "Union Bank-MI-TM-DC (70119)" },
    { value: "69311", value2: "69311", value3: "69311", value4: "69311", name: "Union Building Trades FCU CC (69311)" },
    { value: "06683", value2: "06683", value3: "06683", value4: "06683", name: "Union National Bank IL (06683)" },
    { value: "03125", value2: "03125", value3: "03125", value4: "03125", name: "Union National Bank IL Bus (03125)" },
    { value: "69891", value2: "69891", value3: "69891", value4: "69891", name: "Union National Bank IL Bus DC (69891)" },
    { value: "69890", value2: "69890", value3: "69890", value4: "69890", name: "Union National Bank IL DC (69890)" },
    { value: "04169", value2: "04169", value3: "04169", value4: "04169", name: "Union Savings Bank Business - CT (04169)" },
    { value: "69303", value2: "69303", value3: "69303", value4: "69303", name: "Union Square CU CC (69303)" },
    { value: "10840", value2: "10840", value3: "10840", value4: "10840", name: "Union Square Credit Union (10840)" },
    { value: "05053", value2: "05053", value3: "05053", value4: "05053", name: "Union State Bank (05053)" },
    { value: "11256", value2: "11256", value3: "11256", value4: "11256", name: "Union State Bank - Hazen (11256)" },
    { value: "01120", value2: "01120", value3: "01120", value4: "01120", name: "Union State Bank - Pell City,AL (01120)" },
    { value: "29415", value2: "29415", value3: "29415", value4: "29415", name: "Union State Bank IA (29415)" },
    { value: "10861", value2: "10861", value3: "10861", value4: "10861", name: "Union State Bank, Everest (10861)" },
    { value: "51327", value2: "51327", value3: "51327", value4: "51327", name: "Union State Bank, Uniontown, KS (51327)" },
    { value: "16962", value2: "16962", value3: "16962", value4: "16962", name: "Unison Bank (16962)" },
    { value: "67788", value2: "67788", value3: "67788", value4: "67788", name: "Unison Bank Business (67788)" },
    { value: "63648", value2: "63648", value3: "63648", value4: "63648", name: "Unison Credit Union (63648)" },
    { value: "17487", value2: "17487", value3: "17487", value4: "17487", name: "United Advantage NW FCU (17487)" },
    { value: "14232", value2: "14232", value3: "14232", value4: "14232", name: "United Bank & Trust - KS (14232)" },
    { value: "07541", value2: "07541", value3: "07541", value4: "07541", name: "United Bank - AL FL Business (07541)" },
    { value: "67745", value2: "67745", value3: "67745", value4: "67745", name: "United Bank - AL FL Business DC (67745)" },
    { value: "07538", value2: "07538", value3: "07538", value4: "07538", name: "United Bank - AL FL Cons_SB (07538)" },
    { value: "65217", value2: "65217", value3: "65217", value4: "65217", name: "United Bank - DC QB (65217)" },
    { value: "50955", value2: "50955", value3: "50955", value4: "50955", name: "United Bank - GA (50955)" },
    { value: "01692", value2: "01692", value3: "01692", value4: "01692", name: "United Bank - QN QB (01692)" },
    { value: "11353", value2: "11353", value3: "11353", value4: "11353", name: "United Bank - Springdale, AR (11353)" },
    { value: "10637", value2: "10637", value3: "10637", value4: "10637", name: "United Bank BeB (10637)" },
    { value: "69490", value2: "69490", value3: "69490", value4: "69490", name: "United Bank BeB EWC (69490)" },
    { value: "17713", value2: "17713", value3: "17713", value4: "17713", name: "United Bank Credit Card (17713)" },
    { value: "01435", value2: "01435", value3: "01435", value4: "01435", name: "United Bank Of Iowa (01435)" },
    { value: "69922", value2: "69922", value3: "69922", value4: "69922", name: "United Bank and Trust Company (69922)" },
    { value: "69923", value2: "69923", value3: "69923", value4: "69922", name: "United Bank and Trust Company DC (69923)" },
    { value: "04737", value2: "04737", value3: "04737", value4: "04737", name: "United Bank of Michigan (04737)" },
    { value: "63744", value2: "63744", value3: "63744", value4: "63744", name: "United Bank of Michigan - Business (63744)" },
    { value: "50811", value2: "50811", value3: "50811", value4: "50811", name: "United Bank of Philadelphia (50811)" },
    { value: "12527", value2: "12527", value3: "12527", value4: "12527", name: "United Bank of Union Retail (12527)" },
    { value: "67721", value2: "67721", value3: "67721", value4: "67721", name: "United Bank-AL FL Consumer-SB (67721)" },
    { value: "13731", value2: "13731", value3: "13731", value4: "13731", name: "United Business Bank (13731)" },
    { value: "16470", value2: "16470", value3: "16470", value4: "16470", name: "United Community Bank (16470)" },
    { value: "05136", value2: "05136", value3: "05136", value4: "05136", name: "United Community Bank - Central IL (05136)" },
    { value: "14613", value2: "14613", value3: "14613", value4: "14613", name: "United Community Bank - LA (14613)" },
    { value: "12150", value2: "12150", value3: "12150", value4: "12150", name: "United Community Bank - MN (12150)" },
    { value: "70454", value2: "70454", value3: "70454", value4: "70454", name: "United Community Bank Bus CC (70454)" },
    { value: "69371", value2: "69371", value3: "69371", value4: "69371", name: "United Community Bank CC (69371)" },
    { value: "17937", value2: "17937", value3: "17937", value4: "17937", name: "United Community Bank IA (17937)" },
    { value: "02988", value2: "02988", value3: "02988", value4: "02988", name: "United Community Bank WI (02988)" },
    { value: "28203", value2: "28203", value3: "28203", value4: "28203", name: "United Community Bank of W KY (28203)" },
    { value: "69640", value2: "69640", value3: "69640", value4: "12150", name: "United Community Bank-MN QBDC (69640)" },
    { value: "00737", value2: "00737", value3: "00737", value4: "00737", name: "United Community CU CC (00737)" },
    { value: "57084", value2: "57084", value3: "57084", value4: "57084", name: "United Community Credit Union (57084)" },
    { value: "00440", value2: "00440", value3: "00440", value4: "00440", name: "United Consumers Credit Union (00440)" },
    { value: "70207", value2: "70207", value3: "70207", value4: "70207", name: "United Cumberland Bank BO (70207)" },
    { value: "70208", value2: "70208", value3: "70208", value4: "70208", name: "United Cumberland Bank RO (70208)" },
    { value: "69521", value2: "69521", value3: "69521", value4: "69521", name: "United FCU CC (69521)" },
    { value: "68111", value2: "68111", value3: "68111", value4: "68111", name: "United Farmers State Bank MN (68111)" },
    { value: "01908", value2: "01908", value3: "01908", value4: "01908", name: "United Federal Credit Union (01908)" },
    { value: "50220", value2: "50220", value3: "50220", value4: "50220", name: "United Fidelity Bank, fsb (50220)" },
    { value: "57345", value2: "57345", value3: "57345", value4: "57345", name: "United Heritage CU (57345)" },
    { value: "00957", value2: "00957", value3: "00957", value4: "00957", name: "United Methodist FCU (00957)" },
    { value: "02280", value2: "02280", value3: "02280", value4: "02280", name: "United Mississippi Bank (02280)" },
    { value: "68036", value2: "68036", value3: "68036", value4: "02280", name: "United Mississippi Bank-DC (68036)" },
    { value: "02087", value2: "02087", value3: "02087", value4: "02087", name: "United National Bank (02087)" },
    { value: "09950", value2: "09950", value3: "09950", value4: "09950", name: "United Poles FCU (09950)" },
    { value: "04326", value2: "04326", value3: "04326", value4: "04326", name: "United Prairie Bank (04326)" },
    { value: "12444", value2: "12444", value3: "12444", value4: "12444", name: "United Republic Bank (12444)" },
    { value: "53844", value2: "53844", value3: "53844", value4: "53844", name: "United Savings Bank (53844)" },
    { value: "09494", value2: "09494", value3: "09494", value4: "09494", name: "United Savings Bank - Business (09494)" },
    { value: "70117", value2: "70117", value3: "70117", value4: "70117", name: "United Savings Credit Union (70117)" },
    { value: "01794", value2: "01794", value3: "01794", value4: "01794", name: "United Security Bank (01794)" },
    { value: "04376", value2: "04376", value3: "04376", value4: "04376", name: "United Security Bank QBBO (04376)" },
    { value: "02542", value2: "02542", value3: "02542", value4: "02542", name: "United Southern Bank (02542)" },
    { value: "13011", value2: "13011", value3: "13011", value4: "13011", name: "United Southern Bank - KY (13011)" },
    { value: "69850", value2: "69850", value3: "69850", value4: "13011", name: "United Southern Bank- KY QBDC (69850)" },
    { value: "35298", value2: "35298", value3: "35298", value4: "35298", name: "United State Bank (35298)" },
    { value: "63477", value2: "63477", value3: "63477", value4: "63477", name: "United Teletech Financial (63477)" },
    { value: "04279", value2: "04279", value3: "04279", value4: "04279", name: "United Texas Bank (04279)" },
    { value: "16956", value2: "16956", value3: "16956", value4: "16956", name: "United Valley Bank (16956)" },
    { value: "67948", value2: "67948", value3: "67948", value4: "67948", name: "United Valley Bank Business (67948)" },
    { value: "11432", value2: "11432", value3: "11432", value4: "11432", name: "UnitedOne Credit Union (11432)" },
    { value: "02122", value2: "02122", value3: "02122", value4: "02122", name: "Unitus Community Credit Union (02122)" },
    { value: "03071", value2: "03071", value3: "03071", value4: "03071", name: "Unity Bank Wisconsin (03071)" },
    { value: "69604", value2: "69604", value3: "69604", value4: "69604", name: "Unity Bank of Mississippi (69604)" },
    { value: "69607", value2: "69607", value3: "69607", value4: "69604", name: "Unity Bank of Mississippi-DC QB (69607)" },
    { value: "13920", value2: "13920", value3: "13920", value4: "13920", name: "Unity Bank, 021210057 (13920)" },
    { value: "00347", value2: "00347", value3: "00347", value4: "00347", name: "Unity National Bank Houston (00347)" },
    { value: "67907", value2: "67907", value3: "67907", value4: "67907", name: "Unity One Credit Union (67907)" },
    { value: "67908", value2: "67908", value3: "67908", value4: "67908", name: "Unity One Credit Union QBDC (67908)" },
    { value: "14950", value2: "14950", value3: "14950", value4: "14950", name: "Universal 1 CU (14950)" },
    { value: "06910", value2: "06910", value3: "06910", value4: "06910", name: "Universal 1 CU - Webconnect (06910)" },
    { value: "64290", value2: "64290", value3: "64290", value4: "64290", name: "Universal 1 Credit Card (64290)" },
    { value: "68186", value2: "68186", value3: "68186", value4: "68186", name: "Universal Bank  Business DC (68186)" },
    { value: "68185", value2: "68185", value3: "68185", value4: "68185", name: "Universal Bank Business WC (68185)" },
    { value: "68345", value2: "68345", value3: "68345", value4: "68345", name: "Universal Bank Personal DC (68345)" },
    { value: "31491", value2: "31491", value3: "31491", value4: "31491", name: "Universal Bank Personal WC (31491)" },
    { value: "00842", value2: "00842", value3: "00842", value4: "00842", name: "University & Community Credit Card (00842)" },
    { value: "14958", value2: "14958", value3: "14958", value4: "14958", name: "University & Community FCU (14958)" },
    { value: "16342", value2: "16342", value3: "16342", value4: "16342", name: "University Bank BeB Quickbooks (16342)" },
    { value: "67680", value2: "67680", value3: "67680", value4: "67680", name: "University Bank Web Connect (67680)" },
    { value: "67608", value2: "67608", value3: "67608", value4: "67608", name: "University CU Miami Pers (67608)" },
    { value: "67611", value2: "67611", value3: "67611", value4: "67608", name: "University CU Miami Pers QBDC (67611)" },
    { value: "03981", value2: "03981", value3: "03981", value4: "03981", name: "University Credit Union (03981)" },
    { value: "02776", value2: "02776", value3: "02776", value4: "02776", name: "University Credit Union - L.A.-CA (02776)" },
    { value: "39276", value2: "39276", value3: "39276", value4: "39276", name: "University FCU (39276)" },
    { value: "55635", value2: "55635", value3: "55635", value4: "39276", name: "University FCU_QB (55635)" },
    { value: "09946", value2: "09946", value3: "09946", value4: "09946", name: "University Federal CU (09946)" },
    { value: "06182", value2: "06182", value3: "06182", value4: "06179", name: "University of IL CU (06182)" },
    { value: "69611", value2: "69611", value3: "69611", value4: "69611", name: "University of Michigan CU (69611)" },
    { value: "68709", value2: "68709", value3: "68709", value4: "68709", name: "University of Nebraska (68709)" },
    { value: "65076", value2: "65076", value3: "65076", value4: "65076", name: "University of Toledo FCU (65076)" },
    { value: "65388", value2: "65388", value3: "65388", value4: "65388", name: "Univest Bank & Trust Co (65388)" },
    { value: "65391", value2: "65391", value3: "65391", value4: "65388", name: "Univest Bank & Trust Co - DL - NEW (65391)" },
    { value: "68718", value2: "68718", value3: "68718", value4: "68718", name: "Univest Bank & Trust Co-TM (68718)" },
    { value: "68719", value2: "68719", value3: "68719", value4: "68718", name: "Univest Bank & Trust Co-TM-DC (68719)" },
    { value: "36150", value2: "36150", value3: "36150", value4: "36150", name: "Upper Peninsula State Bank (36150)" },
    { value: "02136", value2: "02136", value3: "02136", value4: "02136", name: "Utah First Credit Union (02136)" },
    { value: "58251", value2: "58251", value3: "58251", value4: "58251", name: "Utah Heritage CU (58251)" },
    { value: "58407", value2: "58407", value3: "58407", value4: "58407", name: "Utah Independent Bank (58407)" },
    { value: "68187", value2: "68187", value3: "68187", value4: "68187", name: "Utica Gas & Electric FCU CC (68187)" },
    { value: "03937", value2: "03937", value3: "03937", value4: "03937", name: "Uwharrie Bank-WC Quickbooks (03937)" },
    { value: "70226", value2: "70226", value3: "70226", value4: "70226", name: "VACU (70226)" },
    { value: "70227", value2: "70227", value3: "70227", value4: "70226", name: "VACU - QBDC (70227)" },
    { value: "31986", value2: "31986", value3: "31986", value4: "31986", name: "VISIONBank ND (31986)" },
    { value: "68493", value2: "68493", value3: "68493", value4: "31986", name: "VISIONBank ND QBDC (68493)" },
    { value: "20301", value2: "20301", value3: "20301", value4: "20301", name: "Vacationland FCU (20301)" },
    { value: "04507", value2: "04507", value3: "04507", value4: "04507", name: "Valley - WC for QB (04507)" },
    { value: "53406", value2: "53406", value3: "53406", value4: "53406", name: "Valley Bank (53406)" },
    { value: "16345", value2: "16345", value3: "16345", value4: "16345", name: "Valley Bank - Bus (16345)" },
    { value: "69333", value2: "69333", value3: "69333", value4: "53406", name: "Valley Bank Bus - QBDC (69333)" },
    { value: "70488", value2: "70488", value3: "70488", value4: "70488", name: "Valley Bank Helena (70488)" },
    { value: "70489", value2: "70489", value3: "70489", value4: "70488", name: "Valley Bank Helena-DC (70489)" },
    { value: "09248", value2: "09248", value3: "09248", value4: "09248", name: "Valley Bank NV Business (09248)" },
    { value: "09245", value2: "09245", value3: "09245", value4: "09245", name: "Valley Bank NV Business Direct (09245)" },
    { value: "67825", value2: "67825", value3: "67825", value4: "67825", name: "Valley Bank of Commerce - NM (67825)" },
    { value: "68255", value2: "68255", value3: "68255", value4: "67825", name: "Valley Bank of Commerce-NM-DC (68255)" },
    { value: "04220", value2: "04220", value3: "04220", value4: "04220", name: "Valley Bank of Kalispell MT (04220)" },
    { value: "09773", value2: "09773", value3: "09773", value4: "09773", name: "Valley Central Bank (09773)" },
    { value: "62997", value2: "62997", value3: "62997", value4: "62997", name: "Valley Central Bank - QBWC (62997)" },
    { value: "01895", value2: "01895", value3: "01895", value4: "01895", name: "Valley Credit Union (01895)" },
    { value: "63885", value2: "63885", value3: "63885", value4: "63885", name: "Valley Financial Credit Union-QBO (63885)" },
    { value: "18559", value2: "18559", value3: "18559", value4: "18559", name: "Valley Isle Community FCU- QB (18559)" },
    { value: "18556", value2: "18556", value3: "18556", value4: "18556", name: "Valley Isle Community Federal CU (18556)" },
    { value: "04473", value2: "04473", value3: "04473", value4: "04473", name: "Valley Oak Credit Union (04473)" },
    { value: "18166", value2: "18166", value3: "18166", value4: "18166", name: "Valley Star Credit Union (18166)" },
    { value: "56313", value2: "56313", value3: "56313", value4: "56313", name: "Valley State Bank (56313)" },
    { value: "00339", value2: "00339", value3: "00339", value4: "00339", name: "Valley Strong Credit Union (00339)" },
    { value: "03095", value2: "03095", value3: "03095", value4: "03095", name: "ValleyStar C.U. Credit Card (03095)" },
    { value: "12504", value2: "12504", value3: "12504", value4: "12504", name: "Valliance Bank (12504)" },
    { value: "16315", value2: "16315", value3: "16315", value4: "16315", name: "Valliance Bank Bus Direct (16315)" },
    { value: "02222", value2: "02222", value3: "02222", value4: "02222", name: "Valor Bank (02222)" },
    { value: "69414", value2: "69414", value3: "69414", value4: "02222", name: "Valor Bank QBDC (69414)" },
    { value: "03375", value2: "03375", value3: "03375", value4: "03375", name: "ValueBank Texas (03375)" },
    { value: "07268", value2: "07268", value3: "07268", value4: "07268", name: "ValueBank Texas QBDC (07268)" },
    { value: "09263", value2: "09263", value3: "09263", value4: "09263", name: "Valwood Park Federal Credit Union (09263)" },
    { value: "05306", value2: "05306", value3: "05306", value4: "05306", name: "Vanderbilt Univ. C.U. Credit Card (05306)" },
    { value: "67284", value2: "67284", value3: "67284", value4: "67284", name: "Vantage Bank (67284)" },
    { value: "13428", value2: "13428", value3: "13428", value4: "13428", name: "Vantage Bank Texas (13428)" },
    { value: "01024", value2: "01024", value3: "01024", value4: "01024", name: "Vantage Credit Union (01024)" },
    { value: "11363", value2: "11363", value3: "11363", value4: "11363", name: "Vantage West Credit Union (11363)" },
    { value: "23754", value2: "23754", value3: "23754", value4: "23754", name: "Vast Bank N.A. (23754)" },
    { value: "10848", value2: "10848", value3: "10848", value4: "10848", name: "Vectra Bank Colorado - WEB (10848)" },
    { value: "13507", value2: "13507", value3: "13507", value4: "13507", name: "Vectra Bank of Colorado - Direct (13507)" },
    { value: "06216", value2: "06216", value3: "06216", value4: "06216", name: "Velocity Community Credit Union (06216)" },
    { value: "16363", value2: "16363", value3: "16363", value4: "16363", name: "Velocity Credit Union (16363)" },
    { value: "06886", value2: "06886", value3: "06886", value4: "06886", name: "Ventura County Credit Union (06886)" },
    { value: "11983", value2: "11983", value3: "11983", value4: "11983", name: "VeraBank (11983)" },
    { value: "11986", value2: "11986", value3: "11986", value4: "11983", name: "VeraBank QBDC (11986)" },
    { value: "69616", value2: "69616", value3: "69616", value4: "69616", name: "Vergas State Bank (69616)" },
    { value: "03488", value2: "03488", value3: "03488", value4: "03488", name: "Veridian CU (03488)" },
    { value: "59433", value2: "59433", value3: "59433", value4: "59433", name: "Verimore Bank (59433)" },
    { value: "07670", value2: "07670", value3: "07670", value4: "07670", name: "Veritex Comm Bank Dallas TX DC (07670)" },
    { value: "11819", value2: "11819", value3: "11819", value4: "11819", name: "Veritex Community Bank Dallas TX (11819)" },
    { value: "10625", value2: "10625", value3: "10625", value4: "10625", name: "Verity Credit Union (10625)" },
    { value: "67306", value2: "67306", value3: "67306", value4: "67306", name: "Vermilion Bank & Trust Company (67306)" },
    { value: "55998", value2: "55998", value3: "55998", value4: "55998", name: "Vermillion FCU - Vermillion SD (55998)" },
    { value: "25170", value2: "25170", value3: "25170", value4: "25170", name: "Vermillion State Bank (25170)" },
    { value: "09355", value2: "09355", value3: "09355", value4: "09355", name: "Vermont FCU WCQB (09355)" },
    { value: "02410", value2: "02410", value3: "02410", value4: "02410", name: "Verus Bank of Commerce (02410)" },
    { value: "62385", value2: "62385", value3: "62385", value4: "62385", name: "Verve, a Credit Union (62385)" },
    { value: "24228", value2: "24228", value3: "24228", value4: "24228", name: "Via Credit Union - IN (24228)" },
    { value: "70180", value2: "70180", value3: "70180", value4: "70180", name: "Vibrant CU - Bus (70180)" },
    { value: "09978", value2: "09978", value3: "09978", value4: "09978", name: "Vibrant Credit Union (09978)" },
    { value: "09308", value2: "09308", value3: "09308", value4: "09308", name: "Vidalia Federal Savings Bank of GA (09308)" },
    { value: "33720", value2: "33720", value3: "33720", value4: "33720", name: "Viking Bank MN (33720)" },
    { value: "04284", value2: "04284", value3: "04284", value4: "04284", name: "Village Bank (04284)" },
    { value: "03220", value2: "03220", value3: "03220", value4: "03220", name: "Village Bank & Trust Bus (03220)" },
    { value: "03897", value2: "03897", value3: "03897", value4: "03897", name: "Village Bank & Trust IBB (03897)" },
    { value: "70460", value2: "70460", value3: "70460", value4: "70460", name: "Village Bank Bus CC (70460)" },
    { value: "02798", value2: "02798", value3: "02798", value4: "02798", name: "Village Bank Credit Card (02798)" },
    { value: "67910", value2: "67910", value3: "67910", value4: "67910", name: "Village Bank MN Business DC (67910)" },
    { value: "63645", value2: "63645", value3: "63645", value4: "63645", name: "Village Bank MN Business WC (63645)" },
    { value: "14808", value2: "14808", value3: "14808", value4: "14808", name: "Village Bank MN RO WC (14808)" },
    { value: "00602", value2: "00602", value3: "00602", value4: "00602", name: "Vintage Bank Kansas (00602)" },
    { value: "19662", value2: "19662", value3: "19662", value4: "19662", name: "Vinton County National Bank (19662)" },
    { value: "59649", value2: "59649", value3: "59649", value4: "59649", name: "Vinton County National Bank CC (59649)" },
    { value: "14695", value2: "14695", value3: "14695", value4: "14695", name: "Virginia CU (14695)" },
    { value: "68351", value2: "68351", value3: "68351", value4: "68351", name: "Virginia National Bank PFM (68351)" },
    { value: "19362", value2: "19362", value3: "19362", value4: "19362", name: "Virginia National Bank-Business (19362)" },
    { value: "04819", value2: "04819", value3: "04819", value4: "04819", name: "Vision Bank (04819)" },
    { value: "64305", value2: "64305", value3: "64305", value4: "64305", name: "Vision Bank of Iowa Credit Card (64305)" },
    { value: "00871", value2: "00871", value3: "00871", value4: "00871", name: "Vision One Credit Union (00871)" },
    { value: "59370", value2: "59370", value3: "59370", value4: "59370", name: "VisionBank - MN (59370)" },
    { value: "59373", value2: "59373", value3: "59373", value4: "59373", name: "VisionBank - MN Business (59373)" },
    { value: "16611", value2: "16611", value3: "16611", value4: "16611", name: "VisionBank Of Iowa (16611)" },
    { value: "70443", value2: "70443", value3: "70443", value4: "70443", name: "VisionBank of Iowa Bus CC (70443)" },
    { value: "00320", value2: "00320", value3: "00320", value4: "00320", name: "Visionary FCU credit card (00320)" },
    { value: "49518", value2: "49518", value3: "49518", value4: "49518", name: "Visions Federal Credit Union (49518)" },
    { value: "00581", value2: "00581", value3: "00581", value4: "00581", name: "Vista Bank TX (00581)" },
    { value: "06623", value2: "06623", value3: "06623", value4: "06623", name: "Vista Bank Treasury eServices (06623)" },
    { value: "45366", value2: "45366", value3: "45366", value4: "45366", name: "Vocality Community Credit Union (45366)" },
    { value: "04884", value2: "04884", value3: "04884", value4: "04884", name: "Volunteer Federal (04884)" },
    { value: "13716", value2: "13716", value3: "13716", value4: "13716", name: "Volunteer State Bank (13716)" },
    { value: "05501", value2: "05501", value3: "05501", value4: "05501", name: "Volunteer State Bank - DC (05501)" },
    { value: "09350", value2: "09350", value3: "09350", value4: "09350", name: "Voyage F.C.U. Credit Card (09350)" },
    { value: "63810", value2: "63810", value3: "63810", value4: "63810", name: "Voyage Federal Credit Union (63810)" },
    { value: "15538", value2: "15538", value3: "15538", value4: "15538", name: "VyStar Credit Union (15538)" },
    { value: "17974", value2: "17974", value3: "17974", value4: "17974", name: "WECU Direct Connect (17974)" },
    { value: "16933", value2: "16933", value3: "16933", value4: "16933", name: "WECU WebConnect (16933)" },
    { value: "17839", value2: "17839", value3: "17839", value4: "17839", name: "WESTconsin Business Connect QB WC (17839)" },
    { value: "12221", value2: "12221", value3: "12221", value4: "12221", name: "WESTconsin Credit Union CC (12221)" },
    { value: "15880", value2: "15880", value3: "15880", value4: "15880", name: "WESTconsin Online QB DC (15880)" },
    { value: "01095", value2: "01095", value3: "01095", value4: "01095", name: "WNB Financial (01095)" },
    { value: "07342", value2: "07342", value3: "07342", value4: "07342", name: "WSECU (07342)" },
    { value: "10531", value2: "10531", value3: "10531", value4: "10531", name: "WSFS Bank - Business (10531)" },
    { value: "69719", value2: "69719", value3: "69719", value4: "69719", name: "WTC Digital Business Banking (69719)" },
    { value: "67937", value2: "67937", value3: "67937", value4: "06653", name: "WaFd Bank - Direct Connect (67937)" },
    { value: "06653", value2: "06653", value3: "06653", value4: "06653", name: "WaFd Bank - Treasury (06653)" },
    { value: "03417", value2: "03417", value3: "03417", value4: "03417", name: "Waggoner National Bank (03417)" },
    { value: "07982", value2: "07982", value3: "07982", value4: "07982", name: "Wahoo State Bank - Business (07982)" },
    { value: "04730", value2: "04730", value3: "04730", value4: "04730", name: "Wakefield Co-operative Bank (04730)" },
    { value: "63078", value2: "63078", value3: "63078", value4: "63078", name: "Wakefield Co-operative Bank Bus (63078)" },
    { value: "69540", value2: "69540", value3: "69540", value4: "69540", name: "Walden Mutual Bank (69540)" },
    { value: "14193", value2: "14193", value3: "14193", value4: "14193", name: "Walden Savings Bank (14193)" },
    { value: "69839", value2: "69839", value3: "69839", value4: "69839", name: "Walden Savings Bank QBDC (69839)" },
    { value: "69527", value2: "69527", value3: "69527", value4: "69527", name: "Waldo State Bank (69527)" },
    { value: "55764", value2: "55764", value3: "55764", value4: "55764", name: "Walker County Federal Credit Union (55764)" },
    { value: "20148", value2: "20148", value3: "20148", value4: "20148", name: "Wallis Bank (20148)" },
    { value: "07316", value2: "07316", value3: "07316", value4: "07316", name: "Wallkill Valley Federal S&L (07316)" },
    { value: "53100", value2: "53100", value3: "53100", value4: "53100", name: "Wallkill Valley Federal S&L QBWC (53100)" },
    { value: "12543", value2: "12543", value3: "12543", value4: "12543", name: "Walpole Co-Operative Bank (12543)" },
    { value: "21588", value2: "21588", value3: "21588", value4: "21588", name: "Warren-Boynton State Bank (21588)" },
    { value: "70523", value2: "70523", value3: "70523", value4: "70523", name: "Warsaw Federal (70523)" },
    { value: "70524", value2: "70524", value3: "70524", value4: "70523", name: "Warsaw Federal DC (70524)" },
    { value: "07239", value2: "07239", value3: "07239", value4: "07239", name: "Wasatch Peaks Credit Union (07239)" },
    { value: "67805", value2: "67805", value3: "67805", value4: "67805", name: "Washington Business Bank (67805)" },
    { value: "19098", value2: "19098", value3: "19098", value4: "19098", name: "Washington Business Bank BB (19098)" },
    { value: "05437", value2: "05437", value3: "05437", value4: "05437", name: "Washington County Bank (05437)" },
    { value: "15867", value2: "15867", value3: "15867", value4: "15867", name: "Washington Financial Bank-Bus. (15867)" },
    { value: "10018", value2: "10018", value3: "10018", value4: "10018", name: "Washington Mutual Business Banking (10018)" },
    { value: "49755", value2: "49755", value3: "49755", value4: "49755", name: "Washington Savings Bank (49755)" },
    { value: "06560", value2: "06560", value3: "06560", value4: "06560", name: "Washington Savings Bank QB DC (06560)" },
    { value: "52092", value2: "52092", value3: "52092", value4: "52092", name: "Washington Savings Bank-IL (52092)" },
    { value: "06581", value2: "06581", value3: "06581", value4: "06581", name: "Washington State Bank - IA (06581)" },
    { value: "58986", value2: "58986", value3: "58986", value4: "58986", name: "Washington State Bank - LA (58986)" },
    { value: "57552", value2: "57552", value3: "57552", value4: "57552", name: "Washington Trust Bank - NT (57552)" },
    { value: "67225", value2: "67225", value3: "67225", value4: "67225", name: "Washington Trust Bank BDB DC (67225)" },
    { value: "67224", value2: "67224", value3: "67224", value4: "67224", name: "Washington Trust Bank BDB WC (67224)" },
    { value: "09944", value2: "09944", value3: "09944", value4: "09944", name: "Washington Trust Bank CC (09944)" },
    { value: "05735", value2: "05735", value3: "05735", value4: "05735", name: "Washington Trust Bank-DC (05735)" },
    { value: "70404", value2: "70404", value3: "70404", value4: "70404", name: "Washita State Bank (70404)" },
    { value: "70405", value2: "70405", value3: "70405", value4: "70404", name: "Washita State Bank - DC (70405)" },
    { value: "00770", value2: "00770", value3: "00770", value4: "00770", name: "Washita Valley Bank (00770)" },
    { value: "06124", value2: "06124", value3: "06124", value4: "06124", name: "Water and Power Community CU (06124)" },
    { value: "68649", value2: "68649", value3: "68649", value4: "68649", name: "Waterfall Bank (68649)" },
    { value: "68650", value2: "68650", value3: "68650", value4: "68650", name: "Waterfall Bank QBDC (68650)" },
    { value: "14919", value2: "14919", value3: "14919", value4: "14919", name: "Waterford Bank, N.A. (14919)" },
    { value: "07817", value2: "07817", value3: "07817", value4: "07817", name: "Watermark Bank (07817)" },
    { value: "11894", value2: "11894", value3: "11894", value4: "11894", name: "Watermark Bank DC (11894)" },
    { value: "67845", value2: "67845", value3: "67845", value4: "67845", name: "Watermark Bank-TM (67845)" },
    { value: "67849", value2: "67849", value3: "67849", value4: "67845", name: "Watermark Bank-TM-DC (67849)" },
    { value: "05204", value2: "05204", value3: "05204", value4: "05204", name: "Waterstone Bank-Business (05204)" },
    { value: "17097", value2: "17097", value3: "17097", value4: "17097", name: "Watertown Savings Bank (17097)" },
    { value: "63612", value2: "63612", value3: "63612", value4: "63612", name: "Watertown Savings Bank, MA (63612)" },
    { value: "69313", value2: "69313", value3: "69313", value4: "69313", name: "Wauchula State Bank FL Retail DC (69313)" },
    { value: "04118", value2: "04118", value3: "04118", value4: "04118", name: "Wauchula State Bank, FL (04118)" },
    { value: "21672", value2: "21672", value3: "21672", value4: "21672", name: "Waukesha State Bank (21672)" },
    { value: "70038", value2: "70038", value3: "70038", value4: "70038", name: "Waukon State Bank (70038)" },
    { value: "70235", value2: "70235", value3: "70235", value4: "70235", name: "Waukon State Bank BB-DC (70235)" },
    { value: "70039", value2: "70039", value3: "70039", value4: "70039", name: "Waukon State Bank QB-WC (70039)" },
    { value: "65487", value2: "65487", value3: "65487", value4: "65487", name: "Wauna CU Credit Card (65487)" },
    { value: "70373", value2: "70373", value3: "70373", value4: "70373", name: "Wauna CU- DC Bus (70373)" },
    { value: "67288", value2: "67288", value3: "67288", value4: "67288", name: "Wauna Federal CU DC (67288)" },
    { value: "17554", value2: "17554", value3: "17554", value4: "17554", name: "Wauna Federal CU QB WC (17554)" },
    { value: "34617", value2: "34617", value3: "34617", value4: "34617", name: "Waycross Bank & Trust (34617)" },
    { value: "03944", value2: "03944", value3: "03944", value4: "34617", name: "Waycross Bank & Trust-DL (03944)" },
    { value: "08450", value2: "08450", value3: "08450", value4: "08450", name: "Wayland State Bank (08450)" },
    { value: "67257", value2: "67257", value3: "67257", value4: "67257", name: "Wayne Bank Business Direct (67257)" },
    { value: "67256", value2: "67256", value3: "67256", value4: "67256", name: "Wayne Bank Business Web (67256)" },
    { value: "67258", value2: "67258", value3: "67258", value4: "67258", name: "Wayne Bank Personal Direct (67258)" },
    { value: "67255", value2: "67255", value3: "67255", value4: "67255", name: "Wayne Bank Personal Web (67255)" },
    { value: "00491", value2: "00491", value3: "00491", value4: "00491", name: "Wayne Savings Comm Bank - Business (00491)" },
    { value: "41106", value2: "41106", value3: "41106", value4: "41106", name: "Wayne Westland FCU (41106)" },
    { value: "02203", value2: "02203", value3: "02203", value4: "02203", name: "WaypointBank (02203)" },
    { value: "04983", value2: "04983", value3: "04983", value4: "04983", name: "We Florida Financial (04983)" },
    { value: "68510", value2: "68510", value3: "68510", value4: "68510", name: "WeStreet CU Business DC (68510)" },
    { value: "16960", value2: "16960", value3: "16960", value4: "16960", name: "WeStreet Credit Union (16960)" },
    { value: "16963", value2: "16963", value3: "16963", value4: "16963", name: "WeStreet Credit Union - QB (16963)" },
    { value: "69459", value2: "69459", value3: "69459", value4: "69459", name: "Webster Bank EWC (69459)" },
    { value: "50259", value2: "50259", value3: "50259", value4: "50259", name: "Webster First FCU Quickbooks (50259)" },
    { value: "09422", value2: "09422", value3: "09422", value4: "09422", name: "Webster Five - DC (09422)" },
    { value: "09413", value2: "09413", value3: "09413", value4: "09413", name: "Webster Five Bank (09413)" },
    { value: "62721", value2: "62721", value3: "62721", value4: "62721", name: "Webster SNB Business Online BOB (62721)" },
    { value: "01504", value2: "01504", value3: "01504", value4: "62721", name: "Webster SNB Business Online-BOB (01504)" },
    { value: "15361", value2: "15361", value3: "15361", value4: "15361", name: "Webster SNB Personal (15361)" },
    { value: "59283", value2: "59283", value3: "59283", value4: "59283", name: "Webster SNB e-Treasury Commercial (59283)" },
    { value: "67718", value2: "67718", value3: "67718", value4: "59283", name: "Webster SNB e-Treasury-DC (67718)" },
    { value: "49758", value2: "49758", value3: "49758", value4: "49758", name: "Welch State Bank (49758)" },
    { value: "69888", value2: "69888", value3: "69888", value4: "69888", name: "Welch State Bank - DC (69888)" },
    { value: "06050", value2: "06050", value3: "06050", value4: "06050", name: "Wellby (06050)" },
    { value: "14583", value2: "14583", value3: "14583", value4: "14583", name: "Wellington State Bank (14583)" },
    { value: "69838", value2: "69838", value3: "69838", value4: "69838", name: "Wellington State Bank - DC (69838)" },
    { value: "64161", value2: "64161", value3: "64161", value4: "64161", name: "Wells Bank - Platte City - MO (64161)" },
    { value: "03000", value2: "03000", value3: "03000", value4: "03000", name: "Wells Fargo Bank (03000)" },
    { value: "12721", value2: "12721", value3: "12721", value4: "03000", name: "Wells Fargo Bank - DirectConnect (12721)" },
    { value: "14323", value2: "14323", value3: "14323", value4: "14323", name: "Wells Fargo Business Elite Card (14323)" },
    { value: "04411", value2: "04411", value3: "04411", value4: "04411", name: "Wells Fargo CEO Basic Banking (04411)" },
    { value: "15762", value2: "15762", value3: "15762", value4: "15762", name: "Wells River Savings Bank (15762)" },
    { value: "01203", value2: "01203", value3: "01203", value4: "01203", name: "Weokie Credit Union (01203)" },
    { value: "69485", value2: "69485", value3: "69485", value4: "69485", name: "WesBanco Comm Banking EWC (69485)" },
    { value: "68487", value2: "68487", value3: "68487", value4: "68487", name: "WesBanco Commerical Banking (68487)" },
    { value: "68513", value2: "68513", value3: "68513", value4: "68513", name: "WesBanco Consumer Banking (68513)" },
    { value: "00160", value2: "00160", value3: "00160", value4: "00160", name: "West Aircomm Federal Credit Union (00160)" },
    { value: "09489", value2: "09489", value3: "09489", value4: "09489", name: "West Alabama Bank & Trust (09489)" },
    { value: "19473", value2: "19473", value3: "19473", value4: "19473", name: "West Bank (19473)" },
    { value: "23880", value2: "23880", value3: "23880", value4: "23880", name: "West Central Georgia Bank (23880)" },
    { value: "00261", value2: "00261", value3: "00261", value4: "00261", name: "West Community Credit Union (00261)" },
    { value: "15832", value2: "15832", value3: "15832", value4: "15832", name: "West End Bank (15832)" },
    { value: "17272", value2: "17272", value3: "17272", value4: "17272", name: "West Gate Bank - Business (17272)" },
    { value: "14007", value2: "14007", value3: "14007", value4: "14007", name: "West Gate Bank - Personal (14007)" },
    { value: "18096", value2: "18096", value3: "18096", value4: "18096", name: "West Michigan Community Bank (18096)" },
    { value: "07148", value2: "07148", value3: "07148", value4: "18096", name: "West Michigan Community Bank-DC (07148)" },
    { value: "51021", value2: "51021", value3: "51021", value4: "51021", name: "West Michigan Credit Union (51021)" },
    { value: "59199", value2: "59199", value3: "59199", value4: "59199", name: "West Plains Bank Ainsworth (59199)" },
    { value: "11693", value2: "11693", value3: "11693", value4: "11693", name: "West Plains Bank and Trust Co (11693)" },
    { value: "04184", value2: "04184", value3: "04184", value4: "04184", name: "West Point Bank (04184)" },
    { value: "03021", value2: "03021", value3: "03021", value4: "03021", name: "West Shore Bank (03021)" },
    { value: "63927", value2: "63927", value3: "63927", value4: "63927", name: "West Shore Bank - DL (63927)" },
    { value: "67144", value2: "67144", value3: "67144", value4: "67144", name: "West Shore Bank-TM (67144)" },
    { value: "67145", value2: "67145", value3: "67145", value4: "67144", name: "West Shore Bank-TM-DC (67145)" },
    { value: "11772", value2: "11772", value3: "11772", value4: "11772", name: "West Texas National Bank (11772)" },
    { value: "67282", value2: "67282", value3: "67282", value4: "67282", name: "West Texas National Bank - TCM (67282)" },
    { value: "15066", value2: "15066", value3: "15066", value4: "15066", name: "West Texas State Bank (15066)" },
    { value: "04361", value2: "04361", value3: "04361", value4: "04361", name: "West Town Bank & Trust (04361)" },
    { value: "06080", value2: "06080", value3: "06080", value4: "04361", name: "West Town Bank & Trust-DC (06080)" },
    { value: "12932", value2: "12932", value3: "12932", value4: "12932", name: "West Union Bank QBWC (12932)" },
    { value: "67081", value2: "67081", value3: "67081", value4: "67081", name: "West Union Bank WC (67081)" },
    { value: "12579", value2: "12579", value3: "12579", value4: "12579", name: "West Valley National Bank (12579)" },
    { value: "16980", value2: "16980", value3: "16980", value4: "16980", name: "West Virginia FCU (16980)" },
    { value: "11648", value2: "11648", value3: "11648", value4: "11648", name: "WestStar Bank (11648)" },
    { value: "68238", value2: "68238", value3: "68238", value4: "68238", name: "WestStar Bank QB DC (68238)" },
    { value: "10891", value2: "10891", value3: "10891", value4: "10891", name: "Westamerica Bank (10891)" },
    { value: "64473", value2: "64473", value3: "64473", value4: "64473", name: "Westamerica Bank- Web Connect QB (64473)" },
    { value: "69047", value2: "69047", value3: "69047", value4: "69047", name: "Westbury Bank Business (69047)" },
    { value: "37713", value2: "37713", value3: "37713", value4: "37713", name: "Westby Co-Op CU (37713)" },
    { value: "07920", value2: "07920", value3: "07920", value4: "07920", name: "Westerly Community Credit Union (07920)" },
    { value: "00653", value2: "00653", value3: "00653", value4: "00653", name: "Western Alliance Bank - Business (00653)" },
    { value: "00758", value2: "00758", value3: "00758", value4: "00758", name: "Western Alliance Bank - Direct (00758)" },
    { value: "18831", value2: "18831", value3: "18831", value4: "18831", name: "Western Bancshares (18831)" },
    { value: "67847", value2: "67847", value3: "67847", value4: "67847", name: "Western Bank Lordsburg (67847)" },
    { value: "67848", value2: "67848", value3: "67848", value4: "67848", name: "Western Bank Lordsburg - DC (67848)" },
    { value: "13633", value2: "13633", value3: "13633", value4: "13633", name: "Western Commerce Bank (13633)" },
    { value: "14148", value2: "14148", value3: "14148", value4: "14148", name: "Western Cooperative CU (14148)" },
    { value: "55569", value2: "55569", value3: "55569", value4: "55569", name: "Western Heritage Bank- WC QB (55569)" },
    { value: "40533", value2: "40533", value3: "40533", value4: "40533", name: "Western National Bank (40533)" },
    { value: "70520", value2: "70520", value3: "70520", value4: "70520", name: "Western Security Bank (70520)" },
    { value: "70506", value2: "70506", value3: "70506", value4: "70506", name: "Western Security Bank-DC (70506)" },
    { value: "68015", value2: "68015", value3: "68015", value4: "68015", name: "Western State Bank Direct Connect (68015)" },
    { value: "68809", value2: "68809", value3: "68809", value4: "68806", name: "Western State Bank ND-AZ Bus QBDC (68809)" },
    { value: "68806", value2: "68806", value3: "68806", value4: "68806", name: "Western State Bank ND-AZ Business (68806)" },
    { value: "18555", value2: "18555", value3: "18555", value4: "18555", name: "Western State Bank ND-AZ Personal (18555)" },
    { value: "64932", value2: "64932", value3: "64932", value4: "64932", name: "Western State Bank-Business (64932)" },
    { value: "00365", value2: "00365", value3: "00365", value4: "00365", name: "Western Sun FCU Credit Card (00365)" },
    { value: "23790", value2: "23790", value3: "23790", value4: "23790", name: "Western Vista FCU (23790)" },
    { value: "07484", value2: "07484", value3: "07484", value4: "07484", name: "Westerra CU Business Banking (07484)" },
    { value: "14013", value2: "14013", value3: "14013", value4: "14013", name: "Westerra Credit Union (14013)" },
    { value: "07981", value2: "07981", value3: "07981", value4: "07981", name: "Westfield Bank - Massachusetts (07981)" },
    { value: "06338", value2: "06338", value3: "06338", value4: "06338", name: "Westfield Bank FSB - New (06338)" },
    { value: "62190", value2: "62190", value3: "62190", value4: "06338", name: "Westfield Bank FSB - QB (62190)" },
    { value: "05668", value2: "05668", value3: "05668", value4: "05668", name: "Westmark Credit Union (05668)" },
    { value: "14061", value2: "14061", value3: "14061", value4: "14061", name: "Westminster FCU (14061)" },
    { value: "18399", value2: "18399", value3: "18399", value4: "18399", name: "Wexford Community Credit Union (18399)" },
    { value: "59136", value2: "59136", value3: "59136", value4: "59136", name: "Wheatland Bank Credit Card - Personal (59136)" },
    { value: "06695", value2: "06695", value3: "06695", value4: "06695", name: "Wheatland Bank WA - Direct (06695)" },
    { value: "06692", value2: "06692", value3: "06692", value4: "06692", name: "Wheatland Bank WA - Web (06692)" },
    { value: "06698", value2: "06698", value3: "06698", value4: "06698", name: "Wheatland Bank WA Premier - Web (06698)" },
    { value: "06701", value2: "06701", value3: "06701", value4: "06701", name: "Wheatland Bank WA Premier-Direct (06701)" },
    { value: "03211", value2: "03211", value3: "03211", value4: "03211", name: "Wheaton Bank & Trust Bus (03211)" },
    { value: "03891", value2: "03891", value3: "03891", value4: "03891", name: "Wheaton Bank & Trust IBB (03891)" },
    { value: "32475", value2: "32475", value3: "32475", value4: "32475", name: "Wheeler County State Bank (32475)" },
    { value: "20058", value2: "20058", value3: "20058", value4: "20058", name: "Whitaker Bank (20058)" },
    { value: "09041", value2: "09041", value3: "09041", value4: "09041", name: "Whitaker Bank - Business (09041)" },
    { value: "07844", value2: "07844", value3: "07844", value4: "07844", name: "Whitaker Bank Credit Card (07844)" },
    { value: "52755", value2: "52755", value3: "52755", value4: "52755", name: "White River CU (52755)" },
    { value: "62310", value2: "62310", value3: "62310", value4: "62310", name: "Whitefish Credit Union (62310)" },
    { value: "03161", value2: "03161", value3: "03161", value4: "03161", name: "Whitesville State Bank (03161)" },
    { value: "17980", value2: "17980", value3: "17980", value4: "17980", name: "Widget Financial (17980)" },
    { value: "50508", value2: "50508", value3: "50508", value4: "50508", name: "Wilcox County State Bank (50508)" },
    { value: "02331", value2: "02331", value3: "02331", value4: "02331", name: "Willamette Valley Bank (02331)" },
    { value: "67834", value2: "67834", value3: "67834", value4: "67834", name: "William Penn Bank BO QB DC (67834)" },
    { value: "09608", value2: "09608", value3: "09608", value4: "09608", name: "William Penn Bank, FSB-PA (09608)" },
    { value: "67325", value2: "67325", value3: "67325", value4: "67325", name: "William Penn Bank, FSB-PA Retail (67325)" },
    { value: "70424", value2: "70424", value3: "70424", value4: "70424", name: "Williamstown Bank Inc. (70424)" },
    { value: "70425", value2: "70425", value3: "70425", value4: "70424", name: "Williamstown Bank Inc. QBDC (70425)" },
    { value: "35316", value2: "35316", value3: "35316", value4: "35316", name: "Williamsville State Bank & Trust (35316)" },
    { value: "06353", value2: "06353", value3: "06353", value4: "06353", name: "Wilmington Savings Bank - BUS (06353)" },
    { value: "56805", value2: "56805", value3: "56805", value4: "56805", name: "Wilmington Savings Bank - RO (56805)" },
    { value: "00589", value2: "00589", value3: "00589", value4: "00589", name: "Wilson & Muir Bank and Trust Co (00589)" },
    { value: "67831", value2: "67831", value3: "67831", value4: "67831", name: "Wilson & Muir Bk & Trust Co QBDC (67831)" },
    { value: "01094", value2: "01094", value3: "01094", value4: "01094", name: "Wilson Bank & Trust - Business (01094)" },
    { value: "69443", value2: "69443", value3: "69443", value4: "69443", name: "Wilson Bank & Trust CC (69443)" },
    { value: "67461", value2: "67461", value3: "67461", value4: "01094", name: "Wilson Bank & Trust DC (67461)" },
    { value: "69299", value2: "69299", value3: "69299", value4: "69299", name: "Wilson Bank & Trust Retail (69299)" },
    { value: "69300", value2: "69300", value3: "69300", value4: "69299", name: "Wilson Bank & Trust Retail-DC (69300)" },
    { value: "69647", value2: "69647", value3: "69647", value4: "69647", name: "Wilson Bank & Trust-TM (69647)" },
    { value: "69648", value2: "69648", value3: "69648", value4: "69647", name: "Wilson Bank & Trust-TM-DC (69648)" },
    { value: "53613", value2: "53613", value3: "53613", value4: "53613", name: "Wilson State Bank (53613)" },
    { value: "70500", value2: "70500", value3: "70500", value4: "70500", name: "WinSouth CU AL (70500)" },
    { value: "67193", value2: "67193", value3: "67193", value4: "67193", name: "WinSouth CU AL WC (67193)" },
    { value: "62469", value2: "62469", value3: "62469", value4: "62469", name: "Winchester Cooperative Bank Bus (62469)" },
    { value: "62466", value2: "62466", value3: "62466", value4: "62466", name: "Winchester Cooperative Bank New (62466)" },
    { value: "64164", value2: "64164", value3: "64164", value4: "64164", name: "Winchester Savings - WC (64164)" },
    { value: "56136", value2: "56136", value3: "56136", value4: "56136", name: "Winchester Savings Bank (56136)" },
    { value: "10880", value2: "10880", value3: "10880", value4: "10880", name: "Windsor Federal Bank (10880)" },
    { value: "08765", value2: "08765", value3: "08765", value4: "08765", name: "Winnsboro State Bank & Trust Co. (08765)" },
    { value: "18006", value2: "18006", value3: "18006", value4: "18006", name: "Winston Salem FCU (18006)" },
    { value: "55857", value2: "55857", value3: "55857", value4: "18006", name: "Winston Salem FCU_QB (55857)" },
    { value: "03692", value2: "03692", value3: "03692", value4: "03692", name: "Winter Park National Bank (03692)" },
    { value: "03698", value2: "03698", value3: "03698", value4: "03692", name: "Winter Park National Bank - DL (03698)" },
    { value: "14160", value2: "14160", value3: "14160", value4: "14160", name: "Winthrop Area Federal Credit Union (14160)" },
    { value: "03879", value2: "03879", value3: "03879", value4: "03879", name: "Wintrust Bank IBB (03879)" },
    { value: "51711", value2: "51711", value3: "51711", value4: "51711", name: "Wisconsin Bank & Trust (51711)" },
    { value: "67789", value2: "67789", value3: "67789", value4: "67789", name: "Wisconsin River Bank (67789)" },
    { value: "70214", value2: "70214", value3: "70214", value4: "70214", name: "Wolf River Community Bank (70214)" },
    { value: "70215", value2: "70215", value3: "70215", value4: "70214", name: "Wolf River Community QBDC (70215)" },
    { value: "16156", value2: "16156", value3: "16156", value4: "16156", name: "Wood & Huston Bank QBO (16156)" },
    { value: "67114", value2: "67114", value3: "67114", value4: "67114", name: "WoodTrust Bank â€“ BeB WC (67114)" },
    { value: "08552", value2: "08552", value3: "08552", value4: "08552", name: "Woodford State Bank - BO - WC (08552)" },
    { value: "08681", value2: "08681", value3: "08681", value4: "08681", name: "Woodford State Bank Bus Direct (08681)" },
    { value: "08549", value2: "08549", value3: "08549", value4: "08549", name: "Woodford State Bank-WC (08549)" },
    { value: "03547", value2: "03547", value3: "03547", value4: "03547", name: "Woodforest (03547)" },
    { value: "15033", value2: "15033", value3: "15033", value4: "15033", name: "Woodlands Bank (15033)" },
    { value: "41910", value2: "41910", value3: "41910", value4: "41910", name: "Woodlands National Bank (41910)" },
    { value: "07479", value2: "07479", value3: "07479", value4: "07479", name: "Woodsboro Bank (07479)" },
    { value: "69617", value2: "69617", value3: "69617", value4: "69617", name: "Woodsfield Savings Bank - QBDC (69617)" },
    { value: "69618", value2: "69618", value3: "69618", value4: "69618", name: "Woodsfield Savings Bank - QBWC (69618)" },
    { value: "14763", value2: "14763", value3: "14763", value4: "14763", name: "Woodsville Guaranty Savings Bank (14763)" },
    { value: "18445", value2: "18445", value3: "18445", value4: "18445", name: "Woori America Bank (18445)" },
    { value: "68822", value2: "68822", value3: "68822", value4: "68822", name: "Woori America Bank-Bus QBDC (68822)" },
    { value: "57888", value2: "57888", value3: "57888", value4: "57888", name: "Workers' Credit Union WC QB (57888)" },
    { value: "14496", value2: "14496", value3: "14496", value4: "14496", name: "Worthington National Bank (14496)" },
    { value: "67551", value2: "67551", value3: "67551", value4: "67551", name: "Wray State Bank Business (67551)" },
    { value: "53439", value2: "53439", value3: "53439", value4: "53439", name: "Wray State Bank Retail (53439)" },
    { value: "17826", value2: "17826", value3: "17826", value4: "17826", name: "Wrentham Co-operative Bank (17826)" },
    { value: "67579", value2: "67579", value3: "67579", value4: "67579", name: "Wright-Patt CU DC Business (67579)" },
    { value: "13777", value2: "13777", value3: "13777", value4: "13777", name: "Wright-Patt Credit Union (13777)" },
    { value: "13798", value2: "13798", value3: "13798", value4: "13798", name: "Wright-Patt Credit Union WCQB (13798)" },
    { value: "14250", value2: "14250", value3: "14250", value4: "14250", name: "WyHy Federal Credit Union (14250)" },
    { value: "04458", value2: "04458", value3: "04458", value4: "04458", name: "Wyo Central FCU (04458)" },
    { value: "53481", value2: "53481", value3: "53481", value4: "04458", name: "Wyo Central FCU_DC (53481)" },
    { value: "69491", value2: "69491", value3: "69491", value4: "69491", name: "Wyoming Bank & Trust QBDC (69491)" },
    { value: "09500", value2: "09500", value3: "09500", value4: "09500", name: "Wyoming Community Bank New (09500)" },
    { value: "09506", value2: "09506", value3: "09506", value4: "09500", name: "Wyoming Community Bank WC (09506)" },
    { value: "00749", value2: "00749", value3: "00749", value4: "00749", name: "Wyoming Valley FCU Credit Card (00749)" },
    { value: "19833", value2: "19833", value3: "19833", value4: "19833", name: "Xcel FCU (19833)" },
    { value: "00164", value2: "00164", value3: "00164", value4: "00164", name: "Xcel FCU Credit Card (00164)" },
    { value: "19923", value2: "19923", value3: "19923", value4: "19923", name: "Y-12 FCU (19923)" },
    { value: "70078", value2: "70078", value3: "70078", value4: "70078", name: "Y-12 FCU Business DC (70078)" },
    { value: "70136", value2: "70136", value3: "70136", value4: "70136", name: "Y-12 FCU Quickbooks WC (70136)" },
    { value: "04511", value2: "04511", value3: "04511", value4: "04511", name: "YNB Oklahoma (04511)" },
    { value: "07142", value2: "07142", value3: "07142", value4: "07142", name: "YS Federal Credit Union (07142)" },
    { value: "00821", value2: "00821", value3: "00821", value4: "00821", name: "Yampa Valley Bank - Business (00821)" },
    { value: "00818", value2: "00818", value3: "00818", value4: "00818", name: "Yampa Valley Bank - Consumer (00818)" },
    { value: "01847", value2: "01847", value3: "01847", value4: "01847", name: "Yampa Valley Bank-Bus.WC (01847)" },
    { value: "01799", value2: "01799", value3: "01799", value4: "01799", name: "Yampa Valley Bank-DC QB Retail (01799)" },
    { value: "70116", value2: "70116", value3: "70116", value4: "70116", name: "Yantis Federal Credit Union (70116)" },
    { value: "12204", value2: "12204", value3: "12204", value4: "12204", name: "Yellowstone Bank (12204)" },
    { value: "12458", value2: "12458", value3: "12458", value4: "12458", name: "Yoakum National Bank (12458)" },
    { value: "12461", value2: "12461", value3: "12461", value4: "12458", name: "Yoakum National Bank - QBDC (12461)" },
    { value: "06119", value2: "06119", value3: "06119", value4: "06119", name: "Yolo Federal Credit Union (06119)" },
    { value: "05440", value2: "05440", value3: "05440", value4: "05440", name: "York State Bank (05440)" },
    { value: "20199", value2: "20199", value3: "20199", value4: "20199", name: "York Traditions Bank (20199)" },
    { value: "68568", value2: "68568", value3: "68568", value4: "68568", name: "Z-Client Service Center-MS WM (68568)" },
    { value: "14470", value2: "14470", value3: "14470", value4: "14470", name: "Z-Client Service Center-MSCM (14470)" },
    { value: "05783", value2: "05783", value3: "05783", value4: "05783", name: "ZZ ENOLATIPAC (05783)" },
    { value: "67540", value2: "67540", value3: "67540", value4: "67540", name: "ZZ FDMS SS and WSI PAuth (67540)" },
    { value: "69914", value2: "69914", value3: "69914", value4: "69914", name: "ZZ OGRAF SLLEW (69914)" },
    { value: "69501", value2: "69501", value3: "69501", value4: "69501", name: "ZZ-AFOB-DCP-M (69501)" },
    { value: "68132", value2: "68132", value3: "68132", value4: "68132", name: "ZZ-CMPJ-DCP-1 (68132)" },
    { value: "69915", value2: "69915", value3: "69915", value4: "69915", name: "ZZ-KNABSU-DC-NU (69915)" },
    { value: "69917", value2: "69917", value3: "69917", value4: "69917", name: "ZZ-RCNP-ytinas (69917)" },
    { value: "69677", value2: "69677", value3: "69677", value4: "69677", name: "ZZ-XEMA-XDF-YTINAS (69677)" },
    { value: "08350", value2: "08350", value3: "08350", value4: "08350", name: "ZZZ-Bank of Everett BBQB (08350)" },
    { value: "14830", value2: "14830", value3: "14830", value4: "14830", name: "Zeal Credit Union- WC QBO (14830)" },
    { value: "70089", value2: "70089", value3: "70089", value4: "70089", name: "Zenith Bank & Trust (70089)" },
    { value: "70090", value2: "70090", value3: "70090", value4: "70089", name: "Zenith Bank & Trust QBDC (70090)" },
    { value: "51795", value2: "51795", value3: "51795", value4: "51795", name: "Zing Credit Union (51795)" },
    { value: "11765", value2: "11765", value3: "11765", value4: "11765", name: "Zions Bank - WEB (11765)" },
    { value: "13495", value2: "13495", value3: "13495", value4: "13495", name: "Zions First National Bank - Direct (13495)" },
    { value: "55503", value2: "55503", value3: "55503", value4: "55503", name: "b1BANK (55503)" },
    { value: "69045", value2: "69045", value3: "69045", value4: "55503", name: "b1BANK QB DC (69045)" },
    { value: "14821", value2: "14821", value3: "14821", value4: "14821", name: "bankESB (14821)" },
    { value: "00278", value2: "00278", value3: "00278", value4: "14821", name: "bankESB SB (00278)" },
    { value: "63297", value2: "63297", value3: "63297", value4: "63297", name: "bankHometown (63297)" },
    { value: "63300", value2: "63300", value3: "63300", value4: "63297", name: "bankHometown - bus (63300)" },
    { value: "69757", value2: "69757", value3: "69757", value4: "12552", name: "bankcda QBDC (69757)" },
    { value: "67710", value2: "67710", value3: "67710", value4: "67710", name: "blueharbor Business DC (67710)" },
    { value: "08398", value2: "08398", value3: "08398", value4: "08398", name: "blueharbor bank (08398)" },
    { value: "67593", value2: "67593", value3: "67593", value4: "67593", name: "blueharbor bank Bus (67593)" },
    { value: "08828", value2: "08828", value3: "08828", value4: "08828", name: "brayn (08828)" },
    { value: "67155", value2: "67155", value3: "67155", value4: "67155", name: "broodii (67155)" },
    { value: "67226", value2: "67226", value3: "67226", value4: "67226", name: "broodii - DC (67226)" },
    { value: "13080", value2: "13080", value3: "13080", value4: "13080", name: "cPort Credit Union (13080)" },
    { value: "53370", value2: "53370", value3: "53370", value4: "53370", name: "eZBusinessCardManagement (53370)" },
    { value: "03148", value2: "03148", value3: "03148", value4: "03148", name: "https://www.monsonsavings.bank (03148)" },
    { value: "05105", value2: "05105", value3: "05105", value4: "05105", name: "i3 Bank (05105)" },
    { value: "65319", value2: "65319", value3: "65319", value4: "65319", name: "i3 Bank - Business (65319)" },
    { value: "68325", value2: "68325", value3: "68325", value4: "68325", name: "iBank Business - TN (68325)" },
    { value: "11204", value2: "11204", value3: "11204", value4: "11204", name: "iQ Credit Union (11204)" },
    { value: "12536", value2: "12536", value3: "12536", value4: "12536", name: "iTHINK Financial (12536)" },
    { value: "12539", value2: "12539", value3: "12539", value4: "12536", name: "iTHINK Financial QB (12539)" },
    { value: "69975", value2: "69975", value3: "69975", value4: "69975", name: "iThink Financial Business QBDC (69975)" },
    { value: "69916", value2: "69916", value3: "69916", value4: "69916", name: "itic_ytinas (69916)" },
    { value: "09958", value2: "09958", value3: "09958", value4: "09958", name: "kState Credit Union (09958)" },
    { value: "68494", value2: "68494", value3: "68494", value4: "68494", name: "mph.bank (68494)" },
    { value: "68495", value2: "68495", value3: "68495", value4: "68494", name: "mph.bank QB DC (68495)" },
    { value: "00137", value2: "00137", value3: "00137", value4: "00137", name: "test HAL (00137)" },
    { value: "05309", value2: "05309", value3: "05309", value4: "05309", name: "zOCG_Spl1 (05309)" },
    { value: "17494", value2: "17494", value3: "17494", value4: "17494", name: "zOCG_WC (17494)" },
    { value: "53877", value2: "53877", value3: "53877", value4: "53877", name: "zZz-Bankers Trust DM (53877)" },
    { value: "65067", value2: "65067", value3: "65067", value4: "65067", name: "zZz-Colombo Bk DC - Biz (65067)" },
    { value: "69526", value2: "69526", value3: "69526", value4: "69526", name: "zz itic - sanity (69526)" },
    { value: "58962", value2: "58962", value3: "58962", value4: "12099", name: "zzz - Marshland Credit Union_DC (58962)" },
    { value: "00140", value2: "00140", value3: "00140", value4: "00140", name: "zzz test HAL 0706 (00140)" },
    { value: "12411", value2: "12411", value3: "12411", value4: "12411", name: "zzz-1st Bank & Trust (12411)" },
    { value: "02824", value2: "02824", value3: "02824", value4: "02824", name: "zzz-1st National Bank & Trust (02824)" },
    { value: "15654", value2: "15654", value3: "15654", value4: "15654", name: "zzz-AccessBank Texas (15654)" },
    { value: "57255", value2: "57255", value3: "57255", value4: "57255", name: "zzz-Alerus Financial - Bus (57255)" },
    { value: "16723", value2: "16723", value3: "16723", value4: "16723", name: "zzz-Alerus Financial - Bus QBDC (16723)" },
    { value: "09417", value2: "09417", value3: "09417", value4: "09417", name: "zzz-AltaPacific Bank (09417)" },
    { value: "17248", value2: "17248", value3: "17248", value4: "17248", name: "zzz-AltaPacific Bank QB DC (17248)" },
    { value: "61722", value2: "61722", value3: "61722", value4: "61722", name: "zzz-American Express @ Work (61722)" },
    { value: "69907", value2: "69907", value3: "69907", value4: "69907", name: "zzz-American Express Card - QBDT (69907)" },
    { value: "19002", value2: "19002", value3: "19002", value4: "19002", name: "zzz-Ann Arbor State Bank (19002)" },
    { value: "51717", value2: "51717", value3: "51717", value4: "51717", name: "zzz-Arizona Bank and Trust BB WC (51717)" },
    { value: "11800", value2: "11800", value3: "11800", value4: "11800", name: "zzz-Atlantic Stewardship Bk (11800)" },
    { value: "17205", value2: "17205", value3: "17205", value4: "17205", name: "zzz-Atlantic Stewardship Bk QB (17205)" },
    { value: "51435", value2: "51435", value3: "51435", value4: "51435", name: "zzz-Ball State FCU (51435)" },
    { value: "05339", value2: "05339", value3: "05339", value4: "05339", name: "zzz-Bancredito International Bk (05339)" },
    { value: "07587", value2: "07587", value3: "07587", value4: "07587", name: "zzz-Biscayne Bank (07587)" },
    { value: "20328", value2: "20328", value3: "20328", value4: "20328", name: "zzz-BkFirst Financial Services BB (20328)" },
    { value: "67153", value2: "67153", value3: "67153", value4: "67153", name: "zzz-Blackhawk Bank-Bus (67153)" },
    { value: "62817", value2: "62817", value3: "62817", value4: "62817", name: "zzz-Brighton Bank-QB-DC (62817)" },
    { value: "07793", value2: "07793", value3: "07793", value4: "07793", name: "zzz-CBTFL (07793)" },
    { value: "53571", value2: "53571", value3: "53571", value4: "53571", name: "zzz-California United Bk - DC (53571)" },
    { value: "01941", value2: "01941", value3: "01941", value4: "01941", name: "zzz-Canopy CU QKWC (01941)" },
    { value: "57048", value2: "57048", value3: "57048", value4: "57048", name: "zzz-Cedar Point FCU (57048)" },
    { value: "63471", value2: "63471", value3: "63471", value4: "63471", name: "zzz-Citizens State Bk of Lanki (63471)" },
    { value: "64188", value2: "64188", value3: "64188", value4: "64188", name: "zzz-Clark County CU Bus_DC (64188)" },
    { value: "64182", value2: "64182", value3: "64182", value4: "64182", name: "zzz-Clark County CU Business (64182)" },
    { value: "10744", value2: "10744", value3: "10744", value4: "10744", name: "zzz-Clarkston State Bank (10744)" },
    { value: "04624", value2: "04624", value3: "04624", value4: "04624", name: "zzz-Coastway CU- WCQB (04624)" },
    { value: "64074", value2: "64074", value3: "64074", value4: "64074", name: "zzz-Colorado National Bk (64074)" },
    { value: "20172", value2: "20172", value3: "20172", value4: "20172", name: "zzz-Commerce Bk-IN-WC (20172)" },
    { value: "51735", value2: "51735", value3: "51735", value4: "51735", name: "zzz-Continental National Bank (51735)" },
    { value: "52986", value2: "52986", value3: "52986", value4: "52986", name: "zzz-Customers Bk QB Bus (52986)" },
    { value: "11314", value2: "11314", value3: "11314", value4: "11314", name: "zzz-DNB First (11314)" },
    { value: "35502", value2: "35502", value3: "35502", value4: "35502", name: "zzz-DNB First Business (35502)" },
    { value: "12594", value2: "12594", value3: "12594", value4: "12594", name: "zzz-Damascus Community Bank (12594)" },
    { value: "15493", value2: "15493", value3: "15493", value4: "15493", name: "zzz-Deer Valley CU (15493)" },
    { value: "12613", value2: "12613", value3: "12613", value4: "12613", name: "zzz-Discover Bank QB WC (12613)" },
    { value: "01818", value2: "01818", value3: "01818", value4: "01818", name: "zzz-ELGA Credit Union (01818)" },
    { value: "63741", value2: "63741", value3: "63741", value4: "63741", name: "zzz-Equitable Bank (63741)" },
    { value: "01162", value2: "01162", value3: "01162", value4: "01162", name: "zzz-Excel Bank Minnesota (01162)" },
    { value: "51090", value2: "51090", value3: "51090", value4: "51090", name: "zzz-FNB Bk - Bus Banking (51090)" },
    { value: "17314", value2: "17314", value3: "17314", value4: "05275", name: "zzz-Farmers & Merchants Bk QB (17314)" },
    { value: "05275", value2: "05275", value3: "05275", value4: "05275", name: "zzz-Farmers & Merchants Bk-LB (05275)" },
    { value: "68643", value2: "68643", value3: "68643", value4: "68643", name: "zzz-Farmers National Bk-PA BO QB (68643)" },
    { value: "12059", value2: "12059", value3: "12059", value4: "12059", name: "zzz-First American Bk - DC (12059)" },
    { value: "63819", value2: "63819", value3: "63819", value4: "63819", name: "zzz-First American Na Bk - MS (63819)" },
    { value: "63822", value2: "63822", value3: "63822", value4: "63822", name: "zzz-First American National Bk-MS (63822)" },
    { value: "04623", value2: "04623", value3: "04623", value4: "04623", name: "zzz-First Bk of the Palm Beaches (04623)" },
    { value: "05375", value2: "05375", value3: "05375", value4: "05375", name: "zzz-First Ipswich Bank WC (05375)" },
    { value: "03878", value2: "03878", value3: "03878", value4: "03878", name: "zzz-First Ipswich Bk DC (03878)" },
    { value: "01657", value2: "01657", value3: "01657", value4: "01657", name: "zzz-First Ipswich Bk WC (01657)" },
    { value: "02049", value2: "02049", value3: "02049", value4: "02049", name: "zzz-First Mid Bank & Trust (02049)" },
    { value: "05151", value2: "05151", value3: "05151", value4: "05151", name: "zzz-First Midwest Bank - Bus (05151)" },
    { value: "52377", value2: "52377", value3: "52377", value4: "52377", name: "zzz-First National Bank Pawnee (52377)" },
    { value: "05758", value2: "05758", value3: "05758", value4: "05758", name: "zzz-First National Bk in Staunton (05758)" },
    { value: "62415", value2: "62415", value3: "62415", value4: "62415", name: "zzz-First Ntl Bk Lg Island DC (62415)" },
    { value: "62412", value2: "62412", value3: "62412", value4: "62412", name: "zzz-First Ntnl Bank Long (62412)" },
    { value: "63249", value2: "63249", value3: "63249", value4: "63249", name: "zzz-First State Bk - QB (63249)" },
    { value: "01526", value2: "01526", value3: "01526", value4: "01526", name: "zzz-FirstCapital Bk of Texas (01526)" },
    { value: "18159", value2: "18159", value3: "18159", value4: "18159", name: "zzz-Forward Financial Bank (18159)" },
    { value: "63774", value2: "63774", value3: "63774", value4: "63774", name: "zzz-Frederick County Bk - BOB (63774)" },
    { value: "06332", value2: "06332", value3: "06332", value4: "06332", name: "zzz-Grand Bk - NJ (06332)" },
    { value: "18156", value2: "18156", value3: "18156", value4: "18156", name: "zzz-Grand Mountain Bk, FSB (18156)" },
    { value: "18180", value2: "18180", value3: "18180", value4: "18180", name: "zzz-Holladay Bank & Trust (18180)" },
    { value: "62520", value2: "62520", value3: "62520", value4: "62520", name: "zzz-Hughes FCU QB (62520)" },
    { value: "59022", value2: "59022", value3: "59022", value4: "59022", name: "zzz-IBM Southeast ECU -QB (59022)" },
    { value: "58620", value2: "58620", value3: "58620", value4: "58620", name: "zzz-Icon Credit Un (58620)" },
    { value: "06131", value2: "06131", value3: "06131", value4: "06131", name: "zzz-InTouch CU QB DC (06131)" },
    { value: "04613", value2: "04613", value3: "04613", value4: "04613", name: "zzz-Inter  Finance Bk-DC (04613)" },
    { value: "51267", value2: "51267", value3: "51267", value4: "51267", name: "zzz-International Finance Bk (51267)" },
    { value: "56520", value2: "56520", value3: "56520", value4: "56520", name: "zzz-Iowa State Bk-DC (56520)" },
    { value: "64857", value2: "64857", value3: "64857", value4: "64857", name: "zzz-Landmark Bk Bus MO (64857)" },
    { value: "00085", value2: "00085", value3: "00085", value4: "00085", name: "zzz-Lexus Financial Savings Bk (00085)" },
    { value: "65382", value2: "65382", value3: "65382", value4: "65382", name: "zzz-Lighthouse Bank - DC (65382)" },
    { value: "06584", value2: "06584", value3: "06584", value4: "06584", name: "zzz-Louisa Community Bank - KY (06584)" },
    { value: "02825", value2: "02825", value3: "02825", value4: "02825", name: "zzz-MVB Bank (02825)" },
    { value: "02828", value2: "02828", value3: "02828", value4: "02828", name: "zzz-MVB Bank - DL (02828)" },
    { value: "53751", value2: "53751", value3: "53751", value4: "53751", name: "zzz-Macatawa Bank - Personal (53751)" },
    { value: "06358", value2: "06358", value3: "06358", value4: "06358", name: "zzz-Malvern Fed Savings WCQBBB (06358)" },
    { value: "04526", value2: "04526", value3: "04526", value4: "04526", name: "zzz-Modern Bank QBDC (04526)" },
    { value: "00466", value2: "00466", value3: "00466", value4: "00466", name: "zzz-Nantucket Bank (00466)" },
    { value: "11395", value2: "11395", value3: "11395", value4: "11395", name: "zzz-Nationwide Bank (11395)" },
    { value: "03274", value2: "03274", value3: "03274", value4: "03274", name: "zzz-Navigant CU Bus QB (03274)" },
    { value: "56310", value2: "56310", value3: "56310", value4: "56310", name: "zzz-New Orleans FCU QB (56310)" },
    { value: "20406", value2: "20406", value3: "20406", value4: "20406", name: "zzz-Oak Bank (20406)" },
    { value: "09519", value2: "09519", value3: "09519", value4: "09519", name: "zzz-Old Line Bk (09519)" },
    { value: "50904", value2: "50904", value3: "50904", value4: "50904", name: "zzz-Old Line-Bus Electronic (50904)" },
    { value: "20184", value2: "20184", value3: "20184", value4: "20184", name: "zzz-Oritani Bank (20184)" },
    { value: "11767", value2: "11767", value3: "11767", value4: "11767", name: "zzz-Ouachita Independent Bank (11767)" },
    { value: "12391", value2: "12391", value3: "12391", value4: "12391", name: "zzz-Penn Community Bank BB QB (12391)" },
    { value: "37218", value2: "37218", value3: "37218", value4: "37218", name: "zzz-Peoples First Savings Bk OH (37218)" },
    { value: "56202", value2: "56202", value3: "56202", value4: "56202", name: "zzz-Peoples First Savings Bk_DC (56202)" },
    { value: "06147", value2: "06147", value3: "06147", value4: "06147", name: "zzz-Peoples National Bank -FL (06147)" },
    { value: "04846", value2: "04846", value3: "04846", value4: "04846", name: "zzz-Presidio Bank Bus Banking QB (04846)" },
    { value: "13519", value2: "13519", value3: "13519", value4: "13519", name: "zzz-Presidio Pers & Small Bus (13519)" },
    { value: "11177", value2: "11177", value3: "11177", value4: "11177", name: "zzz-Prosperity Bank (11177)" },
    { value: "54009", value2: "54009", value3: "54009", value4: "54009", name: "zzz-Prosperity Bank - DC (54009)" },
    { value: "18598", value2: "18598", value3: "18598", value4: "18598", name: "zzz-QBD-Disable DCard (18598)" },
    { value: "01001", value2: "01001", value3: "01001", value4: "01001", name: "zzz-QuickBooks Bill  Pay (01001)" },
    { value: "02975", value2: "02975", value3: "02975", value4: "02975", name: "zzz-Relyance Bank, N.A.-DC (02975)" },
    { value: "23085", value2: "23085", value3: "23085", value4: "23085", name: "zzz-Republic Bank - MN (23085)" },
    { value: "03664", value2: "03664", value3: "03664", value4: "03664", name: "zzz-Republic Bk - Norman, OK (03664)" },
    { value: "06973", value2: "06973", value3: "06973", value4: "06973", name: "zzz-Resolute Bank (06973)" },
    { value: "13959", value2: "13959", value3: "13959", value4: "13959", name: "zzz-Richland State Bank (13959)" },
    { value: "09616", value2: "09616", value3: "09616", value4: "09616", name: "zzz-Rockford Bank & Trust Retail (09616)" },
    { value: "06863", value2: "06863", value3: "06863", value4: "06863", name: "zzz-Rockford Bk & Trust Retail QB (06863)" },
    { value: "18019", value2: "18019", value3: "18019", value4: "18019", name: "zzz-Rockford Bk and Trust - QBDC (18019)" },
    { value: "51720", value2: "51720", value3: "51720", value4: "51720", name: "zzz-Rocky Mountain Bk BB WC (51720)" },
    { value: "59337", value2: "59337", value3: "59337", value4: "59337", name: "zzz-SB One - Business (59337)" },
    { value: "62748", value2: "62748", value3: "62748", value4: "62748", name: "zzz-Seattle Bank-Biz (62748)" },
    { value: "18079", value2: "18079", value3: "18079", value4: "18079", name: "zzz-Seattle Bk DC (18079)" },
    { value: "18082", value2: "18082", value3: "18082", value4: "18082", name: "zzz-Seattle Bk-Biz DC (18082)" },
    { value: "05432", value2: "05432", value3: "05432", value4: "05432", name: "zzz-Sequoia Federal CU- QB (05432)" },
    { value: "05378", value2: "05378", value3: "05378", value4: "05378", name: "zzz-Southwest Bk (05378)" },
    { value: "09583", value2: "09583", value3: "09583", value4: "09583", name: "zzz-Southwest Bk IBWC QB (09583)" },
    { value: "03399", value2: "03399", value3: "03399", value4: "03399", name: "zzz-State Bk of Arizona (03399)" },
    { value: "04200", value2: "04200", value3: "04200", value4: "04200", name: "zzz-State Bk of Arizona - Direct (04200)" },
    { value: "10910", value2: "10910", value3: "10910", value4: "10910", name: "zzz-Surrey Bank & Trust (10910)" },
    { value: "50238", value2: "50238", value3: "50238", value4: "50238", name: "zzz-Texas First State Bk (50238)" },
    { value: "57570", value2: "57570", value3: "57570", value4: "57570", name: "zzz-Trinity Bank USA (57570)" },
    { value: "40176", value2: "40176", value3: "40176", value4: "40176", name: "zzz-TruPoint Bank (40176)" },
    { value: "10800", value2: "10800", value3: "10800", value4: "10800", name: "zzz-UniBank for Savings (10800)" },
    { value: "52833", value2: "52833", value3: "52833", value4: "52833", name: "zzz-Unity Bank (52833)" },
    { value: "57492", value2: "57492", value3: "57492", value4: "57492", name: "zzz-Unity Bank North (57492)" },
    { value: "67297", value2: "67297", value3: "67297", value4: "67297", name: "zzz-WaunaFCU (67297)" },
]

export default quickbooksBIDs;
