import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    Typography,
    Skeleton,
    IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Visa from "../../assets/visa.svg";
import Mastercard from "../../assets/mastercard.svg";
import Amex from "../../assets/amex.svg";
import Discover from "../../assets/discover.svg";
import Diners from "../../assets/diners.svg";
import JCB from "../../assets/jcb.svg";
import UnionPay from "../../assets/unionpay.svg";
import { useCallback, useEffect, useState } from "react";
import GridUI from "@mui/material/Grid";
import { Grid, Table, TableHeaderRow, PagingPanel, Toolbar, SearchPanel } from "@devexpress/dx-react-grid-material-ui";
import { SearchState, IntegratedFiltering, PagingState, IntegratedPaging } from "@devexpress/dx-react-grid";
import { styled } from "@mui/material/styles";
import AddPaymentDialog from "../payments/AddPaymentDialog";
import BaseSnackbar from "../ui/BaseSnackbar";
import EditPaymentDialog from "../payments/EditPayDialog";
import SimpleBackdrop from "../ui/SimpleBackdrop";
import FiltersButton from "./FiltersButtonPayments";
import LightTooltip from "../ui/LightTooltip";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Invoice } from "./Invoice";
import customClasses from "./SubscriptionTab2.module.css";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";
import subscriptionService from "../../services/subscription";
import payService from "../../services/payment_methods";
import companyService from "../../services/company";

import { useDispatch, useSelector } from "react-redux";
import { addCredits, addSubscription } from "../../redux/userSlice";

import moment from "moment";
import AlertDialog from "../ui/AlertDialog";
import SimpleDialog from "../ui/SimpleDialog";

const PREFIX = "PaymentMethods";

const classes = {
    tableHeader: `${PREFIX}-tableHeader`,
    tableBody: `${PREFIX}-tableBody`,
    pager: `${PREFIX}-pager`,
    toolbarSearch: `${PREFIX}-toolbarSearch`,
    searchInput: `${PREFIX}-searchInput`,
    tableHeaderBand: `${PREFIX}-tableHeaderBand`,
};

const StyledContainer = styled(Box)(({ theme }) => ({
    [`& .${classes.tableHeaderBand}`]: {
        backgroundColor: "transparent",
        color: "#4a22d4",
        fontSize: "14px",
        fontWeight: 600,
        border: "none !important",
        textAlign: "center",
        paddingBottom: 0,
        [theme.breakpoints.up("md")]: {
            whiteSpace: "pre-wrap",
        },
    },

    [`& .${classes.tableHeader}`]: {
        border: "none",
        backgroundColor: "rgb(241, 241, 243)",
        fontSize: "15px",
        fontWeight: 600,
        color: "#131F3E !important",
        "&:first-of-type": {
            borderRadius: "20px 0 0 0",
        },
        "&:last-child": {
            borderRadius: "0 20px 0 0",
            paddingRight: "24px",
        },
    },

    [`& .${classes.tableHeader} div`]: {
        justifyContent: "center !important",
    },

    [`& .${classes.tableBody}`]: {
        backgroundColor: "#FFF",
        borderBottom: "3px solid #f8f8f9",
        fontSize: "14px",
        fontWeight: 400,
        color: "#131f3e",
        "&:first-of-type": {
            borderRadius: "5px 0 0 5px",
        },
        "&:last-child": {
            borderRadius: "0 5px 5px 0",
        },
        paddingTop: "0px",
        paddingBottom: "0px",
    },

    [`& .${classes.pager}`]: {
        "& .Pagination-button": {
            paddingTop: 0,
            paddingBottom: 0,
            color: "#031851",
            fontWeight: 600,
        },
        "& .Pagination-activeButton": {
            backgroundColor: "rgba(47,49,144,0.3)",
        },
    },

    [`& .${classes.toolbarSearch}`]: {
        border: "none !important",
        padding: "0 0 5px !important",
    },

    [`& .${classes.searchInput}`]: {
        color: "#131F3E",
        backgroundColor: "#ececed",
        borderRadius: 8,
        borderBottom: "none",
        paddingTop: "11px",
        paddingBottom: "11px",
        paddingLeft: "20px",
        paddingRight: "40px",
        marginBottom: "35px",
        "&:before": {
            borderBottom: "none",
            color: "#131F3E",
        },
        "&:hover:before": {
            borderBottom: "none",
            color: "#131F3E",
        },
    },
}));

const rowsSkeleton = [];
const rowsSkeletonPayments = [];

for (let i = 0; i < 15; i++) {
    rowsSkeleton.push({
        id: i,
        date: <Skeleton variant="text" animation="wave" />,
        number: <Skeleton variant="text" animation="wave" />,
        concept: <Skeleton variant="text" animation="wave" />,
        period: <Skeleton variant="text" animation="wave" />,
        card: <Skeleton variant="text" animation="wave" />,
        download: <Skeleton variant="text" animation="wave" />,
    });

    rowsSkeletonPayments.push({
        id: i,
        payments: <Skeleton variant="text" animation="wave" />,
    });
}

const tableHeaderCell = ({ ...props }) => <TableHeaderRow.Cell {...props} className={classes.tableHeader} />;

const TableCellComponent = ({ ...props }) => <Table.Cell {...props} className={classes.tableBody} />;

const PagingPanelContainer = ({ ...props }) => <PagingPanel.Container {...props} className={classes.pager} />;

const inputComponent = ({ ...props }) => <SearchPanel.Input {...props} className={classes.searchInput} />;

const SubscriptionTab2 = (props) => {
    const { payments, reloadData, showSkeleton, franchise_id, company_id } = props;
    const [t] = useTranslation("global");
    const [selectedValue, setSelectedValue] = useState("");
    const [showAddPay, setShowAddPay] = useState(false);
    const [showEditPay, setShowEditPay] = useState(false);
    const [cardAddress, setCardAddress] = useState({});
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState(rowsSkeleton);
    const [openBill, setOpenBill] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const [pageSizes] = useState([15, 30, 50]);
    const [currentPage, setCurrentPage] = useState(0);
    const [invoiceInfo, setInvoiceInfo] = useState(null);
    const [cardENum, setCardENum] = useState();
    const [reload, setReload] = useState(false);
    const [openRetryModal, setOpenRetryModal] = useState(false);
    const [invoiceDetails, setInvoiceDetails] = useState({});

    const todos = useSelector((state) => state.value);

    var userEmail = "";
    if (todos) {
        if (todos.userInfo) {
            if (todos.userInfo.email) {
                userEmail = todos.userInfo.email;
            }
        }
    }

    const dispatch = useDispatch();

    const handleChange = async (event) => {
        setSelectedValue(event.target.value);

        const params = {
            payment_id: event.target.value,
            default_method: true,
        };

        setIsLoading(true);

        await payService
            .updateDefaultMethod(params)
            .then(() => reloadData())
            .catch((err) => console.log(err));

        setIsLoading(false);
    };

    const handleAddPaymentM = () => {
        setShowAddPay(true);
    };

    const handleOpenEdit = (value) => {
        setCardAddress(value);
        setShowEditPay(true);
    };

    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            setShowEditPay(false);
            setCardAddress({});
        }
    };

    /** Component functions */
    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    useEffect(() => {
        if (payments.length > 0) {
            setSelectedValue(payments.find((item) => item.default_method)._id);
        } else {
            setSelectedValue("");
        }
    }, [payments]);

    const handleSearch = (value) => {
        let itemS = value.replace(/ +(?= )/g, "");
        setSearchState(itemS);
    };

    const columns = [
        { name: "date", title: t("payment.date") },
        { name: "number", title: t("converter.number") },
        { name: "concept", title: t("payment.description") },
        { name: "period", title: t("payment.period") },
        { name: "card", title: t("payment.paymentMethod") },
        { name: "download", title: t("payment.download") },
    ];

    const tableColumnExtensions = [
        { columnName: "date", width: "15%", align: "center" },
        { columnName: "number", width: "12%", align: "center" },
        { columnName: "concept", width: "25%", align: "left" },
        { columnName: "period", width: "20%", align: "center" },
        { columnName: "card", align: "center" },
        { columnName: "download", align: "center" },
    ];

    const [searchValue, setSearchState] = useState("");

    const tableMessages = {
        noData: <Typography fontSize={14}>{t("dashboard.noInfo")}</Typography>,
    };

    const pagingMessages = {
        info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ""} ${t("dashboard.pagingOf")} ${count}`,
        rowsPerPage: t("dashboard.rowsPerPage"),
    };

    const searchMessages = {
        searchPlaceholder: t("dashboard.search"),
    };

    const handleOpenBill = async (action, invoice) => {
        if (action === "open") {
            await subscriptionService
                .getInvoiceDetails({ invoice_id: invoice })
                .then((data) => {
                    setInvoiceInfo(data);
                    setOpenBill((prevState) => !prevState);
                })
                .catch((err) => console.log(err));
        } else {
            setOpenBill((prevState) => !prevState);
        }
    };

    const retryPayment = async () => {
        setIsLoading(true);

        const search = payments.find((item) => item._id === invoiceDetails.payment_method_id);

        await payService
            .retryPayment({
                invoice_id: invoiceDetails._id,
                customer_id: search.customer_id,
                payment_id: search.payment_id,
                franchise_id: todos.franchiseID,
            })
            .then(async () => {
                setReload((prev) => !prev);

                await subscriptionService
                    .getFranchiseCredits({ franchise_id: todos.franchiseID })
                    .then((response) => dispatch(addCredits(response)))
                    .catch((err) => console.log(err));

                await companyService
                    .getFranchiseDetails(todos.franchiseID)
                    .then((data) => {
                        dispatch(
                            addSubscription({
                                value: data.id_status,
                                last_value: data.id_last_status,
                                plan: data.subscription_plan,
                            })
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                setAlertType("success");
                setMessage(t("dialog.successRePay"));
                setShow(true);
            })
            .catch((err) => {
                setAlertType("error");
                setMessage(err.response.data.message);
                setShow(true);
            });

        handleCloseDialog();
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            const plansInfo = [
                {
                    planId: 1,
                    credits: 5,
                },
                {
                    planId: 2,
                    credits: 15,
                },
                {
                    planId: 3,
                    credits: 30,
                },
                {
                    planId: 4,
                    credits: 50,
                },
                {
                    planId: 5,
                    credits: 200,
                },
                {
                    planId: 6,
                    credits: 500,
                },
            ];

            const handleRetry = (invoice) => {
                setOpenRetryModal(true);
                setInvoiceDetails(invoice);
            };

            const printDocument = async (invoice) => {
                setIsLoading(true);
                await subscriptionService
                    .generateInvoice({ invoice_id: invoice._id })
                    .then(async (response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "Invoice #" + invoice.invoice_number + ".pdf");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    })
                    .catch((err) => console.log(err));
                setIsLoading(false);
            };

            const getConcept = (concept) => {
                let finalConcept = [];
                for (let i = 0; i < concept.length; i++) {
                    if (concept[i].type === 1) {
                        finalConcept.push(
                            t("subscription.activation") +
                                " Plan " +
                                plansInfo.find((item) => item.planId === concept[i].value).credits
                        );
                    } else if (concept[i].type === 2) {
                        finalConcept.push(
                            t("subscription.renewal") +
                                " Plan " +
                                plansInfo.find((item) => item.planId === concept[i].value).credits
                        );
                    } else if (concept[i].type === 3 || concept[i].type === 4) {
                        finalConcept.push(
                            t("payment.update") +
                                " Plan " +
                                plansInfo.find((item) => item.planId === concept[i].value).credits
                        );
                    } else {
                        finalConcept.push(
                            t("credits.buySingleCred").charAt(0).toUpperCase() +
                                t("credits.buySingleCred").slice(1) +
                                " " +
                                concept[i].value
                        );
                    }
                }
                return finalConcept.join(" + ");
            };

            const getPeriod = (invoice) => {
                let finalPeriod = "";
                let getAllTypes = invoice.invoice_description.map((item) => item.type);

                if (getAllTypes.some((i) => [1, 2, 3, 4].includes(i))) {
                    finalPeriod =
                        moment(invoice.since_date, "YYYY-MM-DD").format(todos.dateFormat) +
                        " - " +
                        moment(invoice.end_date, "YYYY-MM-DD").format(todos.dateFormat);
                } else {
                    finalPeriod = moment(invoice.creation_date, "YYYY-MM-DD").format(todos.dateFormat);
                }

                return finalPeriod;
            };

            await subscriptionService
                .getInvoices({ franchise_id: todos.franchiseID })
                .then((response) => {
                    const rowsData = response.map((item) => {
                        return {
                            id: item._id,
                            date: moment(item.creation_date, "YYYY-MM-DD").format(todos.dateFormat),
                            number: (
                                <Button onClick={() => handleOpenBill("open", item._id)}>{item.invoice_number}</Button>
                            ),
                            concept:
                                item.invoice_description !== "" ? (
                                    <>
                                        <LightTooltip title={getConcept(item.invoice_description)} position="bottom">
                                            <span>{getConcept(item.invoice_description)}</span>
                                        </LightTooltip>
                                    </>
                                ) : (
                                    ""
                                ),
                            period:
                                item.invoice_description !== ""
                                    ? getPeriod(item)
                                    : moment(item.creation_date, "YYYY-MM-DD").format(todos.dateFormat),
                            card:
                                item.brand && item.card_number
                                    ? `${item.brand ? item.brand.charAt(0).toUpperCase() + item.brand.slice(1) : ""} ${
                                          item.card_number ? "*" + item.card_number.split("-").pop() : ""
                                      }`
                                    : t("payment.withoutInfo"),
                            payment_id: item.payment_method_id,
                            status: item.invoice_status,
                            download:
                                item.invoice_status === 2 ? (
                                    <LightTooltip title={t("payment.retryPay")}>
                                        <IconButton onClick={() => handleRetry(item)}>
                                            <ErrorIcon fontSize="small" color="error" />
                                        </IconButton>
                                    </LightTooltip>
                                ) : (
                                    <IconButton onClick={() => printDocument(item)}>
                                        <CloudDownloadIcon fontSize="small" color="primary" />
                                    </IconButton>
                                ),
                        };
                    });
                    setRows(rowsData);

                    const aux = rowsData.find((item) => item.status === 2);
                    let aux2;

                    if (aux) {
                        aux2 = payments.find((item) => item._id === aux.payment_id);
                        if (aux2) {
                            setCardENum(aux2._id);
                        }
                    }
                    if (!aux || !aux2) {
                        setCardENum();
                    }
                })
                .catch((err) => console.log(err));
        })();
    }, [payments, t, todos.dateFormat, todos.franchiseID, reload]);

    const handleCloseDialog = () => {
        setShowAlert(false);
        setOpenRetryModal(false);
        setInvoiceDetails({});
    };

    const deleteCard = async () => {
        setIsLoading(true);

        const params = {
            id: cardAddress._id,
            payment_id: cardAddress.payment_id,
        };

        await payService
            .deletePaymentMethod(params)
            .then(() => {
                setShowAlert(false);
                setAlertType("success");
                setMessage(t("miscellaneous.deleteCardSuccess"));
                setShow(true);
                reloadData();
                handleCloseModal();
            })
            .catch((err) => console.log(err));

        setIsLoading(false);
    };

    const bodyMess = (
        <GridUI container justifyContent="center" spacing={2}>
            <GridUI item>
                <Typography fontSize={14} textAlign="justify">
                    {payments.length === 1 ? t("payment.deleteMsg2") : t("payment.deleteMsg1")}
                </Typography>
            </GridUI>
            {payments.length === 1 && (
                <GridUI item>
                    <Typography fontSize={14} textAlign="justify">
                        {t("dialog.warningConfirm2")}
                    </Typography>
                </GridUI>
            )}
        </GridUI>
    );

    const bodyMess2 = (
        <Typography fontSize={16} textAlign="justify">
            {t("payment.retryMessage")}
        </Typography>
    );

    const Root = useCallback(
        (props) => {
            return (
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    justifyContent={{ xs: "center", md: "space-between" }}
                    spacing={2}
                >
                    <Box>
                        <Typography sx={{ pb: 2 }} fontWeight={700} fontSize={16}>
                            {t("payment.billing")}
                        </Typography>
                    </Box>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        justifyContent={{ xs: "center", md: "end" }}
                        alignItems={{ xs: "center", sm: "start" }}
                        spacing={{ xs: 2, sm: "25px" }}
                    >
                        <Box sx={{ width: { xs: "100%", sm: "160px" } }}>
                            <FiltersButton
                                franchise={franchise_id}
                                company={company_id}
                                rowsSkeleton={rowsSkeleton}
                                setRows={setRows}
                                handleOpenBill={handleOpenBill}
                                payments={payments}
                            />
                        </Box>
                        <Box sx={{ width: { xs: "100%", sm: "auto" } }}>
                            <Toolbar.Root {...props} className={classes.toolbarSearch} />
                        </Box>
                    </Stack>
                </Stack>
            );
        },
        [company_id, franchise_id, setRows, t, payments]
    );

    return (
        <GridUI container spacing={4} justifyContent="center">
            <GridUI item xs={12} md={4}>
                <Paper
                    elevation={0}
                    square={false}
                    sx={{
                        width: "100%",
                        borderRadius: 2.5,
                        backgroundColor: "rgba(19, 31, 62, 0.03)",
                        minHeight: "calc(100vh - 230px)",
                        p: 4,
                        justifyContent: "space-between",
                        flexDirection: "column",
                        display: "flex",
                    }}
                >
                    <Box>
                        <Typography sx={{ pb: 2 }} fontWeight={700} fontSize={16}>
                            {t("payment.paymentsMethod")}
                        </Typography>
                        {showSkeleton ? (
                            rowsSkeletonPayments.map((item, index) => (
                                <Box sx={{ p: 2 }} key={"skeleton_payment_" + index}>
                                    {item.payments}
                                </Box>
                            ))
                        ) : payments.length > 0 ? (
                            payments.map((item, index) => (
                                <Box className={customClasses.boxPay} key={"box_payment_" + index}>
                                    <FormControl disabled={payments.length === 1} sx={{ width: "100%" }}>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group"
                                            onChange={handleChange}
                                            value={selectedValue}
                                        >
                                            <FormControlLabel
                                                value={item._id}
                                                control={<Radio size="small" />}
                                                disableTypography
                                                label={
                                                    <Stack
                                                        direction={{ xs: "column", sm: "row" }}
                                                        spacing={2}
                                                        alignItems="center"
                                                        sx={{ width: "100%" }}
                                                    >
                                                        <Box
                                                            component="img"
                                                            src={
                                                                item.brand === "visa"
                                                                    ? Visa
                                                                    : item.brand === "mastercard"
                                                                    ? Mastercard
                                                                    : item.brand === "amex"
                                                                    ? Amex
                                                                    : item.brand === "discover"
                                                                    ? Discover
                                                                    : item.brand === "diners"
                                                                    ? Diners
                                                                    : item.brand === "unionpay"
                                                                    ? UnionPay
                                                                    : JCB
                                                            }
                                                            sx={{ width: 45 }}
                                                        />
                                                        <Stack>
                                                            <Stack direction="row" spacing={1}>
                                                                <Typography
                                                                    fontWeight={600}
                                                                    fontSize={12}
                                                                    color="#131F3E"
                                                                    textAlign={{ xs: "center", sm: "left" }}
                                                                >
                                                                    {item.brand.charAt(0).toUpperCase() +
                                                                        item.brand.slice(1)}
                                                                    {" *"}
                                                                    {item.card_number.split("-").pop()}
                                                                    {moment
                                                                        .utc(item.due_date)
                                                                        .subtract(3, "M")
                                                                        .isSameOrBefore(moment()) && (
                                                                        <LightTooltip
                                                                            title={t("payment.dueSoon")}
                                                                            position="top"
                                                                        >
                                                                            <InfoIcon
                                                                                fontSize="inherit"
                                                                                sx={{
                                                                                    ml: 1,
                                                                                    mb: -0.2,
                                                                                    color: "#FF9900",
                                                                                }}
                                                                            />
                                                                        </LightTooltip>
                                                                    )}
                                                                </Typography>
                                                                {cardENum && cardENum === item._id && (
                                                                    <LightTooltip title={t("payment.errorPay")}>
                                                                        <ErrorIcon
                                                                            sx={{ fontSize: 16 }}
                                                                            color="error"
                                                                        />
                                                                    </LightTooltip>
                                                                )}
                                                            </Stack>
                                                            <Box
                                                                component="span"
                                                                sx={{ p: 0, m: 0, display: "flex" }}
                                                                justifyContent={{ xs: "center", sm: "left" }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: 10,
                                                                        fontWeight: 600,
                                                                        color: "#4A22D4",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => handleOpenEdit(item)}
                                                                >
                                                                    {t("team.edit")}
                                                                </Typography>
                                                            </Box>
                                                        </Stack>
                                                        {item?.default_method && item.default_method === true && (
                                                            <Box
                                                                component="span"
                                                                sx={{
                                                                    py: 0.5,
                                                                    px: 2,
                                                                    backgroundColor: "#F6F4FD",
                                                                    borderRadius: "20px",
                                                                    height: 24,
                                                                }}
                                                            >
                                                                <Typography variant="h3" color="primary">
                                                                    {t("payment.default")}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Stack>
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            ))
                        ) : (
                            <Box className={customClasses.boxPay}>{t("subscription.noPayMethod")}</Box>
                        )}
                    </Box>
                    {userEmail !== "support@kiiper.app" && (
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
                            <Button disableElevation variant="contained" onClick={handleAddPaymentM}>
                                {t("credits.add")}
                            </Button>
                        </Box>
                    )}
                </Paper>
            </GridUI>
            <GridUI item xs={12} md={8}>
                <Paper
                    elevation={0}
                    square={false}
                    sx={{
                        borderRadius: 2.5,
                        backgroundColor: "rgba(19, 31, 62, 0.03)",
                        minHeight: "calc(100vh - 230px)",
                        p: 4,
                        justifyContent: "center",
                    }}
                >
                    <StyledContainer
                        component="main"
                        sx={{
                            backgroundColor: "rgb(241, 241, 243)",
                        }}
                    >
                        <Grid rows={rows} columns={columns}>
                            <SearchState value={searchValue} onValueChange={handleSearch} />
                            <IntegratedFiltering />
                            {rows.length > 0 && (
                                <PagingState
                                    currentPage={currentPage}
                                    onCurrentPageChange={setCurrentPage}
                                    pageSize={pageSize}
                                    onPageSizeChange={setPageSize}
                                />
                            )}
                            {rows.length > 0 && <IntegratedPaging />}
                            <Table
                                columnExtensions={tableColumnExtensions}
                                cellComponent={TableCellComponent}
                                messages={tableMessages}
                            />
                            <TableHeaderRow cellComponent={tableHeaderCell} />
                            <Toolbar rootComponent={Root} />
                            <SearchPanel messages={searchMessages} inputComponent={inputComponent} />
                            {rows.length > 0 && (
                                <PagingPanel
                                    containerComponent={PagingPanelContainer}
                                    messages={pagingMessages}
                                    pageSizes={pageSizes}
                                />
                            )}
                        </Grid>
                    </StyledContainer>
                </Paper>
            </GridUI>
            <SimpleDialog
                open={openBill}
                handleClose={() => handleOpenBill("close", "")}
                maxWidth="md"
                content={<Invoice invoiceInfo={invoiceInfo} />}
                invoice={true}
            />
            {showAddPay && (
                <AddPaymentDialog
                    open={showAddPay}
                    handleClose={handleCloseModal}
                    maxWidth="sm"
                    setShow={setShow}
                    setMessage={setMessage}
                    setAlertType={setAlertType}
                    reloadData={reloadData}
                    setIsLoading={setIsLoading}
                    isTab2={true}
                />
            )}
            {showEditPay && (
                <EditPaymentDialog
                    open={showEditPay}
                    handleClose={handleCloseModal}
                    maxWidth="sm"
                    setShow={setShow}
                    setMessage={setMessage}
                    setAlertType={setAlertType}
                    addressData={cardAddress}
                    reloadData={reloadData}
                    setIsLoading={setIsLoading}
                    setShowAlert={setShowAlert}
                    setShowEditPay={setShowEditPay}
                />
            )}
            <BaseSnackbar type={alertType} show={show} message={message} onClose={handleCloseSnack} />
            <SimpleBackdrop open={isLoading} />
            <AlertDialog
                open={showAlert}
                onClose={handleCloseDialog}
                type="warning"
                maxWidth="sm"
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("dialog.cancel")}
                title={t("dialog.warning")}
                message={bodyMess}
                agreeAction={deleteCard}
            />
            <AlertDialog
                open={openRetryModal}
                onClose={handleCloseDialog}
                type="info"
                maxWidth="sm"
                agreeBtnLabel={t("converter.yes")}
                disagreeBtnLabel={t("converter.no")}
                message={bodyMess2}
                agreeAction={retryPayment}
            />
        </GridUI>
    );
};

export default SubscriptionTab2;
