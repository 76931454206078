/** MUI imports */
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltipCust = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 320,
        borderRadius: 6,
        fontWeight: "normal",
    },
});

const LightTooltipCust2 = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.80)",
        fontSize: 12,
        maxWidth: 500,
        borderRadius: 6,
        fontWeight: "normal",
        padding: 15
    },
});

const LightTooltip = (props) => {
    const { title, position, children, custom } = props;

    if(custom){
        return (
            <LightTooltipCust2 title={title} aria-label="tooltip" placement={position}>
                {children}
            </LightTooltipCust2>
        );
    }

    return (
        <LightTooltipCust title={title} aria-label="tooltip" placement={position}>
            {children}
        </LightTooltipCust>
    );
};

export default LightTooltip;
