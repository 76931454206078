import { Button, DialogActions, DialogContent } from "@mui/material";
import { AddressElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import payService from "../../services/payment_methods";

const EditPayForm = (props) => {
    const { handleClose, setMessage, setShow, setAlertType, cardData, reloadData, setIsLoading } = props;

    const [t] = useTranslation("global");

    const stripe = useStripe();
    const elements = useElements();

    const [address, setAddress] = useState("");
    const [name, setName] = useState("");

    const handleChangeAddress = (event) => {
        if (event.complete) {
            setAddress(event.value.address);
            setName(event.value.name);
        }
    };

    const handleError = (error) => {
        setIsLoading(false);
        setAlertType("error");
        setMessage(error.message);
        setShow(true);
    };

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        const params = {
            id: cardData._id,
            card_holder: name,
            address: address,
            customer_id: cardData.customer_id
        };

        // Editamos la tarjeta
        await payService
            .updatePaymentMethod(params)
            .then(() => {
                setAlertType("success");
                setMessage(t("miscellaneous.editCardSuccess"));
                setShow(true);
                reloadData();
                handleClose();
            })
            .catch((err) => console.log(err));

        setIsLoading(false);
    };


    return (
        <>
            <DialogContent>
                <AddressElement
                    options={{
                        mode: "billing",
                        defaultValues: {
                            name: cardData.card_holder,
                            address: cardData.address,
                        },
                    }}
                    onChange={handleChangeAddress}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disableElevation
                    variant="outlined"
                    onClick={handleClose}
                    color="primary"
                    disabled={!stripe || !elements}
                    sx={{
                        px: 5,
                        borderRadius: 2,
                    }}
                >
                    {t("miscellaneous.goBack")}
                </Button>
                <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    sx={{
                        borderRadius: 2,
                        px: 5,
                    }}
                    disabled={!stripe || !elements}
                    onClick={handleSubmit}
                >
                    {t("dialog.continue")}
                </Button>
            </DialogActions>
        </>
    );
};

export default EditPayForm;
