import { Card, CardActionArea, CardContent, Grid, Paper, Skeleton, Stack, Typography } from "@mui/material";
import BaseSelect from "../ui/BaseSelect";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import { useSelector } from "react-redux";

import accountsService from "../../services/accounts";
import { useEffect, useState } from "react";

function Arrows(props) {
    const { className, style, onClick, isDisabled } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", pointerEvents: isDisabled ? "none" : "all" }}
            onClick={onClick}
        />
    );
}

const rowsSkeleton = [];

for (let i = 0; i < 4; i++) {
    rowsSkeleton.push(<Skeleton variant="rounded" width="100%" height={100} />);
}

const BanksList = (props) => {
    const { accounts, selAccount, setSelAccount, setSelAccountData, setBankDetails } = props;
    const [t] = useTranslation("global");

    const settings = {
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <Arrows isDisabled={accounts?.length <= 4} />,
        prevArrow: <Arrows isDisabled={accounts?.length <= 4} />,
        draggable: accounts?.length > 4,
        infinite: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const todos = useSelector((state) => state.value);
    const [finalAccs, setFinalAccs] = useState([]);

    const handleChangeAcc = (event) => {
        setSelAccount(event.target.value);
        setSelAccountData(accounts.find((item) => item._id === event.target.value));
    };

    const handleBank = async (bank) => {
        setSelAccount(bank._id);
        setSelAccountData(bank);

        await accountsService
            .getReconciliationBankDetails({ account_id: bank._id })
            .then((details) => {
                setBankDetails(details);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (accounts) {
            const finalAccs = accounts.map((item) => {
                return { id: item._id, name: item.account_name };
            });

            setFinalAccs(finalAccs);
        }
    }, [accounts]);

    return (
        <Paper elevation={0} square={false} sx={{ backgroundColor: "white", p: 3, borderRadius: 3 }}>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h2">{t("dashboard.accounts")}</Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <BaseSelect
                        id="account"
                        label={t("dashboard.accounts")}
                        value={selAccount}
                        values={finalAccs}
                        onChange={handleChangeAcc}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Slider {...settings} style={{ margin: 20 }}>
                        {!accounts
                            ? rowsSkeleton.map((item, index) => <div key={index}>{item}</div>)
                            : accounts.map((item) => (
                                  <div key={item._id}>
                                      <Card
                                          sx={{
                                              margin: "0 10px",
                                              backgroundColor: selAccount === item._id ? "#4a22d4" : "#F9F8FD",
                                              boxShadow: "4px 4px 5px 0px rgba(74, 34, 212, 0.1)",
                                          }}
                                      >
                                          <CardActionArea
                                              onClick={() => handleBank(item)}
                                              sx={{
                                                  minHeight: 120,
                                              }}
                                          >
                                              <CardContent>
                                                  <Typography
                                                      variant="h1"
                                                      color={selAccount === item._id ? "white" : "primary"}
                                                      gutterBottom
                                                  >
                                                      {item.account_name}
                                                  </Typography>
                                                  <Stack direction="row" spacing={1} alignItems="center">
                                                      {parseInt(item.unreconciled_transactions) === 0 ? (
                                                          <>
                                                              <Typography
                                                                  color={selAccount === item._id ? "white" : "#131F3E"}
                                                                  gutterBottom
                                                              >
                                                                  {t("dashboard.reconTrans")}
                                                              </Typography>
                                                              <CheckIcon color="success" fontSize="small" />
                                                          </>
                                                      ) : (
                                                          <Typography
                                                              color={selAccount === item._id ? "white" : "#131F3E"}
                                                              gutterBottom
                                                          >
                                                              <span
                                                                  style={{
                                                                      color:
                                                                          selAccount === item._id ? "#fff" : "#4A22D4",
                                                                      fontWeight: 600,
                                                                  }}
                                                              >
                                                                  {parseInt(item.unreconciled_transactions)}
                                                              </span>{" "}
                                                              {t("dashboard.pendingTrans")}
                                                          </Typography>
                                                      )}
                                                  </Stack>
                                                  <Stack direction="row" spacing={1} alignItems="center">
                                                      <Typography
                                                          variant="h2"
                                                          color={selAccount === item._id ? "white" : "#131F3E"}
                                                      >
                                                          {t("dashboard.difBalanc")}
                                                      </Typography>
                                                      <InfoIcon
                                                          sx={{ color: selAccount === item._id ? "#fff" : "#4A22D4" }}
                                                          fontSize="small"
                                                      />
                                                      <Typography
                                                          variant="h2"
                                                          color={selAccount === item._id ? "white" : "error"}
                                                      >
                                                          $
                                                          {parseFloat(
                                                              item.last_conversion_balance - item.bank_balance_system
                                                          ).toLocaleString(todos.amountFormat, {
                                                              minimumFractionDigits: 2,
                                                          })}
                                                      </Typography>
                                                  </Stack>
                                              </CardContent>
                                          </CardActionArea>
                                      </Card>
                                  </div>
                              ))}
                    </Slider>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default BanksList;
