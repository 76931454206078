/* Componentes */
import { Box, Grid } from "@mui/material";
import LangButton from "../auth/LangButton";

/* Iconos */
import { ReactComponent as KiiperLogo } from "../../assets/kiiper-logo.svg";

/* Estilos */
import classes from "./BackLogin.module.css";
import { useNavigate } from "react-router-dom";

const BackKiiper = (props) => {
    const { children } = props;
    const navigate = useNavigate();

    const goToLogin = () => {
        navigate("/");  
    }

    return (
        <Box className={classes.background}>
            <LangButton />
            <Grid container justifyContent="center" alignItems="center" direction="column">
                <Grid item>
                    <KiiperLogo className={classes.logo} onClick={goToLogin} />
                </Grid>
                <Grid item>{children}</Grid>
            </Grid>
        </Box>
    );
};

export default BackKiiper;
