import { Button, Card, CardContent, Grid, Paper, Stack, Typography, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

import classes from "./SubscriptionTab3.module.css";
import CreditsRecord from "./CreditsRecord";

import InfoIcon from "@mui/icons-material/Info";
import LightTooltip from "../ui/LightTooltip";
import { useEffect, useState } from "react";
import credService from "../../services/subscription";
import userCompanyService from "../../services/user_company";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SubscriptionTab3 = (props) => {
    const { setIsLoading, franchise_id, company_id, showSkeleton, setShowSkeleton, plansInfo } = props;

    const [credits, setCredits] = useState([]);
    const [creditsOG, setCreditsOG] = useState([]);
    const [currentPlan, setCurrentPlan] = useState({});
    const [extraCredits, setExtraCredits] = useState({});
    const [totalCredits, setTotalCredits] = useState(0);
    const [planInfo, setPlanInfo] = useState({});
    const [usersFranq, setUsersFranq] = useState([]);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));

    const [t, i18n] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            setShowSkeleton(true);

            const params = {
                franchise_id: franchise_id,
                company_id: company_id,
                all: true,
            };

            let counter = 0;

            await credService
                .getCredits(params)
                .then((response) => {
                    setCredits(response.historyCredits);
                    setCreditsOG(response.historyCredits);
                    setCurrentPlan(response.currentPlan);
                    setPlanInfo(plansInfo.find((item) => item.planId === response.currentPlan.subscription_plan));
                    setExtraCredits(response.extraCredits);
                    if (response.currentPlan) {
                        counter += response.currentPlan.count;
                    }
                    if (response.extraCredits) {
                        counter += response.extraCredits.count;
                    }
                    setTotalCredits(counter);
                })
                .catch((error) => {
                    console.log(error);
                });

            await userCompanyService
                .getAllUsersFranchise(params)
                .then(async (data) => {
                    setUsersFranq(data.users);
                })
                .catch((error) => {
                    console.log(error);
                });

            setShowSkeleton(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company_id, franchise_id, setShowSkeleton]);

    /** Component functions */
    const goToPlans = () => {
        navigate("/subscription", {
            state: {
                tab: 0,
            },
        });
    };

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    return (
        <>
            <Paper square={false} elevation={0} sx={{ backgroundColor: "rgba(3, 24, 81, 0.03)", p: "15px 20px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Card>
                            {showSkeleton ? (
                                <CardContent className={classes.sizeCard}>
                                    <Grid container spacing={2} alignItems="center" sx={{ paddingTop: 1 }}>
                                        <Grid item xs={12} md={8}>
                                            <Skeleton variant="text" animation="wave" sx={{ mt: 1 }} />
                                            <Skeleton variant="text" animation="wave" />
                                            <Skeleton variant="text" animation="wave" />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Skeleton
                                                variant="text"
                                                animation="wave"
                                                width="35%"
                                                height={70}
                                                sx={{ ml: "auto", mt: 1 }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            ) : (
                                <CardContent className={classes.sizeCard}>
                                    <Grid container spacing={2} alignItems="center" sx={{ paddingTop: 1 }}>
                                        {/* Free trial */}
                                        {todos.subscription && todos.subscription.value === 1 && (
                                            <>
                                                <Grid item xs={12} md={7}>
                                                    <Typography variant="h2" gutterBottom>
                                                        {t("credits.converOf")}{" "}
                                                        <span style={{ color: "#4A22D4" }}>
                                                            {t("navBar.freeTrial")}
                                                        </span>
                                                    </Typography>
                                                    <Typography>
                                                        {t("credits.subsBestSuit")} <b>{t("credits.subsBestSuit2")}</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={5} className={classes.textCard}>
                                                    <Typography color="primary" className={classes.numberCred}>
                                                        {totalCredits}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        )}
                                        {/* On hold o Cancelado */}
                                        {todos.subscription &&
                                            (todos.subscription.value === 4 ||
                                                todos.subscription.value === 5 ||
                                                todos.subscription.value === 6 ||
                                                todos.subscription.value === 2) && (
                                                <>
                                                    <Grid item xs={12} md={7}>
                                                        <Typography variant="h2" gutterBottom color="#4A22D4">
                                                            {t("credits.noSubs")}
                                                        </Typography>
                                                        <Typography>
                                                            {t("credits.subsBestSuit")}{" "}
                                                            <b>{t("credits.subsBestSuit2")}</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={5} className={classes.textCard}>
                                                        <Typography fontSize={11} color="primary">
                                                            {t("credits.convMonthly")}
                                                        </Typography>
                                                        <Button
                                                            variant="contained"
                                                            className={classes.subsButton2}
                                                            onClick={goToPlans}
                                                            sx={{ width: { lg: "100%" } }}
                                                        >
                                                            {t("credits.subsPlan")}
                                                        </Button>
                                                    </Grid>
                                                </>
                                            )}
                                        {todos.subscription && todos.subscription.value === 3 && (
                                            <>
                                                <Grid item xs={12} md={7}>
                                                    <Typography variant="h2" gutterBottom>
                                                        {t("plans.converPlan")}
                                                        <span style={{ color: "#4A22D4" }}>
                                                            {" "}
                                                            {planInfo?.data?.credits}
                                                        </span>
                                                    </Typography>
                                                    <Stack direction="row" alignItems="center" spacing={1}>
                                                        <Typography variant="h4">
                                                            {t("plans.nextExpired")}{" "}
                                                            <span style={{ color: "#4A22D4" }}>
                                                                {/* {moment(currentPlan.due_date).isAfter(moment())
                                                                    ? moment(currentPlan.due_date).format(
                                                                        todos.dateFormat
                                                                    )
                                                                    : moment()
                                                                        .set(
                                                                            "date",
                                                                            moment(currentPlan.since_date).format(
                                                                                "DD"
                                                                            )
                                                                        )
                                                                        .add(1, "M")
                                                                        .format(todos.dateFormat)} */}
                                                                {moment
                                                                    .utc(currentPlan.due_date)
                                                                    .format(todos.dateFormat)}
                                                            </span>
                                                        </Typography>
                                                        <LightTooltip
                                                            title={
                                                                <Typography fontSize={12}>
                                                                    {t("plans.thisDateExp")}{" "}
                                                                    <span style={{ color: "#0FF" }}>
                                                                        {currentPlan.count_plan} {t("credits.credits")}
                                                                    </span>{" "}
                                                                    {t("plans.oldestCharge")}
                                                                    <br />
                                                                    <span style={{ color: "#02f92a" }}>
                                                                        {t("plans.conversionExtension") +
                                                                            " +" +
                                                                            (currentPlan.subscription_plan <= 3
                                                                                ? "30"
                                                                                : "60") +
                                                                            " " +
                                                                            t("miscellaneous.days") +
                                                                            "."}
                                                                    </span>
                                                                </Typography>
                                                            }
                                                        >
                                                            <InfoIcon sx={{ fontSize: 14 }} color="primary" />
                                                        </LightTooltip>
                                                    </Stack>
                                                    <Typography fontSize={12}>
                                                        {t("plans.renewOf")} {planInfo?.data?.credits}{" "}
                                                        {t("plans.monthlyConv")}
                                                    </Typography>
                                                </Grid>
                                                {todos.credits !== 0 ? (
                                                    <Grid item xs={12} md={5} className={classes.textCardRight}>
                                                        <Typography fontSize={54} fontWeight={600} color="primary">
                                                            {currentPlan.count}
                                                        </Typography>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12} md={5} className={classes.textCard}>
                                                        <Typography fontSize={11} color="primary">
                                                            {t("credits.runOutConver")}
                                                        </Typography>
                                                        <Button
                                                            variant="contained"
                                                            className={classes.subsButton2}
                                                            onClick={goToPlans}
                                                            sx={{ width: { lg: "100%" } }}
                                                        >
                                                            {t("credits.upgrade")}
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                </CardContent>
                            )}
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            {showSkeleton ? (
                                <CardContent className={classes.sizeCard}>
                                    <Grid container spacing={2} alignItems="center" sx={{ paddingTop: 1 }}>
                                        <Grid item xs={12} md={8}>
                                            <Skeleton variant="text" animation="wave" sx={{ mt: 1 }} />
                                            <Skeleton variant="text" animation="wave" />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Skeleton
                                                variant="text"
                                                animation="wave"
                                                width="35%"
                                                height={70}
                                                sx={{ ml: "auto", mt: 1 }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            ) : (
                                <CardContent className={classes.sizeCard}>
                                    <Grid container spacing={2} alignItems="center" sx={{ paddingTop: 1 }}>
                                        {todos.subscription &&
                                            (todos.subscription.value === 1 ||
                                                todos.subscription.value === 4 ||
                                                todos.subscription.value === 5 ||
                                                todos.subscription.value === 6 ||
                                                (todos.subscription.value === 3 && extraCredits.count === 0) ||
                                                (todos.subscription.value === 2 && extraCredits.count === 0)) && (
                                                <>
                                                    <Grid item xs={12} md={7}>
                                                        <Typography variant="h2" gutterBottom>
                                                            {t("credits.converExtra")}
                                                        </Typography>
                                                        <Typography>
                                                            {todos.subscription.value === 3 && extraCredits.count === 0
                                                                ? t("credits.complementPlan")
                                                                : t("credits.converExtraDet")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={5} className={classes.textCard}>
                                                        <Typography fontSize={11} color="primary">
                                                            {t("credits.addConverAdd")}
                                                        </Typography>
                                                        <Button
                                                            variant="contained"
                                                            className={classes.subsButton2}
                                                            onClick={goToPlans}
                                                            sx={{ width: { lg: "100%" } }}
                                                        >
                                                            {t("credits.buy")}
                                                        </Button>
                                                    </Grid>
                                                </>
                                            )}
                                        {todos.subscription &&
                                            extraCredits.count > 0 &&
                                            (todos.subscription.value === 3 || todos.subscription.value === 2) && (
                                                <>
                                                    <Grid item xs={12} md={8}>
                                                        <Typography variant="h2" gutterBottom>
                                                            {t("credits.converExtra")}
                                                        </Typography>
                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                            <Typography>
                                                                {t("plans.nextExpired")}{" "}
                                                                <span style={{ color: "#4A22D4" }}>
                                                                    {moment
                                                                        .utc(extraCredits.due_date)
                                                                        .format(todos.dateFormat)}
                                                                </span>{" "}
                                                            </Typography>
                                                            <LightTooltip
                                                                title={
                                                                    language === "es" ? (
                                                                        <Typography fontSize={12}>
                                                                            {extraCredits.count === 1
                                                                                ? t("plans.expiredDateToolSing")
                                                                                : t("plans.expiredDateTool")}{" "}
                                                                            <span style={{ color: "#0FF" }}>
                                                                                {extraCredits.count}{" "}
                                                                                {extraCredits.count === 1
                                                                                    ? t("credits.conversion")
                                                                                    : t("credits.credits")}
                                                                                .
                                                                            </span>
                                                                        </Typography>
                                                                    ) : (
                                                                        <Typography fontSize={12}>
                                                                            {t("plans.expiredDateToolSing")}{" "}
                                                                            <span style={{ color: "#0FF" }}>
                                                                                {extraCredits.count}{" "}
                                                                                {t("credits.conversion")}{" "}
                                                                            </span>
                                                                            {t("plans.willExp")}
                                                                        </Typography>
                                                                    )
                                                                }
                                                            >
                                                                <InfoIcon sx={{ fontSize: 14 }} color="primary" />
                                                            </LightTooltip>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} md={4} className={classes.textCardRight}>
                                                        <Typography fontSize={54} fontWeight={600} color="primary">
                                                            {extraCredits.count}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            )}
                                    </Grid>
                                </CardContent>
                            )}
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            {showSkeleton ? (
                                <CardContent className={classes.sizeCard}>
                                    <Grid container spacing={2} alignItems="center" sx={{ paddingTop: 1 }}>
                                        <Grid item xs={12} md={8}>
                                            <Skeleton variant="text" animation="wave" sx={{ mt: 2 }} />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Skeleton
                                                variant="text"
                                                animation="wave"
                                                width="35%"
                                                height={70}
                                                sx={{ ml: "auto", mt: 1 }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            ) : (
                                <CardContent className={classes.sizeCard}>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ paddingTop: 1 }}
                                    >
                                        <Grid item>
                                            <Typography variant="h2">{t("credits.totalConv")}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography color="primary" className={classes.numberCred}>
                                                {totalCredits}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
            <CreditsRecord
                setIsLoading={setIsLoading}
                franchise_id={franchise_id}
                company_id={company_id}
                credits={credits}
                creditsOG={creditsOG}
                setCredits={setCredits}
                setCreditsOG={setCreditsOG}
                showSkeleton={showSkeleton}
                setShowSkeleton={setShowSkeleton}
                usersFranq={usersFranq}
            />
        </>
    );
};

export default SubscriptionTab3;
