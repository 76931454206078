import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function SimpleBackdrop(props) {
    const { open } = props;
    return (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 999 }} open={open}>
            <CircularProgress color="secondary" />
        </Backdrop>
    );
}
