import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import classes from "./SubscriptionTab2.module.css";
import { ReactComponent as KiiperLogo } from "../../assets/kiiper-logo.svg";
import { ReactComponent as Visa } from "../../assets/visa.svg";
import { ReactComponent as Mastercard } from "../../assets/mastercard.svg";
import { ReactComponent as Amex } from "../../assets/amex.svg";
import { ReactComponent as Discover } from "../../assets/discover.svg";
import { ReactComponent as Diners } from "../../assets/diners.svg";
import { ReactComponent as JCB } from "../../assets/jcb.svg";
import { ReactComponent as UnionPay } from "../../assets/unionpay.svg";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

export const Invoice = forwardRef((props, ref) => {
    const { invoiceInfo } = props;

    const newInfo = invoiceInfo.line_items.filter((item) => item.line_type !== 5 && item.line_type !== 6);

    let isSingleConv = false;

    if (newInfo.length === 0) {
        isSingleConv = true;
    }

    const todos = useSelector((state) => state.value);

    const sumTotal = invoiceInfo.line_items
        .map((item) => {
            if (item.line_type === 5 || item.line_type === 6) {
                return item.line_amount;
            }
            return 0;
        })
        .reduce((partialSum, a) => partialSum + a, 0);

    if (sumTotal > 0) {
        const sumQty = invoiceInfo.line_items
            .map((item) => {
                if (item.line_type === 5 || item.line_type === 6) {
                    return item.line_qty;
                }
                return 0;
            })
            .reduce((partialSum, a) => partialSum + a, 0);

        const finalUnit = invoiceInfo.line_items.find(
            (item) => item.line_type === 5 || item.line_type === 6
        ).line_unit_amount;

        const newLine = {
            line_type: 5,
            line_amount: sumTotal,
            line_qty: sumQty,
            line_unit_amount: finalUnit,
        };

        newInfo.push(newLine);
    }

    const [t] = useTranslation("global");

    const plansInfo = [
        {
            planId: 1,
            planTitle: t("plans.essential"),
            cost: 7.75,
            colorTop: "#484949",
            data: {
                credits: 5,
                users: 1,
                roles: true,
                storage: t("plans.unlimited"),
                support: t("plans.standard"),
                integrations: [],
                xeroDashboard: false,
                training: false,
            },
            customCredits: 1.8,
        },
        {
            planId: 2,
            planTitle: t("plans.advanced"),
            cost: 19.75,
            colorTop: "#a35eff",
            data: {
                credits: 15,
                users: 2,
                roles: true,
                storage: t("plans.unlimited"),
                support: t("plans.standard"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
                training: false,
            },
            customCredits: 1.7,
        },
        {
            planId: 3,
            planTitle: t("plans.premium"),
            cost: 35.75,
            colorTop: "#ffab4c",
            data: {
                credits: 30,
                users: 3,
                roles: true,
                storage: t("plans.unlimited"),
                support: t("plans.standard"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
                training: false,
            },
            customCredits: 1.6,
        },
        {
            planId: 4,
            planTitle: t("plans.professional"),
            cost: 55.75,
            colorTop: "#00B147",
            data: {
                credits: 50,
                users: 5,
                roles: true,
                support: t("plans.standard"),
                storage: t("plans.unlimited"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
                training: t("plans.standard"),
            },
            customCredits: 1.5,
        },
        {
            planId: 5,
            planTitle: t("plans.business"),
            cost: 189.75,
            colorTop: "#00FFFF",
            data: {
                credits: 200,
                users: 6,
                roles: true,
                support: t("plans.priority"),
                storage: t("plans.unlimited"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
                training: t("plans.custom"),
            },
            customCredits: 1.4,
        },
        {
            planId: 6,
            planTitle: t("plans.corporate"),
            cost: 425.75,
            colorTop: "#FF007A",
            data: {
                credits: 500,
                users: t("plans.unlimited"),
                roles: true,
                support: t("plans.priority"),
                storage: t("plans.unlimited"),
                integrations: ["Xero", "Quickbooks"],
                xeroDashboard: "Xero",
                training: t("plans.custom"),
            },
            customCredits: 1.3,
        },
    ];

    const getUsers = (item) =>
        plansInfo[invoiceInfo.invoice_description.find((item2) => item2.type === item.line_type).value - 1].data.users;

    return (
        <Box className={classes.topHeader} ref={ref}>
            <Box className={classes.purpleBack}>
                <Grid container>
                    <Grid item xs={12}>
                        <KiiperLogo className={classes.kiiperLogo} />
                    </Grid>
                    <Grid item xs={12} className={classes.whiteTextAll}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Typography className={classes.whiteText2}>{t("payment.ourClient")}</Typography>
                            <Typography sx={{ color: "#0ff" }}>support@kiiper.app</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.whiteBack}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h3" color="primary">
                            {t("payment.client")}:
                        </Typography>
                        <Typography>{invoiceInfo.franchise_id.franchise_name}</Typography>
                        <Typography>{invoiceInfo.franchise_id.country_name}</Typography>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="h3" color="primary">
                                {t("payment.invoice")}:
                            </Typography>
                            <Typography>{invoiceInfo.invoice_number}</Typography>
                        </Stack>
                        <Typography>
                            {moment.utc(invoiceInfo.creation_date).lang(t("language.locale")).format("DD/MMM/YY")}
                        </Typography>
                        <Stack direction="row" alignItems="center">
                            <Chip
                                className={invoiceInfo.invoice_status === 1 ? classes.status : classes.statusPending}
                                size="small"
                                label={invoiceInfo.invoice_status === 1 ? t("payment.paid") : t("payment.pending")}
                                color="primary"
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Typography variant="h3" color="primary" pt={4}>
                    {t("payment.services")}
                </Typography>
                {/* // Tipo de línea (1: Activación, 2: Renovación, 3: Downgrade, 4: Upgrade, 5: Adicional / Paquete de créditos, 6: Paquete de créditos personalizado, 7: Cancelación, 8: Reactivación) */}
                {newInfo.map((item) => (
                    <>
                        {((item.line_type >= 1 && item.line_type <= 4) ||
                            item.line_type === 7 ||
                            item.line_type === 8) && (
                            <Box className={classes.borderGrey}>
                                <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <Typography fontSize={22}>
                                                Plan{" "}
                                                {
                                                    plansInfo[
                                                        invoiceInfo.invoice_description.find(
                                                            (item2) => item2.type === item.line_type
                                                        ).value - 1
                                                    ].data.credits
                                                }
                                            </Typography>
                                            <Chip
                                                className={classes.status2}
                                                size="small"
                                                label={
                                                    item.line_type === 1
                                                        ? t("subscription.activation")
                                                        : item.line_type === 2
                                                        ? t("subscription.renewal")
                                                        : item.line_type === 3
                                                        ? t("subscription.downgrade")
                                                        : item.line_type === 4
                                                        ? t("subscription.upgrade")
                                                        : item.line_type === 7
                                                        ? t("subscription.cancel")
                                                        : item.line_type === 8
                                                        ? t("subscription.reactivate")
                                                        : ""
                                                }
                                                color="primary"
                                            />
                                        </Stack>
                                        <Typography>
                                            {t("payment.cutDate")}: {moment.utc(invoiceInfo.creation_date).format("DD")}{" "}
                                            {t("payment.eachMonth")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography textAlign="right">
                                            <b>{t("payment.period")}:</b>{" "}
                                            {moment
                                                .utc(invoiceInfo.since_date)
                                                .lang(t("language.locale"))
                                                .format("DD/MMM/YY")}
                                            {" - "}
                                            {moment
                                                .utc(invoiceInfo.end_date)
                                                .lang(t("language.locale"))
                                                .format("DD/MMM/YY")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h3">{t("inbox.conversions")}</Typography>
                                        <Typography>
                                            {
                                                plansInfo[
                                                    invoiceInfo.invoice_description.find(
                                                        (item2) => item2.type === item.line_type
                                                    ).value - 1
                                                ].data.credits
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h3">{t("plans.users1")}</Typography>
                                        <Typography>
                                            {isNaN(getUsers(item))
                                                ? getUsers(item).charAt(0).toUpperCase() + getUsers(item).slice(1)
                                                : getUsers(item)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h3">{t("plans.banks")}</Typography>
                                        <Typography>{t("payment.unlimited")}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h3">{t("plans.storage")}</Typography>
                                        <Typography>
                                            {plansInfo[
                                                invoiceInfo.invoice_description.find(
                                                    (item2) => item2.type === item.line_type
                                                ).value - 1
                                            ].data.storage
                                                .charAt(0)
                                                .toUpperCase() +
                                                plansInfo[
                                                    invoiceInfo.invoice_description.find(
                                                        (item2) => item2.type === item.line_type
                                                    ).value - 1
                                                ].data.storage.slice(1)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h3">{t("converter.price")}</Typography>
                                        <Typography>
                                            ${" "}
                                            {parseFloat(
                                                plansInfo[
                                                    invoiceInfo.invoice_description.find(
                                                        (item2) => item2.type === item.line_type
                                                    ).value - 1
                                                ].cost
                                            ).toLocaleString(todos.amountFormat, {
                                                minimumFractionDigits: 2,
                                            })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        {item.line_type === 5 && (
                            <Box className={classes.borderGrey2}>
                                <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <Typography fontSize={22}>
                                            {isSingleConv ? t("payment.converPay") : t("payment.addConver")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Stack direction="row" spacing={5}>
                                            <Box>
                                                <Typography variant="h3">{t("inbox.conversions")}</Typography>
                                                <Typography>{item.line_qty}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="h3">{t("payment.unitPrice")}</Typography>
                                                <Typography>$ {item.line_unit_amount}</Typography>
                                            </Box>
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h3">{t("converter.price")}</Typography>
                                        <Typography>
                                            ${" "}
                                            {parseFloat(item.line_amount).toLocaleString(todos.amountFormat, {
                                                minimumFractionDigits: 2,
                                            })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </>
                ))}
                <Box className={classes.greyBack}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={12}>
                            <Typography variant="h4" color="primary">
                                {t("payment.paymentMethod").toUpperCase()}
                            </Typography>
                            {invoiceInfo.brand ? (
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    {invoiceInfo.brand === "visa" && <Visa className={classes.card} />}
                                    {invoiceInfo.brand === "mastercard" && <Mastercard className={classes.card} />}
                                    {invoiceInfo.brand === "amex" && <Amex className={classes.card} />}
                                    {invoiceInfo.brand === "discover" && <Discover className={classes.card} />}
                                    {invoiceInfo.brand === "diners" && <Diners className={classes.card} />}
                                    {invoiceInfo.brand === "jcb" && <JCB className={classes.card} />}
                                    {invoiceInfo.brand === "unionpay" && <UnionPay className={classes.card} />}
                                    <Typography fontSize={16} sx={{ pb: 1, pt: 1 }}>
                                        {invoiceInfo.card_number ? "*" + invoiceInfo.card_number.split("-").pop() : ""}
                                    </Typography>
                                </Stack>
                            ) : (
                                <Typography fontSize={14} sx={{ pb: 1, pt: 1 }}>
                                    {t("payment.noPayMethod")}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item>
                            {invoiceInfo.invoice_status === 1 ? (
                                <Typography fontSize={12}>
                                    {t("payment.paymentProcess")}{" "}
                                    {moment
                                        .utc(invoiceInfo.payment_date)
                                        .lang(t("language.locale"))
                                        .format("DD/MMM/YY")}
                                </Typography>
                            ) : (
                                <Typography fontSize={12}>{t("payment.pendingPay")}</Typography>
                            )}
                        </Grid>
                        <Grid item className={classes.spacingTot}>
                            <Typography>
                                <b>Total:</b> ${" "}
                                {parseFloat(
                                    invoiceInfo.line_items
                                        .map((item) => item.line_amount)
                                        .reduce((partialSum, a) => partialSum + a, 0)
                                ).toLocaleString(todos.amountFormat, {
                                    minimumFractionDigits: 2,
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
});
