/* Componentes */
import { useSelector } from "react-redux";
import BackGeneral from "../components/layout/BackGeneral";
import MainInbox from "../components/inbox/MainInbox";
import { useEffect, useState } from "react";
import casesService from "../services/case";
// import communicationsService from "../services/communication";
// import notificationsService from "../services/notification";
import ITTeam from "../components/Js/ITTeam";

const Inbox = () => {
    const todos = useSelector((state) => state.value);
    const [casesOG, setCasesOG] = useState(null);
    const [cases, setCases] = useState(null);
    const [comm, setComm] = useState([]);
    const [notif, setNotif] = useState([]);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [accountsFilter, setAccountsFilter] = useState([]);
    const [usersFilter, setUsersFilter] = useState([]);
    const [banksFilter, setBanksFilter] = useState([]);
    const [loadingF, setLoadingF] = useState(false);
    const [subsFilter, setSubsFilter] = useState([]);
    const [comFilter, setComFilter] = useState([]);
    const [countriesFilter, setCountriesFilter] = useState([]);
    const [subsInvFilter, setSubsInvFilter] = useState([]);
    const [countriesInvFilter, setCountriesInvFilter] = useState([]);
    const [subsDirFilter, setSubsDirFilter] = useState([]);
    const [countriesDirFilter, setCountriesDirFilter] = useState([]);

    useEffect(() => {
        (async () => {
            setShowSkeleton(true);
            setLoadingF(false);
            let params = {
                user_id: todos.userInfo._id,
                company_id: todos.companyID,
                franchise_id: todos.franchiseID,
            };

            if (ITTeam.includes(todos.userInfo.email)) {
                await casesService
                    .getAdminCases()
                    .then((data) => {
                        setCases(data);
                        setCasesOG(data);
                        if (data.allFranchises.length > 0){
                            setSubsFilter(data.allFranchises)
                        }
                        if (data.allCompanies.length > 0){
                            setComFilter(data.allCompanies)
                        }
                        if (data.allFranchisesCountries.length > 0){
                            setCountriesFilter(data.allFranchisesCountries)
                        }
                        if (data.allFranchisesInvoices.length > 0){
                            setSubsInvFilter(data.allFranchisesInvoices)
                        }
                        if (data.allFranchisesCountriesInvoices.length > 0){
                            setCountriesInvFilter(data.allFranchisesCountriesInvoices)
                        }
                        if (data.allFranchisesDir.length > 0){
                            setSubsDirFilter(data.allFranchisesDir)
                        }
                        if (data.allFranchisesCountriesDir.length > 0){
                            setCountriesDirFilter(data.allFranchisesCountriesDir)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                await casesService
                    .getCases(params)
                    .then((data) => {
                        setCases(data);
                        setAccountsFilter(data?.allAccounts ?? []);
                        setUsersFilter(data?.allUsers ?? []);
                        setBanksFilter(data?.allBanks ?? []);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            // let params2 = { user_id: todos.userInfo._id, all_communications: true };

            // await communicationsService
            //     .getCommunications(params2)
            //     .then((data) => {
            //         setComm(data);
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //     });

            // let param3 = {
            //     ...params2,
            //     all_notifications: true,
            // };

            // await notificationsService
            //     .getNotifications(param3)
            //     .then((data) => {
            //         setNotif(data);
            //     })
            //     .catch((err) => {
            //         console.log(err);
            //     });

            setShowSkeleton(false);
            setLoadingF(true);
        })();
    }, [todos.companyID, todos.franchiseID, todos.userInfo._id, todos.userInfo.email]);

    return (
        <BackGeneral color="#f8f8f9">
            <MainInbox
                franchise_id={todos.franchiseID}
                company_id={todos.companyID}
                casesOG={casesOG}
                cases={cases}
                user_id={todos.userInfo._id}
                setCases={setCases}
                comm={comm}
                setComm={setComm}
                notif={notif}
                setNotif={setNotif}
                setShowSkeleton={setShowSkeleton}
                showSkeleton={showSkeleton}
                accountsFilter={accountsFilter}
                usersFilter={usersFilter}
                banksFilter={banksFilter}
                setCasesOG={setCasesOG}
                loadingF={loadingF}
                subsFilter={subsFilter}
                comFilter={comFilter}
                countriesFilter={countriesFilter}
                subsInvFilter={subsInvFilter}
                countriesInvFilter={countriesInvFilter}
                subsDirFilter={subsDirFilter}
                countriesDirFilter={countriesDirFilter}
            />
        </BackGeneral>
    );
};

export default Inbox;
