import styled from "@emotion/styled";
import { Grid, Paper, Typography, Box, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import XeroLogo from "../../assets/xero.svg";
import QuickBooksLogo from "../../assets/quickbooks.svg";
import subscriptionService from "../../services/subscription";
import { useSelector } from "react-redux";
import moment from "moment";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CheckIcon from "@mui/icons-material/Check";
import { useState, useEffect } from "react";

const ColorButton2 = styled(Button)(({ theme }) => ({
    letterSpacing: "-0.22px",
    color: "#FFF",
    fontStyle: "normal",
    fontWeight: "350",
    border: "1px solid #4A22D4",
    borderRadius: "6px",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "4px",
    paddingBottom: "4px",
}));

const CardPlan = (props) => {
    const {
        data,
        activePlan,
        cartItems,
        setCartItems,
        setIsLoading,
        currentPlan,
        setActivePlan,
        creditsInfo,
        setPlanCredits,
        handleOpenCart,
        currentPlanInfo,
        setReactivatePlan,
        setOpenAlert,
        setOpenReactiveAlert,
        plansInfo,
    } = props;
    const [t, i18n] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));

    const handlePlanSelect = async (value) => {
        if (currentPlan) {
            if (value.planId < currentPlan) {
                //Downgrade
                const newVAl = {
                    planType: value.data.credits,
                    planData: value.data,
                    subscription_plan: value.planId,
                    line_type: 3,
                    line_amount: value.cost,
                    line_unit_amount: (value.cost / value.data.credits).toFixed(2),
                    line_qty: 1,
                    line_since_date: moment().format(),
                    line_end_date: moment().add(1, "M").format(),
                    image: value.image,
                };
                const newArr = cartItems.filter((item) => !item.subscription_plan && item.line_type !== 5);

                const params = {
                    franchise_id: todos.franchiseID,
                    company_id: todos.companyID,
                    cart_lines: [newVAl, ...newArr],
                };

                setIsLoading(true);

                await subscriptionService
                    .updateCart(params)
                    .then()
                    .catch((error) => {
                        console.log(error);
                    });

                setIsLoading(false);

                setCartItems([newVAl, ...newArr]);

                // setActivePlan(value.planId);

                handleOpenCart();
            } else if (value.planId > currentPlan) {
                //Upgrade
                const newVAl = {
                    planType: value.data.credits,
                    planData: value.data,
                    subscription_plan: value.planId,
                    line_type: 4,
                    line_amount: value.cost,
                    line_unit_amount: (value.cost / value.data.credits).toFixed(2),
                    line_qty: 1,
                    line_since_date: moment().format(),
                    line_end_date: moment().add(1, "M").format(),
                    image: value.image,
                };
                const newArr = cartItems.filter((item) => !item.subscription_plan && item.line_type !== 5);

                const params = {
                    franchise_id: todos.franchiseID,
                    company_id: todos.companyID,
                    cart_lines: [newVAl, ...newArr],
                };

                setIsLoading(true);

                await subscriptionService
                    .updateCart(params)
                    .then()
                    .catch((error) => {
                        console.log(error);
                    });

                setIsLoading(false);

                setCartItems([newVAl, ...newArr]);

                setActivePlan(value.planId);

                handleOpenCart();
            }
        } else {
            const newVAl = {
                planType: value.data.credits,
                planData: value.data,
                subscription_plan: value.planId,
                line_type: 1,
                line_amount: value.cost,
                line_unit_amount: value.cost,
                line_qty: 1,
                line_since_date: moment().format(),
                line_end_date: moment().add(1, "M").format(),
                image: value.image,
            };
            const newArr = cartItems.filter((item) => !item.subscription_plan && item.line_type !== 5);

            const params = {
                franchise_id: todos.franchiseID,
                company_id: todos.companyID,
                cart_lines: [newVAl, ...newArr],
            };

            setIsLoading(true);

            await subscriptionService
                .updateCart(params)
                .then()
                .catch((error) => {
                    console.log(error);
                });

            setIsLoading(false);

            setCartItems([newVAl, ...newArr]);

            setActivePlan(value.planId);
            if (value.planId === 1) {
                setPlanCredits(creditsInfo.slice(0, 2));
            } else if (value.planId === 2) {
                setPlanCredits(creditsInfo.slice(1, 3));
            } else if (value.planId === 3) {
                setPlanCredits(creditsInfo.slice(2, 4));
            } else if (value.planId === 4) {
                setPlanCredits(creditsInfo.slice(4, 6));
            } else if (value.planId === 5) {
                setPlanCredits(creditsInfo.slice(5, 7));
            } else {
                setPlanCredits(creditsInfo.slice(7, 9));
            }
            handleOpenCart();
        }
    };

    const handleClickCancel = async (plan) => {
        const newVAl = {
            planType: plan.data.credits,
            planData: plan.data,
            subscription_plan: plan.planId,
            line_type: 7,
            line_amount: 0.0,
            line_unit_amount: 0.0,
            line_qty: 1,
            line_since_date: moment().format(),
            line_end_date: moment().add(1, "M").format(),
            image: plan.image,
        };
        const newArr = cartItems.filter((item) => !item.subscription_plan && item.line_type !== 5);

        const params = {
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
            cart_lines: [newVAl, ...newArr],
        };

        setIsLoading(true);

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);

        setCartItems([newVAl, ...newArr]);
        handleOpenCart();
    };

    const reactivatePlan = () => {
        setReactivatePlan(plansInfo.find((item) => item.planId === currentPlanInfo.subscription_plan));
        setOpenReactiveAlert(true);
    };

    const handleClickReactivateChange = (data) => {
        setReactivatePlan(data);
        setOpenAlert(true);
    };

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    return (
        <Paper
            elevation={3}
            square={false}
            sx={{
                pb: 1,
                width: "100%",
                minHeight: 460,
                transition: "transform 0.2s", // Add a transition for a smooth effect
                transform:
                    currentPlan !== "" && currentPlan !== null && currentPlan === data.planId
                        ? "scale(1.07)"
                        : "scale(1.0)", // Scale the card on hover
                "&:hover": {
                    transform: "scale(1.07)", // Scale the card on hover
                },
                position: "relative",
                "&::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%", // 1/2 del card
                    height: "20%",
                    opacity: "0.1",
                    backgroundColor: data.colorTop, // Color de fondo
                    borderRadius: "10px 10px 50% 50%", // ", // Usar clipPath para crear un semicírculo
                    zIndex: 1,
                },
                borderRadius: 2.5,
            }}
        >
            <Grid container>
                <Grid item xs={12} textAlign="center" pt={8}>
                    <Box
                        component="img"
                        sx={{
                            height: 100,
                            width: 100,
                            maxHeight: { xs: 100, md: 100 },
                            maxWidth: { xs: 100, md: 100 },
                            zIndex: 999,
                        }}
                        src={data.image}
                    />
                </Grid>
                <Grid item xs={12} pt={2}>
                    {Object.entries(data.data).map((item, index) => (
                        <Grid container px={{ xs: 5, md: 8 }} pt={0.7} spacing={0.5} key={item[0]}>
                            {item[0] === "credits" && (
                                <>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography>
                                            <Box
                                                component="span"
                                                sx={{ color: "rgba(74, 34, 212, 1)", fontWeight: 600 }}
                                            >
                                                {item[1]}
                                            </Box>{" "}
                                            {item[0] === "credits"
                                                ? t("plans.conversionsMonth")
                                                : item[0] === "users"
                                                ? t(item[1] === 1 ? "plans.user" : "plans.users")
                                                : t("plans." + item[0])}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography>
                                            {data.planId === 1
                                                ? t("plans.convCost5")
                                                : data.planId === 2
                                                ? t("plans.convCost15")
                                                : data.planId === 3
                                                ? t("plans.convCost30")
                                                : data.planId === 4
                                                ? t("plans.convCost50")
                                                : data.planId === 5
                                                ? t("plans.convCost200")
                                                : t("plans.convCost500")}
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            {item[0] === "users" && item[1] !== t("plans.unlimited") && (
                                <>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography>
                                            <Box
                                                component="span"
                                                sx={{ color: "rgba(74, 34, 212, 1)", fontWeight: 600 }}
                                            >
                                                {item[1]}
                                            </Box>{" "}
                                            {item[0] === "credits"
                                                ? t("plans.conversionsMonth")
                                                : item[0] === "users"
                                                ? t(item[1] === 1 ? "plans.user" : "plans.users")
                                                : t("plans." + item[0])}
                                        </Typography>
                                    </Grid>
                                </>
                            )}
                            {item[0] === "roles" && item[1] === true && (
                                <>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography>{t("plans." + item[0])}</Typography>
                                    </Grid>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography>{t("plans.allFormats")}</Typography>
                                    </Grid>
                                </>
                            )}
                            {item[0] === "integrations" && item[1].length > 0 && (
                                <Grid item xs={12} textAlign="center">
                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                        <Typography sx={{ mr: 1 }}>{t("plans." + item[0])}</Typography>
                                        {item[1].includes("Xero") && (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 15,
                                                    width: 15,
                                                    maxHeight: { xs: 15, md: 15 },
                                                    maxWidth: { xs: 15, md: 15 },
                                                }}
                                                src={XeroLogo}
                                            />
                                        )}
                                        {item[1].includes("Quickbooks") && (
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 15,
                                                    width: 75,
                                                    maxHeight: { xs: 15, md: 15 },
                                                    maxWidth: { xs: 75, md: 75 },
                                                    ml: 0.5,
                                                }}
                                                src={QuickBooksLogo}
                                            />
                                        )}
                                    </Stack>
                                </Grid>
                            )}
                            {(item[0] === "training" ||
                                item[0] === "support" ||
                                item[0] === "storage" ||
                                (item[0] === "xeroDashboard" && item[1] === "Xero") ||
                                (item[0] === "users" && item[1] === t("plans.unlimited"))) && (
                                <Grid item xs={12} textAlign="center">
                                    <Typography>
                                        {t(
                                            "plans." +
                                                (item[0] === "storage"
                                                    ? "storage"
                                                    : item[0] === "users" && item[1] === t("plans.unlimited")
                                                    ? "users2"
                                                    : item[0] === "training" &&
                                                      language === "en" &&
                                                      (data.planId === 5 || data.planId === 6)
                                                    ? "training2"
                                                    : item[0] === "training"
                                                    ? "training1"
                                                    : item[0] === "support" && data.planId !== 5 && data.planId !== 6
                                                    ? "support1"
                                                    : item[0] === "support" && (data.planId === 5 || data.planId === 6)
                                                    ? "support2"
                                                    : item[0])
                                        )}{" "}
                                        <Box
                                            component="span"
                                            sx={{
                                                color:
                                                    item[0] === "users" && data.planId !== 6
                                                        ? "rgba(74, 34, 212, 1)"
                                                        : "#131F3E",
                                                fontWeight: item[0] === "users" && data.planId !== 6 ? 600 : "",
                                            }}
                                        >
                                            {item[0] === "xeroDashboard" && item[1] === "Xero" && language === "en"
                                                ? ""
                                                : item[0] === "support" && language === "en"
                                                ? t("plans.support3")
                                                : item[0] !== "training" &&
                                                  data.planId !== 1 &&
                                                  item[1] !== t("plans.unlimited")
                                                ? item[1]
                                                : item[0] === "storage"
                                                ? t("plans.unlimited")
                                                : (data.planId === 5 || data.planId === 6) && item[0] === "training"
                                                ? t("plans.custom1")
                                                : data.planId === 5 || (data.planId === 6 && item[0] !== "users")
                                                ? t("plans.priority2")
                                                : data.planId === 6 && item[0] === "users"
                                                ? t("plans.unlimited1")
                                                : t("plans.standard1")}
                                        </Box>
                                    </Typography>
                                </Grid>
                            )}
                            {((item[0] === "xeroDashboard" && item[1] === false) ||
                                (item[0] === "integrations" && item[1].length === 0)) && (
                                <Grid item xs={12} textAlign="center">
                                    &nbsp;
                                </Grid>
                            )}
                        </Grid>
                    ))}
                </Grid>
                <Grid item xs={12} textAlign="center" pt={1}>
                    <Typography color="#00B147" fontWeight={600}>
                        {`${t("credits.converExten")} +${[1, 2, 3].includes(data.planId) ? 30 : 60} ${t(
                            "miscellaneous.days"
                        )}.`}
                    </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center" pt={4}>
                    <Stack direction="column">
                        <Typography color="#4A22D4" fontWeight={600}>
                            $ {parseFloat(data.cost).toLocaleString(todos.amountFormat, { minimumFractionDigits: 2 })}/
                            {t("plans.month")}
                        </Typography>
                        <Box>
                            <ColorButton2
                                disableElevation
                                variant="contained"
                                endIcon={<CheckIcon />}
                                sx={{
                                    backgroundColor:
                                        currentPlan !== "" && currentPlan !== null && currentPlan === data.planId
                                            ? "#FFF"
                                            : "#4A22D4",
                                    color:
                                        currentPlan !== "" && currentPlan !== null && currentPlan === data.planId
                                            ? "#4A22D4 !important"
                                            : "#FFF",
                                    fontWeight:
                                        currentPlan !== "" && currentPlan !== null && currentPlan === data.planId
                                            ? "600 !important"
                                            : "300",
                                    border: "1px solid #4A22D4",
                                    "&:hover": {
                                        backgroundColor:
                                            currentPlan !== "" && currentPlan !== null && currentPlan === data.planId
                                                ? "#FFF"
                                                : "#331794",
                                        color:
                                            currentPlan !== "" && currentPlan !== null && currentPlan === data.planId
                                                ? "#4A22D4 !important"
                                                : "#FFF",
                                        fontWeight:
                                            currentPlan !== "" && currentPlan !== null && currentPlan === data.planId
                                                ? "600 !important"
                                                : "300",
                                        border:
                                            currentPlan !== "" && currentPlan !== null && currentPlan === data.planId
                                                ? "1px solid #4A22D4"
                                                : "1px solid #331794",
                                    },
                                }}
                                onClick={() => handlePlanSelect(data)}
                            >
                                {currentPlan !== "" && currentPlan !== null && currentPlan === data.planId ? (
                                    t("plans.active")
                                ) : activePlan && activePlan === data.planId ? (
                                    <>
                                        {t("plans.inCart")} <ShoppingCartIcon sx={{ ml: 0.5 }} fontSize="inherit" />
                                    </>
                                ) : (
                                    t("dialog.select")
                                )}
                            </ColorButton2>
                        </Box>
                        {/* Cambiar 'activePlan' por 'currentPlan' al obtener los planes de BD */}
                        <Box sx={{ visibility: currentPlan && currentPlan === data.planId ? "visible" : "hidden" }}>
                            <Button
                                sx={{
                                    color:
                                        currentPlanInfo?.last_cancel || currentPlanInfo?.last_change
                                            ? "#01AC45"
                                            : "#EC0000",
                                    fontWeight: 600,
                                    fontSize: 12,
                                    mt: 0.5,
                                }}
                                onClick={
                                    currentPlanInfo?.last_cancel !== undefined
                                        ? reactivatePlan
                                        : currentPlanInfo?.last_change !== undefined
                                        ? () => handleClickReactivateChange(data)
                                        : () => handleClickCancel(data)
                                }
                            >
                                {currentPlanInfo?.last_cancel !== undefined ||
                                currentPlanInfo?.last_change !== undefined
                                    ? t("accounts.activate")
                                    : t("dialog.cancel")}
                            </Button>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CardPlan;
