import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { useState } from "react";
import classes from "./MainConverter.module.css";
import { useTranslation } from "react-i18next";
import { getFileExtension } from "../../utils/utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const BasePreviewFile = (props) => {
    const { filename, filedata, __html } = props;

    const [t] = useTranslation("global");

    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState();

    const nextPage = () => {
        setPageNumber((prevNum) => prevNum + 1);
    };

    const prevPage = () => {
        setPageNumber((prevNum) => prevNum - 1);
    };

    const lastPage = () => {
        setPageNumber(numPages);
    };

    const firstPage = () => {
        setPageNumber(1);
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <>
            {getFileExtension(filename) === "pdf" ? (
                <>
                    <Document
                        file={filedata}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}
                    >
                        <IconButton
                            disabled={pageNumber === 1}
                            onClick={firstPage}
                        >
                            <FirstPageIcon />
                        </IconButton>
                        <IconButton
                            disabled={pageNumber === 1}
                            onClick={prevPage}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography>
                            {pageNumber} - {numPages}
                        </Typography>
                        <IconButton
                            disabled={pageNumber === numPages}
                            onClick={nextPage}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                        <IconButton
                            disabled={pageNumber === numPages}
                            onClick={lastPage}
                        >
                            <LastPageIcon />
                        </IconButton>
                    </Stack>
                </>
            ) : getFileExtension(filename) === "jpg" ||
              getFileExtension(filename) === "png" ||
              getFileExtension(filename) === "jpeg" ? (
                <>
                    <Box
                        className={classes.workbookContainer}
                        sx={{ bgcolor: "#CCC", height: "100%" }}
                        justifyContent="center"
                        display="flex"
                        alignItems="center"
                        
                    >
                        <Box
                            sx={{ width: "100%", height: "auto" }}
                            component="img"
                            src={filedata}
                        />
                    </Box>
                </>
            ) : getFileExtension(filename) === "xls" ||
              getFileExtension(filename) === "xlsx" ? (
                __html ? (
                    <>
                        <Box
                            className={classes.workbookContainer}
                            dangerouslySetInnerHTML={{ __html }}
                        />
                    </>
                ) : (
                    <Box
                        className={classes.workbookContainer}
                        sx={{ bgcolor: "#CCC", height: "100%" }}
                        justifyContent="center"
                        display="flex"
                        alignItems="center"
                    >
                        {t("converter.cannotPrev")}
                    </Box>
                )
            ) : (
                <Box
                    className={classes.workbookContainer}
                    sx={{ bgcolor: "#CCC", height: "100%" }}
                    justifyContent="center"
                    display="flex"
                    alignItems="center"
                >
                    {t("converter.cannotPrev")}
                </Box>
            )}
        </>
    );
};

export default BasePreviewFile;
