import { Box, Stack, Typography, Skeleton, FormControlLabel, Switch, Link, Button } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from '@mui/material/styles';
import { useState } from "react";
import CommuIcon from "../../assets/Communication.svg"
import moment from "moment";
import bankComm from "../../assets/bankComm.svg"
import checkComm from "../../assets/checkComm.svg"
import computerComm from "../../assets/computerComm.svg"
import settingsComm from "../../assets/settingsComm.svg"
import settingsComm2 from "../../assets/settingsComm2.svg"
import communicationsService from "../../services/communication";
import { useTranslation } from "react-i18next";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderRadius: "10px",
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        {...props}
    />
))(({ theme }) => ({
    borderRadius: "10px",
    px: 5
    // '& .MuiAccordionSummary-content': {
    //     marginLeft: theme.spacing(1),
    // },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    borderRadius: "10px",
}));

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        communication: <Skeleton variant="text" animation="wave" width="100%" />,
    });
};

const CaseTab3 = (props) => {
    const { comm, setComm, franchise_id, company_id, user_id, showSkeleton, setShow, setTexto, setAlertType, } = props

    const [expanded, setExpanded] = useState(false);
    const [checked, setChecked] = useState(true);

    const [t] = useTranslation("global");

    const handleChange = (panel) => async (event, newExpanded) => {
        setExpanded(newExpanded ? panel._id : false);

        if (panel.comunication_status === 1) {
            let params1 = { id: panel._id }
            await communicationsService
                .setRead(params1)
                .then(async () => {

                    let params2 = {
                        user_id: user_id,
                        company_id: company_id,
                        franchise_id: franchise_id,
                        all_communications: true
                    }

                    await communicationsService
                        .getCommunications(params2)
                        .then((data) => {
                            setComm(data);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleChangeSwitch = async (event) => {
        setChecked(event.target.checked);

        if (event.target.checked) {
            let params = {
                user_id: user_id,
                company_id: company_id,
                franchise_id: franchise_id,
                all_communications: true
            }
            await communicationsService
                .getCommunications(params)
                .then((data) => {
                    setComm(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            let params = {
                user_id: user_id,
                company_id: company_id,
                franchise_id: franchise_id,
            }
            await communicationsService
                .getCommunications(params)
                .then((data) => {
                    setComm(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    };

    function durationAsString(start, end) {
        const duration = moment.duration(moment(end).diff(moment(start)));

        //Get Days
        const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
        const daysFormatted = days ? `${days}d ` : ''; // if no full days then do not display it at all

        //Get Hours
        const hours = duration.hours();
        const hoursFormatted = `${hours}h `;

        //Get Minutes
        const minutes = duration.minutes();
        const minutesFormatted = `${minutes}m`;

        return [daysFormatted, hoursFormatted, minutesFormatted].join('');
    }

    const handleMark = async () => {
        let params = {
            user_id: user_id,
            company_id: company_id,
            franchise_id: franchise_id,
            read_all: true
        };

        await communicationsService
            .setRead(params)
            .then(async () => {

                let params2 = {
                    user_id: user_id,
                    company_id: company_id,
                    franchise_id: franchise_id,
                    all_communications: true
                }

                await communicationsService
                    .getCommunications(params2)
                    .then((data) => {
                        setComm(data);
                        setAlertType("success");
                        setTexto(t("inbox.markedReadMultipleComm"));
                        setShow(true);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Box paddingX={{ xs: 0, md: 2, lg: 8, height: "100vh" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" pb={1}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={checked}
                            onChange={handleChangeSwitch}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={<Typography fontWeight={600} fontSize={16}>{t("inbox.showAll")}</Typography>}
                />
                <Link sx={{ fontWeight: 600, fontSize: 16, cursor: "pointer", textDecoration: "none" }} component={Button} onClick={handleMark}>{t("inbox.markAll")}</Link>
            </Stack>
            {showSkeleton ? rowsSkeleton.map((item, index) => (
                <Box key={`box_${index}`} bgcolor="#FFF" py={2} px={5} borderRadius="10px" mb={0.7}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        {item.communication}
                    </Stack>
                </Box>
            )) : comm.length > 0 ? comm.map((item, index) => (
                <Accordion key={`communication_${index}`} expanded={expanded === item._id} onChange={handleChange(item)} sx={{
                    backgroundColor: item.comunication_status === 1 ? '#e4def9' : "#FFF",
                    mb: 0.6,
                }}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
                        {item.comunication_status === 1 && <Box component="img" src={CommuIcon} />}
                        <Typography color="#131F3E" fontWeight={600} sx={{ ml: 1 }} noWrap>
                            {item.comunication_title.toUpperCase()} {/* - */}
                        </Typography>
                        {/* <Typography noWrap sx={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "1200px",
                        }}>
                            {" " + item.comunication_description}
                        </Typography> */}
                        <Typography sx={{ ml: "auto" }}>
                            {durationAsString(item.creation_date, moment())}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: item.comunication_status === 1 ? '#e4def9' : "#FFF" }}>
                        <Stack direction="row" alignItems="center" spacing={4} px={3}>
                            <Box component="img" src={item.comunication_image === "bank" ? bankComm : item.comunication_image === "check" ? checkComm : item.comunication_image === "computer" ? computerComm : item.comunication_image === "settings" ? settingsComm : settingsComm2} />
                            <Typography>
                                {item.comunication_description}
                            </Typography>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            )) : <Box bgcolor="#FFF" py={2} px={5} borderRadius="10px">
                <Typography color="#131F3E" fontSize={18} sx={{ ml: 1 }} fontStyle="italic">
                    {t("inbox.noComm")}
                </Typography>
            </Box>}
        </Box>
    );
};

export default CaseTab3;
