import axios from "axios";

/* Script que contiene las llamadas a la API */
const dashboard = {
    getDashboard: (params) => {
        const request = axios.get("/getDashboard", {
            params: params,
        });

        return request.then((response) => response.data);
    },
    getXeroDashboard: (params) => {
        const request = axios.get("/getXeroDashboard", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    updateXeroDashboard: (params) => {
        const request = axios.post("/updateXeroDashboard", params);

        return request.then((response) => response);
    },

    getCompaniesFirm: (params) => {
        const request = axios.get("/getCompaniesFirm", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    getCompaniesUser: (params) => {
        const request = axios.get("/getCompaniesUser", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    updateGroupedXeroDashboard: (params) => {
        const request = axios.post("/updateGroupedXeroDashboard", params);

        return request.then((response) => response);
    },

    downloadKiiperTransactions: (params) => {
        const request = axios.get("/downloadKiiperTransactions", { responseType: "blob", params: params });

        return request.then((response) => response);
    },

    downloadSystemTransactions: (params) => {
        const request = axios.get("/downloadSystemTransactions", { responseType: "blob", params: params });

        return request.then((response) => response);
    },

    updateDashboard: (params) => {
        const request = axios.post("/updateDashboard", params);

        return request.then((response) => response);
    },
};

export default dashboard;
