import moment from "moment";

/** MUI imports */
import { styled } from "@mui/material/styles";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    InputAdornment,
    Skeleton,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import SearchIcon from "@mui/icons-material/Search";

/** MUI icons */
import { useTranslation } from "react-i18next";
import { useState } from "react";

import TablePaginationActions from "../ui/TablePagActions";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import casesService from "../../services/case";
import subscriptionService from "../../services/subscription";
import SimpleDialog from "../ui/SimpleDialog";
import { Invoice } from "../subscription/Invoice";
import FiltersButtonClientDir from "./FiltersButtonClientDir";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.03)",
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        franchise: <Skeleton variant="text" animation="wave" />,
        creation_date: <Skeleton variant="text" animation="wave" />,
        owner_name: <Skeleton variant="text" animation="wave" />,
        country_name: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        credits_count: <Skeleton variant="text" animation="wave" />,
        renew_date: <Skeleton variant="text" animation="wave" />,
        last_invoice_date: <Skeleton variant="text" animation="wave" />,
        open_cases: <Skeleton variant="text" animation="wave" />,
    });
}

const ClientsDir = (props) => {
    const { setIsLoading, loadingF, subsFilter, countriesFilter } = props;

    const [t] = useTranslation("global");
    /* React redux */
    const todos = useSelector((state) => state.value);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const [rows, setRows] = useState(rowsSkeleton);
    const [invoiceInfo, setInvoiceInfo] = useState(null);
    const [rowsOG, setRowsOG] = useState([]);
    const [openBill, setOpenBill] = useState(false);

    const columns = [
        { name: "franchise", title: t("navBar.subscription"), align: "left", width: 320 },
        { name: "creation_date", title: t("inbox.creationDate"), align: "center" },
        { name: "owner_name", title: t("team.owner"), align: "left", width: 230 },
        { name: "country_name", title: t("register.country"), align: "left", width: 160 },
        { name: "status", title: t("converter.status"), align: "left" },
        { name: "credits_count", title: t("inbox.availConv"), align: "left" },
        { name: "renew_date", title: t("inbox.nextRenew"), align: "center" },
        { name: "last_invoice_date", title: t("inbox.lastBill"), align: "center" },
        { name: "open_cases", title: t("inbox.openCases"), align: "left" },
    ];

    const handleCloseModal = () => {
        setOpenBill(false);
    };

    useEffect(() => {
        (async () => {
            const handleOpenBill = async (invoice) => {
                if (invoice) {
                    setIsLoading(true);
                    await subscriptionService
                        .getInvoiceDetails({ invoice_id: invoice })
                        .then((data) => {
                            setInvoiceInfo(data);
                            setOpenBill(true);
                        })
                        .catch((err) => console.log(err));
                    setIsLoading(false);
                }
            };

            await casesService
                .getClients()
                .then((data) => {
                    const notAssingData = data.map((item) => {
                        return {
                            id: item.franchise_id,
                            franchise_name: item.franchise_name,
                            franchise: item.franchise_name,
                            creation_date: moment.utc(item.creation_date).format(todos.dateFormat),
                            owner_name: item.owner_name,
                            country_name: item.country_name,
                            status: item.status,
                            credits_count: item.credits_count,
                            renew_date: item.renew_date ? moment.utc(item.renew_date).format(todos.dateFormat) : "N/A",
                            last_invoice_date: item.last_invoice_date ? (
                                <Button size="small" onClick={() => handleOpenBill(item.invoice_id)}>
                                    {moment.utc(item.last_invoice_date).format(todos.dateFormat)}
                                </Button>
                            ) : (
                                "-"
                            ),
                            open_cases: `${item.open_cases ?? 0} (${item.all_cases ?? 0})`,
                            invoice_id: item.invoice_id,
                        };
                    });

                    setRows(notAssingData);
                    setRowsOG(notAssingData);
                })
                .catch((err) => {
                    console.log(err);
                });
        })();
    }, [setIsLoading, todos.dateFormat]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSearchValue = (event) => {
        setSearchValue(event.target.value);

        const filterCases = rowsOG.filter(
            (item) =>
                item.franchise_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.owner_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.country_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                item.status.toLowerCase().includes(event.target.value.toLowerCase())
        );

        setRows(filterCases);
    };

    const handleOpenBill = async (invoice) => {
        if (invoice) {
            setIsLoading(true);
            await subscriptionService
                .getInvoiceDetails({ invoice_id: invoice })
                .then((data) => {
                    setInvoiceInfo(data);
                    setOpenBill(true);
                })
                .catch((err) => console.log(err));
            setIsLoading(false);
        }
    };

    return (
        <>
            <Card>
                <CardContent sx={{ padding: 0, paddingBottom: "12px !important" }}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ pb: 2, pt: 2, px: { xs: 1, md: 5 } }}
                    >
                        <Grid item>
                            <Typography variant="h1" color="primary">
                                {t("inbox.clientDir")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    {loadingF && (
                                        <FiltersButtonClientDir
                                            subscriptionFilter={subsFilter}
                                            countriesFilter={countriesFilter}
                                            setRows={setRows}
                                            handleOpenBill={handleOpenBill}
                                            todos={todos}
                                            setPage={setPage}
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="filled"
                                        hiddenLabel
                                        placeholder="Buscar..."
                                        //size="small"
                                        value={searchValue}
                                        onChange={handleChangeSearchValue}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Box
                component="main"
                sx={{
                    px: { xs: 2, md: 5 },
                    backgroundColor: "#F8F8F9",
                    pb: 4,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {rows.length > 0 ? (
                            <TableContainer>
                                <Table sx={{ minWidth: 1300 }} aria-label="simple table">
                                    <TableHead>
                                        <StyledTableRow>
                                            {columns.map((item) => (
                                                <StyledTableCell
                                                    key={item.name}
                                                    align={item.align ?? "left"}
                                                    style={{ width: item.width }}
                                                >
                                                    {item.title}
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(rowsPerPage > 0
                                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : rows
                                        ).map((row) => (
                                            <StyledTableRow
                                                key={row.id}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <StyledTableCell align="left">
                                                    <Typography variant="h3" color="primary">
                                                        {row.franchise}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{row.creation_date}</StyledTableCell>
                                                <StyledTableCell align="left">{row.owner_name}</StyledTableCell>
                                                <StyledTableCell align="left">{row.country_name}</StyledTableCell>
                                                <StyledTableCell align="left">{row.status}</StyledTableCell>
                                                <StyledTableCell align="left">{row.credits_count}</StyledTableCell>
                                                <StyledTableCell align="center">{row.renew_date}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {row.last_invoice_date}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{row.open_cases}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[
                                                    10,
                                                    25,
                                                    50,
                                                    { label: t("miscellaneous.all"), value: -1 },
                                                ]}
                                                colSpan={12}
                                                count={rows.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        "aria-label": "rowsPerPage",
                                                    },
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                                labelRowsPerPage={t("dashboard.rowsPerPage")}
                                                labelDisplayedRows={(page) =>
                                                    `${page.from}-${page.to === -1 ? page.count : page.to} ${t(
                                                        "team.unlinkText2"
                                                    )} ${page.count}`
                                                }
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        ) : (
                            <>
                                <Typography fontSize={14} textAlign="center" sx={{ mt: 3 }}>
                                    {t("dashboard.noInfo")}
                                </Typography>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <SimpleDialog
                open={openBill}
                handleClose={handleCloseModal}
                maxWidth="md"
                content={<Invoice invoiceInfo={invoiceInfo} />}
                invoice={true}
            />
        </>
    );
};

export default ClientsDir;
