/** React imports */
import { useTranslation } from "react-i18next";

/** MUI imports */
import {
    Grid,
    TextField,
    Checkbox,
    ListItemText,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    FilledInput,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

/** Js imports **/
import CustomFilledSelect from "../ui/CustomFilledSelect";
import CustomFilledSelectCountries from "../ui/CustomFilledSelectCountries";
import businessActivity from "../Js/businessActivity";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 48 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getcontentanchorel: null,
};

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const AddCompanyStep1 = (props) => {
    const [t] = useTranslation("global");

    const { isError, smbInfo, handleChange, systemsList, handleChangeCompanyName, isErrorName } = props;

    let bussinessActFinal = []

    for (let i = 0; i < businessActivity.length; i++) {
        bussinessActFinal.push({
            id: businessActivity[i].id,
            name: t("dialog." + businessActivity[i].translation)
        })
    }

    return (
        <Grid container sx={{ mt: 3 }} spacing={2}>
            {/* {!addWithFranchise && (
                <Grid item xs={12} md={6}>
                    <CustomFilledSelect
                        error={isError && !smbInfo.companyType}
                        required={true}
                        disabled={addWithFranchise}
                        label={t("dialog.companyType")}
                        value={smbInfo.companyType}
                        onChange={handleChangeCompanyType}
                        values={companyTypeList}
                        name="companyType"
                        idName="id"
                        valueName="name"
                    />
                </Grid>
            )} */}
            <Grid item xs={12} md={6}>
                <CustomTextField
                    fullWidth
                    label={t("dialog.companyName")}
                    name="companyName"
                    required
                    onChange={handleChangeCompanyName}
                    value={smbInfo.companyName}
                    variant="filled"
                    inputProps={{
                        fontStyle: "normal",
                    }}
                    error={(isError && smbInfo.companyName.trim() === "") || isErrorName}
                    helperText={isErrorName && t("dialog.alreadyExists")}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomFilledSelectCountries
                    required={true}
                    error={isError && !smbInfo.country}
                    label={t("register.country")}
                    value={smbInfo.country}
                    onChange={handleChange}
                    name="country"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <CustomFilledSelect
                    error={isError && !smbInfo.ecActivity}
                    required
                    label={t("dialog.ecActivity")}
                    value={smbInfo.ecActivity}
                    onChange={handleChange}
                    name="ecActivity"
                    values={bussinessActFinal}
                    idName="id"
                    valueName="name"
                />
            </Grid>
            {/* <Grid item xs={12} md={6}>
                <CustomFilledSelect
                    error={isError && !smbInfo.companySize}
                    required
                    label={t("dialog.companySize")}
                    value={smbInfo.companySize}
                    onChange={handleChange}
                    name="companySize"
                    values={sizeList}
                    idName="id"
                    valueName="name"
                />
            </Grid> */}
            <Grid item xs={12} md={6}>
                <FormControl variant="filled" fullWidth required error={isError && !smbInfo.systems.length}>
                    <InputLabel
                        id="demo-multiple-checkbox-label"
                        sx={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            color: "#131F3E",
                        }}
                    >
                        {t("dialog.usedFormats")}
                    </InputLabel>
                    <Select
                        IconComponent={(props) => {
                            if (props.className.includes('MuiSelect-iconOpen')) {
                                return <PlayArrowIcon sx={{ position: "absolute", transform: "rotate(270deg)", color: "#131F3E", right: ".5rem", cursor: "pointer", zIndex: 0, pointerEvents: "none" }} />
                            }
                            return <PlayArrowIcon sx={{ position: "absolute", transform: "rotate(90deg)", color: "#131F3E", right: ".5rem", cursor: "pointer", zIndex: 0, pointerEvents: "none" }} />
                        }}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        name="systems"
                        value={smbInfo.systems}
                        onChange={handleChange}
                        input={<FilledInput label={t("dialog.usedFormats")} />}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                    >
                        {systemsList.map((item) => (
                            <MenuItem key={item} value={item}>
                                <Checkbox checked={smbInfo.systems.indexOf(item) > -1} />
                                <ListItemText primary={item} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default AddCompanyStep1;
