import axios from "axios";

/* Script que contiene las llamadas a la API */
const cases = {
    // Peticion para obtener los casos
    getCommunications: (params) => {
        const request = axios.get("/getCommunications", {
            params: params,
        });

        return request.then((response) => response.data);
    },

    setRead: (params) => {
        const request = axios.put("/setRead", params);

        return request.then((response) => response);
    },

    updateStatusMess: (params) => {
        const request = axios.put("/updateStatusMess", params);

        return request.then((response) => response);
    },
};

export default cases;
