const productTeam = [
	{
		value: "dloyo@gca.digital",
		name: "David Loyo"
	},
	{
		value: "gloyo@gca.digital",
		name: "Gabriel Loyo"
	},
	{
		value: "pherrera@gca.digital",
		name: "Pedro Herrera"
	},
	{
		value: "galbani@gca.digital",
		name: "Giancarlo Albani"
	},
	{
		value: "na",
		name: "N/A"
	},
]

export default productTeam;
