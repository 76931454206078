import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Stack,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import payService from "../../../services/payment_methods";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Visa from "../../../assets/visa.svg";
import Mastercard from "../../../assets/mastercard.svg";
import Amex from "../../../assets/amex.svg";
import Discover from "../../../assets/discover.svg";
import Diners from "../../../assets/diners.svg";
import JCB from "../../../assets/jcb.svg";
import UnionPay from "../../../assets/unionpay.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 20,
        padding: "20px 30px",
    },
    "& .MuiDialogTitle-root": {
        fontSize: 20,
        fontWeight: 600,
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
        justifyContent: "space-between",
    },
}));

export default function ChangePayDialog(props) {
    const { open, handleClose, payments, setIsLoading, reloadData, defaultCard, handleAddPaymentM } = props;

    const [t] = useTranslation("global");

    const [selectedValue, setSelectedValue] = useState("");

    useEffect(() => {
        setSelectedValue(defaultCard?._id);
    }, [defaultCard]);

    const handleChange = async (event) => {
        setSelectedValue(event.target.value);

        const params = {
            payment_id: event.target.value,
            default_method: true,
        };

        setIsLoading(true);

        await payService
            .updateDefaultMethod(params)
            .then(() => reloadData())
            .catch((err) => console.log(err));

        setIsLoading(false);
    };

    return (
        <BootstrapDialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>{t("credits.paymentMethods")}</DialogTitle>
            <DialogContent>
                <FormControl sx={{ width: "100%" }}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={handleChange}
                        value={selectedValue}
                    >
                        {payments.map((item) => (
                            <FormControlLabel
                                value={item._id}
                                key={"box_payment_" + item._id}
                                control={<Radio size="small" />}
                                disableTypography
                                sx={{ pb: 1 }}
                                label={
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Box
                                            component="img"
                                            src={
                                                item.brand === "visa"
                                                ? Visa
                                                : item.brand === "mastercard"
                                                ? Mastercard
                                                : item.brand === "amex"
                                                ? Amex
                                                : item.brand === "discover"
                                                ? Discover
                                                : item.brand === "diners"
                                                ? Diners
                                                : item.brand === "unionpay"
                                                ? UnionPay
                                                : JCB
                                            }
                                            sx={{ width: 45 }}
                                        />
                                        <Stack direction="column">
                                            <Typography fontWeight={600} fontSize={12} color="#131F3E">
                                                {item.brand.charAt(0).toUpperCase() + item.brand.slice(1)}{" "}
                                                {t("payment.endingIn")} {item.card_number.split("-").pop()}
                                            </Typography>
                                        </Stack>
                                        {item.default_method && (
                                            <Box
                                                component="span"
                                                sx={{
                                                    py: 0.5,
                                                    px: 2,
                                                    backgroundColor: "#F6F4FD",
                                                    borderRadius: "20px",
                                                    height: 24
                                                }}
                                            >
                                                <Typography variant="h3" color="primary">
                                                    {t("payment.default")}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Stack>
                                }
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Button onClick={handleClose}>{t("register.back")}</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disableElevation
                            variant="contained"
                            onClick={handleAddPaymentM}
                        >
                            {t("credits.add")}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </BootstrapDialog>
    );
}
